import React, { FC } from "react";
import { Modal, ModalProps } from "react-bootstrap";

import * as api from "~/api";
import { TSButton } from "~common";

import "./ReactivateEmployeeModal.scss";

interface ReactivateEmployeeModalProps extends ModalProps {
  employee: api.Employee;
  onConfirm: () => any;
}

export const ReactivateEmployeeModal: FC<ReactivateEmployeeModalProps> = ({
  employee,
  onConfirm,
  ...modalProps
}) => {
  return (
    <Modal
      className="reactivate-modal"
      container={document.getElementById("ts-modal")}
      centered
      {...modalProps}
    >
      <Modal.Body>
        <h1 className="text">Reactivate {employee.name}</h1>
        <p>
          Reactivating {employee.name} (ID: {employee.external_id}) will
          re-enable their access to TeamSense. All their historical data has
          been preserved. If you would like to edit any of this employee’s
          information, please head to their page after reactivation.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <TSButton
          variant="link"
          size="lg"
          onClick={() => modalProps.onHide?.()}
        >
          Cancel
        </TSButton>
        <TSButton variant="primary" size="lg" onClick={() => onConfirm()}>
          Reactivate
        </TSButton>
      </Modal.Footer>
    </Modal>
  );
};
