import React, { FunctionComponent, useState } from "react";
import DatePicker from "react-datepicker";

import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import {
  TimePickerModal,
  TimePickerModalProps
} from "~/components/TimePickerModal/TimePickerModal";
import CheckboxChecked from "~/images/checkbox-checked.svg";
import CheckboxEmpty from "~/images/checkbox-empty.svg";
import { useLinguiLocale } from "~/lib/hooks";

interface ScheduleWeekProps {
  className?: string;
  desktopPicker?: boolean;
  timezone: string;
  weekdayTimes: api.WeeklySchedule;
  onChangeWeekdayTimes: (weekdayTimes: api.WeeklySchedule) => any;
}

interface ParsedTime {
  hour: number;
  minute: number;
}

const parseTime = (isoTimeOnly?: string): ParsedTime => {
  const numericTime = (isoTimeOnly?.split("T") ?? [])[1];
  const hour = parseInt(numericTime?.substr(0, 2) ?? "0");
  const minute = parseInt(numericTime?.substr(2, 2) ?? "0");
  return { hour, minute };
};

const toISOTimeOnly = (hour: number, minute: number): string => {
  const paddedHour = hour < 10 ? `0${hour}` : `${hour}`;
  const paddedMinute = minute < 10 ? `0${minute}` : `${minute}`;
  return `T${paddedHour}${paddedMinute}`;
};

const displayTime = (
  locale: string,
  timezone: string,
  isoTimeOnly?: string
): string => {
  const { hour, minute } = parseTime(isoTimeOnly);
  let displayTime = "";
  try {
    displayTime = DateTime.local()
      .setZone(timezone)
      .set({
        hour,
        minute
      })
      .toLocaleString({
        ...DateTime.TIME_SIMPLE,
        locale,
        timeZoneName: "short"
      });
  } catch (e) {
    displayTime = DateTime.local()
      .set({
        hour,
        minute
      })
      .toLocaleString({
        ...DateTime.TIME_SIMPLE,
        locale: "en-US",
        timeZoneName: "short"
      });
  }
  return displayTime;
};

const timeAsJSDate = (isoTimeOnly?: string): Date => {
  const { hour, minute } = parseTime(isoTimeOnly);
  return DateTime.local()
    .set({
      hour,
      minute
    })
    .toJSDate();
};

const Checkbox: FunctionComponent<{
  className?: string;
  checked: boolean;
  onClick: () => any;
}> = ({ className, checked, onClick }) => (
  <div className={clsx("cursor-pointer", className)} onClick={onClick}>
    <img src={checked ? CheckboxChecked : CheckboxEmpty} alt="" />
  </div>
);

export interface TimePickerState
  extends Omit<TimePickerModalProps, "onClose" | "onSave"> {
  weekday: number;
}

export const ScheduleWeek: FunctionComponent<ScheduleWeekProps> = ({
  className,
  desktopPicker,
  timezone,
  weekdayTimes,
  onChangeWeekdayTimes
}) => {
  const [timePickerState, setTimePickerState] = useState<TimePickerState>();
  const locale = useLinguiLocale();
  const datetime = DateTime.local().setLocale(locale);

  const WEEKDAYS =
    locale === "en-US" ? [7, 1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5, 6, 7];

  const initialTime = (): ParsedTime => {
    // Choose a reasonable intial time by looking for an existing time:
    for (let i = 0; i < WEEKDAYS.length; i++) {
      const weekday = WEEKDAYS[i];
      if (weekdayTimes[weekday]) {
        return parseTime(weekdayTimes[weekday]);
      }
    }
    // Default to 8AM if there are no times chosen yet:
    return { hour: 8, minute: 0 };
  };

  const onClickCheckbox = (weekday: number): void => {
    const newTimes = { ...weekdayTimes };
    if (weekdayTimes[weekday]) {
      // Turn this day off:
      delete newTimes[weekday];
      onChangeWeekdayTimes(newTimes);
      return;
    }
    // Turn this day on with a reasonable initial time:
    const { hour: initialHour, minute: initialMinute } = initialTime();
    if (desktopPicker) {
      onChangeWeekdayTimes({
        ...newTimes,
        [weekday]: toISOTimeOnly(initialHour, initialMinute)
      });
    } else {
      setTimePickerState({ weekday, initialHour, initialMinute });
    }
  };

  const onClickTime = (weekday: number): void => {
    const { hour, minute } = parseTime(weekdayTimes[weekday]);
    setTimePickerState({ weekday, initialHour: hour, initialMinute: minute });
  };

  const updateWeekdayTime = (
    weekday: number,
    hour: number,
    minute: number
  ): void =>
    onChangeWeekdayTimes({
      ...weekdayTimes,
      [weekday]: toISOTimeOnly(hour, minute)
    });

  const onSaveTime = (hour: number, minute: number): void => {
    const { weekday } = timePickerState || {};
    if (weekday) {
      updateWeekdayTime(weekday, hour, minute);
    }
  };

  const onDesktopPickerChange = (
    weekday: number,
    value: Date | [Date, Date] | null
  ): void => {
    if (!(value instanceof Array)) {
      const dt = DateTime.fromJSDate(value ?? new Date());
      updateWeekdayTime(weekday, dt.get("hour"), dt.get("minute"));
    }
  };

  return (
    <>
      <div className={className}>
        {WEEKDAYS.map(weekday => (
          <div
            key={`day-${weekday}`}
            className={clsx(
              "flex flex-row items-center",
              desktopPicker ? "h-16" : "pt-4 pb-3"
            )}
          >
            <div
              className="flex flex-row w-24 cursor-pointer"
              onClick={() => onClickCheckbox(weekday)}
            >
              <Checkbox
                className="mr-3 ml-3"
                checked={Boolean(weekdayTimes[weekday])}
                onClick={() => onClickCheckbox(weekday)}
              />
              {datetime.set({ weekday }).weekdayShort}
            </div>
            {weekdayTimes[weekday] && (
              <>
                {!desktopPicker && (
                  <div
                    className="underline cursor-pointer"
                    onClick={() => onClickTime(weekday)}
                    dir="ltr"
                  >
                    {true &&
                      displayTime(locale, timezone, weekdayTimes[weekday])}
                  </div>
                )}
                {desktopPicker && (
                  <DatePicker
                    selected={timeAsJSDate(weekdayTimes[weekday])}
                    wrapperClassName="w-24"
                    className="field"
                    placeholderText="Select"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeFormat="p"
                    dateFormat="p"
                    onBlur={() => console.log(`onBlur`)}
                    onChange={value => onDesktopPickerChange(weekday, value)}
                  />
                )}
                {desktopPicker && (
                  <div className="ml-2 text-sm text-hs-medium-dark-gray">
                    {DateTime.local()
                      .setZone(timezone)
                      .toFormat("ZZZZ", { locale: "en-US" })}
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
      {timePickerState?.weekday && (
        <TimePickerModal
          {...timePickerState}
          onClose={() => setTimePickerState(undefined)}
          onSave={onSaveTime}
        />
      )}
    </>
  );
};
