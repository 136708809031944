import React, { useContext, useState, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { EmployeeSummary } from "~/ts-components/employees/EmployeeSummary";
import { TSButton, TSInput } from "~common";

import "./UserAccount.scss";

export const UserAccount: React.FC = () => {
  const { session } = useContext(MutableSessionContext);
  const history = useHistory();
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordEvent = function (
    e: React.KeyboardEvent<HTMLSpanElement> | React.MouseEvent<HTMLSpanElement>
  ): void {
    if ("key" in e && e.key !== "Enter" && e.key !== " ") {
      return;
    }
    setShowPassword(!showPassword);
  };

  const goToEmployeeRecord = (): void => {
    if (session?.employee?.id) {
      history.push(`/dashboard/new-employees/employee/${session.employee.id}`);
    }
  };
  const onChangePassword = (): void => {
    api
      .updatePassword({
        current_password: currentPassword,
        password1: newPassword,
        password2: newPassword
      })
      .then(response => {
        if (response.ok) {
          setShowPassword(false);
          toast.success("Password changed!");
        } else if (response.errors.password1) {
          toast.error(`${response.errors.password1[0]}`);
        } else if (response.errors.error) {
          toast.error(`${response.errors.error}`);
        } else {
          toast.error("Unable to update password");
        }
      })
      .catch(error => {
        toast.error(`Error: ${error.message}`);
      });
  };
  return (
    <div className="ts user-account">
      <div className="standard-page container-fluid">
        <div className="row justify-content-center">
          {session?.employee && (
            <div className="d-none d-md-block col-md-6 col-lg-5 col-xl-4">
              <EmployeeSummary
                employee={session?.employee}
                onEdit={() => goToEmployeeRecord()}
                editText="View Employee"
              />
            </div>
          )}
          <div className="col-md-6 col-lg-7 col-xl-8">
            <div className="content-card card card-border-radius m-0">
              <h1>Manage Account</h1>
              <div className="h-px bg-hs-alt-gray my-5-2"></div>
              <div>
                <h2>Change your password</h2>
                <div className="account-inputs">
                  <TSInput
                    id="current-password"
                    label="Current Password"
                    type="password"
                    required
                    defaultValue={""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setCurrentPassword(e.target.value)
                    }
                    placeholder="Enter current password"
                  />
                  <TSInput
                    id="new-password"
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    required
                    defaultValue={""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setNewPassword(e.target.value)
                    }
                    placeholder="Enter new password"
                  />
                  <span
                    role="button"
                    tabIndex={0}
                    className="form-label float-end mt-2"
                    onKeyUp={showPasswordEvent}
                    onClick={showPasswordEvent}
                  >
                    {showPassword ? "Hide" : "Show"}
                    <span className="d-none d-sm-inline d-md-none d-lg-inline">
                      {" "}
                      password
                    </span>
                  </span>
                  <TSButton onClick={onChangePassword}>
                    Change Password
                  </TSButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
