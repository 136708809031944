import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Badge, Table } from "react-bootstrap";

import { DateTime } from "luxon";

import { FormScheduleModal } from "./FormScheduleModal";

import { retrieveSurveySchedules } from "~/api";
import { MutableSessionContext } from "~/lib/context";
import {
  DAY_OF_MONTH_OPTIONS,
  DAY_OF_WEEK_OPTIONS,
  INTERVAL_OPTIONS
} from "~/lib/scheduleRecurrence";
import {
  FormScheduleRecurrenceDetails,
  RecipientDivisions,
  RecipientEmployees,
  RecipientGroups
} from "~/types";
import { GenericErrorText, ToastContext, TSButton } from "~common";

import "./FormSchedules.scss";

export const FormSchedules: FC<FormScheduleProps> = props => {
  const { formId, isAnonymous, isCovid } = props;

  const { session } = useContext(MutableSessionContext);
  const toast = useRef(useContext(ToastContext));

  const [showFormScheduleModal, setShowFormScheduleModal] =
    useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [scheduleData, setScheduleData] =
    useState<FormScheduleRecurrenceDetails[]>();
  const [selectedSchedule, setSelectedSchedule] =
    useState<FormScheduleRecurrenceDetails>({});

  // data loader
  const loadSchedules = useCallback(async (): Promise<void> => {
    if (!session.company?.id) {
      return;
    }

    try {
      const response = await retrieveSurveySchedules(
        session.company?.id,
        formId
      );

      if (response.ok) {
        setScheduleData(response.data);
      }
    } catch {
      toast.current.show({
        variant: "danger",
        message: <GenericErrorText />
      });
    }
  }, [formId, session.company?.id]);

  // Utility Methods
  const getBadge = (label: string, index?: number): ReactNode => {
    return (
      <Badge key={index} pill bg="light" text="dark">
        {label}
      </Badge>
    );
  };

  const getMonthlyBadge = (item: FormScheduleRecurrenceDetails): ReactNode => {
    const scheduleObj = item.schedules && item.schedules[0];

    // pull values from the first schedule item
    const interval = scheduleObj?.kind === "dayOfWeek" && scheduleObj?.interval;
    const dayOfWeek =
      scheduleObj?.kind === "dayOfWeek" && scheduleObj?.dayOfWeek;
    const dayOfMonth =
      scheduleObj?.kind === "dayOfMonth" && scheduleObj?.dayOfMonth;

    // get the labels from the dropdown objects based on the item schedule data
    const selectedInterval =
      dayOfWeek &&
      INTERVAL_OPTIONS.find(p => p.value === Number(interval))?.label;
    const selectedDayOfWeek =
      dayOfWeek &&
      DAY_OF_WEEK_OPTIONS.find(p => p.value === Number(dayOfWeek))?.label;
    const selectedDayOfMonth =
      dayOfMonth &&
      DAY_OF_MONTH_OPTIONS.find(p => p.value === Number(dayOfMonth))?.label;

    // Return a badge with the correct content
    if (dayOfWeek) {
      return getBadge(`${selectedInterval} ${selectedDayOfWeek}`);
    } else if (dayOfMonth) {
      return getBadge(`${selectedDayOfMonth}`);
    } else {
      return <></>;
    }
  };

  const getShortWeekday = (item: FormScheduleRecurrenceDetails): ReactNode => {
    return item.schedules?.map((schedule, index) => {
      const label = DAY_OF_WEEK_OPTIONS.find(
        p => p.value === schedule.dayOfWeek
      )?.label.substr(0, 3);
      return getBadge(`${label}`, index);
    });
  };

  const getFrequency = (item: FormScheduleRecurrenceDetails): ReactNode => {
    return item.frequency === "weekly"
      ? getShortWeekday(item)
      : getMonthlyBadge(item);
  };

  const getTimeLabel = (
    item: FormScheduleRecurrenceDetails
  ): string | undefined => {
    if (item.frequency === "weekly") {
      return "Various";
    }

    if (item.schedules?.length) {
      const dailyEntry = item.schedules[0];
      return (
        dailyEntry.time &&
        DateTime.fromISO(dailyEntry.time).toFormat("h:mm a", {
          locale: "en-US"
        })
      );
    }
  };

  const getBadgeGroup = (
    label: string,
    data: (RecipientEmployees | RecipientGroups | RecipientDivisions)[]
  ): ReactNode => {
    const badges = data.map(
      (item: { name: string }, index: number | undefined) => {
        return getBadge(item.name, index);
      }
    );

    return (
      Boolean(badges?.length) && (
        <div className="schedule-td">
          <h6>{label}</h6>
          <div className="schedule-pills">{badges}</div>
        </div>
      )
    );
  };

  const getRecipientBadges = (
    item: FormScheduleRecurrenceDetails
  ): ReactNode => {
    const { recipientGroups } = item;

    const divisionBadgeGroup =
      Boolean(recipientGroups?.divisions?.length) &&
      recipientGroups?.divisions &&
      getBadgeGroup("Division", recipientGroups?.divisions);

    const groupBadgeGroup =
      Boolean(recipientGroups?.groups?.length) &&
      recipientGroups?.groups &&
      getBadgeGroup("Group", recipientGroups.groups);

    const employeeBadgeGroup =
      Boolean(recipientGroups?.employees?.length) &&
      recipientGroups?.employees &&
      getBadgeGroup("Employee", recipientGroups.employees);

    return item.recipientGroups?.allCompany ? (
      getBadge("All Company")
    ) : (
      <>
        {divisionBadgeGroup}
        {groupBadgeGroup}
        {employeeBadgeGroup}
      </>
    );
  };

  // Event Handlers
  const addScheduleClickHandler = (): void => {
    setIsEditMode(false);
    setShowFormScheduleModal(true);
  };

  const modalCloseHandler = (): void => {
    setShowFormScheduleModal(false);
  };

  const rowSelectedHandler = (item: FormScheduleRecurrenceDetails): void => {
    setSelectedSchedule(item);
    setIsEditMode(true);
    setShowFormScheduleModal(true);
  };

  // Hooks
  useEffect(() => {
    loadSchedules();
  }, [loadSchedules]);
  // Content Fragments
  const tableRows = scheduleData?.map((item, index) => {
    return (
      <tr
        className="hover-pointer"
        key={index}
        onClick={() => rowSelectedHandler(item)}
      >
        <td className="schedule-name">
          <h3 className="mb-0">{item.name}</h3>
        </td>
        <td className="schedule-time">
          <h6>{getTimeLabel(item)}</h6>
        </td>
        <td className="schedule-schedule">
          <div className="schedule-td">
            <h6>{item.frequency}</h6>
            <div className="schedule-pills">{getFrequency(item)}</div>
          </div>
        </td>
        <td className="schedule-recipients">{getRecipientBadges(item)}</td>
      </tr>
    );
  });

  return (
    <>
      {showFormScheduleModal && (
        <FormScheduleModal
          show={showFormScheduleModal}
          handleClose={modalCloseHandler}
          details={isEditMode ? selectedSchedule : {}}
          isEditMode={isEditMode}
          formId={formId}
          loadSchedules={loadSchedules}
          isAnonymous={isAnonymous}
          isCovid={isCovid}
        />
      )}
      <div className="main-content-header">
        <TSButton
          variant="primary"
          className="ms-auto"
          onClick={addScheduleClickHandler}
        >
          Add Schedule
        </TSButton>
      </div>
      <Table responsive className="form-schedules p-3">
        <thead>
          <tr className="text-muted text-xs">
            <th>NAME</th>
            <th>TIME</th>
            <th>SCHEDULE</th>
            <th>RECIPIENTS</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
          {scheduleData?.length === 0 && (
            <tr>
              <td colSpan={4}>There are no schedules configured.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

interface FormScheduleProps {
  formId: string;
  isAnonymous: boolean;
  isCovid: boolean;
}
