import React, { FunctionComponent, useState } from "react";

import * as api from "~/api";
import { ConfirmationModal, ModalProps } from "~common";

interface Props extends ModalProps {
  employee: api.Employee;
  note?: api.Note;
  onSave?: (employee: api.Employee, body: string, note?: api.Note) => any;
  onClickDelete: () => any;
}

export const NoteModal: FunctionComponent<Props> = ({
  employee,
  note,
  onClose,
  onSave,
  onClickDelete
}) => {
  const [body, setBody] = useState(note?.body);

  const onTextAreaChanged = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => setBody(event.target.value);

  const onSendClicked = (): void => {
    onSave?.(employee, body ?? "", note);
  };

  return (
    <ConfirmationModal
      prompt={note ? "Edit Note" : "Add Note"}
      onClose={onClose}
      onConfirm={onSendClicked}
      confirmText="Save"
      confirmDisabled={(note?.body ?? "") === (body ?? "")}
      topRightLinkText={
        note && !note.has_employee_events ? "Delete note" : undefined
      }
      onClickTopRightLink={onClickDelete}
    >
      <div className="pt-2 sm:flex sm:items-center">
        <div className="max-w-m w-full">
          <label htmlFor="note" className="sr-only">
            Note
          </label>
          <textarea
            id="note"
            rows={8}
            placeholder="Enter note"
            className="bg-transparent resize-none text-sm w-full leading-normal placeholder-gray-400 flex-grow outline-none p-5"
            onChange={onTextAreaChanged}
            value={body}
          ></textarea>
        </div>
      </div>
    </ConfirmationModal>
  );
};
