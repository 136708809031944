import React, { FC, useState } from "react";

import clsx from "clsx";

import "./TSPill.scss";

export const TSPill: FC<TSPillInterface> = ({
  text,
  variant,
  background,
  color,
  backgroundHover,
  button,
  disabled,
  onClick,
  className
}) => {
  const Tag = (button ? "button" : "span") as TagType;

  const [styles, setStyles] = useState({
    background,
    color
  });

  return (
    <Tag
      className={clsx(
        "badge ts-pill rounded-pill",
        variant && `badge-${variant}`,
        className
      )}
      disabled={disabled || !onClick}
      onMouseOver={() =>
        backgroundHover && setStyles({ background: backgroundHover, color })
      }
      onMouseOut={() => setStyles({ background, color })}
      onClick={onClick}
      style={styles}
    >
      {text}
    </Tag>
  );
};

export interface TSPillInterface {
  variant?: string;
  text: string;
  background?: string;
  color?: string;
  backgroundHover?: string;
  button?: boolean;
  onClick?: (event: any) => void;
  disabled?: boolean;
  className?: string;
}

type TagType = "button" | "span";
