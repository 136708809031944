import { MuiElementTheme } from "../Theme/types";

const MuiTypographyTheme: MuiElementTheme<"MuiTypography"> = {
  defaultProps: {
    letterSpacing: "0.5px"
  },
  styleOverrides: {
    root: {
      marginBottom: "0px !important" // !I-BRB
    },
    h1: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      fontWeight: 700
    },
    h2: {
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
      fontWeight: 700
    },
    h3: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 600
    },
    h4: {
      fontSize: "0.875rem",
      lineHeight: "1rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "0.875rem",
      lineHeight: "1rem",
      fontWeight: 500
    },
    h6: {
      fontSize: "0.75rem",
      lineHeight: "1rem",
      fontWeight: 700,
      textTransform: "uppercase"
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem"
    },
    body2: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem"
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem"
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem"
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: "1rem"
    }
  }
};

export default MuiTypographyTheme;
