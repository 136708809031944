import React, { FunctionComponent, useState } from "react";
import { Container, Nav, Col, Row, Tab } from "react-bootstrap";

import { Buttons } from "./sections/Buttons";
import { Cards } from "./sections/Cards";
import { Depth } from "./sections/Depth";
import { Forms } from "./sections/Forms";
import { Icons } from "./sections/Icons";
import { Modals } from "./sections/Modals";
import { Spinners } from "./sections/Spinners";
import { Tables } from "./sections/Tables";
import { Toasts } from "./sections/Toasts";
import { Typography } from "./sections/Typography";

import { TSNavbar } from "~common";

import "./Demo.scss";

export const Demo: FunctionComponent = () => {
  const [key, setKey] = useState<any>("buttons");

  return (
    <main className="ts">
      <div className="bg-body min-vh-100 pb-2">
        <TSNavbar />
        <Container fluid>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="first"
            activeKey={key}
            onSelect={k => setKey(k)}
          >
            <Row>
              <Col sm={2}>
                <Nav variant="pills" className="flex-column mt-5">
                  <Nav.Item>
                    <Nav.Link eventKey="buttons">Buttons</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="cards">Cards</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="depth">Depth</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="forms">Forms</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="icons">Icons</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="modals">Modals</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="spinners">Spinners</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tables">Tables</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="toasts">Toasts</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="typography">Typography</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="buttons">
                    <Buttons />
                  </Tab.Pane>
                  <Tab.Pane eventKey="cards">
                    <Cards />
                  </Tab.Pane>
                  <Tab.Pane eventKey="forms">
                    <Forms />
                  </Tab.Pane>
                  <Tab.Pane eventKey="typography">
                    <Typography />
                  </Tab.Pane>
                  <Tab.Pane eventKey="icons">
                    <Icons />
                  </Tab.Pane>
                  <Tab.Pane eventKey="modals">
                    <Modals />
                  </Tab.Pane>
                  <Tab.Pane eventKey="spinners">
                    <Spinners />
                  </Tab.Pane>
                  <Tab.Pane eventKey="tables">
                    <Tables />
                  </Tab.Pane>
                  <Tab.Pane eventKey="depth">
                    <Depth />
                  </Tab.Pane>
                  <Tab.Pane eventKey="toasts">
                    <Toasts />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
      <div id="ts-overlays" className="ts"></div>
    </main>
  );
};
