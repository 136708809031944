import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { Card } from "react-bootstrap";

import clsx from "clsx";
import { DateTime } from "luxon";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { AbsenceRateByStatusTooltip } from "./AbsenceRateByStatusTooltip";
import { AttendanceInsightsInfo } from "./AttendanceInsightsInfo";

import * as api from "~/api";
import { FrameLength, roundPercentForInsights } from "~/lib/attendanceInsights";
import { MutableSessionContext } from "~/lib/context";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelColor, getLabelDisplay } from "~/lib/status";

import "./AbsencesByStatus.scss";

export const AbsencesByStatus: FunctionComponent<
  AbsencesByStatusProps
> = props => {
  const language = useLinguiLanguage();
  const { session } = useContext(MutableSessionContext);
  const [chartData, setChartData] = useState<ChartFrame[] | undefined>();
  const [uniqueLabels, setUniqueLabels] = useState<UniqueLabel[] | undefined>();
  // const [totalEmployees, setTotalEmployees] = useState<number>();

  const { isLoading, frameLength } = props;

  // format data on prop update
  useEffect(() => {
    // const total = props.data?.flatMap(frame => frame.summary?.employeesCount);
    const formattedData = props.data?.map(frame => {
      const startDate = DateTime.fromISO(frame.name);
      const indexTitle =
        frameLength === "month"
          ? startDate.toFormat("MMMM y")
          : startDate.toFormat("MMM d, y");

      return {
        name: indexTitle,
        // estimated percentage
        percent: roundPercentForInsights(
          frame.summary?.absencesPercent ? frame.summary?.absencesPercent : 0
        ),
        ...frame.labels?.reduce(
          (prev, curr) => ({ ...prev, [`${curr.name}_count`]: curr.count }),
          {}
        ),
        ...frame.labels?.reduce(
          (prev, curr) => ({ ...prev, [curr.name]: curr.percent }),
          {}
        )
      };
    });

    // setTotalEmployees(total ? total[0] : 0);
    setChartData(formattedData);
  }, [props.data, frameLength]);

  // set the unique labels
  useEffect(() => {
    const resultLabels: string[] = [];
    props.data?.flatMap(frame =>
      (frame.labels ?? []).flatMap(({ name }) =>
        resultLabels.indexOf(name) === -1 ? resultLabels.push(name) : undefined
      )
    );
    setUniqueLabels(
      resultLabels?.map((label, index) => ({
        label,
        color: getLabelColor(label, session.labels),
        labelDisplay: getLabelDisplay(label, session.labels, language)
      }))
    );
  }, [props.data, session.labels, language]);

  const renderLegend = (): JSX.Element => {
    return (
      <ul className="ts-legend">
        {uniqueLabels?.map((label, i) => (
          <li key={i} className="arbs-status">
            <span
              style={{ backgroundColor: label.color }}
              className={clsx("legend-square")}
            ></span>
            {label.labelDisplay}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Card body={true} className="arbs-card">
      <div className={clsx(isLoading && "opacity-25")}>
        <h1 className="h3 mb-0">Absences by Status</h1>
        <h2 className="h2">
          This is how your absences are broken down by your available statuses.
        </h2>

        {/*{isLoading && <TSCenteredLoadingSpinner />}*/}
        {chartData && chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              className="arbs-chart"
              barSize={16}
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 20,
                bottom: 20,
                right: 20
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                tickSize={20}
                tickMargin={30}
                height={100}
                axisLine={false}
                dataKey="name"
                angle={-62}
              />
              <YAxis
                className="arbs-ticks"
                tickLine={true}
                axisLine={false}
                allowDecimals={true}
                domain={[0, "auto"]}
                tickCount={5}
                unit="%"
              />
              <Tooltip
                content={
                  <AbsenceRateByStatusTooltip frameLength={frameLength} />
                }
              />
              <Legend
                verticalAlign="bottom"
                align="right"
                content={renderLegend}
              />
              {uniqueLabels &&
                uniqueLabels.map((label, index) => {
                  return (
                    <Bar
                      key={label.label}
                      dataKey={label.label}
                      stackId="a"
                      fill={label.color}
                    />
                  );
                })}
              <Bar stackId="a" dataKey="" isAnimationActive={false}>
                <LabelList dataKey="percent" position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <>
            {!isLoading && !chartData && (
              <div className="arbs-no-results">
                <h1>No data to display.</h1>
                <h2>
                  There is no data available that meets your criteria. Tip: Try
                  checking your filters.
                </h2>
              </div>
            )}
          </>
        )}
        <AttendanceInsightsInfo />
      </div>
    </Card>
  );
};

interface AbsencesByStatusProps {
  data: api.Frame[] | undefined;
  isLoading: boolean;
  frameLength?: FrameLength;
}

interface ChartFrame {
  name: string;
  percent: string;

  [key: string]: string;
}

interface UniqueLabel {
  label: string;
  color: string;
  labelDisplay: string;
}
