import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import {
  parseRecipients,
  generateRecipientConfirmationText
} from "~/lib/recipientsUtils";
import {
  ConfirmationModal,
  FieldLabel,
  FullScreenModal,
  GenericErrorText,
  MessageActionButton,
  RecipientsField,
  SelectRecipientsModal
} from "~common";

export const FormsSend: FunctionComponent = () => {
  interface ParamTypes {
    templateId: string;
  }
  const { templateId } = useParams<ParamTypes>();
  const location = useLocation<any>();
  const history = useHistory();
  const { session } = useContext(MutableSessionContext);
  const [groups, setGroups] = useState<api.Group[]>();
  const [saving, setSaving] = useState(false);
  const [showRecipientsModal, setShowRecipientsModal] = useState(false);
  const [recipientsCount, setRecipientsCount] = useState<number>();
  const [allEmployees, setAllEmployees] =
    useState<api.IdentificationEmployee[]>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [messageRecipients, setMessageRecipients] = useState<
    api.MessageRecipient[]
  >([]);
  const [survey, setSurvey] = useState<api.SurveySummary | undefined>(
    location.state?.survey
  );
  const [navigationPushDestination, setNavigationPushDestination] =
    useState<string>();

  const isRecipientsDirty = (): boolean => {
    return messageRecipients?.length > 0;
  };

  const dirty = isRecipientsDirty();

  useEffect(() => {
    if (navigationPushDestination) {
      setNavigationPushDestination(undefined);
      history.push(navigationPushDestination);
      return;
    }
    const unblock = history.block(() => {
      if (dirty) {
        return "Are you sure you want to leave?\n\nYour changes will be discarded.";
      }
    });
    return unblock;
  }, [dirty, history, navigationPushDestination]);

  useEffect(() => {
    const loadGroups = async (): Promise<void> => {
      if (!session.company) {
        return;
      }
      let response: api.APIResponse<api.Group[]> | undefined;
      try {
        response = await api.retrieveGroups(session.company.id);
      } catch {}

      if (response?.ok) {
        setGroups(response.data);
      } else {
        toast.error(<GenericErrorText />);
      }
    };

    loadGroups();
  }, [session.company]);

  useEffect(() => {
    const loadEmployees = async (): Promise<void> => {
      if (!session?.company?.id) {
        return;
      }

      let response:
        | api.APIResponse<api.PaginatedResponse<api.IdentificationEmployee>>
        | undefined;
      try {
        response = await api.getCompanyEmployees(session.company.id, {
          limit: api.MAX_LIMIT
        });
      } catch {}

      if (response?.ok) {
        setAllEmployees(response.data.results);
      } else {
        toast.error(<GenericErrorText />);
      }
    };
    loadEmployees();
  }, [session?.company?.id]);

  useEffect(() => {
    const loadSurveyTemplates = async (): Promise<void> => {
      if (!session.company) {
        return;
      }
      let response: api.APIResponse<api.SurveysSummaryResponse> | undefined;
      try {
        response = await api.retrieveSurveysSummary(session.company.id);
      } catch {}
      if (response?.ok) {
        const surveysSummary = response.data.data;
        setSurvey(surveysSummary?.find(s => s.id === templateId));
      } else {
        toast.error(<GenericErrorText />);
      }
    };
    //We may have been passed the survey name, if we were let's not make an api call.
    if (!survey) {
      loadSurveyTemplates();
    }
  }, [session.company, survey, templateId]);

  useEffect(() => {
    const fetchRecipientsCount = async (): Promise<void> => {
      if (messageRecipients?.length === 0) {
        setRecipientsCount(0);
        return;
      }
      let selectedRecipientsCountResponse:
        | api.APIResponse<api.Count>
        | undefined;

      setRecipientsCount(undefined);
      try {
        selectedRecipientsCountResponse = await api.countMessageRecipients(
          messageRecipients
        );
      } catch {}
      if (selectedRecipientsCountResponse?.ok) {
        setRecipientsCount(selectedRecipientsCountResponse.data.count);
      } else {
        toast.error(<GenericErrorText />);
      }
    };

    fetchRecipientsCount();
  }, [messageRecipients]);

  const handleSendSurvey = useCallback(async (): Promise<void> => {
    if (!session?.company?.id || !messageRecipients?.length || !templateId) {
      return;
    }

    let response: api.APIResponse<{}> | undefined;

    try {
      setSaving(true);
      const sendSurveyInfo: api.SendSurveyInfo = {
        template_id: templateId,
        company_id: session?.company.id,
        recipients_json: messageRecipients
      };
      response = await api.sendSurvey(sendSurveyInfo);
    } catch (error) {}

    setSaving(false);
    if (response?.ok) {
      toast.success("Your form has sent.");
      setNavigationPushDestination(`/dashboard/forms`);
    } else {
      toast.error(<GenericErrorText />);
    }
  }, [messageRecipients, templateId, session]);

  const onSendSurvey = useCallback((): void => {
    setShowConfirmModal(true);
  }, []);
  const parsedRecipients = parseRecipients(
    messageRecipients,
    session,
    groups,
    allEmployees
  );
  return (
    <FullScreenModal title={`Send Form: ${survey?.name}`} loading={saving}>
      <div className="mt-8">
        <FieldLabel>Recipients</FieldLabel>
        <RecipientsField
          recipients={parsedRecipients}
          recipientsCount={recipientsCount}
          onClick={() => setShowRecipientsModal(true)}
        />

        <div className="pt-8 mb-4 flex flex-row justify-end">
          <MessageActionButton
            inline
            className="w-40"
            text="Send now"
            disabled={!messageRecipients?.length}
            theme="green"
            onClick={() => onSendSurvey()}
          />
        </div>
        {showRecipientsModal && (
          <SelectRecipientsModal
            recipients={messageRecipients}
            groups={groups}
            employees={survey?.is_anonymous ? [] : allEmployees}
            onClose={() => setShowRecipientsModal(false)}
            onSave={setMessageRecipients}
            disableEmployeesMessage={
              survey?.is_anonymous
                ? "Cannot choose individual employees for anonymous surveys"
                : undefined
            }
          />
        )}
        {showConfirmModal && (
          <ConfirmationModal
            prompt="Are you sure you want to send the message now?"
            onClose={() => setShowConfirmModal(false)}
            onConfirm={() => handleSendSurvey()}
          >
            <div className="text-hs-dark-green py-3">
              <div>{generateRecipientConfirmationText(parsedRecipients)}</div>
              This action can't be undone.
            </div>
          </ConfirmationModal>
        )}
      </div>
    </FullScreenModal>
  );
};
