import React, { FunctionComponent, ReactNode, useContext } from "react";
import { Route } from "react-router-dom";

import { FullRedirect } from "./FullRedirect";
import { getSafePath } from "./getSafePath";
import { RouteProps } from "./routeProps";

import { EmployeeRole } from "~/api";
import { MutableSessionContext } from "~/lib/context";

/**
 * Guarded version of Route that makes sure a user is logged in and is the
 * administrator of *some* company.
 *
 * We have the following fallback behaviors:
 *
 *  - If the user is staff, they are redirected to /admin/
 *  - If the user is not staff, they are redirect to /403/
 *  - If there is no user, we redirect to login
 */

export const CompanyAdminRoute: FunctionComponent<RouteProps> = ({
  component: Component,
  render,
  ...config
}) => {
  const context = useContext(MutableSessionContext);
  const { session } = context;

  const LoggedInWithBadRoles = (
    <div className="m-8">
      <h1 className="p-8">Unexpected Condition</h1>
      <p className="p-8">
        You appear to be logged in, but wihtout the correct roles to route you
        to the right place. Please contact tech support, or{" "}
        <a className="text-blue-600" href="/logout">
          try logging out and in again
        </a>
        .
      </p>
    </div>
  );

  return (
    <Route
      {...config}
      render={(props): ReactNode => {
        if (
          session.user &&
          session.company &&
          session.employee &&
          (session.employee.role === EmployeeRole.divisionAdmin ||
            session.employee.role === EmployeeRole.companyWideAdmin ||
            session.employee.role === EmployeeRole.teamLeader ||
            session.is_company_admin)
        ) {
          return Component ? <Component {...props} /> : render();
        } else {
          if (session.user) {
            return LoggedInWithBadRoles;
          }

          return (
            <FullRedirect
              to={{
                pathname: "/sign-in",
                search: `?next=${getSafePath()}`
              }}
            />
          );
        }
      }}
    />
  );
};
