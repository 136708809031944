import React, { FunctionComponent, ReactNode } from "react";
import { Container, Navbar } from "react-bootstrap";

import { TSFooter } from "../ts-footer/TSFooter";

import BrandWhite from "~/images/brand-white.svg";
import "./Error.scss";
import Error403Illustration from "~/images/error-403-expired-link-illustration.svg";
import TimerIcon from "~/images/timer-icon.svg";

export const ErrorExpiredLink: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => (
  <div className="page-wrapper">
    <main className="ts">
      <Navbar
        className="ts-navbar"
        bg="secondary"
        collapseOnSelect
        expand="lg"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <img className="teamsense-logo" src={BrandWhite} alt="TeamSense" />
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Container fluid>
        <div className="ts error-page mt-0">
          <div className="px-4">
            <h2 className="my-4">
              Oops, Your Link Has Expired.
              <img
                className="m-auto p-8 d-block d-md-inline"
                src={TimerIcon}
                alt="Timer Icon"
              />
            </h2>
            <img
              className="m-auto"
              src={Error403Illustration}
              alt="403 Page Forbidden"
            />
            {children && <div className="mt-5">{children}</div>}
          </div>
        </div>
      </Container>
    </main>
    <TSFooter />
  </div>
);
