import React, { FC, useState } from "react";
import { Nav, NavLinkProps, NavProps } from "react-bootstrap";
import { Link } from "react-router-dom";

import clsx from "clsx";

import Restricted from "~/ts-components/permissionProvider/Restricted";

import "./SidebarNav.scss";

export const SidebarNav: FC<SidebarNavProps> = props => {
  const { className, links, children, activeKey, onNavClick } = props;
  const [selectedKey, setSelectedKey] = useState<string | null>();

  // Content Fragments
  const navLinkElements =
    links &&
    links.map((linkItem, index) => {
      return (
        <Restricted to={linkItem.restrictedTo} key={index}>
          <Nav.Link
            key={index}
            eventKey={linkItem.eventKey || linkItem.href}
            to={linkItem.href}
            as={Link}
            className={className}
          >
            {linkItem.icon && <i className={linkItem.icon} />}
            <div>{linkItem.label}</div>
          </Nav.Link>
        </Restricted>
      );
    });

  return (
    <Nav
      variant="pills"
      className={clsx("sidebar-nav", className)}
      activeKey={selectedKey || activeKey}
      onSelect={eventKey => {
        setSelectedKey(eventKey);
        onNavClick && onNavClick(eventKey);
      }}
    >
      {children}
      {navLinkElements}
    </Nav>
  );
};

export interface SidebarNavProps extends NavProps {
  links?: SidebarNavItemProps[];
  onNavClick?: Function;
}

export interface SidebarNavItemProps extends NavLinkProps {
  icon?: string;
  label: string;
  href: string;
  restrictedTo: string | string[];
  className?: string;
}
