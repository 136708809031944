import React, { FunctionComponent, ReactNode } from "react";

import clsx from "clsx";

export const TextBubble: FunctionComponent<{
  className?: string;
  children?: ReactNode;
}> = ({ className, children }) => (
  <div>
    <div
      className={clsx("inline-block leading-tight rounded-lg p-3", className)}
    >
      <p className="whitespace-pre-wrap">{children}</p>
    </div>
  </div>
);
