import React from "react";
import { toast } from "react-toastify";

import * as api from "~/api";
import { GenericErrorText } from "~common";

export interface ScheduleChangeArgs {
  employee: api.Employee;
  schedule: api.RecurringSurveyScheduleWriter;
  options?: { token?: string };
  suppressSuccess?: boolean;
}

const handleSurveySettingsStatus = (
  name: string,
  success?: boolean,
  suppressSuccess?: boolean,
  status?: number
): void => {
  if (success) {
    !suppressSuccess &&
      toast.success(`${name}'s survey settings have been updated.`);
  } else if (status === 409) {
    toast.error(
      "We had trouble updating the schedule changes due to a conflict. To edit this employee's notification schedule, reach out to support@teamsense.com."
    );
  } else {
    toast.error(<GenericErrorText />);
  }
};

export const saveScheduleChange = async ({
  employee,
  schedule,
  options,
  suppressSuccess
}: ScheduleChangeArgs): Promise<boolean> => {
  let response;

  try {
    response = await api.setEmployeeRecurringSurveySchedule(
      employee.company_id,
      employee.id,
      schedule,
      options
    );
  } catch {}

  handleSurveySettingsStatus(
    employee.name,
    response?.ok,
    suppressSuccess,
    response?.status
  );

  return Boolean(response?.ok);
};
