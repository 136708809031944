import React, { FunctionComponent, useState } from "react";

import { Link } from "../Link/Link";
import { SearchEmployee } from "../Search/SearchEmployee";

import * as api from "~/api";
import { MessageActionButton, Modal, ModalProps } from "~common";

interface TextScreenerLinkModalProps extends ModalProps {
  onSend?: (employee: api.IdentificationEmployee) => any;
}

export const TextScreenerLinkModal: FunctionComponent<
  TextScreenerLinkModalProps
> = ({ onSend, onClose }) => {
  const [employee, setEmployee] = useState<api.IdentificationEmployee>();

  const onSendClicked = (): void => {
    if (!employee) {
      return;
    }
    onSend?.(employee);
    onClose();
  };

  return (
    <Modal className="max-w-xl p-16 bg-hs-light" onClose={onClose}>
      <div className="font-bold text-4xl mb text-hs-dark-green">
        Screen Employees
      </div>
      <div className="my-2">
        TeamSense Screener functionality enables individuals to screen other
        employees by entering survey responses on their behalf.
      </div>
      <div className="mb-2">
        To authorize an individual to screen others, search for their name and
        click "Send." TeamSense will immediately text or email that individual a
        unique Screener link that will enable them to search for any employee
        and fill out a survey on their behalf. For security purposes, the link
        will expire in 24 hours.
      </div>
      <div>
        For more information, please visit the{" "}
        <Link
          external
          to="https://help.teamsense.com"
          className="text-hs-alt-darker-gray underline"
        >
          TeamSense Help Center
        </Link>
        .
      </div>
      <SearchEmployee
        id="searchEmployee"
        className="w-80 my-8 ts"
        presentation="admin"
        placeholder="Search by employee name or ID"
        onEmployeeSelected={setEmployee}
      />
      <div className="flex justify-end pt-2.5">
        <MessageActionButton
          inline
          className="w-32"
          text="Cancel"
          theme="clear-green"
          onClick={onClose}
        />
        <MessageActionButton
          inline
          disabled={!employee}
          className="w-32"
          text="Send"
          theme="green"
          onClick={onSendClicked}
        />
      </div>
    </Modal>
  );
};
