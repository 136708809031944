import { isStorybook } from "./utils";

export const colors = [
  "primary",
  "secondary",
  "error",
  "info",
  "success",
  "warning"
];

export const cleanMeta = (meta: any): void => {
  if (meta?.component?.__docgenInfo?.props) {
    meta.component.__docgenInfo.props = meta.argTypes;
  }
};

const forcedClassNames = (classNames?: string): string => {
  // Tailwind classes are not getting applied to MUI components
  // on Storybook, so we need to force them with !important
  // until we figure out why.
  if (!classNames) {
    return "";
  }
  if (!isStorybook) {
    return classNames;
  } else {
    return classNames
      ?.split(" ")
      .map(c => `!${c}`)
      .join(" ");
  }
};

export const adaptProps = (props: any): any => {
  const { children, className, ...rest } = props;
  return {
    children,
    className: forcedClassNames(className),
    props: rest
  };
};

export const updateClassNameWithProps = (
  className: string,
  rules: {
    [rule: string]: { true: string; false: string };
  },
  props: { [prop: string]: boolean }
): string => {
  let classNames = className;
  for (const [prop, value] of Object.entries(props)) {
    if (rules[prop]) {
      classNames += ` ${
        rules[prop][String(Boolean(value)) as "true" | "false"]
      }`;
    }
  }
  return classNames;
};
