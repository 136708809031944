import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";

import { DateTime } from "luxon";

import { FormReminders } from "./FormReminders";
import { FormResults } from "./FormResults";
import { FormSchedules } from "./FormSchedules";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { useLinguiLocale } from "~/lib/hooks";
import { SidebarInfoCard, SidebarLayout, ToastContext } from "~common";

export const FormDetails: FC<FormDetailsProps> = params => {
  const { parentUrl } = params;
  const { formId } = useParams<{ formId: string }>();
  const match = useRouteMatch();
  const locale = useLinguiLocale();

  const { session } = useContext(MutableSessionContext);
  const toast = useRef(useContext(ToastContext));

  const [surveySummary, setSurveySummary] = useState<api.SurveySummary>();
  const [pages, setPages] = useState<PageProps[]>([]);

  // Determine which urls to show in the sidebar
  const [reportingReady, setReportingReady] = useState(false);
  const [showReporting, setShowReporting] = useState(false);

  // Form page urls
  const resultsUrl = `${match.url}/results`;
  const scheduleUrl = `${match.url}/schedules`;
  const reminderUrl = `${match.url}/reminders`;
  const defaultUrl = showReporting ? resultsUrl : scheduleUrl;

  // Info card data
  let lastResponseDate: string | undefined;
  let surveyCreationDate: string | undefined;

  try {
    lastResponseDate =
      surveySummary?.latest &&
      DateTime.fromISO(surveySummary.latest)
        .setLocale(locale)
        .toLocaleString(DateTime.DATE_MED);

    surveyCreationDate =
      surveySummary &&
      DateTime.fromISO(surveySummary?.created)
        .setLocale(locale)
        .toLocaleString(DateTime.DATE_MED);
  } catch {
    lastResponseDate =
      surveySummary?.latest &&
      DateTime.fromISO(surveySummary.latest)
        .setLocale("en-US")
        .toLocaleString(DateTime.DATE_MED);

    surveyCreationDate =
      surveySummary &&
      DateTime.fromISO(surveySummary?.created)
        .setLocale("en-US")
        .toLocaleString(DateTime.DATE_MED);
  }
  const isAnonymous = surveySummary?.is_anonymous ? "Yes" : "No";

  // Effects

  useEffect(() => {
    // Check to make sure the form is reportable
    const handleReportingView = (res: Array<string> | undefined): void => {
      const isReportable = res?.includes("reporting");
      if (isReportable) {
        setShowReporting(true);
      }
      setReportingReady(true);
    };

    const loadSurveyStatus = async (): Promise<void> => {
      if (!session.company) {
        return;
      }

      let response: api.APIResponse<api.SurveySummary> | undefined;
      try {
        response = await api.retrieveSurveySummary(session.company.id, formId);
      } catch {}

      if (response?.ok) {
        setSurveySummary(response.data);
        handleReportingView(response.data.behaviors);
      } else {
        toast.current.show({
          variant: "danger",
          message: "An error occurred while retrieving the form details."
        });
      }
    };

    loadSurveyStatus();
  }, [session.company, formId]);

  useEffect(() => {
    // Check if the optional nav items have been determined before setting the pages array.
    // We only want this to happen once, otherwise we have multiple data fetches on page load.
    const thePages = reportingReady && [
      ...(showReporting
        ? [
            {
              label: "Results",
              href: resultsUrl,
              icon: "icon-check",
              restrictedTo: ["FormsTab.READ"],
              render: () => (
                <FormResults
                  formId={formId}
                  isAnonymous={surveySummary?.is_anonymous || false}
                />
              )
            }
          ]
        : []),
      {
        label: "Schedules",
        href: scheduleUrl,
        icon: "icon-calendar",
        restrictedTo: ["FormsTab.READ"],
        render: () => (
          <FormSchedules
            formId={formId}
            isAnonymous={surveySummary?.is_anonymous || false}
            isCovid={surveySummary?.is_covid || false}
          />
        )
      },
      {
        label: "Reminders",
        href: reminderUrl,
        icon: "icon-clock",
        restrictedTo: ["FormReminders.READ"],
        render: () => <FormReminders formId={formId} />
      }
    ];

    // Only set the pages array if it does not exist yet.
    thePages && !pages.length && setPages(thePages);
  }, [
    showReporting,
    surveySummary,
    formId,
    pages,
    locale,
    reminderUrl,
    resultsUrl,
    scheduleUrl,
    reportingReady
  ]);

  // Content Fragments

  const cardContent = (
    <SidebarInfoCard title={surveySummary?.name}>
      <dl>
        <dt className="h6">Responses today:</dt>
        <dd className="h5">{surveySummary?.today}</dd>
        <dt className="h6">Last Response:</dt>
        <dd className="h5">{lastResponseDate}</dd>
        <dt className="h6">Created:</dt>
        <dd className="h5">{surveyCreationDate}</dd>
        <dt className="h6">Anonymous:</dt>
        <dd className="h5">{isAnonymous}</dd>
      </dl>
    </SidebarInfoCard>
  );

  return (
    pages && (
      <SidebarLayout
        parentUrl={parentUrl}
        defaultUrl={defaultUrl}
        pages={pages}
        cardContent={cardContent}
        detailsLinkText="Form Details"
        sidebarLinkText="Forms"
      />
    )
  );
};

interface PageProps {
  label: string;
  href: string;
  icon: string;
  render: () => JSX.Element;
  restrictedTo: string[];
}

interface FormDetailsProps {
  parentUrl: string;
}
