import React, { FunctionComponent } from "react";
import { Card } from "react-bootstrap";

import clsx from "clsx";

import { AttendanceInsightsNoResults } from "./AttendanceNoResults";
import { AttendancePopover } from "./AttendancePopover";
import { AttendanceProgressBar } from "./AttendanceProgressBar";

import * as api from "~/api";

import "./EmployeesWithMostAbsences.scss";

export const EmployeesWithMostAbsences: FunctionComponent<
  EmployeesWithMostAbsencesProps
> = ({ isLoading, data, frameLengthText }) => {
  data?.sort((a, b) => b.absencesCount - a.absencesCount);
  let max = 0;
  data?.forEach(d => {
    max = Math.max(d.absencesCount, max);
  });

  const labelBuilder = (name: string, division: string): JSX.Element => {
    return (
      <div>
        <h6>{name}</h6>
        <p>{division}</p>
      </div>
    );
  };

  const percentBuilder = (percent: number): number => {
    return (percent / max) * 100;
  };

  return (
    <Card className="attendance-insights-card">
      <div className={clsx("p-4 animate-insight", isLoading && "opacity-25")}>
        {data?.length ? (
          <>
            <header>
              <h3>Employees with the Most Absences</h3>
              <h2>
                These employees have logged more absences than other employees.
                Try filtering to see who is out most for each of your company’s
                statuses.
              </h2>
            </header>
            <div>
              {data?.slice(0, 7).map(employee => (
                <AttendanceProgressBar
                  key={employee.id}
                  barColor="#FBDF9D"
                  barHoverColor="#F6B725"
                  value={employee.absencesCount}
                  percentCompleted={percentBuilder(employee.absencesCount)}
                  label={labelBuilder(
                    employee.name,
                    employee.division?.name || ""
                  )}
                  popover={
                    <AttendancePopover
                      title={employee.name}
                      labels={employee.labels}
                      absencesCount={employee.absencesCount}
                      durationText={frameLengthText}
                    />
                  }
                />
              ))}
            </div>
          </>
        ) : (
          <AttendanceInsightsNoResults title="Employees with the Most Absences" />
        )}
      </div>
    </Card>
  );
};

interface EmployeesWithMostAbsencesProps {
  isLoading: boolean;
  data?: api.InsightsEmployee[];
  frameLengthText?: string;
}
