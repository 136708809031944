import React, { useState, useEffect, useCallback, useContext } from "react";
import { useMediaQuery } from "react-responsive";

import { TypeColumn } from "@inovua/reactdatagrid-community/types";

import { MutableSessionContext } from "../context";
import {
  findEventDifferences,
  sortEmployeesWithEvents
} from "../sortEmployees";
import {
  formatISODateToCustomFormat,
  getDateRange,
  getEmployeeSyncStatus,
  getLabelColorDarkened,
  getLabelColorLightened,
  getLabelDisplay
} from "../status";

import { useLinguiLanguage } from "./I18nHooks";
import { useSessionStorageConfig } from "./useSessionStorageConfig";

import * as api from "~/api";
import { AttendanceSyncStatusIcon } from "~/ts-components/attendance/AttendanceSyncStatusIcon";
import { TSPill } from "~common";

interface UseSortProps {
  employees: api.SimpleEmployeeWithEvents[] | undefined;
  selectedEmployeeId: string | undefined;
  onSelectEmployee: (employee: api.SimpleEmployeeWithEvents) => void;
  isFiltering: boolean;
  setIsFiltering: (isFiltering: boolean) => void;
}

interface UseSortReturn {
  selected: string;
  dataSource: api.SimpleEmployeeWithEvents[];
  onSelectionChange: (data: any) => void;
  onSortInfoChange: (value: any) => void;
  columns: TypeColumn[];
  mobileColumns: TypeColumn[];
  isMobile: boolean;
  config: any;
  setSelected: (selected: string) => void;
}

export const useSort = ({
  employees,
  selectedEmployeeId,
  onSelectEmployee,
  isFiltering,
  setIsFiltering
}: UseSortProps): UseSortReturn => {
  const language = useLinguiLanguage();
  const [config, setConfig] = useSessionStorageConfig();
  const { session } = useContext(MutableSessionContext);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1023 });
  const isLarge = useMediaQuery({ minWidth: 1920 });
  const [selected, setSelected] = useState<string>(selectedEmployeeId || "");
  const [dataSource, setDataSource] = useState<api.SimpleEmployeeWithEvents[]>(
    []
  );
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const currentEmployee = dataSource.find(
    employee => employee.id === selectedEmployeeId
  );

  const updatedEmployee = employees?.find(
    employee => employee.id === selectedEmployeeId
  );

  const onSelectionChange = useCallback(
    ({ data }: any) => {
      onSelectEmployee(data as api.SimpleEmployeeWithEvents);
    },
    [onSelectEmployee]
  );

  const onSortInfoChange = (value: any): void => {
    const sortInfo = value?.name ? value : { ...config.sortInfo, dir: 1 };
    setConfig({ ...config, sortInfo });

    if (sortInfo?.name) {
      const sortedData = sortEmployeesWithEvents(
        dataSource,
        sortInfo.name,
        sortInfo.dir,
        session,
        language
      );
      setDataSource(sortedData);
      setIsUpdating(true);
    }
  };

  const columns: TypeColumn[] = [
    {
      name: "syncStatus",
      flex: 0,
      defaultWidth: 80,
      header: (
        <div className="sync-status">
          <AttendanceSyncStatusIcon status="not_synced" />
        </div>
      ),
      resizable: false,
      render: ({ data }: any) => {
        return (
          <div className="sync-status">
            <AttendanceSyncStatusIcon status={getEmployeeSyncStatus(data)} />
          </div>
        );
      }
    },
    {
      name: "given_name",
      header: "First Name",
      resizable: false,
      flex: isLarge ? 1 : 0,
      sortName: "given_name",
      render: ({ data }: any) => {
        return (
          <div className="employee-info">
            <h3>{data.given_name}</h3>
          </div>
        );
      }
    },
    {
      name: "family_name",
      header: "Last Name",
      resizable: false,
      flex: isLarge ? 1 : 0,
      sortName: "family_name",
      render: ({ data }: any) => {
        return (
          <div className="employee-info">
            <h3>{data.family_name}</h3>
          </div>
        );
      }
    },
    {
      name: "status",
      header: "Status",
      defaultWidth: 205,
      resizable: false,
      render: ({ data }: any) =>
        data.events.map(
          (event: {
            id: React.Key | null | undefined;
            label: { name: string };
          }) => (
            <React.Fragment key={`status-${event.id}`}>
              <TSPill
                text={getLabelDisplay(
                  event.label.name,
                  session.labels,
                  language
                )}
                background={getLabelColorLightened(
                  event.label.name,
                  session.labels
                )}
                color={getLabelColorDarkened(event.label.name, session.labels)}
              />
              <br />
            </React.Fragment>
          )
        )
    },
    {
      name: "statusDates",
      header: "Dates",
      flex: isLarge ? 1 : 0,
      resizable: false,
      render: ({ data }: any) => (
        <div className="event-dates">
          {data.events.map(
            (event: {
              id: React.Key | null | undefined;
              started: string;
              ended: string | undefined;
            }) => (
              <span key={`status-date-${event.id}`}>
                {getDateRange(data.timezone, event.started, event.ended, true)}
              </span>
            )
          )}
        </div>
      )
    },
    {
      name: "external_id",
      header: "Employee ID",
      flex: isLarge ? 1 : 0,
      resizable: false,
      sortName: "external_id",
      render: ({ data }: any) => {
        return <span>{data.external_id}</span>;
      }
    },
    {
      name: "created",
      header: "Created",
      flex: isLarge ? 1 : 0,
      resizable: false,
      sortName: "created",
      render: ({ data }: any) => (
        <div className="event-dates">
          {data.events.map(
            (event: { id: React.Key | null | undefined; created: string }) => {
              return (
                <span key={`created-${event.id}`}>
                  {formatISODateToCustomFormat(event.created, data.timezone)}
                </span>
              );
            }
          )}
        </div>
      )
    }
  ];

  const mobileColumns: TypeColumn[] = [
    {
      name: "syncStatus",
      header: null,
      flex: 1,
      resizable: false,
      render: ({ data }: any) => {
        return (
          <div className="employee-info-container">
            <div className="employee-info-container__left">
              <div className="employee-info">
                <div className="sync-status">
                  <AttendanceSyncStatusIcon
                    status={getEmployeeSyncStatus(data)}
                  />
                </div>
                <div>
                  <h3>{data.name}</h3>
                  <div className="event-dates">
                    {data.events.map(
                      (event: {
                        id: React.Key | null | undefined;
                        label: { name: string };
                      }) => (
                        <TSPill
                          key={event.id}
                          text={getLabelDisplay(
                            event.label.name,
                            session.labels,
                            language
                          )}
                          background={getLabelColorLightened(
                            event.label.name,
                            session.labels
                          )}
                          color={getLabelColorDarkened(
                            event.label.name,
                            session.labels
                          )}
                        />
                      )
                    )}
                    <div>
                      {data.events.map(
                        (event: {
                          id: React.Key | null | undefined;
                          started: string;
                          ended: string | undefined;
                        }) => (
                          <span key={event.id}>
                            {getDateRange(
                              data.timezone,
                              event.started,
                              event.ended,
                              true
                            )}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="employee-info-container__right">
              <i className="icon-caret-right" />
            </div>
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    if (!selectedEmployeeId || !employees) {
      return;
    }

    setSelected(selectedEmployeeId);
  }, [selectedEmployeeId, employees]);

  useEffect(() => {
    if (!employees) {
      return;
    }

    if (
      dataSource.length > 0 &&
      selectedEmployeeId &&
      currentEmployee &&
      updatedEmployee
    ) {
      const index = dataSource.findIndex(
        (employee: api.SimpleEmployeeWithEvents) =>
          employee.id === selectedEmployeeId
      );
      const isEventUpdated = findEventDifferences(
        currentEmployee,
        updatedEmployee
      );

      if (isEventUpdated) {
        dataSource[index] = updatedEmployee as api.SimpleEmployeeWithEvents;
        setDataSource([...dataSource]);
      }
    }

    if (isFiltering) {
      const employeesWithEvents = sortEmployeesWithEvents(
        employees,
        config?.sortInfo?.name,
        config?.sortInfo?.dir,
        session,
        language
      );

      setIsFiltering(false);
      setDataSource(employeesWithEvents as api.SimpleEmployeeWithEvents[]);
      setIsUpdating(true);

      if (isTablet) {
        setSelected("");
      }
    }
  }, [
    config,
    currentEmployee,
    dataSource,
    employees,
    isFiltering,
    isTablet,
    language,
    selectedEmployeeId,
    session,
    setConfig,
    setIsFiltering,
    updatedEmployee
  ]);

  useEffect(() => {
    if (isUpdating && !isFiltering && !isTablet) {
      const data = dataSource[0];
      onSelectionChange({ data });
      setIsUpdating(false);
    }
  }, [isUpdating, dataSource, onSelectionChange, isFiltering, isTablet]);

  return {
    selected,
    dataSource,
    onSelectionChange,
    onSortInfoChange,
    setSelected,
    columns,
    mobileColumns,
    isMobile,
    config
  };
};
