import React, { FunctionComponent, ReactNode } from "react";

import { Trans } from "@lingui/react";

import * as api from "~/api";

interface EmployeeHeaderProps {
  employee?: api.Employee;
}

const HeaderCell: FunctionComponent<{ children?: ReactNode }> = ({
  children
}) => (
  <div className="h-8 flex flex-col justify-center">
    <p>{children}</p>
  </div>
);

export const EmployeeHeader: FunctionComponent<EmployeeHeaderProps> = ({
  employee
}) => {
  return (
    <div className="flex flex-col justify-center">
      <div hidden>
        <Trans id="header.employeeId.label">Employee ID:</Trans>
      </div>
      <p className="text-3-5xl font-bold text-hs-dark-green">
        {employee?.name ?? "\ufeff"}
      </p>
      <div className="mt-2.5 w-full flex flex-row">
        <div className="mr-3.5 text-xs font-bold text-hs-medium-dark-gray uppercase">
          <HeaderCell>
            <Trans id="header.employeeId.label">Employee ID:</Trans>
          </HeaderCell>
          <HeaderCell>
            <Trans id="header.manager.label">Manager:</Trans>
          </HeaderCell>
        </div>
        <div className="text-hs-dark-green text-lg font-semibold">
          <HeaderCell>{employee?.external_id}</HeaderCell>
          <HeaderCell>{employee?.team_lead?.name}</HeaderCell>
        </div>
      </div>
    </div>
  );
};
