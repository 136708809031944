import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { merge } from "lodash";
import * as yup from "yup";

import Restricted from "../permissionProvider/Restricted";

import { OrganizationInputs } from "./EditEmployeeOrganizationInputs";
import { RolesPermissionsInputs } from "./EditEmployeeRolesPermissions";

import * as api from "~/api";
import { EmployeeRole, NotificationPreference } from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { TCallResponse, useApi } from "~/lib/hooks/useApi";
import { displayLanguage } from "~/lib/languages";
import { showDivisionsIfCompanyHasDivisions } from "~/lib/showDivisions";
import {
  SelectOptionsProps,
  ToastContext,
  TSButton,
  TSInput,
  TSPhoneInput,
  TSTypeaheadOption
} from "~common";

import "./EditEmployeeModal.scss";

export const EditEmployeeModal: FC<EditEmployeeModalProps> = props => {
  const {
    show,
    modalCancelClickHandler,
    modalSaveClickHandler,
    employee,
    groups,
    disableApi
  } = props;

  const toast = useRef(useContext(ToastContext));
  const { session } = useContext(MutableSessionContext);
  const history = useHistory();
  const languages = session.company?.languages;
  const companyId = session.company?.id ?? "";
  const showDivisions = showDivisionsIfCompanyHasDivisions(session);

  // If the employee had notifications on load, then notification are required on save
  const loadedWithNotifications = Boolean(
    (employee?.phone_number && employee.phone_number.length > 0) ||
      (employee?.notify_email && employee.notify_email.length > 0)
  );

  const [showDeactivateConfirmationModal, setShowDeactivateConfirmationModal] =
    useState(false);

  const [modalShown, setModalShown] = useState(false);
  const [timezones, setTimezones] = useState<DropDownItem[]>();
  const [allGroups, setAllGroups] = useState<api.Group[]>();
  const [allEmployeeRoles, setAllEmployeeRoles] =
    useState<DropDownItemWithDescription[]>();

  // API
  const { call: getTimezones } = useApi<api.Timezones[]>(
    api.timezonesRetrieveUrl(companyId)
  );
  const { call: getAllGroups } = useApi<api.Group[]>(
    api.groupsRetrieveUrl(companyId)
  );
  const { call: getEmployeeRoleOptions } = useApi<api.EmployeeRoleOptions[]>(
    api.employeeRolesRetrieveUrl(companyId, employee?.id)
  );

  // Form Validation
  const isEmailPreference = (value: api.NotificationPreference): boolean =>
    value === api.NotificationPreference.email ||
    value === api.NotificationPreference.both;

  const isEmailRequired = (
    notificationPreference: NotificationPreference,
    role: EmployeeRole | undefined
  ): boolean => {
    const adminRoles = [
      api.EmployeeRole.divisionAdmin,
      api.EmployeeRole.companyWideAdmin,
      api.EmployeeRole.owner
    ];
    return (
      isEmailPreference(notificationPreference) ||
      role === api.EmployeeRole.teamLeader ||
      (role ? adminRoles.includes(role) : false)
    );
  };

  const isSMSPreference = (value: api.NotificationPreference): boolean =>
    value === api.NotificationPreference.sms ||
    value === api.NotificationPreference.both;

  // Schema
  const formSchema = yup.object().shape({
    firstName: yup.string().required(VAL_MESSAGE.Required),
    lastName: yup.string().required(VAL_MESSAGE.Required),
    preferredName: yup.string(),
    timezone: yup.string().required(VAL_MESSAGE.Required),
    language: yup.string().required(VAL_MESSAGE.Required),
    phone: yup.string().when("notificationPreference", {
      is: (notificationPreference: api.NotificationPreference) =>
        isSMSPreference(notificationPreference),
      then: yup
        .string()
        .test("is-phone-valid", VAL_MESSAGE.Phone, () => {
          return phoneIsValid.current;
        })
        .required(VAL_MESSAGE.Required),
      otherwise: yup.string().test("is-phone-valid", VAL_MESSAGE.Phone, () => {
        return phoneIsValid.current;
      })
    }),
    email: yup.string().when("notificationPreference", {
      is: (notificationPreference: api.NotificationPreference) =>
        isEmailRequired(notificationPreference, formData?.role),
      then: yup
        .string()
        .required(VAL_MESSAGE.Required)
        .email(VAL_MESSAGE.Email),
      otherwise: yup.string().email(VAL_MESSAGE.Email)
    }),
    notificationPreference: yup
      .mixed<api.NotificationPreference>()
      .when({
        is: () => notificationPrefsRequired,
        then: yup
          .mixed<api.NotificationPreference>()
          .oneOf(
            [
              api.NotificationPreference.sms,
              api.NotificationPreference.email,
              api.NotificationPreference.both
            ],
            VAL_MESSAGE.Required
          )
      })
      .default(api.NotificationPreference.none),
    externalId: yup
      .string()
      .test("len", VAL_MESSAGE.ExternalIdLength, val => {
        if (!val) {
          return true;
        }
        return !(val.length > 63);
      })
      .required(VAL_MESSAGE.Required),
    divisionId: yup.string().when({
      is: () => showDivisions,
      then: yup.string().required(VAL_MESSAGE.Required)
    }),
    teamLead: yup.mixed<api.TeamLead>(),
    groups: yup.mixed<api.Group[]>(),
    role: yup.mixed<api.EmployeeRole>().required(VAL_MESSAGE.Required),
    alternateFor: yup.mixed<api.TeamLead[]>(),
    allCompany: yup.bool().default(true),
    adminDivisions: yup.mixed<api.Division[]>().when("role", {
      is: (role: api.EmployeeRole) => role === EmployeeRole.divisionAdmin,
      then: yup
        .mixed<api.Division[]>()
        .test(
          "req",
          VAL_MESSAGE.Required,
          () => isAdminRoleDivisionsValid.current
        )
    })
  });

  const isAdminRoleDivisionsValid = useRef(false);
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const [formErrors, setFormErrors] = useState<FormErrors>();
  const [notificationPrefsRequired, setNotificationPrefsRequired] =
    useState(false);
  const phoneIsValid = useRef(true);

  // Utility Methods

  // Check a single input for validity
  const validateInput = (targetInput: string, data: FormData): void => {
    formSchema
      .validateAt(targetInput, data)
      .then(() => {
        // Splice out our error.
        if (formErrors) {
          delete formErrors?.[targetInput];
          setFormErrors({ ...formErrors });
        }
      })
      .catch((e: yup.ValidationError) => {
        if (e.errors) {
          setFormErrors(merge({}, formErrors, { [targetInput]: e.errors[0] }));
        }
      });
  };

  // Check the whole form validity
  const validateForm = async (): Promise<boolean> => {
    let isFormValid = true;
    await formSchema
      .validate(formData, { abortEarly: false })
      .catch((e: yup.ValidationError) => {
        if (e.inner) {
          const errorObj = e.inner.map(p => {
            return { [p.path as string]: e.errors[0] };
          });
          setFormErrors(merge({}, formErrors, ...errorObj));
        }
        isFormValid = false;
      });
    return isFormValid;
  };

  // Update any input's data
  const updateFormData = (id: string, value: string): void => {
    const modifiedFormData = merge({}, formData, { [id]: value });
    setFormData(modifiedFormData);
    validateInput(id, modifiedFormData);
  };

  // Event Handlers

  // change handlers for various input types in the form
  const tsInputChangeHandler = (e: ChangeEvent<HTMLInputElement>): void =>
    updateFormData(e.target.id, e.target.value);

  const phoneChangeHandler = (value: string, isValid: boolean): void => {
    phoneIsValid.current = isValid;
    updateFormData("phone", value);
  };

  const notificationPreferenceChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, checked } = event.target;

    // Pull in the existing selection.
    const previousPref: api.NotificationPreference =
      formData?.notificationPreference || api.NotificationPreference.none;
    let newPref: api.NotificationPreference;

    // If they checked SMS or both.
    if (isSMSPreference(value as api.NotificationPreference)) {
      if (checked) {
        newPref =
          previousPref === api.NotificationPreference.email
            ? api.NotificationPreference.both
            : api.NotificationPreference.sms;
      } else {
        newPref =
          previousPref === api.NotificationPreference.both
            ? api.NotificationPreference.email
            : api.NotificationPreference.none;
      }
    } else if (checked) {
      newPref =
        previousPref === api.NotificationPreference.sms
          ? api.NotificationPreference.both
          : api.NotificationPreference.email;
    } else {
      newPref =
        previousPref === api.NotificationPreference.both
          ? api.NotificationPreference.sms
          : api.NotificationPreference.none;
    }

    const modifiedFormData = merge({}, formData, {
      notificationPreference: newPref
    });

    setFormData(modifiedFormData);
    validateInput("notificationPreference", modifiedFormData);

    // Clear the associated fields validation errors if needed.
    if (!checked && formErrors) {
      if (value === api.NotificationPreference.sms) {
        delete formErrors.phone;
      } else {
        delete formErrors.email;
      }
      setFormErrors(formErrors);
    }
  };

  const typeaheadChangeHandler = <T,>(name: string, value: T): void => {
    const modifiedFormData = merge({}, formData, { [name]: value });

    setFormData(modifiedFormData);
    validateInput(name, modifiedFormData);
  };

  const companyWideAdminHandler = (value: boolean): void => {
    const modifiedFormData = formData;
    modifiedFormData.allCompany = value;

    setFormData(modifiedFormData);
    validateInput("allCompany", modifiedFormData);
  };

  const notificationChangeHandler = (id: string, value: string): void => {
    if (!loadedWithNotifications) {
      const hasContent = Boolean(value.length);
      const otherVal = Boolean(
        id === "email" ? formData.phone?.length : formData.email?.length
      );
      const isRequired = hasContent || otherVal;
      setNotificationPrefsRequired(isRequired);

      // Clear the form error for notification preference if needed.
      if (formErrors?.notificationPreference && !otherVal) {
        delete formErrors.notificationPreference;
        setFormErrors(formErrors);
      }
    }
  };

  // modal state change handlers
  const dialogClosedHandler = (): void => {
    setFormData({} as FormData);
    setFormErrors(undefined);
    setModalShown(false);
  };

  const dialogShowHandler = (): void => {
    setModalShown(true);
  };

  const getAdminDivisions = (
    formData: FormData
  ): api.Division[] | undefined => {
    return formData?.role === EmployeeRole.divisionAdmin
      ? formData?.adminDivisions
      : [];
  };

  // Create or update the employee
  const formSubmissionHandler = async (): Promise<void> => {
    if (session.company === undefined) {
      return;
    }

    // Validate the form to make sure we can save.
    const isValid = await validateForm();
    if (!isValid) {
      return;
    }

    const isNewEmployee = employee?.id === undefined;

    const employeePayload = {
      ...employee,
      given_name: formData?.firstName ?? "",
      family_name: formData?.lastName ?? "",
      preferred_name: formData?.preferredName ?? "",
      notify_email: formData?.email ?? null,
      phone_number: formData?.phone ? `+${formData.phone}` : null,
      division: formData?.divisionId ? { id: formData.divisionId } : undefined,
      timezone: formData?.timezone ?? "",
      preferred_language: formData?.language ?? "",
      notification_preference:
        formData?.notificationPreference ?? api.NotificationPreference.none,
      team_lead: formData?.teamLead ?? null,
      company_id: session.company.id,
      external_id: formData?.externalId ?? "",
      groups: formData?.groups ?? [],
      role: formData?.role,
      alternate_for:
        formData?.role === "team_leader" ? formData?.alternateFor : [],
      admin_divisions: getAdminDivisions(formData)
    };

    const response: TCallResponse<api.Employee> = isNewEmployee
      ? await api.createEmployee(session.company.id, employeePayload)
      : await api.updateEmployee(employeePayload);

    if (response.ok && response.data) {
      let successMessage;
      if (isNewEmployee) {
        const subtext = response.data?.message ?? "";
        successMessage = `${response.data?.name} was created. ${subtext}`;
      } else if (response.data?.message) {
        successMessage = response.data.message;
      } else {
        successMessage = `${response.data?.name} was updated`;
      }

      toast.current.show({
        variant: "success",
        message: successMessage
      });

      if (isNewEmployee) {
        history.push({
          pathname: `/dashboard/employees/employee/${response.data?.id}`,
          state: { afterEmployeeCreate: true }
        });
      } else {
        modalSaveClickHandler && modalSaveClickHandler(response.data);
      }
    } else {
      let errorMsg = "Sorry, an error occurred. Please try again.";
      if (response?.status === 409) {
        errorMsg = response?.error?.email
          ? "Someone else is already using this email. Please Contact TeamSense Support support@teamsense.com"
          : "Someone else is already using this employee id.";
      }

      toast.current.show({
        variant: "danger",
        message: errorMsg
      });
    }
  };

  // Delete the employee
  const confirmDeactivationClickHandler = async (): Promise<void> => {
    if (!session.company || !employee) {
      return;
    }

    const { ok } = await api.deactivateEmployee(
      session.company.id,
      employee.id
    );
    if (ok) {
      toast.current.show({
        variant: "success",
        message: `${employee.name} has been deactivated.`
      });
      history.push("/dashboard/employees");
    } else {
      toast.current.show({
        variant: "danger",
        message: "Sorry, an error occurred. Please try again."
      });
    }
  };

  const loadAllGroups = async (): Promise<void> => {
    if (disableApi) {
      setAllGroups(groups);
    } else {
      const { data } = await getAllGroups();
      data && setAllGroups(data);
    }
  };

  // Effects

  // Trigger data loads
  // Hit APIs for dropdown data and process the results
  useEffect(() => {
    const loadTimeZones = async (): Promise<void> => {
      const { data } = await getTimezones();
      data &&
        setTimezones(
          data.map(tz => {
            return { id: tz.iana, text: tz.tzname };
          })
        );
    };

    loadAllGroups();
    loadTimeZones();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadAllGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, disableApi]);

  // Initialize the form
  useEffect(() => {
    const loadEmployeeRoleOptions = async (): Promise<void> => {
      const { data } = await getEmployeeRoleOptions();
      data &&
        setAllEmployeeRoles(
          data.map(role => {
            return {
              id: role.role,
              text: role.displayText,
              secondaryLabel: role.description,
              disabled: role.disabled
            };
          })
        );
    };

    loadEmployeeRoleOptions();

    if (!employee) {
      return;
    }

    let givenName = employee.given_name;
    let familyName = employee.family_name;
    if (!givenName && !familyName) {
      const [derivedFirstName, ...otherNames] = employee.name.split(" ");
      givenName = derivedFirstName;
      familyName = otherNames.join(" ");
    }
    // set initial form values
    setFormData({
      firstName: givenName,
      lastName: familyName,
      preferredName: employee.preferred_name,
      phone: employee.phone_number ?? "",
      email: employee.notify_email ?? "",
      notificationPreference: employee.notification_preference,
      timezone: employee.timezone,
      language: employee.preferred_language,
      externalId: employee.external_id,
      divisionId: employee.division?.id ?? "",
      teamLead: employee.team_lead,
      groups: employee.groups,
      role: employee.role,
      alternateFor: employee.alternate_for,
      adminDivisions: employee.admin_divisions,
      allCompany: employee.role === EmployeeRole.companyWideAdmin
    });

    // If the employee has previously set a phone or email, then require the pref question.
    setNotificationPrefsRequired(loadedWithNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee, modalShown]);

  // Content Fragments

  const timeZoneOptions = useMemo((): SelectOptionsProps[] => {
    return (
      timezones?.map((item: DropDownItem) => ({
        label: item.text,
        value: item.id
      })) || []
    );
  }, [timezones]);

  const languageOptions = useMemo((): SelectOptionsProps[] => {
    return (
      languages?.map((item: string) => ({
        label: displayLanguage(item, true),
        value: item
      })) || []
    );
  }, [languages]);

  return (
    <>
      <Modal
        container={document.getElementById("ts-modal")}
        show={show}
        onShow={dialogShowHandler}
        onExited={dialogClosedHandler}
        className={showDeactivateConfirmationModal ? "visually-hidden" : ""}
        size="lg"
        centered
        onHide={() => {
          modalCancelClickHandler();
        }}
      >
        <Modal.Header>
          <h1 className="mb-0">
            {employee ? `Edit ${employee.name}` : "Add Employee"}
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="form-columns">
            <small className="text-danger text-uppercase required-header-label">
              * Required Information
            </small>
            <h1>Employee information</h1>
            <Row className="mb-0">
              <TSInput
                groupClass="col-sm-6"
                id="firstName"
                label="First Name"
                type="text"
                placeholder="Enter First Name"
                onChange={tsInputChangeHandler}
                defaultValue={formData?.firstName || ""}
                isInvalid={Boolean(formErrors?.firstName)}
                errorText={formErrors?.firstName}
                required
              />
              <TSInput
                groupClass="col-sm-6"
                id="lastName"
                label="Last Name"
                type="text"
                placeholder="Enter Last Name"
                onChange={tsInputChangeHandler}
                defaultValue={formData?.lastName || ""}
                isInvalid={Boolean(formErrors?.lastName)}
                errorText={formErrors?.lastName}
                required
              />
              <TSInput
                groupClass="col-sm-6"
                id="preferredName"
                label="Preferred Name"
                type="text"
                placeholder="Enter Preferred Name"
                onChange={tsInputChangeHandler}
                defaultValue={formData?.preferredName || ""}
                isInvalid={Boolean(formErrors?.preferredName)}
                errorText={formErrors?.preferredName}
              />
            </Row>
            <Row>
              <TSInput
                groupClass="col-sm-6"
                select
                options={timeZoneOptions}
                id="timezone"
                label="Timezone"
                placeholder="Select Timezone"
                onChange={tsInputChangeHandler}
                defaultValue={formData?.timezone || ""}
                isInvalid={Boolean(formErrors?.timezone)}
                errorText={formErrors?.timezone}
                required
              />
              {languages?.length && (
                <TSInput
                  groupClass="col-sm-6"
                  select
                  options={languageOptions}
                  id="language"
                  label="Language"
                  placeholder="Select Language"
                  onChange={tsInputChangeHandler}
                  defaultValue={formData?.language || ""}
                  isInvalid={Boolean(formErrors?.language)}
                  errorText={formErrors?.language}
                  required
                />
              )}
            </Row>
            <h1>Contact information</h1>
            <Row>
              <div className="col-sm-6">
                <TSPhoneInput
                  id="phone"
                  label="Mobile Number"
                  value={formData?.phone || ""}
                  onChange={(value: string, valid: boolean) => {
                    phoneChangeHandler(value, valid);
                    notificationChangeHandler("phone", value);
                  }}
                  isInvalid={Boolean(formErrors?.phone)}
                  errorText={formErrors?.phone}
                  required={
                    formData && isSMSPreference(formData.notificationPreference)
                  }
                />
                <TSInput
                  id="email"
                  label="Email"
                  type="text"
                  placeholder="Enter Email"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const { id, value } = e.target;
                    updateFormData(id, value);
                    notificationChangeHandler(id, value);
                  }}
                  defaultValue={formData?.email || ""}
                  isInvalid={Boolean(formErrors?.email)}
                  errorText={formErrors?.email}
                  required={
                    formData &&
                    isEmailRequired(
                      formData.notificationPreference,
                      formData?.role
                    )
                  }
                />
              </div>
              <div className="col-sm-6">
                <Form.Label>
                  {notificationPrefsRequired && <span>＊</span>}
                  Notification Preferences
                </Form.Label>
                <Form.Check
                  name="notificationPreference"
                  type="checkbox"
                  id={api.NotificationPreference.sms}
                  checked={
                    formData && isSMSPreference(formData.notificationPreference)
                  }
                  value={api.NotificationPreference.sms}
                  label="Via text message"
                  onChange={notificationPreferenceChangeHandler}
                  className="check-label check-lg"
                  isInvalid={formErrors?.notificationPreference !== undefined}
                  required={notificationPrefsRequired}
                />
                <Form.Check
                  name="notificationPreference"
                  type="checkbox"
                  id={api.NotificationPreference.email}
                  checked={
                    formData &&
                    isEmailPreference(formData.notificationPreference)
                  }
                  value={api.NotificationPreference.email}
                  label="Via email"
                  onChange={notificationPreferenceChangeHandler}
                  className="check-label check-lg"
                  isInvalid={formErrors?.notificationPreference !== undefined}
                  required={notificationPrefsRequired}
                />
                {formErrors?.notificationPreference ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formErrors?.notificationPreference}
                  </Form.Control.Feedback>
                ) : (
                  <></>
                )}
                <p className="text-muted">
                  <small>
                    By providing a phone number you agree that TeamSense may use
                    this phone number to send SMS messages. Message and data
                    rates may apply.
                  </small>
                </p>
              </div>
            </Row>
            <Restricted
              to={["EmployeeCreate.CREATE", "EmployeeOrgInfo.UPDATE"]}
              disabled
            >
              <OrganizationInputs
                session={session}
                formData={formData}
                formErrors={formErrors}
                tsInputChangeHandler={tsInputChangeHandler}
                typeaheadChangeHandler={typeaheadChangeHandler}
                employee={employee}
                showDivisions={showDivisions}
                allGroups={allGroups}
              />
            </Restricted>
            <Restricted
              to={["EmployeeCreate.CREATE", "EmployeeOrgInfo.UPDATE"]}
              disabled
            >
              <RolesPermissionsInputs
                session={session}
                formData={formData}
                formErrors={formErrors}
                typeaheadChangeHandler={typeaheadChangeHandler}
                allEmployeeRoles={allEmployeeRoles}
                showDivisions={showDivisions}
                isAdminRoleDivisionsValid={isAdminRoleDivisionsValid}
                companyWideAdminHandler={companyWideAdminHandler}
              />
            </Restricted>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="edit-employee-modal-footer">
            <div>
              <Restricted to="EmployeeDeactivate.DELETE">
                {employee?.id ? (
                  <TSButton
                    variant="danger"
                    onClick={() => setShowDeactivateConfirmationModal(true)}
                  >
                    Deactivate Employee
                  </TSButton>
                ) : (
                  <></>
                )}
              </Restricted>
            </div>
            <div>
              <TSButton
                variant="link"
                onClick={() => {
                  modalCancelClickHandler();
                }}
                size="lg"
              >
                Cancel
              </TSButton>
              <TSButton
                variant="primary"
                onClick={formSubmissionHandler}
                size="lg"
              >
                Save Changes
              </TSButton>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        className="confirm-delete-modal"
        container={document.getElementById("ts-modal")}
        show={showDeactivateConfirmationModal}
        onHide={() => setShowDeactivateConfirmationModal(false)}
        centered
      >
        <Modal.Body>
          <h1 className="text-danger">
            Deactivate {formData?.firstName} {formData?.lastName}
          </h1>
          <p>
            Deactivating {formData?.firstName} {formData?.lastName} (ID:{" "}
            {formData?.externalId}) will disable their access to TeamSense. All
            their historical data will be preserved. They can be reactivated by
            selecting Show Deactivated Employees.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <TSButton
            variant="link"
            size="lg"
            onClick={() => setShowDeactivateConfirmationModal(false)}
          >
            Cancel
          </TSButton>
          <TSButton
            variant="danger"
            size="lg"
            onClick={() => confirmDeactivationClickHandler()}
          >
            Deactivate
          </TSButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const VAL_MESSAGE = {
  Required: "This field is required",
  Email: "Email address is invalid",
  Phone: "Phone number is invalid",
  ExternalIdLength: "Cannot be longer than 63 characters"
};

export interface FormData {
  firstName: string;
  lastName: string;
  preferredName?: string;
  timezone: string;
  language: string;
  phone?: string;
  email?: string;
  notificationPreference: api.NotificationPreference;
  externalId: string;
  divisionId: string;
  teamLead?: api.TeamLead | null;
  groups?: api.Group[];
  role?: api.EmployeeRole;
  alternateFor?: api.TeamLead[];
  allCompany?: boolean;
  adminDivisions?: api.Division[];
}

export interface TeamLeadSelectItemOption extends TSTypeaheadOption {
  id: string;
}

export type FormErrors = {
  [key: string]: string;
};

type DropDownItem = {
  id: string;
  text: string;
};

interface EditEmployeeModalProps {
  show: boolean;
  employee?: api.Employee;
  modalSaveClickHandler?(employee: api.Employee): void;
  modalCancelClickHandler: Function;
  groups?: api.Group[];
  disableApi?: boolean;
}

export type DropDownItemWithDescription = {
  id: string;
  text: string;
  secondaryLabel: string;
  disabled: boolean;
};
