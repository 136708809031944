import React, { FunctionComponent, useContext, Fragment } from "react";

import * as api from "~/api";
import { Link } from "~/components/Link/Link";
import { MutableSessionContext } from "~/lib/context/";
import { displayPhoneNumber } from "~/lib/phoneNumber";
import { showDivisions } from "~/lib/showDivisions";
import { SidebarInfoCard, TSButton } from "~common";

export interface EmployeeSummaryProps {
  employee?: api.Employee;
  onEdit?: () => any;
  editText?: string;
}

interface SummaryField {
  heading: string;
  value: string | React.ReactNode;
}

export const EmployeeSummary: FunctionComponent<EmployeeSummaryProps> = ({
  employee,
  onEdit,
  editText = "Edit"
}) => {
  const { session } = useContext(MutableSessionContext);

  const emailValue = employee?.notify_email ? (
    <Link
      key="email"
      external
      to={`mailto:${employee?.notify_email}`}
      className="block truncate"
    >
      {employee?.notify_email}
    </Link>
  ) : (
    ""
  );
  const phoneValue = displayPhoneNumber(employee?.phone_number);

  const summaryFields: SummaryField[] = [
    { heading: "Phone", value: phoneValue },
    {
      heading: "Email",
      value: emailValue
    },
    { heading: "Employee ID", value: employee?.external_id },
    ...(employee?.badge_id
      ? [{ heading: "Badge ID", value: employee.badge_id }]
      : []),
    { heading: "Manager", value: employee?.team_lead?.name },
    ...(showDivisions(session)
      ? [{ heading: "Division", value: employee?.division?.name }]
      : []),
    ...[{ heading: "Role", value: employee?.role_label }]
  ];

  return (
    <SidebarInfoCard
      title={employee?.name}
      button={
        <TSButton size="sm" variant="link" onClick={onEdit}>
          {editText}
        </TSButton>
      }
    >
      <dl>
        {summaryFields.map(({ heading, value }, index) => (
          <Fragment key={index}>
            <dt className="h6">{heading}:</dt>
            <dd className="h5">{value}</dd>
          </Fragment>
        ))}
      </dl>
    </SidebarInfoCard>
  );
};
