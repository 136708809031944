import React, { FC, useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { getRoundedPercentage } from "~/lib/stats";

import "./ResultsGauge.scss";

/*
 * This entire component is a bit of a placeholder for now until we wire it up.
 */

class ResultGaugeProp {
  surveysCount?: number;
  surveysCompleted?: number;
}

// set to -202 because that's the dash offset that matches the size of the svg
const DASH_OFFSET = -202;

export const ResultsGauge: FC<ResultGaugeProp> = ({
  surveysCount,
  surveysCompleted
}) => {
  const [dashOffset, setDashOffset] = useState(DASH_OFFSET);
  const [percent, setPercent] = useState<number>();

  useEffect(() => {
    if (surveysCount === undefined || surveysCompleted === undefined) {
      setDashOffset(DASH_OFFSET);
      setPercent(undefined);
      return;
    }
    const percentComplete =
      surveysCount && surveysCompleted ? surveysCompleted / surveysCount : 0;
    // offset wants to happen from a reverse direction
    setDashOffset(DASH_OFFSET * (1 - percentComplete));
    setPercent(getRoundedPercentage(percentComplete));
  }, [surveysCompleted, surveysCount]);

  return (
    <div className="results-gauge">
      <svg
        width="128"
        height="72"
        viewBox="0 0 137 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M132.646 68.0749C132.646 32.7287 103.992 4.07492 68.6455 4.07492C33.2993 4.07492 4.64553 32.7287 4.64553 68.0749"
          stroke="#E4E6E6"
          strokeWidth="8"
          strokeLinecap="round"
        />
        <path
          d="M132.646 68.0749C132.646 32.7287 103.992 4.07492 68.6455 4.07492C33.2993 4.07492 4.64553 32.7287 4.64553 68.0749"
          stroke="#5C8DFF"
          strokeWidth="8"
          strokeLinecap="round"
          className="percent"
          style={{ strokeDashoffset: dashOffset }}
        />
      </svg>
      <h1>{percent === undefined ? <Skeleton width={64} /> : `${percent}%`}</h1>
      <p>
        {percent === undefined ? <Skeleton width={150} /> : "Response Rate"}
      </p>
    </div>
  );
};
