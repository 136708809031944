import React, { FunctionComponent, useState } from "react";

import { EmployeeNotesList } from "./EmployeeNotesList";

import * as api from "~/api";
import { NoteModal } from "~/components/NoteModal/NoteModal";
import { ConfirmationModal, TopButton } from "~common";

interface Props {
  notes: api.Note[];
  employee: api.Employee;
  onCreateNote?: (employee: api.Employee, body: string) => any;
  onUpdateNote?: (employee: api.Employee, body: string, note: api.Note) => any;
  onDeleteNote?: (employee: api.Employee, note: api.Note) => any;
}

export const EmployeeNotes: FunctionComponent<Props> = props => {
  const { notes, employee, onCreateNote, onUpdateNote, onDeleteNote } = props;
  const [noteToEdit, setNoteToEdit] = useState<api.Note | undefined | null>();
  const [deleteModalNote, setDeleteModalNote] = useState<api.Note>();

  const onSaveModal = async (
    employee: api.Employee,
    body: string,
    note?: api.Note
  ): Promise<void> => {
    if (note) {
      const success = await onUpdateNote?.(employee, body, note);
      if (!success) {
        return; // skip closing the modal
      }
    } else {
      onCreateNote?.(employee, body);
    }
    setNoteToEdit(undefined);
  };

  const onClickDeleteFromEdit = (): void => {
    setDeleteModalNote(noteToEdit ?? undefined);
    setNoteToEdit(undefined);
  };

  const onDelete = (): void => {
    if (deleteModalNote) {
      onDeleteNote?.(employee, deleteModalNote);
    }
    setDeleteModalNote(undefined);
  };

  return (
    <div className="mx-10 flex flex-col max-h-full">
      <div className="flex flex-row my-8 justify-end">
        <TopButton title="Add Note" onClick={() => setNoteToEdit(null)} />
      </div>
      <EmployeeNotesList notes={notes} onClick={setNoteToEdit} />
      <div className="z-50">
        {noteToEdit !== undefined && (
          <NoteModal
            employee={employee}
            note={noteToEdit ?? undefined}
            onClose={() => setNoteToEdit(undefined)}
            onSave={onSaveModal}
            onClickDelete={onClickDeleteFromEdit}
          />
        )}
      </div>
      <div className="z-50">
        {deleteModalNote && (
          <ConfirmationModal
            prompt="Delete Note"
            onClose={() => setDeleteModalNote(undefined)}
            confirmText="Delete"
            onConfirm={onDelete}
            dangerous={true}
          >
            <div className="py-3">
              Are you sure you want to permanently delete this note?
            </div>
          </ConfirmationModal>
        )}
      </div>
    </div>
  );
};
