import React, { ChangeEvent, FunctionComponent, useContext } from "react";
import { Form, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";

import { DateTime } from "luxon";

import Restricted from "../permissionProvider/Restricted";

import { EmployeeListHeading } from "./EmployeeListHeading";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import {
  EmployeeDisplayList,
  EmployeeFilterActions,
  FilterListName
} from "~/lib/employeesList";
import { FilterListItem } from "~/lib/filterList";
import { useLinguiLanguage } from "~/lib/hooks";
import { showDivisions } from "~/lib/showDivisions";
import {
  getLabelColor,
  getLabelColorDarkened,
  getLabelColorLightened,
  getLabelDisplay
} from "~/lib/status";
import { InfiniteScrollLoadingSpinner, TSButton, TSPill } from "~common";

import "./SelectableEmployeeList.scss";

const NUM_SKELETON_ROWS = 10;

export const SelectableEmployeeList: FunctionComponent<
  SelectableEmployeeListProps
> = props => {
  const {
    deactivatedMode,
    displayList,
    dispatch,
    groups,
    managers,
    statuses,
    onRequestAllEmployeeIds,
    onChange,
    onEditStatusClick,
    onLoadMoreEmployees,
    onReactivateClick
  } = props;

  const {
    employees,
    selectedEmployeeIds,
    allEmployeeIds,
    loadingEmployeeIds,
    nextUrl,
    loading
  } = displayList;

  const history = useHistory();
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const shouldShowDivisions = showDivisions(session);

  const allSelected =
    !loading &&
    Boolean(allEmployeeIds?.length) &&
    allEmployeeIds?.length === selectedEmployeeIds.length;

  const relativeTime = (input?: string): string => {
    let timeString = "None";
    if (input) {
      const parsed = DateTime.fromISO(input).toRelative();
      timeString = parsed ?? timeString;
    }
    return timeString;
  };

  // Event handlers

  const handleRowClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    employee: api.Employee
  ): void => {
    const { tagName } = event.target as Element;
    if (!deactivatedMode && tagName !== "INPUT") {
      history.push(`/dashboard/new-employees/employee/${employee.id}`);
    }
  };

  const handleToggleEmployee: (
    employee: api.Employee,
    index: number
  ) => (event: ChangeEvent<HTMLInputElement>) => void = (employee, index) => {
    return event => {
      onChange?.(employee, index, event.target.checked);
    };
  };

  const handleToggleAllSelected = (): void => {
    if (!allEmployeeIds) {
      onRequestAllEmployeeIds?.();
      return;
    }
    dispatch({
      type: allSelected ? "selectNone" : "selectAll"
    });
  };

  // Content fragments

  const tableHeader = (
    <tr>
      <Restricted to={["Groups.CREATE", "Groups.UPDATE", "Groups.DELETE"]}>
        <th>
          {!deactivatedMode &&
            (loadingEmployeeIds ? (
              <i className="fas fa-circle-notch fa-spin" />
            ) : (
              <Form.Check
                id="heading-select-all"
                type="checkbox"
                checked={allSelected}
                onChange={handleToggleAllSelected}
              />
            ))}
        </th>
      </Restricted>
      <th>Employee</th>
      <th>
        {!deactivatedMode && (
          <EmployeeListHeading
            name="Status"
            className="mx-1"
            data={statuses}
            dispatch={dispatch}
            list={displayList}
            listName="activeStatuses"
          />
        )}
      </th>
      {shouldShowDivisions && (
        <th>
          <EmployeeListHeading
            name="Division"
            className="mx-1"
            data={session?.divisions ?? []}
            dispatch={dispatch}
            displayNone
            list={displayList}
            listName="activeDivisions"
          />
        </th>
      )}
      <th>
        <EmployeeListHeading
          name="Manager"
          className="mx-1"
          data={managers}
          dispatch={dispatch}
          displayNone
          list={displayList}
          listName="activeManagers"
        />
      </th>
      <Restricted to="Groups.READ">
        <th>
          {!deactivatedMode && (
            <EmployeeListHeading
              name="Groups"
              className="mx-1"
              data={groups}
              dispatch={dispatch}
              displayNone
              list={displayList}
              listName="activeGroups"
            />
          )}
        </th>
      </Restricted>
      {/* empty header for the arrow caret on items below */}
      <th></th>
    </tr>
  );

  const skeletonRows = [...Array(NUM_SKELETON_ROWS)].map((_, index) => {
    return (
      <tr key={`skeletonRow${index}`}>
        <Restricted to={["Groups.CREATE", "Groups.UPDATE", "Groups.DELETE"]}>
          <td>
            {!deactivatedMode && (
              <label key="skeleton-input" className="m-auto leading-3 p-2">
                <input className="form-check-input" type="checkbox" disabled />
              </label>
            )}
          </td>
        </Restricted>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        {shouldShowDivisions && (
          <td>
            <Skeleton />
          </td>
        )}
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton style={{ minWidth: "15vw" }} />
        </td>
        {!deactivatedMode && (
          <td className="h1">
            <i className="icon-caret-right" />
          </td>
        )}
      </tr>
    );
  });

  const employeeRows = employees.map((employee, index) => {
    const selected = selectedEmployeeIds.includes(employee.id);
    const activeLabelKeys = (employee.active_events ?? []).map(
      ({ label }) => label.name
    );
    const labelKeys =
      session.features.full_covid_health_statuses &&
      activeLabelKeys.length === 0
        ? ["unknown"]
        : activeLabelKeys;
    const inputId = `select-employee-${employee.id}`;

    const statusPills = labelKeys.map(key => (
      <Restricted
        to={[
          "EmployeeHealthStatus.READ",
          "EmployeeHealthStatus.UPDATE",
          "EmployeeHealthStatus.CREATE",
          "EmployeeHealthStatus.DELETE"
        ]}
        key={key}
        disabled
      >
        <TSPill
          key={key}
          text={getLabelDisplay(key, session.labels, language)}
          background={getLabelColorLightened(key, session.labels)}
          color={getLabelColorDarkened(key, session.labels)}
          backgroundHover={getLabelColor(key, session.labels)}
          button
          onClick={event => {
            event.stopPropagation();
            onEditStatusClick?.(employee, key);
          }}
        />
      </Restricted>
    ));

    const groupsList =
      employee.groups?.map(group => group.name).join(", ") || "";

    return (
      <tr
        className={selected ? "table-active" : ""}
        key={`employeeRow${index}`}
        onClick={event => {
          handleRowClick(event, employee);
        }}
      >
        <Restricted to={["Groups.CREATE", "Groups.UPDATE", "Groups.DELETE"]}>
          <td>
            {!deactivatedMode && (
              <Form.Check
                key={inputId}
                type="checkbox"
                id={inputId}
                checked={selected}
                onChange={handleToggleEmployee(employee, index)}
              />
            )}
          </td>
        </Restricted>
        <td className="employee-info">
          <h3>{employee.name}</h3>
          <h6>ID: {employee.external_id}</h6>
        </td>
        <td>
          {!deactivatedMode && statusPills}
          {!deactivatedMode && session.features.full_covid_health_statuses && (
            <div className="caption">
              <span className="d-block">
                Scheduled:{" "}
                {relativeTime(employee.health_survey_activity?.last_scheduled)}
              </span>
              <span className="d-block">
                Completed:{" "}
                {relativeTime(employee.health_survey_activity?.last_completed)}
              </span>
            </div>
          )}
          {deactivatedMode && (
            <TSPill
              text="Deactivated"
              background={getLabelColorLightened("unknown", session.labels)}
              color={getLabelColorDarkened("unknown", session.labels)}
            />
          )}
        </td>
        {shouldShowDivisions && (
          <td>
            <h3>{employee.division?.name}</h3>
          </td>
        )}
        <td>
          <h3>{employee.team_lead?.name}</h3>
        </td>
        {deactivatedMode ? (
          <Restricted to="EmployeeReactivate.CREATE">
            <td>
              <TSButton
                variant="link"
                onClick={() => onReactivateClick?.(employee)}
              >
                Reactivate
              </TSButton>
            </td>
          </Restricted>
        ) : (
          <Restricted to="Groups.READ">
            <td>
              {groupsList.length ? (
                <h4>{groupsList}</h4>
              ) : (
                deactivatedMode && (
                  // placeholder while groups resolve
                  <Skeleton style={{ minWidth: "15vw" }} />
                )
              )}
            </td>
          </Restricted>
        )}
        <td className="h1">
          {!deactivatedMode && <i className="icon-caret-right" />}
        </td>
      </tr>
    );
  });

  return (
    <>
      <InfiniteScroll
        dataLength={employees?.length ?? 0}
        hasMore={Boolean(nextUrl)}
        next={onLoadMoreEmployees}
        loader={<InfiniteScrollLoadingSpinner />}
      >
        <Table responsive className="employees-table table-hover">
          <thead>{tableHeader}</thead>
          <tbody>
            {loading && skeletonRows}
            {!loading && employeeRows}
            {/* menus are borked when there are no results but we can fix that when we rebuild the headings */}
            {!loading && employees.length === 0 && (
              <tr>
                <td colSpan={7} className="border-0">
                  <div className="p-4 text-center">
                    <h1>No results found</h1>
                    <h3>
                      We couldn't find any records that met your criteria.
                    </h3>
                    <h4>
                      Tip: Try checking your filters or try a different search.
                    </h4>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </InfiniteScroll>
    </>
  );
};

export interface HeadingProps<T extends FilterListItem> {
  className?: string;
  name: string;
  list: EmployeeDisplayList;
  listName: FilterListName;
  data: Array<T>;
  dispatch: (value: EmployeeFilterActions) => void;
  displayNone?: boolean;
}

interface SelectableEmployeeListProps {
  deactivatedMode: boolean;
  displayList: EmployeeDisplayList;
  dispatch: (value: EmployeeFilterActions) => void;
  groups: api.Group[];
  managers: api.IdentificationEmployee[];
  statuses: FilterListItem[];
  onRequestAllEmployeeIds?: () => any;
  onChange?: (employee: api.Employee, index: number, selected: boolean) => any;
  onEditStatusClick?: (employee: api.Employee, labelKey: api.AnyStatus) => any;
  onLoadMoreEmployees: () => any;
  onReactivateClick?: (employee: api.Employee) => any;
}
