import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import DarkBackArrow from "~/images/dark-back-arrow.svg";
import SmallBackArrow from "~/images/small-back-arrow.svg";

interface Props {
  className?: string;
  textClassName?: string;
  smallArrow?: boolean;
  backUrl?: string;
}

export const BackButton: FunctionComponent<Props> = ({
  className,
  textClassName = "text-hs-dark-green text-base font-semibold",
  smallArrow,
  backUrl
}) => {
  const history = useHistory();
  const onBack = (): void => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  };
  return (
    <div className={clsx("flex flex-row", textClassName, className)}>
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={onBack}
      >
        <img
          className="mr-3"
          src={smallArrow ? SmallBackArrow : DarkBackArrow}
          alt="Back"
        />
        <div className="mt-0.5">
          <Trans id="navigate.back">Back</Trans>
        </div>
      </div>
    </div>
  );
};
