import React, { FunctionComponent } from "react";
import { useMediaQuery } from "react-responsive";

import clsx from "clsx";

import Restricted from "../../permissionProvider/Restricted";

import {
  AlteredEventSyncStatus,
  AttendanceEventHistory,
  Employee
} from "~/api";
import NotSyncedStatusIcon from "~/images/not-event-synced-icon.svg";
import NoteIcon from "~/images/note-icon.svg";
import SyncedStatusIcon from "~/images/synced-status-icon.svg";
import { useLinguiLocale } from "~/lib/hooks";
import { formatISODateToLongFormat } from "~/lib/status";
import { AttendanceSyncStatus } from "~attendance";

export const AttendanceStatus: FunctionComponent<AttendanceStatusProps> = ({
  attendanceEvent,
  automaticSync,
  onEventStatusSync,
  employee
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const locale = useLinguiLocale();

  return (
    <div
      className={clsx(
        "flex-row bg-ts-gray-90 border-1 border-ts-gray-70 rounded mt-3 mb-3",
        {
          "p-3": !isMobile,
          "p-2": isMobile
        }
      )}
    >
      <AttendanceSyncStatus
        event={attendanceEvent.event}
        automaticSync={automaticSync}
        onEventStatusSync={onEventStatusSync}
      />
      {attendanceEvent.event.sync_timestamp &&
      attendanceEvent.event.last_sync_by &&
      attendanceEvent.event.sync_status !== "not_synced" ? (
        <div className="flex-row mt-2">
          <div className="inline-flex text-ts-gray-40 text-sm font-medium">
            <img src={SyncedStatusIcon} alt="Sync status" />
            <div className="ml-2">
              Synced by {attendanceEvent.event.last_sync_by} on{" "}
              <span className="font-bold">
                {formatISODateToLongFormat(
                  attendanceEvent.event.sync_timestamp,
                  employee.timezone,
                  locale
                )}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-row mt-2">
          <div className="inline-flex text-sm font-medium">
            <img src={NotSyncedStatusIcon} alt="Sync status" />
            <div className="ml-2 text-ts-gray-60">Not yet synced</div>
          </div>
        </div>
      )}
      {attendanceEvent.event.note && (
        <Restricted to="EmployeeNotes.READ">
          <div className="flex-row mt-1">
            <div className="inline-flex text-ts-gray-40 text-sm font-medium">
              <img src={NoteIcon} alt="Note" />
              <div className="ml-2">
                Note by {attendanceEvent.event.note.created_by_name}:{" "}
                <span className="font-bold text-ts-gray-20">
                  {attendanceEvent.event.note.body}
                </span>
              </div>
            </div>
          </div>
        </Restricted>
      )}
    </div>
  );
};

interface AttendanceStatusProps {
  automaticSync?: boolean;
  attendanceEvent: AttendanceEventHistory;
  onEventStatusSync: (eventId: string, status: AlteredEventSyncStatus) => void;
  employee: Employee;
}
