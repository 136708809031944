import React, { FunctionComponent } from "react";

import "./AttendanceNoDivisions.scss";
import UnlockInsights from "../../images/unlock-insights.svg";

export const AttendanceInsightsNoDivisions: FunctionComponent<AINRProps> = ({
  title,
  text
}) => {
  return (
    <div className="ainr-no-results">
      <img src={UnlockInsights} alt="" className="unlock-icon" />
      <h1>{title}</h1>
      {text ? (
        <p>{text}</p>
      ) : (
        <p>
          You must have more than one division in TeamSense to view division
          comparisons. Contact customer success to chat about adding divisions
          to your TeamSense account.
        </p>
      )}
    </div>
  );
};

interface AINRProps {
  title: string;
  text?: string;
}
