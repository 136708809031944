import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";

export const NextButtonText: FunctionComponent = () => (
  <Trans id="survey.button.next">Next</Trans>
);

export const CheckInCompleteButtonText: FunctionComponent = () => (
  <Trans id="survey.button.checkInComplete">Check-in Complete</Trans>
);
