import React, { FunctionComponent } from "react";

import Mustache from "mustache";

import * as api from "~/api";
import { MessageResponseField } from "~/components/MessageResponseField/MessageResponseField";
import { TextBubble } from "~/components/TextBubble/TextBubble";
import { useConversationTranslation, useEffectOnce } from "~/lib/hooks";

export interface StringQuestionProps {
  questionMetadata: Pick<
    api.StringQuestion,
    "minLength" | "maxLength" | "hintExample"
  >;
  templateText: string;
  context?: api.TemplateStringContext;
  answer?: api.StringAnswer;
  disabled?: boolean;
  onChange?: (newAnswer: api.StringAnswer, valid: boolean) => any;
}

export const StringQuestion: FunctionComponent<StringQuestionProps> = ({
  questionMetadata,
  answer,
  templateText,
  context,
  disabled,
  onChange
}) => {
  const placeholderText = useConversationTranslation(
    questionMetadata.hintExample
  );

  // Give calling components an opportunity to get initial state.
  useEffectOnce(() => {
    if (disabled) {
      return;
    }
    onChange?.({ kind: "string", value: answer?.value ?? "" }, true);
  });

  return (
    <div className="flex flex-col space-y-3">
      <TextBubble className="bg-gray-100 text-hs-dark">
        {Mustache.render(templateText, context)}
      </TextBubble>
      {disabled && (
        <TextBubble
          className={
            "border border-solid border-hs-green bg-hs-green-20 text-hs-dark text-sm"
          }
        >
          {answer?.value}
        </TextBubble>
      )}
      {!disabled && (
        <MessageResponseField
          placeholder={placeholderText ? placeholderText : undefined}
          value={answer?.value ?? ""}
          onChange={value => onChange?.({ kind: "string", value }, true)}
        />
      )}
    </div>
  );
};
