import React, { ChangeEvent, FunctionComponent } from "react";

import clsx from "clsx";

import "./Dropdown.scss";

export interface DropdownItem {
  id: string;
  text: string;
}

export interface DropdownProps {
  containerClassName?: string;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  items: DropdownItem[];
  currentId?: string;
  onChangeItem: (newItem: DropdownItem) => any;
}

export const Dropdown: FunctionComponent<DropdownProps> = ({
  containerClassName,
  className,
  readOnly,
  items,
  currentId,
  onChangeItem
}) => {
  const currentItem = items.find(({ id }) => id === currentId);

  // Shows empty, selected option if no value (currentItem) is passed in.
  // Once a value is selected it's removed.
  const emptyOption = !currentId && <option value="" disabled />;

  // update selectable options only if items changes.
  const allItemsOptions = items.map(item => (
    <option key={item.id} value={item.id} role="menuitem">
      {item.text}
    </option>
  ));

  // return object from items array based on the item selected
  const selectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const selectedItem =
      items.find(({ id }) => id === event.target.value) || currentItem;
    selectedItem && onChangeItem(selectedItem);
  };

  return (
    <select
      className={clsx("ts-select", containerClassName, className)}
      disabled={readOnly}
      onChange={selectChange}
      value={currentItem?.id ?? ""}
    >
      {emptyOption}
      {allItemsOptions}
    </select>
  );
};
