import React, { FunctionComponent, ReactNode } from "react";

import clsx from "clsx";

interface Props {
  className?: string;
  onClick?: () => any;
  children?: ReactNode;
}
// TODO: There is one instance of this. Replace and standardize.
export const SmallButton: FunctionComponent<Props> = ({
  className,
  onClick,
  children
}) => {
  return (
    <div
      className={clsx(
        "cursor-pointer px-4 py-3-2 border rounded-full",
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
