import React, { FunctionComponent } from "react";
import { Modal } from "react-bootstrap";

import { DateTime } from "luxon";

import * as api from "~/api";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";
import { TSButton } from "~common";

import "./SickEventModal.scss";

export const SickEventModal: FunctionComponent<SickEventModalProps> = ({
  employee,
  event,
  labelInfoMap,
  handleExit,
  show
}) => {
  const formattedDate = (iso: api.ISODateTime): string =>
    DateTime.fromISO(iso).toFormat("MMMM d", { locale: "en-US" });
  const language = useLinguiLanguage();
  const alertStatus = getLabelDisplay("sick", labelInfoMap, language);

  return (
    <Modal
      onHide={handleExit}
      show={show}
      container={document.getElementById("ts-modal")}
      centered
      className="sick-event-modal"
    >
      <Modal.Body>
        <h1 className="text-danger">Alert</h1>
        <p>
          {employee.name} entered {alertStatus} status on{" "}
          {formattedDate(event.started)}.
        </p>

        {event.note && (
          <div className="sick-event-note">{event.note.body} </div>
        )}
        {event.ended && (
          <p>
            {employee.friendly_name} was cleared on {formattedDate(event.ended)}
            .
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <TSButton onClick={handleExit}>Close</TSButton>
      </Modal.Footer>
    </Modal>
  );
};

interface SickEventModalProps {
  employee: api.Employee;
  event: api.EmployeeEvent;
  labelInfoMap: api.LabelInfoMap;
  handleExit: () => any;
  show: boolean;
}
