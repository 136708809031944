import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import * as api from "~/api";
import { displayPhoneNumber } from "~/lib/phoneNumber";
import { Loading, MessageActionButton } from "~common";

interface ConfirmPhoneNumberProps {
  className?: string;
  identifyResponse: api.IdentifyEmployeeResponse;
  requesting: boolean;
  onRequestSurvey: () => any;
  onDone: () => any;
}

export const PhoneNumberUpdated: FunctionComponent<ConfirmPhoneNumberProps> = ({
  className,
  identifyResponse,
  requesting,
  onRequestSurvey,
  onDone
}) => (
  <div
    className={clsx(
      "h-full w-full flex-grow flex flex-col justify-between",
      className
    )}
  >
    <div className="p-9 text-2xl font-bold text-hs-dark-green">
      <p>
        <Trans id="identify.phoneUpdated.summary">
          Great! Your number in TeamSense has been updated to
        </Trans>
      </p>
      <p>{displayPhoneNumber(identifyResponse.inbound_sms_phone_number)}</p>
      <p className="text-base text-hs-darker-gray font-medium mt-8">
        <Trans id="identify.phoneUpdated.note">
          *Don’t forget you may also need to update your number in the HR
          system.
        </Trans>
      </p>
    </div>
    <div className="bg-hs-light p-9 rounded-t-4xl">
      {requesting && <Loading />}

      <div className="pb-3">
        <p className="text-base text-hs-dark-green font-semibold mb-5">
          <Trans id="identify.phoneUpdated.accessSurvey">
            Still need to access your form or survey?
          </Trans>
        </p>
        <MessageActionButton
          theme="dark-green"
          onClick={onRequestSurvey}
          text={
            <Trans id="identify.phoneUpdated.continue">Yes, continue</Trans>
          }
        />
        <div
          className="mt-6 text-center text-lg underline cursor-pointer text-hs-dark-green"
          onClick={onDone}
        >
          <Trans id="identify.phoneUpdated.done">No, I'm done</Trans>
        </div>
      </div>
    </div>
  </div>
);
