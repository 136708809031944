import { useState, useEffect } from "react";

import { DateTime } from "luxon";

interface AppConfig {
  date: DateTime;
  key: string;
  sortInfo: {
    name: string;
    dir: number;
  };
}

export const useSessionStorageConfig = (): any => {
  const initialConfig: AppConfig = {
    date: DateTime.local(),
    key: "",
    sortInfo: { name: "created", dir: 1 }
  };

  const [config, setConfig] = useState<AppConfig>(() => {
    const storedConfig = sessionStorage.getItem("config");
    return storedConfig ? JSON.parse(storedConfig) : initialConfig;
  });

  useEffect(() => {
    sessionStorage.setItem("config", JSON.stringify(config));
  }, [config]);

  return [config, setConfig] as const;
};
