import React, { FunctionComponent, ReactNode, useContext } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";

import { CalendarGrid } from "./CalendarGrid";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelColor, getLabelDisplay } from "~/lib/status";
import { ContentCard } from "~common";

const CalendarLegend: FunctionComponent = () => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const { unknown, ...labelsWithoutUnknown } = session.labels;
  const labelKeysWithoutUnknown = Object.keys(labelsWithoutUnknown);

  return (
    <div className="flex flex-wrap justify-end mr-6 pb-2 w-139">
      {labelKeysWithoutUnknown.map(key => (
        <div key={`status-${key}`} className="flex flex-row items-center">
          <div
            className="w-3.5 h-3.5 mr-1.5 ml-6 rounded-full"
            style={{ backgroundColor: getLabelColor(key, session.labels) }}
          />
          {getLabelDisplay(key, session.labels, language)}
        </div>
      ))}
    </div>
  );
};

interface CalendarMonthProps {
  className?: string;
  monthOfYear: number;
  year: number;
  compactSpacing?: boolean;
  titleClassName?: string;
  gridClassName?: string;
  showLegend?: boolean;
  hoverTooltips?: boolean;
  events?: api.EmployeeEvent[];
  onClickDay?: (day: DateTime, events?: api.EmployeeEvent[]) => any;
  topRightNode?: string | ReactNode;
  timezone: string;
}

export const CalendarMonth: FunctionComponent<CalendarMonthProps> = ({
  className,
  monthOfYear,
  year,
  compactSpacing,
  titleClassName,
  gridClassName,
  showLegend,
  hoverTooltips,
  events,
  onClickDay,
  topRightNode,
  timezone
}) => {
  return (
    <ContentCard outerWrapperClassName={clsx("p-0 pb-8 m-0", className)}>
      <div
        className={clsx(
          "flex flex-row items-center justify-between",
          titleClassName
        )}
      >
        <div className="text-base font-bold">
          {DateTime.local(year, monthOfYear).toFormat("MMMM y", {
            locale: "en-US"
          })}
        </div>
        {topRightNode}
      </div>
      <CalendarGrid
        className={gridClassName}
        monthOfYear={monthOfYear}
        year={year}
        compactSpacing={compactSpacing}
        events={events}
        onClickDay={onClickDay}
        hoverTooltips={hoverTooltips}
        timezone={timezone}
      />
      {showLegend && <CalendarLegend />}
    </ContentCard>
  );
};
