import React, { FunctionComponent } from "react";

import "./AttendanceInsightsInfo.scss";

export const AttendanceInsightsInfo: FunctionComponent = () => {
  return (
    <div className="more-info-container">
      <i className="icon-help more-info"></i>
      <a
        href="https://help.teamsense.com/troubleshooting/attendance-insights-faqs"
        className="d-inline-flex"
      >
        How is this calculated?
      </a>
    </div>
  );
};
