import * as api from "~/api";
export interface Counts {
  [key: string]: number;
}

export const countsByLabel = (statusCounts: api.StatusCounts): Counts => {
  const counts: Counts = {
    ...statusCounts[api.LabelSet.CovidHealthStatus],
    ...statusCounts[api.LabelSet.AttendanceStatus]
  };
  if (statusCounts.unknown !== undefined) {
    counts.unknown = statusCounts.unknown;
  }
  return counts;
};

export const orderedKeysFromStatusCount = (
  statusCounts: api.StatusCounts
): string[] => {
  const keys = [
    ...Object.keys(statusCounts[api.LabelSet.AttendanceStatus]),
    ...Object.keys(statusCounts[api.LabelSet.CovidHealthStatus])
  ];
  if (statusCounts.unknown !== undefined) {
    keys.splice(0, 0, "unknown");
  }
  return keys;
};

export const countsFromEvents = (
  labelInfoMap: api.LabelInfoMap,
  events?: api.EmployeeEventWithEmployee[],
  keysToHide?: string[]
): Counts | undefined => {
  if (!events) {
    return;
  }
  const counts: Counts = {};
  Object.keys(labelInfoMap).forEach(key => {
    if (keysToHide?.includes(key)) {
      return;
    }
    counts[key] = 0;
  });
  events.forEach(event => {
    if (typeof counts[event.label.name] === "number") {
      counts[event.label.name] += 1;
    }
  });
  return counts;
};

export const getRoundedPercentage = (percentage: number): number => {
  // Multiply by 1000, round down, and divide by 10 (e.g. 0.5625 becomes 56.2)
  // This gives us only one digit after the decimal point
  // Rounding down using Math.floor() ensures we don't show 100% prematurely
  return Math.floor(percentage * 1000) / 10;
};
