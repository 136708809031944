import React, { FunctionComponent, useContext } from "react";

import clsx from "clsx";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelColor, getLabelColorDarkened } from "~/lib/status";

export enum EventDay {
  first,
  middle,
  last,
  only,
  none
}

export type StatusEventDayMap = { [K in api.AnyLabelStatus]: EventDay };

interface CalendarRowProps {
  className?: string;
  cellClassName?: string;
  inactiveCellClassName?: string;
  labels: string[];
  compactSpacing?: boolean;
  hoverTooltips?: boolean;
  dateCells?: boolean;
  todayIndex?: number;
  eventCells?: StatusEventDayMap[];
  inactiveCells?: boolean[];
  onClickDayLabel?: (label: string) => any;
}

const DAYS_IN_WEEK = 7;
const days = Array(DAYS_IN_WEEK).fill(undefined);

interface DayInfo {
  eventDay: EventDay;
  onDay: boolean;
  onDayVisible: boolean;
  onDayTodayVisible: boolean;
  firstDayVisible: boolean;
  middleDay: boolean;
  useBackground: boolean;
  lastDayVisible: boolean;
  onlyDayVisible: boolean;
}
type StatusDayInfoMap = { [K in api.AnyLabelStatus]: DayInfo };

const getTooltipText = (
  labelInfoMap: api.LabelInfoMap,
  map: StatusDayInfoMap,
  language: string
): string => {
  const labelNames: string[] = [];
  for (const statusKey in map) {
    if (map[statusKey as api.AnyLabelStatus].onDay) {
      labelNames.push(labelInfoMap[statusKey].display[language]);
    }
  }
  return labelNames.join(",\n");
};

//
// Helper function to find the first color from the StatusDayInfoMap
// from a sorted status list and key from the DayInfo for that status
//
// Also supports a specific logic operation where the key on DayInfo
// must evaluate to TRUE and the notKey on DayInfo must evaluate to FALSE
//

const firstColorFromDayInfoKey = (
  dayInfoMap: StatusDayInfoMap,
  sortedStatuses: api.AnyLabelStatus[],
  colorLookupFunc: (key: string, labelInfoMap: api.LabelInfoMap) => string,
  key: keyof DayInfo,
  labelInfoMap: api.LabelInfoMap,
  notKey?: keyof DayInfo
): string | undefined => {
  for (let i = 0; i < sortedStatuses.length; i++) {
    const status = sortedStatuses[i];
    if (!dayInfoMap[status][key]) {
      continue;
    }
    if (notKey && dayInfoMap[status][notKey]) {
      continue;
    }
    const color = colorLookupFunc(status, labelInfoMap);
    if (color) {
      return color;
    }
  }
};

export const CalendarRow: FunctionComponent<CalendarRowProps> = ({
  className,
  cellClassName,
  inactiveCellClassName,
  labels,
  compactSpacing,
  hoverTooltips,
  dateCells,
  todayIndex,
  eventCells,
  inactiveCells,
  onClickDayLabel
}) => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  return (
    <div className={clsx("flex flex-row justify-between", className)}>
      {days.map((_, i) => {
        const inactiveCell = inactiveCells?.[i];
        const hasDateCells = Boolean(dateCells);
        const isToday = hasDateCells && todayIndex === i;
        const dayInfoArray: DayInfo[] = [];
        // prioritizedDayInfo keeps track of DayInfo flags that have already
        // been used by higher priority events
        const prioritizedDayInfo: Omit<
          DayInfo,
          "eventDay" | "onDayVisible" | "onDayTodayVisible"
        > = {
          onDay: false,
          firstDayVisible: false,
          middleDay: false,
          useBackground: false,
          lastDayVisible: false,
          onlyDayVisible: false
        };
        const { sick, clear, unknown, ...otherLabels } = session.labels;
        // Always place sick first, clear last, and don't include unknown:
        const sortedStatuses = [
          ...(sick ? ["sick"] : []),
          ...Object.keys(otherLabels),
          ...(clear ? ["clear"] : [])
        ];
        sortedStatuses.forEach(eventType => {
          const eventDay = eventCells?.[i][eventType] ?? EventDay.none;
          const onDay = hasDateCells && eventDay !== EventDay.none;
          const middleDay = hasDateCells && eventDay === EventDay.middle;
          const newDayInfo = {
            eventDay,
            onDay,
            onDayVisible: onDay && !prioritizedDayInfo.onDay,
            onDayTodayVisible: isToday && onDay && !prioritizedDayInfo.onDay,
            firstDayVisible:
              hasDateCells &&
              eventDay === EventDay.first &&
              !prioritizedDayInfo.onDay,
            middleDay,
            useBackground:
              ((middleDay && !prioritizedDayInfo.middleDay) ||
                (onDay &&
                  (prioritizedDayInfo.firstDayVisible ||
                    prioritizedDayInfo.lastDayVisible))) &&
              !prioritizedDayInfo.useBackground,
            lastDayVisible:
              hasDateCells &&
              eventDay === EventDay.last &&
              !prioritizedDayInfo.onDay,
            onlyDayVisible:
              hasDateCells &&
              eventDay === EventDay.only &&
              !prioritizedDayInfo.onDay
          };
          dayInfoArray.push(newDayInfo);
          // Update prioritizedDayInfo to reflect what has been used
          prioritizedDayInfo.onDay =
            prioritizedDayInfo.onDay || newDayInfo.onDay;
          prioritizedDayInfo.firstDayVisible =
            prioritizedDayInfo.firstDayVisible || newDayInfo.firstDayVisible;
          prioritizedDayInfo.middleDay =
            prioritizedDayInfo.middleDay || newDayInfo.middleDay;
          prioritizedDayInfo.useBackground =
            prioritizedDayInfo.useBackground || newDayInfo.useBackground;
          prioritizedDayInfo.lastDayVisible =
            prioritizedDayInfo.lastDayVisible || newDayInfo.lastDayVisible;
          prioritizedDayInfo.onlyDayVisible =
            prioritizedDayInfo.onlyDayVisible || newDayInfo.onlyDayVisible;
        });
        const statusDayInfo = sortedStatuses.reduce(
          (object, key) => ({
            ...object,
            [key]: dayInfoArray[sortedStatuses.findIndex(s => s === key)]
          }),
          {}
        ) as StatusDayInfoMap;
        const emptyDay = hasDateCells && !dayInfoArray.find(di => di.onDay);
        const emptyAndToday = emptyDay && isToday;

        const roundDay =
          hasDateCells &&
          (!dayInfoArray.find(di => di.onDay) ||
            dayInfoArray.find(di => di.onlyDayVisible));
        const firstEventDay = dayInfoArray.find(di => di.firstDayVisible);
        const lastEventDay = dayInfoArray.find(di => di.lastDayVisible);

        const onClickCell = (): void => onClickDayLabel?.(labels[i]);
        const tooltipText =
          hoverTooltips && !inactiveCell
            ? getTooltipText(session.labels, statusDayInfo, language)
            : undefined;

        return (
          <div
            className={clsx(
              "flex flex-row",
              compactSpacing ? "w-12" : "w-16",
              !inactiveCell && "cursor-pointer",
              inactiveCell && "cursor-default",
              inactiveCell && inactiveCellClassName
            )}
            style={{
              backgroundColor: firstColorFromDayInfoKey(
                statusDayInfo,
                sortedStatuses,
                getLabelColor,
                "useBackground",
                session.labels
              )
            }}
            key={i}
            onClick={onClickCell}
          >
            <div
              className={compactSpacing ? "w-1-2 h-11" : "w-2 h-12"}
              style={{
                backgroundColor: firstColorFromDayInfoKey(
                  statusDayInfo,
                  sortedStatuses,
                  getLabelColor,
                  "lastDayVisible",
                  session.labels
                )
              }}
            />
            <div
              className={clsx(
                "flex items-center justify-center tooltip",
                compactSpacing ? "w-11 h-11" : "w-12 h-12",
                dateCells && !isToday && "text-black",
                emptyAndToday && "text-white",
                emptyDay && !isToday && "bg-hs-light-gray",
                emptyAndToday && "bg-hs-darker-gray",
                roundDay && "rounded-full",
                firstEventDay && "rounded-l-full",
                lastEventDay && "rounded-r-full",
                cellClassName
              )}
              style={{
                color: firstColorFromDayInfoKey(
                  statusDayInfo,
                  sortedStatuses,
                  getLabelColorDarkened,
                  "onDayTodayVisible",
                  session.labels
                ),
                backgroundColor: firstColorFromDayInfoKey(
                  statusDayInfo,
                  sortedStatuses,
                  getLabelColor,
                  "onDayVisible",
                  session.labels,
                  "middleDay"
                )
              }}
            >
              <div>
                {labels[i]}{" "}
                {tooltipText && (
                  <div className="relative">
                    <span className="tooltip-text pointer-events-none text-sm bg-hs-dark-green text-white rounded w-28 p-4 mt-1 mr-1 max-w-xs">
                      {tooltipText}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div
              className={compactSpacing ? "w-1-2 h-11" : "w-2 h-12"}
              style={{
                backgroundColor: firstColorFromDayInfoKey(
                  statusDayInfo,
                  sortedStatuses,
                  getLabelColor,
                  "firstDayVisible",
                  session.labels
                )
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
