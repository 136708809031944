import humanizeDuration from "humanize-duration";
import { DateTime, Duration } from "luxon";

import * as api from "~/api";

/**
 * Generate a range of floating point values between min and max (inclusive)
 * at a given increment.
 *
 * This is weirder code than you might expect, because the thing you intuitively
 * want this method to do doesn't quite line up with how floating point
 * arithmetic works in practice. This would be cleaner if we used a javascript
 * Decimal arithmetic library; since we don't, we fake it here with the use of
 * toFixed(...) and parseFloat(...).
 */
export const floatRange = (
  min: number,
  max: number,
  increment: number
): number[] => {
  const places = Math.round(1 / (increment * 10));
  const clean = (n: number): number => parseFloat(n.toFixed(places));
  const values: number[] = [];
  let value = min;
  do {
    values.push(value);
    value = clean(value + increment);
  } while (value <= max);
  return values;
};

/** Format an arbitrary range value for a number question with dimension/unit hints. */
export const formatRangeValue = (
  step: api.NumberQuestion,
  optionData: number,
  context: api.TemplateStringContext
): string => {
  switch (step.hint) {
    case "duration":
      return formatDuration(
        optionData,
        step.hintUnit,
        context.employee.preferred_language
      );
    case "temperature":
      return formatTemp(optionData, step.hintUnit);
    default:
      return `${optionData}`;
  }
};

// return human readable time strings
export const formatDuration = (
  value: number,
  unit: string,
  language?: string
): string => {
  // We need to do this map because humanizeDuration requires it
  // TODO: can we standardize on either 4 letter or two letter country codes?
  const languageMap: { [key: string]: string } = {
    "en-US": "en",
    es: "es",
    "vi-VN": "vi",
    "ht-HT": "en", // English: Creole not supported by humanize
    ak: "en", // English: Akan not supported by humanize
    ar: "ar",
    bn: "bn",
    fr: "fr",
    mh: "en", // English: Marshallese not supported by humanize
    my: "en", // English: Burmese not supported by humanize
    "zh-CN": "zh_CN",
    "zh-TW": "zh_TW",
    yue: "en", // English: Cantonese not supported by humanize
    hi: "hi",
    "km-KH": "km",
    ne: "en", // English: Nepalese not supported by humanize
    so: "en", // English: Somali not supported by humanize
    sw: "sw",
    tw: "en", // English: Twi not supported by humanize
    tl: "en" // English: Tagalog not supported by humanize
  };
  // humanizeDuration requires the first parameter (value) to be in milliseconds
  const durationMillis = Duration.fromObject({ [unit]: value }).toMillis();

  return humanizeDuration(durationMillis, {
    // TODO: align the humanize units with the incoming hint unit
    // Currently, when there are 0 days, it shows as 0 seconds
    units: ["w", "d", "h", "m", "s"],
    delimiter: " ",
    language: language && languageMap[language] ? languageMap[language] : "en"
  });
};

export const shortCompletionTimeHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms"
    }
  },
  round: true,
  spacer: "",
  delimiter: " "
});

// Pass in temp and a unit, return formatted number or formatted string with '° C/F' appended
export const formatTemp = (temp: number, unit: string): string => {
  // TODO: The following string should be localized
  return `${temp.toFixed(1)}° ${unit}`;
};

// example: Tue, Sep 14
export const shortDate = (
  iso: api.ISODateTime,
  timeZone?: string
): string | null => {
  if (!iso) {
    return null;
  }

  const dtIso = DateTime.fromISO(iso);
  const zonedIso = timeZone
    ? dtIso.setZone(timeZone).setZone("local", { keepLocalTime: true })
    : dtIso;

  let zonedIsoString = "";
  try {
    zonedIsoString = zonedIso.toLocaleString({
      weekday: "short",
      month: "short",
      day: "2-digit"
    });
  } catch (error) {
    zonedIsoString = zonedIso.toLocaleString({
      weekday: "short",
      month: "short",
      day: "2-digit",
      locale: "en-US"
    });
  }
  return zonedIsoString;
};
