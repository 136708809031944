import React, { ChangeEvent, FC } from "react";
import { Dropdown, Form } from "react-bootstrap";

import clsx from "clsx";

import FilterDropdownActive from "~/images/filter-dropdown-active.svg";
import FilterDropdown from "~/images/filter-dropdown.svg";
import {
  areAllSelected,
  FilterList,
  FilterListItem,
  selectedCount
} from "~/lib/filterList";
import "./TSMultiSelectCheckbox.scss";

export interface TSMultiSelectCheckboxProps {
  titlePrefix: string;
  variant: "dropdown" | "heading";
  displayNone: boolean;
  filterList: FilterList<FilterListItem>;
  onChangeFilterList: (updateList: FilterList<FilterListItem>) => any;
  className?: string;
}

export const TSMultiSelectCheckbox: FC<TSMultiSelectCheckboxProps> = ({
  titlePrefix,
  variant,
  displayNone,
  filterList,
  onChangeFilterList,
  className
}) => {
  const itemArray = Object.values(filterList.items).map(({ item }) => item);
  const allSelected = areAllSelected(filterList, displayNone);
  const noneSelected = displayNone && filterList.displayEmpty;
  const titleSuffix = allSelected
    ? "All"
    : `(${selectedCount(filterList, displayNone)})`;

  const itemsSelected = itemArray.map(
    ({ id }) => filterList.items[id]?.selected
  );
  const checkboxHandler = (id: string, selected: boolean): any => {
    onChangeFilterList({
      ...filterList,
      items: {
        ...filterList.items,
        [id]: {
          ...filterList.items[id],
          selected
        }
      }
    });
  };
  const onSelectAll = (event: ChangeEvent<HTMLInputElement>): any => {
    const { checked: selected } = event.target;
    const items = Object.fromEntries(
      Object.entries(filterList.items).map(([k, item]) => [
        k,
        {
          ...item,
          selected
        }
      ])
    );
    onChangeFilterList({
      items,
      displayEmpty: displayNone && selected
    });
  };

  /* Selecting 'None' in the dropdown with filter results
   * to include entries without categories
   * ex: employees not in a division when it is a division dropdown */
  const onNone = (event: ChangeEvent<HTMLInputElement>): any => {
    const { checked: selected } = event.target;
    onChangeFilterList({
      ...filterList,
      displayEmpty: displayNone && selected
    });
  };

  // an entry and checkbox for each item in the dropdown
  const toggleList = (): JSX.Element => (
    <>
      {itemArray.map(({ name, id }, index) => (
        <Form.Check
          key={id}
          label={name}
          onChange={({ target: { checked } }) => checkboxHandler(id, checked)}
          type="checkbox"
          checked={itemsSelected[index]}
          id={id}
        />
      ))}
    </>
  );

  return (
    <Dropdown
      autoClose="outside"
      className={clsx(className, variant === "dropdown" && "fw-filter")}
    >
      <Dropdown.Toggle
        variant={clsx(variant === "dropdown" && "outline-primary")}
        size={variant === "dropdown" ? "lg" : "sm"}
        className={
          variant === "heading"
            ? clsx(
                "inline-flex justify-between w-full p-0",
                "focus:outline-none",
                "hover:underline",
                "transition ease-in-out duration-150"
              )
            : "flex-fill no-toggle filter-button"
        }
      >
        <span>{`${titlePrefix}: ${titleSuffix}`}</span>
        <img
          className="my-auto ml-1"
          src={allSelected ? FilterDropdown : FilterDropdownActive}
          alt="FilterDropdown"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="table-dropdown border shadow-md w-56">
        <Form.Check
          label="Select All"
          onChange={onSelectAll}
          type="checkbox"
          checked={allSelected}
          id={`${titlePrefix}-all`}
        />
        <hr className="dropdown-break" />
        {toggleList()}
        {displayNone && (
          <>
            <hr className="dropdown-break" />
            <Form.Check
              label="(None)"
              onChange={onNone}
              type="checkbox"
              checked={noneSelected}
              id={`${titlePrefix}-none`}
            />
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
