import React, { FunctionComponent, MouseEvent, ReactNode } from "react";

import clsx from "clsx";
import { isNil } from "lodash";

interface WideRowProps {
  className?: string;

  /** Optional click handler for row */
  onClick?: (e: MouseEvent, index: number, r?: Array<ReactNode>) => void;

  /** data */
  record: Array<ReactNode>;

  index: number;
}

export const WideRow: FunctionComponent<WideRowProps> = props => {
  const { className, onClick, record, index } = props;

  const internalClick = (e: MouseEvent): void => {
    onClick?.(e, index);
  };

  return (
    <tr
      className={clsx(
        "wide-row",
        className,
        "border-b border-gray-200 last:border-b-0",
        { "cursor-pointer": onClick }
      )}
    >
      {record.map((data, i) => (
        <td
          key={`${i}`}
          onClick={internalClick}
          className="p-4 leading-5 text-gray-500"
        >
          {data}
        </td>
      ))}
    </tr>
  );
};

interface WideTableProps {
  className?: string;

  /** Array of headers corresponding to records. */
  headings: Array<ReactNode | undefined>;

  children?: ReactNode;
}

const thClass =
  "px-4 py-3 border-b border-gray-200 bg-gray-50 select-none text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider";
const emptyThClass = "px-6 py-3 border-b border-gray-200 bg-gray-50";

// TODO: loading, empty, error?, self-container fetch?
// TODO: filtering, sorting, etc.?
export const WideTable: FunctionComponent<WideTableProps> = props => {
  const { className, headings } = props;

  const getThClass = (empty: boolean): string => {
    return empty ? emptyThClass : thClass;
  };

  return (
    <div className={clsx("wide-table", className, "flex flex-col")}>
      <div className="align-middle inline-block min-w-full">
        <table className="min-w-full">
          {headings && (
            <thead className="sticky top-16">
              <tr>
                {headings.map((th, i) => (
                  <th className={getThClass(isNil(th))} key={i}>
                    {th ?? ""}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody className="bg-white">{props.children}</tbody>
        </table>
      </div>
    </div>
  );
};
