import React, {
  FunctionComponent,
  MouseEvent,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import { SurveyDropdown } from "~/components/SurveyDropdown/SurveyDropdown";
import { WideRow, WideTable } from "~/components/WideTable/WideTable";
import { MutableSessionContext } from "~/lib/context/";
import { processConversationTranslation, useLinguiLanguage } from "~/lib/hooks";
import { shortDate } from "~/lib/ranges";
import { getLabelColor, getLabelDisplay } from "~/lib/status";
import { convertAnswerToString } from "~/lib/surveyUtils";
import {
  ContentCard,
  GenericErrorText,
  Loading,
  StatusLabel,
  TopButton
} from "~common";

const formattedTime = (iso: api.ISODateTime): string =>
  DateTime.fromISO(iso).toFormat("t", { locale: "en-US" });

interface EmployeeConversationHistoryProps {
  className?: string;
  employee: api.Employee;
  showEditRecurringSurvey?: boolean;
  recurringSurveyTemplateId?: string;
  onRowClick?: (employee: api.Employee, e: MouseEvent) => void;
}

const NoWrap: FunctionComponent<{ children?: ReactNode }> = ({ children }) => (
  <span className="whitespace-no-wrap">{children}</span>
);

export const EmployeeConversationHistory: FunctionComponent<
  EmployeeConversationHistoryProps
> = props => {
  const {
    className,
    employee,
    showEditRecurringSurvey,
    recurringSurveyTemplateId
  } = props;
  const history = useHistory();
  const language = useLinguiLanguage();
  const { session } = useContext(MutableSessionContext);
  const [surveyStates, setSurveyStates] = useState<api.SurveyState[]>();
  const [surveyTemplates, setSurveyTemplates] =
    useState<api.SurveyTemplate[]>();
  const [surveyTemplateId, setSurveyTemplateId] = useState<string>();

  const statusString = (
    updated: api.ISODateTime,
    completed?: api.ISODateTime,
    started?: api.ISODateTime
  ): string => {
    if (completed) {
      return `Completed at ${formattedTime(completed ?? updated)}`;
    }
    if (started) {
      return `Interaction at ${formattedTime(updated)}`;
    }
    return "Unopened";
  };

  useEffect(() => {
    const loadSurveyTemplates = async (): Promise<void> => {
      let response: api.APIResponse<api.SurveyTemplate[]> | undefined;
      try {
        response = await api.retrieveSurveyTemplates(
          employee.company_id,
          employee.id
        );
      } catch {}
      if (response?.ok) {
        setSurveyTemplates(response.data);
        setSurveyTemplateId(response.data?.[0].id);
      } else {
        toast.error(<GenericErrorText />);
      }
    };
    loadSurveyTemplates();
  }, [employee.company_id, employee.id]);

  useEffect(() => {
    const loadEmployeeConversationHistory = async (): Promise<void> => {
      if (!session.company || !surveyTemplateId) {
        return;
      }
      setSurveyStates(undefined);
      let response:
        | api.APIResponse<api.PaginatedResponse<api.SurveyState>>
        | undefined;
      try {
        response = await api.retrieveEmployeeSurveyHistory(
          session.company.id,
          employee.id,
          {
            surveyTemplateId,
            limit: api.MAX_LIMIT
          }
        );
      } catch {}

      if (response?.ok) {
        setSurveyStates(response.data.results);
      } else {
        toast.error(<GenericErrorText />);
      }
    };
    loadEmployeeConversationHistory();
  }, [surveyTemplateId, employee.id, session.company]);

  // Choose *some* consistent order for our answer columns. It's possible that older conversations
  // had different questions, so we have to accumulate our question names across the full history.
  const questionMap: { [k: string]: string } = {};
  (surveyStates ?? []).forEach(({ history }) => {
    history.forEach(({ name, step, answer }) => {
      if (!("prompts" in step) || !answer) {
        return;
      }
      const numSteps = step.prompts.length;
      if (numSteps > 0) {
        questionMap[name] = processConversationTranslation(
          language,
          step.prompts[numSteps - 1]
        );
      }
    });
  });

  /** An ordered collection of questions whose answers we'd like to show. */
  const questionNames = Object.keys(questionMap);
  const translatedQuestions = questionNames.map(name => questionMap[name]);
  const uniqueTranslatedQuestions = Array.from(new Set(translatedQuestions));
  const translatedQuestionInfo = uniqueTranslatedQuestions.map(
    translatedQuestion => ({
      translatedQuestion,
      questionNames: questionNames.filter(
        questionName => questionMap[questionName] === translatedQuestion
      )
    })
  );

  // TODO span 2 cols?
  /** Table headings */
  const headings = [
    "Date",
    "Survey Result",
    "Last Seen",
    "Delivery Time",
    ...uniqueTranslatedQuestions
  ].map((text, index) => (
    <div key={index} className="line-clamp-6">
      {text}
    </div>
  ));

  /** Render answers for all columns in a single row. */
  const renderAnswerColumns = (
    history: api.SurveyState["history"]
  ): ReactElement[] =>
    translatedQuestionInfo.map(({ questionNames }, index) => {
      // NOTE: We are assuming that all questions that share the same translated "prompt"
      // should be combined into a single column, as typically only one will have been
      // answered. If we find in the future that we have authored surveys where we ask
      // multiple questions with the same prompt, we'll need to revisit this.
      return (
        <>
          {questionNames.map(questionName => {
            const { answer, step } =
              history.find(
                ({ name: entryName }) => entryName === questionName
              ) || {};
            if (answer && step) {
              return convertAnswerToString(
                step,
                answer,
                language,
                employee?.timezone
              );
            } else {
              return null;
            }
          })}
        </>
      );
    });

  /** Build wide table records for each historical conversation */
  const records = (surveyStates ?? []).map(
    ({ history, outcome, created, completed, updated, started }) => {
      const { employeeEvents: events } = outcome || { employeeEvents: [] };

      return [
        <NoWrap key={`time_${employee.id}-${created}`}>{`${shortDate(
          created
        )}`}</NoWrap>,
        <div
          key={`primary-labels-${employee.id}-${created}`}
          className="space-y-1"
        >
          {events.map(({ label }) => (
            <StatusLabel
              displayText={getLabelDisplay(
                label.name,
                session.labels,
                language
              )}
              color={getLabelColor(label.name, session.labels)}
              key={label.name}
            />
          ))}
        </div>,
        statusString(updated, completed, started),
        `Delivered at ${formattedTime(created)}`,
        ...renderAnswerColumns(history)
      ];
    }
  );

  const onEditSurveySchedule = (): void => {
    history.push(`/dashboard/employees/employee/${employee.id}/schedule`);
  };

  const getSurveyNameByTemplateId = (surveyTemplateId: string): any => {
    return surveyTemplates?.find(({ id }) => id === surveyTemplateId)?.name;
  };
  const recurringSurveyName =
    recurringSurveyTemplateId &&
    getSurveyNameByTemplateId(recurringSurveyTemplateId);

  const editSurveyLabel = recurringSurveyName
    ? `Edit Survey Settings: ${recurringSurveyName}`
    : "Edit Survey Settings";

  const surveyName =
    surveyTemplateId && getSurveyNameByTemplateId(surveyTemplateId);
  const messageEmptySurvey = `No "${surveyName}" responses found for ${employee.name}`;

  return (
    <div className="mx-10 flex flex-col max-h-full">
      <div className="flex flex-row justify-between my-8">
        <SurveyDropdown
          containerClassName="max-w-xs"
          className="bg-white text-base text-hs-dark-green border border-hs-dark-gray rounded-md"
          surveyTemplates={surveyTemplates ?? []}
          currentId={surveyTemplateId}
          onChange={setSurveyTemplateId}
        />
        {showEditRecurringSurvey && (
          <TopButton
            title={editSurveyLabel}
            onClick={() => onEditSurveySchedule()}
          />
        )}
      </div>
      {!surveyStates && <Loading />}
      {surveyStates && (
        <ContentCard outerWrapperClassName="p-0 mt-0 mb-8 h-full max-h-full w-full relative">
          <WideTable
            headings={headings}
            className={clsx("max-w-full fs-exclude", className)}
          >
            {records.map((record, index) => (
              <WideRow
                key={`wide-row-${index}`}
                index={index}
                record={record}
              />
            ))}
          </WideTable>
          {records.length === 0 && (
            <div className="my-20 col-span-32">
              <div className="mb-2.5 text-xl font-semibold text-center">
                {messageEmptySurvey}
              </div>
            </div>
          )}
        </ContentCard>
      )}
    </div>
  );
};
