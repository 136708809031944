const formats = {
  dateTime12HourWithTimeZone: (date: Date): string =>
    // MM/dd h:mma zzz
    // EG: 01/01 12:00am EST
    // EG: 12/31 11:59pm PT
    // EG: 03/25 3:47pm UTC
    // EG: 11/02 11:04am GMT-5
    new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short"
    })
      .format(date)
      .replace(/,/g, "")
      .replace(/\sam/gi, "am")
      .replace(/\spm/gi, "pm"),
  dateTime12HourWithAtAndTimeZone: (date: Date): string =>
    // MM/dd 'at' h:mma zzz
    // EG: 01/01 at 12:00am EST
    // EG: 12/31 at 11:59pm PT
    // EG: 03/25 at 3:47pm UTC
    // EG: 11/02 at 11:04am GMT-5
    new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short"
    })
      .format(date)
      .replace(/,/g, " at")
      .replace(/\sam/gi, "am")
      .replace(/\spm/gi, "pm"),
  default: (date: Date): string =>
    // MM/dd/yyyy
    // EG: 01/01/2025
    // EG: 12/31/2027
    // EG: 03/25/2030
    // EG: 11/02/1997
    new Intl.DateTimeFormat("en", {}).format(date).replace(/,/g, "")
};

export const enum DateFormat {
  dateTime12HourWithTimeZone = "dateTime12HourWithTimeZone",
  dateTime12HourWithAtAndTimeZone = "dateTime12HourWithAtAndTimeZone",
  default = "default"
}

export const getFormattedDate = (
  date: string | number | Date,
  format: keyof typeof formats
): string => {
  if (!date) {
    return "";
  }
  const dateObj =
    typeof date === "string" || typeof date === "number"
      ? new Date(date)
      : date;
  return (formats[format] || formats.default)(dateObj);
};

// To generate the examples please use the following dates:
// new Date(2025, 0, 1, 0, 0, 0)
// new Date(2027, 11, 31, 23, 59, 0)
// new Date(2030, 2, 25, 15, 47, 0)
// new Date(1997, 10, 2, 11, 4, 0)
