import React, {
  createContext,
  useState,
  FunctionComponent,
  useCallback,
  useMemo
} from "react";
import { ToastContainerProps, ToastContainer } from "react-bootstrap";

import { TSToastProps, TSToast } from "./TSToast";

export const TSToastProvider: FunctionComponent<TSToastContainerProps> = ({
  position,
  className,
  pauseOnFocusLoss,
  autoClose,
  closeOnClick,
  pauseOnHover,
  children
}) => {
  const [toasts, setToasts] = useState<TSToastProps[]>([]);

  const removeToast = (id: string): void => {
    setToasts(toasts => toasts.filter(e => e.id !== id));
  };

  const addToast = useCallback(
    (toastProps: TSToastProps): void => {
      toastProps.id = Math.random().toString();

      // Let the container delay pass through to the child if needed.
      if (toastProps.delay === undefined && autoClose) {
        toastProps.delay = autoClose;
      }

      // If the child has no autohide set but we have a global delay, then set it.
      if (toastProps.autohide === undefined && autoClose) {
        toastProps.autohide = true;
      }

      // Pull in the container closeOnClick if our toast doesn't have it set.
      if (toastProps.closeOnClick === undefined && closeOnClick) {
        toastProps.closeOnClick = closeOnClick;
      }

      // Pull in the container pauseOnHover if our toast doesn't have it set.
      if (toastProps.pauseOnHover === undefined && pauseOnHover) {
        toastProps.pauseOnHover = pauseOnHover;
      }

      setToasts(toasts => [...toasts, toastProps]);
    },
    [autoClose, closeOnClick, pauseOnHover]
  );

  const value = useMemo(() => ({ show: addToast }), [addToast]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      {/* This div is temporary and only needed for namespacing. */}
      <div className="ts">
        <ToastContainer position={position} className={className}>
          {toasts.map(toastProps => (
            <TSToast
              key={toastProps.id}
              {...toastProps}
              pauseOnFocusLoss={pauseOnFocusLoss}
              removeToast={removeToast}
            />
          ))}
        </ToastContainer>
      </div>
    </ToastContext.Provider>
  );
};

export const ToastContext = createContext<ToastContextProps>({
  show: () => ({})
});

export interface TSToastContainerProps extends ToastContainerProps {
  pauseOnFocusLoss?: boolean;
  autoClose?: number;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
}

interface ToastContextProps {
  show: (toastProps: TSToastProps) => void;
}
