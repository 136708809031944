import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState
} from "react";
import { Toast, ToastProps } from "react-bootstrap";

import "./TSToast.scss";

export const TSToast: FunctionComponent<TSToastProps> = props => {
  const {
    variant,
    message,
    delay,
    id,
    removeToast,
    closeOnClick,
    autohide,
    pauseOnFocusLoss,
    closeButton,
    pauseOnHover
  } = props;

  const [show, setShow] = useState(true);
  const [autoHideState, setAutoHideState] = useState(false);

  // Event Handlers
  const blurHandler = (): void => {
    setAutoHideState(false);
  };

  const focusHandler = (): void => {
    setAutoHideState(true);
  };

  const handleClose = (): void => {
    setShow(false);
    removeToast && removeToast(id);
  };

  // Hooks
  useEffect(() => {
    if (autohide) {
      setAutoHideState(true);

      if (pauseOnFocusLoss) {
        if (!document.hasFocus()) {
          setAutoHideState(false);
          return;
        }
        window.addEventListener("blur", blurHandler);
        window.addEventListener("focus", focusHandler);
      }
    }

    return () => {
      window.removeEventListener("blur", blurHandler);
      window.removeEventListener("focus", focusHandler);
    };
  }, [autohide, pauseOnFocusLoss, setAutoHideState]);

  return (
    <Toast
      show={show}
      bg={variant ?? "primary"}
      delay={delay}
      onClose={handleClose}
      autohide={autoHideState}
      onClick={() => {
        closeOnClick && handleClose();
      }}
      onMouseEnter={() => {
        autohide && pauseOnHover && setAutoHideState(false);
      }}
      onMouseLeave={() => {
        autohide && pauseOnHover && setAutoHideState(true);
      }}
    >
      <Toast.Body>
        {closeButton && <i className="icon-close"></i>}
        {message}
      </Toast.Body>
    </Toast>
  );
};

export interface TSToastProps extends ToastProps {
  message: string | ReactNode;
  closeOnClick?: boolean;
  removeToast?: Function;
  pauseOnFocusLoss?: boolean;
  pauseOnHover?: boolean;
  closeButton?: boolean;
  variant?: ToastProps["bg"];
}
