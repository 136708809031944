import React, { FunctionComponent, useContext } from "react";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelColor, getLabelDisplay } from "~/lib/status";

export const AttendancePopover: FunctionComponent<
  SingleServingPopoverProps
> = ({ title, absenceRate, absencesCount, labels, durationText }) => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  return (
    <div className="insights-popover">
      <header className="mb-3">
        {title && <h6 className="mb-0">{title}</h6>}
        {absenceRate && <p className="mb-0">{absenceRate}</p>}
        <p className="mb-0">
          {absencesCount} Absence{absencesCount !== 1 && "s"}
        </p>
        <p>{durationText}</p>
      </header>
      {labels &&
        labels
          .sort((a, b) => b.count - a.count)
          .filter(label => label.count > 0)
          .map(label => {
            const { name } = label;
            return (
              <div
                key={label.name}
                className="label-bar-insights d-flex justify-content-between"
              >
                <div className="d-flex align-items-center mr-4">
                  <span
                    key={label.name}
                    style={{
                      backgroundColor: getLabelColor(name, session.labels)
                    }}
                    className="legend-square mr-1"
                  ></span>
                  <span className="label-name">
                    {getLabelDisplay(label.name, session.labels, language)}
                  </span>
                </div>
                <div>
                  <strong>{label.count}</strong>
                </div>
              </div>
            );
          })}
    </div>
  );
};

interface SingleServingPopoverProps {
  title?: string;
  absencesCount: number;
  labels?: api.InsightsLabel[] | Array<{ name: string; count: number }>;
  durationText?: string;
  absenceRate?: string;
}
