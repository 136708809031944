import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
  ChangeEvent
} from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { displayLanguage } from "~/lib/languages";
import { showDivisions, singleDivisionId } from "~/lib/showDivisions";
import { TSButton, ModalProps, SelectOptionsProps, TSInput } from "~common";

import "./PortalEditLinkModal.scss";

interface PortalEditLinkModalProps extends ModalProps {
  portalLink?: api.AdminPortalLink | null;
  onConfirm: (portalLink: api.PortalLink | api.AdminPortalLinkWriter) => any;
  onDeleteLink: (portalLink: api.AdminPortalLink) => any;
}

export const PortalEditLinkModal: FunctionComponent<
  PortalEditLinkModalProps
> = ({ portalLink, onClose, onConfirm, onDeleteLink }) => {
  const { session } = useContext(MutableSessionContext);
  const { languages = [] } = session.company ?? {};
  const multiLanguage = languages.length > 1;
  //We only show division dropdown if there is more than one division
  const showDivisionDropDown = showDivisions(session);
  //We only show all company if the user is an all company admin
  //and there is more than one division
  const showAllCompanyOption = session.is_company_admin && showDivisionDropDown;
  const canModify =
    !portalLink ||
    session.is_company_admin ||
    (session.divisions ?? [])
      .map(({ id }) => id)
      .includes(portalLink.division_id);

  const [text, setText] = useState<api.StringTranslations | undefined>(
    portalLink?.text
  );
  const [url, setUrl] = useState(portalLink?.url);
  //if a link was clicked then set division appropriately
  //if divisions dropdown is hidden then default to users division
  const [divisionId, setDivisionId] = useState<string | undefined>(
    portalLink?.division_id ??
      (showDivisionDropDown ? undefined : singleDivisionId(session))
  );
  //if a link was clicked then set allCompany appropiately
  //if a user is a company admin with only a single division
  //or the division portal feature is not active
  //or for all company admins (if not editing)
  //then we default allCompany to true
  const [allCompany, setAllCompany] = useState(
    portalLink?.all_company ||
      (session.is_company_admin && (!showDivisions(session) || !portalLink))
  );
  const [allowedDivisions, setAllowedDivisions] = useState<
    SelectOptionsProps[] | null
  >();

  const { id } = portalLink ?? {};
  const topRightLinkText = id && canModify ? "Delete link" : undefined;

  const handleToggleAllCompany = (): void => {
    setAllCompany(!allCompany);
    //If all company is checked we need set division dropdown to none
    if (!allCompany) {
      setDivisionId(undefined);
    }
  };

  const handleDivisionOnChange = (id: string): void => {
    setDivisionId(id);
    if (id) {
      setAllCompany(false);
    }
  };

  const validateCanSave = (): boolean => {
    //if user cant modify (and is not creating a new portal link)
    if (!canModify) {
      return false;
    }
    //if required data is missing
    if (
      (url?.length ?? 0) === 0 ||
      !text ||
      !languages.find(language => text[language]?.length > 0)
    ) {
      return false;
    }
    //All company or Division must be selected
    //unless divison is not visible then we will be using the user division
    if (showDivisionDropDown && !allCompany && !divisionId) {
      return false;
    }

    return true;
  };

  const onTextChange = (language: string, value: string): void => {
    setText({
      ...text,
      [language]: value
    });
  };

  const onConfirmClicked = (): void => {
    //we are checking that required values exist in validateCanSave()
    //typescript wants us to check them specifically though
    if (!url || !text) {
      return;
    }

    if (validateCanSave()) {
      onConfirm?.({
        ...(id ? { id } : {}),
        url,
        text,
        division_id: divisionId,
        all_company: allCompany
      });
      onClose();
    }
  };

  const onDeleteLinkClicked = (): void => {
    if (!portalLink) {
      return;
    }
    onDeleteLink(portalLink);
  };

  const confirmDisabled = !validateCanSave();

  let prompt;
  if (!canModify && portalLink) {
    prompt = "View portal link";
  } else if (canModify && portalLink) {
    prompt = "Edit portal link";
  } else {
    prompt = "Add a new portal link";
  }
  // If user is not a company adimin adjust the verbiage
  if (!session.is_company_admin && canModify) {
    prompt = `${prompt} for your division`;
  }

  useEffect(() => {
    const theDivisions: SelectOptionsProps[] = [];
    setAllowedDivisions([]);
    session?.divisions?.forEach(division => {
      theDivisions.push({ label: division.name, value: division.id });
    });
    setAllowedDivisions(theDivisions);
  }, [session?.divisions]);

  //TODO: HOw to make a "read only modal"?
  return (
    <Modal
      show
      container={document.getElementById("ts-modal")}
      centered
      size="lg"
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>{prompt}</Modal.Title>
        {topRightLinkText && (
          <TSButton variant="danger" onClick={onDeleteLinkClicked}>
            {topRightLinkText}
          </TSButton>
        )}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs="12">
            <TSInput
              id="portal-url"
              label="Url Link"
              type="text"
              required
              defaultValue={url ?? ""}
              readOnly={!canModify}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setUrl(e.target.value)
              }
              placeholder="Enter full URL (e.g. https://www.teamsense.com/)"
            />
          </Col>

          {showDivisionDropDown && allowedDivisions && (
            <Col xs="12">
              <Form.Label>Division</Form.Label>
              <Form.Select
                aria-label="Divisions"
                disabled={!canModify || allCompany}
                placeholder="Select Division"
                value={divisionId ? divisionId : ""}
                required
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  handleDivisionOnChange(e.target.value);
                }}
              >
                <option value="" disabled>
                  Select Division
                </option>
                {allowedDivisions.map((division, i) => (
                  <option value={division.value} key={i}>
                    {division.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
          )}

          {showAllCompanyOption && (
            <Col xs="12">
              <Form.Check
                type="checkbox"
                className="mt-2"
                label={`Available to All ${session.company?.name} Employees`}
                readOnly={!canModify}
                checked={allCompany}
                name="all-company-checkbox"
                id="all-company-checkbox"
                onChange={handleToggleAllCompany}
              />
            </Col>
          )}
        </Row>
        <Row>
          {languages && languages.length > 0 && (
            <h1 className="mt-3">Display Name:</h1>
          )}
          {languages.map(language => (
            <Col key={language} md="6">
              <TSInput
                id={`portal-display-${language}`}
                label={
                  multiLanguage ? displayLanguage(language, true) : undefined
                }
                placeholder="Enter display name"
                defaultValue={text?.[language] ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onTextChange(language, e.target.value)
                }
                readOnly={!canModify}
              />
            </Col>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <TSButton variant="text" onClick={onClose}>
          Cancel
        </TSButton>
        <TSButton onClick={onConfirmClicked} disabled={confirmDisabled}>
          Confirm
        </TSButton>
      </Modal.Footer>
    </Modal>
  );
};
