import React, { FunctionComponent } from "react";
import { Modal } from "react-bootstrap";

import * as api from "~/api";
import { ModalProps, TSButton } from "~common";

export interface PortalDeleteLinkModalProps extends ModalProps {
  portalLink: api.AdminPortalLink;
  onDelete: (portalLink: api.AdminPortalLink) => any;
}

export const PortalDeleteLinkModal: FunctionComponent<
  PortalDeleteLinkModalProps
> = ({ portalLink, onDelete, onClose }) => {
  const onConfirm: () => void = () => {
    onDelete(portalLink);
    onClose();
  };

  return (
    <Modal
      show
      keyboard={false}
      container={document.getElementById("ts-modal")}
      centered
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>Delete Portal Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>{`Are you sure want to delete the portal link to ${portalLink.url}?`}</Modal.Body>
      <Modal.Footer>
        <TSButton variant="link" onClick={onClose}>
          Cancel
        </TSButton>
        <TSButton variant="danger" onClick={onConfirm}>
          Delete
        </TSButton>
      </Modal.Footer>
    </Modal>
  );
};
