import React from "react";

import { PermissionContext } from "~/lib/context/";

type Props = {
  permissions: string[];
  children: React.ReactNode;
};

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider: React.FunctionComponent<Props> = ({
  permissions,
  children
}) => {
  // Creates a method that returns whether the requested permission is available in the list of permissions
  // passed as parameter
  const isAllowedTo = (permission: string | string[]): boolean => {
    if (typeof permission === "string") {
      return permissions.includes(permission);
    } else {
      return permission.every(elem => permissions.includes(elem));
    }
  };

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
