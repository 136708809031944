import React, { FunctionComponent } from "react";

interface BulletProps {
  className?: string;
  color: string;
  size?: "large" | "x-large";
}

export const Bullet: FunctionComponent<BulletProps> = ({
  className,
  size,
  color
}) => {
  const diameter = size === "large" ? 10 : 16;
  const radius = diameter / 2;
  return (
    <div
      className={className}
      style={{
        borderRadius: `${radius}px`,
        minWidth: `${diameter}px`,
        width: `${diameter}px`,
        minHeight: `${diameter}px`,
        height: `${diameter}px`,
        backgroundColor: color
      }}
    />
  );
};
