import React, { FunctionComponent } from "react";
import Skeleton from "react-loading-skeleton";

import { AnimatePresence, motion } from "framer-motion";

import loading from "../../images/attendance-comparison-loading.svg";

import { TSButton } from "~common";

import "./AttendanceTwoColumn.scss";

// you'll notice a lot of alt="" and that's intentional to prevent
// screen readers from reading the image

export const AttendanceTwoColumn: FunctionComponent<
  AttendanceTwoColumnProps
> = ({ hideReport, isLoading, onCTAClick, heading, text, icon }) => {
  return (
    <div className="attendance-two-col">
      <div className="attendance-two-col-icon">
        <img src={isLoading ? loading : icon} alt="" />
      </div>
      <div className="attendance-two-col-text">
        <AnimatePresence>
          {isLoading ? (
            <motion.div
              className="attendance-mini-skeleton"
              key="skeleton"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <h1 className="d-block">
                <Skeleton width="40%" />
              </h1>
              <p>
                <Skeleton count={3} />
              </p>
            </motion.div>
          ) : (
            <motion.div
              key="heading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <h1>
                {heading}{" "}
                {!hideReport && onCTAClick && (
                  <TSButton variant="link" size="sm" onClick={onCTAClick}>
                    View Report
                  </TSButton>
                )}
              </h1>
              {text}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

interface AttendanceTwoColumnProps {
  isLoading: boolean;
  heading?: number | string;
  text?: JSX.Element;
  icon?: string;
  onCTAClick?: () => any;
  hideReport?: boolean;
}
