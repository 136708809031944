import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

import { Trans } from "@lingui/react";

import DownArrowIcon from "~/images/down-arrow-icon.svg";
import { ErrorExpiredLink } from "~/ts-components/common/errors/ErrorExpiredLink";
import { MessageActionButton } from "~common";

export const ConversationErrorPage: FunctionComponent = () => {
  const history = useHistory();
  const navigateToGoPage = (): void => {
    history.push("/go");
  };
  return (
    <ErrorExpiredLink>
      <div className="text-hs-medium-dark-gray text-center">
        <Trans id="error.survey.expired.link">
          Send yourself a new link below
        </Trans>
      </div>
      <img className="pt-3 m-auto" src={DownArrowIcon} alt="Down Arrow Icon" />
      <div className="px-4 my-4">
        <MessageActionButton
          className="btn btn-primary"
          text="Send my survey"
          theme="dark-green"
          onClick={navigateToGoPage}
        />
      </div>
    </ErrorExpiredLink>
  );
};
