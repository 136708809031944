import React, { FunctionComponent, MouseEvent, useCallback } from "react";
import { Button, ButtonProps, Spinner, SpinnerProps } from "react-bootstrap";

import clsx from "clsx";

export const TSButton: FunctionComponent<TSButtonProps> = props => {
  const {
    children,
    className,
    onClick,
    disabled,
    submit,
    startIcon,
    endIcon,
    variant,
    size,
    showSpinner,
    spinnerProps
  } = props;

  // Event Handlers
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      onClick && onClick(event);
      event.stopPropagation();
    },
    [onClick]
  );

  // Content Fragments
  const getIcon = (icon?: string): JSX.Element => {
    return (
      <i
        className={clsx(
          "btn-icon",
          icon,
          startIcon && "btn-icon-start",
          endIcon && "btn-icon-end"
        )}
      />
    );
  };

  const getSpinnerIcon = (): JSX.Element => {
    return (
      <Spinner
        animation="border"
        as="span"
        role="status"
        size={size === "sm" ? "sm" : undefined}
        {...spinnerProps}
      />
    );
  };

  return (
    <Button
      variant={variant}
      size={size}
      className={clsx("text-nowrap", className, showSpinner && "btn-loader")}
      disabled={disabled || showSpinner}
      onClick={handleClick}
      type={submit ? "submit" : "button"}
      data-testid="button"
    >
      {showSpinner && getSpinnerIcon()}
      {startIcon && getIcon(startIcon)}
      {children}
      {endIcon && getIcon(endIcon)}
    </Button>
  );
};

interface TSButtonProps extends ButtonProps {
  className?: string;
  startIcon?: string;
  endIcon?: string;
  onClick?: (event: MouseEvent) => void;
  submit?: boolean;
  showSpinner?: boolean;
  spinnerProps?: SpinnerProps;
}
