import React, { FunctionComponent } from "react";

import comingSoonLg from "../../images/attendance-coming-soon-lg.svg";
import comingSoonSm from "../../images/attendance-coming-soon-sm.svg";

import "./AttendanceComingSoon.scss";

const ResponsiveImage: FunctionComponent<RespsonsiveImageInterface> = ({
  sm,
  md,
  lg,
  alt
}) => {
  return (
    <picture className="attendance-responsive-image">
      <source media="(max-width: 600px)" srcSet={sm} />
      {md && <source media="(max-width: 992px)" srcSet={md} />}
      <img src={lg} alt={alt} />
    </picture>
  );
};

export const AttendanceComingSoon: FunctionComponent = () => {
  return (
    <div className="attendance-coming-soon">
      <header className="mb-4">
        <ResponsiveImage sm={comingSoonSm} lg={comingSoonLg} />
      </header>
    </div>
  );
};

interface RespsonsiveImageInterface {
  sm: any;
  md?: any;
  lg: any;
  alt?: string;
}
