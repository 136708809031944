import React, { FunctionComponent, ReactNode, useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { RouteProps } from "./routeProps";

import { MutableSessionContext } from "~/lib/context";

/**
 * Guarded version of route that makes sure a user is NOT logged in
 */
export const GuestRoute: FunctionComponent<RouteProps> = ({
  component: Component,
  render,
  ...config
}) => {
  const { session } = useContext(MutableSessionContext);
  const SentryRoute = Sentry.withSentryRouting(Route);

  return (
    <SentryRoute
      {...config}
      render={(props): ReactNode => {
        if (session.user) {
          return <Redirect to="/dashboard" />;
        } else {
          return Component ? <Component {...props} /> : render();
        }
      }}
    />
  );
};
