import React, { PropsWithChildren, ReactElement } from "react";

import { HeadingProps } from "./SelectableEmployeeList";

import { FilterHeading } from "~/components/FilterHeading/FilterHeading";
import { FilterListItem, areAllSelected } from "~/lib/filterList";

// todo: replace this with the new filter dropdowns?
export const EmployeeListHeading = <T extends FilterListItem>({
  className,
  name,
  list,
  listName,
  data,
  dispatch,
  displayNone
}: PropsWithChildren<HeadingProps<T>>): ReactElement | null => (
  <FilterHeading
    className={className}
    title={name}
    titleClassName="uppercase"
    allSelected={areAllSelected(list[listName], displayNone ?? false)}
    noneSelected={list[listName].displayEmpty}
    itemNames={data.map(({ name }) => name)}
    itemSelections={data.map(({ id }) => list[listName].items[id]?.selected)}
    displayNone={displayNone ?? false}
    onClick={(index, selected) =>
      dispatch({
        type: "updateVisibleByFilterItem",
        listName,
        newValue: selected,
        item: data[index]
      })
    }
    onSelectAll={selected =>
      dispatch({
        type: "setVisibleAllFilterItems",
        listName,
        newValue: selected
      })
    }
    onNone={selected =>
      dispatch({
        type: "updateVisibleEmptyFilterItem",
        listName,
        newValue: selected
      })
    }
  />
);
