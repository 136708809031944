import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

import * as api from "~/api";

import "./FormResults.scss";

interface FormResultsSkipQuestionProps {
  skipQuestion?: api.SurveyQuestion;
}

export const FormResultsSkipQuestion: FC<FormResultsSkipQuestionProps> = ({
  skipQuestion
}) => (
  <div className="form-question-set mb-8">
    <div className="form-question">
      <h2>{skipQuestion ? skipQuestion.question : <Skeleton />}</h2>
    </div>
    <div className="form-skip-explanation">
      {skipQuestion ? (
        "To view answers to this question, please use Export Responses."
      ) : (
        <Skeleton />
      )}
    </div>
  </div>
);
