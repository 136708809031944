import React, { FunctionComponent, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";

import clsx from "clsx";

import "./AttendanceProgressBar.scss";

export const AttendanceProgressBar: FunctionComponent<
  AttendanceProgressBarProps
> = ({ barColor, barHoverColor, label, percentCompleted, popover, value }) => {
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<any>(null);
  const ref = useRef<any>();
  const barWidth = `${percentCompleted}%`;
  const backgroundColor = isActive ? barHoverColor : barColor;

  const handleEnter = (): void => {
    setShow(true);
    setTarget(ref);
  };

  const handleExit = (): void => {
    setShow(false);
  };

  return (
    <div
      className="single-bar-row"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className="sbr-label">{label}</div>
      <div
        className="sbr-range"
        onMouseEnter={handleEnter}
        onMouseLeave={handleExit}
      >
        <div className="sbr-range-full" ref={ref}>
          <div
            className={clsx("sbr-range-percent", barWidth === "0%" && "d-none")}
            style={{
              width: barWidth,
              backgroundColor
            }}
          ></div>
        </div>
        {popover && (
          <Overlay
            show={show}
            target={target}
            placement="top"
            container={ref}
            containerPadding={0}
          >
            <Popover id="popover-container" className="attendance-popovers">
              <Popover.Body>{popover}</Popover.Body>
            </Popover>
          </Overlay>
        )}
      </div>
      <div className="sbr-value">{value}</div>
    </div>
  );
};

interface AttendanceProgressBarProps {
  percentCompleted: number;
  value?: string | number;
  label?: JSX.Element;
  barHoverColor: string;
  barColor: string;
  popover?: JSX.Element;
}
