import React, { FunctionComponent } from "react";

import clsx from "clsx";

import Restricted from "../permissionProvider/Restricted";

import { AttendanceSyncStatusButton } from "./AttendanceSyncStatusButton";

import * as api from "~/api";
import WarningIcon from "~/images/warning-icon.svg";

import "./AttendanceSyncStatus.scss";

export const AttendanceSyncStatus: FunctionComponent<
  AttendanceSyncStatusProps
> = ({ automaticSync, onEventStatusSync, event }) => {
  const status = event.sync_status;
  const isErrored = status === "errored";
  const isChanged = status === "changed";
  const buttonContext = isChanged ? (
    <div className="inline-flex mt-2 text-sm font-medium">
      <img src={WarningIcon} alt="warning icon" />
      <span className="ml-2 text-ts-gold-40">
        A change has been made since last sync
      </span>
    </div>
  ) : (
    "*An error occured during the update. Please try again."
  );

  return (
    <div className="attendance-sync-status">
      <Restricted to="AttendanceSyncStatus.UPDATE" disabled>
        <AttendanceSyncStatusButton
          status={status}
          event={event}
          automaticSync={automaticSync}
          onEventStatusSync={onEventStatusSync}
        />
      </Restricted>
      {(isErrored || isChanged) && (
        <div
          className={clsx(
            "text-sm",
            isErrored ? "status-message-red" : "status-message-gray"
          )}
        >
          {buttonContext}
        </div>
      )}
    </div>
  );
};

interface AttendanceSyncStatusProps {
  automaticSync?: boolean;
  event: api.BaseEmployeeEvent;
  onEventStatusSync: (
    eventId: string,
    status: api.AlteredEventSyncStatus
  ) => void;
}
