import React, { FunctionComponent, useContext, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastOptions } from "react-toastify";

import * as api from "~/api";
import { TodaysDirectReports } from "~/components";
import { MutableSessionContext } from "~/lib/context";
import { useEffectOnce } from "~/lib/hooks";
import { generateFullStatusesWithEmployeeList } from "~/lib/status";
import { Loading, GenericErrorText } from "~common";

const toastGenericError = (options?: ToastOptions): void => {
  toast.error(<GenericErrorText />, options);
};

/**
 * The direct reports view.
 */
export const DirectReports: FunctionComponent<{}> = () => {
  //
  // Hooks & initialization
  //

  const [directReports, setDirectReports] = useState<api.Employee[]>();
  interface ParamTypes {
    token: string;
  }
  const { token } = useParams<ParamTypes>();
  const history = useHistory();
  const { session } = useContext(MutableSessionContext);

  useEffectOnce(() => {
    const loadTeamMembersAndConversationsToday = async (
      companyId?: string,
      employeeId?: string,
      token?: string
    ): Promise<void> => {
      let teamMembersResponse: api.APIResponse<api.Employee[]> | undefined;
      try {
        if (companyId && employeeId) {
          teamMembersResponse = await api.retrieveTeamLeadTeamMembers(
            companyId,
            employeeId,
            {
              token,
              activeEvents: true
            }
          );
        }
      } catch {}

      if (!teamMembersResponse?.ok) {
        toastGenericError({ autoClose: false, closeOnClick: false });
        return;
      }

      setDirectReports(teamMembersResponse.data);
    };

    loadTeamMembersAndConversationsToday(
      session.company?.id,
      session.employee?.id,
      token
    );
  });

  const fullStatuses = useMemo(
    () =>
      generateFullStatusesWithEmployeeList(
        session.features.full_covid_health_statuses,
        directReports
      ),
    [directReports, session.features.full_covid_health_statuses]
  );

  const onClickEmployee = (employee: api.Employee): void =>
    history.push(
      `/manager/employee/${employee.id}/schedule${token ? `?t=${token}` : ""}`
    );

  //
  // Rendering
  //

  return (
    <div className="h-screen w-screen bg-white">
      {!fullStatuses && <Loading />}
      {fullStatuses && (
        <TodaysDirectReports
          name={session.employee?.friendly_name ?? ""}
          statuses={fullStatuses}
          onClickEmployee={onClickEmployee}
        />
      )}
    </div>
  );
};
