import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { FormResultsChoiceAnswer } from "./FormResultsChoiceAnswer";

import * as api from "~/api";

import "./FormResults.scss";

interface FormResultsChoiceQuestionProps {
  choiceQuestion?: api.SurveyChoiceResults;
}

export const FormResultsChoiceQuestion: FC<FormResultsChoiceQuestionProps> = ({
  choiceQuestion
}) => {
  // [undefined, undefined, undefined] will render as three skeleton answers
  const choiceAnswersWithSkeletonSentinels: (
    | api.SurveyChoiceAnswers
    | undefined
  )[] = choiceQuestion?.answers ?? [undefined, undefined, undefined];
  return (
    <div className="form-question-set mb-8">
      <div className="form-question">
        <h2>{choiceQuestion ? choiceQuestion.question : <Skeleton />}</h2>
      </div>
      <>
        {choiceAnswersWithSkeletonSentinels.map((answer, index) => (
          <FormResultsChoiceAnswer
            key={answer?.name ?? `skeleton-${index}`}
            answer={answer}
            totalCount={choiceQuestion?.totalCount}
          />
        ))}
      </>
    </div>
  );
};
