import React, { FC, useEffect, useContext, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { DateTime } from "luxon";

import { MutableSessionContext } from "../lib/context/mutableSession";

import * as api from "~/api";
import { countsByLabel } from "~/lib/stats";
import { StatsGrid, TSButton, Loading } from "~common";
import { StatusGraph } from "~reports";

const DAYS_TO_SHOW_IN_STATUS_GRAPH = 20;

export const Reports: FC<ReportsProps> = props => {
  const { session } = useContext(MutableSessionContext);
  const history = useHistory();

  const [statusCounts, setStatusCounts] = useState<api.StatusCounts[]>([]);
  // const [exportModalSurveyId, setExportModalSurveyId] = useState<string>();

  const statClickHandler = (key: string): void =>
    history.push(`/dashboard/employees?status=${key}`);

  // update to new hook syntax
  useEffect(() => {
    const loadStatusCounts = async (): Promise<void> => {
      if (!session.company) {
        return;
      }

      // setExportModalSurveyId(session.company.id);

      let response: api.APIResponse<api.StatusCounts[]> | undefined;
      try {
        response = await api.retrieveStatusCounts(
          session.company.id,
          DateTime.local()
            .minus({ days: DAYS_TO_SHOW_IN_STATUS_GRAPH })
            .set({ hour: 22, minute: 59, second: 59, millisecond: 0 })
            .toISO()
        );
      } catch {}

      if (response?.ok) {
        setStatusCounts(response.data);
      } else {
        // replace with new toast
        // toast.error(<GenericErrorText />);
        console.log("api error");
      }
    };

    loadStatusCounts();
  }, [session.company]);

  // something is messed up in the StatusGraph component so we have this ugly hack for now
  if (!statusCounts?.length) {
    return <Loading />;
  }

  return (
    <div className="ts">
      <Container fluid className="standard-page">
        <div className="text-end mb-4">
          <TSButton variant="primary">Screen Employees</TSButton>
          <TSButton variant="primary">Export Attendance Report</TSButton>
        </div>

        <Card className="content-block">
          <h1>Today's Status</h1>
          <StatsGrid
            counts={countsByLabel(statusCounts[statusCounts.length - 1])}
            statClickHandler={(key: string) => statClickHandler(key)}
          />
        </Card>

        <Card className="content-block">
          <h1>Trends</h1>
          <h3>Number of Employees</h3>
          {statusCounts && <StatusGraph statusCounts={statusCounts} />}
        </Card>
      </Container>
    </div>
  );
};

interface ReportsProps {
  prop1?: string;
  prop2?: number;
}
