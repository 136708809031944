import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export const Icons = (): JSX.Element => {
  return (
    <Card className="demo-icons">
      <Card.Body>
        <h1>Icons</h1>
        <Row className="justify-content-start">
          <Col>
            <i className="icon-plus" />
            <div>icon-plus</div>
          </Col>
          <Col>
            <i className="icon-warning" />
            <div>icon-warning</div>
          </Col>
          <Col>
            <i className="icon-info" />
            <div>icon-info</div>
          </Col>
          <Col>
            <i className="icon-help" />
            <div>icon-help</div>
          </Col>
          <Col>
            <i className="icon-close" />
            <div>icon-close</div>
          </Col>
          <Col>
            <i className="icon-dots" />
            <div>icon-dots</div>
          </Col>
          <Col>
            <i className="icon-hamburger" />
            <div>icon-hamburger</div>
          </Col>
          <Col>
            <i className="icon-caret-top" />
            <div>icon-caret-top</div>
          </Col>
          <Col>
            <i className="icon-caret-bottom" />
            <div>icon-caret-bottom</div>
          </Col>
          <Col>
            <i className="icon-caret-left" />
            <div>icon-caret-left</div>
          </Col>
          <Col>
            <i className="icon-caret-right" />
            <div>icon-caret-right</div>
          </Col>
          <Col>
            <i className="icon-check" />
            <div>icon-check</div>
          </Col>
          <Col>
            <i className="icon-search" />
            <div>icon-search</div>
          </Col>
          <Col>
            <i className="icon-filter" />
            <div>icon-filter</div>
          </Col>
          <Col>
            <i className="icon-trash" />
            <div>icon-trash</div>
          </Col>
          <Col>
            <i className="icon-send" />
            <div>icon-send</div>
          </Col>
          <Col>
            <i className="icon-edit" />
            <div>icon-edit</div>
          </Col>
          <Col>
            <i className="icon-notifications" />
            <div>icon-notifications</div>
          </Col>
          <Col>
            <i className="icon-message" />
            <div>icon-message</div>
          </Col>
          <Col>
            <i className="icon-clock" />
            <div>icon-clock</div>
          </Col>
          <Col>
            <i className="icon-phone" />
            <div>icon-phone</div>
          </Col>
          <Col>
            <i className="icon-calendar" />
            <div>icon-calendar</div>
          </Col>
          <Col>
            <i className="icon-envelope" />
            <div>icon-envelope</div>
          </Col>
          <Col>
            <i className="icon-user" />
            <div>icon-user</div>
          </Col>
          <Col>
            <i className="icon-upload" />
            <div>icon-upload</div>
          </Col>
          <Col>
            <i className="icon-sync-unsynced" />
            <div>icon-sync-unsynced</div>
          </Col>
          <Col>
            <i className="icon-sync-synced" />
            <div>icon-sync-synced</div>
          </Col>
          <Col>
            <i className="icon-sync-error" />
            <div>icon-sync-error</div>
          </Col>
          <Col>
            <i className="icon-user-add" />
            <div>icon-user-add</div>
          </Col>
          <Col>
            <i className="icon-lock" />
            <div>icon-lock</div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
