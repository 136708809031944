import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export const Cards = (): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <h1>Cards</h1>
        <p>
          This general area is a card but we've included a nested one as well.
        </p>
        <Row>
          <Col>
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src="https://picsum.photos/360/200" />
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
