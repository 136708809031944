import React, { useState } from "react";
import { Card, Row, Col, Modal } from "react-bootstrap";

import { TSButton, TSInput } from "~common";

export const Modals = (): JSX.Element => {
  // const [show, setShow] = useState(false);
  const [showModalSmall, setShowModalSmall] = useState(false);
  const [showModalLarge, setShowModalLarge] = useState<boolean>(false);
  const [showModalFW, setShowModalFW] = useState<boolean>(false);

  const handleClose = (): void => {
    setShowModalSmall(false);
    setShowModalLarge(false);
    setShowModalFW(false);
  };

  return (
    <Card>
      <Card.Body>
        <h1>Modals</h1>
        <Row>
          <Col>
            <TSButton
              variant="outline-primary"
              onClick={() => setShowModalSmall(true)}
            >
              Small Modal
            </TSButton>
            <TSButton
              variant="outline-primary"
              onClick={() => setShowModalLarge(true)}
            >
              Large Modal
            </TSButton>
            <TSButton
              variant="outline-primary"
              onClick={() => setShowModalFW(true)}
            >
              Full Width Modal
            </TSButton>
          </Col>
        </Row>
      </Card.Body>

      {/* Small Modal */}
      <Modal
        container={document.getElementById("ts-modal")}
        show={showModalSmall}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <h1>Small Modal</h1>
          Woohoo, you're reading this text in a modal!
        </Modal.Body>
        <Modal.Footer>
          <TSButton variant="link" onClick={handleClose} size="lg">
            Cancel
          </TSButton>
          <TSButton variant="primary" onClick={handleClose} size="lg">
            Save Changes
          </TSButton>
        </Modal.Footer>
      </Modal>

      {/* Large Modal */}
      <Modal
        container={document.getElementById("ts-modal")}
        show={showModalLarge}
        onHide={handleClose}
        size="lg"
        centered
      >
        <Modal.Body>
          <h1>I'm a snazzy large modal</h1>
          <p>
            TeamSense Screener functionality enables individuals to screen other
            employees by entering survey responses on their behalf.
          </p>

          <p>
            To authorize an individual to screen others, search for their name
            and click "Send." TeamSense will immediately text or email that
            individual a unique Screener link that will enable them to search
            for any employee and fill out a survey on their behalf. For security
            purposes, the link will expire in 24 hours.
          </p>

          <p>For more information, please visit the TeamSense Help Center.</p>

          <TSInput
            id="nickname"
            label="Nickname"
            type="text"
            placeholder="Enter Nickname"
            defaultValue="Sparky"
            helpText="This is super helpful"
            groupClass="pt-4"
          />
        </Modal.Body>
        <Modal.Footer>
          <TSButton variant="link" onClick={handleClose} size="lg">
            Cancel
          </TSButton>
          <TSButton variant="primary" onClick={handleClose} size="lg">
            Save Changes
          </TSButton>
        </Modal.Footer>
      </Modal>

      {/* Fullscreen Modal */}
      <Modal
        container={document.getElementById("ts-modal")}
        show={showModalFW}
        onHide={handleClose}
        size="xl"
        centered
        scrollable
      >
        <Modal.Header>
          <h1 className="mb-0">Full Size</h1>
        </Modal.Header>
        <Modal.Body>
          <p>
            In a hole in the ground there lived a hobbit. Not a nasty, dirty,
            wet hole, filled with the ends of worms and an oozy smell, nor yet a
            dry, bare, sandy hole with nothing in it to sit down on or to eat:
            it was a hobbit-hole, and that means comfort.
          </p>
          <p>
            It had a perfectly round door like a porthole, painted green, with a
            shiny yellow brass knob in the exact middle. The door opened on to a
            tube-shaped hall like a tunnel: a very comfortable tunnel without
            smoke, with panelled walls, and floors tiled and carpeted, provided
            with polished chairs, and lots and lots of pegs for hats and
            coats—the hobbit was fond of visitors. The tunnel wound on and on,
            going fairly but not quite straight into the side of the hill—The
            Hill, as all the people for many miles round called it—and many
            little round doors opened out of it, first on one side and then on
            another. No going upstairs for the hobbit: bedrooms, bathrooms,
            cellars, pantries (lots of these), wardrobes (he had whole rooms
            devoted to clothes), kitchens, dining-rooms, all were on the same
            floor, and indeed on the same passage. The best rooms were all on
            the left-hand side (going in), for these were the only ones to have
            windows, deep-set round windows looking over his garden, and meadows
            beyond, sloping down to the river.
          </p>
          <p>
            This hobbit was a very well-to-do hobbit, and his name was Baggins.
            The Bagginses had lived in the neighbourhood of The Hill for time
            out of mind, and people considered them very respectable, not only
            because most of them were rich, but also because they never had any
            adventures or did anything unexpected: you could tell what a Baggins
            would say on any question without the bother of asking him. This is
            a story of how a Baggins had an adventure, and found himself doing
            and saying things altogether unexpected. He may have lost the
            neighbours’ respect, but he gained—well, you will see whether he
            gained anything in the end.
          </p>
          <p>
            The mother of our particular hobbit—what is a hobbit? I suppose
            hobbits need some description nowadays, since they have become rare
            and shy of the Big People, as they call us. They are (or were) a
            little people, about half our height, and smaller than the bearded
            dwarves. Hobbits have no beards. There is little or no magic about
            them, except the ordinary everyday sort which helps them to
            disappear quietly and quickly when large stupid folk like you and me
            come blundering along, making a noise like elephants which they can
            hear a mile off. They are inclined to be fat in the stomach; they
            dress in bright colours (chiefly green and yellow); wear no shoes,
            because their feet grow natural leathery soles and thick warm brown
            hair like the stuff on their heads (which is curly); have long
            clever brown fingers, good-natured faces, and laugh deep fruity
            laughs (especially after dinner, which they have twice a day when
            they can get it). Now you know enough to go on with. As I was
            saying, the mother of this hobbit—of Bilbo Baggins, that is—was the
            famous Belladonna Took, one of the three remarkable daughters of the
            Old Took, head of the hobbits who lived across The Water, the small
            river that ran at the foot of The Hill. It was often said (in other
            families) that long ago one of the Took ancestors must have taken a
            fairy wife. That was, of course, absurd, but certainly there was
            still something not entirely hobbitlike about them, and once in a
            while members of the Took-clan would go and have adventures. They
            discreetly disappeared, and the family hushed it up; but the fact
            remained that the Tooks were not as respectable as the Bagginses,
            though they were undoubtedly richer.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <TSButton
            variant="danger"
            endIcon="icon-trash"
            size="lg"
            className="me-auto"
          >
            My Precious
          </TSButton>

          <TSButton variant="text" onClick={handleClose} size="lg">
            Close
          </TSButton>
          <TSButton variant="primary" onClick={handleClose} size="lg">
            Bilbo
          </TSButton>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};
