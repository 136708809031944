import React, { FunctionComponent } from "react";

import { HeatMapTile } from "./HeatMapTile";

import * as api from "~/api";
import { orderFrames } from "~/lib/attendanceInsights";

import "./MonthlyHeatMap.scss";

export const MonthlyHeatMap: FunctionComponent<MonthlyInterface> = ({
  data
}) => {
  data && orderFrames(data);

  return (
    <div className="heatmap-monthly-grid">
      {data &&
        data.map((month, index) => {
          return <HeatMapTile key={index} frame={month} type="month" />;
        })}
    </div>
  );
};

interface MonthlyInterface {
  data?: api.Frame[];
}
