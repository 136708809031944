import React from "react";

// eslint-disable-next-line import/order
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import "./TSTable.scss";
import {
  TypeColumn,
  TypeDataGridProps
} from "@inovua/reactdatagrid-community/types";

type TSTableProps = Partial<TypeDataGridProps> & {
  columns: TypeColumn[];
  dataSource: any[];
  filterRowHeight?: number;
  renderScroller?: (domProps: any) => void;
  enableCellBulkUpdate?: boolean;
  defaultShowHeader?: boolean;
  defaultShowCellBorders?: boolean;
  expandOnMouseDown?: boolean;
  defaultColumnOrder?: undefined;
};

interface SortIndicatorProps {
  direction: number;
}

const SortIndicator = ({ direction }: SortIndicatorProps): JSX.Element => {
  return (
    <div>
      {direction === -1 ? (
        <i className="icon-caret-top active icon-caret-top--active" />
      ) : (
        <i className="icon-caret-top icon-caret-top--hide" />
      )}
      {direction === 1 ? (
        <i className="icon-caret-bottom icon-caret-bottom--active" />
      ) : (
        <i className="icon-caret-bottom icon-caret-bottom--hide" />
      )}
    </div>
  );
};

const renderSortTool = (direction: number): JSX.Element => {
  return <SortIndicator direction={direction} />;
};

const TSTable: React.FC<TSTableProps> = (props: TSTableProps) => {
  return <ReactDataGrid renderSortTool={renderSortTool} {...props} />;
};

export default TSTable;
