import React, { FunctionComponent, useMemo } from "react";
import { ValueType } from "react-select";

import Creatable from "react-select/creatable";

import {
  BaseEntitiesDropdownProps,
  stylesConfig,
  themeConfig
} from "./entities-dropdown-common";

interface CreateableDropdownListItem {
  value?: string;
  label: string;
}

interface CreateableEntity {
  id?: string;
  name: string;
}

interface CreateableEntitiesDropdownProps extends BaseEntitiesDropdownProps {
  entities: CreateableEntity[];
  onChange?: (value: CreateableEntity[]) => any;
}

export const CreateableEntitiesDropdown: FunctionComponent<
  CreateableEntitiesDropdownProps
> = ({ allEntities, entities, onChange, ...selectProps }) => {
  const options = useMemo(
    () =>
      (allEntities ?? []).map(({ id, name }) => ({
        value: id,
        label: name
      })),
    [allEntities]
  );

  const value = useMemo(
    () =>
      (entities ?? []).map(({ id, name }) => ({
        value: id,
        label: name
      })),
    [entities]
  );

  const onSelectChange = (
    inputValue: ValueType<CreateableDropdownListItem>
  ): void => {
    const selected = inputValue as CreateableDropdownListItem[];
    const entityData = selected.map(({ value, label }) => ({
      id: value,
      name: label
    }));
    onChange?.(entityData);
  };

  const onCreateOption = (name: string): void => {
    onChange?.([...entities, { name }]);
  };

  return (
    <Creatable
      className="react-select-container"
      classNamePrefix="react-select field--select"
      styles={stylesConfig}
      theme={themeConfig}
      isMulti
      menuPlacement="auto"
      menuPortalTarget={document.body}
      value={value}
      options={options}
      onChange={onSelectChange}
      onCreateOption={onCreateOption}
      {...selectProps}
    />
  );
};
