const colors = {
  white: "#FFFFFF",

  // Brand colors
  teal: {
    11: "#6DAEA2", // TODO: Found this color in figma button but not in the design system
    10: "#0A2C29",
    20: "#0E3F3A",
    30: "#145851",
    40: "#1C7E74",
    50: "#60A59E",
    70: "#BFDBD8",
    80: "#DFEDEC",
    90: "#EFF6F5"
  },
  gold: {
    30: "#946E16",
    40: "#C5921E",
    50: "#F6B725",
    70: "#FAD47C",
    80: "#FCE9BE",
    90: "#FEF8E9"
  },
  coral: {
    30: "#944416",
    40: "#B8561C",
    50: "#F67225",
    70: "#FAAA7C",
    80: "#FDE3D3"
  },
  gray: {
    10: "#252827",
    20: "#343737",
    30: "#595F5E",
    40: "#767E7D",
    50: "#949E9C",
    60: "#BDC3C3",
    70: "#BFC5C4",
    80: "#DFE2E1",
    90: "#FAFAFA",
    100: "#FFFFFF"
  },

  // UI Status colors
  red: {
    20: "#AD0000",
    50: "#FE4545",
    70: "#FFC7C7",
    80: "#FFECEC"
  },
  yellow: {
    20: "#6B5303",
    50: "#FFD13B",
    70: "#FFEDB1",
    80: "#FFF6D8"
  },
  green: {
    20: "#0B5B19",
    50: "#65D277",
    70: "#B2E9BB",
    80: "#E0F6E4"
  },
  blue: {
    20: "#0031A3",
    50: "#5C8DFF",
    70: "#CEDDFF",
    80: "#EFF4FF"
  },

  // UI Extended colors
  grape: {
    20: "#780CA7",
    50: "#BC4CF0",
    70: "#EDC8FE",
    80: "#F8EBFF"
  },
  guava: {
    20: "#96034A",
    50: "#FC67AF",
    70: "#FECAE3",
    80: "#FFF0F7"
  },
  tangerine: {
    20: "#7D4008",
    50: "#FFA047",
    70: "#FFD9B6",
    80: "#FFECDA"
  },
  lemon: {
    20: "#5C5400",
    50: "#FFEF3B",
    70: "#FFF8A7",
    80: "#FFCBD0"
  },
  lime: {
    20: "#455C00",
    50: "#BEE250",
    70: "#E2F2B0",
    80: "#EFF8D3"
  },
  ocean: {
    20: "#0B5B4D",
    50: "#65D2C0",
    70: "#B2E9E0",
    80: "#E0F6F2"
  },
  berry: {
    20: "#0800A3",
    50: "#645CFF",
    70: "#D0CEFF",
    80: "#EFEFFF"
  },
  plum: {
    20: "#5B075F",
    50: "#D85DDF",
    70: "#F2C6F4",
    80: "#FBEFFC"
  },
  coconut: {
    20: "#49331D",
    50: "#AE7945",
    70: "#EBDED0",
    80: "#F7F2EC"
  },
  carrot: {
    20: "#7D2F08",
    50: "#FF8547",
    70: "#FFD4BF",
    80: "#FFEDE3"
  }
};

export default colors;
