import React, { FunctionComponent, useContext } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";
import { MessageActionButton, Modal, ModalProps } from "~common";

export interface DeleteAttendanceStatusModalProps extends ModalProps {
  employee: api.Employee;
  event: api.EmployeeEvent;
  mobileStyle?: boolean;
  onDelete: () => any;
}

export const DeleteAttendanceStatusModal: FunctionComponent<
  DeleteAttendanceStatusModalProps
> = ({ employee, event, mobileStyle, onDelete, onClose }) => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const startDate = DateTime.fromISO(event.started)
    .setZone(employee.timezone)
    .toFormat("MMMM d", { locale: "en-US" });
  // Adjust end date from 12:00AM the following date to the previous date so the
  // displayed range is inclusive
  const endDate = DateTime.fromISO(event.ended ?? "")
    .setZone(employee.timezone)
    .minus({ days: 1 })
    .toFormat("MMMM d", { locale: "en-US" });

  const onDeleteClicked = (): void => {
    onDelete();
    onClose();
  };

  const eventType = getLabelDisplay(event.label.name, session.labels, language);

  return (
    <Modal
      className={clsx(
        "rounded max-w-lg overflow-visible",
        mobileStyle ? "px-7 flex-grow" : "p-12"
      )}
      onClose={onClose}
    >
      <div
        className={clsx(
          "font-bold mb",
          mobileStyle ? "mt-10 text-lg uppercase tracking-wider" : "text-4xl"
        )}
      >
        Delete {eventType} Status
      </div>
      <div
        className={clsx(
          "flex flex-row text-base",
          mobileStyle ? "font-semibold py-8" : "py-16"
        )}
      >
        Are you sure want to delete {employee.friendly_name}’s {eventType}{" "}
        status from {startDate} to {endDate}?
      </div>
      <div
        className={clsx(
          "flex justify-end",
          mobileStyle ? "border-t border-hs-alt-gray pt-4 -mx-7" : "py-2.5"
        )}
      >
        <MessageActionButton
          inline
          className="w-32"
          textSizeClassName={mobileStyle ? "text-base" : undefined}
          text="Cancel"
          theme={mobileStyle ? "clear-dark-green" : "clear-green"}
          onClick={onClose}
        />
        <MessageActionButton
          inline
          className={mobileStyle ? undefined : "w-32"}
          textSizeClassName={mobileStyle ? "text-base" : undefined}
          text="Delete"
          theme={mobileStyle ? "clear-red" : "red"}
          onClick={onDeleteClicked}
        />
      </div>
    </Modal>
  );
};
