import { DateTime } from "luxon";

import * as api from "~/api";

export const eventIsActive = (
  event: api.EmployeeEvent,
  labelSet: api.LabelSet,
  type: string,
  time: string
): boolean => {
  if (labelSet !== event.label.label_set || type !== event.label.name) {
    return false;
  }
  const dateTime = DateTime.fromISO(time);
  return (
    DateTime.fromISO(event.started) <= dateTime &&
    (!event.ended || DateTime.fromISO(event.ended) >= dateTime)
  );
};

export const updateEvent = (
  events: api.AttendanceEventHistory[],
  currentEvent: api.AttendanceEventHistory,
  updates?: Partial<api.AttendanceEventHistory>
): api.AttendanceEventHistory[] => {
  return events.map(event => {
    if (event.event.id === currentEvent.event.id) {
      return { ...currentEvent, ...updates };
    }

    return event;
  });
};
