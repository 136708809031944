import React, { FunctionComponent } from "react";

import Linkify from "linkify-react";

interface LinkifiedProps {
  body: string;
}

export const Linkified: FunctionComponent<LinkifiedProps> = ({ body }) => {
  const options = {
    target: "blank",
    className: "text-indigo-500 underline"
  };
  return (
    <Linkify tagName="p" options={options}>
      {body}
    </Linkify>
  );
};
