import * as React from "react";

import { default as MuiButton, ButtonProps } from "@mui/material/Button";
import clsx from "clsx";

import { adaptProps } from "../mui-stories-utils";
import ThemeLayout from "../Theme/ThemeLayout";

const getIcon = (icon: string | any, position: string): React.ReactElement => {
  if (typeof icon === "string") {
    return (
      <i
        className={clsx(
          "btn-icon",
          icon,
          position === "start" && "btn-icon-start",
          position === "end" && "btn-icon-end"
        )}
      />
    );
  } else {
    return icon;
  }
};

const Button = (
  componentProps: ButtonProps
): React.ReactElement<ButtonProps> => {
  const { children, className, props } = adaptProps(componentProps);
  const icons = {
    startIcon: getIcon(props.startIcon, "start"),
    endIcon: getIcon(props.endIcon, "end")
  };
  return (
    <ThemeLayout>
      <MuiButton className={className} {...props} {...icons}>
        {children}
      </MuiButton>
    </ThemeLayout>
  );
};

export default Button;
