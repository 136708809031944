import React, { FunctionComponent, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { motion, useMotionValue } from "framer-motion";

import styles from "../../ts-styles/shared.scss";

import { AttendanceMiniCard } from "./AttendanceMiniCard";

import { roundPercentForInsights } from "~/lib/attendanceInsights";

import "./AbsenceGauge.scss";

export const AbsenceGauge: FunctionComponent<AbsenceGaugeProp> = ({
  absencePercent,
  durationText,
  isLoading
}) => {
  const [AbsentPercent, setAbsentPercent] = useState<string>();
  const [clicked, setClicked] = useState(false);
  const [dashOffset, setDashOffset] = useState(0);
  const pathLength = useMotionValue(0);

  useEffect(() => {
    if (absencePercent === undefined) {
      // setDashOffset(DASH_OFFSET);
      setAbsentPercent(undefined);
      return;
    }
    // offset wants to happen from a reverse direction
    setDashOffset(absencePercent / 100);
    setAbsentPercent(roundPercentForInsights(absencePercent).toString());
  }, [absencePercent]);

  const gaugeVariants = {
    initial: {
      pathLength: 0.002
    },
    filled: {
      pathLength: dashOffset
    }
  };

  const handleClick = (): void => {
    setClicked(!clicked);
  };

  return (
    <AttendanceMiniCard>
      <div className="absence-gauge" onClick={() => handleClick()}>
        <motion.svg
          width="128"
          height="72"
          viewBox="0 0 137 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M132.646 68.0749C132.646 32.7287 103.992 4.07492 68.6455 4.07492C33.2993 4.07492 4.64553 32.7287 4.64553 68.0749"
            stroke={styles.brandGray90}
            strokeWidth="8"
            strokeLinecap="round"
          />
          <motion.path
            d="M4 68C4 51.0261 10.7428 34.7475 22.7452 22.7452C34.7475 10.7428 51.0261 4 68 4C84.9738 4 101.252 10.7428 113.255 22.7452C125.257 34.7475 132 51.0261 132 68"
            stroke={styles.brandTeal56}
            strokeWidth="8"
            strokeLinecap="round"
            className="percent"
            variants={gaugeVariants}
            style={{ pathLength }}
            animate={isLoading ? "initial" : "filled"}
            transition={{ default: { ease: "easeInOut" } }}
          />
        </motion.svg>
        <h1>{isLoading ? <Skeleton width={64} /> : `${AbsentPercent}`}</h1>
        <h5 className="mb-0">
          {isLoading ? <Skeleton width={150} /> : `Average for ${durationText}`}
        </h5>
      </div>
    </AttendanceMiniCard>
  );
};

type AbsenceGaugeProp = {
  isLoading: boolean;
  absencePercent?: number;
  durationText?: string;
};
