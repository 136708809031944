import React, { forwardRef, FunctionComponent, MouseEventHandler } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DatePicker, { CalendarContainer } from "react-datepicker";

import { DateTime } from "luxon";

import CalendarIcon from "~/images/calendar-select-icon.svg";
import "./TSDateSelectorButton.scss";

const DateButton = forwardRef<HTMLButtonElement, DateButtonProps>(
  ({ onClick, selectedDate }, ref) => {
    let dateString = "";
    try {
      dateString = selectedDate.toLocaleString({ ...DateTime.DATE_MED });
    } catch (e) {
      dateString = selectedDate.toLocaleString({
        ...DateTime.DATE_MED,
        locale: "en-US"
      });
    }
    return (
      <button ref={ref} className="date-button" onClick={onClick}>
        {" "}
        {dateString} <img src={CalendarIcon} alt="" />{" "}
      </button>
    );
  }
);

const MyContainer: FunctionComponent<ContainerProps> = ({
  className,
  children
}) => {
  return (
    <div className="attendance-container-picker">
      <Container fluid>
        <Row>
          <Col>
            <CalendarContainer className={className}>
              <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const TSDateSelectorButton: FunctionComponent<
  DateSelectorButtonProps
> = ({ selectedDate, onDateSelected }) => {
  return (
    <DatePicker
      portalId="root"
      selected={selectedDate.toJSDate()}
      popperClassName="properAttendanceContainer"
      popperPlacement="bottom-end"
      onChange={date => {
        if (!(date instanceof Array)) {
          if (date) {
            onDateSelected(DateTime.fromJSDate(date));
          }
        }
      }}
      customInput={<DateButton selectedDate={selectedDate} />}
      calendarContainer={MyContainer}
    />
  );
};

interface BaseDateSelectorProps {
  selectedDate: DateTime;
}
interface DateButtonProps extends BaseDateSelectorProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

interface DateSelectorButtonProps extends BaseDateSelectorProps {
  onDateSelected: (x: DateTime) => any;
}

interface ContainerProps {
  className: string;
  children: any;
}
