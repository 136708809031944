import React, { FunctionComponent } from "react";

import clsx from "clsx";
import Mustache from "mustache";
import RMCPicker from "rmc-picker/lib";

import * as api from "~/api";
import { useEffectOnce } from "~/lib/hooks";
import { floatRange, formatRangeValue } from "~/lib/ranges";

import "./RMCPicker.scss";

export interface NumberQuestionProps {
  // Context for rendering template text.
  context: api.TemplateStringContext;
  // Prompt string.
  templateText: string;
  // Object containing hint, hintUnit, hintInitial, increment, kind, maxValue, minValue,
  step: api.NumberQuestion;
  // Initial value, if any.
  value?: number;
  // If true, no longer allow interaction.
  disabled?: boolean;
  // Change handler that always shows the latest selected set.
  onChange: (value: number) => any;
}

export const NumberQuestion: FunctionComponent<NumberQuestionProps> = props => {
  const { value, disabled, onChange, templateText, context, step } = props;

  const pickerDefault = step.hintInitial ?? step.minValue;
  const pickerValues = floatRange(step.minValue, step.maxValue, step.increment);

  // Give calling components an opportunity to get initial state.
  useEffectOnce(() => {
    if (disabled) {
      return;
    }
    onChange(pickerDefault);
  });

  return (
    <>
      <div
        className={clsx(
          "inline-block leading-tight rounded-lg p-3",
          "bg-gray-100 text-hs-dark"
        )}
      >
        <p>{Mustache.render(templateText, context)}</p>
      </div>

      <div
        className={clsx("select-none my-3", disabled && "pointer-events-none")}
        style={{
          touchAction: "none",
          WebkitUserSelect: "none"
        }}
      >
        <RMCPicker
          prefixCls={"rmc-picker-light"}
          defaultSelectedValue={pickerDefault}
          selectedValue={value}
          onValueChange={onChange}
        >
          {pickerValues?.map((item, index) => (
            <RMCPicker.Item value={item} key={index}>
              {formatRangeValue(step, item, context)}
            </RMCPicker.Item>
          ))}
        </RMCPicker>
      </div>
    </>
  );
};
