import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect
} from "react";
import { useHistory } from "react-router-dom";

import { Chip } from "@mui/material";
import { Warning, WarningDiamond } from "@phosphor-icons/react";

import { AttendanceEvent } from "../employees/attendance/AttendanceEvent";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { getFormattedDate, DateFormat } from "~/lib/dateFormatter";
import { showDivisions } from "~/lib/showDivisions";
import { getLabelColorDarkened } from "~/lib/status";
import MuiButton from "~/mui-components/Button/Button";
import Typography from "~/mui-components/Typography/Typography";
import { BundledEventsWithSurveys } from "~/types/attendance";
import { TSCenteredLoadingSpinner } from "~common";

import "./AttendanceDetails.scss";

export const AttendanceDetails: FunctionComponent<AttendanceDetailsProps> = ({
  employee,
  eventsAndSurveys,
  onEventStatusSync
}) => {
  const history = useHistory();
  const { session } = useContext(MutableSessionContext);
  const [attendancePoints, setAttendancePoints] = useState<
    api.AttendancePoints | null | undefined
  >(undefined);
  const [attendancePolicy, setAttendancePolicy] =
    useState<api.AttendancePolicy | null>(null);
  const [pillProps, setPillProps] = useState<
    { icon: JSX.Element; color: string; background: string } | undefined
  >();

  const fetchAttendancePolicy = (): void => {
    if (session?.company?.id && employee?.id) {
      api
        .getAttendancePolicy(session.company.id, employee?.id)
        .then(response => {
          if (response.ok && "data" in response) {
            setAttendancePolicy(response.data);
          }
        });
    }
  };

  const fetchEventsAndPoints = (): void => {
    if (session?.company?.id && employee?.id) {
      api
        .getEmployeeAttendancePoints(session.company.id, employee?.id)
        .then(response => {
          if (
            response.ok &&
            "data" in response &&
            response.data.attendancePoints
          ) {
            setAttendancePoints(response.data.attendancePoints);
          }
        });
    }
  };

  const getThresholdColorAndIcon = (
    attendancePolicy: api.AttendancePolicy,
    pointsNumber: number
  ): { color: string; icon: JSX.Element; background: string } => {
    const thresholds = attendancePolicy?.points_thresholds?.sort(
      (a, b) => b.points - a.points
    );
    const colorAndIconByType = {
      safe: {
        icon: <></>,
        color: "#0A2C29",
        background: "#DFE2E1"
      },
      light: {
        color: "#0A2C29",
        background: "#FFD13B",
        icon: (
          <Warning className="min-w-4 min-h-4" style={{ color: "#0A2C29" }} />
        )
      },
      severe: {
        color: "#FFFFFF",
        background: "#AD0000",
        icon: (
          <WarningDiamond
            className="min-w-4 min-h-4"
            style={{ color: "#FFFFFF" }}
          />
        )
      },
      termination: {
        color: "#FFFFFF",
        background: "#AD0000",
        icon: (
          <WarningDiamond
            className="min-w-4 min-h-4"
            style={{ color: "#FFFFFF" }}
          />
        )
      }
    };

    const threshold = thresholds.find(t => pointsNumber >= t.points);
    if (threshold) {
      return colorAndIconByType[threshold.type];
    }
    return colorAndIconByType.safe;
  };

  useEffect(() => {
    if (attendancePolicy && attendancePoints) {
      const props = getThresholdColorAndIcon(
        attendancePolicy,
        attendancePoints?.count || 0
      );
      setPillProps(props);
    }
  }, [attendancePolicy, attendancePoints]);

  useEffect(() => {
    fetchAttendancePolicy();
    fetchEventsAndPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const goToEmployee = (employeeId: string): void => {
    if (employeeId) {
      history.push(`/dashboard/new-employees/employee/${employeeId}`);
    }
  };

  const ExternalIdAndDivision = (): JSX.Element => {
    const externalId = employee?.external_id;
    const divisionName = showDivisions(session) ? employee?.division?.name : "";
    const CustomTypography = ({
      content,
      classes = ""
    }: {
      content: string;
      classes?: string;
    }): JSX.Element => (
      <Typography variant="h4" className={`text-ts-gray-40 ${classes}`}>
        {content}
      </Typography>
    );
    let content = <></>;
    if (externalId && divisionName) {
      content = (
        <>
          <CustomTypography content={externalId} />
          <CustomTypography content="-" classes="hidden xl:inline-flex" />
          <CustomTypography content={divisionName} />
        </>
      );
    } else if (externalId && !divisionName) {
      content = <CustomTypography content={externalId} />;
    } else if (!externalId && divisionName) {
      content = <CustomTypography content={divisionName} />;
    }
    return <div className="flex gap-1 flex-col xl:flex-row">{content}</div>;
  };

  const Header = ({
    employee
  }: {
    employee?: api.SimpleEmployee;
  }): JSX.Element => {
    return (
      <div className="flex flex-col gap-2 bg-ts-gray-90 border-t border-l border-r rounded-t-lg border-ts-gray-70 p-6">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-2">
            {employee?.name && (
              <a
                href={`/dashboard/new-employees/employee/${employee?.id}`}
                className="!no-underline"
              >
                <Typography variant="h2" className="text-ts-teal-20">
                  {employee?.name || ""}
                </Typography>
              </a>
            )}
            <ExternalIdAndDivision />
          </div>
          <div>
            <MuiButton
              color="secondary"
              size="small"
              onClick={() => goToEmployee(employee?.id || "")}
            >
              View Profile
            </MuiButton>
          </div>
        </div>
        {attendancePolicy && attendancePoints?.count && (
          <>
            <Chip
              className="mr-2 px-1 w-fit mt-2"
              size="small"
              style={{
                color: pillProps?.color,
                backgroundColor: pillProps?.background
              }}
              label={
                <Typography
                  variant="h4"
                  className="flex flex-row items-center gap-x-1"
                >
                  {pillProps?.icon}
                  {attendancePoints?.count} pts
                </Typography>
              }
            ></Chip>
            <Typography variant="h4" className="text-ts-gray-50">
              Points as of{" "}
              {getFormattedDate(
                attendancePoints?.updated || "",
                DateFormat.dateTime12HourWithTimeZone
              )}{" "}
              <span className="italic">(prior to this absence)</span>
            </Typography>
          </>
        )}
      </div>
    );
  };

  const attendanceEvents =
    employee &&
    eventsAndSurveys?.map(item => {
      const attendanceEvent = {
        event: {
          ...item.events[0],
          employee_id: employee.id
        },
        color: getLabelColorDarkened(item.events[0].label.name, session.labels),
        isOpen: true,
        survey: item.survey
      };

      return (
        <div key={`attendance-event-${item.events[0].id}`} className="mb-4">
          <AttendanceEvent
            attendanceEvent={attendanceEvent}
            employee={employee as api.Employee}
            automaticSync={session.features?.attendance_event_integration}
            onEventStatusSync={onEventStatusSync}
          />
        </div>
      );
    });

  return (
    <div>
      <Header employee={employee} />
      <div className="p-3 border-1 border-ts-gray-70 rounded-b-lg">
        {attendanceEvents ?? (
          <TSCenteredLoadingSpinner className="spinner" delaySeconds={1} />
        )}
      </div>
    </div>
  );
};

interface AttendanceDetailsProps {
  onEventStatusSync: (
    eventId: string,
    status: api.AlteredEventSyncStatus
  ) => void;
  employee?: api.SimpleEmployee;
  eventsAndSurveys?: BundledEventsWithSurveys[];
}
