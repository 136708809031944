import React, { FunctionComponent } from "react";

import { Link } from "../Link/Link";

import { PhoneInput } from "./PhoneInput";
import { ValidatedPhoneNumber, ValidatedString } from "./types";

import { TextInput, emailFormatRegex } from "~common";

interface EmployeeCheckInBodyProps {
  phone?: ValidatedPhoneNumber;
  email?: ValidatedString;
  onPhoneChange: (phone?: ValidatedPhoneNumber) => any;
  onEmailChange: (email?: ValidatedString) => any;
  onEnter: () => any;
}

export const EmployeeCheckInBody: FunctionComponent<
  EmployeeCheckInBodyProps
> = ({ phone, email, onPhoneChange, onEmailChange, onEnter }) => (
  <>
    <div className="mt-2 pr-2 text-xs font-medium">
      Already using TeamSense? Enter your information and we will send your
      check-in!
    </div>

    <div className="mt-4 ml-3 text-lg font-medium">Mobile Number</div>
    <PhoneInput
      autoFocus
      className="mt-2 py-2 px-5 rounded border border-hs-dark-gray focus-within:border-hs-green"
      inputClassName="font-semibold"
      inputStyle={{ fontSize: "18px" }}
      value={phone?.value ?? ""}
      onChange={(value, valid, international) => {
        onPhoneChange({ value, valid, international });
        if (value) {
          onEmailChange();
        }
      }}
      onEnter={onEnter}
    />
    <div className="mt-4 pr-2 text-xs font-medium tracking-normal text-hs-dark-gray">
      By providing your mobile number, you agree that TeamSense may use it to
      text you a link to check-in. Message and data rates may apply.
    </div>

    <div className="w-full mt-1 text-lg font-medium text-center">or</div>
    <div className="mt-1 ml-3 text-lg font-medium">Email</div>
    <TextInput
      className="mt-2 py-2 px-5 wk-appearance-none rounded border border-hs-dark-gray focus-within:border-hs-green text-lg font-semibold"
      validationRegex={emailFormatRegex}
      inputType="email"
      value={email?.value ?? ""}
      onChange={(value, valid) => {
        onEmailChange({ value, valid });
        if (value) {
          onPhoneChange();
        }
      }}
      onEnter={onEnter}
    />

    <div className="mt-8 text-xs text-hs-alt-darker-gray">
      <Link
        external
        to="https://www.teamsense.com/terms-of-service"
        className="text-hs-dark-gray underline"
      >
        Terms of Service
      </Link>
      {" & "}
      <Link
        external
        to="https://www.teamsense.com/privacy-policy"
        className="text-hs-dark-gray underline"
      >
        Privacy Policy
      </Link>
    </div>
  </>
);
