import React, { FunctionComponent, ReactNode } from "react";

import clsx from "clsx";

interface MessageProps {
  className?: string;
  text?: string | ReactNode;
}

export const Message: FunctionComponent<MessageProps> = props => {
  const { className, text } = props;
  return (
    <div
      className={clsx(
        "bg-gray-100 inset-x-0 p-3 text-hs-dark-green",
        className
      )}
    >
      {text}
    </div>
  );
};
