import React, { FunctionComponent, ReactNode, useState } from "react";

import { t } from "@lingui/macro";
import { I18n, Trans } from "@lingui/react";
import clsx from "clsx";

import * as api from "~/api";
import { ValidatedString } from "~/components/EmployeeCheckInRequest/types";
import { LanguageDropdown } from "~/components/LanguageDropdown/LanguageDropdown";
import { TermsAndPrivacy } from "~/components/RegisterEmployee/TermsAndPrivacy";
import EmployeeIcon from "~/images/employee-icon.svg";
import {
  GenericErrorText,
  Loading,
  MessageActionButton,
  TextInput,
  TokenErrorText
} from "~common";

interface EmployeeIDProps {
  className?: string;
  language?: string;
  company?: api.Company;
  requesting: boolean;
  error?: Error;
  onChangeLanguage?: (lang: string) => any;
  onConfirm: (employeeId: string) => any;
}

export const EmployeeID: FunctionComponent<EmployeeIDProps> = ({
  className,
  language,
  company,
  requesting,
  error,
  onChangeLanguage,
  onConfirm
}) => {
  const [employeeId, setEmployeeId] = useState<ValidatedString>();
  const identifyFlow = !company;
  let summaryMessage: ReactNode;
  if (company) {
    const { name: companyName } = company;
    summaryMessage = (
      <Trans id="register.employeeId.summary" values={{ companyName }}>
        First, let us know your {companyName} employee ID
      </Trans>
    );
  } else {
    summaryMessage = (
      <Trans id="identify.employeeId.summary" components={[<br key="1" />]}>
        Hi! Your number looks new to us, so let’s get to know each other a bit.
        <br />
        First, let us know your employee ID
      </Trans>
    );
  }

  let errorMessage: ReactNode;
  if (error?.name === "tokenError") {
    errorMessage = <TokenErrorText />;
  } else if (error?.name === "unknown") {
    errorMessage = (
      <Trans id="identify.employeeId.error">
        We don't recognize that ID. Please double check and try again. If you're
        sure the ID is correct, please contact HR to add your ID into TeamSense.
      </Trans>
    );
  } else if (error?.name === "conflict") {
    errorMessage = (
      <Trans id="identify.employeeId.conflict">
        Sorry, we can’t look up your ID with this method right now. Please
        contact your HR Admin to have them update your phone number.
      </Trans>
    );
  } else {
    errorMessage = <GenericErrorText />;
  }

  return (
    <div
      className={clsx(
        "h-full w-full flex-grow flex flex-col justify-between",
        className
      )}
    >
      <div className="px-9 pt-9">
        {language && onChangeLanguage && (
          <div className="flex flex-row justify-end">
            <div className="w-34">
              <LanguageDropdown
                allLanguages
                containerClassName="mb-2 rounded border border-hs-dark-gray bg-white"
                language={language}
                onChange={onChangeLanguage}
              />
            </div>
          </div>
        )}

        <p className="text-2xl font-bold text-hs-dark-green">
          {summaryMessage}
        </p>
      </div>
      <div className="bg-hs-light p-9 rounded-t-4xl">
        <div className="flex flex-row items-center mb-2">
          <img className="mr-4" src={EmployeeIcon} alt="Employee" />
          <p className="text-2xl font-semibold text-hs-dark-green">
            <Trans id="register.employeeId.label">Employee ID:</Trans>
          </p>
        </div>

        <I18n>
          {({ i18n }) => (
            <TextInput
              autoFocus
              className={clsx(
                "my-3 py-2 px-5 border rounded text-lg w-full",
                !error && "border-hs-dark-gray focus:border-hs-green",
                error && "border-hs-error-red"
              )}
              validationRegex={/^.+$/}
              value={employeeId?.value ?? ""}
              placeholder={
                identifyFlow
                  ? i18n._(t("identify.employeeId.placeholder")`Employee ID`)
                  : i18n._(
                      t(
                        "register.employeeId.placeholder"
                      )`Enter your employee ID`
                    )
              }
              onChange={(value, valid) => {
                setEmployeeId({ value, valid });
              }}
              onEnter={() => employeeId?.valid && onConfirm(employeeId?.value)}
            />
          )}
        </I18n>
        {error && errorMessage && (
          <p className="my-3 text-xs text-hs-error-red">{errorMessage}</p>
        )}

        {requesting && <Loading />}

        <div className="py-5">
          <MessageActionButton
            theme="dark-green"
            disabled={!employeeId?.valid}
            onClick={() => onConfirm(employeeId?.value ?? "")}
            text={<Trans id="register.employeeId.confirm">Confirm ID</Trans>}
          />
        </div>
        <div className="flex flex-row justify-center">
          <TermsAndPrivacy />
        </div>
      </div>
    </div>
  );
};
