import React, { FunctionComponent } from "react";

import { HeatMapTile } from "./HeatMapTile";

import * as api from "~/api";
import { orderFrames } from "~/lib/attendanceInsights";
import "./WeeklyHeatMap.scss";

export const WeeklyHeatMap: FunctionComponent<WeeklyInterface> = ({ data }) => {
  data && orderFrames(data);

  return (
    <div className="heatmap-weekly-grid">
      {data &&
        data.map((week, index) => {
          return <HeatMapTile key={index} frame={week} type="week" />;
        })}
    </div>
  );
};

interface WeeklyInterface {
  data?: api.Frame[];
}
