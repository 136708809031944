export const employeesUrl = (companyId: string): string =>
  `/companies/${companyId}/employees/`;

export const employeeModifyUrl = (
  companyId: string,
  employeeId: string
): string => `/companies/${companyId}/employees/${employeeId}/`;

export const timezonesRetrieveUrl = (companyId: string): string =>
  `/companies/${companyId}/timezones/`;

export const permissionMappings: Record<string, string | string[]> = {};

export const groupsRetrieveUrl = (companyId: string): string => {
  const url = `/companies/${companyId}/groups/?simple=true`;
  permissionMappings[url] = "Groups.READ";
  return url;
};

export const employeeRolesRetrieveUrl = (
  companyId: string,
  employeeId = ""
): string =>
  `/companies/${companyId}/employee_roles/${
    employeeId ? `?employee_id=${employeeId}` : ""
  }`;

export const recipientCountUrl = (): string => `/messaging/count-recipients/`;
