import React, { FunctionComponent, useState } from "react";
import { Form } from "react-bootstrap";
import PhoneInput2 from "react-phone-input-2";

import PhoneNumber from "awesome-phonenumber";
import clsx from "clsx";

import "./TSPhoneInput.scss";

export const TSPhoneInput: FunctionComponent<TSPhoneInputProps> = ({
  errorText,
  groupClass,
  helpText,
  id,
  isInvalid = false,
  label,
  onChange,
  required,
  value
}) => {
  const [typing, setTyping] = useState(false);
  const [validationTimeout, setValidationTimeout] = useState<NodeJS.Timeout>();

  // Event Handlers
  const validate = (value: string, data: PICountryData | {}): void => {
    validationTimeout && clearTimeout(validationTimeout);
    setTyping(false);

    const phoneNumber = new PhoneNumber(
      value,
      "countryCode" in data ? data.countryCode : undefined
    );

    onChange &&
      onChange(
        value,
        value.length > 0 ? phoneNumber.isValid() : true,
        phoneNumber.getNumber("e164") ?? value
      );
  };

  const changeHandler = (value: string, data: PICountryData | {}): void => {
    // clear the error feedback while they're typing
    setTyping(true);

    // reset the timer
    validationTimeout && clearTimeout(validationTimeout);
    setValidationTimeout(
      setTimeout(() => {
        // check validity when typing pauses
        validate(value, data);
      }, 300)
    );
  };

  return (
    <Form.Group controlId={id} className={clsx("ts-input", groupClass)}>
      {label && (
        <Form.Label>
          {required && <span>＊</span>}
          {label}
        </Form.Label>
      )}

      <PhoneInput2
        preferredCountries={["us", "ca", "mx"]}
        preserveOrder={["preferredCountries"]}
        country="us"
        isValid={!isInvalid}
        value={value}
        onChange={(value, data) => changeHandler(value, data)}
      />

      {errorText && !typing && isInvalid && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errorText}
        </Form.Control.Feedback>
      )}

      {helpText && !errorText && (
        <Form.Text id={`${id}HelpBlock`} className="text-muted">
          {helpText}
        </Form.Text>
      )}
    </Form.Group>
  );
};

// This should be exported by react-phone-input-2, but it's not:
interface PICountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

interface TSPhoneInputProps {
  className?: string;
  errorText?: string;
  groupClass?: string;
  helpText?: string;
  id?: string;
  inputClassName?: string;
  isInvalid?: boolean;
  label?: string;
  onChange?: (value: string, valid: boolean, international: string) => any;
  required?: boolean;
  value?: string;
}
