import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export const Depth = (): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <h1>Depth</h1>
        <Row>
          <Col>
            <Card className="shadow text-center p-12">Depth 1</Card>
          </Col>
          <Col>
            <Card className="shadow-lg text-center p-12">Depth 2</Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
