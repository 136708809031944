import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import { TermsAndPrivacy } from "./TermsAndPrivacy";

import * as api from "~/api";
import GreenRegisterWelcomeIllustration from "~/images/green-register-welcome-illustration.svg";

interface SuccessProps {
  className?: string;
  company?: api.Company;
  friendlyName: string;
  goodToGoTitle: boolean;
  providedInfo?: boolean;
  hideDetail?: boolean;
}

export const Success: FunctionComponent<SuccessProps> = ({
  className,
  company = {},
  friendlyName,
  goodToGoTitle,
  providedInfo,
  hideDetail
}) => {
  const { name: companyName } = company;

  return (
    <div
      className={clsx(
        "h-full w-full flex-grow flex flex-col justify-between",
        className
      )}
    >
      <div>
        <div className="bg-hs-light p-9 rounded-b-4xl">
          <p className="pb-6 text-2xl font-bold text-hs-dark-green">
            {goodToGoTitle && (
              <Trans id="register.success.summary" values={{ friendlyName }}>
                Thanks, {friendlyName}! You are good to go.
              </Trans>
            )}
            {!goodToGoTitle && (
              <Trans id="register.noInfo.summary" values={{ friendlyName }}>
                Thanks, {friendlyName}
              </Trans>
            )}
          </p>
          {!hideDetail && (
            <p className="mb-16 text-base leading-tight text-hs-even-darker-gray">
              {providedInfo && (
                <Trans id="register.success.detail">
                  You can expect to see your first message from TeamSense in the
                  coming days.
                </Trans>
              )}
              {!providedInfo && (
                <Trans id="register.noInfo.detail" values={{ companyName }}>
                  Please let your manager at {companyName} know that you may
                  need an accommodation to be screened by someone else. You are
                  good to go, and can exit your web browser.
                </Trans>
              )}
            </p>
          )}
        </div>

        <img
          className="py-4 ml-auto"
          src={GreenRegisterWelcomeIllustration}
          alt="Welcome Illustration"
        />
      </div>

      <div className="mt-3 mb-12 flex flex-row justify-center">
        <TermsAndPrivacy />
      </div>
    </div>
  );
};
