import { APIResponse, post } from "./lib";

export interface User {
  id: string;
  email: string;
  is_staff: boolean;
  url: string;
}

export interface PasswordData {
  current_password: string;
  password1: string;
  password2: string;
}

export const updatePassword = async (
  data: PasswordData
): Promise<APIResponse<User>> => {
  return await post(`/users/change-password/`, data);
};
