import React, { FunctionComponent, useState } from "react";

import * as api from "~/api";
import { EntitiesDropdown, ConfirmationModal, ModalProps } from "~common";

interface RemoveFromGroupsModalProps extends ModalProps {
  employeeIds: string[];
  groups: api.Group[];
  onConfirm?: (groups: api.Group[]) => any;
}

export const RemoveFromGroupsModal: FunctionComponent<
  RemoveFromGroupsModalProps
> = ({ employeeIds, groups, onClose, onConfirm }) => {
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>(
    groups.map(({ id }) => id)
  );

  const removedGroups = groups.filter(
    ({ id }) => !selectedGroupIds.includes(id)
  );

  const multipleEmployees = employeeIds.length > 1;
  const prompt = `Remove ${
    multipleEmployees ? "employees" : "employee"
  } from groups`;

  const explanation =
    removedGroups.length === 0
      ? "Click x to remove a group"
      : `${
          multipleEmployees ? "Employees" : "The employee"
        } will be removed from these groups:`;

  const undoGroupRemoval: (id: string) => void = undoGroupId => {
    const undoGroup = removedGroups.find(({ id }) => id === undoGroupId);
    if (undoGroup) {
      setSelectedGroupIds([...selectedGroupIds, undoGroupId]);
    }
  };

  return (
    <ConfirmationModal
      className="overflow-visible"
      prompt={prompt}
      onClose={onClose}
      onConfirm={() => onConfirm?.(removedGroups)}
      confirmDisabled={removedGroups.length === 0}
    >
      <p className="text-sm pt-1 pb-3">
        Current employee groups: (remove from this list)
      </p>
      <EntitiesDropdown
        allEntities={groups}
        entityIds={selectedGroupIds}
        onChange={setSelectedGroupIds}
      />
      <p className="text-sm pt-3 pb-1">{explanation}</p>
      {removedGroups.length > 0 && (
        <div className="text-sm space-y-1">
          {removedGroups.map(group => (
            <div className="flex space-x-2" key={group.id}>
              <div className="py-1 px-2 bg-hs-alt-gray text-hs-gray-20">
                {group.name}
              </div>
              <div
                className="my-auto cursor-pointer underline text-hs-dark-green hover:text-hs-green"
                onClick={() => undoGroupRemoval(group.id)}
              >
                Undo
              </div>
            </div>
          ))}
        </div>
      )}
    </ConfirmationModal>
  );
};
