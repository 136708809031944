import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useMemo
} from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import { TodaysDirectReportsSummary } from "./TodaysDirectReportsSummary";

import * as api from "~/api";
import { Bullet } from "~/components/Bullet/Bullet";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import {
  EmployeeLabels,
  getLabelColor,
  getLabelDisplay,
  statusSortRank
} from "~/lib/status";

interface TodaysDirectReportsProps {
  className?: string;
  name: string;
  statuses: EmployeeLabels[];
  onClickEmployee?: (employee: api.Employee) => any;
}

interface TableRowProps {
  className?: string;
  name: ReactNode;
  status: ReactNode;
  onClick?: () => any;
}

const TableRow: FunctionComponent<TableRowProps> = ({
  className,
  name,
  status,
  onClick
}) => (
  <div
    className={clsx(
      "flex items-center justify-between border-b border-hs-alt-gray mx-2 pl-9 pr-8",
      className
    )}
    onClick={onClick}
  >
    {name}
    <div className="flex flex-col">{status}</div>
  </div>
);

const EmployeeRowHeader: FunctionComponent = () => (
  <TableRow
    className="text-sm text-hs-darker-gray h-12"
    name={<Trans id="roster.header.name">Name</Trans>}
    status={<Trans id="roster.header.status">Status</Trans>}
  />
);

const EmployeeRow: FunctionComponent<{
  status: EmployeeLabels;
  onClick?: () => any;
}> = ({ status, onClick }) => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const statusNode = (
    <div className="space-y-1">
      {status.labelKeys.map(key => (
        <div className="w-20 xxs:w-28 flex flex-row items-center" key={key}>
          <Bullet className="mr-1" color={getLabelColor(key, session.labels)} />
          <div className="uppercase text-xs">
            {getLabelDisplay(key, session.labels, language)}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <TableRow
      className={clsx("h-16", onClick && "cursor-pointer")}
      name={status.employee.name}
      status={statusNode}
      onClick={onClick}
    />
  );
};

export const TodaysDirectReports: FunctionComponent<
  TodaysDirectReportsProps
> = props => {
  const { className, name, statuses, onClickEmployee } = props;
  const { session } = useContext(MutableSessionContext);

  const sortedStatuses = useMemo(
    () =>
      [...statuses].sort(
        (statusA, statusB) =>
          statusSortRank(statusB, session.labels) -
          statusSortRank(statusA, session.labels)
      ),
    [session.labels, statuses]
  );

  const statusCounts = useMemo(() => {
    const result: { [name: string]: number } = {};
    statuses.forEach(status => {
      status.labelKeys.forEach(key => {
        result[key] = (result[key] ?? 0) + 1;
      });
    });
    return result;
  }, [statuses]);

  return (
    <div className={clsx("w-screen min-w-screen select-none", className)}>
      <TodaysDirectReportsSummary name={name} statusCounts={statusCounts} />
      <EmployeeRowHeader />
      {sortedStatuses.map(status => (
        <EmployeeRow
          status={status}
          key={status.employee.id}
          onClick={() => onClickEmployee?.(status.employee)}
        />
      ))}
    </div>
  );
};
