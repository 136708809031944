import React, { FunctionComponent, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { useEffectOnce } from "~/lib/hooks";
import { Survey } from "~/views/Survey";
import { SurveyAbsenceHistory } from "~/views/SurveyAbsenceHistory";

interface ParamTypes {
  token: string;
}

export const PreSurvey: FunctionComponent = () => {
  const { session } = useContext(MutableSessionContext);
  /*
   * Determine which pre-survey component to show, if applicable
   * Show the survey if none of the pre-survey components are happening
   */
  const [showAttendanceHistory, setShowAttendanceHistory] = useState<boolean>();

  const shouldShowAttendanceHistory = async (token: string): Promise<void> => {
    let showHistory = false;
    let response: api.APIResponse<Boolean> | undefined;
    try {
      response = await api.retrieveShowAttendanceHistory(
        session.company?.id,
        session.employee?.id,
        token
      );
    } catch {}

    if (response?.ok) {
      showHistory = response.data as boolean;
    }
    setShowAttendanceHistory(showHistory);
  };

  const shouldShowSurvey = (): boolean => showAttendanceHistory === false;
  const { token: paramsToken } = useParams<ParamTypes>();

  useEffectOnce(() => {
    shouldShowAttendanceHistory(paramsToken);
  });

  return (
    <>
      {showAttendanceHistory && (
        <SurveyAbsenceHistory
          setShowAttendanceHistory={setShowAttendanceHistory}
        />
      )}
      {shouldShowSurvey() && <Survey />}
    </>
  );
};
