import React, { FunctionComponent, useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Trans } from "@lingui/react";
import { DateTime } from "luxon";

import * as api from "~/api";
import {
  ArrowPicker,
  BackButton,
  CalendarMonth,
  EditNotifications,
  EmployeeHeader,
  SmallButton,
  ToggleControl
} from "~/components";
import { DeleteAttendanceStatusModal } from "~/components/DeleteAttendanceStatusModal/DeleteAttendanceStatusModal";
import { ScheduleAttendanceStatusModal } from "~/components/ScheduleAttendanceStatusModal/ScheduleAttendanceStatusModal";
import CalendarIconDark from "~/images/calendar-icon-dark.svg";
import CalendarIcon from "~/images/calendar-icon.svg";
import NotificationsIconDark from "~/images/notifications-icon-dark.svg";
import NotificationsIcon from "~/images/notifications-icon.svg";
import { MutableSessionContext } from "~/lib/context";
import { useEffectOnce, useLinguiLanguage } from "~/lib/hooks";
import { saveScheduleChange } from "~/lib/schedule";
import {
  deleteAttendanceStatusEvent,
  saveAttendanceStatusChange
} from "~/lib/status";
import { Loading, GenericErrorText } from "~common";

interface ScheduleAttendanceStatusModalState {
  visible: boolean;
  event?: api.EmployeeEvent;
  defaultStartDate?: DateTime;
}

/**
 * The mobile view for a manager to edit an employee's schedule.
 */
export const EditEmployeeSchedule: FunctionComponent<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [notificationsDirty, setNotificationsDirty] = useState(false);
  const [employee, setEmployee] = useState<api.Employee>();
  const [currentWeekdayTimes, setCurrentWeekdayTimes] =
    useState<api.WeeklySchedule>();
  const [dateTimeCalendar, setDateTimeCalendar] = useState(DateTime.local());
  const [notificationsPaneActive, setNotificationsPaneActive] = useState(false);
  const [scheduleAttendanceStatusModal, setScheduleAttendanceStatusModal] =
    useState<ScheduleAttendanceStatusModalState>({ visible: false });
  const [deleteAttendanceStatusModal, setDeleteAttendanceStatusModal] =
    useState<api.EmployeeEvent>();
  const language = useLinguiLanguage();

  interface ParamTypes {
    employeeId: string;
  }
  const { employeeId } = useParams<ParamTypes>();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get("t") ?? undefined;

  const { session } = useContext(MutableSessionContext);

  useEffectOnce(() => {
    if (session.company?.id) {
      loadEmployee(session.company.id);
    }
  });

  const loadEmployee = async (companyId: string): Promise<void> => {
    let response: api.APIResponse<api.Employee> | undefined;
    setLoading(true);
    try {
      response = await api.retrieveEmployee(companyId, employeeId, {
        token,
        events: true
      });
    } catch {}
    setLoading(false);

    if (!response?.ok) {
      toast.error(<GenericErrorText />);
      return;
    }

    setEmployee(response.data);
  };

  const onPickForward = (): void =>
    setDateTimeCalendar(dateTimeCalendar.plus({ months: 1 }));

  const onPickBack = (): void => {
    setDateTimeCalendar(dateTimeCalendar.minus({ months: 1 }));
  };

  const onAttendanceStatusSave = async (
    startDate: api.ISODateTime,
    endDate: api.ISODateTime,
    status: api.AttendanceStatus,
    note?: string
  ): Promise<void> => {
    const { event } = scheduleAttendanceStatusModal;
    if (!employee || !session.company?.id) {
      return;
    }
    if (
      await saveAttendanceStatusChange(
        employee,
        startDate,
        endDate,
        status,
        session.labels,
        language,
        note,
        event,
        {
          token
        }
      )
    ) {
      loadEmployee(session.company.id);
    }
  };

  const onClickAttendanceStatusDelete = (): void => {
    const { event } = scheduleAttendanceStatusModal;

    setScheduleAttendanceStatusModal({ visible: false });
    setDeleteAttendanceStatusModal(event);
  };

  const onAttendanceStatusDelete = async (): Promise<void> => {
    const event = deleteAttendanceStatusModal;
    if (!employee || !event || !session.company?.id) {
      return;
    }
    if (await deleteAttendanceStatusEvent(employee, event, { token })) {
      loadEmployee(session.company.id);
    }
  };

  const onClickDay = (day: DateTime, events?: api.EmployeeEvent[]): void => {
    const event = events?.[0];
    if (!event) {
      setScheduleAttendanceStatusModal({
        visible: true,
        defaultStartDate: day
      });
      return;
    }

    if (event?.label.label_set === api.LabelSet.AttendanceStatus) {
      setScheduleAttendanceStatusModal({ visible: true, event });
    }
  };

  const onSave = async (): Promise<void> => {
    if (!notificationsDirty) {
      history.goBack();
      return;
    }

    if (!employee || !currentWeekdayTimes) {
      return;
    }

    setLoading(true);
    const success = await saveScheduleChange({
      employee,
      schedule: { schedule: currentWeekdayTimes },
      options: {
        token
      }
    });
    setLoading(false);

    if (!success) {
      return;
    }

    history.goBack();
  };

  return (
    <div className="h-screen w-screen bg-white">
      {loading && <Loading />}
      <div className="bg-hs-light rounded-b-4xl">
        <div className="px-5 pt-6 pb-3 flex flex-row justify-between">
          <BackButton />
          {notificationsPaneActive && (
            <div
              className="mt-0.5 font-semibold text-hs-medium-dark-gray cursor-pointer"
              onClick={onSave}
            >
              <Trans id="common.save">Save</Trans>
            </div>
          )}
        </div>

        <div className="px-9 py-2" dir="auto">
          <div className="flex flex-row justify-between items-center pb-7">
            <EmployeeHeader employee={employee} />
          </div>
        </div>
      </div>
      <ToggleControl
        on={notificationsPaneActive}
        onToggle={setNotificationsPaneActive}
        nameOn="Notifications"
        imageSrcOn={NotificationsIcon}
        selectedImageSrcOn={NotificationsIconDark}
        nameOff="Calendar"
        imageSrcOff={CalendarIcon}
        selectedImageSrcOff={CalendarIconDark}
      />
      {!notificationsPaneActive && employee && (
        <>
          <div key="calendar" className="flex flex-row justify-center">
            <CalendarMonth
              year={dateTimeCalendar.year}
              monthOfYear={dateTimeCalendar.month}
              events={employee.events}
              onClickDay={onClickDay}
              compactSpacing
              timezone={employee.timezone}
              titleClassName="text-base font-bold px-3 pb-5 pt-8"
              topRightNode={
                <SmallButton
                  className="select-none text-hs-dark-green border-hs-dark-green text-xs tracking-wider font-bold uppercase"
                  onClick={() =>
                    setScheduleAttendanceStatusModal({ visible: true })
                  }
                >
                  <Trans id="roster.addStatus">Add Status</Trans>
                </SmallButton>
              }
            />
          </div>
          <ArrowPicker
            key="arrows"
            className="mb-8"
            onLeft={onPickBack}
            onRight={onPickForward}
          />
        </>
      )}
      {notificationsPaneActive && employee && (
        <EditNotifications
          employee={employee}
          token={token}
          insetDarkHeader
          onWeekdayTimesChange={setCurrentWeekdayTimes}
          onDirtyChange={setNotificationsDirty}
        />
      )}
      {employee && scheduleAttendanceStatusModal.visible && (
        <ScheduleAttendanceStatusModal
          mobileStyle
          event={scheduleAttendanceStatusModal.event}
          defaultStartDate={scheduleAttendanceStatusModal.defaultStartDate}
          timezone={employee.timezone}
          onClose={() => setScheduleAttendanceStatusModal({ visible: false })}
          onSave={onAttendanceStatusSave}
          onClickDelete={onClickAttendanceStatusDelete}
        />
      )}
      {employee && deleteAttendanceStatusModal && (
        <DeleteAttendanceStatusModal
          mobileStyle
          employee={employee}
          event={deleteAttendanceStatusModal}
          onDelete={onAttendanceStatusDelete}
          onClose={() => setDeleteAttendanceStatusModal(undefined)}
        />
      )}
    </div>
  );
};
