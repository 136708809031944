import React, { FunctionComponent, useContext } from "react";
import { useHistory } from "react-router-dom";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { useEffectOnce } from "~/lib/hooks/useEffectOnce";
import { Loading } from "~common";

export const Logout: FunctionComponent = () => {
  const history = useHistory();
  const { updateSession } = useContext(MutableSessionContext);

  useEffectOnce(() => {
    logout();
  });

  const logout = async (): Promise<void> => {
    await api.logout();
    updateSession(api.EMPTY_SESSION);
    sessionStorage.clear();
    history.push("/sign-in");
  };
  return <Loading />;
};
