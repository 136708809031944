import React from "react";
import { Card, Table } from "react-bootstrap";

export const Typography = (): JSX.Element => {
  return (
    <Card className="demo-typography">
      <Card.Body>
        <h1>Typography</h1>
        <Table responsive className="align-middle p-3">
          <thead>
            <tr className="text-muted text-xs">
              <th></th>
              <th>FONT SIZE / WEIGHT</th>
              <th>LINE HEIGHT</th>
              <th>USE CASE</th>
              <th className="col-2">LINE HEIGHT EXAMPLE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h2 className="mb-0">H1</h2>
              </td>
              <td>
                <h1>24 px / Semibold</h1>
              </td>
              <td>
                <h1>32 px</h1>
              </td>
              <td>
                <h1>Top Header like Edit, Add, Comm. Page, Modal Title</h1>
              </td>
              <td>
                <h1 className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  ut massa lacinia.
                </h1>
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">H2</h2>
              </td>
              <td>
                <h2 className="mb-0">18 px / Semibold</h2>
              </td>
              <td>
                <h2 className="mb-0">24 px</h2>
              </td>
              <td>
                <h2 className="mb-0">Secondary titles?</h2>
              </td>
              <td>
                <h2 className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  ut massa lacinia.
                </h2>
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">H3</h2>
              </td>
              <td>
                <h3>16 px/ Semibold</h3>
              </td>
              <td>
                <h3>24 px</h3>
              </td>
              <td>
                <h3>Top Navigation, Employee Info Card (Name)</h3>
              </td>
              <td>
                <h3 className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  ut massa lacinia.
                </h3>
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">H4</h2>
              </td>
              <td>
                <h4>14 px / Semibold</h4>
              </td>
              <td>
                <h4>16 px</h4>
              </td>
              <td>
                <h4>Employee Info Card (info)</h4>
              </td>
              <td>
                <h4 className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  ut massa lacinia.
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">H5</h2>
              </td>
              <td>
                <h5>14 px / Medium</h5>
              </td>
              <td>
                <h5>16 px</h5>
              </td>
              <td>
                <h5>Employee Info Card (info)</h5>
              </td>
              <td>
                <h5 className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  ut massa lacinia.
                </h5>
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">H6 / Overline</h2>
              </td>
              <td>
                <h6>12 px / Bold / Letter Spacing = .5</h6>
              </td>
              <td>
                <h6>16 px</h6>
              </td>
              <td>
                <h6>
                  form titles, table headers, BETA label, employee card info
                  title
                </h6>
              </td>
              <td>
                <h6 className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  ut massa lacinia.
                </h6>
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">Paragraph</h2>
              </td>
              <td>
                <p>16 px / Medium</p>
              </td>
              <td>
                <p>24 px</p>
              </td>
              <td>
                <p>
                  Side Bar Menu, Modal Copy, Form Fields, Error States, Table
                  Body
                </p>
              </td>
              <td>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  ut massa lacinia.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">Data</h2>
              </td>
              <td>
                <data>32 px / Semibold</data>
              </td>
              <td>
                <data>40 px</data>
              </td>
              <td>
                <data>Numbers on Cards</data>
              </td>
              <td>
                <data>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  ut massa lacinia.
                </data>
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">Caption</h2>
              </td>
              <td className="caption">12 px / Medium</td>
              <td className="caption">16 px</td>
              <td className="caption">
                Table Description, Toast Message, Info Pop-up
              </td>
              <td className="caption">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna ut
                massa lacinia.
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">Button + Pill Default</h2>
              </td>
              <td className="btn-pill">16 px/ Bold</td>
              <td className="btn-pill">16 px</td>
              <td className="btn-pill">Default & Medium Buttons & Pills</td>
              <td className="btn-pill">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna ut
                massa lacinia.
              </td>
            </tr>
            <tr>
              <td>
                <h2 className="mb-0">Button + Pill Small</h2>
              </td>
              <td className="btn-pill small">12 px / Bold</td>
              <td className="btn-pill small">16 px</td>
              <td className="btn-pill small">
                Employee Card (Info), Small Buttons, Small Pills
              </td>
              <td className="btn-pill small">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna ut
                massa lacinia.
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
