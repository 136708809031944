import React, { FunctionComponent, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import * as api from "~/api";
import { EmployeeCheckInRequest } from "~/components";

/**
 * The direct reports view.
 */
export const RequestCheckin: FunctionComponent = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const visitorCode = (query.get("visitor_code") ?? undefined)?.toUpperCase();

  const [requesting, setRequesting] = useState(false);
  const [visitorCompanyName, setVisitorCompanyName] = useState<string>();

  const onEmployeeRequest = async (
    phone?: string,
    email?: string
  ): Promise<void> => {
    setRequesting(true);

    let response;
    try {
      response = await api.requestConversationForGo(phone, email);
    } catch (error) {
      if (error instanceof TypeError) {
        setRequesting(false);
        toast.error(
          "Uh oh! Looks like there's no internet connection. Please check your connection and try again.",
          { autoClose: false, closeOnClick: false }
        );
        return;
      }
    }

    setRequesting(false);
    if (response?.ok) {
      toast.success(
        `Great to see you! We just sent a check-in to the place where you receive your TeamSense notifications.`
      );
    } else if (response?.status === 404) {
      const infoType = phone ? "mobile number" : "email address";
      toast.error(`Sorry, we don't recognize that ${infoType}.
      Please try again!
      If you're unsure which ${infoType} is in the TeamSense database, please
      contact your company administrator.`);
    } else {
      toast.error("Sorry, an error occurred. Please try again.");
    }
  };

  const onVisitorCodeSubmit = async (code: string): Promise<void> => {
    setRequesting(true);

    const response = await api.verifyVisitorCode(code);

    setRequesting(false);
    if (response.ok) {
      setVisitorCompanyName(
        api.isDivision(response.data)
          ? response.data.company.name
          : response.data.name
      );
    } else if (response.status === 404) {
      toast.error("Sorry, we don't recognize that code. Please try again!");
    } else {
      toast.error("Sorry, an error occurred. Please try again.");
    }
  };

  const onVisitorRequest = async (
    code: string,
    phone: string,
    name: string
  ): Promise<void> => {
    setRequesting(true);

    const response = await api.createVisitorConversationForGo(
      code,
      phone,
      name
    );
    setRequesting(false);
    if (response.ok) {
      // NOTE: if we use history.push() here, react-router-dom won't load the token properly
      window.location.assign(response.data.url);
    } else {
      toast.error("Sorry, an error occurred. Please try again.");
    }
  };

  return (
    <div className="w-screen">
      <EmployeeCheckInRequest
        waiting={requesting}
        visitorCode={visitorCode}
        visitorCompanyName={visitorCompanyName}
        onEmployeeRequest={onEmployeeRequest}
        onVisitorCodeSubmit={onVisitorCodeSubmit}
        onVisitorRequest={onVisitorRequest}
      />
    </div>
  );
};
