import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from "react";
import { Card } from "react-bootstrap";

import { DateTime } from "luxon";

import { FormResultsChoiceQuestion } from "./FormResultsChoiceQuestion";
import { FormResultsNoResponses } from "./FormResultsNoResponses";
import { FormResultsSkipQuestion } from "./FormResultsSkipQuestion";
import { FormResultsStatCard } from "./FormResultsStatCard";
import { FormResultsTextQuestion } from "./FormResultsTextQuestion";
import { ResultsGauge } from "./ResultsGauge";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import {
  areAllSelected,
  FilterList,
  FilterListItem,
  filterIdsFromSelectedItems,
  SelectedItems,
  updateFilterList
} from "~/lib/filterList";
import { shortCompletionTimeHumanizer } from "~/lib/ranges";
import {
  showDivisions,
  showDivisionsIfCompanyHasDivisions
} from "~/lib/showDivisions";
import { ExportSurveyResultsModal } from "~/ts-components/forms/ExportSurveyResultsModal";
import {
  TSDateRangePicker,
  GenericErrorText,
  ToastContext,
  TSMultiSelectCheckbox
} from "~common";

import "./FormResults.scss";

const DIVISION_FILTER_INCLUDES_NONE = true;
const INITIAL_DIVISION_FILTER_LIST: FilterList<api.Division> = {
  displayEmpty: DIVISION_FILTER_INCLUDES_NONE,
  items: {}
};

export const FormResults: FC<FormResultsProps> = ({ formId, isAnonymous }) => {
  const toast = useRef(useContext(ToastContext));
  const { session } = useContext(MutableSessionContext);

  const [surveysCompletedCount, setSurveysCompletedCount] =
    useState<api.SurveysCompleted>();
  const [surveysMedianTime, setSurveysMedianTime] =
    useState<api.SurveyCompletionMedianTime>();

  const [choiceResults, setChoiceResults] =
    useState<api.SurveyChoiceResults[]>();
  const [skipResults, setSkipResults] = useState<api.SurveyQuestion[]>();
  const [textResults, setTextResults] = useState<api.SurveyTextResults[]>();

  const [start, setStart] = useState<DateTime>();
  const [end, setEnd] = useState<DateTime>();
  const startTime = start && end ? start.toISO() : undefined;
  const endTime = start && end ? end.toISO() : undefined;

  const [showSurveyResultsModal, setShowSurveyResultsModal] =
    useState<boolean>(false);

  const [filteredDivisions, setFilteredDivisions] = useState<
    FilterList<api.Division>
  >(INITIAL_DIVISION_FILTER_LIST);
  const [filteredGroups, setFilteredGroups] = useState<
    FilterList<FilterListItem>
  >({ displayEmpty: true, items: {} });
  const [filteredTeamLeads, setFilteredTeamLeads] = useState<
    FilterList<FilterListItem>
  >({ displayEmpty: true, items: {} });

  const filterDivisionIds = useMemo(
    () => filterIdsFromSelectedItems(filteredDivisions.items),
    [filteredDivisions.items]
  );
  const filterGroupIds = useMemo(
    () => filterIdsFromSelectedItems(filteredGroups.items),
    [filteredGroups.items]
  );
  const filterTeamLeadIds = useMemo(
    () => filterIdsFromSelectedItems(filteredTeamLeads.items),
    [filteredTeamLeads.items]
  );

  const areAllDivisionsSelected = areAllSelected(
    filteredDivisions,
    DIVISION_FILTER_INCLUDES_NONE
  );
  const areAllGroupsSelected = areAllSelected(filteredGroups, true);
  const areAllTeamLeadsSelected = areAllSelected(filteredTeamLeads, true);

  const divisionIds = useMemo(
    () =>
      areAllDivisionsSelected
        ? undefined
        : [
            ...filterDivisionIds,
            ...(filteredDivisions.displayEmpty && DIVISION_FILTER_INCLUDES_NONE
              ? ["None"]
              : [])
          ],
    [areAllDivisionsSelected, filterDivisionIds, filteredDivisions.displayEmpty]
  );
  const groupIds = useMemo(
    () =>
      areAllGroupsSelected
        ? undefined
        : [...filterGroupIds, ...(filteredGroups.displayEmpty ? ["None"] : [])],
    [areAllGroupsSelected, filterGroupIds, filteredGroups.displayEmpty]
  );
  const teamLeadIds = useMemo(
    () =>
      areAllTeamLeadsSelected
        ? undefined
        : [
            ...filterTeamLeadIds,
            ...(filteredTeamLeads.displayEmpty ? ["None"] : [])
          ],
    [areAllTeamLeadsSelected, filterTeamLeadIds, filteredTeamLeads.displayEmpty]
  );

  const filtersWillEnsureZeroResults =
    divisionIds?.length === 0 ||
    groupIds?.length === 0 ||
    teamLeadIds?.length === 0;

  const showNoResponses = surveysCompletedCount?.totalSurveysCompleted === 0;
  const choiceResultsOnlyWhenResponses = showNoResponses ? [] : choiceResults;
  const textResultsOnlyWhenResponses = showNoResponses ? [] : textResults;
  const skipResultsOnlyWhenResponses = showNoResponses ? [] : skipResults;

  const choiceResultsWithSkeletonSentinel: (
    | api.SurveyChoiceResults
    | undefined
  )[] = choiceResultsOnlyWhenResponses ?? [undefined]; // [undefined] will render as a single skeleton

  const textResultsWithSkeletonSentinel: (api.SurveyTextResults | undefined)[] =
    textResultsOnlyWhenResponses ?? [undefined]; // [undefined] will render as a single skeleton

  const skipResultsWithSkeletonSentinel: (api.SurveyQuestion | undefined)[] =
    skipResultsOnlyWhenResponses ?? [undefined]; // [undefined] will render as a single skeleton

  const noFiltersApplied =
    areAllSelected(filteredDivisions, DIVISION_FILTER_INCLUDES_NONE) &&
    areAllSelected(filteredGroups, true) &&
    areAllSelected(filteredTeamLeads, true) &&
    !startTime &&
    !endTime;

  // event handlers
  const onRangeSelected = (
    rangeStart?: DateTime,
    rangeEnd?: DateTime
  ): void => {
    setStart(rangeStart);
    setEnd(rangeEnd);
  };

  // effects
  useEffect(() => {
    setFilteredDivisions(
      updateFilterList(INITIAL_DIVISION_FILTER_LIST, session.divisions ?? [])
    );
  }, [session.divisions]);

  useEffect(() => {
    const loadGroups = async (): Promise<void> => {
      if (!session.company?.id) {
        return;
      }
      let response: api.APIResponse<api.Group[]> | undefined;

      try {
        response = await api.retrieveGroups(session.company.id);
      } catch {}

      if (response?.ok) {
        const items = response.data.reduce((dict, group) => {
          dict[group.id] = { selected: true, item: group };

          return dict;
        }, {} as SelectedItems<FilterListItem>);

        setFilteredGroups({
          displayEmpty: true,
          items
        });
      } else {
        toast.current.show({
          variant: "danger",
          message: <GenericErrorText />
        });
      }
    };

    loadGroups();
  }, [session.company?.id]);

  useEffect(() => {
    const loadTeamLeads = async (): Promise<void> => {
      let response:
        | api.APIResponse<api.PaginatedResponse<api.Employee>>
        | undefined;

      try {
        response = await api.retrieveEmployees(session.company?.id || "", {
          teamLeadsOnly: true,
          limit: api.MAX_LIMIT
        });
      } catch {}

      if (response?.ok) {
        const items = response.data.results.reduce((dict, manager) => {
          dict[manager.id] = { selected: true, item: manager };

          return dict;
        }, {} as SelectedItems<FilterListItem>);

        setFilteredTeamLeads({
          displayEmpty: true,
          items
        });
      } else {
        toast.current.show({
          variant: "danger",
          message: <GenericErrorText />
        });
      }
    };

    loadTeamLeads();
  }, [session.company?.id]);

  useEffect(() => {
    const loadSurveysCompletedCountData = async (): Promise<void> => {
      if (!session.company?.id) {
        return;
      }
      setSurveysCompletedCount(undefined);

      let response: api.APIResponse<api.SurveysCompleted> | undefined;

      try {
        if (filtersWillEnsureZeroResults) {
          // When we have an empty array of filters, don't call the API
          // Just use a hardcoded empty response since we have no way to express this
          // in the API and no events can be returned in this case
          response = {
            ok: true,
            status: 200,
            data: {
              totalSurveys: 0,
              totalSurveysCompleted: 0
            }
          };
        } else {
          response = await api.retrieveSurveysCompletedCount(
            session.company.id,
            formId,
            {
              divisionIds,
              teamLeadIds,
              groupIds,
              startTime,
              endTime
            }
          );
        }
      } catch {}
      if (response?.ok) {
        setSurveysCompletedCount(response.data);
      } else {
        toast.current.show({
          variant: "danger",
          message: <GenericErrorText />
        });
      }
    };

    loadSurveysCompletedCountData();
  }, [
    divisionIds,
    endTime,
    filtersWillEnsureZeroResults,
    formId,
    groupIds,
    session.company?.id,
    startTime,
    teamLeadIds
  ]);

  useEffect(() => {
    const loadSurveysMedianTimeData = async (): Promise<void> => {
      if (!session.company?.id) {
        return;
      }
      setSurveysMedianTime(undefined);

      let response: api.APIResponse<api.SurveyCompletionMedianTime> | undefined;

      try {
        if (filtersWillEnsureZeroResults) {
          // When we have an empty array of filters, don't call the API
          // Just use a hardcoded empty response since we have no way to express this
          // in the API and no events can be returned in this case
          response = {
            ok: true,
            status: 200,
            data: {}
          };
        } else {
          response = await api.retrieveSurveyCompletionMedianTime(
            session.company.id,
            formId,
            {
              divisionIds,
              teamLeadIds,
              groupIds,
              startTime,
              endTime
            }
          );
        }
      } catch {}
      if (response?.ok) {
        setSurveysMedianTime(response.data);
      } else {
        toast.current.show({
          variant: "danger",
          message: <GenericErrorText />
        });
      }
    };

    loadSurveysMedianTimeData();
  }, [
    divisionIds,
    endTime,
    filtersWillEnsureZeroResults,
    formId,
    groupIds,
    session.company?.id,
    startTime,
    teamLeadIds
  ]);

  useEffect(() => {
    const loadResults = async (): Promise<void> => {
      if (!session.company?.id) {
        return;
      }
      setTextResults(undefined);

      let response: api.APIResponse<api.SurveyTextResults[]> | undefined;

      try {
        if (filtersWillEnsureZeroResults) {
          // When we have an empty array of filters, don't call the API
          // Just use a hardcoded empty response since we have no way to express this
          // in the API and no events can be returned in this case
          response = {
            ok: true,
            status: 200,
            data: []
          };
        } else {
          response = await api.retrieveSurveyTextResults(
            session.company.id,
            formId,
            {
              divisionIds,
              teamLeadIds,
              groupIds,
              startTime,
              endTime
            }
          );
        }
      } catch {}

      if (response?.ok) {
        setTextResults(response.data);
      } else if (response?.status === 503) {
        toast.current.show({
          variant: "danger",
          message:
            "We’re having trouble retrieving responses, please try narrowing down your survey’s responses with additional filters."
        });
      } else {
        toast.current.show({
          variant: "danger",
          message: <GenericErrorText />
        });
      }
    };

    loadResults();
  }, [
    divisionIds,
    formId,
    groupIds,
    session.company?.id,
    teamLeadIds,
    startTime,
    endTime,
    filtersWillEnsureZeroResults
  ]);

  useEffect(() => {
    const loadChoiceResults = async (): Promise<void> => {
      if (!session.company?.id) {
        return;
      }
      setChoiceResults(undefined);

      let response: api.APIResponse<api.SurveyChoiceResults[]> | undefined;

      try {
        if (filtersWillEnsureZeroResults) {
          // When we have an empty array of filters, don't call the API
          // Just use a hardcoded empty response since we have no way to express this
          // in the API and no events can be returned in this case
          response = {
            ok: true,
            status: 200,
            data: []
          };
        } else {
          response = await api.retrieveSurveyChoiceResults(
            session.company.id,
            formId,
            {
              divisionIds,
              teamLeadIds,
              groupIds,
              startTime,
              endTime
            }
          );
        }
      } catch {}
      if (response?.ok) {
        setChoiceResults(response.data);
      } else if (response?.status === 503) {
        toast.current.show({
          variant: "danger",
          message:
            "We’re having trouble retrieving responses, please try narrowing down your survey’s responses with additional filters."
        });
      } else {
        toast.current.show({
          variant: "danger",
          message: <GenericErrorText />
        });
      }
    };

    loadChoiceResults();
  }, [
    divisionIds,
    endTime,
    filtersWillEnsureZeroResults,
    formId,
    groupIds,
    session.company?.id,
    startTime,
    teamLeadIds
  ]);

  useEffect(() => {
    const loadSkipResults = async (): Promise<void> => {
      if (!session.company?.id) {
        return;
      }
      setSkipResults(undefined);

      let response: api.APIResponse<api.SurveyQuestion[]> | undefined;

      try {
        if (filtersWillEnsureZeroResults) {
          // When we have an empty array of filters, don't call the API
          // Just use a hardcoded empty response since we have no way to express this
          // in the API and no events can be returned in this case
          response = {
            ok: true,
            status: 200,
            data: []
          };
        } else {
          response = await api.retrieveSurveySkipResults(
            session.company.id,
            formId,
            {
              divisionIds,
              teamLeadIds,
              groupIds,
              startTime,
              endTime
            }
          );
        }
      } catch {}
      if (response?.ok) {
        setSkipResults(response.data);
      } else {
        toast.current.show({
          variant: "danger",
          message: <GenericErrorText />
        });
      }
    };

    loadSkipResults();
  }, [
    divisionIds,
    endTime,
    filtersWillEnsureZeroResults,
    formId,
    groupIds,
    session.company?.id,
    startTime,
    teamLeadIds
  ]);

  return (
    <>
      <div className="main-content-header"></div>
      <h1>Results</h1>
      <div className="form-results-filters">
        {showDivisions(session) && (
          <TSMultiSelectCheckbox
            titlePrefix="Divisions"
            variant="dropdown"
            displayNone
            filterList={filteredDivisions}
            onChangeFilterList={setFilteredDivisions}
          />
        )}
        <TSMultiSelectCheckbox
          titlePrefix="Groups"
          variant="dropdown"
          displayNone
          filterList={filteredGroups}
          onChangeFilterList={setFilteredGroups}
        />
        <TSMultiSelectCheckbox
          titlePrefix="Managers"
          variant="dropdown"
          displayNone
          filterList={filteredTeamLeads}
          onChangeFilterList={setFilteredTeamLeads}
        />
        <TSDateRangePicker start={start} end={end} onChange={onRangeSelected} />
      </div>

      <div className="form-quick-results">
        <FormResultsStatCard
          iconClassName="icon-user"
          value={surveysCompletedCount?.totalSurveys}
          description="Surveys Sent"
        />
        <FormResultsStatCard
          iconClassName="icon-clock"
          value={
            surveysMedianTime
              ? shortCompletionTimeHumanizer(surveysMedianTime?.medianTime ?? 0)
              : undefined
          }
          description="Median Completion Time"
        />
        <Card body={true} className="quick-stat rounded-0">
          <ResultsGauge
            surveysCount={surveysCompletedCount?.totalSurveys}
            surveysCompleted={surveysCompletedCount?.totalSurveysCompleted}
          />
        </Card>
      </div>

      <Card body={true} className="rounded-0">
        {choiceResultsWithSkeletonSentinel.map(choiceQuestion => (
          <FormResultsChoiceQuestion
            key={choiceQuestion?.name ?? "choice-skeleton"}
            choiceQuestion={choiceQuestion}
          />
        ))}
        {textResultsWithSkeletonSentinel.map(textQuestion => (
          <FormResultsTextQuestion
            key={textQuestion?.name ?? "text-skeleton"}
            textQuestion={textQuestion}
            showDivColumn={showDivisionsIfCompanyHasDivisions(session)}
            isAnonymous={isAnonymous}
          />
        ))}
        {skipResultsWithSkeletonSentinel.map(skipQuestion => (
          <FormResultsSkipQuestion
            key={skipQuestion?.name ?? "skip-skeleton"}
            skipQuestion={skipQuestion}
          />
        ))}
        {showNoResponses && (
          <FormResultsNoResponses
            noSurveysSent={surveysCompletedCount?.totalSurveys === 0}
            noFiltersApplied={noFiltersApplied}
          />
        )}
      </Card>
      {showSurveyResultsModal && (
        <ExportSurveyResultsModal
          allowFutureDates={false}
          handleClose={() => setShowSurveyResultsModal(false)}
          exportModalSurveyId={formId}
          exportType="survey"
        />
      )}
    </>
  );
};

interface FormResultsProps {
  formId: string;
  isAnonymous: boolean;
}
