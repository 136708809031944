/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { TSInput } from "~common";

const dropdownOtions = [
  { label: "Item 1", value: "item1" },
  { label: "Item 2", value: "item2" },
  { label: "Item 3", value: "item3" }
];

export const Forms = (): JSX.Element => {
  const invaidItem = true;

  const [singleSelections, setSingleSelections] = useState<any>([
    { label: "Item 2", value: "item2" }
  ]);
  const [multiSelections, setMultiSelections] = useState<any>([
    { label: "Item 1", value: "item1" },
    { label: "Item 3", value: "item3" }
  ]);

  return (
    <Card as="section">
      <Card.Body>
        <h1>Forms</h1>
        <Row>
          <Col xs={12} xl={4}>
            <h2>Who are you?</h2>
            <TSInput
              id="firstName"
              label="First Name"
              type="text"
              placeholder="Enter First Name"
              onChange={() => {}}
              defaultValue="Bob"
              helpText="What your mom calls you"
              errorText=""
              required
            />
            <TSInput
              id="middleName"
              label="Middle Name"
              type="text"
              placeholder="Enter Middle Name"
              onChange={() => {}}
              defaultValue=""
              helpText="What your mom calls you when you're in trouble"
            />
            <TSInput
              id="lastName"
              label="Last Name"
              type="text"
              placeholder="Enter Last Name"
              onChange={() => {}}
              defaultValue="Jones"
              isInvalid={invaidItem}
              errorText="Don't you know this by now?"
              required
            />
            <TSInput
              id="preferredName"
              label="Preferred Name"
              type="text"
              placeholder="Enter Preferred Name"
              onChange={() => {}}
              defaultValue="Bobbert"
              isInvalid={false}
              helpText="What your friends call you, (wether you like it or not!)"
              disabled
            />
            <TSInput
              select
              options={dropdownOtions}
              id="timezone"
              label="Standard Select"
              placeholder="Select Item"
              onChange={() => {}}
              defaultValue={""}
              isInvalid={false}
              errorText=""
              required
            />

            <Form.Group className="mb-3">
              <Form.Label>Single item Typeahead Select</Form.Label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setSingleSelections}
                options={dropdownOtions}
                placeholder="Search for Item"
                selected={singleSelections}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Single item Typeahead Select</Form.Label>
              <Typeahead
                id="basic-typeahead-multiple"
                labelKey="label"
                multiple
                onChange={setMultiSelections}
                options={dropdownOtions}
                placeholder="Select Multiple Items"
                selected={multiSelections}
              />
            </Form.Group>
            <TSInput
              as="textarea"
              rows={5}
              id="specialInstructions"
              label="Special Instructions"
              type="text"
              placeholder="Enter Special Instructions"
              onChange={() => {}}
              isInvalid={false}
              errorText=""
              // required
            />
          </Col>
          <Col xs={12} xl={4}>
            <div className="mb-3">
              <h2>Some small questions</h2>
              <Form.Label>
                <span>＊</span>
                Won't the real Slim Shady please stand up?
              </Form.Label>
              <Form.Check type="radio" id="slimShady1" onChange={() => {}}>
                <Form.Check.Input name="slimShady" type="radio" />
                <Form.Check.Label>I'm Slim Shady</Form.Check.Label>
                <Form.Text muted>Yes I'm the real Shady</Form.Text>
              </Form.Check>
              <Form.Check
                name="slimShady"
                type="radio"
                id="slimShady2"
                label="All the other Slim Shady's are just immitating"
                onChange={() => {}}
              />
              <Form.Check
                name="slimShady"
                type="radio"
                id="slimShady3"
                label="I can't stand up"
                onChange={() => {}}
                disabled
              />
            </div>

            <div className="mb-3">
              <Form.Label>
                <span>＊</span>A few of my favorite things.
              </Form.Label>
              <Form.Check
                name="favoriteThings"
                type="checkbox"
                id="favoriteThings1"
                label="Raindrops on roses"
                onChange={() => {}}
              />
              <Form.Check
                name="favoriteThings"
                type="checkbox"
                id="favoriteThings2"
                label="Whiskers on kittens"
                onChange={() => {}}
              />
              <Form.Check
                name="favoriteThings"
                type="checkbox"
                id="favoriteThings3"
                label="Bright copper kettles"
                onChange={() => {}}
                checked
                disabled
              />
              <Form.Check
                name="favoriteThings"
                type="checkbox"
                id="favoriteThings4"
                label="Warm wollen mittens"
                onChange={() => {}}
                disabled
              />
            </div>

            <div className="mb-3">
              <Form.Label>
                <span>＊</span>
                Things I'm never gonna
              </Form.Label>
              <Form.Check
                name="neverGonna"
                type="switch"
                id="neverGonna1"
                label="Give you up"
                onChange={() => {}}
              />
              <Form.Check
                name="neverGonna"
                type="switch"
                id="neverGonna2"
                label="Let you down"
                onChange={() => {}}
              />
              <Form.Check
                name="neverGonna"
                type="switch"
                id="neverGonna3"
                label="Run around"
                onChange={() => {}}
                checked
                disabled
              />
              <Form.Check
                name="neverGonna"
                type="switch"
                id="neverGonna4"
                label="Hurt you"
                onChange={() => {}}
                disabled
              />
            </div>
          </Col>
          <Col xs={12} xl={4}>
            <div className="mb-3">
              <h2>Some big ideas</h2>
              <Form.Label>
                <span>＊</span>
                Won't the real Slim Shady please stand up?
              </Form.Label>
              <Form.Check
                className="check-lg"
                type="radio"
                id="slimShadyLg1"
                onChange={() => {}}
              >
                <Form.Check.Input name="slimShadyLg" type="radio" />
                <Form.Check.Label>I'm Slim Shady</Form.Check.Label>
                <Form.Text muted>Yes I'm the real Shady</Form.Text>
              </Form.Check>
              <Form.Check
                className="check-lg"
                name="slimShadyLg"
                type="radio"
                id="slimShadyLg2"
                label="All the other Slim Shady's are just immitating"
                onChange={() => {}}
              />
              <Form.Check
                className="check-lg"
                name="slimShadyLg"
                type="radio"
                id="slimShadyLg3"
                label="I can't stand up"
                onChange={() => {}}
                disabled
              />
            </div>

            <div className="mb-3">
              <Form.Label>
                <span>＊</span>A few of my favorite things.
              </Form.Label>
              <Form.Check
                className="check-lg"
                name="favoriteThingsLg"
                type="checkbox"
                id="favoriteThingsLg1"
                label="Raindrops on roses"
                onChange={() => {}}
              />
              <Form.Check
                className="check-lg"
                name="favoriteThingsLg"
                type="checkbox"
                id="favoriteThingsLg2"
                label="Whiskers on kittens"
                onChange={() => {}}
              />
              <Form.Check
                className="check-lg"
                name="favoriteThingsLg"
                type="checkbox"
                id="favoriteThingsLg3"
                label="Bright copper kettles"
                onChange={() => {}}
                checked
                disabled
              />
              <Form.Check
                className="check-lg"
                name="favoriteThingsLg"
                type="checkbox"
                id="favoriteThingsLg4"
                label="Warm wollen mittens"
                onChange={() => {}}
                disabled
              />
            </div>

            <div className="mb-3">
              <Form.Label>
                <span>＊</span>
                Things I'm never gonna
              </Form.Label>
              <Form.Check
                className="check-lg"
                name="neverGonnaLg"
                type="switch"
                id="neverGonnaLg1"
                label="Give you up"
                onChange={() => {}}
              />
              <Form.Check
                className="check-lg"
                name="neverGonnaLg"
                type="switch"
                id="neverGonnaLg2"
                label="Let you down"
                onChange={() => {}}
              />
              <Form.Check
                className="check-lg"
                name="neverGonnaLg"
                type="switch"
                id="neverGonnaLg3"
                label="Run around"
                onChange={() => {}}
                checked
                disabled
              />
              <Form.Check
                className="check-lg"
                name="neverGonnaLg"
                type="switch"
                id="neverGonnaLg4"
                label="Hurt you"
                onChange={() => {}}
                disabled
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
