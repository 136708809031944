import React, { useState, FunctionComponent } from "react";
import { Form, InputGroup } from "react-bootstrap";

import clsx from "clsx";

import "./TSInput.scss";

export const TSInput: FunctionComponent<TSInputProps> = props => {
  const {
    groupClass,
    select,
    options,
    required,
    id,
    isInvalid,
    onChange,
    defaultValue,
    placeholder,
    label,
    helpText,
    errorText,
    appendContent,
    prependContent,
    noValidate,
    ...passThroughProps
  } = props;
  const [typing, setTyping] = useState(false);
  const [validationTimeout, setValidationTimeout] = useState<NodeJS.Timeout>();

  // Event Handlers
  const validate = (event: TSInputChangeEvent): void => {
    validationTimeout && clearTimeout(validationTimeout);
    setTyping(false);
    onChange?.(event);
  };

  const changeHandler = (event: TSInputChangeEvent): void => {
    event.persist();

    if (select) {
      validate(event);
    } else if (!noValidate) {
      // clear the error feedback while they're typing
      setTyping(true);

      // reset the timer
      validationTimeout && clearTimeout(validationTimeout);
      setValidationTimeout(
        setTimeout(() => {
          // check validity when typing pauses
          validate(event);
        }, 300)
      );
    }
  };

  // Content Fragments
  const selectOptions = (): JSX.Element => {
    const emptyOption = defaultValue ? null : (
      <option key={`${id}emptyOption`} value={""} disabled hidden>
        {placeholder}
      </option>
    );
    const dataOptions =
      select &&
      options?.length &&
      options?.map((option: SelectOptionsProps, index) => (
        <option
          key={index}
          value={option?.value || option.label}
          disabled={option.disabled}
        >
          {option.label}
        </option>
      ));
    return (
      <>
        {emptyOption}
        {dataOptions}
      </>
    );
  };

  const theInput = select ? (
    <Form.Select
      required={required}
      isInvalid={!typing && isInvalid}
      onInput={changeHandler}
      onBlur={validate}
      defaultValue={defaultValue}
      placeholder={placeholder}
      aria-describedby={`${id}HelpBlock`}
      {...passThroughProps}
    >
      {options?.length && selectOptions()}
    </Form.Select>
  ) : (
    <Form.Control
      required={required}
      isInvalid={!typing && isInvalid}
      onInput={changeHandler}
      onBlur={validate}
      noValidate={noValidate}
      defaultValue={defaultValue}
      placeholder={placeholder}
      aria-describedby={`${id}HelpBlock`}
      {...passThroughProps}
    />
  );

  const theInputGroup =
    prependContent || appendContent ? (
      <InputGroup>
        {prependContent}
        {theInput}
        {appendContent}
      </InputGroup>
    ) : (
      theInput
    );

  return (
    <Form.Group controlId={id} className={clsx("ts-input", groupClass)}>
      {label && (
        <Form.Label>
          {required && <span>＊</span>}
          {label}
        </Form.Label>
      )}
      {theInputGroup}
      {errorText && !typing && isInvalid && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errorText}
        </Form.Control.Feedback>
      )}

      {helpText && !errorText && (
        <Form.Text id={`${id}HelpBlock`} className="text-muted">
          {helpText}
        </Form.Text>
      )}
    </Form.Group>
  );
};

export interface SelectOptionsProps {
  label: string;
  value?: string | number;
  disabled?: boolean;
}

export type TSInputChangeEvent =
  | React.FormEvent<HTMLSelectElement>
  | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>;

interface TSInputProps {
  as?: "input" | "textarea" | React.ElementType;
  rows?: number;
  groupClass?: string;
  ref?: any;
  select?: boolean;
  options?: SelectOptionsProps[];
  disabled?: boolean;
  required?: boolean;
  id?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  onChange?: React.EventHandler<TSInputChangeEvent>;
  plaintext?: boolean;
  readOnly?: boolean;
  size?: "sm" | "lg";
  type?: string;
  defaultValue?: string | number | [];
  placeholder?: string;
  bsPrefix?: string;
  label?: string;
  helpText?: string;
  errorText?: string;
  max?: number | string;
  min?: number | string;
  prependContent?: JSX.Element;
  appendContent?: JSX.Element;
  noValidate?: boolean;
}
