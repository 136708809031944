import React, { FunctionComponent } from "react";

import "./Error.scss";
import { Link } from "~/components/Link/Link";
import Error404Illustration from "~/images/error-404-not-found-illustration.svg";

export const ErrorPageNotFound: FunctionComponent = () => {
  return (
    <div className="ts error-page">
      <div className="px-4">
        <h2>404, The page you’re looking for can’t be found.</h2>
        <img
          className="illustration m-auto"
          src={Error404Illustration}
          alt="404 Page Not Found Illustration"
        />
        <h4>Sorry about that, the page you’re looking for can’t be found.</h4>
        <div className="mb-5 text-center">
          <Link to="/" className="btn btn-primary">
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
};
