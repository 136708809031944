import React, { FunctionComponent, useCallback } from "react";
import Calendar, { CalendarTileProperties } from "react-calendar";

import { DateTime } from "luxon";

import { HeatMapTile } from "./HeatMapTile";

import * as api from "~/api";

// import "react-calendar/dist/Calendar.css";
import "./DailyHeatMap.scss";

export const DailyHeatMap: FunctionComponent<DailyHeatMapProps> = ({
  data
}) => {
  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];

  const tileContent = useCallback(
    ({ date }: CalendarTileProperties): JSX.Element => {
      const currentDay = DateTime.fromJSDate(date).toISODate();
      const day = data?.find(day => day.name === currentDay);
      const calendarDay = date.getDate();
      if (day) {
        return <HeatMapTile frame={day} type="day" />;
      }

      return (
        <div className="heatmap-tile heatmap-tile__empty">{calendarDay}</div>
      );
    },
    [data]
  );

  return (
    <Calendar
      calendarType="US"
      className="heatmap-calendar"
      activeStartDate={new Date()}
      tileContent={tileContent}
      formatShortWeekday={(locale, date) => weekdays[date.getDay()]}
    />
  );
};

interface DailyHeatMapProps {
  data?: api.Frame[];
}
