import React, { FunctionComponent, useState } from "react";

import { Loading } from "../../_old/common/loading/Loading";

import * as api from "~/api";
import { MutableSession, MutableSessionContext } from "~/lib/context";
import { useEffectOnce } from "~/lib/hooks";

/**
 * Initializes and provides session from:
 *
 * 1. Data set by server in initial page rendering
 * 2. The /sessions api
 *
 * Waits to render children until a session has been initialized
 */
export const SessionWrapper: FunctionComponent<{
  children: React.ReactNode;
  conversationToken?: string;
  employeeSessionToken?: string;
}> = ({ children, conversationToken, employeeSessionToken }) => {
  const syncSession = api.retrieveGlobalSession();

  const [isInitialized, setInitialized] = useState<boolean>(
    syncSession !== null
  );

  // TODO DMF -- updateSession closes on the initial value of mutableSession -- bug??
  const [mutableSession, setMutableSession] = useState<MutableSession>({
    session: syncSession ?? api.EMPTY_SESSION,
    updateSession: (session: api.Session) => {
      setMutableSession({ ...mutableSession, session });
    }
  });

  const setSession = (session: api.Session): void => {
    setMutableSession({ ...mutableSession, session });
  };

  useEffectOnce(() => {
    initializeSession();
  });

  const initializeSession = async (): Promise<void> => {
    // bail quickly if the session is immediately available
    // this magic will happen in our Django web/FrontendView, as an optimization.
    if (syncSession !== null) {
      return;
    }

    // otherwise, ping the server
    const response = await api.retrieveSession({
      conversationToken,
      employeeSessionToken
    });

    if (response.ok) {
      setSession(response.data);
    } else {
      console.debug(
        "initializeSession: not retrieve session: ",
        response.errors
      );
    }

    setInitialized(true);
  };

  if (!isInitialized) {
    return <Loading className="bg-yellow-600" />;
  }

  return (
    <MutableSessionContext.Provider value={mutableSession}>
      {children}
    </MutableSessionContext.Provider>
  );
};
