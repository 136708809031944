import React, { FC, useCallback, useEffect, useState } from "react";
import { Col, Collapse, Form, InputGroup, Row } from "react-bootstrap";

import { DateTime } from "luxon";

import { useLinguiLocale } from "~/lib/hooks";
import {
  DAY_OF_MONTH_OPTIONS,
  DAY_OF_WEEK_OPTIONS,
  FREQUENCY_OPTIONS,
  INTERVAL_OPTIONS,
  KIND_OPTIONS
} from "~/lib/scheduleRecurrence";
import {
  FormSchedule,
  FormScheduleRecurrenceDetails,
  FormScheduleRecurrenceProps,
  FrequencyType
} from "~/types";
import { TSInput, TSInputChangeEvent } from "~common";

// time is always stored in utc time, so we add the `Z` to the default.
// additionally, we add the `Z` any time a time input changes.
// time is converted to `HH:mm` anytime it is pulled into the interface.
const DEFAULT_SCHEDULE_TIME = "16:30Z";
const LOCAL_TIME_ZONE = DateTime.now().toFormat("ZZZZ", { locale: "en-US" });

export const FormScheduleRecurrence: FC<
  FormScheduleRecurrenceProps
> = props => {
  const { details, recurrenceChangeHandler, showErrors } = props;
  const { frequency, schedules } = details;

  const locale = useLinguiLocale();
  const WEEKDAYS =
    locale === "en-US" ? [7, 1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 5, 6, 7];

  // `schedules` array only has multiple items if we're dealing with weekly occurrence
  // so we're safe to grab values from index 0 for daily and monthly frequencies
  const isWeekly = frequency === "weekly";
  const kind = !isWeekly && schedules?.[0].kind;
  const interval = !isWeekly && schedules?.[0].interval;
  const dayOfWeek = !isWeekly && schedules?.[0].dayOfWeek;
  const dayOfMonth = !isWeekly && schedules?.[0].dayOfMonth;
  const time = !isWeekly && schedules?.[0].time;

  // State for the changeable recurrence properties
  const [selectedFrequency, setSelectedFrequency] = useState(frequency || "");
  const [selectedKind, setSelectedKind] = useState(kind || "");
  const [selectedInterval, setSelectedInterval] = useState(interval || "");
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(dayOfWeek || "");
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState(
    dayOfMonth || ""
  );
  const [selectedTime, setSelectedTime] = useState(
    time || DEFAULT_SCHEDULE_TIME
  );

  // state for the schedule if the schedule is set to weekly
  const [weeklySchedule, setWeeklySchedule] = useState<FormSchedule[]>(
    isWeekly && schedules ? schedules : []
  );

  // event handlers
  // handle all dropdown inputs
  const selectHandler = (event: TSInputChangeEvent): void => {
    const target = event.target as HTMLSelectElement;

    switch (target?.id) {
      case "frequency":
        return setSelectedFrequency(target.value);
      case "kind":
        return setSelectedKind(target.value);
      case "interval":
        return setSelectedInterval(target.value);
      case "dayOfWeek":
        return setSelectedDayOfWeek(target.value);
      case "dayOfMonth":
        return setSelectedDayOfMonth(target.value);
      default:
    }
  };

  // handle daily/monthly time
  const timeHandler = (event: TSInputChangeEvent): void => {
    const target = event.target as HTMLInputElement;
    // convert it to UTC, add the "Z"
    target.value
      ? setSelectedTime(
          `${DateTime.fromISO(target.value).toUTC().toFormat("HH:mm")}Z`
        )
      : setSelectedTime("");
  };

  // handle changes to weekly schedule
  const weeklyCheckHandler = (dayNumber: number): void => {
    const match = weeklySchedule.find(x => x.dayOfWeek === dayNumber);

    // If the selected day exists in the weeklySchedule array, remove it
    match &&
      setWeeklySchedule(oldSchedule => {
        return oldSchedule.filter(x => x.dayOfWeek !== dayNumber);
      });

    // If the selected day does not exist in the weeklySchedule array,
    // add an item to it with the selected day and default time
    !match &&
      setWeeklySchedule(oldSchedule => {
        return (
          oldSchedule &&
          oldSchedule.concat({
            kind: "dayOfWeek",
            dayOfWeek: dayNumber,
            time: DEFAULT_SCHEDULE_TIME
          })
        );
      });
  };

  const weeklyTimeHandler = (
    event: TSInputChangeEvent,
    dayNumber: number
  ): void => {
    const target = event.target as HTMLInputElement;
    const weekdayIndex = weeklySchedule.findIndex(
      x => x.dayOfWeek === dayNumber
    );

    // clone the existing weekly schedule,
    const newSchedule = [...weeklySchedule];
    // update the correct item's time
    // convert it to UTC, add the "Z"
    newSchedule[weekdayIndex].time = target.value
      ? `${DateTime.fromISO(target.value)
          .toUTC()
          .toFormat("HH:mm", { locale: "en-US" })}Z`
      : "";
    setWeeklySchedule(newSchedule);
  };

  // utility functions
  // bundle up the export details to be passed to parent
  const getExportDetails = useCallback((): FormScheduleRecurrenceDetails => {
    // potential schedule data to be sent. weekly is defined above
    const monthlyDayOfWeekSchedule = {
      kind: selectedKind,
      interval: Number(selectedInterval),
      dayOfWeek: Number(selectedDayOfWeek),
      time: selectedTime
    };

    const monthlyDayOfMonthSchedule = {
      kind: selectedKind,
      dayOfMonth: Number(selectedDayOfMonth),
      time: selectedTime
    };

    const dailySchedule = {
      time: selectedTime
    };

    // select the correct schedule, default to "daily"
    /* eslint-disable no-nested-ternary */
    const exportedSchedule =
      selectedFrequency === "monthly" && selectedKind === "dayOfWeek"
        ? [monthlyDayOfWeekSchedule]
        : selectedFrequency === "monthly" && selectedKind === "dayOfMonth"
        ? [monthlyDayOfMonthSchedule]
        : selectedFrequency === "weekly"
        ? weeklySchedule
        : [dailySchedule];

    // assemble the details
    return {
      frequency: selectedFrequency as FrequencyType,
      schedules: exportedSchedule
    };
  }, [
    selectedDayOfMonth,
    selectedDayOfWeek,
    selectedFrequency,
    selectedInterval,
    selectedKind,
    selectedTime,
    weeklySchedule
  ]);

  // distill all of the different inputs down to a single boolean that
  // the parent can use to determine if the data can be sent to the api
  const exportedScheduleIsValid = useCallback((): boolean => {
    const monthlyDayOfWeekScheduleIsValid =
      Boolean(selectedFrequency) &&
      Boolean(selectedKind) &&
      Boolean(selectedInterval) &&
      Boolean(selectedDayOfWeek) &&
      Boolean(selectedTime);

    const monthlyDayOfMonthScheduleIsValid =
      Boolean(selectedFrequency) &&
      Boolean(selectedKind) &&
      Boolean(selectedDayOfMonth) &&
      Boolean(selectedTime);

    const weeklyScheduleIsValid =
      Boolean(selectedFrequency) &&
      Boolean(weeklySchedule.length) &&
      !weeklySchedule.some(day => day.time === "");

    const dailyScheduleIsValid =
      Boolean(selectedFrequency) && Boolean(selectedTime);

    if (selectedFrequency === "monthly") {
      if (selectedKind === "dayOfWeek") {
        return monthlyDayOfWeekScheduleIsValid;
      } else if (selectedKind === "dayOfMonth") {
        return monthlyDayOfMonthScheduleIsValid;
      } else {
        return false;
      }
    } else if (selectedFrequency === "weekly") {
      return weeklyScheduleIsValid;
    } else if (selectedFrequency === "daily") {
      return dailyScheduleIsValid;
    } else {
      return false;
    }
  }, [
    selectedDayOfMonth,
    selectedDayOfWeek,
    selectedFrequency,
    selectedInterval,
    selectedKind,
    selectedTime,
    weeklySchedule
  ]);

  // generate checkboxes and inputs for the weekly schedule
  const weeklyInputs = (): JSX.Element => {
    // loop through the days of the week and return a row with
    // a checkbox and time input for each.
    const weekdayInputs = WEEKDAYS.map(dayNumber => {
      const day = DAY_OF_WEEK_OPTIONS.find(x => x.value === dayNumber);
      // Enable the checkbox and input and populate if there is a match
      if (day) {
        const match =
          weeklySchedule && weeklySchedule.find(x => x.dayOfWeek === day.value);
        const timeFromIso = match?.time
          ? DateTime.fromISO(match.time).toFormat("HH:mm", { locale: "en-US" })
          : "";

        return (
          <Row key={day.value} className="my-2">
            <div className="col-5 col-lg-6">
              <Form.Check
                className="check-lg"
                name={`${day.label}checkbox`}
                type="checkbox"
                id={`${day.label}checkbox`}
                label={day.label}
                checked={Boolean(match)}
                onChange={() => weeklyCheckHandler(day.value)}
              />
            </div>
            <div className="col-7 col-lg-6">
              {/* If the time input is invalid and you disable the checkbox,
              it won't get re-initialized, when you toggle the disabled prop.
              To get around this, we're replacing it with a placeholder input when disabled */}
              {match ? (
                <div>
                  <TSInput
                    groupClass="mb-0"
                    type="time"
                    onChange={event => weeklyTimeHandler(event, day.value)}
                    defaultValue={
                      timeFromIso ||
                      DateTime.fromISO(DEFAULT_SCHEDULE_TIME).toFormat(
                        "HH:mm",
                        { locale: "en-US" }
                      )
                    }
                    required
                    isInvalid={match && !timeFromIso}
                    errorText="Please enter a valid time"
                    appendContent={
                      <InputGroup.Text>{LOCAL_TIME_ZONE}</InputGroup.Text>
                    }
                  />
                </div>
              ) : (
                <div>
                  <TSInput
                    groupClass="mb-0"
                    type="text"
                    placeholder={"Select day"}
                    disabled
                  />
                </div>
              )}
            </div>
          </Row>
        );
      } else {
        return <></>;
      }
    });

    return (
      <Col className="col-12">
        {showErrors && weeklySchedule.length <= 0 && (
          <div className="d-block invalid-feedback">
            Please add one or more week days to the schedule
          </div>
        )}

        {weekdayInputs}
      </Col>
    );
  };

  useEffect(() => {
    // pass the details up to the parent component
    recurrenceChangeHandler &&
      recurrenceChangeHandler(getExportDetails(), exportedScheduleIsValid());
    // have to disable exhaustive-deps because including the passed in method blows the world up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportedScheduleIsValid, getExportDetails]);

  return (
    <Row>
      <TSInput
        groupClass="col-12 col-lg-6"
        select
        options={FREQUENCY_OPTIONS}
        id="frequency"
        label="Recurrence"
        placeholder="Select Frequency"
        onChange={event => selectHandler(event)}
        defaultValue={selectedFrequency}
        required
        isInvalid={showErrors && !selectedFrequency}
        errorText="Please make a selection"
      />

      <Collapse in={selectedFrequency === "monthly"}>
        <div className="col-12 col-lg-6">
          <TSInput
            select
            options={KIND_OPTIONS}
            id="kind"
            label="Type"
            placeholder="Select Type"
            onChange={event => selectHandler(event)}
            defaultValue={selectedKind}
            required
            isInvalid={showErrors && !selectedKind}
            errorText="Please make a selection"
          />
        </div>
      </Collapse>

      <Collapse
        in={selectedFrequency === "monthly" && selectedKind === "dayOfWeek"}
      >
        <div className="col-12 col-lg-6">
          <TSInput
            select
            options={INTERVAL_OPTIONS}
            id="interval"
            label="Every"
            placeholder="Select Interval"
            onChange={event => selectHandler(event)}
            defaultValue={selectedInterval}
            required
            isInvalid={showErrors && !selectedInterval}
            errorText="Please make a selection"
          />
        </div>
      </Collapse>
      <Collapse
        in={selectedFrequency === "monthly" && selectedKind === "dayOfWeek"}
      >
        <div className="col-12 col-lg-6">
          <TSInput
            select
            options={DAY_OF_WEEK_OPTIONS}
            id="dayOfWeek"
            label="Day of the Week"
            placeholder="Select Day of Week"
            onChange={event => selectHandler(event)}
            defaultValue={selectedDayOfWeek}
            required
            isInvalid={showErrors && !selectedDayOfWeek}
            errorText="Please make a selection"
          />
        </div>
      </Collapse>

      <Collapse
        in={selectedFrequency === "monthly" && selectedKind === "dayOfMonth"}
      >
        <div className="col-12 col-lg-6">
          <TSInput
            select
            options={DAY_OF_MONTH_OPTIONS}
            id="dayOfMonth"
            label="Day of the Month"
            placeholder="Select Day of Month"
            onChange={event => selectHandler(event)}
            defaultValue={selectedDayOfMonth}
            required
            isInvalid={showErrors && !selectedDayOfMonth}
            errorText="Please make a selection"
          />
        </div>
      </Collapse>

      <Collapse in={selectedFrequency !== "weekly"}>
        <div className="col-12 col-lg-6">
          <TSInput
            id="selectedTime"
            type="time"
            label="Schedule Time"
            onChange={event => timeHandler(event)}
            defaultValue={DateTime.fromISO(selectedTime).toFormat("HH:mm", {
              locale: "en-US"
            })}
            required
            isInvalid={!selectedTime}
            errorText="Please enter a valid time"
            appendContent={<InputGroup.Text>{LOCAL_TIME_ZONE}</InputGroup.Text>}
          />
        </div>
      </Collapse>

      <Collapse in={selectedFrequency === "weekly"}>
        <div>{weeklyInputs()}</div>
      </Collapse>
    </Row>
  );
};
