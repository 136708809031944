import React, { FunctionComponent } from "react";

export const StatusComponent: FunctionComponent = () => (
  <iframe
    src="https://status.teamsense.com/badge"
    title="TeamSense Status"
    width="200"
    height="30"
    frameBorder="0"
    scrolling="no"
  ></iframe>
);
