import React, { FunctionComponent } from "react";

import { IMsalContext } from "@azure/msal-react";

import { Loading } from "../../_old/common/loading/Loading";

/**
 * Waits for any outstanding msal operations to complete,
 * then renders children.
 */
export const WaitForMsal: FunctionComponent<{
  children: React.ReactNode;
  msal: Pick<IMsalContext, "inProgress" | "accounts">;
}> = ({ children, msal }) => {
  if (msal.inProgress === "none") {
    return <>{children}</>;
  }

  return <Loading />;
};
