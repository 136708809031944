import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import DatePicker from "react-datepicker";

import { t } from "@lingui/macro";
import { I18n, Trans } from "@lingui/react";
import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import { StatusDropdown } from "~/components/StatusDropdown/StatusDropdown";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";
import Restricted from "~/ts-components/permissionProvider/Restricted";
import { MessageActionButton, Modal, ModalProps } from "~common";
import "react-datepicker/dist/react-datepicker.css";

export interface ScheduleAttendanceStatusModalProps extends ModalProps {
  event?: api.EmployeeEvent;
  defaultStartDate?: DateTime; // Day/Month/Year are honored from this value
  timezone: string;
  mobileStyle?: boolean;
  onSave: (
    startDate: api.ISODateTime,
    endDate: api.ISODateTime,
    status: api.AttendanceStatus,
    note?: string
  ) => any;
  onClickDelete: () => any;
}

export const ScheduleAttendanceStatusModal: FunctionComponent<
  ScheduleAttendanceStatusModalProps
> = ({
  event,
  defaultStartDate,
  timezone,
  mobileStyle,
  onSave,
  onClickDelete,
  onClose
}) => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const refStartDate = useRef<DatePicker>(null);
  const refEndDate = useRef<DatePicker>(null);

  useEffect(() => {
    if (!mobileStyle) {
      return;
    }
    // Make the underlying `INPUT` elements read only so the virtual keyboard doesn't appear on mobile
    if (refStartDate.current) {
      (refStartDate.current as any).input.readOnly = true;
    }
    if (refEndDate.current) {
      (refEndDate.current as any).input.readOnly = true;
    }
  }, [mobileStyle, refStartDate, refEndDate]);
  // NOTE: the initialStatus is passed to the <StatusDropdown /> - if that status is not
  // available, it will choose another valid option instead
  const initialStatus =
    (event?.label?.label_set === api.LabelSet.AttendanceStatus &&
      event?.label?.name) ||
    "away";
  const initialStartDate =
    event?.started ??
    (defaultStartDate ?? DateTime.fromObject({ zone: timezone })).toISODate();

  let initialEndDate;
  if (event?.ended) {
    const ended = DateTime.fromISO(event.ended, { zone: timezone });
    if (ended.equals(ended.startOf("day"))) {
      // Since we show "End Date" and not "Return Date" (which is what our data model stores),
      // we back up the end date by one day when the event ends at 12:00AM in the employee's timezone
      initialEndDate = ended.minus({ days: 1 }).toISO();
    } else {
      initialEndDate = event.ended;
    }
  }

  const [currentStatus, setCurrentStatus] =
    useState<api.AttendanceStatus>(initialStatus);
  const [newStartDate, setNewStartDate] = useState<string | undefined>(
    initialStartDate
  );
  const [newEndDate, setNewEndDate] = useState<string | undefined>(
    initialEndDate
  );
  const [note, setNote] = useState(event?.note?.body);
  const isValid =
    DateTime.fromISO(newStartDate ?? "").isValid &&
    DateTime.fromISO(newEndDate ?? "").isValid;

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setNote(event.target.value);

  const onSaveClicked = (): void => {
    if (!newStartDate || !newEndDate) {
      return;
    }

    const zoneAdjustedStartDate = DateTime.fromISO(newStartDate, {
      zone: timezone
    }).toISO();

    const newEnded = DateTime.fromISO(newEndDate, { zone: timezone });
    let zoneAdjustedEndDate;
    if (newEnded.equals(newEnded.startOf("day"))) {
      // Since we show "End Date" and not "Return Date" (which is what our data model stores),
      // we add one day to the end date if it is set to 12:00AM in the employee's timezone
      zoneAdjustedEndDate = newEnded.plus({ days: 1 }).toISO();
    } else {
      zoneAdjustedEndDate = newEnded.toISO();
    }

    if (
      !event?.ended ||
      !DateTime.fromISO(zoneAdjustedStartDate).equals(
        DateTime.fromISO(event.started)
      ) ||
      !DateTime.fromISO(zoneAdjustedEndDate).equals(
        DateTime.fromISO(event.ended)
      ) ||
      currentStatus !== event?.label?.name ||
      note !== event?.note?.body
    ) {
      // Changes were made - so we must call onSave()
      onSave(zoneAdjustedStartDate, zoneAdjustedEndDate, currentStatus, note);
    }

    onClose();
  };

  const editEventName =
    event && getLabelDisplay(event.label.name, session.labels, language);

  const startDatePickerValue = newStartDate
    ? DateTime.fromISO(newStartDate, { zone: timezone })
        .setZone("local", { keepLocalTime: true })
        .toJSDate()
    : null;

  const endDatePickerValue = newEndDate
    ? DateTime.fromISO(newEndDate, { zone: timezone })
        .setZone("local", { keepLocalTime: true })
        .toJSDate()
    : null;

  return (
    <Modal
      className={clsx(
        "rounded max-w-lg overflow-visible",
        mobileStyle ? "p-4 flex-grow" : "p-12"
      )}
      onClose={onClose}
    >
      {event && (
        <div className="flex flex-row justify-end">
          <div
            className="text-hs-red hover:text-hs-dark-red cursor-pointer"
            onClick={onClickDelete}
          >
            <Trans id="common.delete">Delete</Trans>
          </div>
        </div>
      )}

      <div
        className={clsx(
          "font-bold mb",
          mobileStyle ? "pl-3 pb-6" : "text-4xl pb-16"
        )}
      >
        {editEventName ? (
          <Trans id="attendance.modal.header.edit" values={{ editEventName }}>
            Edit {editEventName} Status
          </Trans>
        ) : (
          <Trans id="attendance.modal.header.add">Add Attendance Status</Trans>
        )}
      </div>

      <div
        className={clsx(
          "text-base",
          mobileStyle ? "pb-6 mx-3 text-hs-dark-green" : "pb-6"
        )}
      >
        <div
          className={clsx(
            "font-bold",
            mobileStyle
              ? "pl-1-2 text-xs tracking-wider uppercase mb-3-2 w-full"
              : "pl-3"
          )}
        >
          <Trans id="common.status">Status</Trans>
        </div>
        <StatusDropdown
          containerClassName="rounded border border-hs-dark-gray w-full"
          status={currentStatus}
          statusFilter={key => api.isAttendanceStatus(key)}
          onChange={setCurrentStatus}
        />
      </div>
      <div
        className={clsx(
          "flex text-base pb-6",
          mobileStyle
            ? "flex-col space-y-8 mx-3 text-hs-dark-green"
            : "flex-row"
        )}
      >
        <div className={mobileStyle ? undefined : "mr-3 flex-1"}>
          <div
            className={clsx(
              "font-bold",
              mobileStyle
                ? "pl-1-2 text-xs tracking-wider uppercase mb-3-2"
                : "pl-3"
            )}
          >
            <Trans id="attendance.modal.startDate">Start Date</Trans>
          </div>
          <DatePicker
            portalId="root"
            wrapperClassName="w-full"
            selected={startDatePickerValue}
            maxDate={endDatePickerValue}
            className="field"
            ref={refStartDate}
            onChange={value => {
              if (!(value instanceof Array)) {
                setNewStartDate(
                  value ? DateTime.fromJSDate(value).toISODate() : undefined
                );
              }
              refEndDate.current?.setFocus();
            }}
            onFocus={() => refEndDate.current?.setOpen(false)}
            tabIndex={1}
          />
        </div>
        <div className="flex-1">
          <div
            className={clsx(
              "font-bold",
              mobileStyle
                ? "pl-1-2 text-xs tracking-wider uppercase mb-3-2"
                : "pl-3"
            )}
          >
            <Trans id="attendance.modal.endDate">End Date</Trans>
          </div>
          <DatePicker
            portalId="root"
            wrapperClassName="w-full"
            selected={endDatePickerValue}
            minDate={startDatePickerValue}
            maxDate={
              DateTime.local().plus({ years: 1 }).endOf("day").toJSDate() ??
              null
            }
            className="field"
            ref={refEndDate}
            onChange={value => {
              if (!(value instanceof Array)) {
                setNewEndDate(
                  value ? DateTime.fromJSDate(value).toISODate() : undefined
                );
              }
            }}
            onFocus={() => refStartDate.current?.setOpen(false)}
            tabIndex={1}
          />
        </div>
      </div>
      <Restricted
        to={[
          "EmployeeNotes.READ",
          "EmployeeNotes.CREATE",
          "EmployeeNotes.UPDATE",
          "EmployeeNotes.DELETE"
        ]}
      >
        <I18n>
          {({ i18n }) => (
            <div className={mobileStyle ? "mx-3" : undefined}>
              <label
                htmlFor="notesText"
                className={clsx(
                  "font-bold",
                  mobileStyle
                    ? "pl-1-2 text-xs tracking-wider uppercase mb-3-2"
                    : "pl-3"
                )}
              >
                <Trans id="attendance.modal.notes">Notes</Trans>
              </label>
              <div
                className={clsx(
                  "flex items-center mb-8",
                  mobileStyle ? "h-20" : "h-40"
                )}
              >
                <textarea
                  id="notesText"
                  autoFocus
                  maxLength={255}
                  className="bg-transparent resize-none text-sm p-5 h-full leading-normal placeholder-gray-400 flex-grow outline-none"
                  onChange={onInputChange}
                  value={note ?? ""}
                  placeholder={i18n._(
                    t("attendance.modal.notesPlaceholder")`Add notes (optional)`
                  )}
                />
              </div>
            </div>
          )}
        </I18n>
      </Restricted>
      <div
        className={clsx(
          "flex justify-end",
          mobileStyle
            ? "border-t border-hs-alt-gray mt-4 pt-4 px-4 -mx-4"
            : "py-2.5"
        )}
      >
        <MessageActionButton
          inline
          className="w-32"
          textSizeClassName={mobileStyle ? "text-base" : undefined}
          text={<Trans id="common.cancel">Cancel</Trans>}
          theme={mobileStyle ? "clear-dark-green" : "clear-green"}
          onClick={onClose}
        />
        <MessageActionButton
          inline
          className={mobileStyle ? undefined : "w-32"}
          textSizeClassName={mobileStyle ? "text-base" : undefined}
          disabled={!isValid}
          text={<Trans id="common.save">Save</Trans>}
          theme={mobileStyle ? "clear-green" : "green"}
          onClick={onSaveClicked}
        />
      </div>
    </Modal>
  );
};
