import React, {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import Calendar, { CalendarTileProperties } from "react-calendar";
import { useMediaQuery } from "react-responsive";

import { ErrorBoundary } from "@sentry/react";
import { DateTime, Interval } from "luxon";

import { CalendarDay } from "./CalendarDay";
import { CalendarLegend } from "./CalendarLegend";
import { DeleteEventModal } from "./DeleteEventModal";
import { SickEventModal } from "./SickEventModal";
import { StatusModal } from "./StatusModal";

import * as api from "~/api";
import { EmployeeRole } from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { localeNotSupported } from "~/lib/errorBoundary";
import { useEffectOnce, useLinguiLanguage } from "~/lib/hooks";
import {
  deleteAttendanceStatusEvent,
  getLabelColor,
  getLabelColorLightened,
  saveAttendanceStatusChange
} from "~/lib/status";
import { TSButton } from "~common";
import "./StatusCalendar.scss";

export const StatusCalendar: FunctionComponent<StatusCalendarInterface> = ({
  employee
}) => {
  const showTwoCalendars = useMediaQuery({ query: "(min-width: 1400px)" });
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const [activeDate, setActiveDate] = useState(new Date());
  const [modalDate, setModalDate] = useState<DateTime>(DateTime.local());
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showSickEventModal, setShowSickEventModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [event, setEvent] = useState<api.EmployeeEvent>();
  const [employeeEvents, setEmployeeEvents] = useState<api.EmployeeEvent[]>();
  const [eventsLoaded, setEventsLoaded] = useState(false);

  const nextMonth = DateTime.fromJSDate(activeDate)
    .plus({ months: 1 })
    .toJSDate();

  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];
  let currentMonthText = "";
  let nextMonthText = "";
  try {
    currentMonthText = DateTime.fromJSDate(activeDate).toLocaleString({
      month: "long",
      year: "numeric"
    });
    nextMonthText = DateTime.fromJSDate(nextMonth).toLocaleString({
      month: "long",
      year: "numeric"
    });
  } catch (e) {
    currentMonthText = DateTime.fromJSDate(activeDate).toLocaleString({
      month: "long",
      year: "numeric",
      locale: "en-US"
    });
    nextMonthText = DateTime.fromJSDate(nextMonth).toLocaleString({
      month: "long",
      year: "numeric",
      locale: "en-US"
    });
  }

  const loadEvents = async (): Promise<void> => {
    const response = await api.retrieveEmployee(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      session.company!.id,
      employee.id,
      { events: true, healthSurveyActivity: true }
    );

    if (response.ok) {
      setEmployeeEvents(response.data.events);
      setEventsLoaded(true);
    } else {
      console.debug(
        "loadEmployeeWithEvents: could not retrieve employee: ",
        response.errors
      );
    }
  };

  useEffectOnce(() => {
    loadEvents();
  });

  const { unknown, ...labelsWithoutUnknown } = session.labels;
  const labelKeysWithoutUnknown = Object.keys(labelsWithoutUnknown);

  const colorMap: Array<LabelColorMapInterface> = useMemo(() => {
    return labelKeysWithoutUnknown.map(key => ({
      status: key,
      lightColor: getLabelColorLightened(key, session.labels),
      color: getLabelColor(key, session.labels)
    }));
  }, [labelKeysWithoutUnknown, session.labels]);

  const handlePrevMonth = (): void => {
    setActiveDate(
      DateTime.fromJSDate(activeDate).minus({ months: 1 }).toJSDate()
    );
  };

  const handleNextMonth = (): void => {
    setActiveDate(
      DateTime.fromJSDate(activeDate).plus({ months: 1 }).toJSDate()
    );
  };

  const handleClick = (value: Date, event: MouseEvent): void => {
    const currentDay = DateTime.fromJSDate(value);
    const currentDayAtEightPM = currentDay.endOf("day").minus({ hours: 4 });
    const eventToday = employeeEvents?.filter(currentEvent => {
      const start = DateTime.fromISO(currentEvent.started);
      const end = currentEvent.ended
        ? DateTime.fromISO(currentEvent.ended)
        : DateTime.local().endOf("day");
      const dateRange = Interval.fromDateTimes(start, end);

      if (dateRange.contains(currentDayAtEightPM)) {
        return true;
      }
      return false;
    })?.[0];

    setModalDate(currentDay);

    if (!eventToday) {
      setEvent(undefined);
      setShowStatusModal(true);
      return;
    }

    setEvent(eventToday);

    switch (eventToday.label.label_set) {
      case api.LabelSet.CovidHealthStatus:
        // if they have another event that isn't covid, this will be ignored
        setShowSickEventModal(true);
        break;
      case api.LabelSet.AttendanceStatus:
        setShowStatusModal(true);
        break;
    }
  };

  const handleExit = (): void => {
    setShowStatusModal(false);
    setShowSickEventModal(false);
  };

  const handleNewStatus = (): void => {
    setEvent(undefined);
    setShowStatusModal(true);
  };

  const handleDeleteWarning = (): void => {
    setShowStatusModal(false);
    setShowDeleteModal(true);
  };

  const onAttendanceStatusSave = async (
    startDate: api.ISODateTime,
    endDate: api.ISODateTime,
    status: api.AttendanceStatus,
    note?: string
  ): Promise<void> => {
    const statusIsSaved = await saveAttendanceStatusChange(
      employee,
      startDate,
      endDate,
      status,
      session.labels,
      language,
      note,
      event
    );

    if (statusIsSaved) {
      loadEvents();
      handleExit();
    }
  };

  const handleAttendanceStatusDelete = async (): Promise<void> => {
    if (!event) {
      return;
    }
    if (await deleteAttendanceStatusEvent(employee, event)) {
      loadEvents();
      setShowDeleteModal(false);
    }
  };

  const tileContent = useCallback(
    ({ date }: CalendarTileProperties): JSX.Element => {
      return (
        <ErrorBoundary fallback={<div>{localeNotSupported}</div>}>
          <CalendarDay
            date={date}
            colorMap={colorMap}
            currentMonthDate={activeDate}
            events={employeeEvents}
          />
        </ErrorBoundary>
      );
    },
    [employeeEvents, colorMap, activeDate]
  );

  return (
    <>
      {eventsLoaded && (
        <>
          <div className="main-content-header justify-content-end">
            <TSButton onClick={handleNewStatus} startIcon="icon-plus">
              New Occurrence
            </TSButton>
          </div>
          <div className="employee-calendars">
            <div className="left-column">
              <div className="employee-calendar current-month">
                <header>
                  <h2>{currentMonthText}</h2>
                </header>
                <ErrorBoundary fallback={<div>{localeNotSupported}</div>}>
                  <Calendar
                    showNavigation={false}
                    onClickDay={(value, event) => handleClick(value, event)}
                    activeStartDate={activeDate}
                    calendarType="US"
                    tileContent={tileContent}
                    view="month"
                    formatShortWeekday={(locale = "en-US", date) =>
                      weekdays[date.getDay()]
                    }
                  />
                </ErrorBoundary>
                <ErrorBoundary fallback={<div>{localeNotSupported}</div>}>
                  <CalendarLegend />
                </ErrorBoundary>
              </div>
              <nav className="employee-calendar-nav">
                <TSButton variant="link" size="lg" onClick={handlePrevMonth}>
                  <i className="icon-caret-left" />
                </TSButton>
                <TSButton variant="link" size="lg" onClick={handleNextMonth}>
                  <i className="icon-caret-right" />
                </TSButton>
              </nav>
            </div>
            {showTwoCalendars && (
              <div className="employee-calendar next-month">
                <header>
                  <h2>{nextMonthText}</h2>
                </header>
                <ErrorBoundary fallback={<div>{localeNotSupported}</div>}>
                  <Calendar
                    showNavigation={false}
                    activeStartDate={nextMonth}
                    calendarType="US"
                    tileContent={tileContent}
                    onClickDay={(value, event) => handleClick(value, event)}
                    view="month"
                    formatShortWeekday={(locale = "en-US", date) =>
                      weekdays[date.getDay()]
                    }
                  />
                </ErrorBoundary>
              </div>
            )}
          </div>
        </>
      )}

      {employee && (
        <StatusModal
          currentDay={modalDate}
          show={showStatusModal}
          handleExit={handleExit}
          event={event}
          employee={employee}
          timezone={employee.timezone}
          showDeleteWarning={session.employee?.role !== EmployeeRole.teamLeader}
          handleDeleteWarning={handleDeleteWarning}
          onSave={onAttendanceStatusSave}
        />
      )}
      {event && employee && (
        <SickEventModal
          event={event}
          employee={employee}
          labelInfoMap={session.labels}
          handleExit={handleExit}
          show={showSickEventModal}
        />
      )}
      {event && employee && (
        <DeleteEventModal
          show={showDeleteModal}
          event={event}
          employee={employee}
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={handleAttendanceStatusDelete}
        />
      )}
    </>
  );
};

interface StatusCalendarInterface {
  employee: api.Employee;
}

export interface LabelColorMapInterface {
  status: string;
  color: string;
  lightColor: string;
}
