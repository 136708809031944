import React, { FunctionComponent, ReactNode, useContext } from "react";
import { Route } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { FullRedirect } from "./FullRedirect";
import { getSafePath } from "./getSafePath";
import { RouteProps } from "./routeProps";

import { MutableSessionContext } from "~/lib/context";

/**
 * Guarded version of route that makes sure a user IS logged in
 */
export const UserRoute: FunctionComponent<RouteProps> = ({
  component: Component,
  render,
  ...config
}) => {
  const context = useContext(MutableSessionContext);
  const { session } = context;
  const SentryRoute = Sentry.withSentryRouting(Route);

  return (
    <SentryRoute
      {...config}
      render={(props): ReactNode => {
        if (session.user) {
          return Component ? <Component {...props} /> : render();
        } else {
          return (
            <FullRedirect
              to={{
                pathname: "/sign-in",
                search: `?next=${getSafePath()}`
              }}
            />
          );
        }
      }}
    />
  );
};
