import React, { FunctionComponent } from "react";

interface RadioButtonProps {
  label: string;
  value: string;
  name: string;
  selected: boolean;
  onSelectItem: (selected: string) => any;
}

export const RadioButton: FunctionComponent<RadioButtonProps> = ({
  label,
  value,
  name,
  selected,
  onSelectItem
}) => {
  return (
    <label className="field-label flex items-center mt-2" key={value}>
      <input
        className="mr-3 cursor-pointer focus:shadow-none"
        type="radio"
        value={value}
        name={name}
        checked={selected}
        onChange={() => onSelectItem(value)}
      />
      {label}
    </label>
  );
};
