import React, { FunctionComponent } from "react";

import { DateTime } from "luxon";

import * as api from "~/api";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";
import { MessageActionButton, Modal, ModalProps } from "~common";

const formattedDate = (iso: api.ISODateTime): string =>
  DateTime.fromISO(iso).toFormat("MMMM d", { locale: "en-US" });

export interface SickEventModalProps extends ModalProps {
  employee: api.Employee;
  event: api.EmployeeEvent;
  labelInfoMap: api.LabelInfoMap;
}

export const SickEventModal: FunctionComponent<SickEventModalProps> = ({
  employee,
  event,
  labelInfoMap,
  onClose
}) => {
  const language = useLinguiLanguage();
  const alertStatus = getLabelDisplay("sick", labelInfoMap, language);

  return (
    <Modal className="rounded max-w-lg p-16" onClose={onClose}>
      <div className="font-bold text-4xl mb">Alert</div>
      <div className="text-base font-light pt-2">{`${
        employee.name
      } entered ${alertStatus} status on ${formattedDate(
        event.started
      )}.`}</div>
      {event.note && (
        <div className="flex items-center border border-hs-dark-gray h-40 my-2">
          <textarea
            disabled
            className="resize-none text-sm p-5 h-full leading-normal placeholder-gray-400 flex-grow outline-none"
            value={event.note?.body}
          />
        </div>
      )}
      {event.ended && (
        <div className="text-base font-light pt-2">{`${
          employee.friendly_name
        } was cleared on ${formattedDate(event.ended)}.`}</div>
      )}
      <div className="flex justify-end pt-8">
        <MessageActionButton
          inline
          className="w-32"
          text="Close"
          theme="green"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};
