import React, { FunctionComponent } from "react";

import { DateTime } from "luxon";

import { DatePickerMessage } from "../DatePickerMessage/DatePickerMessage";
import { NumberQuestion } from "../NumberQuestion/NumberQuestion";

import * as api from "~/api";
import { ChoiceMessage } from "~/components/ChoiceMessage/ChoiceMessage";
import { MessageHistory } from "~/components/MessageHistory/MessageHistory";
import { StringQuestion } from "~/components/StringQuestion/StringQuestion";
import { TextMessage } from "~/components/TextMessage/TextMessage";
import { processConversationTranslation, useLinguiLanguage } from "~/lib/hooks";

interface Props {
  step: api.InlineStep;
  employee: api.Employee;
  answer?: api.Answer;
  otherAnswer?: api.Answer;
  onChange?: (answer?: api.Answer, valid?: boolean) => any;
}

export const InlineStep: FunctionComponent<Props> = ({
  step,
  employee,
  answer,
  otherAnswer,
  onChange
}) => {
  const language = useLinguiLanguage();
  const context: api.TemplateStringContext = { employee };
  const { prompts } = step;
  const translatedPrompts = prompts.map(prompt =>
    processConversationTranslation(language, prompt)
  );
  const lastPrompt = translatedPrompts.pop();

  const onChoiceChange = (selected: string[], valid: boolean): void => {
    onChange?.({ kind: "choice", value: selected }, valid);
  };

  const onDateChange = (value: api.ISODateTime): void => {
    const adjustedTime = DateTime.fromISO(value).setZone(employee.timezone, {
      keepLocalTime: true
    });
    onChange?.({ kind: "date", value: adjustedTime.toISO() }, true);
  };

  const onNumberChange = (value: number): void => {
    onChange?.({ kind: "number", value }, true);
  };
  return (
    <>
      <MessageHistory>
        {translatedPrompts.map((text, index) => (
          <TextMessage
            key={`prompt-${index}`}
            context={context}
            templateText={text}
          />
        ))}
      </MessageHistory>
      {step.kind === "choice" && (
        <ChoiceMessage
          templateText={lastPrompt ?? ""}
          context={context}
          {...step.choiceSet}
          selected={answer?.kind === "choice" ? answer.value : undefined}
          disabled={!onChange}
          onChange={onChoiceChange}
        />
      )}
      {step.kind === "date" && (
        <DatePickerMessage
          templateText={lastPrompt ?? ""}
          context={context}
          hintRange={step.hintRange}
          otherAnswerValue={
            otherAnswer?.kind === "date"
              ? DateTime.fromISO(otherAnswer.value)
                  .setZone(employee.timezone)
                  .setZone("local", { keepLocalTime: true })
                  .toISO()
              : undefined
          }
          maxPastDays={step.maxPastDays}
          maxFutureDays={step.maxFutureDays}
          value={
            answer?.kind === "date"
              ? DateTime.fromISO(answer.value)
                  .setZone(employee.timezone)
                  .setZone("local", { keepLocalTime: true })
                  .toISO()
              : undefined
          }
          disabled={!onChange}
          onChange={onDateChange}
        />
      )}
      {step.kind === "number" && (
        <NumberQuestion
          templateText={lastPrompt ?? ""}
          context={context}
          step={step}
          value={answer?.kind === "number" ? answer.value : step.hintInitial}
          disabled={!onChange}
          onChange={onNumberChange}
        />
      )}
      {step.kind === "string" && (
        <StringQuestion
          templateText={lastPrompt ?? ""}
          context={context}
          answer={answer?.kind === "string" ? answer : undefined}
          questionMetadata={step}
          disabled={!onChange}
          onChange={onChange}
        />
      )}
      {step.kind === "message" && (
        <TextMessage context={context} templateText={lastPrompt ?? ""} />
      )}
    </>
  );
};
