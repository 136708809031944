import React, { FunctionComponent } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";
import Mustache from "mustache";

import { DatePickerWidget } from "./DatePickerWidget";

import * as api from "~/api";
import { TextBubble } from "~/components/TextBubble/TextBubble";
import { useLinguiLocale } from "~/lib/hooks";

import "./DatePicker.scss";

export interface DatePickerMessageProps
  extends Omit<api.DateQuestion, "kind" | "prompts"> {
  templateText: string;
  context: api.TemplateStringContext;
  onChange: (value: api.ISODateTime) => any;
  disabled?: boolean;
  darkMode?: boolean;
  otherAnswerValue?: api.ISODateTime;
  value?: api.ISODateTime;
  maxPastDays?: number;
  maxFutureDays?: number;
}

export const DatePickerMessage: FunctionComponent<DatePickerMessageProps> = ({
  templateText,
  hintRange,
  otherAnswerValue,
  context,
  disabled,
  darkMode,
  onChange,
  value,
  maxPastDays,
  maxFutureDays
}) => {
  const locale = useLinguiLocale();
  const date = value ? DateTime.fromISO(value) : DateTime.local();
  const otherAnswerDate = otherAnswerValue
    ? DateTime.fromISO(otherAnswerValue)
    : undefined;
  let localeDate = "";
  try {
    localeDate = date.setLocale(locale).toLocaleString({
      ...DateTime.DATE_MED_WITH_WEEKDAY,
      month: "long"
    });
  } catch (e) {
    localeDate = date.setLocale("en-US").toLocaleString({
      ...DateTime.DATE_MED_WITH_WEEKDAY,
      month: "long"
    });
  }

  return (
    <div className="flex flex-col space-y-3">
      <TextBubble
        className={
          darkMode ? "bg-hs-black-20 text-white" : "bg-gray-100 text-hs-dark"
        }
      >
        {Mustache.render(templateText, context)}
      </TextBubble>
      {disabled ? (
        <TextBubble
          className={clsx(
            "border border-solid border-hs-green bg-hs-green-20 text-sm",
            darkMode ? "text-white" : "text-hs-dark"
          )}
        >
          {Mustache.render(localeDate, context)}
        </TextBubble>
      ) : (
        <DatePickerWidget
          className={darkMode ? undefined : "bg-gray-100"}
          range={hintRange}
          maxPastDays={maxPastDays}
          maxFutureDays={maxFutureDays}
          otherAnswerValue={otherAnswerDate?.toJSDate()}
          onChange={jsDate => onChange(DateTime.fromJSDate(jsDate).toISO())}
          value={date.toJSDate()}
        />
      )}
    </div>
  );
};
