import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import clsx from "clsx";
import { Form, Formik } from "formik";

import * as api from "~/api";
import { PasswordField } from "~/components";
import BrandLogoGreen from "~/images/brand-green.svg";
import { updateFormForAPIResponse } from "~/lib/forms";
import { Button } from "~common";

export const LoginReset: FunctionComponent = () => {
  const [validResetToken, setValidToken] = useState(false);
  const history = useHistory();
  interface ParamTypes {
    token: string;
  }
  const { token } = useParams<ParamTypes>();

  useEffect(() => {
    async function validateToken(): Promise<void> {
      const response = await api.validateResetToken({ token });
      if (response.ok) {
        setValidToken(true);
      } else {
        history.push("/sign-in/forgot?expired");
      }
    }

    token && validateToken();
  }, [token, history]);

  //- Based on TailwindUI Simple card sign-in
  //- https://tailwindui.com/components/application-ui/forms/sign-in-forms
  return (
    <Formik
      initialValues={{ password: "", passwordConfirm: "", token }}
      onSubmit={async (values, actions) => {
        const response = await api.confirmPasswordReset(values);
        if (response.ok) {
          history.push("/sign-in?reset=y");
        } else if (
          response.errors.non_field_errors &&
          response.errors.non_field_errors.includes("Not found.")
        ) {
          history.push("/sign-in/forgot?expired");
        }
        updateFormForAPIResponse(actions, response);
        actions.setSubmitting(false);
      }}
      validate={values => {
        const errors: Partial<api.PasswordToken> = {};
        if (values.password !== values.passwordConfirm) {
          errors.password = "Password fields do not match.";
        }
        return errors;
      }}
    >
      {({ isSubmitting }) => (
        <Form
          className={clsx(
            "min-h-full py-12 sm:px-6 lg:px-8",
            "flex flex-col justify-center"
          )}
        >
          {validResetToken && (
            <>
              <div className="p-0">
                <img
                  className="m-auto w-auto h-6"
                  src={BrandLogoGreen}
                  alt="TeamSense Logo"
                />
              </div>
              <h1 className="prose-h1 text-center">Let’s set your password.</h1>
              <div
                className={clsx(
                  "sm:w-full bg-gray-50 shadow sm:rounded-lg",
                  "py-8 px-4 sm:px-10 mt-8 sm:mx-auto sm:max-w-md"
                )}
              >
                <PasswordField label="New Password" name="password" />
                <PasswordField
                  label="Confirm Password"
                  name="passwordConfirm"
                />

                <Button submit className="button--primary w-full mt-6">
                  Save Password
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
