import React, { FunctionComponent } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Form, Formik } from "formik";

import { LoginPage } from "./LoginPage";

import * as api from "~/api";
import { EmailField } from "~/components";
import { updateFormForAPIResponse } from "~/lib/forms";
import { Button } from "~common";

export const LoginForgot: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  let showExpiredTokenMessage = false;

  if (new URLSearchParams(location.search).has("expired")) {
    showExpiredTokenMessage = true;
  }

  return (
    <LoginPage>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={async (values, actions) => {
          const response = await api.requestPasswordReset(values);
          if (response.ok) {
            history.push("/sign-in/forgot/sent");
          }
          updateFormForAPIResponse(actions, response);
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {showExpiredTokenMessage ? (
              <>
                <h1>Oops, looks like that link expired.</h1>
                <h2>Enter your email to receive a new link.</h2>
              </>
            ) : (
              <h1>Let’s set your password.</h1>
            )}
            <EmailField label="Email" name="email" autoComplete="username" />

            <Button submit className="btn btn-primary" disabled={isSubmitting}>
              Set Password
            </Button>
          </Form>
        )}
      </Formik>
    </LoginPage>
  );
};
