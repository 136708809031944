import React, { FunctionComponent, useMemo } from "react";
import Select, { ValueType } from "react-select";

import {
  BaseEntitiesDropdownProps,
  Entity,
  stylesConfig,
  themeConfig
} from "./entities-dropdown-common";

interface DropdownListItem {
  value: string;
  label: string;
}

interface EntitiesDropdownProps extends BaseEntitiesDropdownProps {
  entityIds: string[];
  onChange?: (entityIds: string[]) => any;
  onChangeEntities?: (entities: Entity[]) => any;
}

export const EntitiesDropdown: FunctionComponent<EntitiesDropdownProps> = ({
  allEntities,
  entityIds,
  onChange,
  onChangeEntities,
  ...selectProps
}) => {
  const options = useMemo(
    () =>
      (allEntities ?? []).map(({ id, name }) => ({
        value: id,
        label: name
      })),
    [allEntities]
  );

  const value = options.filter(({ value }) => entityIds.includes(value));

  const onSelectChange = (inputValue: ValueType<DropdownListItem>): void => {
    let selected: DropdownListItem[] = [];
    if (inputValue instanceof Array) {
      selected = inputValue as DropdownListItem[];
    }

    onChangeEntities?.(
      selected.map(({ value, label }) => ({ id: value, name: label }))
    );
    onChange?.(selected.map(({ value }) => value));
  };

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select field--select"
      styles={stylesConfig}
      theme={themeConfig}
      isMulti
      menuPlacement="auto"
      menuPortalTarget={document.body}
      value={value}
      options={options}
      onChange={onSelectChange}
      {...selectProps}
    />
  );
};
