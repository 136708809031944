import React, { FunctionComponent, useContext } from "react";
import { Modal } from "react-bootstrap";

import { DateTime } from "luxon";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";
import { TSButton } from "~common";

export const DeleteEventModal: FunctionComponent<DeleteEventModalInterface> = ({
  show,
  event,
  employee,
  handleHide,
  handleDelete
}) => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const eventType = getLabelDisplay(event.label.name, session.labels, language);
  const startDate = DateTime.fromISO(event.started)
    .setZone(employee.timezone)
    .toFormat("MMMM d");
  // Adjust end date from 12:00AM the following date to the previous date so the
  // displayed range is inclusive
  const endDate = DateTime.fromISO(event.ended ?? "")
    .setZone(employee.timezone)
    .minus({ days: 1 })
    .toFormat("MMMM d");
  return (
    <Modal
      show={show}
      container={document.getElementById("ts-modal")}
      centered
      onHide={handleHide}
    >
      <Modal.Body>
        <h1 className="text-danger">Delete {eventType} Status</h1>
        <p>
          Are you sure want to delete {employee.friendly_name}’s {eventType}{" "}
          status from {startDate} to {endDate}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <TSButton variant="link" onClick={handleHide}>
          Cancel
        </TSButton>
        <TSButton variant="danger" endIcon="icon-trash" onClick={handleDelete}>
          Delete
        </TSButton>
      </Modal.Footer>
    </Modal>
  );
};

interface DeleteEventModalInterface {
  show: boolean;
  event: api.EmployeeEvent;
  employee: api.Employee;
  handleHide: () => any;
  handleDelete: () => any;
}
