import React, { FunctionComponent, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { toast, ToastOptions } from "react-toastify";

import { Trans } from "@lingui/react";
import * as Sentry from "@sentry/react";

import * as api from "~/api";
import { BackButton } from "~/components";
import {
  Email,
  EmployeeID,
  EnterCode,
  PhoneNumber,
  Success,
  Welcome
} from "~/components/RegisterEmployee";
import { useEffectOnce } from "~/lib/hooks";
import { GenericErrorText, Loading } from "~common";

interface RouteParamTypes {
  screen: string;
}

enum Screen {
  Welcome = 1,
  EmployeeID = 2,
  PhoneNumber = 3,
  Email = 4,
  EnterCode = 5,
  Success = 6
}

export const RegisterEmployeeInfo: FunctionComponent = () => {
  const SentryRoute = Sentry.withSentryRouting(Route);
  const [requesting, setRequesting] = useState(false);
  const [company, setCompany] = useState<api.Company>();
  const [confirmingId, setConfirmingId] = useState(false);
  const [errorConfirmingId, setErrorConfirmingId] = useState<Error>();
  const [employee, setEmployee] = useState<api.ExternalEmployee>();
  const [confirmingPhone, setConfirmingPhone] = useState(false);
  const [errorConfirmingPhone, setErrorConfirmingPhone] = useState(false);
  const [phone, setPhone] = useState<string>();
  const [confirmingEmail, setConfirmingEmail] = useState(false);
  const [errorConfirmingEmail, setErrorConfirmingEmail] = useState(false);
  const [email, setEmail] = useState<string>();
  const [confirmingCode, setConfirmingCode] = useState(false);
  const [errorConfirmingCode, setErrorConfirmingCode] = useState(false);
  const [sendingNewCode, setSendingNewCode] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const companyId = query.get("company") ?? "";
  const language = query.get("lang") ?? "en-US";
  const history = useHistory();
  const match = useRouteMatch();
  const matchScreen = useRouteMatch<RouteParamTypes>(`${match.path}/:screen`);
  const { screen } = matchScreen?.params || {};
  const screenValue = parseInt(screen ?? `${Screen.Welcome}`);
  const showBackButton =
    screenValue !== Screen.Welcome && screenValue !== Screen.Success;
  const showLanguages = (company?.languages ?? []).length > 1;

  const toastGenericError = (options?: ToastOptions): void => {
    toast.error(<GenericErrorText />, options);
  };

  const navigateToScreen = (screen: Screen): void =>
    history.push({ pathname: `${match.path}/${screen}`, search });

  useEffectOnce(() => {
    const loadCompany = async (): Promise<void> => {
      let response: api.APIResponse<api.Company> | undefined;
      try {
        setRequesting(true);
        response = await api.retrieveCompany(companyId);
      } catch {}

      setRequesting(false);
      if (!response?.ok) {
        toastGenericError({ autoClose: false, closeOnClick: false });
        return;
      }

      if (response.ok) {
        setCompany(response.data);
      }
    };

    loadCompany();
  });

  const onChangeLanguage = (lang: string): void => {
    query.set("lang", lang);
    window.location.assign(`${match.path}/?${query}`);
  };

  const onConfirmEmployeeId = async (employeeId: string): Promise<void> => {
    setErrorConfirmingId(undefined);
    setConfirmingId(true);

    let response: api.APIResponse<api.ExternalEmployee> | undefined;
    try {
      response = await api.retrieveExternalEmployee(companyId, employeeId);
    } catch {}

    setConfirmingId(false);

    if (!response?.ok) {
      const error = new Error();
      error.name = "unknown";
      setErrorConfirmingId(error);
      return;
    }

    setEmployee(response.data);
    navigateToScreen(Screen.PhoneNumber);
  };

  const startVerification = async (
    phoneNumber?: string,
    notifyEmail?: string
  ): Promise<api.APIResponse<{}> | undefined> => {
    let response: api.APIResponse<{}> | undefined;
    try {
      response = await api.startInfoVerification(
        companyId,
        employee?.id ?? "",
        showLanguages ? language : undefined,
        phoneNumber,
        notifyEmail
      );
    } catch {}
    return response;
  };

  const onConfirmPhone = async (phone: string): Promise<void> => {
    setPhone(phone);
    setErrorConfirmingPhone(false);
    setConfirmingPhone(true);

    const response = await startVerification(phone);

    setConfirmingPhone(false);

    if (!response?.ok) {
      setPhone(undefined);
      setErrorConfirmingPhone(true);
      return;
    }

    navigateToScreen(Screen.EnterCode);
  };

  const onConfirmEmail = async (email: string): Promise<void> => {
    setEmail(email);
    setErrorConfirmingEmail(false);
    setConfirmingEmail(true);

    const response = await startVerification(undefined, email);

    setConfirmingEmail(false);

    if (!response?.ok) {
      setEmail(undefined);
      setErrorConfirmingEmail(true);
      return;
    }

    navigateToScreen(Screen.EnterCode);
  };

  const onSendNewCode = async (): Promise<void> => {
    setSendingNewCode(true);

    const response = await startVerification(phone, email);

    setSendingNewCode(false);

    if (!response?.ok) {
      toastGenericError();
      return;
    }

    toast.success(
      <Trans id="register.enterCode.newCode">We just sent a new code!</Trans>
    );
  };

  const onConfirmCode = async (code: string): Promise<void> => {
    setErrorConfirmingCode(false);
    setConfirmingCode(true);

    let response: api.APIResponse<{}> | undefined;
    try {
      response = await api.completeInfoVerification(
        companyId,
        employee?.id ?? "",
        code
      );
    } catch {}

    setConfirmingCode(false);

    if (!response?.ok) {
      setErrorConfirmingCode(true);
      return;
    }

    navigateToScreen(Screen.Success);
  };

  if (requesting) {
    return <Loading />;
  }

  if (!company) {
    return null;
  }

  return (
    <div className="wk-min-h-screen h-full w-screen bg-white flex flex-col">
      {showBackButton && <BackButton className="my-5 ml-2.5 mr-5" />}
      <Switch>
        {company && (
          <SentryRoute
            path={`${match.path}/${Screen.EmployeeID}`}
            render={() => (
              <EmployeeID
                company={company}
                requesting={confirmingId}
                error={errorConfirmingId}
                onConfirm={onConfirmEmployeeId}
              />
            )}
            exact
          />
        )}
        {company && employee && (
          <SentryRoute
            path={`${match.path}/${Screen.PhoneNumber}`}
            render={() => (
              <PhoneNumber
                company={company}
                employee={employee}
                requesting={confirmingPhone}
                error={errorConfirmingPhone}
                onConfirm={onConfirmPhone}
                onDontHavePhone={() => navigateToScreen(Screen.Email)}
              />
            )}
            exact
          />
        )}
        {employee && (
          <SentryRoute
            path={`${match.path}/${Screen.Email}`}
            render={() => (
              <Email
                employee={employee}
                requesting={confirmingEmail}
                error={errorConfirmingEmail}
                onConfirm={onConfirmEmail}
                onDontHaveEmail={() => navigateToScreen(Screen.Success)}
              />
            )}
            exact
          />
        )}
        {(phone || email) && (
          <SentryRoute
            path={`${match.path}/${Screen.EnterCode}`}
            render={() => (
              <EnterCode
                requesting={confirmingCode || sendingNewCode}
                error={errorConfirmingCode}
                onConfirm={onConfirmCode}
                phoneNumber={phone}
                email={email}
                onSendNewCode={onSendNewCode}
              />
            )}
            exact
          />
        )}
        {company && employee && (
          <SentryRoute
            path={`${match.path}/${Screen.Success}`}
            render={() => (
              <Success
                company={company}
                friendlyName={employee.friendly_name}
                goodToGoTitle={Boolean(phone || email)}
                providedInfo={Boolean(phone || email)}
              />
            )}
            exact
          />
        )}
        <SentryRoute
          path={match.path}
          render={() => (
            <Welcome
              company={company}
              showLanguages={showLanguages}
              language={language}
              onChangeLanguage={onChangeLanguage}
              onNext={() => navigateToScreen(Screen.EmployeeID)}
            />
          )}
        />
      </Switch>
    </div>
  );
};
