import React, { FunctionComponent } from "react";

import clsx from "clsx";

interface ArrowPickerProps {
  className?: string;
  onLeft?: () => any;
  onRight?: () => any;
}

export const ArrowPicker: FunctionComponent<ArrowPickerProps> = ({
  className,
  onLeft,
  onRight
}) => (
  <div
    className={clsx("flex flex-row w-full justify-center mt-7 pb-8", className)}
  >
    <div
      onClick={onLeft}
      className="cursor-pointer mx-4 w-0 h-0 border-t-10 border-b-10 border-r-15 border-r-hs-dark-green border-t-transparent border-b-transparent"
    />
    <div
      onClick={onRight}
      className="cursor-pointer mx-4 w-0 h-0 border-t-10 border-b-10 border-l-15 border-l-hs-dark-green border-t-transparent border-b-transparent"
    />
  </div>
);
