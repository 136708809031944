import React from "react";
import { Card, Row, Col, Table } from "react-bootstrap";

export const Tables = (): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <h1>Tables</h1>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>EMPLOYEE</th>
                  <th>STATUS</th>
                  <th>DIVISION</th>
                  <th>GROUP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Vern Briggs</td>
                  <td>Working it</td>
                  <td>Prefers multiplication</td>
                  <td>Guitars, FED, Esoteric bands</td>
                </tr>
                <tr>
                  <td>Paul Terhaar</td>
                  <td>Front ending things</td>
                  <td>Long</td>
                  <td>Guitars, FED</td>
                </tr>
                <tr>
                  <td>Michael Gunderson</td>
                  <td>Windy day hat chasing</td>
                  <td>Short</td>
                  <td>FED, Esoteric bands</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Highlight on hover</h2>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>EMPLOYEE</th>
                  <th>STATUS</th>
                  <th>DIVISION</th>
                  <th>GROUP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Vern Briggs</td>
                  <td>Working it</td>
                  <td>Prefers multiplication</td>
                  <td>Guitars, FED, Esoteric bands</td>
                </tr>
                <tr>
                  <td>Paul Terhaar</td>
                  <td>Front ending things</td>
                  <td>Long</td>
                  <td>Guitars, FED</td>
                </tr>
                <tr>
                  <td>Michael Gunderson</td>
                  <td>Windy day hat chasing</td>
                  <td>Short</td>
                  <td>FED, Esoteric bands</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
