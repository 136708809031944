import React, { FunctionComponent } from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import "./CommunicateMessageList.scss";

import { CommunicateMessageRow } from "./CommunicateMessageRow";

import * as api from "~/api";

const NUM_SKELETON_ROWS = 3;
const numColumns = 5;

export const CommunicateMessageList: FunctionComponent<MessageListProps> = ({
  messages,
  onClick,
  groups
}) => {
  const skeleton =
    !messages &&
    [...Array(NUM_SKELETON_ROWS)].map((_, index) => (
      <tr key={`skeleton-${index}`} className="communicate-row-skeleton">
        {[...Array(numColumns)].map((_, index) => (
          <td key={`td-skeleton-${index}`}>
            <Skeleton />
          </td>
        ))}
      </tr>
    ));

  const communicateMessageRows = (messages ?? []).map(message => (
    <CommunicateMessageRow
      message={message}
      key={message.id}
      onClick={onClick}
      groups={groups}
    />
  ));

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>STATUS</th>
          <th>SEND DATE</th>
          <th>RECIPIENTS</th>
          <th>MESSAGE</th>
          <th>CREATED BY</th>
        </tr>
      </thead>
      <tbody>
        {skeleton}
        {communicateMessageRows}
      </tbody>
    </Table>
  );
};

interface MessageListProps {
  messages?: (api.ScheduledMessage | api.SentMessage)[];
  onClick: (message: api.SentMessage) => any;
  groups?: api.Group[];
}
