import React, { Children, FunctionComponent, ReactNode } from "react";

export interface MessageHistoryProps {
  children?: ReactNode | ReactNode[];
}

// eslint-disable-next-line max-len
export const MessageHistory: FunctionComponent<MessageHistoryProps> = ({
  children
}) => (
  <div className="flex flex-col justify-end">
    {Children.map(children, child => (
      <div className="pb-3" dir="auto">
        {child}
      </div>
    ))}
  </div>
);
