/**
 * @file Simple tools for getting page-wide ("global") data values.
 */

/**
 * Extract a javascript object from the page.
 *
 * The provided id should be the identity of a <script> tag on the page
 * that contains "global" data in the form of a JSON structure.
 *
 * These values can be created using Django 2.1's json_script tag.
 */
export const getGlobalData = <T extends object>(id: string): T | null => {
  const element = document.getElementById(id);
  let data = null;
  if (element && element.textContent) {
    try {
      data = JSON.parse(element.textContent) as T;
    } catch {
      data = null;
    }
  }
  return data;
};
