import * as api from "~/api";

export const showDivisions = (session?: api.Session): boolean => {
  const numDivisions = session?.divisions?.length ?? 0;
  return numDivisions > (session?.is_company_admin ? 0 : 1);
};

export const showDivisionsIfCompanyHasDivisions = (
  session?: api.Session
): boolean => {
  const numDivisions = session?.divisions?.length ?? 0;
  return numDivisions > 0;
};

export const singleDivisionId = (session?: api.Session): string | undefined => {
  const numDivisions = session?.divisions?.length ?? 0;
  if (numDivisions !== 1) {
    return;
  }
  return session?.divisions?.[0].id;
};
