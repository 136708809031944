import React, { FunctionComponent, useState } from "react";
import { toast } from "react-toastify";

import clsx from "clsx";
import { DateTime } from "luxon";

import { WideRow, WideTable } from "../WideTable/WideTable";

import * as api from "~/api";
import { useEffectOnce } from "~/lib/hooks";
import { ContentCard } from "~common";

/** Props type for the raw table UI */
export interface EmployeeMessageLogTableProps {
  statuses: api.EmployeeNotificationStatus[];
}

const renderMessageStatus = (status: api.MessageStatus): string => {
  switch (status) {
    case "delivered":
      return "Delivered";
    case "sent":
      return "Sent";
    case "undelivered":
    case "no_destination":
    case "opted_out":
    case "logged":
    case "accepted":
    case "queued":
    case "unknown":
      return "Undelivered";
    case "received":
      return "Received";
    case "error":
    case "rejected":
    case "failed":
      return "Error";
  }
};

const isDelivered = (status: api.MessageStatus): boolean =>
  status === "delivered" || status === "received";

/** React component for the raw table UI */
export const EmployeeMessageLogTable: FunctionComponent<
  EmployeeMessageLogTableProps
> = props => {
  // Define the table's headings
  const headings = ["Status", "Date", "Channel", "Sent To", "Details"];

  /** Make a date/time look pretty. */
  const formatTime = (iso: api.ISODateTime): string =>
    // CONSIDER: we're formatting in the browser's local time zone right now.
    // That's... probably a good decision? Dunno. -Dave
    DateTime.fromISO(iso).toFormat("LLL. dd, yyyy @ t", { locale: "en-US" });

  // Render and return the table
  return (
    <div className="mx-10 max-h-full flex flex-col">
      <ContentCard outerWrapperClassName="p-0 max-h-full w-full h-full relative ">
        <WideTable headings={headings} className="max-w-full">
          {props.statuses.map((empStatus, index) => (
            <WideRow
              key={`wide-row-${index}`}
              index={index}
              record={[
                renderMessageStatus(empStatus.status),
                formatTime(empStatus.sent),
                empStatus.channel === "sms" ? "SMS" : "Email",
                empStatus.target,
                empStatus.details ?? ""
              ]}
              className={clsx(
                !isDelivered(empStatus.status) && "bg-hs-pale-red"
              )}
            />
          ))}
        </WideTable>
      </ContentCard>
    </div>
  );
};

/** Props type for the top-level API-calling react component */
export interface EmployeeMessageLogProps {
  employee: api.Employee;
}

/** The top-level API-calling react component */
export const EmployeeMessageLog: FunctionComponent<
  EmployeeMessageLogProps
> = props => {
  const { employee } = props;
  const [statuses, setStatuses] = useState<api.EmployeeNotificationStatus[]>(
    []
  );

  useEffectOnce(() => {
    loadStatuses();
  });

  /** Invoke the API to get the employee's notification statuses. */
  const loadStatuses = async (): Promise<void> => {
    const response = await api.retrieveEmployeeNotificationStatuses(
      employee.company_id,
      employee.id
    );

    if (response.ok) {
      setStatuses(response.data ?? []);
    } else {
      toast.error(
        "We were unable to retrieve message history. Please refresh to try again."
      );
    }
  };

  return <EmployeeMessageLogTable statuses={statuses} />;
};
