import React, { FC, useEffect, useState } from "react";
import "./ConfettiAnimation.scss";

export const ConfettiAnimation: FC<ConfettiAnimationlInterface> = ({
  delay = 4000
}) => {
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimation(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return showAnimation ? (
    <div className="confetti-animation-background" />
  ) : null;
};

interface ConfettiAnimationlInterface {
  delay?: number;
}
