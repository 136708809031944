import React, {
  CSSProperties,
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useEffect,
  useReducer,
  useState
} from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";
import { DateTime } from "luxon";

import { Linkified } from "./Linkified";

import * as api from "~/api";
import { useEffectOnce, useLinguiLocale } from "~/lib/hooks";
import { TeamSenseCheck } from "~/svg";
import { MessageActionButton } from "~common";

enum Logo {
  darkGreen,
  white
}
type LogoString = keyof typeof Logo;

interface BadgeWrapperProps {
  className: string;

  /** extra styling string */
  style?: CSSProperties;

  logo: LogoString;

  children?: ReactNode | ReactNode[];
}

const ConversationBadge: FunctionComponent<BadgeWrapperProps> = ({
  className,
  children,
  logo,
  style
}) => (
  <div
    className={clsx("wk-min-h-screen flex flex-col items-center", className)}
    style={style}
  >
    <div className="mx-auto content-center pt-4 pb-1">
      <TeamSenseCheck fill={logo === "white" ? "#ffffff" : "#263d39"} />
    </div>
    {children}
  </div>
);

/** Navigation function with curried URI for future event handling. */
function navTo(uri: string): (e: MouseEvent) => void {
  return (e: MouseEvent) => {
    // TODO
    console.log(`TODO: Navigate to: ${uri}`);
  };
}

export interface ConversationBadgeProps {
  /** date conversation created */
  created: api.ISODateTime;

  /** extra styling string */
  style?: CSSProperties;

  /** date badge expires */
  expiration?: api.ISODateTime;

  /** Employee friendly name */
  friendlyName: string;

  /** Employee name */
  name: string;

  /** Employee ID */
  employeeId: string;

  /** optional Title. */
  title?: string;

  /** optional Subtitle. */
  subtitle?: string;

  /** optional CheckIn URI. */
  checkInURI?: string;

  /** optional onStartOver callback - when present, show a Start Over button */
  onStartOver?: () => any;
}

export const AlertConversationBadge: FunctionComponent<
  ConversationBadgeProps
> = ({
  created,
  style,
  friendlyName,
  title,
  subtitle,
  checkInURI,
  onStartOver
}) => {
  const locale = useLinguiLocale();
  let alertDate = "";
  try {
    alertDate = DateTime.fromISO(created)
      .setLocale(locale)
      .toLocaleString(DateTime.DATE_MED);
  } catch (e) {
    alertDate = DateTime.fromISO(created)
      .setLocale("en-US")
      .toLocaleString(DateTime.DATE_MED);
  }

  return (
    <ConversationBadge
      className="text-gray-100"
      style={{
        ...style,
        backgroundImage: "linear-gradient(180deg, #282B30 0%, #2A2E34 100%)"
      }}
      logo="white"
    >
      <div
        className="badge flex flex-col content-center text-center pt-1/5 overflow-y-auto"
        style={{ flex: 2 }}
      >
        <div className="text-6xl font-bold text-hs-red" dir="auto">
          {title}
          {!title && <Trans id="badge.alert.title">Alert!</Trans>}
        </div>
        <div className="message pw-4 pt-3">
          <div className="px-10 text-left whitespace-pre-wrap" dir="auto">
            {subtitle && <Linkified body={subtitle} />}
            {!subtitle && (
              <Trans id="badge.alert.message" values={{ friendlyName }}>
                {friendlyName}, thank you for responding. We have alerted your
                supervisor.
              </Trans>
            )}
          </div>
          <div className="mt-4 text-xs" dir="auto">
            {
              <Trans id="badge.alert.date" values={{ alertDate }}>
                Date of Alert: {alertDate}
              </Trans>
            }
          </div>
        </div>
      </div>
      <div className="footer p-4 self-stretch" dir="auto">
        {checkInURI && (
          <MessageActionButton
            text={<Trans id="badge.checkIn">Check-in</Trans>}
            theme="red"
            onClick={navTo(checkInURI)}
          />
        )}
        {onStartOver && (
          <MessageActionButton
            className="mt-4"
            text={<Trans id="badge.startOver">Start Over</Trans>}
            theme="dark-green"
            onClick={onStartOver}
          />
        )}
      </div>
    </ConversationBadge>
  );
};

export const ExpiredConversationBadge: FunctionComponent<
  ConversationBadgeProps
> = ({ expiration, style, friendlyName, checkInURI, onStartOver }) => {
  const locale = useLinguiLocale();
  const expirationDate = DateTime.fromISO(expiration ?? "")
    .setLocale(locale)
    .toLocaleString(DateTime.DATE_MED);

  return (
    <ConversationBadge
      style={style}
      className="text-gray-100 bg-hs-dark-green"
      logo="white"
    >
      <div
        className="badge flex flex-col content-center text-center pt-1/5 overflow-y-auto"
        style={{ flex: 2 }}
      >
        <div className="text-6xl font-bold text-hs-light" dir="auto">
          <Trans id="badge.expired.title">Expired</Trans>
        </div>
        <div className="message pt-3" dir="auto">
          <div className="px-10">
            {checkInURI && (
              <Trans id="badge.expired.message" values={{ friendlyName }}>
                {friendlyName}, please update your status by checking in below.
              </Trans>
            )}
          </div>
          <div className="text-xs">{expirationDate}</div>
        </div>
      </div>
      <div className="footer p-4 self-stretch" dir="auto">
        {checkInURI && (
          <MessageActionButton
            text={<Trans id="badge.checkIn">Check-in</Trans>}
            theme="red"
            onClick={navTo(checkInURI)}
          />
        )}
        {onStartOver && (
          <MessageActionButton
            className="mt-4"
            text={<Trans id="badge.startOver">Start Over</Trans>}
            theme="dark-green"
            onClick={onStartOver}
          />
        )}
      </div>
    </ConversationBadge>
  );
};

export const ClearWithActiveSickConversationBadge: FunctionComponent<
  ConversationBadgeProps
> = ({ style, onStartOver }) => (
  <ConversationBadge
    style={style}
    className="text-gray-100 bg-hs-dark"
    logo="white"
  >
    <div
      className="badge flex flex-col w-full content-center justify-center text-center overflow-y-auto"
      style={{ flex: 2 }}
    >
      <div
        className="text-6xl font-bold leading-none text-hs-yellow"
        dir="auto"
      >
        <Trans id="badge.pending.title">Status Pending</Trans>
      </div>
      <div className="message pt-9 px-12 text-base" dir="auto">
        <Trans id="badge.pending.message">
          We’re glad you feel ready to return to work! You will need to be
          cleared for work by a company administrator. Please contact HR and
          remain at home until you receive clearance.
        </Trans>
      </div>
    </div>
    <div className="footer p-4 self-stretch" dir="auto">
      {onStartOver && (
        <MessageActionButton
          className="mt-4"
          text={<Trans id="badge.startOver">Start Over</Trans>}
          theme="dark-green"
          onClick={onStartOver}
        />
      )}
    </div>
  </ConversationBadge>
);

const CardRow: FunctionComponent<{
  className?: string;
  label?: string | ReactNode;
  value?: string | null;
}> = ({ className, label, value }) => (
  <div
    className={clsx(className, "flex flex-row justify-between items-center")}
  >
    <div className="text-base font-semibold text-hs-medium-dark-gray">
      {label}
    </div>
    <div className="text-1-5xl font-semibold text-hs-dark-green">{value}</div>
  </div>
);

const BadgeCard: FunctionComponent<{
  name: string;
  employeeId: string;
  hideEmployeeDetails?: boolean;
  children?: ReactNode;
}> = ({ name, employeeId, hideEmployeeDetails, children }) => (
  <div className="bg-white rounded-lg m-5">
    <div className="p-5" dir="auto">
      <div hidden>
        <Trans id="badge.prefixedId" values={{ employeeId }}>
          ID: {employeeId}
        </Trans>
      </div>
      {!hideEmployeeDetails && (
        <>
          <div className="text-1-5xl leading-relaxed font-semibold text-hs-dark-green whitespace-pre-wrap">
            {name}
          </div>
          <div className="mt-1 mb-5 text-1-5xl font-semibold text-hs-medium-dark-gray">
            <Trans id="badge.prefixedId" values={{ employeeId }}>
              ID: {employeeId}
            </Trans>
          </div>
          <div className="-mx-5 border border-hs-dark-gray" />
        </>
      )}
      <>{children}</>
    </div>
  </div>
);

export interface GenericConversationBadgeProps {
  /** Employee */
  employee: api.Employee;

  /** extra styling string */
  style?: CSSProperties;

  /** Context for template strings */
  context: api.BadgeContext;

  /** optional Title. */
  title?: string;

  /** optional Header. */
  header?: string;

  /** optional Body. */
  body?: string;

  /** optional Flag to hide employee details. */
  hideEmployeeDetails?: boolean;

  /** optional onStartOver callback - when present, show a Start Over button */
  onStartOver?: () => any;
}

export const GenericConversationBadge: FunctionComponent<
  GenericConversationBadgeProps
> = ({
  employee,
  style,
  context,
  title,
  header,
  body,
  hideEmployeeDetails,
  onStartOver
}) => {
  const { name, external_id: employeeId } = employee;
  const { eventName, eventDateRangeShort, completedDate, completedTime } =
    context;
  return (
    <ConversationBadge
      className="text-hs-dark-green bg-hs-white-green"
      style={style}
      logo="darkGreen"
    >
      <div className="m-2.5 text-center">
        <div
          className="my-2.5 text-lg text-hs-medium-dark-gray font-semibold whitespace-pre-wrap"
          dir="auto"
        >
          {header}
          {!header && (
            <Trans
              id="badge.generic.header"
              values={{ completedDate, completedTime }}
            >
              Form submitted on {completedDate} at {completedTime}
            </Trans>
          )}
        </div>
        <div
          className="text-1-5xl font-semibold whitespace-pre-wrap"
          dir="auto"
        >
          {title}
          {!title &&
            eventName &&
            eventDateRangeShort &&
            `${eventName}\n${eventDateRangeShort}`}
          {!title && !eventName && (
            <Trans id="badge.generic.title">
              Survey successfully submitted
            </Trans>
          )}
        </div>
      </div>
      <div className="w-full flex-grow overflow-y-auto">
        <BadgeCard
          name={name}
          employeeId={employeeId}
          hideEmployeeDetails={hideEmployeeDetails}
        >
          <div className="my-4 leading-7 whitespace-pre-wrap" dir="auto">
            {body && <Linkified body={body} />}
            {!body && (
              <Trans id="badge.generic.body">
                Thanks for completing our survey! 👍
              </Trans>
            )}
          </div>
        </BadgeCard>
      </div>
      {onStartOver && (
        <div className="w-full flex flex-row max-h-20 p-4" dir="auto">
          <MessageActionButton
            text={<Trans id="badge.startOver">Start Over</Trans>}
            theme="dark-green"
            onClick={onStartOver}
          />
        </div>
      )}
    </ConversationBadge>
  );
};

export const ClearConversationBadge: FunctionComponent<
  ConversationBadgeProps
> = ({
  created,
  style,
  expiration,
  friendlyName,
  subtitle,
  name,
  employeeId,
  onStartOver
}) => {
  const locale = useLinguiLocale();
  const expirationDT = DateTime.fromISO(expiration ?? "").setLocale(locale);
  const forceUpdate = useReducer(x => x + 1, 0)[1];

  useEffectOnce(() => {
    const intervalId = setInterval(forceUpdate, 1000);
    return () => clearInterval(intervalId);
  });

  const conversationMonthAndDay = DateTime.fromISO(created)
    .setLocale(locale)
    .toLocaleString({ ...DateTime.DATE_MED, year: undefined });
  const expiresIn = expirationDT.toRelative();
  const expirationDate = expirationDT.toLocaleString({
    ...DateTime.DATE_SHORT,
    weekday: "short",
    year: undefined
  });
  const expirationTime = expirationDT.toLocaleString({
    ...DateTime.TIME_SIMPLE,
    timeZoneName: "short"
  });

  return (
    <ConversationBadge
      className="text-gray-100"
      style={{
        ...style,
        backgroundImage: "linear-gradient(0deg, #01C16B, #01C16B)"
      }}
      logo="white"
    >
      <div
        className="text-center text-4-3xl font-bold leading-tight whitespace-pre-wrap"
        dir="auto"
      >
        <Trans
          id="badge.clear.title"
          values={{ conversationMonthAndDay }}
          components={[<br key="1" />]}
        >
          Clear
          <br />
          {conversationMonthAndDay}
        </Trans>
      </div>
      <div className="w-full flex-grow overflow-y-auto">
        <BadgeCard name={name} employeeId={employeeId}>
          {expirationDT.isValid && (
            <>
              <div className="my-4" dir="auto">
                <CardRow
                  className="mb-10"
                  label={<Trans id="badge.clear.expires">Expires:</Trans>}
                  value={expiresIn}
                />
                <CardRow
                  label={
                    <Trans id="badge.clear.validUntil">Valid until:</Trans>
                  }
                  value={expirationDate}
                />
                <CardRow className="mb-3" value={expirationTime} />
              </div>
              <div className="-mx-5 border-2 border-dashed border-hs-dark-gray" />
            </>
          )}
          <div className="mt-5 text-hs-dark-green leading-relaxed" dir="auto">
            <>
              {subtitle}
              {!subtitle && (
                <Trans id="badge.clear.message" values={{ friendlyName }}>
                  {friendlyName}, please show this screen as you enter the
                  facility.
                </Trans>
              )}
            </>
          </div>
        </BadgeCard>
      </div>
      {onStartOver && (
        <div className="w-full flex flex-row max-h-20 p-4" dir="auto">
          <MessageActionButton
            text={<Trans id="badge.startOver">Start Over</Trans>}
            theme="dark-green"
            onClick={onStartOver}
          />
        </div>
      )}
    </ConversationBadge>
  );
};

export interface ExpireableConversationBadgeProps {
  /** date badge expires */
  expiration?: api.ISODateTime;

  /** ConversationBadge to show when not expired. */
  badge: ReactNode;

  /** ConversationBadge to show when expired. */
  expiredBadge: ReactNode;
}

export const ExpireableConversationBadge: FunctionComponent<
  ExpireableConversationBadgeProps
> = ({ expiration, badge, expiredBadge }) => {
  const expirationDT: DateTime = DateTime.fromISO(expiration ?? "");
  const calcRemaining = (): number => expirationDT.toMillis() - Date.now();
  const [remaining, setRemaining] = useState(calcRemaining);

  useEffect(() => {
    if (!expirationDT.isValid) {
      return;
    }
    const timer = setTimeout(() => {
      setRemaining(calcRemaining());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return <>{expiration && remaining <= 0 ? expiredBadge : badge}</>;
};
