import * as React from "react";

import {
  default as MuiTypography,
  TypographyProps
} from "@mui/material/Typography";

import ThemeLayout from "../Theme/ThemeLayout";

const Typography = (
  props: TypographyProps
): React.ReactElement<TypographyProps> => {
  const { children, ...componentProps } = props;
  return (
    <ThemeLayout>
      <MuiTypography {...componentProps}>{children}</MuiTypography>
    </ThemeLayout>
  );
};

export default Typography;
