import React, { FunctionComponent, useState } from "react";

import { t } from "@lingui/macro";
import { I18n, Trans } from "@lingui/react";
import clsx from "clsx";

import { ValidatedString } from "~/components/EmployeeCheckInRequest/types";
import TextBubbleIcon from "~/images/text-bubble-icon.svg";
import { displayPhoneNumber } from "~/lib/phoneNumber";
import { Loading, MessageActionButton, TextInput } from "~common";

const CODE_LENGTH = 6;
const codeFormatRegex = RegExp(String.raw`^[0-9]{${CODE_LENGTH}}$`);

interface EnterCodeProps {
  className?: string;
  phoneNumber?: string;
  email?: string;
  requesting: boolean;
  error: boolean;
  onConfirm: (code: string) => any;
  onSendNewCode: () => any;
}

export const EnterCode: FunctionComponent<EnterCodeProps> = ({
  className,
  phoneNumber,
  email,
  requesting,
  error,
  onConfirm,
  onSendNewCode
}) => {
  const [code, setCode] = useState<ValidatedString>();
  let codeDestination;
  if (phoneNumber) {
    codeDestination = displayPhoneNumber(phoneNumber);
  } else {
    codeDestination = email;
  }

  return (
    <div
      className={clsx(
        "h-full w-full flex-grow flex flex-col justify-between",
        className
      )}
    >
      <div className="p-9 text-2xl font-bold text-hs-dark-green">
        <p>
          {phoneNumber && (
            <Trans id="register.enterCode.summary.phone">
              Thanks! Please check your texts for a code
            </Trans>
          )}
          {email && (
            <Trans id="register.enterCode.summary.email">
              Thanks! Please check your email inbox for a code
            </Trans>
          )}
        </p>
        <p className="mt-4 font-medium text-base text-hs-even-darker-gray">
          <Trans id="register.enterCode.detail" values={{ codeDestination }}>
            We just sent a six-digit code to {codeDestination} that will be
            valid for 10 minutes
          </Trans>
        </p>
      </div>

      <div className="bg-hs-light p-9 rounded-t-4xl">
        <div className="flex flex-row items-center mb-2">
          <img className="mr-4" src={TextBubbleIcon} alt="Code" />
          <p className="text-2xl font-semibold text-hs-dark-green">
            <Trans id="register.enterCode.label">Enter your code:</Trans>
          </p>
        </div>

        <I18n>
          {({ i18n }) => (
            <TextInput
              className={clsx(
                "my-3 py-2 px-5 border rounded text-lg w-full",
                !error && "border-hs-dark-gray focus:border-hs-green",
                error && "border-hs-error-red"
              )}
              validationRegex={codeFormatRegex}
              inputType="tel"
              maxLength={CODE_LENGTH}
              placeholder={i18n._(
                t("register.enterCode.placeholder")`Enter the code we sent`
              )}
              value={code?.value ?? ""}
              onChange={(value, valid) => {
                setCode({ value, valid });
              }}
              onEnter={() => code?.valid && onConfirm(code?.value)}
            />
          )}
        </I18n>

        {error && (
          <p className="my-3 text-xs text-hs-error-red">
            <Trans id="register.enterCode.error">
              Please double check and try again. It's possible your code
              expired; if so, you can get a new one below.
            </Trans>
          </p>
        )}

        {requesting && <Loading />}

        <div className="pt-5 pb-3">
          <MessageActionButton
            theme="dark-green"
            disabled={!code?.valid}
            onClick={() => code?.valid && onConfirm(code.value)}
            text={<Trans id="register.enterCode.confirm">Confirm code</Trans>}
          />
          <div
            className="mt-6 text-center text-lg underline cursor-pointer text-hs-dark-green"
            onClick={onSendNewCode}
          >
            <Trans id="register.enterCode.sendNew">Send a new code</Trans>
          </div>
        </div>
      </div>
    </div>
  );
};
