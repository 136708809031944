import { DateTime } from "luxon";

import styles from "../ts-styles/shared.scss";

import * as api from "~/api";

const defaultHeatMapBg = styles.brandGray94;
const defaultHeatMapTextColor = styles.brandGray20;

export type FrameLength = "month" | "week" | "day" | undefined;
export type AttendanceResponse =
  | api.APIResponse<api.AttendanceInsightsResponse>
  | api.APIResponse<api.AttendanceInsightsEmployeeResponse>
  | undefined;

export const endDate = DateTime.local()
  .setZone("US/Pacific")
  .minus({ days: 1 })
  .toISODate();

export const orderFrames = (frames: api.Frame[]): void => {
  frames.sort((a, b) => {
    const aDate = DateTime.fromISO(a.name).toMillis();
    const bDate = DateTime.fromISO(b.name).toMillis();
    return aDate - bDate;
  });
};

export const heatmapColorMap = [
  {
    color: defaultHeatMapBg,
    value: "0%",
    textColor: defaultHeatMapTextColor,
    max: 0
  },
  {
    color: styles.brandTeal91,
    value: "< 1%",
    textColor: defaultHeatMapTextColor,
    max: 1
  },
  {
    color: styles.brandTeal78,
    value: "1% - 2%",
    textColor: defaultHeatMapTextColor,
    max: 2
  },
  {
    color: styles.brandTeal56,
    value: "2% - 3%",
    textColor: defaultHeatMapTextColor,
    max: 3
  },
  {
    color: styles.brandTeal39,
    value: "3% - 5%",
    textColor: "#ffffff",
    max: 5
  },
  { color: styles.brandTeal19, value: "> 5%", textColor: "#ffffff", max: 100 }
];

export const getHeatmapColor = (value?: number): string => {
  if (value) {
    return (
      heatmapColorMap.find(tier => value < tier.max)?.color || defaultHeatMapBg
    );
  }
  return defaultHeatMapBg;
};

export const getHeatmapTextColor = (value?: number): string => {
  if (value) {
    return (
      heatmapColorMap.find(tier => value < tier.max)?.textColor ||
      defaultHeatMapTextColor
    );
  }
  return defaultHeatMapTextColor;
};

export const roundPercentForInsights = (percent: number): string => {
  const absPercent = Math.abs(percent);
  if (absPercent === 0) {
    return "0%";
  } else if (absPercent < 0.1) {
    return "< 0.1%";
  } else {
    return `${Math.round(percent * 10) / 10}%`;
  }
};

interface EmailToHrefProps {
  emailTo: string;
  subject?: string;
  body?: string;
}

export const emailToHref: (props: EmailToHrefProps) => string = ({
  emailTo,
  subject,
  body
}) => {
  let href = `mailto:${emailTo}`;
  if (subject) {
    href += `?subject=${encodeURIComponent(subject)}`;
  }
  if (body) {
    href += `${subject ? "&" : "?"}body=${encodeURIComponent(body)}`;
  }
  return href;
};
