import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";

import { Employee, StringAnswer, SurveyState } from "~/api";
import { processConversationTranslation } from "~/lib/hooks";
import { convertAnswerToString } from "~/lib/surveyUtils";
import Typography from "~/mui-components/Typography/Typography";

export const AttendanceSurvey: FunctionComponent<AttendanceSurveyProps> = ({
  survey,
  employee,
  language
}) => {
  return (
    <div className="flex flex-col text-sm gap-2.5">
      {survey?.history.map(({ step, answer, name }, index) => {
        if (!("prompts" in step) || !answer) {
          return null;
        }

        const prompt = step?.prompts?.slice(-1)[0];
        const question = processConversationTranslation(language, prompt);
        const answerString = convertAnswerToString(
          step,
          answer,
          language,
          employee?.timezone
        );
        const isStringPending =
          step.kind === "string" && ((answer as StringAnswer).pending || false);

        const StepAnswer = ({
          text,
          isPending
        }: {
          text: string | null;
          isPending: boolean;
        }): JSX.Element => {
          const PendingTranscription = (): JSX.Element => (
            <Typography
              variant="h3"
              className="bg-ts-teal-90 text-ts-gray-50 rounded p-2 fond-medium italic w-fit"
            >
              <Trans id="survey.answer.pendingTranscription">
                Voice transcription in progress. Please refresh in a minute to
                see the response
              </Trans>
            </Typography>
          );

          const StepMessage = ({ text }: { text: string }): JSX.Element => (
            <Typography variant="h3">{text}</Typography>
          );

          const StepNoMessage = (): JSX.Element => (
            <Typography variant="h3" className="text-ts-gray-40">
              No answer provided
            </Typography>
          );
          if (isPending) {
            return <PendingTranscription />;
          } else if (text) {
            return <StepMessage text={text} />;
          } else {
            return <StepNoMessage />;
          }
        };

        return (
          <div
            key={`q-and-a-${name}-${index}`}
            className="flex flex-row step gap-1"
          >
            <Typography variant="h5" className="text-ts-gray-40">
              {index + 1}.
            </Typography>
            <div className="flex flex-col gap-2.5">
              <Typography variant="h5" className="text-ts-gray-40">
                {question}
              </Typography>
              {step && answer && (
                <StepAnswer text={answerString} isPending={isStringPending} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface AttendanceSurveyProps {
  employee: Employee;
  survey: SurveyState;
  language: string;
}
