import React, { FunctionComponent } from "react";
import { Container, Navbar } from "react-bootstrap";

import BrandWhite from "~/images/brand-white.svg";
import { ErrorPageNotFound } from "~/ts-components/common/errors/ErrorPageNotFound";
import { TSFooter } from "~common";

export const Error404: FunctionComponent = () => {
  return (
    <div className="page-wrapper">
      <main className="ts">
        <Navbar
          className="ts-navbar"
          bg="secondary"
          collapseOnSelect
          expand="lg"
          variant="dark"
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                className="teamsense-logo"
                src={BrandWhite}
                alt="TeamSense"
              />
            </Navbar.Brand>
          </Container>
        </Navbar>

        <Container fluid>
          <ErrorPageNotFound></ErrorPageNotFound>
        </Container>
      </main>
      <TSFooter />
    </div>
  );
};
