import React, { FunctionComponent, useEffect, useState } from "react";

import clsx from "clsx";

interface Props {
  className?: string;
  delaySeconds?: number;
}

export const CenteredLoadingSpinner: FunctionComponent<Props> = ({
  className,
  delaySeconds = 0
}) => {
  const [visible, setVisible] = useState(delaySeconds === 0);

  useEffect(() => {
    if (delaySeconds === 0) {
      return;
    }
    const handle = setTimeout(() => setVisible(true), delaySeconds * 1000);
    return () => clearTimeout(handle);
  }, [delaySeconds]);

  return (
    <div
      className={clsx("flex items-center justify-center", className)}
      data-testid="wrapper"
    >
      <i
        className={clsx(
          "fas fa-circle-notch fa-spin fa-3x",
          !visible && "opacity-0"
        )}
      />
    </div>
  );
};

export const Loading: FunctionComponent<Props> = ({ className, ...rest }) => (
  <CenteredLoadingSpinner
    className={clsx(className, "absolute inset-0")}
    {...rest}
  />
);
