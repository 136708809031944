import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "~/AuthConfig";

// Holds a reference to the msal PublicClientApplication
// In react components, this is accessed via the `useMsal()` hook

// This is a singleton reference because API wrapper functions live outside the react lifecycle,
// and need to use the same instance to retrieve a JWT for use as bearer token.
let _instance: PublicClientApplication | null = null;

export const msalInstance = (): PublicClientApplication => {
  if (!_instance) {
    _instance = new PublicClientApplication(msalConfig);
  }

  return _instance;
};

// Used for setting the instance in automated tests
export const setMsalInstance = (
  instance: null | PublicClientApplication
): void => {
  _instance = instance;
};
