import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Badge, Modal, Table } from "react-bootstrap";

import humanizeDuration from "humanize-duration";

import { ToastContext } from "../common/ts-toast/TSToastProvider";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import {
  GenericErrorText,
  SelectOptionsProps,
  TSButton,
  TSInput
} from "~common";

import "./FormReminders.scss";

const SURVEY_REMINDER_OPTIONS: SelectOptionsProps[] = [
  { label: "1 Hour", value: 3600000 },
  { label: "2 Hours", value: 7200000 },
  { label: "4 Hours", value: 14400000 },
  { label: "1 Day", value: 86400000 },
  { label: "2 Days", value: 172800000 },
  { label: "3 Days", value: 259200000 },
  { label: "1 Week", value: 604800000 },
  { label: "2 Weeks", value: 1209600000 },
  { label: "4 Weeks", value: 2419200000 }
];

export const FormReminders: FC<FormReminderProps> = props => {
  const { formId } = props;

  const { session } = useContext(MutableSessionContext);
  const toast = useRef(useContext(ToastContext));

  const [surveyReminders, setSurveyReminders] =
    useState<api.SurveyReminder[]>();
  const [selectedReminderDuration, setSelectedReminderDuration] = useState<
    string | undefined
  >();
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentReminder, setCurrentReminder] =
    useState<api.SurveyReminder | null>();

  const display_send_time = (duration: number): ParsedDuration => {
    const [value, unit] = humanizeDuration(duration, {
      units: ["w", "d", "h", "m"]
    }).split(" ");

    return { value, unit };
  };

  const surveyOptionItems = SURVEY_REMINDER_OPTIONS.map(item => {
    item.disabled = false;
    if (
      surveyReminders &&
      surveyReminders?.findIndex(p => p.duration === item.value) > -1
    ) {
      item.disabled = true;
    }
    return item;
  });

  // API calls
  const loadSurveyReminders = useCallback(async (): Promise<void> => {
    if (!session.company || !formId) {
      return;
    }

    let response: api.APIResponse<api.SurveyReminder[]> | undefined;
    try {
      response = await api.retrieveSurveyReminders(session.company.id, formId);
    } catch {}

    if (response?.ok) {
      setSurveyReminders(response.data);
    } else {
      toast.current.show({
        variant: "danger",
        message: <GenericErrorText />
      });
    }
  }, [formId, session.company]);

  const createOrUpdateSurveyReminder = useCallback(
    async (reminder: api.SurveyReminder): Promise<void> => {
      if (!session.company || !formId) {
        return;
      }

      let response: api.APIResponse<api.SurveyReminder> | undefined;
      try {
        if (reminder.id) {
          response = await api.updateSurveyReminder(reminder);
        } else {
          response = await api.addSurveyReminder(reminder);
        }
      } catch {}

      if (response?.status === 200 || response?.status === 201) {
        setShowReminderModal(false);
        loadSurveyReminders();

        toast.current.show({
          variant: "success",
          message: reminder.id
            ? "Your reminder changes have been saved."
            : "Your new reminder has been added."
        });
      } else {
        const apiErrors = response as api.APIErrorResponse;
        toast.current.show({
          variant: "danger",
          message: apiErrors.errors?.non_field_errors?.shift() || (
            <GenericErrorText />
          )
        });
      }
    },
    [loadSurveyReminders, formId, session.company]
  );

  const deleteSurveyReminder = useCallback(
    async (reminder: api.SurveyReminder): Promise<void> => {
      if (!session.company || !formId) {
        return;
      }

      let response: api.APIResponse<object> | undefined;
      try {
        reminder.company_id = session.company?.id;
        response = await api.deleteSurveyReminder(reminder);
      } catch {}

      if (response?.status === 204) {
        loadSurveyReminders();

        toast.current.show({
          variant: "danger",
          message: "Your reminder has been deleted."
        });
      } else {
        toast.current.show({
          variant: "danger",
          message: <GenericErrorText />
        });
      }

      setShowReminderModal(false);
      setShowDeleteConfirmation(false);
    },
    [session.company, loadSurveyReminders, formId]
  );

  // Event Handlers
  const reminderModalExitHandler = (): void => {
    setCurrentReminder(null);
    setSelectedReminderDuration("");
  };

  const surveyReminderSelectChangedHandler = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    if (
      !currentReminder ||
      Number(event.target.value) !== currentReminder.duration
    ) {
      setSelectedReminderDuration(event.target.value);
    }
  };

  const reminderRowClickHandler = (reminder: api.SurveyReminder): void => {
    setCurrentReminder(reminder);
    setShowReminderModal(true);
  };

  const setReminderClickHandler = (): void => {
    if (!selectedReminderDuration) {
      return;
    }

    createOrUpdateSurveyReminder({
      id: currentReminder?.id,
      company_id: session.company?.id,
      survey_template: currentReminder?.survey_template || formId,
      duration: Number(selectedReminderDuration)
    });
  };

  const deleteReminderClickHandler = (
    currentReminder: api.SurveyReminder
  ): void => {
    deleteSurveyReminder(currentReminder);
  };

  // Effects
  useEffect(() => {
    loadSurveyReminders();
  }, [loadSurveyReminders]);

  // Content fragments
  const tableRows = (surveyReminders ?? []).map(reminder => {
    const duration = display_send_time(reminder.duration);
    return (
      <tr
        className="hover-pointer"
        key={reminder.id}
        onClick={() => reminderRowClickHandler(reminder)}
      >
        <td className="reminder-duration">
          <div className="reminder-duration-length">{duration.value}</div>
          <Badge pill bg="light" text="dark" className="reminder-badge">
            {duration.unit}
          </Badge>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className="main-content-header">
        <TSButton
          variant="primary"
          onClick={() => setShowReminderModal(true)}
          className="ms-auto"
        >
          Add Reminder
        </TSButton>
      </div>

      <Table responsive className="p-3 form-reminders-table" hover>
        <thead>
          <tr className="text-muted text-xs">
            <th>SEND TIME FROM SURVEY START</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
          {surveyReminders?.length === 0 && (
            <tr>
              <td>There are no reminders configured.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal
        className="confirm-delete-modal"
        container={document.getElementById("ts-modal")}
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        centered
      >
        <Modal.Body>
          <h1 className="text-danger">
            Are you sure you want to permanently delete this reminder?
          </h1>
          <p>You can't retrieve deleted reminders.</p>
        </Modal.Body>
        <Modal.Footer>
          <TSButton
            variant="link"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </TSButton>
          <TSButton
            variant="danger"
            endIcon="icon-trash"
            onClick={() => {
              currentReminder?.id &&
                deleteReminderClickHandler(currentReminder);
            }}
          >
            Delete
          </TSButton>
        </Modal.Footer>
      </Modal>
      <Modal
        container={document.getElementById("ts-modal")}
        show={showReminderModal}
        onHide={() => setShowReminderModal(false)}
        onExited={reminderModalExitHandler}
        centered
      >
        <Modal.Body>
          <h1>Set Survey Reminder</h1>
          <TSInput
            select
            options={surveyOptionItems}
            id="surveyReminderValue"
            label="Reminder"
            placeholder="Select Item"
            onChange={surveyReminderSelectChangedHandler}
            defaultValue={currentReminder?.duration || ""}
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="reminders-modal-footer">
            <div>
              {currentReminder && (
                <TSButton
                  variant="danger"
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  Delete
                </TSButton>
              )}
            </div>
            <div>
              <TSButton
                variant="link"
                onClick={() => setShowReminderModal(false)}
              >
                Cancel
              </TSButton>
              <TSButton
                variant="primary"
                onClick={setReminderClickHandler}
                disabled={!selectedReminderDuration}
              >
                Set Reminder
              </TSButton>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface FormReminderProps {
  formId: string;
}

interface ParsedDuration {
  value: string;
  unit: string;
}
