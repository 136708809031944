import React, { ReactText, FC } from "react";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import "./FormResults.scss";

interface FormResultsStatCardProps {
  iconClassName: string;
  value?: ReactText;
  description: string;
}

export const FormResultsStatCard: FC<FormResultsStatCardProps> = ({
  iconClassName,
  value,
  description
}) => (
  <Card body className="quick-stat rounded-0">
    {value === undefined ? (
      <>
        <i key="icon">
          <Skeleton width={32} />
        </i>
        <h1 key="value">
          <Skeleton width={64} />
        </h1>
        <p key="description">
          <Skeleton width={150} />
        </p>
      </>
    ) : (
      <>
        <i key="icon" className={iconClassName} />
        <h1 key="value">{value}</h1>
        <p key="description">{description}</p>
      </>
    )}
  </Card>
);
