import React, { FC, ReactNode, useState } from "react";
import { Form } from "react-bootstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";

import clsx from "clsx";
import {
  Option,
  TypeaheadPropsAndState,
  RenderToken
} from "react-bootstrap-typeahead/types/types";

import "./TSTypeahead.scss";

export const TSTypeahead: FC<TSTypeaheadProps> = props => {
  const {
    className,
    defaultSelected,
    errorText,
    helpText,
    id,
    isInvalid = false,
    label,
    labelKey,
    multiple,
    onBlur,
    onChange,
    onFocus,
    options,
    paginate,
    placeholder,
    autoFocus,
    required,
    selected,
    readOnly,
    showId = false,
    showAllOptions = false,
    asyncSearchHandler,
    asyncSearchDelay,
    asyncSearchText,
    renderToken,
    children
  } = props;

  const [editing, setEditing] = useState(false);
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [asyncOptions, setAsyncOptions] = useState<Option[]>([]);

  // handlers
  const onChangeHandler = (event: Option[]): void => {
    const newCount = event.length;
    if (multiple && asyncSearchHandler && newCount > selectedCount) {
      setAsyncOptions([]);
    }
    setSelectedCount(newCount);
    onChange?.(event);
  };

  const onFocusHandler = (
    event: React.SyntheticEvent<HTMLInputElement, Event>
  ): void => {
    setEditing(true);
    onFocus?.();
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
    setEditing(false);
    onBlur?.();
  };

  // Utility Methods
  const typeaheadSelectFilter = (
    option: Option,
    propsAndState: TypeaheadPropsAndState
  ): boolean => {
    if (showAllOptions) {
      return true;
    }
    const item = option as TSTypeaheadOption;
    const inputText = propsAndState.text.toLowerCase();
    const mainLabel = item.label.toLowerCase();
    const secondaryValue = item.secondaryValue?.toLowerCase();

    if (propsAndState.multiple && propsAndState.selected?.length) {
      const isItemSelected = propsAndState.selected.some(
        (selectedItem: any) => selectedItem.id === item.value
      );
      if (isItemSelected) {
        return false;
      }
    }

    return (
      mainLabel.includes(inputText) ||
      Boolean(secondaryValue?.includes(inputText))
    );
  };

  const typeaheadOptionFormatter = (option: Option): JSX.Element => {
    const item = option as TSTypeaheadOption;
    return (
      <>
        {item.label}
        {showId && (
          <small className="d-block text-muted">
            {item?.secondaryLabel || `ID: ${item.secondaryValue}`}
          </small>
        )}
      </>
    );
  };

  const onSearchHandler = async (query: string): Promise<void> => {
    if (asyncSearchHandler) {
      setIsAsyncLoading(true);
      const results = await asyncSearchHandler(query);
      setAsyncOptions(results);
      setIsAsyncLoading(false);
    }
  };

  return (
    <Form.Group className={clsx("mb-3", className)}>
      {label && (
        <Form.Label>
          {required && <span>＊</span>}
          {label}
        </Form.Label>
      )}
      {asyncSearchHandler ? (
        <AsyncTypeahead
          defaultSelected={defaultSelected}
          filterBy={typeaheadSelectFilter}
          flip
          id={id}
          disabled={readOnly}
          inputProps={{ readOnly }}
          isInvalid={isInvalid}
          labelKey={labelKey || "label"}
          minLength={0}
          multiple={multiple}
          delay={asyncSearchDelay || 500}
          searchText={asyncSearchText}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          open={multiple ? editing : undefined}
          options={asyncOptions}
          paginate={paginate}
          placeholder={placeholder}
          autoFocus={autoFocus}
          renderMenuItemChildren={typeaheadOptionFormatter}
          selected={selected}
          isLoading={isAsyncLoading}
          onSearch={onSearchHandler}
          useCache={false}
          renderToken={renderToken}
        />
      ) : (
        <Typeahead
          defaultSelected={defaultSelected}
          filterBy={typeaheadSelectFilter}
          flip
          id={id}
          disabled={readOnly}
          inputProps={{ readOnly }}
          isInvalid={isInvalid}
          labelKey={labelKey || "label"}
          multiple={multiple}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          open={multiple ? editing : undefined}
          options={options}
          paginate={paginate}
          placeholder={placeholder}
          autoFocus={autoFocus}
          renderMenuItemChildren={typeaheadOptionFormatter}
          selected={selected}
          renderToken={renderToken}
        />
      )}
      {errorText && isInvalid && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errorText}
        </Form.Control.Feedback>
      )}
      {helpText && !isInvalid && (
        <Form.Text id={`${id}HelpBlock`} className="text-muted">
          {helpText}
        </Form.Text>
      )}
      {children}
    </Form.Group>
  );
};

export interface TSTypeaheadOption {
  label: string;
  secondaryLabel?: string;
  value: string;
  secondaryValue?: string;
  disabled?: boolean;
}

export interface TSTypeaheadProps {
  className?: string;
  errorText?: string;
  helpText?: string;
  id: string;
  isInvalid?: boolean;
  label?: string;
  labelKey?: string;
  multiple?: boolean;
  onBlur?: Function;
  onChange?: (selected: Option[]) => void;
  onFocus?: Function;
  options: Option[];
  paginate?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  required?: boolean;
  selected?: Option[];
  showId?: boolean;
  defaultSelected?: Option[];
  showAllOptions?: boolean;
  asyncSearchHandler?: (query: string) => Promise<Option[]>;
  asyncSearchDelay?: number;
  asyncSearchText?: string;
  renderToken?: RenderToken | undefined;
  children?: ReactNode;
}
