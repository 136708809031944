import React, { FunctionComponent, useState } from "react";

import { t } from "@lingui/macro";
import { I18n, Trans } from "@lingui/react";
import clsx from "clsx";

import * as api from "~/api";
import { PhoneInput } from "~/components/EmployeeCheckInRequest/PhoneInput";
import { ValidatedPhoneNumber } from "~/components/EmployeeCheckInRequest/types";
import PhoneIcon from "~/images/phone-icon.svg";
import { Loading, MessageActionButton } from "~common";

interface PhoneNumberProps {
  className?: string;
  company: api.Company;
  employee: api.ExternalEmployee;
  requesting: boolean;
  error: boolean;
  onConfirm: (phone: string) => any;
  onDontHavePhone: () => any;
}

export const PhoneNumber: FunctionComponent<PhoneNumberProps> = ({
  className,
  company,
  employee,
  requesting,
  error,
  onConfirm,
  onDontHavePhone
}) => {
  const [phone, setPhone] = useState<ValidatedPhoneNumber>();
  const { name: companyName } = company;
  const { friendly_name: friendlyName } = employee;

  return (
    <div
      className={clsx(
        "h-full w-full flex-grow flex flex-col justify-between",
        className
      )}
    >
      <div className="p-9 text-2xl font-bold text-hs-dark-green">
        <p>
          <Trans id="register.phone.title" values={{ friendlyName }}>
            Hi {friendlyName},
          </Trans>
        </p>
        <p>
          <Trans id="register.phone.summary" values={{ companyName }}>
            Where should we text your regular health survey from {companyName}?
          </Trans>
        </p>
      </div>
      <div className="bg-hs-light p-9 rounded-t-4xl">
        <div className="flex flex-row items-center mb-2">
          <img className="mr-4" src={PhoneIcon} alt="Phone" />
          <p className="text-2xl font-semibold text-hs-dark-green">
            <Trans id="register.phone.label">Mobile number:</Trans>
          </p>
        </div>

        <I18n>
          {({ i18n }) => (
            <PhoneInput
              className={clsx(
                "my-3 py-2 px-5 border rounded w-full",
                !error && "border-hs-dark-gray focus:border-hs-green",
                error && "border-hs-error-red"
              )}
              inputStyle={{ fontSize: "18px" }}
              value={phone?.value ?? ""}
              placeholder={i18n._(
                t("register.phone.placeholder")`Text the survey to this number`
              )}
              onChange={(value, valid, international) => {
                setPhone({ value, valid, international });
              }}
              onEnter={() => phone?.valid && onConfirm(phone.international)}
            />
          )}
        </I18n>

        {error && (
          <p className="my-3 text-xs text-hs-error-red">
            <Trans id="register.error.alreadySet" values={{ friendlyName }}>
              {friendlyName}, it looks like we already have your info, so you
              are good to go!
            </Trans>
          </p>
        )}

        {requesting && <Loading />}

        <p className="my-3 text-xs text-hs-even-darker-gray">
          <Trans id="register.phone.agreement" values={{ companyName }}>
            By saving your number in TeamSense, you are subscribing to text
            messages from {companyName} powered by TeamSense. You can
            unsubscribe at any time by texting STOP, and text HELP for help.
            Message frequency varies. Message & data rates apply. Neither{" "}
            {companyName} nor TeamSense will share your mobile information with
            third parties/affiliates for marketing/promotional purposes.
          </Trans>
        </p>

        <div className="pt-5 pb-3">
          <MessageActionButton
            theme="dark-green"
            disabled={!phone?.valid}
            onClick={() => phone?.valid && onConfirm(phone.international)}
            text={<Trans id="register.phone.confirm">Confirm number</Trans>}
          />
          <div
            className="mt-6 text-center text-lg underline cursor-pointer text-hs-dark-green"
            onClick={onDontHavePhone}
          >
            <Trans id="register.phone.dontHavePhone">
              I don't have a smartphone
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
};
