import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useMemo,
  useState
} from "react";
import Skeleton from "react-loading-skeleton";

import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import { FilterHeading } from "~/components/FilterHeading/FilterHeading";
import { Label } from "~/components/Label/Label";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { displayPhoneNumber } from "~/lib/phoneNumber";
import { showDivisions } from "~/lib/showDivisions";
import { getLabelColorLightened, getLabelDisplay } from "~/lib/status";
import { Modal, ModalProps } from "~common";

const HEADINGS = ["Date", "Name", "Mobile", "Check-Ins"];

const formatTime = (iso: api.ISODateTime): string => {
  let formattedTime = "";
  try {
    formattedTime = DateTime.fromISO(iso).toLocaleString(
      DateTime.DATETIME_SHORT
    );
  } catch {
    formattedTime = DateTime.fromISO(iso).toLocaleString({
      ...DateTime.DATETIME_SHORT,
      locale: "en-US"
    });
  }
  return formattedTime;
};

interface GridProps {
  className?: string;
  children?: ReactNode;
}
const Grid: React.FunctionComponent<GridProps> = ({ children, className }) => (
  <div
    className={clsx(
      "grid grid-cols-16 rounded-lg border-solid border-gray-200",
      className
    )}
  >
    {children}
  </div>
);

interface RowProps {
  className?: string;
  textClassName?: string;
  showDivisions: boolean;
  record: Array<ReactNode>;
}
const COL_SPANS = [4, 4, 4, 4];
const COL_SPANS_WITH_DIVISION = [4, 3, 3, 3, 3];
const Row: FunctionComponent<RowProps> = props => {
  const { className, textClassName, showDivisions, record } = props;
  const colSpans = showDivisions ? COL_SPANS_WITH_DIVISION : COL_SPANS;
  return (
    <>
      {record.map((data, i) => (
        <div
          key={`${i}`}
          className={clsx(
            `col-span-${colSpans[i]}`,
            "flex items-center border-b border-gray-200",
            "px-6 leading-5 text-gray-500",
            className,
            typeof data === "string" && textClassName
          )}
        >
          {data}
        </div>
      ))}
    </>
  );
};

const SKELETON_RECORD = [
  <Skeleton key="skeleton-date" width={150} />,
  <Skeleton key="skeleton-name" width={150} />,
  <Skeleton key="skeleton-phone" width={150} />,
  <Skeleton key="skeleton-status" width={100} />
];

const SKELETON_RECORD_WITH_DIVISIONS = [
  <Skeleton key="skeleton-date" width={150} />,
  <Skeleton key="skeleton-division" width={150} />,
  <Skeleton key="skeleton-name" width={150} />,
  <Skeleton key="skeleton-phone" width={150} />,
  <Skeleton key="skeleton-status" width={100} />
];

const NUM_SKELETON_ROWS = 10;

interface VisitorLogModalProps extends ModalProps {
  loading: boolean;
  surveyStates?: api.SurveyStateAndEmployee[];
}

interface DivisionFilterState {
  allSelected: boolean;
  noneSelected: boolean;
  divisionsSelected: boolean[];
}

export const VisitorLogModal: FunctionComponent<VisitorLogModalProps> = ({
  onClose,
  surveyStates,
  loading
}) => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const shouldShowDivisions = showDivisions(session);
  const divisionNames = (session?.divisions ?? []).map(({ name }) => name);
  const divisionIds = (session?.divisions ?? []).map(({ id }) => id);
  const skeletonRecord = shouldShowDivisions
    ? SKELETON_RECORD_WITH_DIVISIONS
    : SKELETON_RECORD;
  const headings: (ReactNode | string)[] = [...HEADINGS];

  const allDivisionsSelectedState = {
    allSelected: true,
    noneSelected: true,
    divisionsSelected: (session?.divisions || []).map(() => true)
  };
  const noDivisionsSelectedState = {
    allSelected: false,
    noneSelected: false,
    divisionsSelected: (session?.divisions || []).map(() => false)
  };
  const [filterState, setFilterState] = useState<DivisionFilterState>(
    allDivisionsSelectedState
  );
  const areAllSelected: (state: DivisionFilterState) => boolean = ({
    noneSelected,
    divisionsSelected
  }) => noneSelected && !divisionsSelected.some(val => !val);
  const onFilterNoneChanged: (checked: boolean) => void = checked => {
    if (checked) {
      const allSelected = areAllSelected({
        ...filterState,
        noneSelected: true
      });
      setFilterState({
        ...filterState,
        noneSelected: true,
        allSelected
      });
    } else {
      setFilterState({
        ...filterState,
        noneSelected: false,
        allSelected: false
      });
    }
  };
  const onFilterDivisionChanged: (
    divisionIdIndex: number,
    checked: boolean
  ) => void = (divisionIdIndex, checked) => {
    if (!session?.divisions) {
      return;
    }
    const newDivisionsSelected = [...filterState.divisionsSelected];
    newDivisionsSelected[divisionIdIndex] = checked;
    const allSelected =
      checked &&
      areAllSelected({
        ...filterState,
        divisionsSelected: newDivisionsSelected
      });
    setFilterState({
      ...filterState,
      divisionsSelected: newDivisionsSelected,
      allSelected
    });
  };
  if (shouldShowDivisions) {
    headings.splice(
      1,
      0,
      <FilterHeading
        title="Division"
        titleClassName="uppercase"
        allSelected={filterState.allSelected}
        noneSelected={filterState.noneSelected}
        itemNames={divisionNames}
        itemSelections={filterState.divisionsSelected}
        displayNone={Boolean(session?.company?.code)}
        onSelectAll={checked =>
          checked
            ? setFilterState(allDivisionsSelectedState)
            : setFilterState(noDivisionsSelectedState)
        }
        onNone={onFilterNoneChanged}
        onClick={onFilterDivisionChanged}
      />
    );
  }

  const filteredSurveyStates = useMemo(
    () =>
      shouldShowDivisions
        ? surveyStates?.filter(surveyState => {
            const divisionId = surveyState.employee.division?.id;
            if (divisionId) {
              const divisionIndex = divisionIds.findIndex(
                id => id === divisionId
              );
              return filterState.divisionsSelected[divisionIndex];
            } else {
              return filterState.noneSelected;
            }
          })
        : surveyStates,
    [
      divisionIds,
      filterState.divisionsSelected,
      filterState.noneSelected,
      shouldShowDivisions,
      surveyStates
    ]
  );

  const records =
    filteredSurveyStates?.map(surveyState => {
      const { employee, created, outcome } = surveyState;
      const { employeeEvents: events } = outcome || { employeeEvents: [] };
      return [
        formatTime(created),
        ...(shouldShowDivisions ? [employee.division?.name] : []),
        employee.name,
        displayPhoneNumber(employee?.phone_number),
        <div key={`primary-label-${employee.id}`} className="space-y-1">
          {events.map(({ label }) => (
            <Label
              display={getLabelDisplay(label.name, session.labels, language)}
              color={getLabelColorLightened(label.name, session.labels)}
              key={label.name}
            />
          ))}
        </div>
      ];
    }) ?? [];

  return (
    <Modal
      className="max-w-7xl w-full m-3 bg-hs-light overflow-y-auto"
      onClose={onClose}
    >
      <div className="px-10 pt-10" style={{ minWidth: "500px" }}>
        <div className="text-3xl font-bold pb-8 text-hs-dark-green">
          Visitor Log
        </div>
        <Grid className="sticky top-30">
          <Row
            record={headings}
            showDivisions={shouldShowDivisions}
            className="py-3 bg-hs-light text-left text-xs leading-4 font-medium"
            textClassName="uppercase"
          />
        </Grid>
        <div className="h-64 overflow-y-scroll">
          <Grid>
            {loading &&
              [...Array(NUM_SKELETON_ROWS)].map((_, index) => (
                <Row
                  key={`row-skeleton-${index}`}
                  record={skeletonRecord}
                  showDivisions={shouldShowDivisions}
                  className={"py-1 h-14 bg-white text-sm"}
                />
              ))}
            {!loading && !records.length && (
              <div className="py-1 h-14 col-span-16 flex items-center border-b border-gray-200 px-6 leading-5 text-gray-500">
                No visitors
              </div>
            )}
            {!loading &&
              records.length > 0 &&
              records.map((row: any, index: number) => (
                <Row
                  key={`row-${index}`}
                  record={row}
                  showDivisions={shouldShowDivisions}
                  className={"py-3 bg-white text-base tracking-wide"}
                />
              ))}
          </Grid>
        </div>
        <div className="flex justify-end mt-10">
          {/* TODO: Extract button into a reusable component*/}
          <button
            className="text-base text-hs-green py-2 px-4 rounded focus:outline-none"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
