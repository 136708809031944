import React, { FunctionComponent } from "react";

import Pagination from "rc-pagination";

import "./TSPagination.scss";

export const TSPagination: FunctionComponent<TSPaginationProps> = ({
  currentPage,
  total,
  resultsPerPage,
  onChange
}) => {
  const localeInfo = {
    items_per_page: "/ page",
    jump_to: "Go to",
    jump_to_confirm: "confirm",
    page: "Page",
    prev_page: "Previous Page",
    next_page: "Next Page",
    prev_5: "Previous 5 Pages",
    next_5: "Next 5 Pages",
    prev_3: "Previous 3 Pages",
    next_3: "Next 3 Pages",
    page_size: "Page Size"
  };

  return (
    <Pagination
      total={total}
      defaultCurrent={1}
      defaultPageSize={resultsPerPage}
      locale={localeInfo}
      onChange={onChange}
      showLessItems={true}
      current={currentPage}
    />
  );
};

interface TSPaginationProps {
  total: number;
  currentPage: number;
  resultsPerPage: number;
  onChange: (page: number) => void;
}
