import React, { FC } from "react";

import ErrorAccessDenied from "~/images/error-access-denied-illustration.svg";

import "./Error.scss";

export const AccessDenied: FC = () => {
  return (
    <div className="ts error-page">
      <div className="px-4">
        <h2>Oops, you don’t have access to this page!</h2>
        <img
          className="illustration m-auto"
          src={ErrorAccessDenied}
          alt="Access Denied Illustration"
        />
        <h4>
          You do not have permissions to view this page. If this is a mistake,
          please contact your company’s TeamSense admin.
        </h4>
      </div>
    </div>
  );
};
