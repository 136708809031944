import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { toast, ToastOptions } from "react-toastify";

import { Trans } from "@lingui/react";

import * as api from "~/api";
import { PortalList, SurveyList, ToggleControl } from "~/components";
import CheckLogoIconDarkGreen from "~/images/check-logo-icon-dark-green.svg";
import FormsIconDark from "~/images/forms-icon-dark.svg";
import FormsIcon from "~/images/forms-icon.svg";
import PortalIconDark from "~/images/portal-icon-dark.svg";
import PortalIcon from "~/images/portal-icon.svg";
import { MutableSessionContext } from "~/lib/context";
import { SourceWrapper } from "~/ts-components/common/auth/SourceWrapper";
import { ErrorExpiredLink } from "~/ts-components/common/errors/ErrorExpiredLink";
import { Loading, GenericErrorText } from "~common";

interface ParamTypes {
  token: string;
}

export const EmployeePortal: FunctionComponent = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { session } = useContext(MutableSessionContext);
  const [surveyTemplates, setSurveyTemplates] =
    useState<api.SurveyTemplate[]>();
  const [portalLinks, setPortalLinks] = useState<api.PortalLink[]>();
  const [invalidSession, setInvalidSession] = useState<boolean>();
  const [requestingSurvey, setRequestingSurvey] = useState(false);
  const { token } = useParams<ParamTypes>();

  const formsPaneActive = match.path.startsWith("/menu");

  const setFormsPaneActive = (formsActive: boolean): void =>
    history.replace(`/${formsActive ? "menu" : "portal"}/${token}`);

  const toastGenericError = (options?: ToastOptions): void => {
    toast.error(<GenericErrorText />, options);
  };

  const onClickLink = (portalLink: api.PortalLink): void => {
    window.location.assign(portalLink.url);
  };

  const onRequestSurvey = async (
    template: api.SurveyTemplate
  ): Promise<void> => {
    setRequestingSurvey(true);

    let response: api.APIResponse<api.SurveyLink> | undefined;
    try {
      response = await api.createSurveyFromMenu(token, template.id);
    } catch {}

    setRequestingSurvey(false);

    if (!response?.ok) {
      toastGenericError();
      return;
    }

    history.push(response.data.url);
  };

  useEffect(() => {
    const requestSurveys = async (): Promise<void> => {
      if (portalLinks || formsPaneActive) {
        return;
      }
      if (!session.company) {
        setInvalidSession(true);
        return;
      }

      let response: api.APIResponse<api.PortalLink[]> | undefined;
      try {
        response = await api.retrievePortalLinks(session.company.id, { token });
      } catch {}

      if (response?.ok) {
        setPortalLinks(response.data);
      } else if (response?.status === 403) {
        setInvalidSession(true);
      } else {
        toastGenericError();
      }
    };
    requestSurveys();
  }, [session.company, token, portalLinks, formsPaneActive]);

  useEffect(() => {
    const requestSurveys = async (): Promise<void> => {
      if (surveyTemplates || !formsPaneActive) {
        return;
      }
      if (!session.company || !session.employee) {
        setInvalidSession(true);
        return;
      }

      let response: api.APIResponse<api.SurveyMenuResponse> | undefined;
      try {
        response = await api.retrieveSurveyTemplatesMenu(
          session.company.id,
          session.employee.id,
          { token }
        );
      } catch {}

      if (response?.ok) {
        setSurveyTemplates(response.data.data);
      } else if (response?.status === 403) {
        setInvalidSession(true);
      } else {
        toastGenericError();
      }
    };
    requestSurveys();
  }, [
    formsPaneActive,
    session.company,
    session.employee,
    surveyTemplates,
    token
  ]);

  if (invalidSession) {
    return <ErrorExpiredLink />;
  }

  return (
    <SourceWrapper>
      <div className="wk-min-h-screen h-screen w-screen flex flex-col bg-hs-light">
        <div className="flex-none flex flex-row h-16 border-b border-bg-hs-gray">
          <img
            className="px-5"
            src={CheckLogoIconDarkGreen}
            alt="TeamSense Logo"
          />

          <p className="my-auto text-lg font-semibold text-hs-dark-green">
            {session.company?.name}
          </p>
        </div>

        <ToggleControl
          on={formsPaneActive}
          onToggle={setFormsPaneActive}
          nameOn={<Trans id="survey.list.title">Forms</Trans>}
          imageSrcOn={FormsIcon}
          selectedImageSrcOn={FormsIconDark}
          nameOff={<Trans id="portal.list.title">Portal</Trans>}
          imageSrcOff={PortalIcon}
          selectedImageSrcOff={PortalIconDark}
        />
        {!formsPaneActive && !portalLinks && <Loading />}
        {!formsPaneActive && portalLinks && (
          <PortalList portalLinks={portalLinks} onClick={onClickLink} />
        )}
        {formsPaneActive && (!surveyTemplates || requestingSurvey) && (
          <Loading />
        )}
        {formsPaneActive && (
          <SurveyList templates={surveyTemplates} onClick={onRequestSurvey} />
        )}
      </div>
    </SourceWrapper>
  );
};
