import React, { FunctionComponent, MouseEvent, useState } from "react";

import clsx from "clsx";

export interface LabelProps {
  display: string;
  color: string;
  hoverColor?: string;
  onClick?: (e: MouseEvent) => void;
  className?: string;
  disabled?: boolean;
}

export const Label: FunctionComponent<LabelProps> = ({
  onClick,
  display,
  color,
  disabled,
  hoverColor,
  className
}) => {
  const [hovering, setHovering] = useState(false);
  return (
    <button
      className={clsx(
        "rounded-full overflow-hidden py-3-2 px-3 outline-none focus:outline-none text-base tracking-wide text-hs-dark-green",
        !onClick && "cursor-default",
        className
      )}
      style={{
        backgroundColor: hoverColor && hovering ? hoverColor : color
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={onClick}
      disabled={disabled || !onClick}
    >
      {display}
    </button>
  );
};
