import React, { FunctionComponent } from "react";

import "./AttendanceNoResults.scss";

export const AttendanceInsightsNoResults: FunctionComponent<AINRProps> = ({
  title,
  text
}) => {
  return (
    <div className="ainr-no-results">
      <i className="icon-help" />
      <h1>{title}</h1>
      {text ? (
        <p>{text}</p>
      ) : (
        <p>
          There is no data available for the currently applied parameters. Try
          changing your filters.
        </p>
      )}
    </div>
  );
};

interface AINRProps {
  title: string;
  text?: string;
}
