import React, { FunctionComponent, useContext, useRef } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";

import { DeleteEventModal } from "../status-calendar/DeleteEventModal";
import { StatusModal } from "../status-calendar/StatusModal";

import { AttendanceEventHeader } from "./AttendanceEventHeader";
import { AttendanceSurvey } from "./AttendanceSurvey";

import * as api from "~/api";
import {
  ISODateTime,
  AlteredEventSyncStatus,
  AttendanceEventHistory,
  Employee
} from "~/api";
import { MutableSessionContext } from "~/lib/context";
import {
  useLinguiLanguage,
  useLinguiLocale,
  useWindowDimensions
} from "~/lib/hooks";
import {
  deleteAttendanceStatusEvent,
  formatISODateToLongFormat,
  formatISODateToMonthDay,
  getLabelColorDarkened,
  getLabelColorLightened,
  getLabelDisplay,
  saveAttendanceStatusChange
} from "~/lib/status";
import { AttendanceStatus } from "~/ts-components/employees/attendance/AttendanceStatus";

export const AttendanceEvent: FunctionComponent<AttendanceEventProps> = ({
  attendanceEvent,
  employee,
  automaticSync,
  onEventStatusSync,
  updateEvents,
  showActions = false,
  surveyMode = false,
  withAnimation = false,
  children
}) => {
  const { event, isOpen } = attendanceEvent;
  const language = useLinguiLanguage();
  const { session } = useContext(MutableSessionContext);
  const contentRef = useRef<HTMLDivElement>(null);
  const { height, width } = useWindowDimensions();

  const getEventDateRange = (
    event: api.EmployeeEvent,
    employee: api.Employee
  ): string => {
    if (!event || !employee) {
      return "";
    }
    const started_date = formatISODateToMonthDay(
      event.started,
      employee.timezone,
      language
    );
    const ended_date = event.ended
      ? formatISODateToMonthDay(event.ended, employee.timezone, language)
      : undefined;

    let result = "";
    if (started_date) {
      result += started_date;
    }
    if (ended_date && ended_date !== started_date) {
      result += ` - ${ended_date}`;
    }
    return result;
  };

  // Header begins
  const text = getLabelDisplay(event?.label.name, session?.labels, language);
  const title = `${getEventDateRange(event, employee)} ${text} `;
  const pill = {
    text,
    background: getLabelColorLightened(event.label.name, session.labels),
    color: getLabelColorDarkened(event.label.name, session.labels)
  };
  const locale = useLinguiLocale();
  const created = formatISODateToLongFormat(
    event.created,
    employee.timezone,
    locale
  );
  // Header ends

  const currentDay = DateTime.local();
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const handleShow = (): void => setShowStatusModal(true);
  const handleExit = (): void => setShowStatusModal(false);
  const handleDeleteShow = (): void => {
    setShowStatusModal(false);
    setShowDeleteModal(true);
  };

  const onAttendanceStatusSave = async (
    startDate: ISODateTime,
    endDate: ISODateTime,
    status: api.AttendanceStatus,
    note?: string
  ): Promise<void> => {
    const statusIsSaved = await saveAttendanceStatusChange(
      employee,
      startDate,
      endDate,
      status,
      session.labels,
      language,
      note,
      event
    );

    if (statusIsSaved && updateEvents) {
      updateEvents(true);
      handleExit();
    }
  };

  const handleAttendanceStatusDelete = async (): Promise<void> => {
    if (!event) {
      return;
    }
    if ((await deleteAttendanceStatusEvent(employee, event)) && updateEvents) {
      updateEvents(true);
    }
  };

  React.useEffect(() => {
    // Check if contentRef.current is not null before accessing its properties
    const contentElement = contentRef?.current;
    if (contentElement) {
      if (isOpen) {
        contentElement.style.maxHeight = `${contentElement.scrollHeight}px`;
      } else {
        contentElement.style.maxHeight = "0";
      }
    }
  }, [isOpen, height, width]);

  return (
    <div>
      <AttendanceEventHeader
        title={title}
        created={created}
        pill={pill}
        handleEdit={handleShow}
        handleDelete={handleDeleteShow}
        showActions={showActions}
      />
      <div
        ref={contentRef}
        className={clsx({
          "transition-all duration-700 ease-in-out overflow-hidden max-h-0":
            withAnimation
        })}
      >
        {!surveyMode && (
          <AttendanceStatus
            attendanceEvent={attendanceEvent}
            employee={employee}
            automaticSync={automaticSync}
            onEventStatusSync={onEventStatusSync}
          />
        )}
        <div
          className={clsx(
            surveyMode && "p-3 pt-1 rounded-md w-90 border-1 mt-3"
          )}
        >
          {attendanceEvent.survey && (
            <AttendanceSurvey
              survey={attendanceEvent.survey}
              employee={employee}
              language={language}
            />
          )}
        </div>
        {children}
      </div>
      {!surveyMode && (
        <>
          {employee && (
            <StatusModal
              currentDay={currentDay}
              show={showStatusModal}
              showDeleteWarning={false}
              handleExit={handleExit}
              event={event}
              employee={employee}
              timezone={employee.timezone}
              handleDeleteWarning={handleDeleteShow}
              onSave={onAttendanceStatusSave}
            />
          )}

          {event && employee && (
            <DeleteEventModal
              show={showDeleteModal}
              event={event}
              employee={employee}
              handleHide={() => setShowDeleteModal(false)}
              handleDelete={handleAttendanceStatusDelete}
            />
          )}
        </>
      )}
    </div>
  );
};

interface AttendanceEventProps {
  attendanceEvent: AttendanceEventHistory;
  employee: Employee;
  automaticSync?: boolean;
  onEventStatusSync: (eventId: string, status: AlteredEventSyncStatus) => void;
  updateEvents?: (refresh?: boolean) => void;
  showActions?: boolean;
  surveyMode?: boolean;
  withAnimation?: boolean;
  children?: React.ReactNode;
}
