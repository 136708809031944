import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  createRef
} from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";

import { DateTime } from "luxon";
import * as yup from "yup";

import { getInitialEventDates, getUpdatedEventDates } from "./eventDates";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";
import Restricted from "~/ts-components/permissionProvider/Restricted";
import { TSButton, TSInput, SelectOptionsProps } from "~common";
import "./StatusModal.scss";

export const StatusModal: FunctionComponent<StatusModalInterface> = ({
  show,
  showDeleteWarning = true,
  handleExit,
  event,
  timezone,
  currentDay,
  handleDeleteWarning,
  onSave
}) => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const statusOptions = useMemo((): SelectOptionsProps[] => {
    const labelKeys = Object.keys(session.labels);
    const keys = labelKeys.filter(key => !api.isCovidHealthStatus(key));
    return keys.map(key => ({
      label: getLabelDisplay(key, session.labels, language),
      value: key
    }));
  }, [language, session.labels]);

  const initialStatus = useMemo(
    (): string =>
      (event?.label?.label_set === api.LabelSet.AttendanceStatus &&
        event?.label?.name) ||
      `${statusOptions[0].value}`,
    [event?.label?.label_set, event?.label?.name, statusOptions]
  );

  const defaultMaxDate = DateTime.local().plus({ years: 2 }).startOf("day");

  const VAL_MESSAGE = {
    Required: "This field is required",
    EndDateTooEarly: "Must be >= to Start Date",
    StartDateTooLate: "Schedule Date must be within range."
  };

  const [formData, setFormData] = useState({
    status: initialStatus ?? "",
    startDate: event?.started || currentDay.toISODate(),
    endDate: event?.ended || undefined,
    note: event?.note?.body || undefined
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const statusRef = createRef<HTMLSelectElement>();

  const disableSave =
    Boolean(Object.keys(formErrors).length !== 0) ||
    formData.endDate === undefined;

  const formSchema = yup.object({
    status: yup.string().required(VAL_MESSAGE.Required),
    startDate: yup
      .date()
      .required(VAL_MESSAGE.Required)
      .typeError(VAL_MESSAGE.Required),
    endDate: yup
      .date()
      .required(VAL_MESSAGE.Required)
      .typeError(VAL_MESSAGE.Required)
      .when({
        is: () => formData.startDate,
        then: yup
          .date()
          .typeError(VAL_MESSAGE.Required)
          .min(
            DateTime.fromISO(formData.startDate).toISODate(),
            VAL_MESSAGE.EndDateTooEarly
          )
      }),
    note: yup.string()
  });

  const validateInput = (
    targetInput: string,
    data: { [key: string]: any }
  ): void => {
    formSchema
      .validateAt(targetInput, data)
      .then(() => {
        // Splice out our error.
        const { [targetInput]: _, ...remainingFormErrors } = formErrors;
        setFormErrors(remainingFormErrors);
      })
      .catch((e: yup.ValidationError) => {
        if (e.errors) {
          setFormErrors({ ...formErrors, ...{ [targetInput]: e.errors[0] } });
        }
      });
  };

  const validateForm = useCallback(async (): Promise<boolean> => {
    let isFormValid = true;
    await formSchema
      .validate(formData, { abortEarly: false })
      .catch((e: yup.ValidationError) => {
        if (e.inner) {
          const errorObjects = e.inner.map(({ path, errors }) => ({
            [path ?? ""]: errors[0]
          }));
          const newFormErrors = {
            ...formErrors,
            ...Object.assign({}, ...errorObjects)
          };
          setFormErrors(newFormErrors);
        }
        isFormValid = false;
      });
    return isFormValid;
  }, [formData, formErrors, formSchema]);

  // Update any input's data
  const updateFormData = (id: string, value: string): void => {
    const modifiedFormData = {
      ...formData,
      [id]: value
    };
    setFormData(modifiedFormData);
    validateInput(id, modifiedFormData);
  };

  const editEventName =
    event && getLabelDisplay(event.label.name, session.labels, language);

  const { started: initialStartDate, ended: initialEndDate } = useMemo(
    () =>
      getInitialEventDates({
        currentDay,
        event,
        timezone
      }),
    [event, currentDay, timezone]
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    const { startDate, endDate, status, note } = formData;
    const isFormValid = await validateForm();
    if (endDate === undefined || startDate === undefined || !isFormValid) {
      return;
    }

    const { started, ended } = getUpdatedEventDates({
      startDate,
      endDate,
      timezone
    });

    if (!disableSave) {
      onSave(started, ended, status, note);
      handleExit();
    }
  }, [formData, handleExit, timezone, validateForm, onSave, disableSave]);

  const handleHideModal = (): void => {
    handleExit();
    // reset data to blank state
    setFormData({
      status: initialStatus,
      startDate: currentDay.toISODate(),
      endDate: undefined,
      note: undefined
    });
    setFormErrors({});
  };

  useEffect(() => {
    const { started, ended } = getInitialEventDates({
      event,
      timezone,
      currentDay
    });

    if (event) {
      setFormData({
        status: initialStatus,
        startDate: started,
        endDate: ended, // account for unended
        note: event.note?.body
      });
    } else {
      setFormData({
        status: initialStatus,
        startDate: started,
        endDate: ended,
        note: undefined
      });
    }
  }, [event, currentDay, initialStatus, timezone]);

  return (
    <Modal
      show={show}
      container={document.getElementById("ts-modal")}
      centered
      onHide={handleHideModal}
    >
      <Modal.Body>
        <div className="status-modal-header">
          <h1>
            {editEventName
              ? `Edit ${editEventName} Status`
              : "Add New Occurrence"}
          </h1>
        </div>
        <Row>
          <Col>
            <Form.Label htmlFor="status">
              <span>＊</span>Status
            </Form.Label>
            <Form.Select
              ref={statusRef}
              defaultValue={event?.label.name}
              id="status"
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                updateFormData(event.target.id, event.target.value);
              }}
              className="mb-3"
            >
              {statusOptions.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TSInput
              label="Start Date"
              required
              placeholder="Date"
              id="startDate"
              max={defaultMaxDate.toISODate()}
              type="date"
              defaultValue={initialStartDate}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateFormData(event.target.id, event.target.value);
              }}
              isInvalid={Boolean(formErrors.startDate)}
            />
          </Col>
          <Col md={6}>
            <TSInput
              label="End Date"
              required
              placeholder="Date"
              id="endDate"
              defaultValue={initialEndDate}
              max={defaultMaxDate.toISODate()}
              errorText={formErrors.endDate}
              type="date"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateFormData(event.target.id, event.target.value);
              }}
              isInvalid={Boolean(formErrors.endDate)}
            />
          </Col>
        </Row>
        <Restricted
          to={!event ? "EmployeeNotes.CREATE" : "EmployeeNotes.UPDATE"}
        >
          <Row>
            <Col className="employee-status-notes">
              <TSInput
                defaultValue={event?.note?.body}
                label="Notes"
                id="note"
                placeholder={"Add notes (optional)"}
                as="textarea"
                rows={3}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
                  updateFormData(event.target.id, event.target.value);
                }}
              />
            </Col>
          </Row>
        </Restricted>
      </Modal.Body>
      <Modal.Footer className="display-flex justify-content-between">
        <div>
          {event && showDeleteWarning && (
            <TSButton
              variant="danger"
              size="lg"
              onClick={handleDeleteWarning}
              className="employees-delete-btn"
            >
              <span className="d-none d-sm-inline">Delete</span>
              <i className="icon-trash" />
            </TSButton>
          )}
        </div>
        <div>
          <TSButton variant="link" size="lg" onClick={handleHideModal}>
            Cancel
          </TSButton>

          <TSButton size="lg" onClick={handleSubmit} disabled={disableSave}>
            Save
          </TSButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

type FormErrors = {
  [key: string]: string;
};

interface StatusModalInterface {
  event?: api.EmployeeEvent;
  timezone: string;
  currentDay: DateTime;
  onSave: (
    startDate: api.ISODateTime,
    endDate: api.ISODateTime,
    status: api.AttendanceStatus,
    note?: string
  ) => any;
  handleDeleteWarning: () => any;
  show: boolean;
  showDeleteWarning?: boolean;
  handleExit: () => any;
  employee: api.Employee;
}
