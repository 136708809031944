import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";

import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import {
  AttendanceResponse,
  endDate,
  FrameLength,
  emailToHref
} from "~/lib/attendanceInsights";
import { MutableSessionContext } from "~/lib/context/";
import { isAdminOrOwner } from "~/lib/employeeRoles";
import { useEffectOnce, useLinguiLanguage } from "~/lib/hooks";
import {
  getLabelColorDarkened,
  getLabelColorLightened,
  getLabelDisplay
} from "~/lib/status";
import { SubmitFeedback } from "~/ts-components/common/feedback/SubmitFeedback";
import {
  AbsenceGauge,
  AbsencesByDivision,
  AbsencesByStatus,
  AbsencesCompared,
  AbsencesHeatMap,
  AttendanceComingSoon,
  AttendanceInsightsFilterPanel,
  DateRangeFilterDropdown,
  EmployeesWithMostAbsences,
  PerfectAttendanceSummary,
  UnlockMoreInsights,
  InsightsPromotionalModal
} from "~attendance";
import { GenericErrorText, TSButton, TSTypeaheadOption } from "~common";

import "./AttendanceInsights.scss";

export const AttendanceInsights: FunctionComponent = () => {
  const { session } = useContext(MutableSessionContext);
  const { unknown, ...labelsWithoutUnknown } = session.labels;
  const language = useLinguiLanguage();
  const labelKeysWithoutUnknown = Object.keys(labelsWithoutUnknown);

  const statusRef = React.useRef<any>();
  const divisionRef = React.useRef<any>();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [allDivisions, setAllDivisions] = useState<TSTypeaheadOption[]>([]);

  const [allLabels, setAllLabels] = useState<TSTypeaheadOption[]>([]);
  const [statusesPayload, setStatusesPayload] = useState<TSTypeaheadOption[]>(
    []
  );
  const [divisionsPayload, setDivisionsPayload] = useState<TSTypeaheadOption[]>(
    []
  );
  const [statusQuery, setStatusQuery] = useState<string | undefined>(undefined);
  const [exclusiveStatusQuery, setExclusiveStatusQuery] = useState<
    string | undefined
  >(undefined);
  const [divisionsQuery, setDivisionsQuery] = useState<string | undefined>(
    undefined
  );
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [currentFrameLength, setCurrentFrameLength] =
    useState<FrameLength>("day");
  const [frameLengthText, setFrameLengthText] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<string | undefined>();

  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [perfectAttendanceData, setPerfectAttendanceData] = useState<
    api.AttendanceInsightsEmployeeResponse | undefined
  >(undefined);
  const [isPerfectAttendanceCountLoading, setIsPerfectAttendanceCountLoading] =
    useState(false);
  const [employeesWithMostAbsences, setEmployeesWithMostAbsences] = useState<
    api.InsightsEmployee[] | undefined
  >(undefined);
  const [
    isEmployeesWithMostAbsencesLoading,
    setIsEmployeesWithMostAbsencesLoading
  ] = useState(false);
  const [absencesByStatus, setAbsencesByStatus] = useState<
    api.Frame[] | undefined
  >();
  const [isAbsencesByStatusLoading, setIsAbsencesByStatusLoading] =
    useState(false);
  const [absenceGaugePercent, setAbsenceGaugePercent] = useState<
    number | undefined
  >();

  const [absencesByDivision, setAbsencesByDivision] =
    useState<api.DivisionMetric[]>();
  const [isAbsencesByDivisionLoading, setIsAbsencesByDivisionLoading] =
    useState(false);

  const [currentAbsencesCompared, setCurrentAbsencesCompared] = useState<
    api.TotalMetrics | undefined
  >();
  const [priorAbsencesCompared, setPriorAbsencesCompared] = useState<
    api.TotalMetrics | undefined
  >();

  const [showPromotionalModal, setShowPromotionalModal] =
    useState<boolean>(false);

  const role = session.employee?.role;
  const showAttendanceInsights = Boolean(
    session?.features?.enabled_flags?.includes("attendance_insights") &&
      isAdminOrOwner(role)
  );

  const shouldShowAbsencesByDivisionCTA: boolean =
    session.all_divisions_count <= 1;

  const closeSidePanel = (): void => {
    setIsFilterVisible(false);
  };

  const convertOptions = (options: any): TSTypeaheadOption[] => {
    return (options ?? []).map((option: { id: string; name: string }) => ({
      value: option.id,
      label: option.name
    }));
  };

  const handleClearFilters = (): void => {
    setStatusesPayload([]);
    setStatusQuery(undefined);
    setDivisionsPayload([]);
    setDivisionsQuery(undefined);
    statusRef.current?.clear();
    divisionRef.current?.clear();
    setActiveFilterCount(0);
    closeSidePanel();
  };

  const handleApplyFilters = (): void => {
    setStatusQuery(statusesPayload.map(s => s.value).toString());
    setDivisionsQuery(divisionsPayload.map(s => s.value).toString());
    setActiveFilterCount(statusesPayload.length + divisionsPayload.length);
    closeSidePanel();
  };
  const submitAnIdeaMailTo: string = emailToHref({
    emailTo: "ideas@teamsense.com",
    subject: "I have an idea!"
  });

  const unlockInsightsMailTo: string = emailToHref({
    emailTo: "success@teamsense.com",
    subject: "Let’s discuss Attendance Insights!",
    body: "Hi! I’d like to chat with you about upgrading my TeamSense account to get access to Attendance Insights."
  });

  useEffect(() => {
    setIsLoading(true);

    const getPerfectAttendance = async (): Promise<void> => {
      setIsPerfectAttendanceCountLoading(true);
      if (!session?.company?.id || !startDate) {
        return;
      }
      let response: AttendanceResponse;
      try {
        response = await api.retrieveAttendanceEmployeeInsights({
          start: startDate,
          end: endDate,
          frameLength: currentFrameLength,
          metricsLevel: "perfectAttendance",
          labelList: exclusiveStatusQuery,
          divisionList: divisionsQuery,
          labels: 1,
          limit: 1
        });
      } catch {}

      if (response?.ok) {
        const insightsResponse =
          response as api.APISuccessResponse<api.AttendanceInsightsEmployeeResponse>;
        setPerfectAttendanceData(insightsResponse.data);
        setIsPerfectAttendanceCountLoading(false);
      } else {
        toast.error(<GenericErrorText />);
      }
    };

    const getMostAbsences = async (): Promise<void> => {
      if (!session?.company?.id || !startDate) {
        return;
      }
      setIsEmployeesWithMostAbsencesLoading(true);
      let response: AttendanceResponse;
      try {
        response = await api.retrieveAttendanceEmployeeInsights({
          start: startDate,
          end: endDate,
          frameLength: currentFrameLength,
          metricsLevel: "mostAbsences",
          labelList: statusQuery,
          divisionList: divisionsQuery,
          labels: 1,
          limit: 10
        });
      } catch {}

      if (response?.ok) {
        const insightsResponse =
          response as api.APISuccessResponse<api.AttendanceInsightsEmployeeResponse>;
        setEmployeesWithMostAbsences(insightsResponse?.data?.results);
      } else {
        toast.error(<GenericErrorText />);
      }

      setIsEmployeesWithMostAbsencesLoading(false);
    };

    const getAbsencesByDivision = async (): Promise<void> => {
      if (!session?.company?.id || !startDate) {
        return;
      }
      setIsAbsencesByDivisionLoading(true);
      let response: AttendanceResponse;
      try {
        response = await api.retrieveAttendanceInsights({
          start: startDate,
          end: endDate,
          frameLength: currentFrameLength,
          metricsLevel: "division",
          labelList: statusQuery,
          divisionList: divisionsQuery,
          labels: 1
        });
      } catch {}
      if (response?.ok) {
        const insightsResponse =
          response as api.APISuccessResponse<api.AttendanceInsightsResponse>;
        setAbsencesByDivision(insightsResponse?.data?.divisionMetrics);
      } else {
        toast.error(<GenericErrorText />);
      }

      setIsAbsencesByDivisionLoading(false);
    };

    const getAbsencesByStatus = async (): Promise<void> => {
      if (!session?.company?.id || !startDate) {
        return;
      }
      setIsAbsencesByStatusLoading(true);
      let response: AttendanceResponse;
      try {
        response = await api.retrieveAttendanceInsights({
          start: startDate,
          end: endDate,
          frameLength: currentFrameLength,
          metricsLevel: "total",
          labelList: statusQuery,
          divisionList: divisionsQuery,
          labels: 1
        });
      } catch {}
      if (response?.ok) {
        const insightsResponse =
          response as api.APISuccessResponse<api.AttendanceInsightsResponse>;
        const fl = insightsResponse.data.totalMetrics?.frameLength;
        if (fl === "week" || fl === "month") {
          // create a bumper lane of sorts for the week lookup. only show us 12 weeks
          const timespan = insightsResponse.data.totalMetrics?.frames;
          timespan && timespan.length > 12 && timespan.shift(); // remove initial week if we got too much
        }
        setAbsencesByStatus(insightsResponse.data.totalMetrics?.frames);
        setAbsenceGaugePercent(
          insightsResponse.data.totalMetrics?.totals?.summary.absencesPercent
        );
        setCurrentAbsencesCompared(insightsResponse.data.totalMetrics);
      } else {
        toast.error(<GenericErrorText />);
      }

      setIsAbsencesByStatusLoading(false);
    };

    const getPriorAbsencesCompared = async (): Promise<void> => {
      if (!session?.company?.id || !startDate) {
        return;
      }
      let adjustedStartDate = "";
      let adjustedEndDate = "";

      switch (currentFrameLength) {
        case "day":
          adjustedStartDate = DateTime.fromISO(startDate)
            .minus({ days: 30 })
            .toISODate();
          adjustedEndDate = DateTime.fromISO(endDate)
            .minus({ days: 30 })
            .toISODate();
          break;
        case "week":
          adjustedStartDate = DateTime.fromISO(startDate)
            .minus({ weeks: 12 })
            .toISODate();
          adjustedEndDate = DateTime.fromISO(endDate)
            .minus({ weeks: 12 })
            .toISODate();
          break;
        case "month":
          adjustedStartDate = DateTime.fromISO(startDate)
            .minus({ months: 12 })
            .toISODate();
          adjustedEndDate = DateTime.fromISO(endDate)
            .minus({ months: 12 })
            .toISODate();
          break;
        default:
          return;
      }

      const response: AttendanceResponse = await api.retrieveAttendanceInsights(
        {
          start: adjustedStartDate,
          end: adjustedEndDate,
          frameLength: currentFrameLength,
          metricsLevel: "total",
          labelList: statusQuery,
          divisionList: divisionsQuery,
          labels: 1
        }
      );

      if (response?.ok) {
        const insightsResponse =
          response as api.APISuccessResponse<api.AttendanceInsightsResponse>;
        const fl = insightsResponse.data.totalMetrics?.frameLength;
        if (fl === "week" || fl === "month") {
          // create a bumper lane of sorts for the week lookup. only show us 12 weeks
          const timespan = insightsResponse.data.totalMetrics?.frames;
          timespan && timespan.length > 12 && timespan.shift(); // remove initial week if we got too much
        }

        setPriorAbsencesCompared(insightsResponse.data.totalMetrics);
      } else {
        toast.error(<GenericErrorText />);
      }
    };

    const thePromises = [getAbsencesByStatus(), getPriorAbsencesCompared()];

    // if insights are enabled
    if (showAttendanceInsights) {
      thePromises.push(getMostAbsences(), getPerfectAttendance());

      // check for only one division
      shouldShowAbsencesByDivisionCTA
        ? setAbsencesByDivision([])
        : thePromises.push(getAbsencesByDivision());
    }

    Promise.all(thePromises).then(() => {
      setIsDirty(false);
      setIsLoading(false);
    });
  }, [
    currentFrameLength,
    exclusiveStatusQuery,
    divisionsQuery,
    startDate,
    statusQuery,
    session?.company?.id,
    shouldShowAbsencesByDivisionCTA,
    showAttendanceInsights
  ]);

  useEffect(() => {
    setAllDivisions(convertOptions(session.divisions));
  }, [session.divisions]);

  useEffect(() => {
    const excludeThis = statusQuery?.split(",");
    if (excludeThis) {
      const includeThese = labelKeysWithoutUnknown
        .filter(label => !excludeThis.find(x => label === x))
        .toString();
      setExclusiveStatusQuery(includeThese);
    }
  }, [statusQuery, labelKeysWithoutUnknown]);

  useEffectOnce(() => {
    window.scrollTo(0, 0);

    const nonCovidLabels = labelKeysWithoutUnknown
      .map(label => ({
        value: label,
        label: getLabelDisplay(label, session.labels, language)
      }))
      .filter(label => label.value !== "sick" && label.value !== "clear");

    setAllLabels(nonCovidLabels);
  });

  const shouldShowAbsencesByDivision: boolean =
    session.is_company_admin || (session.divisions?.length || 0) > 1;

  function onDateRangeSelectionChange(
    value: string,
    selectedValue?: FrameLength,
    theStartDate?: DateTime,
    text?: string
  ): void {
    if (theStartDate && selectedValue && text) {
      setStartDate(theStartDate.toISODate());
      setCurrentFrameLength(selectedValue);
      setFrameLengthText(text);
    }
  }

  return (
    <div className="ts">
      <Container fluid className="standard-page">
        <Row>
          <Col>
            <div className="attendance-insights-header">
              <h1>Attendance Insights</h1>
              <div className="attendance-insights-filter-buttons">
                <DateRangeFilterDropdown
                  onDateRangeSelectionChange={onDateRangeSelectionChange}
                  defaultItemValue="lastMonth"
                  disabled={isLoading || !showAttendanceInsights}
                />
                <TSButton
                  onClick={() => setIsFilterVisible(true)}
                  endIcon="icon-filter"
                  variant="secondary"
                  disabled={!showAttendanceInsights}
                >
                  Filters ({activeFilterCount})
                </TSButton>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div
            className={clsx(
              "attendance-insights-small-charts",
              showAttendanceInsights && "triple"
            )}
          >
            <AbsencesCompared
              isLoading={isLoading}
              currentRange={currentAbsencesCompared}
              previousRange={priorAbsencesCompared}
              frameLength={currentFrameLength}
              frameLengthText={frameLengthText}
            />
            <AbsenceGauge
              absencePercent={absenceGaugePercent ?? 0}
              durationText={frameLengthText}
              isLoading={isPerfectAttendanceCountLoading}
            />
            {showAttendanceInsights && (
              <PerfectAttendanceSummary
                timespan={frameLengthText}
                isLoading={isPerfectAttendanceCountLoading}
                data={perfectAttendanceData}
                startDate={startDate}
                frameLength={currentFrameLength}
                labelList={exclusiveStatusQuery}
                divisionList={divisionsQuery}
                hideReport={!showAttendanceInsights}
              />
            )}
          </div>
        </Row>
        {showAttendanceInsights && (
          <>
            <Row>
              <Col>
                <AbsencesByStatus
                  isLoading={isAbsencesByStatusLoading}
                  data={absencesByStatus}
                  frameLength={currentFrameLength}
                />
              </Col>
            </Row>
            <Row>
              <div className="attendance-insights-grid">
                <AbsencesHeatMap
                  isLoading={isAbsencesByStatusLoading}
                  data={absencesByStatus}
                  frameLength={currentFrameLength}
                />
                {(shouldShowAbsencesByDivision ||
                  shouldShowAbsencesByDivisionCTA) && (
                  <AbsencesByDivision
                    isLoading={isAbsencesByDivisionLoading}
                    data={absencesByDivision}
                    frameLengthText={frameLengthText}
                    shouldShowCTA={shouldShowAbsencesByDivisionCTA}
                  />
                )}
                <EmployeesWithMostAbsences
                  isLoading={isEmployeesWithMostAbsencesLoading}
                  data={employeesWithMostAbsences}
                  frameLengthText={frameLengthText}
                />
              </div>
            </Row>
            <Row>
              <Col>
                <AttendanceComingSoon />
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
                <SubmitFeedback
                  mailTo={submitAnIdeaMailTo}
                  heading="Do you have questions or feedback on these reporting concepts?"
                  subHeading="Share your opinion with us!"
                />
              </Col>
            </Row>
          </>
        )}
        {!showAttendanceInsights && (
          <>
            <UnlockMoreInsights
              mailTo={unlockInsightsMailTo}
              onCTAClick={() => setShowPromotionalModal(true)}
            />
            <InsightsPromotionalModal
              show={showPromotionalModal}
              handleExit={() => setShowPromotionalModal(false)}
              mailTo={unlockInsightsMailTo}
            />
          </>
        )}
      </Container>

      <AttendanceInsightsFilterPanel
        isVisible={isFilterVisible}
        handleApplyFilters={handleApplyFilters}
        handleExit={closeSidePanel}
        handleClearFilters={handleClearFilters}
        enableApplyFilters={isDirty}
        isLoading={isLoading}
      >
        <div className="attendance-insights-filter-group">
          <label htmlFor="status">Statuses</label>
          <Typeahead
            id="status"
            options={allLabels}
            placeholder="Search Statuses"
            multiple
            ref={statusRef}
            onChange={items => {
              setStatusesPayload(items as TSTypeaheadOption[]);
              setIsDirty(true);
            }}
            renderToken={(item, props, index) => {
              const filteredSelection = item as TSTypeaheadOption;
              const backgroundColor = getLabelColorLightened(
                filteredSelection.value,
                session.labels
              );
              const color = getLabelColorDarkened(
                filteredSelection.value,
                session.labels
              );
              return (
                <Token
                  key={index}
                  disabled={props.disabled}
                  onRemove={props.onRemove}
                  option={item}
                  style={{ backgroundColor, color }}
                  className="colorized-token"
                >
                  {filteredSelection.label}
                </Token>
              );
            }}
          />
        </div>
        {allDivisions.length > 0 && (
          <div className="attendance-insights-filter-group">
            <label htmlFor="division">Divisions</label>
            <Typeahead
              id="division"
              options={allDivisions}
              placeholder="Search Divisions"
              multiple
              ref={divisionRef}
              onChange={items => {
                setDivisionsPayload(items as TSTypeaheadOption[]);
                setIsDirty(true);
              }}
            />
          </div>
        )}
      </AttendanceInsightsFilterPanel>
    </div>
  );
};
