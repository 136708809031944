import React, { useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";

import { ToastContext, TSButton } from "~common";

export const Toasts = (): JSX.Element => {
  const toast = useContext(ToastContext);

  const showDangerToast = (): void =>
    toast.show({ variant: "danger", message: "🤖 Danger Will Robinson!" });
  const showSuccessToast = (): void =>
    toast.show({
      variant: "success",
      message: "If I could turn back time..."
    });
  const showPinnedSuccessToast = (): void =>
    toast.show({
      variant: "success",
      message: "🪲 Beetlejuice Beetlejuice Beetlejuice 🧃",
      autohide: false,
      closeButton: true
    });
  const showDelaySuccessToast = (): void =>
    toast.show({
      variant: "success",
      message:
        "I don’t need a compass to tell me which way the wind shines! 🧭",
      delay: 8000,
      pauseOnHover: false
    });
  const showDefaultToast = (): void =>
    toast.show({
      message:
        "I'm sorry, Bruce. These boys get that syrup in 'em, they get all antsy in their pantsy."
    });

  return (
    <Card>
      <Card.Body>
        <h1>Toasts</h1>
        <Row>
          <Col>
            <div className="mb-3">
              <TSButton variant="primary" onClick={showSuccessToast}>
                🍻 Success
              </TSButton>
            </div>
            <div className="mb-3">
              <TSButton variant="primary" onClick={showPinnedSuccessToast}>
                🍻 Success - Pinned w/Close Icon
              </TSButton>
            </div>
            <div className="mb-3">
              <div>
                <TSButton variant="primary" onClick={showDelaySuccessToast}>
                  🍻 Success - Long delay
                </TSButton>
              </div>
            </div>
            <div className="mb-3">
              <TSButton variant="danger" onClick={showDangerToast}>
                🛑 Danger
              </TSButton>
            </div>
            <div>
              <TSButton variant="outline-primary" onClick={showDefaultToast}>
                Default
              </TSButton>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
