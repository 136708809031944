import React, { FunctionComponent } from "react";
import { Card } from "react-bootstrap";

export const AttendanceMiniCard: FunctionComponent<AttendanceMiniCardProps> = ({
  children
}) => {
  return (
    <Card className="attendance-insights-card p-4 d-flex overflow-hidden justify-content-center">
      {children}
    </Card>
  );
};

interface AttendanceMiniCardProps {
  children: JSX.Element;
}
