import { FormikErrors, FormikHelpers } from "formik";
import { transform as _transform } from "lodash";

import * as api from "~/api";

interface StrToStr {
  [key: string]: string;
}

/** Given a set of APIErrors, return a FormikErrors-compatible structure. */
const apiErrorsToFormikErrors = (errors: api.APIErrors): StrToStr =>
  _transform(errors, (a, v, k) => {
    if (v && k !== "non_field_errors") {
      a[k] = v[0];
    }
  });

/** Given a set of APIErrors, return a formik status-compatible structure. */
const apiErrorsToFormikStatusError = (errors: api.APIErrors): StrToStr =>
  errors.non_field_errors ? { error: errors.non_field_errors[0] } : {};

/** Given a formik form's actions and an APIErrorResponse, update errors and status. */
const updateFormForAPIErrorResponse = <T>(
  actions: FormikHelpers<T>,
  errorResponse: api.APIErrorResponse
): void => {
  actions.setErrors(
    apiErrorsToFormikErrors(errorResponse.errors) as FormikErrors<T>
  );
  actions.setStatus(apiErrorsToFormikStatusError(errorResponse.errors));
};

/** Given a formik form's actions and an APISuccessResponse, clear errors and status. */
const updateFormForAPISuccessResponse = <T>(
  actions: FormikHelpers<T>
): void => {
  actions.setErrors({});
  actions.setStatus({});
};

/** Given a form form's actions and an APIResponse, update its errors and status. */
export const updateFormForAPIResponse = <
  FormType,
  ResponseDataType extends object
>(
  actions: FormikHelpers<FormType>,
  response: api.APIResponse<ResponseDataType>
): void => {
  if (response.ok) {
    updateFormForAPISuccessResponse(actions);
  } else {
    updateFormForAPIErrorResponse(actions, response);
  }
};
