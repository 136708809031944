import React, { FunctionComponent, MouseEvent, ReactNode } from "react";

import clsx from "clsx";

import { Button } from "../button/Button";

export interface MessageActionButtonProps {
  className?: string;
  textSizeClassName?: string;
  text: string | ReactNode;
  disabled?: boolean;
  inline?: boolean;
  theme:
    | "error-red"
    | "red"
    | "clear-red"
    | "green"
    | "clear-green"
    | "clear-dark-green"
    | "dark-green"
    | "outline"
    | "white";
  onClick: (e: MouseEvent) => void;
}

// eslint-disable-next-line max-len
export const MessageActionButton: FunctionComponent<
  MessageActionButtonProps
> = props => {
  const {
    className,
    textSizeClassName,
    text,
    disabled,
    inline,
    theme,
    onClick
  } = props;
  return (
    <Button
      className={clsx(
        className,
        textSizeClassName ?? "text-xl",
        "leading-tight rounded-lg py-3 px-5",
        !inline && "w-full",
        theme === "green" && "border-0 bg-hs-green",
        theme === "red" && "border-0 bg-hs-red",
        theme === "clear-red" && "border-0 shadow-none bg-transparent",
        theme === "error-red" && "border-0 bg-hs-error-red",
        theme === "outline" && "border-0 bg-transparent",
        theme === "white" && "bg-hs-light border-hs-green",
        theme === "clear-green" && "border-0 shadow-none bg-transparent",
        theme === "clear-dark-green" && "border-0 shadow-none bg-transparent",
        theme === "dark-green" && "bg-hs-dark-green",
        !disabled && theme === "green" && "text-white",
        !disabled && theme === "red" && "text-white",
        !disabled && theme === "clear-red" && "text-hs-red",
        !disabled && theme === "error-red" && "text-white",
        !disabled && theme === "outline" && "text-white",
        !disabled && theme === "white" && "text-hs-green",
        !disabled && theme === "clear-green" && "text-hs-green",
        !disabled && theme === "clear-dark-green" && "text-hs-dark-green",
        !disabled && theme === "dark-green" && "text-hs-light",
        disabled && theme === "green" && "text-gray-300",
        disabled && theme === "red" && "text-gray-400",
        disabled && theme === "clear-red" && "text-gray-400",
        disabled && theme === "error-red" && "text-gray-400",
        disabled && theme === "outline" && "text-gray-500 border-gray-500",
        disabled && theme === "white" && "text-gray-400",
        disabled && theme === "clear-green" && "text-gray-400",
        disabled && theme === "clear-dark-green" && "text-gray-400",
        disabled && theme === "dark-green" && "text-hs-darker-gray"
      )}
      onClick={onClick}
      disabled={disabled ?? false}
    >
      {text}
    </Button>
  );
};
