import { Option } from "react-bootstrap-typeahead/types/types";

import * as api from "~/api";

export const employeeSearchHandler = async (
  session: api.Session,
  options: api.RetrieveEmployeeOptions = {},
  onlyTeamLeaders?: boolean,
  excludeEmployeeIds?: string[]
): Promise<Option[]> => {
  if (!session.company) {
    return [];
  }

  let response:
    | api.APIResponse<api.PaginatedResponse<api.IdentificationEmployee>>
    | undefined;
  try {
    response = await api.getCompanyEmployees(session.company.id, {
      ...options,
      teamLeadsOnly: onlyTeamLeaders,
      limit: api.MAX_TYPEAHEAD_LIMIT
    });
  } catch {}

  return response?.ok
    ? response.data.results
        .filter(
          employee =>
            !excludeEmployeeIds || !excludeEmployeeIds.includes(employee.id)
        )
        .map(employee => ({
          ...employee,
          label: employee.name,
          value: employee.id,
          secondaryValue: employee.external_id
        }))
    : [];
};
