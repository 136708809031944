import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";

import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import {
  getHeatmapColor,
  getHeatmapTextColor,
  roundPercentForInsights
} from "~/lib/attendanceInsights";
import { AttendancePopover } from "~/ts-components/attendance/AttendancePopover";

import "./HeatMapTile.scss";

export const HeatMapTile: FunctionComponent<HeatMapTileProps> = ({
  frame,
  type
}) => {
  const { name, summary, labels } = frame;
  const date = DateTime.fromISO(name);
  const rate = `${roundPercentForInsights(
    summary.absencesPercent
  )} Absence Rate`;

  const [target, setTarget] = useState<any>(null);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [shortTitle, setShortTitle] = useState("");
  const ref = useRef<any>();

  const handleEnter = (): void => {
    setShow(true);
    setTarget(ref);
  };

  const handleExit = (): void => {
    setShow(false);
  };

  useEffect(() => {
    try {
      if (type === "month") {
        setShortTitle(
          `${date.monthShort} \t’${date.toLocaleString({ year: "2-digit" })}`
        );
        setTitle(date.toLocaleString({ month: "long", year: "numeric" }));
      }

      if (type === "week") {
        const startDate = DateTime.fromISO(name).toLocaleString();
        const endDate = DateTime.fromISO(name)
          .plus({ days: 6 })
          .toLocaleString();
        setShortTitle(`${startDate} - ${endDate}`);
        setTitle(`${startDate} - ${endDate}`);
      }

      if (type === "day") {
        setShortTitle(DateTime.fromISO(name).day.toString());
        setTitle(date.toLocaleString(DateTime.DATE_FULL));
      }
    } catch (e) {
      if (type === "month") {
        setShortTitle(
          `${date.monthShort} \t’${date.toLocaleString({
            year: "2-digit",
            locale: "en-US"
          })}`
        );
        setTitle(
          date.toLocaleString({
            month: "long",
            year: "numeric",
            locale: "en-US"
          })
        );
      }

      if (type === "week") {
        const startDate = DateTime.fromISO(name).toLocaleString({
          locale: "en-US"
        });
        const endDate = DateTime.fromISO(name)
          .plus({ days: 6 })
          .toLocaleString({ locale: "en-US" });
        setShortTitle(`${startDate} - ${endDate}`);
        setTitle(`${startDate} - ${endDate}`);
      }

      if (type === "day") {
        setShortTitle(DateTime.fromISO(name).day.toString());
        setTitle(
          date.toLocaleString({ locale: "en-US", ...DateTime.DATE_FULL })
        );
      }
    }
  }, [name, type, date]);

  return (
    <div
      className={clsx(
        "heatmap-tile",
        `heatmap-tile--${type}`,
        `heatmap-tile--`
      )}
      onMouseEnter={handleEnter}
      onMouseLeave={handleExit}
      ref={ref}
      style={{
        backgroundColor: getHeatmapColor(summary?.absencesPercent),
        color: getHeatmapTextColor(summary?.absencesPercent)
      }}
    >
      <div>{shortTitle}</div>
      <Overlay
        show={show}
        target={target}
        placement="top"
        container={ref}
        containerPadding={0}
      >
        <Popover id="popover-container" className="attendance-popovers">
          <Popover.Body>
            <AttendancePopover
              absencesCount={summary.absencesCount}
              labels={labels}
              title={title}
              absenceRate={rate}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

interface HeatMapTileProps {
  frame: api.Frame;
  type: "month" | "week" | "day";
}
