import * as React from "react";

import Paper, { PaperProps } from "@mui/material/Paper";
import clsx from "clsx";

import { adaptProps } from "../mui-stories-utils";
import ThemeLayout from "../Theme/ThemeLayout";

const Card = (componentProps: PaperProps): React.ReactElement<PaperProps> => {
  const { children, className, props } = adaptProps(componentProps);
  return (
    <ThemeLayout>
      <Paper className={clsx("bg-inherit p-4", className)} {...props}>
        {children}
      </Paper>
    </ThemeLayout>
  );
};

export default Card;
