import React, { FunctionComponent } from "react";

import clsx from "clsx";

import "./AttendanceLegendItem.scss";

export const AttendanceLegendItem: FunctionComponent<
  AttendanceLegendItemProps
> = ({ color, text }) => {
  return (
    <div className="attendance-legend-item">
      <span
        style={{ backgroundColor: color }}
        className={clsx("legend-square")}
      ></span>
      {text}
    </div>
  );
};

interface AttendanceLegendItemProps {
  color: string;
  text: string;
}
