import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { Card, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast, ToastOptions } from "react-toastify";

import { Trans } from "@lingui/react";
import { DateTime } from "luxon";

import * as api from "~/api";
import CheckLogoIconDarkGreen from "~/images/check-logo-icon-dark-green.svg";
import { MutableSessionContext } from "~/lib/context";
import { ErrorExpiredLink } from "~/ts-components/common/errors/ErrorExpiredLink";
import { GenericErrorText, Loading } from "~common";

import "./EmployeeMessage.scss";

interface ParamTypes {
  token: string;
}

export const EmployeeMessage: FunctionComponent = () => {
  const { session } = useContext(MutableSessionContext);
  const [message, setMessage] = useState<api.EmployeeMessage>();
  const [invalidSession, setInvalidSession] = useState<boolean>();
  const { token } = useParams<ParamTypes>();

  const toastGenericError = (options?: ToastOptions): void => {
    toast.error(<GenericErrorText />, options);
  };

  useEffect(() => {
    const requestSurveys = async (): Promise<void> => {
      let response: api.APIResponse<api.EmployeeMessage> | undefined;
      try {
        response = await api.retrieveEmployeeMessage(token);
      } catch {}

      if (response?.ok) {
        setMessage(response.data);
      } else if (response?.status === 403 || response?.status === 401) {
        setInvalidSession(true);
      } else {
        toastGenericError();
      }
    };
    requestSurveys();
  }, [token]);

  if (invalidSession) {
    return <ErrorExpiredLink />;
  }

  let sentDate: string | undefined;
  try {
    sentDate =
      message &&
      DateTime.fromISO(message.due).toLocaleString(DateTime.DATETIME_FULL);
  } catch {
    sentDate =
      message &&
      DateTime.fromISO(message.due).toLocaleString({
        ...DateTime.DATETIME_FULL,
        locale: "en-US"
      });
  }

  return (
    <div className="ts">
      <div className="min-vh-100 vh-100 vw-100">
        <div className="d-flex py-3 border-bottom">
          <img
            className="px-4 ts-icon"
            src={CheckLogoIconDarkGreen}
            alt="TeamSense Logo"
          />

          <h2 className="my-auto">{session.company?.name}</h2>
        </div>

        <Container className="my-3">
          <h1>
            <Trans id="employeeMessage.title">Message</Trans>
          </h1>

          <Card className="employee-message-card">
            {!message && <Loading />}
            {message && (
              <>
                <p className="employee-message-text">
                  {message.context_json.sms_content}
                </p>
                <p className="employee-message-sent">
                  <Trans id="employeeMessage.sent" values={{ sentDate }}>
                    Sent: {sentDate}
                  </Trans>
                </p>
              </>
            )}
          </Card>
        </Container>
      </div>
    </div>
  );
};
