/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";

import { Trans } from "@lingui/react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton/IconButton";
import Paper from "@mui/material/Paper/Paper";
import Popover from "@mui/material/Popover/Popover";
import {
  Warning,
  WarningDiamond,
  XCircle,
  CloudX,
  Info as InfoIcon
} from "@phosphor-icons/react";
import clsx from "clsx";

import { useIsMobile } from "../hooks";
import ThemeLayout from "../Theme/ThemeLayout";
import Typography from "../Typography/Typography";

import { Threshold, ThresholdType } from "~/api/companies";

type DateStrObj = { day: string | JSX.Element; time: string };

const AttendancePointsBar = ({
  // Points
  attendancePointsUsed,
  totalAttendancePoints = 0,
  // Dates
  lastUpdatedDate = new Date(),
  // Thresholds
  thresholds,
  lightThreshold = 0,
  severeThreshold = 0,
  terminationThreshold = 0,
  proximityThreshold = 1,
  // Segments
  segmentsNumber,
  showAllSegmentNumbers = true,
  // Info
  infoDetailsText = "",
  infoForbiddenText = "",
  canSeeInfoDetails = false,
  // Date
  unsynced = false,
  hideDate = false,
  // Variants
  showVariants = true,
  unavailable = false
}: {
  attendancePointsUsed: number;
  totalAttendancePoints?: number;
  lastUpdatedDate?: Date | string;
  thresholds: Threshold[];
  lightThreshold?: number;
  severeThreshold?: number;
  terminationThreshold?: number;
  proximityThreshold?: number;
  segmentsNumber?: number;
  showAllSegmentNumbers?: boolean;
  infoDetailsText?: string;
  infoForbiddenText?: string;
  canSeeInfoDetails?: boolean;
  unsynced?: boolean;
  hideDate?: boolean;
  showVariants?: boolean;
  unavailable?: boolean;
}): React.ReactElement => {
  const thresholdProps = {
    negative: {
      barColor: "bg-ts-green-50",
      icon: <></>,
      chipText: "",
      chipColor: "primary"
    },
    safe: {
      barColor: "bg-ts-green-50",
      icon: <></>,
      chipText: "",
      chipColor: "primary"
    },
    light: {
      barColor: "bg-ts-yellow-50",
      icon: <Warning className="min-w-4 text-ts-yellow-50" />,
      chipText: "",
      chipColor: "warning"
    },
    severe: {
      barColor: "bg-ts-red-20",
      icon: <WarningDiamond className="min-w-4 text-ts-red-20" />,
      chipText: "",
      chipColor: "error"
    },
    termination: {
      barColor: "bg-ts-red-20",
      icon: <XCircle weight="fill" className="min-w-4 text-ts-red-20" />,
      chipText: "",
      chipColor: "error"
    },
    overload: {
      barColor: "bg-ts-red-20",
      icon: <></>,
      chipText: "",
      chipColor: "primary"
    }
  };

  const getDateStrObj = (
    date: Date | number | string | undefined
  ): DateStrObj => {
    if (!date) {
      return { day: "", time: "" };
    }
    if (typeof date === "number" || typeof date === "string") {
      date = new Date(date);
    }
    const currentDate = new Date();
    const diff =
      (currentDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
    let day;
    if (diff < 1 && currentDate.getDate() === date.getDate()) {
      day = <Trans id="attendancePointsBar.header.date.today">Today</Trans>;
    } else if (diff < 2 && currentDate.getDate() - date.getDate() === 1) {
      day = (
        <Trans id="attendancePointsBar.header.date.yesterday">Yesterday</Trans>
      );
    } else {
      const options: any = { day: "numeric", month: "short" };
      day = date.toLocaleDateString("en-US", options);
    }

    const options: any = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short"
    };
    const time = date.toLocaleString("en-US", options);
    return { day, time };
  };

  const getPercentage = (): number => {
    if (attendancePointsUsed === 0 || totalPoints === 0) {
      return 0;
    }
    let percentage = (attendancePointsUsed / totalPoints) * 100;
    percentage = Math.ceil(percentage);
    percentage = percentage > 100 ? 100 : percentage;
    percentage = percentage < 0 ? 0 : percentage;
    return percentage;
  };

  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [usagePercentage, setUsagePercentage] = useState<number>(
    getPercentage()
  );
  const [currentThreshold, setCurrentThreshold] = useState<any>(
    thresholdProps.safe
  );
  const [segments, setSegments] = useState<number[]>([]);
  const [approachingThreshold, setApproachingThreshold] = useState<
    Threshold | undefined
  >();
  const isMobile = useIsMobile();
  const [isShowingInfo, setIsShowingInfo] = useState<boolean>(false);
  const [dateData, setDateData] = useState<DateStrObj>(
    getDateStrObj(lastUpdatedDate)
  );
  const anchorInfo = useRef();

  const getCurrentThreshold = (): any => {
    switch (true) {
      case attendancePointsUsed < 0:
        return thresholdProps.negative;
      case attendancePointsUsed < lightThreshold:
        return thresholdProps.safe;
      case attendancePointsUsed < severeThreshold:
        return thresholdProps.light;
      case attendancePointsUsed < terminationThreshold:
        return thresholdProps.severe;
      case attendancePointsUsed === terminationThreshold:
        return thresholdProps.termination;
      default:
        return thresholdProps.overload;
    }
  };

  const getSubsegments = (): number[] => {
    if (totalPoints === 0) {
      return [0];
    }
    const roundOneDecimal = (num: number): number => Math.round(num * 10) / 10;
    if (segmentsNumber) {
      const step = totalPoints / segmentsNumber;

      return [
        0,
        ...Array.from({ length: segmentsNumber }, (_, i) =>
          roundOneDecimal(step * (i + 1))
        )
      ];
    }

    const greatestCommonDivisorOfList = (numbers: number[]): number => {
      const gcd = (a: number, b: number): number => {
        while (b !== 0) {
          const temp = b;
          b = a % b;
          a = temp;
        }
        return a;
      };
      if (numbers.length === 0) {
        return Infinity;
      }
      if (numbers.length === 1) {
        return numbers[0];
      }

      let result = numbers[0];
      for (let i = 1; i < numbers.length; i++) {
        result = gcd(result, numbers[i]);
      }
      return result;
    };

    const step = roundOneDecimal(
      greatestCommonDivisorOfList([
        ...thresholds.map(t => t.points),
        totalPoints
      ])
    );
    // the step is the size of the segements that subdivide the bar
    // to calculate the step we find what number divides all the thresholds and the totalPoints
    // so to guarantee that the bar is divided into segments that include all the thresholds
    // EG: if the thresholds are 5, 10, 15, 20, 25, 30 and the totalPoints is 30, the step is 5
    // EG: if the thresholds are 5, 7.5, and the totalPoints is 10, the step is 2.5

    if (step === Infinity || step === 0) {
      return [0];
    }

    const newSegmentsNumber = Math.floor(totalPoints / step);
    const segments = Array.from({ length: newSegmentsNumber }, (_, i) =>
      roundOneDecimal(step * (i + 1))
    );

    return [0, ...segments];
  };

  const calculateMissingValues = (): void => {
    setTotalPoints(
      totalAttendancePoints ||
        thresholds.find(t => t.type === "termination")?.points ||
        0
    );
    if (!lightThreshold) {
      lightThreshold = thresholds.find(t => t.type === "light")?.points || 0;
    }
    if (!severeThreshold) {
      severeThreshold = thresholds.find(t => t.type === "severe")?.points || 0;
    }
    if (!terminationThreshold) {
      terminationThreshold =
        thresholds.find(t => t.type === "termination")?.points || 0;
    }
  };

  useEffect(() => {
    calculateMissingValues();
    setUsagePercentage(getPercentage());
    setCurrentThreshold(getCurrentThreshold());
    const approachingThreshold = thresholds.find(
      (t: Threshold) =>
        attendancePointsUsed >= t.points - proximityThreshold &&
        attendancePointsUsed < t.points
    );
    setApproachingThreshold(approachingThreshold);
  }, [
    attendancePointsUsed,
    totalPoints,
    thresholds,
    lightThreshold,
    severeThreshold,
    terminationThreshold,
    proximityThreshold
  ]);

  useEffect(() => {
    setDateData(getDateStrObj(lastUpdatedDate));
  }, [lastUpdatedDate.toString()]);

  useEffect(() => {
    calculateMissingValues();
  }, []);

  useEffect(() => {
    calculateMissingValues();
    setSegments(getSubsegments());
  }, [segmentsNumber, totalPoints, thresholds]);

  const showSegmentNumber = (segment: number): boolean => {
    if (showAllSegmentNumbers) {
      return true;
    }
    if (segment === 0 || segment === totalPoints) {
      return true;
    }
    return thresholds.some((t: Threshold) => t.points === segment);
  };

  const thresholdSegmentsWidth = (): { middle: string; edges: string } => {
    const middleFactor = 1.25;
    let edgeFactor = 1;
    if (segments.length < 5) {
      edgeFactor = 1.2;
    } else if (segments.length < 10) {
      edgeFactor = 1;
    } else {
      edgeFactor = 0.8;
    }
    return {
      middle: `${Math.round((100 / (segments.length - 2)) * middleFactor)}%`,
      edges: `${Math.round((100 / segments.length) * edgeFactor)}%`
    };
  };

  const toggleInfo = (fromMobile: boolean, value?: boolean): void => {
    if ((fromMobile && isMobile) || (!fromMobile && !isMobile)) {
      setIsShowingInfo(value !== undefined ? value : !isShowingInfo);
    }
  };

  const ApproachingThresholdTrans = (): React.ReactElement => {
    if (!approachingThreshold?.name) {
      return <></>;
    }
    const thresholdName = approachingThreshold.name;
    return (
      <Trans
        id="attendancePointsBar.header.threshold.approaching"
        values={{ thresholdName }}
      >
        Approaching {thresholdName}
      </Trans>
    );
  };

  const DateTrans = (): React.ReactElement => {
    if (!dateData?.day || !dateData?.time) {
      return <></>;
    }
    const { day, time } = dateData;
    return (
      <Typography
        variant="h5"
        className={clsx(
          "flex text-ts-gray-40 !text-left",
          unsynced && "text-ts-red-20"
        )}
      >
        <Trans id="attendancePointsBar.header.date" values={{ day, time }}>
          as of {day} at {time}
        </Trans>
        {unsynced && (
          <>
            {" "}
            (
            <Trans id="attendancePointsBar.header.latestOccurrence">
              May not reflect latest occurrence
            </Trans>
            )
          </>
        )}
      </Typography>
    );
  };

  const InfoIconButton = (): React.ReactElement => (
    <IconButton
      className="h-6 w-6 p-0"
      onMouseEnter={() => toggleInfo(false, true)}
    >
      <Typography variant="h1" className="text-ts-teal-30">
        {isShowingInfo ? <XCircle weight="fill" /> : <InfoIcon />}
      </Typography>
    </IconButton>
  );

  const HeaderNoChip = (): React.ReactElement => (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row gap-2.5 min-h-12">
        <Typography
          className="flex self-center"
          variant="h1"
          fontSize="300% !important" // !I-BRB
        >
          {attendancePointsUsed >= 0 ? attendancePointsUsed : 0}
        </Typography>
        <div className={clsx("flex flex-col", hideDate && "self-end relative")}>
          <Typography
            variant={!hideDate ? "h3" : "h2"}
            className="flex text-ts-teal-20 !text-left"
          >
            <Trans
              id="attendancePointsBar.header.pointsUsed"
              values={{ totalPoints }}
            >
              of {totalPoints} Attendance Points used
            </Trans>
          </Typography>
          {!hideDate && <DateTrans />}
        </div>
      </div>
      <InfoIconButton />
    </div>
  );

  const HeaderChip = (): React.ReactElement => (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-2">
          <Typography className="flex self-center !text-5xl" variant="h1">
            {attendancePointsUsed >= 0 ? attendancePointsUsed : 0}
          </Typography>
          {approachingThreshold && (
            <Chip
              sx={{
                minHeight: "1.5rem",
                height: "auto",
                whiteSpace: "normal",
                textAlign: "left"
              }}
              label={<ApproachingThresholdTrans />}
              color={
                thresholdProps[approachingThreshold.type].chipColor as
                  | "primary"
                  | "warning"
                  | "error"
              }
            />
          )}
        </div>
        <InfoIconButton />
      </div>
      <div className="flex flex-row gap-2.5">
        <div className="flex flex-col">
          <Typography variant="h3" className="flex text-ts-teal-20 !text-left">
            <Trans
              id="attendancePointsBar.header.pointsUsed"
              values={{ totalPoints }}
            >
              of {totalPoints} Attendance Points used
            </Trans>
          </Typography>
          <DateTrans />
        </div>
      </div>
    </div>
  );

  const HeaderChipNoDate = (): React.ReactElement => (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row gap-2.5">
        <Typography
          className="flex self-center"
          variant="h1"
          fontSize="300% !important" // !I-BRB
        >
          {attendancePointsUsed >= 0 ? attendancePointsUsed : 0}
        </Typography>
        <div className="flex flex-col">
          <Typography variant="h2" className="flex text-ts-teal-20 !text-left">
            <Trans
              id="attendancePointsBar.header.pointsUsed"
              values={{ totalPoints }}
            >
              of {totalPoints} Attendance Points used
            </Trans>
          </Typography>
          {approachingThreshold && (
            <Chip
              sx={{
                minHeight: "1.5rem",
                height: "auto",
                whiteSpace: "normal",
                textAlign: "left"
              }}
              label={<ApproachingThresholdTrans />}
              color={
                thresholdProps[approachingThreshold.type].chipColor as
                  | "primary"
                  | "warning"
                  | "error"
              }
            />
          )}
        </div>
      </div>
      <InfoIconButton />
    </div>
  );

  const UnavailableHeader = (): React.ReactElement => (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row gap-2.5 min-h-12">
        <CloudX size={50} className="text-ts-gray-40" />
        <div className="flex flex-col">
          <Typography variant="h3" className="flex text-ts-gray-40 !text-left">
            <Trans id="attendancePointsBar.header.pointsUnavailable">
              Point balance unavailable
            </Trans>
          </Typography>
          <DateTrans />
        </div>
      </div>
      <InfoIconButton />
    </div>
  );

  const Header = (): React.ReactElement => {
    if (unavailable) {
      return <UnavailableHeader />;
    }
    if (approachingThreshold || !showVariants) {
      if (hideDate) {
        return <HeaderChipNoDate />;
      } else {
        return <HeaderChip />;
      }
    } else {
      return <HeaderNoChip />;
    }
  };

  const Info = ({
    thresholds,
    details,
    isPopOver = false
  }: {
    thresholds: Threshold[];
    details: string;
    isPopOver?: boolean;
  }): React.ReactElement => {
    const filterThresholds = (thresholds: Threshold[]): Threshold[] => {
      const filteredThresholds: Threshold[] = [];
      const thresholdTypes: ThresholdType[] = [
        "safe",
        "light",
        "severe",
        "termination"
      ];
      thresholdTypes.forEach(type => {
        const thresholdsByType = thresholds.filter(t => t.type === type);
        if (thresholdsByType.length >= 1) {
          filteredThresholds.push(
            thresholdsByType.sort(
              (a, b) =>
                parseFloat(a.notification_threshold) -
                parseFloat(b.notification_threshold)
            )[0]
          );
        }
      });
      return filteredThresholds;
    };
    return (
      <Paper
        className={clsx(
          isPopOver ? "w-96" : "w-full",
          "flex flex-col gap-2 !bg-ts-gray-90 p-3"
        )}
      >
        {thresholds.length > 0 && (
          <div>
            {filterThresholds(thresholds).map((threshold, index) => (
              <div
                key={index}
                className="flex flex-row items-center gap-3 py-0.5"
              >
                <Typography className="w-5">
                  {thresholdProps[threshold.type].icon}
                </Typography>
                <Typography
                  variant="h3"
                  className="w-14 !leading-none text-ts-gray-40"
                >
                  {threshold.pointsLimit
                    ? `${threshold.points}-${threshold.pointsLimit}`
                    : `${threshold.points}`}{" "}
                  <Trans id="attendancePointsBar.info.pointsAbbreviation">
                    PTS
                  </Trans>
                </Typography>
                <Typography
                  variant="h3"
                  noWrap
                  className="flex grow self-start leading-none text-ts-gray-40"
                >
                  {threshold.name}
                </Typography>
              </div>
            ))}
          </div>
        )}
        <Typography className="flex flex-col gap-y-0.5 text-left text-ts-teal-20">
          {canSeeInfoDetails
            ? details
                .split("\n")
                .map((line, index) => <div key={index}>{line}</div>)
            : infoForbiddenText}
        </Typography>
      </Paper>
    );
  };

  return (
    <ThemeLayout>
      <ButtonBase
        disableRipple
        onClick={() => toggleInfo(true)}
        className={clsx("w-full", !isMobile && "!cursor-default")}
      >
        <div className="relative flex w-full min-w-72 flex-col gap-3">
          <Box
            ref={anchorInfo}
            className="absolute right-1.5 top-1.5 h-3 w-3"
          />
          {/* Header */}
          <Header />

          {/* Bar */}
          <div className="relative h-7">
            <div
              className={clsx(
                "absolute z-[1] flex h-7 w-full flex-row rounded py-1",
                "!border !border-ts-gray-60", // !I-BUA
                "divide-x divide-ts-gray-60"
              )}
            >
              {segments.slice(1).map((_, index) => (
                <div key={index} className="flex grow" />
              ))}
            </div>
            <div className="absolute z-[-1] h-7 w-full rounded bg-ts-gray-90" />
            {!unavailable && (
              <div
                className={clsx(
                  "absolute h-7 rounded",
                  currentThreshold.barColor
                )}
                style={{
                  width: `${
                    usagePercentage >= 1
                      ? usagePercentage
                      : 1 + Number(isMobile)
                  }${usagePercentage < 100 ? ".1" : ""}%`
                }}
              />
            )}
          </div>

          {/* Thresholds */}
          <div className="flex flex-row justify-between">
            {segments.map((segment: number, index: number) => (
              <div
                key={index}
                className={clsx(
                  "flex flex-col-reverse sm:flex-row justify-end items-center gap-x-0.5",
                  showSegmentNumber(segment) && "min-w-fit",
                  index !== 0 &&
                    index !== segments.length - 1 &&
                    `sm:justify-center`,
                  index === 0 && `sm:justify-start`,
                  index === segments.length - 1 && `sm:justify-end`
                )}
                style={{
                  width:
                    index !== 0 && index !== segments.length - 1
                      ? thresholdSegmentsWidth().middle
                      : thresholdSegmentsWidth().edges
                }}
              >
                {showSegmentNumber(segment) && (
                  <>
                    {
                      thresholdProps[
                        thresholds.find((t: Threshold) => t.points === segment)
                          ?.type || "safe"
                      ].icon
                    }
                    <Typography className="text-ts-gray-50">
                      {segment}
                    </Typography>
                  </>
                )}
              </div>
            ))}
          </div>

          {/* Info */}
          {isMobile ? (
            <Collapse in={isShowingInfo} collapsedSize={0}>
              <Info
                thresholds={thresholds}
                details={infoDetailsText}
                isPopOver={false}
              />
            </Collapse>
          ) : (
            <Popover
              open={isShowingInfo}
              anchorEl={anchorInfo.current}
              onClose={() => toggleInfo(false, false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <Box onMouseLeave={() => toggleInfo(false, false)}>
                <Info
                  thresholds={thresholds}
                  details={infoDetailsText}
                  isPopOver={true}
                />
              </Box>
            </Popover>
          )}
        </div>
      </ButtonBase>
    </ThemeLayout>
  );
};

export default AttendancePointsBar;
