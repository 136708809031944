import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Col, Collapse, Form, InputGroup, Modal, Row } from "react-bootstrap";

import { isGSM7 } from "@loltech/sms-splitter/lib/encodings/gsm-7";
import { DateTime } from "luxon";
import simplur from "simplur";
import * as yup from "yup";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import {
  isCompanySelectedInRecipients,
  recipientIdentificationEmployeesFromRecipients,
  recipientIdsForKind,
  recipientIdentificationEmployeesForDirectReports
} from "~/lib/recipientsUtils";
import {
  CarrierDisallowed,
  GenericErrorText,
  Loading,
  SelectAudience,
  SelectAudienceOptions,
  ToastContext,
  TSButton,
  TSInput
} from "~common";

import "./CommunicateCreateEditModal.scss";

const MAX_MESSAGE_LENGTH = 160;
const MAX_MESSAGE_LENGTH_NON_GSM = 70;

export const CommunicateCreateEditModal: FunctionComponent<
  CommunicateCreateEditModalProps
> = ({
  handleExit,
  show,
  pending,
  handleDeleteConfirmation,
  handleMessageUpdate,
  messageToEdit,
  groups
}) => {
  const { session } = useContext(MutableSessionContext);
  const toast = useContext(ToastContext);
  const isCompanyAdmin = session?.is_company_admin || false;

  const localTimeZone = DateTime.now().toFormat("ZZZZ");

  const [saving, setSaving] = useState(false);

  const [showScheduleUX, setShowScheduleUX] = useState(
    Boolean(messageToEdit?.due)
  );

  const [maxMessageLength, setMaxMessageLength] = useState(MAX_MESSAGE_LENGTH);
  const [bodyCharCount, setBodyCharCount] = useState(
    messageToEdit?.context_json.email_body.length ?? 0
  );
  const [recipientsCount, setRecipientsCount] = useState<number>();
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [isAudienceValid, setIsAudienceValid] = useState(false);
  const [submitDirty, setSubmitDirty] = useState(false);

  const [isSendToBtnVisible, setIsSendToBtnVisible] = useState(false);
  const [isCountUpdatePending, setIsCountUpdatePending] = useState(true);
  const sendToBtnRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<{
    dialog: HTMLDivElement | null;
    backdrop: HTMLDivElement | null;
  }>(null);

  const [audience, setAudience] = useState<SelectAudienceOptions>(() => ({
    allCompany: isCompanySelectedInRecipients(messageToEdit?.recipients_json),
    divisions: recipientIdsForKind("div", messageToEdit?.recipients_json).map(
      value => ({
        value,
        label: session.divisions?.find(({ id }) => id === value)?.name ?? ""
      })
    ),
    groups: recipientIdsForKind("grp", messageToEdit?.recipients_json).map(
      value => ({
        value,
        label: groups.find(({ id }) => id === value)?.name ?? ""
      })
    ),
    directReports: recipientIdentificationEmployeesForDirectReports(
      messageToEdit?.recipients_json
    ).map(employee => ({
      ...employee,
      value: employee.id,
      label: employee.name
    })),
    employees: recipientIdentificationEmployeesFromRecipients(
      messageToEdit?.recipients_json
    ).map(employee => ({
      ...employee,
      value: employee.id,
      label: employee.name
    }))
  }));

  const [formData, setFormData] = useState({
    message: messageToEdit?.context_json.email_body ?? "",
    subject: messageToEdit?.context_json.email_subject ?? "",
    scheduleDate: messageToEdit?.due
      ? DateTime.fromISO(messageToEdit.due).toISODate()
      : null,
    scheduleTime: messageToEdit?.due
      ? DateTime.fromISO(messageToEdit.due).toFormat("HH:mm")
      : ""
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const messageRecipients = useMemo(() => {
    const result = [];
    if (audience.allCompany && session.company?.id) {
      result.push({ kind: "com", values: [{ id: session.company.id }] });
    }
    audience?.divisions?.forEach(({ value: id }) =>
      result.push({ kind: "div", values: [{ id }] })
    );
    audience?.groups?.forEach(({ value: id }) =>
      result.push({ kind: "grp", values: [{ id }] })
    );

    audience?.employees?.forEach(({ value: id }) =>
      result.push({ kind: "emp", values: [{ id }] })
    );
    audience?.directReports?.forEach(({ value: id }) =>
      result.push({ kind: "rpt", values: [{ id }] })
    );

    return result as api.MessageRecipient[];
  }, [
    audience.allCompany,
    audience?.divisions,
    audience?.employees,
    audience?.groups,
    audience?.directReports,
    session.company?.id
  ]);

  const defaultMaxDate = DateTime.local().plus({ years: 2 }).startOf("day");

  const defaultMinTime = DateTime.local();

  const fetchRecipientsCount = (): void | (() => void) => {
    const API_CALL_DELAY = 1000;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (messageRecipients?.length === 0) {
      setRecipientsCount(0);
      return;
    }
    let selectedRecipientsCountResponse: api.APIResponse<api.Count> | undefined;

    setRecipientsCount(undefined);
    const newTimeoutId = setTimeout(async () => {
      try {
        selectedRecipientsCountResponse = await api.countMessageRecipients(
          messageRecipients
        );
      } catch {}
      if (selectedRecipientsCountResponse?.ok) {
        setRecipientsCount(selectedRecipientsCountResponse.data.count);
      } else {
        toast.show({ variant: "danger", message: <GenericErrorText /> });
      }
    }, API_CALL_DELAY);
    setTimeoutId(newTimeoutId);
    return () => clearTimeout(newTimeoutId);
  };

  useEffect(() => {
    if (isSendToBtnVisible) {
      fetchRecipientsCount();
    } else {
      setIsCountUpdatePending(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageRecipients, toast]);

  useEffect(() => {
    if (isCountUpdatePending) {
      fetchRecipientsCount();
      setIsCountUpdatePending(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendToBtnVisible]);

  useEffect(() => {
    const checkVisibility = (): void => {
      if (sendToBtnRef.current) {
        const rect = sendToBtnRef.current.getBoundingClientRect();
        const isVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= window.innerHeight &&
          rect.right <= window.innerWidth;
        setIsSendToBtnVisible(isVisible);
      }
    };

    checkVisibility();

    const modalElement = modalRef.current?.dialog;
    modalElement?.addEventListener("scroll", () => checkVisibility());
    window.addEventListener("resize", () => checkVisibility());

    return () => {
      modalElement?.removeEventListener("scroll", () => checkVisibility());
      window.removeEventListener("resize", () => checkVisibility());
    };
  }, []);

  const notificationPreference = session?.employee?.notification_preference;
  const notificationPreferenceMap = {
    SL: "phone number",
    EL: "email address",
    BL: "phone number and email address"
  };

  const sendPreviewText = notificationPreference
    ? `The preview message will be sent to your ${notificationPreferenceMap[notificationPreference]} in TeamSense.`
    : "Please make sure you have a notification preference set in TeamSense. The message will be sent there.";

  const onInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    updateFormData(e.target.id, e.target.value);
  };

  const onMessageInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setMaxMessageLength(
      isGSM7(e.target.value) ? MAX_MESSAGE_LENGTH : MAX_MESSAGE_LENGTH_NON_GSM
    );
    setBodyCharCount(e.target.value.length);

    onInputChange(e);
  };

  const confirmPrefix = showScheduleUX ? "Schedule" : "Send to";
  const confirmSuffix =
    recipientsCount === undefined
      ? "..."
      : simplur`${recipientsCount} [Person|People]`;
  const confirmButtonTitle = pending
    ? "Close"
    : `${confirmPrefix} ${confirmSuffix}`;

  const VAL_MESSAGE = {
    Required: "This field is required",
    DateInThePast: "Schedule Date must not be in the past.",
    DateTooFarIntoFuture: "Schedule Date must be within range.",
    TimeFormat: "Make sure time is formatted correctly: e.g. 12:00 PM",
    TimeInThePast: "Schedule Time must not be in the past."
  };

  const formSchema = yup.object({
    subject: yup.string().required(VAL_MESSAGE.Required),
    message: yup.string().required(VAL_MESSAGE.Required).max(maxMessageLength),
    scheduleDate: yup
      .date()
      .nullable()
      .when({
        is: () => showScheduleUX,
        then: yup
          .date()
          .typeError(VAL_MESSAGE.Required)
          .min(DateTime.local().startOf("day"), VAL_MESSAGE.DateInThePast)
          .max(defaultMaxDate, VAL_MESSAGE.DateTooFarIntoFuture)
      }),
    scheduleTime: yup.string().when({
      is: () => showScheduleUX,
      then: yup
        .string()
        .matches(/[0-9]{2}:[0-9]{2}/, VAL_MESSAGE.TimeFormat)
        .min(1, VAL_MESSAGE.Required)
        .test("time", VAL_MESSAGE.TimeInThePast, () => {
          const timeValidation =
            formData.scheduleDate === DateTime.local().toISODate()
              ? DateTime.fromFormat(formData.scheduleTime, "HH:mm") >=
                defaultMinTime
              : true;
          return timeValidation;
        })
    })
  });

  const validateInput = (
    targetInput: string,
    data: { [key: string]: any }
  ): void => {
    formSchema
      .validateAt(targetInput, data)
      .then(() => {
        // Splice out our error.
        const { [targetInput]: _, ...remainingFormErrors } = formErrors;
        setFormErrors(remainingFormErrors);
      })
      .catch((e: yup.ValidationError) => {
        if (e.errors) {
          setFormErrors({ ...formErrors, ...{ [targetInput]: e.errors[0] } });
        }
      });
  };

  const validateForm = useCallback(async (): Promise<boolean> => {
    let isFormValid = true;
    await formSchema
      .validate(formData, { abortEarly: false })
      .catch((e: yup.ValidationError) => {
        if (e.inner) {
          const errorObjects = e.inner.map(({ path, errors }) => ({
            [path ?? ""]: errors[0]
          }));
          const newFormErrors = {
            ...formErrors,
            ...Object.assign({}, ...errorObjects)
          };
          setFormErrors(newFormErrors);
        }
        isFormValid = false;
      });
    return isFormValid;
  }, [formData, formErrors, formSchema]);

  // Update any input's data
  const updateFormData = (id: string, value: string): void => {
    const modifiedFormData = {
      ...formData,
      [id]: value
    };
    setFormData(modifiedFormData);
    validateInput(id, modifiedFormData);
  };

  const handleSendOrSchedule = useCallback(async (): Promise<void> => {
    if (pending) {
      handleExit();
      return;
    }
    setSubmitDirty(true);
    const { message, subject } = formData;
    const isFormValid = await validateForm();
    if (!isFormValid || !isAudienceValid) {
      return;
    }
    if (
      !session.company?.id ||
      !subject ||
      !message ||
      !messageRecipients?.length
    ) {
      return;
    }

    if (showScheduleUX && (!formData.scheduleDate || !formData.scheduleTime)) {
      return;
    }

    let dueDT;
    if (showScheduleUX) {
      const dateDT = DateTime.fromISO(formData.scheduleDate ?? "");
      const timeDT = DateTime.fromISO(formData.scheduleTime);
      dueDT = timeDT.set({
        year: dateDT.year,
        month: dateDT.month,
        day: dateDT.day
      });
    } else {
      dueDT = DateTime.local();
    }

    let response: api.APIResponse<api.ScheduledMessage> | undefined;

    try {
      setSaving(true);
      const scheduledMessage: api.ScheduledMessageWriter = {
        name: subject,
        due: dueDT.toISO(),
        context_json: {
          email_subject: subject,
          email_body: message,
          sms_content: message
        },
        recipients_json: messageRecipients
      };

      if (messageToEdit) {
        response = await api.updateScheduledMessage({
          id: messageToEdit.id,
          ...scheduledMessage
        });
      } else {
        response = await api.createScheduledMessage(scheduledMessage);
      }
    } catch (error) {}

    setSaving(false);
    if (response?.ok) {
      if (showScheduleUX) {
        toast.show({
          variant: "success",
          message: `Your message has ${messageToEdit ? "saved" : "scheduled"}.`
        });
      } else {
        toast.show({ variant: "success", message: "Your message has sent." });
      }
      handleMessageUpdate();
      handleExit();
    } else if (response?.status === 400) {
      toast.show({
        variant: "danger",
        message: (
          <CarrierDisallowed type={showScheduleUX ? "schedule" : "sendNow"} />
        )
      });
    } else {
      toast.show({ variant: "danger", message: <GenericErrorText /> });
    }
  }, [
    formData,
    handleExit,
    handleMessageUpdate,
    isAudienceValid,
    messageRecipients,
    messageToEdit,
    pending,
    session.company?.id,
    showScheduleUX,
    toast,
    validateForm
  ]);

  const handleSendTest = async (): Promise<void> => {
    let response: api.APIResponse<api.ScheduledMessage> | undefined;

    const { message, subject } = formData;
    if (!session?.company?.id || !subject || !message) {
      return;
    }

    try {
      const scheduledMessage: api.ScheduledMessageTest = {
        context_json: {
          email_subject: subject,
          email_body: message,
          sms_content: message
        }
      };
      response = await api.createTestMessage(scheduledMessage);
    } catch (error) {}

    if (response?.ok) {
      toast.show({
        variant: "success",
        message:
          "We are sending your preview message. This can take a few minutes."
      });
    } else if (response?.status === 400) {
      toast.show({
        variant: "error",
        message: <CarrierDisallowed />
      });
    } else {
      toast.show({
        variant: "error",
        message: <GenericErrorText />
      });
    }
  };

  const audienceChangeHandler = (
    audience: SelectAudienceOptions,
    isValid: boolean
  ): void => {
    setAudience(audience);
    setIsAudienceValid(isValid);
  };

  let modalTitle;
  if (messageToEdit) {
    modalTitle = pending ? "View pending message" : "Edit scheduled message";
  } else {
    modalTitle = "Create a New Message";
  }

  return (
    <>
      <Modal
        ref={modalRef}
        show={show}
        container={document.getElementById("ts-modal")}
        centered
        onHide={saving ? undefined : handleExit}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h2 className="h1">Select Recipients</h2>
              <SelectAudience
                audience={audience}
                showErrors={submitDirty && !isAudienceValid}
                audienceChangeHandler={audienceChangeHandler}
                checkboxPrepend="Send to"
                readOnly={pending}
                fullGroupList={groups}
                requiredMessage="Recipients are required"
                enableEmployees
                enableGroups
                enableDivisions
                enableAllCompany={isCompanyAdmin}
                disableApi={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="h1">Write Message</h2>
              <TSInput
                id="subject"
                readOnly={pending}
                defaultValue={formData.subject}
                onChange={onInputChange}
                errorText={formErrors.subject}
                isInvalid={Boolean(formErrors.subject)}
                label="Subject"
                required
                helpText="Subject will only be seen by employees with an email notification preference."
              />

              <Form.Group controlId="message" className="mb-3">
                <Form.Label className="d-flex justify-content-between">
                  <div>
                    <span>＊</span>Message
                  </div>
                  <div>
                    {bodyCharCount} / {maxMessageLength} characters
                  </div>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  placeholder="Enter text…"
                  readOnly={pending}
                  defaultValue={formData.message}
                  onBlur={onMessageInputChange}
                  onChange={onMessageInputChange}
                  isInvalid={Boolean(formErrors.message)}
                />
                <Form.Text
                  className={formErrors.message ? "text-danger" : "text-muted"}
                >
                  {maxMessageLength === MAX_MESSAGE_LENGTH
                    ? "SMS has a 160 character limit, be brief like you're writing a tweet."
                    : "👋 Emojis and special characters are awesome, but adding them reduces your character count to 70 due to the way carriers handle SMS."}
                </Form.Text>
              </Form.Group>

              <div className="my-4">
                <TSButton
                  size="sm"
                  className="me-2 mb-2"
                  variant="outline-primary"
                  disabled={
                    pending ||
                    formData.message.length > maxMessageLength ||
                    !formData.subject ||
                    !formData.message
                  }
                  onClick={handleSendTest}
                >
                  Send myself a preview
                </TSButton>
                <small className="text-muted form-text">
                  {sendPreviewText}
                </small>
              </div>
              {!messageToEdit && (
                <>
                  <Form.Check
                    type="checkbox"
                    label="Schedule Message"
                    id="schedule"
                    className="check-lg"
                    checked={showScheduleUX}
                    onChange={() => setShowScheduleUX(!showScheduleUX)}
                  />
                  <div>
                    <label htmlFor="schedule" className="helper-text">
                      Send this message at a later date and time
                    </label>
                  </div>
                </>
              )}
            </Col>
          </Row>
          <Collapse in={showScheduleUX}>
            <Row>
              <Col xs={12} md={6}>
                <TSInput
                  label="Schedule Date"
                  required
                  readOnly={pending}
                  placeholder="Date"
                  id="scheduleDate"
                  min={DateTime.local().toISODate()}
                  max={defaultMaxDate.toISODate()}
                  errorText={formErrors.scheduleDate}
                  type="date"
                  defaultValue={formData.scheduleDate ?? ""}
                  onChange={onInputChange}
                  isInvalid={Boolean(formErrors.scheduleDate)}
                />
              </Col>
              <Col xs={12} md={6}>
                <TSInput
                  id="scheduleTime"
                  type="time"
                  label="Schedule Time"
                  readOnly={pending}
                  defaultValue={
                    formData.scheduleDate === DateTime.local().toISODate()
                      ? defaultMinTime.toFormat("HH:mm")
                      : formData.scheduleTime
                  }
                  onChange={onInputChange}
                  required
                  isInvalid={Boolean(formErrors.scheduleTime)}
                  errorText={formErrors.scheduleTime}
                  appendContent={
                    <InputGroup.Text>{localTimeZone}</InputGroup.Text>
                  }
                />
              </Col>
            </Row>
          </Collapse>
        </Modal.Body>
        <Modal.Footer className="display-flex justify-content-between">
          {messageToEdit && !pending ? (
            <TSButton
              variant="danger"
              size="lg"
              endIcon="icon-trash"
              disabled={pending}
              onClick={handleDeleteConfirmation}
            >
              Discard
            </TSButton>
          ) : (
            <div />
          )}

          <TSButton onClick={handleSendOrSchedule}>
            <div ref={sendToBtnRef} />
            {confirmButtonTitle}
          </TSButton>
        </Modal.Footer>
        {saving && <Loading />}
      </Modal>
    </>
  );
};

type FormErrors = {
  [key: string]: string;
};

interface CommunicateCreateEditModalProps {
  show: boolean;
  messageToEdit?: api.ScheduledMessage | null;
  pending: boolean;
  groups: api.Group[];
  handleMessageUpdate: () => any;
  handleExit: () => any;
  handleDeleteConfirmation: () => any;
}
