import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";

export const TokenErrorText: FunctionComponent<{}> = () => (
  <Trans id="error.token">
    Oops, it looks like this link has expired. To verify your identity, please
    text us again for a new link.
  </Trans>
);
