import React, { FunctionComponent } from "react";
import { Button, Card } from "react-bootstrap";

import "./FeatureDisabled.scss";
import Error403Illustration from "~/images/error-403-forbidden-illustration.svg";

export const FeatureDisabled: FunctionComponent<FeatureDisabledInterface> = ({
  title,
  message,
  learnMoreLink,
  icon,
  contactText,
  contactLinkSubject
}) => {
  const btnText = contactText || "Contact Support to Upgrade";
  const disabledTitle = title || "You discovered a premium feature!";
  const contactBtnHref = `mailto:support@teamsense.com?subject=${
    contactLinkSubject
      ? contactLinkSubject
      : "I'd like to add a feature to my account!"
  }`;
  const learnMoreLinkHref = learnMoreLink
    ? learnMoreLink
    : "https://www.teamsense.com/solutions";

  return (
    <Card className="border-0 bg-transparent">
      <Card.Body className="premium-feature">
        <div>
          <h1>{disabledTitle}</h1>
          <img
            className="illustration"
            src={Error403Illustration}
            alt="403 Page Forbidden"
          />
        </div>
        <div className="inner-wrapper">
          {message ? (
            <p>{message}</p>
          ) : (
            <p>
              Contact us if you would like to upgrade your account to receive
              access to this feature.
            </p>
          )}
          <footer>
            <Button variant="link" size="lg" href={learnMoreLinkHref}>
              Learn more
            </Button>
            <Button href={contactBtnHref} size="lg">
              {btnText}
            </Button>
          </footer>
        </div>
      </Card.Body>
    </Card>
  );
};

interface FeatureDisabledInterface {
  contactLinkSubject?: string;
  contactText?: string;
  icon?: string;
  learnMoreLink?: string;
  message?: string;
  title?: string;
}
