import React, { FunctionComponent, useContext, useState } from "react";

import { DateTime, Interval } from "luxon";

import { CalendarShape } from "./CalendarShape";
import { LabelColorMapInterface } from "./StatusCalendar";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";
import "./CalendarDay.scss";

function getBackgroundColor(
  colorMap: LabelColorMapInterface[],
  theEvent: api.EmployeeEvent
): string {
  const defaultColor = "#99B6FF";
  if (!colorMap) {
    return defaultColor;
  }
  const colors = colorMap.filter(c => c.status === theEvent.label.name);
  if (colors && colors.length > 0 && colors[0]) {
    return colors[0].color;
  }
  return defaultColor;
}
const CalendarTooltip: FunctionComponent<{
  show: boolean;
  date: Date;
  events: api.EmployeeEvent[];
  colorMap: LabelColorMapInterface[];
}> = ({ show, date, events, colorMap }) => {
  const language = useLinguiLanguage();
  const { session } = useContext(MutableSessionContext);
  let displayDate = "";
  try {
    displayDate = DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED);
  } catch (e) {
    displayDate = DateTime.fromJSDate(date).toLocaleString({
      locale: "en-US",
      ...DateTime.DATE_MED
    });
  }

  return (
    <>
      {show && (
        <div className="calendar-tooltip">
          <h4 className="text-left text-nowrap mb-2">{displayDate}</h4>
          <div className="tooltip-legend">
            {events.map((theEvent, i) => (
              <div className="tooltip-legend-label" key={i}>
                <div
                  className="calendar-legend-label-color"
                  style={{
                    backgroundColor: getBackgroundColor(colorMap, theEvent)
                  }}
                ></div>
                {getLabelDisplay(theEvent.label.name, session.labels, language)}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export const CalendarDay: FunctionComponent<CalendarDayInterface> = ({
  date,
  colorMap,
  currentMonthDate,
  events
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const currentDay = DateTime.fromJSDate(date);
  const currentDayAtEightPM = currentDay.endOf("day").minus({ hours: 4 });

  const eventsToday = events?.filter(currentEvent => {
    const start = DateTime.fromISO(currentEvent.started);
    const end = currentEvent.ended
      ? DateTime.fromISO(currentEvent.ended)
      : DateTime.local().endOf("day");
    const dateRange = Interval.fromDateTimes(start, end);

    if (dateRange.contains(currentDayAtEightPM)) {
      return true;
    }
    return false;
  });

  const isFocusedMonth = date.getMonth() === currentMonthDate.getMonth();

  return (
    <>
      <div className="calendar-day-bg" />
      {eventsToday &&
        eventsToday.map((event, i) => (
          <CalendarShape
            key={i}
            singleEvent={event}
            currentDay={currentDay}
            colorMap={colorMap}
          />
        ))}
      <div
        className="current-day"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {currentDay.day}
      </div>
      {isFocusedMonth && eventsToday && eventsToday.length > 0 && (
        <CalendarTooltip
          show={isHovered}
          date={date}
          events={eventsToday}
          colorMap={colorMap}
        />
      )}
    </>
  );
};

interface CalendarDayInterface {
  date: Date;
  colorMap: LabelColorMapInterface[];
  currentMonthDate: Date;
  events?: api.EmployeeEvent[];
}
