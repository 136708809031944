import React, { FunctionComponent, ReactNode } from "react";

import clsx from "clsx";

interface ListGridProps {
  className?: string;
  children?: ReactNode;
}

export const ListGrid: FunctionComponent<ListGridProps> = ({
  children,
  className
}) => (
  <div
    className={clsx(
      "grid grid-cols-32 rounded-lg border-solid border-gray-200",
      className
    )}
  >
    {children}
  </div>
);
