import React, { FunctionComponent, ReactNode } from "react";

import clsx from "clsx";

// NOTE: Reference every col-span from 1-32 since tailwind build needs to find these
// and we programatically generate these below, which isn't enough to force them into the CSS

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
const allListRowColSpanClassNames = [
  "col-span-1",
  "col-span-2",
  "col-span-3",
  "col-span-4",
  "col-span-5",
  "col-span-6",
  "col-span-7",
  "col-span-8",
  "col-span-9",
  "col-span-10",
  "col-span-11",
  "col-span-12",
  "col-span-13",
  "col-span-14",
  "col-span-15",
  "col-span-16",
  "col-span-17",
  "col-span-18",
  "col-span-19",
  "col-span-20",
  "col-span-21",
  "col-span-22",
  "col-span-23",
  "col-span-24",
  "col-span-25",
  "col-span-26",
  "col-span-27",
  "col-span-28",
  "col-span-29",
  "col-span-30",
  "col-span-31",
  "col-span-32"
];
/* eslint-enable no-unused-vars */
/* eslint-enable @typescript-eslint/no-unused-vars */

interface ListRowProps {
  className?: string;

  /** Optional click handler for row */
  onClick?: (e: React.MouseEvent<HTMLDivElement>, index: number) => void;

  /** data */
  record: Array<ReactNode>;

  colSpans: Array<number>;

  showBorder?: boolean;
}

export const ListRow: FunctionComponent<ListRowProps> = props => {
  const { className, colSpans, onClick, record, showBorder = true } = props;

  return (
    <>
      {record.map((data, i) => (
        <div
          key={`${i}`}
          onClick={onClick ? e => onClick(e, i) : undefined}
          className={clsx(
            `col-span-${colSpans[i]}`,
            "flex items-center leading-5 text-gray-500",
            showBorder && "border-b border-gray-200",
            className
          )}
        >
          {data}
        </div>
      ))}
    </>
  );
};
