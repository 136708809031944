import React, { FunctionComponent, useContext } from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";
import { DateTime } from "luxon";

import { Bullet } from "~/components/Bullet/Bullet";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage, useLinguiLocale } from "~/lib/hooks";
import { getLabelColor, getLabelDisplay } from "~/lib/status";

interface TodaysDirectReportsSummaryProps {
  name: string;
  statusCounts: { [key: string]: number };
}

interface StatusContainerProps {
  className?: string;
  count: number;
  text: string;
  color: string;
}

const StatusContainer: FunctionComponent<StatusContainerProps> = ({
  className,
  count,
  text,
  color
}) => (
  <div
    className={clsx(
      className,
      "flex flex-row justify-between space-x-1 border-b border-hs-alt-gray"
    )}
  >
    <div className="flex flex-row items-center space-x-1">
      <Bullet color={color} />
      <div className="uppercase text-xxs text-hs-even-darker-gray flex flex-col justify-center">
        <p>{text}</p>
      </div>
    </div>
    <p className="flex my-auto text-base xxs:text-2-5xl text-hs-dark-green font-extrabold tracking-wide tabular-nums">
      {count}
    </p>
  </div>
);

export const TodaysDirectReportsSummary: FunctionComponent<
  TodaysDirectReportsSummaryProps
> = ({ name, statusCounts }) => {
  const { session } = useContext(MutableSessionContext);
  const locale = useLinguiLocale();
  const language = useLinguiLanguage();
  let currentDate = "";
  try {
    currentDate = DateTime.local()
      .setLocale(locale)
      .toLocaleString({ ...DateTime.DATE_FULL, year: undefined });
  } catch (e) {
    currentDate = DateTime.local()
      .setLocale("en-US")
      .toLocaleString({ ...DateTime.DATE_FULL, year: undefined });
  }

  const statusInfo = Object.keys(session.labels).map(key => ({
    labelKey: key,
    count: statusCounts[key] ?? 0,
    color: getLabelColor(key, session.labels)
  }));

  return (
    <div className="flex flex-col justify-center items-center py-5">
      <p
        className="text-center text-hs-medium-dark-gray tracking-wide mb-3 text-sm"
        dir="auto"
      >
        <Trans id="roster.title" values={{ name, currentDate }}>
          {name}'s Team &mdash; {currentDate}
        </Trans>
      </p>
      <div className="mx-2 grid grid-cols-2">
        {statusInfo.map((info, index) => (
          <StatusContainer
            key={`status-${index}`}
            className={clsx(
              "py-2",
              index % 2 ? "pl-2 xxs:pr-4" : "pr-2 xxs:pl-4"
            )}
            {...info}
            text={getLabelDisplay(info.labelKey, session.labels, language)}
          />
        ))}
      </div>
    </div>
  );
};
