import React, { useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";

import { TSButton } from "~common";

export const Buttons = (): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  return (
    <Card as="section">
      <Card.Body>
        <h1>Buttons</h1>
        <Row>
          <Col xs={12} xl={6} className="mb-3">
            <h2>Large</h2>
            <div className="mb-3">
              <TSButton variant="primary" size="lg">
                Default
              </TSButton>
              <TSButton variant="outline-primary" size="lg">
                Outline
              </TSButton>
              <TSButton variant="link" size="lg">
                Text
              </TSButton>
              <TSButton variant="danger" size="lg">
                Danger
              </TSButton>
            </div>
            <div className="mb-3">
              <TSButton variant="primary" size="lg" disabled>
                Default
              </TSButton>
              <TSButton variant="outline-primary" size="lg" disabled>
                Outline
              </TSButton>
              <TSButton variant="link" size="lg" disabled>
                Text
              </TSButton>
              <TSButton variant="danger" size="lg" disabled>
                Danger
              </TSButton>
            </div>
            <h2>Regular</h2>
            <div className="mb-3">
              <TSButton variant="primary">Default</TSButton>
              <TSButton variant="outline-primary">Outline</TSButton>
              <TSButton variant="link">Text</TSButton>
              <TSButton variant="danger">Danger</TSButton>
            </div>
            <div className="mb-3">
              <TSButton variant="primary" disabled>
                Default
              </TSButton>
              <TSButton variant="outline-primary" disabled>
                Outline
              </TSButton>
              <TSButton variant="link" disabled>
                Text
              </TSButton>
              <TSButton variant="danger" disabled>
                Danger
              </TSButton>
            </div>
            <h2>Small</h2>
            <div className="mb-3">
              <TSButton variant="primary" size="sm">
                Default
              </TSButton>
              <TSButton variant="outline-primary" size="sm">
                Outline
              </TSButton>
              <TSButton variant="link" size="sm">
                Text
              </TSButton>
              <TSButton variant="danger" size="sm">
                Danger
              </TSButton>
            </div>
            <div className="mb-3">
              <TSButton variant="primary" size="sm" disabled>
                Default
              </TSButton>
              <TSButton variant="outline-primary" size="sm" disabled>
                Outline
              </TSButton>
              <TSButton variant="link" size="sm" disabled>
                Text
              </TSButton>
              <TSButton variant="danger" size="sm" disabled>
                Danger
              </TSButton>
            </div>
          </Col>
          <Col xs={12} xl={6} className="mb-3">
            <h2>Icons</h2>
            <div className="mb-3">
              <TSButton variant="primary" startIcon="icon-caret-left" size="lg">
                Back
              </TSButton>
              <TSButton variant="primary" startIcon="icon-caret-left">
                Back
              </TSButton>
              <TSButton variant="primary" startIcon="icon-caret-left" size="sm">
                Back
              </TSButton>
            </div>
            <div className="mb-3">
              <TSButton
                variant="outline-primary"
                endIcon="icon-caret-right"
                size="lg"
              >
                Forward
              </TSButton>
              <TSButton variant="outline-primary" endIcon="icon-caret-right">
                Forward
              </TSButton>
              <TSButton
                variant="outline-primary"
                endIcon="icon-caret-right"
                size="sm"
              >
                Forward
              </TSButton>
            </div>
            <div className="mb-3">
              <TSButton variant="link" endIcon="icon-send" size="lg">
                Send
              </TSButton>
              <TSButton variant="link" endIcon="icon-send">
                Send
              </TSButton>
              <TSButton variant="link" endIcon="icon-send" size="sm">
                Send
              </TSButton>
            </div>
            <div className="mb-3">
              <TSButton variant="danger" endIcon="icon-trash" size="lg">
                Delete
              </TSButton>
              <TSButton variant="danger" endIcon="icon-trash">
                Delete
              </TSButton>
              <TSButton variant="danger" endIcon="icon-trash" size="sm">
                Delete
              </TSButton>
            </div>
            <h2>Loader</h2>
            <Form.Check
              className="check-lg mb-3"
              name="neverGonnaLg"
              type="switch"
              id="neverGonnaLg1"
              label="Toggle spinner"
              checked={showSpinner}
              onChange={() => setShowSpinner(!showSpinner)}
            />

            <div className="mb-3">
              <TSButton
                variant="primary"
                size="lg"
                onClick={() => setShowSpinner(!showSpinner)}
                showSpinner={showSpinner}
              >
                Button
              </TSButton>
              <TSButton
                variant="primary"
                onClick={() => setShowSpinner(!showSpinner)}
                showSpinner={showSpinner}
              >
                Button
              </TSButton>
              <TSButton
                variant="primary"
                size="sm"
                onClick={() => setShowSpinner(!showSpinner)}
                showSpinner={showSpinner}
              >
                Button
              </TSButton>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
