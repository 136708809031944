import React, { FunctionComponent } from "react";

import { useField } from "formik";

import { Field, ViewProps } from "~common";

/**
 * Wrapper for Field which adds the email type and validation function
 */
export const EmailField: FunctionComponent<ViewProps> = props => {
  const [field] = useField(props.name || "field");
  return (
    <Field {...props}>
      {renderProps => (
        <input {...field} {...renderProps} type="email" data-testid="input" />
      )}
    </Field>
  );
};
