import * as React from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import MuiButtonTheme from "../Button/ButtonTheme";
import MuiPaperTheme from "../Card/CardTheme";
import MuiTypographyTheme from "../Typography/TypographyTheme";

import colors from "./colors";

// Urbanist font should be installed from Google Fonts
// https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap

const theme = createTheme({
  palette: {
    primary: {
      main: colors.teal[40],
      contrastText: colors.white
    },
    secondary: {
      main: colors.teal[30],
      contrastText: colors.teal[30]
    },
    error: {
      main: colors.red[20],
      contrastText: colors.white
    },
    warning: {
      main: colors.yellow[50],
      contrastText: colors.white
    },
    success: {
      main: colors.green[50],
      contrastText: colors.white
    },
    info: {
      main: colors.blue[50],
      contrastText: colors.white
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      "Urbanist",
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      '"Noto Sans"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"'
    ].join(",")
  },
  components: {
    MuiTypography: MuiTypographyTheme,
    MuiPaper: MuiPaperTheme,
    MuiButton: MuiButtonTheme,
    MuiChip: {
      defaultProps: {
        size: "small"
      },
      styleOverrides: {
        root: {
          width: "fit-content",
          fontSize: "0.75rem",
          fontWeight: 700
        },
        colorWarning: {
          backgroundColor: colors.yellow[80],
          color: colors.yellow[20]
        },
        colorError: {
          backgroundColor: colors.red[80],
          color: colors.red[20]
        }
      }
    }
  }
});

const ThemeLayout = ({
  children
}: React.PropsWithChildren<{}>): React.ReactElement => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default ThemeLayout;

// TODO: Check if still needed:

// <CssBaseline />
