import React, { useContext, useEffect, useState } from "react";

import { MutableSessionContext } from "~/lib/context";

export const Pendo = (): JSX.Element => {
  const { session } = useContext(MutableSessionContext);
  const [pendoReady, setPendoReady] = useState(false);

  // Add pendo script to the dom
  useEffect(() => {
    const pendoApiKey = process.env.REACT_APP_PENDO_API_KEY;
    console.log(`${pendoApiKey ? "Pendo" : "No pendo"} api key found`);

    /* eslint-disable */
    if (!pendoReady && pendoApiKey) {
      (function(pendoApiKey){
        // @ts-ignore
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
          // @ts-ignore
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          // @ts-ignore
          y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+pendoApiKey+'/pendo.js';
          // @ts-ignore
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
        })(pendoApiKey);

        // trigger initialization with the pendoReady flag.
        setPendoReady(true);
        console.log("Set pendo ready");
      }
      /* eslint-enable */
  }, [pendoReady]);

  // When pendo is ready, and we have employee and company data, initialize.
  useEffect(() => {
    if (pendoReady && session?.employee && session?.company) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.pendo.initialize({
        visitor: {
          id: session.employee.id, // Required if user is logged in
          email: session.employee.notify_email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: session.employee.name, // Recommended if using Pendo Feedback
          role: session.employee.role
          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: session.company.id, // Highly recommended
          name: session.company.name // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        }
      });
      console.log("Pendo initialized");
    }
  }, [pendoReady, session]);

  return <></>;
};
