import React, { FC, ReactNode } from "react";
import { Card } from "react-bootstrap";

import "./SidebarInfoCard.scss";

export const SidebarInfoCard: FC<SidebarInfoCardProps> = props => {
  const { title, button, children } = props;

  return (
    <Card className="sidebar-info-card">
      <Card.Body>
        <div className="sidebar-info-card-header">
          <h1>{title}</h1>
          {button && <div className="sidebar-edit">{button}</div>}
        </div>
        {children}
      </Card.Body>
    </Card>
  );
};

export interface SidebarInfoCardProps {
  title?: string;
  button?: JSX.Element;
  children?: ReactNode;
}
