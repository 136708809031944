import React, { FunctionComponent } from "react";

import clsx from "clsx";
import simplur from "simplur";

import * as api from "~/api";
import { generateRecipientDisplayText } from "~/lib/recipientsUtils";

export interface RecipientListItem {
  kind: api.MessageRecipientKind;
  id: string;
  text: string;
}

interface ReceipientsFieldProps {
  recipients?: RecipientListItem[];
  recipientsCount?: number;
  onClick?: () => any;
}

export const RecipientsField: FunctionComponent<ReceipientsFieldProps> = ({
  recipients,
  recipientsCount,
  onClick
}) => {
  const recipientText = generateRecipientDisplayText(recipients);
  const recipientCountText =
    recipientsCount === undefined
      ? "Calculating recipients..."
      : simplur`${recipientsCount} recipient[|s] selected`;
  return (
    <>
      <div
        className={clsx(
          "w-full text-base text-hs-dark-green border py-2 px-5 border-hs-dark-gray cursor-pointer",
          recipientText ? "text-hs-dark-green" : "text-hs-medium-dark-gray"
        )}
        onClick={onClick}
      >
        {recipientText ? recipientText : "Select Recipients..."}
      </div>
      <div className="my-2 ml-1 text-sm text-hs-medium-dark-gray">
        {recipientCountText}
      </div>
    </>
  );
};
