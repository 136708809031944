import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
  useCallback
} from "react";
import { Container, Row, Col } from "react-bootstrap";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { areAllSelected, FilterList, updateFilterList } from "~/lib/filterList";
import { showDivisionsIfCompanyHasDivisions } from "~/lib/showDivisions";
import {
  FeatureDisabled,
  GenericErrorText,
  ToastContext,
  TSButton
} from "~common";
import {
  PortalDeleteLinkModal,
  PortalEditLinkModal,
  PortalLinkList
} from "~portal";

const allCompanyName = "All Company";

/** The top-level Portal pane view */
export const Portal: FunctionComponent = () => {
  const { session } = useContext(MutableSessionContext);
  const toast = useContext(ToastContext);

  const showDivisionColumn = showDivisionsIfCompanyHasDivisions(session);
  const [portalLinks, setPortalLinks] = useState<api.AdminPortalLink[]>();
  const [portalLinkToEdit, setPortalLinkToEdit] = useState<
    api.AdminPortalLink | undefined | null
  >();
  const [portalLinkToDelete, setPortalLinkToDelete] =
    useState<api.AdminPortalLink>();

  // To simplify using the existing components, we create a special
  // "All Companies" division with an id of "None" and prepend and the beginning
  // of a list of real divisions:
  const [filteredDivisions, setFilteredDivisions] = useState<
    FilterList<api.Division>
  >(
    updateFilterList(
      { displayEmpty: false, items: {} },
      [{ id: "None", name: allCompanyName }].concat(session?.divisions ?? [])
    )
  );

  const loadPortalLinks = useCallback(async (): Promise<void> => {
    if (!session.company) {
      return;
    }
    if (
      !Object.values(filteredDivisions.items).some(({ selected }) => selected)
    ) {
      // Filtered to empty, no need to call an API to show no results:
      setPortalLinks([]);
      return;
    }
    let filterDivisionIds: string[] | undefined;
    if (!areAllSelected(filteredDivisions, false)) {
      // One of the divisions (or the sentinel All-Company "division") is
      // unchecked, so populate the filter parameters:
      filterDivisionIds = Object.values(filteredDivisions.items)
        .filter(({ selected }) => selected)
        .map(({ item }) => item.id);
    }

    setPortalLinks(undefined);
    let response: api.APIResponse<api.AdminPortalLink[]> | undefined;
    try {
      response = await api.retrieveCompanyPortalLinks(
        session.company.id,
        filterDivisionIds
      );
    } catch {}

    if (response?.ok) {
      setPortalLinks(response.data);
    } else {
      toast.show({ variant: "danger", message: <GenericErrorText /> });
    }
  }, [filteredDivisions, session.company, toast]);

  useEffect(() => {
    loadPortalLinks();
  }, [loadPortalLinks]);

  const onConfirmChange = async (
    portalLink: api.PortalLink | api.AdminPortalLinkWriter
  ): Promise<void> => {
    if (!session.company) {
      return;
    }
    let response: api.APIResponse<{}> | undefined;

    try {
      if ("id" in portalLink) {
        response = await api.updateCompanyPortalLink(
          session.company.id,
          portalLink
        );
      } else {
        response = await api.createCompanyPortalLink(
          session.company.id,
          portalLink
        );
      }
    } catch {}

    if (response?.ok) {
      loadPortalLinks();
      toast.show({
        variant: "success",
        message:
          "id" in portalLink
            ? "The portal link was updated."
            : "A new portal link was created."
      });
    } else {
      toast.show({ variant: "danger", message: <GenericErrorText /> });
    }
  };

  const onDeleteLinkConfirmation = (portalLink: api.AdminPortalLink): void => {
    setPortalLinkToEdit(undefined);
    setPortalLinkToDelete(portalLink);
  };

  const onDelete = async (portalLink: api.AdminPortalLink): Promise<void> => {
    if (!session.company) {
      return;
    }
    let response: api.APIResponse<{}> | undefined;

    try {
      response = await api.deleteCompanyPortalLink(
        session.company.id,
        portalLink
      );
    } catch {}

    if (response?.ok) {
      loadPortalLinks();
      toast.show({
        variant: "success",
        message: "The portal link was deleted."
      });
    } else {
      toast.show({ variant: "danger", message: <GenericErrorText /> });
    }
  };

  return (
    <div className="ts">
      <Container fluid className="standard-page">
        {session?.features?.portal ? (
          <>
            <Row>
              <Col className="d-flex justify-content-between align-middle">
                <h1 className="mb-0">Portal</h1>
                <TSButton
                  variant="primary"
                  onClick={() => setPortalLinkToEdit(null)}
                >
                  Add Portal Link
                </TSButton>
              </Col>
            </Row>

            <Row>
              <Col>
                <PortalLinkList
                  portalLinks={portalLinks}
                  showDivisionColumn={showDivisionColumn}
                  divisionFilterList={filteredDivisions}
                  onChangeDivisionFilterList={setFilteredDivisions}
                  onClick={setPortalLinkToEdit}
                />
              </Col>

              {/* NOTE: portalLinkToEdit is set to null to invoke the modal in "add" mode */}
              {portalLinkToEdit !== undefined && (
                <PortalEditLinkModal
                  portalLink={portalLinkToEdit}
                  onConfirm={onConfirmChange}
                  onDeleteLink={onDeleteLinkConfirmation}
                  onClose={() => setPortalLinkToEdit(undefined)}
                />
              )}
              {portalLinkToDelete && (
                <PortalDeleteLinkModal
                  portalLink={portalLinkToDelete}
                  onDelete={onDelete}
                  onClose={() => setPortalLinkToDelete(undefined)}
                />
              )}
            </Row>
          </>
        ) : (
          <FeatureDisabled
            learnMoreLink="https://www.teamsense.com/solutions/ess-portal"
            message="Provide your team instant access to company information with a text-based employee portal."
            contactLinkSubject="I'd like to add the Portal feature to my account!"
          />
        )}
      </Container>
    </div>
  );
};
