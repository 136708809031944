import React, { FunctionComponent } from "react";

import { useMsal } from "@azure/msal-react";

import { useEffectOnce } from "~/lib/hooks";

/**
 * Logs out from msal upon mount
 */
export const AzureLogout: FunctionComponent = () => {
  const msal = useMsal();
  useEffectOnce(() => {
    msal.instance.logoutRedirect();
  });

  // TODO: Redirect? Or perhaps remove this component/route completely?
  return <div>{msal.accounts.length ? "Authed" : "Not Authed"}</div>;
};
