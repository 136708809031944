import React, {
  useState,
  FC,
  useLayoutEffect,
  ComponentType,
  ReactNode,
  useEffect,
  useRef
} from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
  useHistory
} from "react-router-dom";

import clsx from "clsx";

import { TSButton } from "../ts-buttons/TSButton";

import { SidebarNav, SidebarNavItemProps } from "./SidebarNav";

import "./SidebarLayout.scss";

// Bootstrap `sm` screen width
const MOBILE_WIDTH = 768;

export const SidebarLayout: FC<SidebarLayoutProps> = props => {
  const {
    pages,
    defaultUrl,
    cardContent,
    parentUrl,
    detailsLinkText,
    sidebarLinkText,
    mixedUsage
  } = props;

  const location = useLocation();
  const history = useHistory();
  const isFirstLoad = useRef(true);

  const [isMobile, setIsMobile] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [bgNoScroll, setBgNoScroll] = useState(false);
  const [navRoutes, setNavRoutes] = useState<JSX.Element | undefined>();

  // Find a menu item...
  // that matches the current location.
  const activeRouteItem = pages.find(page => page.href === location.pathname);

  // that matches our default route.
  const defaultRouteItem = pages.find(
    page => page.href === defaultUrl || page.eventKey === defaultUrl
  );
  // Then, figure out the active nav key.
  const activeNavKey =
    activeRouteItem?.href ||
    activeRouteItem?.eventKey ||
    defaultRouteItem?.href;

  // Event Handlers

  const onBackClick = (): void => {
    mixedUsage ? history.push("/dashboard/employees") : history.push(parentUrl);
  };

  const onToggleClick = (): void => setShowNav(true);

  const onNavItemClick = (navKey: string): void => {
    // Hide the nav if we're on mobile.
    isMobile && setShowNav(false);
  };

  // Hooks

  // Define the child routes that will be used for our navigation items.
  // Note: We add a catch all child route that handles non-matches when a defaultRouteItem is set.
  useEffect(() => {
    if (!navRoutes && pages.length) {
      const theRoutes = (
        <Switch>
          {pages.map((item, index) => {
            return (
              <Route key={index} path={item.href} render={item.render} exact />
            );
          })}
          {defaultRouteItem && (
            <Route path="/" render={defaultRouteItem.render}></Route>
          )}
        </Switch>
      );

      !navRoutes && theRoutes && setNavRoutes(theRoutes);
    }
  }, [defaultRouteItem, navRoutes, pages]);

  useEffect(() => {
    // If this is our first load and we're on mobile, show the nav.
    if (isFirstLoad.current && isMobile) {
      setShowNav(true);
      isFirstLoad.current = false;
      return;
    }

    if (isMobile) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  }, [isMobile]);

  useEffect(() => {
    // Don't scroll if we're on mobile and the nav is open
    setBgNoScroll(isMobile && showNav);
  }, [isMobile, showNav]);

  // TODO: Look into libs to leverage or break into it's own hook for reuse.
  useLayoutEffect(() => {
    // Determine if we're mobile by checking out width.
    const updateMobileByWidth = (): void => {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };

    // Hookup to resize window event to track when we hit a mobile width and execute at least once on load.
    window.addEventListener("resize", updateMobileByWidth);
    updateMobileByWidth();

    return () => window.removeEventListener("resize", updateMobileByWidth);
  }, []);

  return (
    <div className="ts">
      <div className="sidebar-layout">
        <div className={clsx("main-content", bgNoScroll && "bg-no-scroll")}>
          {isMobile && (
            <div className="details-link-header">
              <TSButton
                variant="link"
                startIcon="icon-caret-left"
                className={clsx("sidebar-toggle", isMobile && "is-mobile")}
                onClick={onToggleClick}
              >
                {detailsLinkText || "Details"}
              </TSButton>
            </div>
          )}
          {navRoutes}
        </div>
        <div className={clsx("sidebar", showNav && "show-nav")}>
          <div className="sidebar-content">
            <div className={clsx("sidebar-header", !isMobile && "is-desktop")}>
              <TSButton
                variant="link"
                startIcon="icon-caret-left"
                className="sidebar-parent-link"
                onClick={onBackClick}
              >
                {sidebarLinkText}
              </TSButton>
            </div>
            {cardContent}

            <SidebarNav
              links={pages}
              activeKey={activeNavKey}
              onNavClick={onNavItemClick}
            ></SidebarNav>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface SidebarLayoutItem extends SidebarNavItemProps {
  component?: ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
}

export interface SidebarLayoutProps {
  pages: SidebarLayoutItem[];
  defaultUrl: string;
  cardContent: ReactNode;
  parentUrl: string;
  detailsLinkText?: string;
  sidebarLinkText: string;
  mixedUsage?: boolean;
}
