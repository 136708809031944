import React, { FunctionComponent } from "react";

import { useMsal } from "@azure/msal-react";

import { SessionWrapper } from "./SessionWrapper";
import { WaitForMsal } from "./WaitForMsal";

/**
 * Determine session state, then render children.
 */
export const AuthWrapper: FunctionComponent<{
  children: React.ReactNode;
  conversationToken?: string;
  employeeSessionToken?: string;
}> = ({ children, conversationToken, employeeSessionToken }) => (
  <WaitForMsal msal={useMsal()}>
    <SessionWrapper
      conversationToken={conversationToken}
      employeeSessionToken={employeeSessionToken}
    >
      {children}
    </SessionWrapper>
  </WaitForMsal>
);
