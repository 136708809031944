import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";

import { DateTime } from "luxon";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
  VictoryTooltip,
  VictoryVoronoiContainer
} from "victory";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { countsByLabel, orderedKeysFromStatusCount } from "~/lib/stats";
import { getLabelColor, getLabelDisplay } from "~/lib/status";

import "./StatusGraph.scss";

const TOOLTIP_BACKGROUND_COLOR = "#263D39";
const TOOLTIP_TEXT_COLOR = "#FFFFFF";
const TOOLTIP_DX = 6;
const TOOLTIP_CENTER_OFFSET_X = 130;

const COLOR_MEDIUM_DARK_GRAY = "#8B8B8B";

const DEFAULT_FONT_STYLE = {
  fontFamily: "Urbanist",
  fontSize: 14
};

const AXIS_COLOR = "#E5E5E5";
const AXIS_TICK_SIZE = 5;
const AXIS_LABEL_BREAKPOINT = 1024;
const AXIS_MIN_WIDTH = 768;
const AXIS_STYLE = {
  axis: { stroke: AXIS_COLOR },
  ticks: { stroke: AXIS_COLOR, size: AXIS_TICK_SIZE },
  tickLabels: {
    ...DEFAULT_FONT_STYLE,
    fill: COLOR_MEDIUM_DARK_GRAY,
    padding: 3
  }
};

export const StatusGraph: FunctionComponent<StatusGraphProps> = ({
  statusCounts
}) => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const [graphWidth, setGraphWidth] = useState(0);
  const graphHeight = 350;
  const TOOLTIP_BREAKPOINT_X = graphWidth - TOOLTIP_CENTER_OFFSET_X - 50;

  const labelKeys = orderedKeysFromStatusCount(statusCounts[0]);
  const labelKeysReversed = [...labelKeys].reverse();

  const labelTooltipText = ({ datum }: any): string => {
    // datum._stack param is 1-based
    const key = labelKeys[datum._stack - 1];
    const display = getLabelDisplay(key, session.labels, language);
    const count = datum[key];
    return `${count} — ${display}`;
  };

  // ref so we can scroll the graph to today's data
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollTo({ left: 2000, behavior: "smooth" });

      const handleResize = (): void => {
        ref.current?.clientWidth &&
          setGraphWidth(
            ref.current?.clientWidth < AXIS_MIN_WIDTH
              ? AXIS_MIN_WIDTH
              : ref.current?.clientWidth
          );
      };
      window.addEventListener("resize", handleResize);
      handleResize();
    }
  }, [ref]);
  // ref can't be at top level so we have to wrap in a fragment
  return (
    <>
      <div className="status-graph-container" ref={ref}>
        <VictoryChart
          width={graphWidth}
          height={graphHeight}
          domainPadding={{ x: 40, y: 40 }}
          padding={{ top: 30, bottom: 70, left: 40, right: 40 }}
          containerComponent={
            <VictoryVoronoiContainer
              responsive={false}
              voronoiDimension="x"
              voronoiPadding={{ top: 30, bottom: 70 }}
              labels={labelTooltipText}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={0}
                  dx={({ x }) =>
                    (x ?? 0) > TOOLTIP_BREAKPOINT_X ? -TOOLTIP_DX : TOOLTIP_DX
                  }
                  centerOffset={{
                    x: ({ x }) =>
                      (x ?? 0) > TOOLTIP_BREAKPOINT_X
                        ? -TOOLTIP_CENTER_OFFSET_X
                        : TOOLTIP_CENTER_OFFSET_X
                  }}
                  pointerLength={1}
                  pointerOrientation={({ x }) =>
                    x > TOOLTIP_BREAKPOINT_X ? "right" : "left"
                  }
                  flyoutPadding={16}
                  flyoutStyle={{ fill: TOOLTIP_BACKGROUND_COLOR }}
                  flyoutWidth={190}
                  labelComponent={
                    <VictoryLabel
                      lineHeight={1.2}
                      dx={-80}
                      textAnchor="start"
                    />
                  }
                  style={{
                    ...DEFAULT_FONT_STYLE,
                    fill: TOOLTIP_TEXT_COLOR
                  }}
                  constrainToVisibleArea
                />
              }
            />
          }
        >
          <VictoryAxis
            dependentAxis
            tickFormat={value => Math.round(value)}
            style={{
              ...AXIS_STYLE,
              grid: { stroke: AXIS_COLOR }
            }}
          />
          <VictoryAxis
            dependentAxis
            orientation="right"
            tickFormat={value => Math.round(value)}
            style={{
              ...AXIS_STYLE,
              grid: { stroke: AXIS_COLOR }
            }}
          />
          <VictoryAxis
            tickCount={statusCounts.length}
            tickFormat={
              graphWidth < AXIS_LABEL_BREAKPOINT
                ? value =>
                    DateTime.fromISO(value).toFormat("L/d", { locale: "en-US" })
                : value =>
                    DateTime.fromISO(value).toFormat("EEE\nL/d", {
                      locale: "en-US"
                    })
            }
            style={AXIS_STYLE}
            tickLabelComponent={
              graphWidth < AXIS_LABEL_BREAKPOINT ? (
                <VictoryLabel angle={-45} textAnchor="end" dx={-5} />
              ) : (
                <VictoryLabel />
              )
            }
          />
          <VictoryStack
            colorScale={labelKeysReversed.map(key =>
              getLabelColor(key, session.labels)
            )}
          >
            {labelKeysReversed.map(key => (
              <VictoryBar
                key={key}
                data={statusCounts.map(sc => ({
                  datetime: sc.datetime,
                  ...countsByLabel(sc)
                }))}
                x="datetime"
                y={key}
              />
            ))}
          </VictoryStack>
        </VictoryChart>
      </div>
    </>
  );
};

interface StatusGraphProps {
  statusCounts: api.StatusCounts[];
}
