import React, { FunctionComponent } from "react";
import { Redirect } from "react-router-dom";

import { useMsal } from "@azure/msal-react";

//
// Upon redirect from azure auth flow,
// redirect to appropriate landing place
//
//
//
export const AzureAuthRedirect: FunctionComponent = () => {
  const msal = useMsal();
  const isAuthenticated = msal.accounts.length > 0;
  const destination = isAuthenticated ? "/" : "/sign-in";
  return <Redirect to={destination} />;
};
