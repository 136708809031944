import { createContext } from "react";

import * as api from "~/api";

/**
 * A wrapper around a session object paired with an update
 * method that, when implemented, can safely update the session
 * globally.
 */
export interface MutableSession {
  session: api.Session;
  updateSession: (session: api.Session) => void;
}

/**
 * An implementation of the MutableSession interface whose update
 * method does nothing.
 */
const NoOpMutableSession: MutableSession = {
  session: api.EMPTY_SESSION,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateSession: session => {}
};

export const MutableSessionContext =
  createContext<MutableSession>(NoOpMutableSession);
