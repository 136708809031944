import React, { FunctionComponent } from "react";

import clsx from "clsx";

export interface TextInputProps {
  autoFocus?: boolean;
  className?: string;
  tabIndex?: number;
  id?: string;
  inputType?: string;
  validationRegex?: RegExp;
  maxLength?: number;
  multiLine?: boolean;
  uppercase?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string, valid: boolean) => any;
  onEnter: () => any;
}

export const emailFormatRegex =
  /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

export const TextInput: FunctionComponent<TextInputProps> = ({
  autoFocus,
  className,
  tabIndex,
  id,
  inputType,
  validationRegex,
  readOnly,
  placeholder,
  maxLength,
  multiLine,
  uppercase,
  onChange,
  onEnter,
  value
}) => {
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value: newValue }
    } = event;
    let modifiedValue = newValue;
    if (uppercase) {
      modifiedValue = modifiedValue.toUpperCase();
    }
    const valid = validationRegex ? validationRegex.test(modifiedValue) : true;
    onChange(modifiedValue, valid);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  const onTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void =>
    onInputChange(event as unknown as React.ChangeEvent<HTMLInputElement>);

  const onTextAreaKeyPress = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ): void =>
    onKeyPress(event as unknown as React.KeyboardEvent<HTMLInputElement>);

  if (multiLine) {
    return (
      <div className={clsx("flex-grow outline-none", className)}>
        <textarea
          className="w-full h-full outline-none resize-none"
          autoFocus={Boolean(autoFocus)}
          tabIndex={tabIndex}
          id={id}
          value={value}
          readOnly={readOnly}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={onTextAreaChange}
          onKeyPress={onTextAreaKeyPress}
        />
      </div>
    );
  }

  return (
    <input
      autoFocus={Boolean(autoFocus)}
      tabIndex={tabIndex}
      id={id}
      type={inputType ?? "text"}
      maxLength={maxLength}
      onChange={onInputChange}
      onKeyPress={onKeyPress}
      readOnly={readOnly}
      placeholder={placeholder}
      className={clsx("flex-grow outline-none", className)}
      value={value}
    />
  );
};
