import React, { FunctionComponent } from "react";

import clsx from "clsx";
import Mustache from "mustache";

import * as api from "~/api";
import { TextBubble } from "~/components/TextBubble/TextBubble";

export interface TextMessageProps {
  templateText: string;
  context: api.TemplateStringContext;
  theme?: "dark" | "green" | "light";
}

export const TextMessage: FunctionComponent<TextMessageProps> = ({
  theme = "light",
  templateText,
  context
}) => {
  const className = clsx(
    theme === "dark" && "bg-hs-black-20 text-white",
    theme === "light" && "bg-gray-100 text-hs-dark",
    theme === "green" && "bg-hs-green text-white"
  );

  return (
    <div className="flex flex-col space-y-3">
      <TextBubble className={className}>
        {Mustache.render(templateText, context)}
      </TextBubble>
    </div>
  );
};
