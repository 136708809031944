import React, { FunctionComponent } from "react";

import CheckLogoIconDarkGreen from "~/images/check-logo-icon-dark-green.svg";

export interface CarrierDisallowedProps {
  type?: "sendNow" | "schedule";
}

export const CarrierDisallowed: FunctionComponent<
  CarrierDisallowedProps
> = props => {
  const { type } = props;

  return (
    <>
      Your message could not be{" "}
      {type && type !== "sendNow" ? "scheduled" : "sent"} as it includes content
      disallowed by cell carriers. For more information, please see our Help
      Article:
      <a href="https://help.teamsense.com/administrators/prohibited-or-suspicious-text-content">
        <img
          className="h-5 inline-block px-1 w-5"
          src={CheckLogoIconDarkGreen}
          alt="TeamSense Check Logo"
        />
        What does it mean if my Communicate message violates TeamSense terms? -
        TeamSense Help Center
      </a>
    </>
  );
};
