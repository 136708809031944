import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  Switch,
  Route
} from "react-router-dom";
import { toast } from "react-toastify";

import { Form, Formik } from "formik";

import { LoginPage } from "./LoginPage";

import * as api from "~/api"; /* Link */
import { EmailField, PasswordField } from "~/components";
import { updateFormForAPIResponse } from "~/lib/forms";
import { useEffectOnce } from "~/lib/hooks";
import { Button } from "~common";

interface LocationState {
  email?: string;
}

const EmailForm: FunctionComponent = () => {
  const history = useHistory();

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(false);

        if (values.email.trim().length === 0) {
          return;
        }

        // Validate email exists and has a set password
        const response = await api.validateEmail(values);
        if (response.ok) {
          history.push("/sign-in/password", { email: values.email });
        } else {
          const response = await api.requestPasswordReset(values);
          if (response.ok) {
            history.push("/sign-in/forgot/sent");
          } else {
            toast.error(
              "Please check your email for instructions on how to set your password. We will only send an email if we recognize your account and it is active."
            );
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <EmailField label="Email" name="email" autoComplete="username" />

          <Button submit className="btn btn-primary" disabled={isSubmitting}>
            Continue
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const PasswordForm: FunctionComponent = () => {
  const history = useHistory();
  interface ParamTypes {
    next: string;
  }
  const { next } = useParams<ParamTypes>();
  const location = useLocation<LocationState>();
  const email = location.state?.email || "";
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordEvent = function (
    e: React.KeyboardEvent<HTMLSpanElement> | React.MouseEvent<HTMLSpanElement>
  ): void {
    if ("key" in e && e.key !== "Enter" && e.key !== " ") {
      return;
    }
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // If email wasn't entered, return to sign-in
    if (!email) {
      history.push("/sign-in");
    }
    document.querySelector<HTMLInputElement>('[name="password"]')?.focus();
  });

  useEffectOnce(() => {
    if (new URLSearchParams(location.search).get("reset") === "y") {
      toast.success("Your password was reset.");
    }
  });

  return (
    <>
      <Formik
        initialValues={{ email, password: "" }}
        onSubmit={async (values, actions) => {
          sessionStorage.clear();
          const response = await api.login(values);
          actions.setSubmitting(false);
          updateFormForAPIResponse(actions, response);
          if (response.ok) {
            history.push(next || "/");
          }
        }}
      >
        {({ isSubmitting, status }) => (
          <Form>
            {status?.error && (
              <div className="field-set">
                <span className="error">{status?.error}</span>
              </div>
            )}

            <EmailField label="Email" name="email" autoComplete="username" />

            <PasswordField
              label="Password"
              name="password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
            />

            <div className="field-set text-right text-sm">
              <span
                role="button"
                tabIndex={0}
                className="link float-start"
                onKeyUp={showPasswordEvent}
                onClick={showPasswordEvent}
              >
                {showPassword ? "Hide" : "Show"} password
              </span>
              <Link className="underline text-hs-dark" to="/sign-in/forgot">
                Forgot password
              </Link>
            </div>

            <Button submit className="btn btn-primary" disabled={isSubmitting}>
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const Login: FunctionComponent = () => {
  return (
    <LoginPage>
      <Switch>
        <Route path="/sign-in" exact>
          <EmailForm />
        </Route>
        <Route path="/sign-in/password">
          <PasswordForm />
        </Route>
      </Switch>
    </LoginPage>
  );
};
