import React, {
  ChangeEvent,
  createRef,
  FunctionComponent,
  useState
} from "react";

import clsx from "clsx";

import { Transition } from "../Transition/Transition";

import FilterDropdownActive from "~/images/filter-dropdown-active.svg";
import FilterDropdown from "~/images/filter-dropdown.svg";

interface FilterHeadingProps {
  className?: string;
  titleClassName?: string;
  title: string;
  allSelected: boolean;
  noneSelected: boolean;
  itemSelections: boolean[];
  itemNames: string[];
  displayNone: boolean;
  onClick?: (index: number, selected: boolean) => void;
  onSelectAll?: (selected: boolean) => void;
  onNone?: (selected: boolean) => void;
}

interface MenuItemProps {
  checked: boolean;
  name: string;
  onChange?: (checked: boolean) => any;
}

const MenuItem: FunctionComponent<MenuItemProps> = ({
  checked,
  name,
  onChange
}) => (
  <div className="flex items-center">
    <label className="block py-2 text-sm leading-5" role="menuitem">
      <div className="flex flex-row">
        <div className="mt-1-2">
          <input
            type="checkbox"
            checked={checked}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onChange?.(event.target.checked);
            }}
          />
        </div>
        <span className="px-4">{name}</span>
      </div>
    </label>
  </div>
);

export const FilterHeading: FunctionComponent<FilterHeadingProps> = props => {
  const {
    className,
    titleClassName,
    title,
    allSelected,
    noneSelected,
    itemSelections,
    itemNames,
    displayNone,
    onClick,
    onSelectAll,
    onNone
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = createRef<HTMLDivElement>();
  const buttonRef = createRef<HTMLButtonElement>();

  return (
    <>
      <div className={clsx("relative inline-block text-left", className)}>
        <div>
          <button
            type="button"
            className={clsx(
              "inline-flex justify-between w-full",
              "focus:outline-none",
              "hover:underline",
              "transition ease-in-out duration-150",
              titleClassName
            )}
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            ref={buttonRef}
            onClick={() => {
              if (!showDropdown) {
                dropdownRef.current?.focus();
              }
              setShowDropdown(!showDropdown);
            }}
          >
            <span>{title}</span>
            <img
              className="my-auto ml-1"
              src={allSelected ? FilterDropdown : FilterDropdownActive}
              alt="FilterDropdown"
            />
          </button>
        </div>
        <div
          onBlur={event => {
            if (
              !(event.relatedTarget === buttonRef.current) &&
              !(
                event.relatedTarget instanceof Node &&
                event.currentTarget?.contains(event.relatedTarget)
              )
            ) {
              setShowDropdown(false);
            }
          }}
          tabIndex={-1}
          ref={dropdownRef}
        >
          <Transition
            show={showDropdown}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div
              className="table-dropdown border shadow-md rounded-md w-56 bg-white absolute overflow-y-auto -left-20"
              style={{ maxHeight: "calc(100vh - 210px)" }}
            >
              <div className="p-2 ml-2">
                <MenuItem
                  checked={allSelected}
                  name="Select All"
                  onChange={onSelectAll}
                />
              </div>
              <hr className="dropdown-break" />
              <div
                className="px-2 ml-2"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {itemNames.map((itemName, index) => (
                  <MenuItem
                    key={`filter-div-${index}`}
                    name={itemName}
                    checked={itemSelections[index]}
                    onChange={checked => {
                      onClick?.(index, checked);
                    }}
                  />
                ))}
              </div>
              {displayNone && (
                <>
                  <hr className="dropdown-break" />
                  <div className="p-2 ml-2">
                    <MenuItem
                      checked={noneSelected}
                      name="(None)"
                      onChange={onNone}
                    />
                  </div>
                </>
              )}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};
