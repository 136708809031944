import React, { FunctionComponent, useContext, useState } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";

import { ArrowPicker } from "./ArrowPicker";

import * as api from "~/api";
import { CalendarMonth } from "~/components";
import { DeleteAttendanceStatusModal } from "~/components/DeleteAttendanceStatusModal/DeleteAttendanceStatusModal";
import { ScheduleAttendanceStatusModal } from "~/components/ScheduleAttendanceStatusModal/ScheduleAttendanceStatusModal";
import { SickEventModal } from "~/components/SickEventModal/SickEventModal";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import {
  deleteAttendanceStatusEvent,
  saveAttendanceStatusChange
} from "~/lib/status";
import { TopButton } from "~common";

const GRID_CLASS_NAME = "ml-18 mr-20 mt-7 mb-8";
const TITLE_CLASS_NAME =
  "text-1-5xl font-bold pb-7 pt-8 px-10 border-b border-hs-alt-gray";

interface ScheduleAttendanceStatusModalState {
  visible: boolean;
  event?: api.EmployeeEvent;
  defaultStartDate?: DateTime;
}

interface EmployeeCalendarOverviewProps {
  className?: string;
  employee: api.Employee;
  onEmployeeChanged?: (employee: api.Employee) => any;
}

export const EmployeeCalendarOverview: FunctionComponent<
  EmployeeCalendarOverviewProps
> = props => {
  const { className, employee, onEmployeeChanged } = props;

  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const [dateTimePrimary, setDateTimePrimary] = useState(DateTime.local());
  const [sickEventModal, setSickEventModal] = useState<api.EmployeeEvent>();
  const [scheduleAttendanceStatusModal, setScheduleAttendanceStatusModal] =
    useState<ScheduleAttendanceStatusModalState>({ visible: false });
  const [deleteAttendanceStatusModal, setDeleteAttendanceStatusModal] =
    useState<api.EmployeeEvent>();

  const nextMonth = dateTimePrimary.plus({ months: 1 });

  const onPickForward = (): void => setDateTimePrimary(nextMonth);

  const onPickBack = (): void => {
    setDateTimePrimary(dateTimePrimary.minus({ months: 1 }));
  };

  const onClickDay = (day: DateTime, events?: api.EmployeeEvent[]): void => {
    const event = events?.[0];
    if (!event) {
      setScheduleAttendanceStatusModal({
        visible: true,
        defaultStartDate: day
      });
      return;
    }
    switch (event.label.label_set) {
      case api.LabelSet.CovidHealthStatus:
        setSickEventModal(event);
        break;
      case api.LabelSet.AttendanceStatus:
        setScheduleAttendanceStatusModal({ visible: true, event });
        break;
    }
  };

  const onAttendanceStatusSave = async (
    startDate: api.ISODateTime,
    endDate: api.ISODateTime,
    status: api.AttendanceStatus,
    note?: string
  ): Promise<void> => {
    const { event } = scheduleAttendanceStatusModal;
    if (
      await saveAttendanceStatusChange(
        employee,
        startDate,
        endDate,
        status,
        session.labels,
        language,
        note,
        event
      )
    ) {
      onEmployeeChanged?.(employee);
    }
  };

  const onClickAttendanceStatusDelete = (): void => {
    const { event } = scheduleAttendanceStatusModal;

    setScheduleAttendanceStatusModal({ visible: false });
    setDeleteAttendanceStatusModal(event);
  };

  const onAttendanceStatusDelete = async (): Promise<void> => {
    const event = deleteAttendanceStatusModal;
    if (!event) {
      return;
    }
    if (await deleteAttendanceStatusEvent(employee, event)) {
      onEmployeeChanged?.(employee);
    }
  };

  return (
    <>
      <div className="flex flex-row justify-end mr-10">
        <div className="my-8">
          <TopButton
            title="Add Status"
            onClick={() => setScheduleAttendanceStatusModal({ visible: true })}
          />
        </div>
      </div>
      <div className={clsx("flex flex-row overflow-x-hidden ml-10", className)}>
        <div>
          <CalendarMonth
            year={dateTimePrimary.year}
            monthOfYear={dateTimePrimary.month}
            gridClassName={GRID_CLASS_NAME}
            titleClassName={TITLE_CLASS_NAME}
            showLegend
            hoverTooltips
            events={employee.events}
            onClickDay={onClickDay}
            timezone={employee.timezone}
          />
          <ArrowPicker onLeft={onPickBack} onRight={onPickForward} />
        </div>
        <div>
          <CalendarMonth
            className="transform scale-90"
            year={nextMonth.year}
            monthOfYear={nextMonth.month}
            gridClassName={GRID_CLASS_NAME}
            titleClassName={TITLE_CLASS_NAME}
            showLegend
            hoverTooltips
            events={employee.events}
            onClickDay={onClickDay}
            timezone={employee.timezone}
          />
        </div>
      </div>
      {employee && sickEventModal && (
        <SickEventModal
          employee={employee}
          event={sickEventModal}
          labelInfoMap={session.labels}
          onClose={() => setSickEventModal(undefined)}
        />
      )}
      {employee && scheduleAttendanceStatusModal.visible && (
        <ScheduleAttendanceStatusModal
          event={scheduleAttendanceStatusModal.event}
          defaultStartDate={scheduleAttendanceStatusModal.defaultStartDate}
          timezone={employee.timezone}
          onClose={() => setScheduleAttendanceStatusModal({ visible: false })}
          onSave={onAttendanceStatusSave}
          onClickDelete={onClickAttendanceStatusDelete}
        />
      )}
      {employee && deleteAttendanceStatusModal && (
        <DeleteAttendanceStatusModal
          employee={employee}
          event={deleteAttendanceStatusModal}
          onDelete={onAttendanceStatusDelete}
          onClose={() => setDeleteAttendanceStatusModal(undefined)}
        />
      )}
    </>
  );
};
