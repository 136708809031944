import React, { FC, useContext, useState } from "react";

import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage, useSessionStorageConfig } from "~/lib/hooks";
import { Counts } from "~/lib/stats";
import {
  getLabelColorDarkened,
  getLabelColorLightened,
  getLabelDisplay
} from "~/lib/status";

import "./StatsGrid.scss";

export const StatsGrid: FC<StatsGridProps> = ({ counts, statClickHandler }) => {
  const [config] = useSessionStorageConfig();
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  // convert statusCounts to an object and then an array of kys to loop through
  const keys = Object.entries(counts ?? {});

  // track the selected item
  const [selectedKey, setSelectedKey] = useState<string | null>(
    config.key || ""
  );

  // loop through the keys, fetch the translated labels and
  // spit out a grid of clickable status counts
  const statTiles = keys?.map(([key, count], index) => {
    const labelText = getLabelDisplay(key, session.labels, language);
    const labelTextColor = getLabelColorDarkened(key, session.labels);
    const labelBgColor = getLabelColorLightened(key, session.labels);

    const pillStyle = {
      color: labelTextColor,
      backgroundColor: labelBgColor
    };

    return (
      count > 0 && (
        <button
          key={`stat${index}`}
          className={`stat-tile${selectedKey === key ? " selected" : ""}`}
          onClick={() => {
            statClickHandler(selectedKey === key ? "" : key);
            setSelectedKey(selectedKey === key ? "" : key);
          }}
        >
          <h1 className="stat-count">{count}</h1>
          <span className="badge rounded-pill" style={pillStyle}>
            {labelText}
          </span>
        </button>
      )
    );
  });

  return <div className="stats-grid">{statTiles}</div>;
};

interface StatsGridProps {
  counts?: Counts;
  statClickHandler: (key: string) => void;
}
