import React, { FunctionComponent, useMemo, useState } from "react";
import DatePicker from "react-mobile-datepicker";

import { Trans } from "@lingui/react";
import { DateTime } from "luxon";

import { useLinguiLocale } from "~/lib/hooks";
import { Modal, ModalProps } from "~common";

import "./TimePicker.scss";

export interface TimePickerModalProps extends ModalProps {
  initialHour: number;
  initialMinute: number;
  onSave: (hour: number, minute: number) => any;
}

const getHourConfig = (locale: string): any => ({
  format: (date: Date) => {
    try {
      return DateTime.local()
        .set({ hour: date.getHours(), minute: date.getMinutes() })
        .toLocaleString({ ...DateTime.TIME_SIMPLE, locale, minute: undefined });
    } catch (e) {
      return DateTime.local()
        .set({ hour: date.getHours(), minute: date.getMinutes() })
        .toLocaleString({
          ...DateTime.TIME_SIMPLE,
          locale: "en-US",
          minute: undefined
        });
    }
  },
  caption: <Trans id="time.caption.hour">Hour</Trans>,
  step: 1
});

const MINUTE_CONFIG = {
  format: "mm",
  caption: <Trans id="time.caption.minute">Minute</Trans>,
  step: 15
};

const getDateConfig = (locale: string): any => {
  try {
    return {
      hour: getHourConfig(locale),
      minute: MINUTE_CONFIG
    };
  } catch (e) {
    return {
      hour: getHourConfig("en-US"),
      minute: MINUTE_CONFIG
    };
  }
};

interface TimePickerWidgetProps {
  onChange: (value: Date) => any;
  value: Date;
}

export const TimePickerWidget: FunctionComponent<TimePickerWidgetProps> = ({
  onChange,
  value
}) => {
  const locale = useLinguiLocale();

  return (
    <div className="w-72 p-4" dir="ltr">
      <DatePicker
        dateConfig={getDateConfig(locale)}
        onChange={onChange}
        showFooter={false}
        showHeader={false}
        isPopup={false}
        showCaption
        value={value}
      />
    </div>
  );
};

export const TimePickerModal: FunctionComponent<TimePickerModalProps> = ({
  onClose,
  initialHour,
  initialMinute,
  onSave
}) => {
  const [hour, setHour] = useState(initialHour);
  const [minute, setMinute] = useState(initialMinute);
  const initialValue = useMemo(() => {
    return DateTime.local().set({ hour, minute }).toJSDate();
  }, [hour, minute]);
  const onChange = (date: Date): void => {
    const dt = DateTime.fromJSDate(date);
    setHour(dt.get("hour"));
    setMinute(dt.get("minute"));
  };

  const onClickSave = (): void => {
    onSave(hour, minute);
    onClose();
  };

  return (
    <Modal className="rounded w-auto p-0" onClose={onClose}>
      <TimePickerWidget onChange={onChange} value={initialValue} />
      <div className="w-72 border border-hs-alt-gray" />
      <div className="flex flex-row py-4 px-6 justify-end">
        <div className="cursor-pointer ml-4 mr-4" onClick={onClose}>
          <Trans id="common.cancel">Cancel</Trans>
        </div>
        <div className="cursor-pointer ml-1 mr-1" onClick={onClickSave}>
          <Trans id="common.save">Save</Trans>
        </div>
      </div>
    </Modal>
  );
};
