import React, { FunctionComponent } from "react";

import { t } from "@lingui/macro";
import { I18n } from "@lingui/react";
import clsx from "clsx";
import TextareaAutosize from "react-autosize-textarea/lib";

import { rightExpander } from "~/svg";

export interface MessageResponseFieldProps {
  darkMode?: boolean;
  showSubmitButton?: boolean;
  placeholder?: string;
  value?: string;
  onClick?: () => any;
  onChange?: (text: string) => any;
}

export const MessageResponseField: FunctionComponent<
  MessageResponseFieldProps
> = ({ darkMode, showSubmitButton, placeholder, value, onClick, onChange }) => {
  return (
    <div
      className={clsx(
        "leading-tight rounded-lg py-2 px-4",
        darkMode ? "bg-hs-white-5 text-white" : "bg-gray-100 text-hs-dark"
      )}
    >
      <I18n>
        {({ i18n }) => (
          <div className="flex flex-row items-center">
            <TextareaAutosize
              className={clsx(
                "flex-grow outline-none py-1 px-1 bg-transparent placeholder-opacity-25",
                darkMode ? "placeholder-white" : "placeholder-black"
              )}
              value={value}
              placeholder={
                placeholder ??
                i18n._(t("message.text.placeholder")`Tap to type (Optional)`)
              }
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                onChange?.(event.target.value)
              }
            />
            {showSubmitButton && (
              <div
                className="flex flex-col cursor-pointer justify-center w-7 h-7"
                onClick={onClick}
              >
                {rightExpander({
                  className: "text-hs-green"
                })}
              </div>
            )}
          </div>
        )}
      </I18n>
    </div>
  );
};
