import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { DateTime } from "luxon";

import * as api from "~/api";
import { TextScreenerLinkModal } from "~/components/TextScreenerLinkModal/TextScreenerLinkModal";
import { VisitorInstructionsModal } from "~/components/VisitorInstructionsModal/VisitorInstructionsModal";
import { VisitorLogModal } from "~/components/VisitorLogModal/VisitorLogModal";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { countsByLabel } from "~/lib/stats";
import {
  countsToStats,
  Stats,
  Loading,
  ContentCard,
  GenericErrorText,
  TopButton
} from "~common";
import { ExportSurveyResultsModal } from "~forms";
import { StatusGraph } from "~reports";

const DAYS_TO_SHOW_IN_STATUS_GRAPH = 20;

/** The top-level reports pane view */
export const ReportsPane: FunctionComponent = () => {
  const [visitorConversations, setVisitorConversations] =
    useState<api.SurveyStateAndEmployee[]>();
  const [statusCounts, setStatusCounts] = useState<api.StatusCounts[]>();
  const [exportModalSurveyId, setExportModalSurveyId] = useState<string>();
  const [showTextScreenerLinkModal, setShowTextScreenerLinkModal] =
    useState(false);
  const [showVisitorLogModal, setShowVisitorLogModal] = useState(false);
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  const [showExportAttendanceReportModal, setShowExportAttendanceReportModal] =
    useState(false);
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const history = useHistory();

  useEffect(() => {
    const loadStatusCounts = async (): Promise<void> => {
      if (!session.company) {
        return;
      }

      setExportModalSurveyId(session.company.id);

      let response: api.APIResponse<api.StatusCounts[]> | undefined;
      try {
        response = await api.retrieveStatusCounts(
          session.company.id,
          DateTime.local()
            .minus({ days: DAYS_TO_SHOW_IN_STATUS_GRAPH })
            .set({ hour: 22, minute: 59, second: 59, millisecond: 0 })
            .toISO()
        );
      } catch {}

      if (response?.ok) {
        setStatusCounts(response.data);
      } else {
        toast.error(<GenericErrorText />);
      }
    };

    loadStatusCounts();
  }, [session.company]);

  useEffect(() => {
    const loadVisitorConversations = async (): Promise<void> => {
      const response = await api.retrieveVisitorSurveyHistory(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        session.company!.id,
        {
          limit: api.MAX_LIMIT
        }
      );

      if (response.ok) {
        setVisitorConversations(response.data.results);
      } else {
        console.debug(
          `loadVisitorConversations: could not retrieve conversations: `,
          response.errors
        );
      }
    };

    if (showVisitorLogModal) {
      setVisitorConversations(undefined);
      loadVisitorConversations();
    }
  }, [session.company, showVisitorLogModal]);

  const onClickStatus = (key: string): void =>
    history.push(`/dashboard/employees?status=${key}`);

  const onSendScreenerLink = async (
    employee: api.IdentificationEmployee
  ): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const response = await api.makeScreener(session.company!.id, employee.id);
    if (response.ok) {
      toast.success(
        "Today's screener link has been sent. It will expire in 24 hours."
      );
    } else {
      toast.error(
        `Sorry, we were unable to send the screener link to ${employee.name}.`
      );
    }
  };

  if (!statusCounts?.length) {
    return <Loading />;
  }

  // TODO: once the Attendance tab is 100% live, we'll remove the Export Attendance Report
  const showExportAttendanceReportButton = true;
  const showTopButtonArea =
    showExportAttendanceReportButton ||
    session.features.full_covid_health_statuses ||
    session.features.visitors;

  return (
    <>
      {showTopButtonArea && (
        <div className="relative flex justify-end space-x-2 my-8 mx-4 sm:mx-6 lg:mx-10">
          {session.features.full_covid_health_statuses && (
            <TopButton
              title="Screen Employees"
              onClick={() => {
                setShowTextScreenerLinkModal(true);
              }}
            />
          )}
          {session.features.visitors && (
            <>
              <TopButton
                title="Visitor Log"
                onClick={() => setShowVisitorLogModal(true)}
              />
              <TopButton
                title="Visitor Instructions"
                onClick={() => setShowInstructionsModal(true)}
              />
            </>
          )}
          {showExportAttendanceReportButton && (
            <TopButton
              title="Export Attendance Report"
              onClick={() => setShowExportAttendanceReportModal(true)}
            />
          )}
        </div>
      )}

      <ContentCard
        outerWrapperClassName="mx-4 sm:mx-6 lg:mx-10"
        title="Today's Status"
      >
        <Stats
          stats={countsToStats(
            countsByLabel(statusCounts[statusCounts.length - 1]),
            session.labels,
            language,
            onClickStatus
          )}
        />
      </ContentCard>

      <ContentCard outerWrapperClassName="mx-4 sm:mx-6 lg:mx-10" title="Trends">
        <h3>Number of Employees</h3>
        <StatusGraph statusCounts={statusCounts} />
      </ContentCard>

      {showTextScreenerLinkModal && (
        <TextScreenerLinkModal
          onClose={() => setShowTextScreenerLinkModal(false)}
          onSend={onSendScreenerLink}
        />
      )}
      {showVisitorLogModal && (
        <VisitorLogModal
          loading={!visitorConversations}
          surveyStates={visitorConversations}
          onClose={() => setShowVisitorLogModal(false)}
        />
      )}
      {showInstructionsModal && (
        <VisitorInstructionsModal
          onClose={() => setShowInstructionsModal(false)}
        />
      )}

      {showExportAttendanceReportModal && (
        <ExportSurveyResultsModal
          allowFutureDates
          handleClose={() => setShowExportAttendanceReportModal(false)}
          exportModalSurveyId={exportModalSurveyId}
          exportType="attendance"
          titleModal="Export Attendance Report"
          descriptionModal="Export a .CSV file of attendance statuses for a selected range of dates:"
        />
      )}
    </>
  );
};
