import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography/Typography";

import Card from "../Card/Card";
import ThemeLayout from "../Theme/ThemeLayout";

import TSLogo from "~/images/brand-dark-green.svg";
import { TSFooter } from "~/ts-components/common/ts-footer/TSFooter";

const FallbackComponent = ({ error }: { error: Error }): JSX.Element => {
  const handleSubmit = (): void => {
    const email = "support@teamsense.com";
    const subject = "Bug Report";
    const errTexts = {
      name: `Error Name: ${error.name}`,
      message: `Error Message: ${error.message}`,
      cause: `Error Cause: ${error.cause}`,
      stack: `Error Stack: ${error.stack}`
    };
    const body = `${errTexts.name} |\n ${errTexts.message} |\n ${errTexts.cause} |\n ${errTexts.stack}`;
    const mailto = `mailto:${email}?subject=${subject}&body=${body}`;
    window.location.href = mailto;
  };

  return (
    <ThemeLayout>
      <Box className="flex flex-col h-screen">
        <Box className="flex flex-col gap-6 py-6 px-16 justify-center grow">
          <img
            alt="TeamSense Logo"
            className="self-center w-[132px]"
            src={TSLogo}
          />
          <Card className="flex flex-col grow p-28 gap-8 justify-center text-center items-center">
            <Box className="rounded-full aspect-square w-28 h-28 bg-[#DFEDEC] p-4">
              <Typography variant="h1" className="!text-6xl !leading-[80px]">
                😦
              </Typography>
            </Box>
            <Typography variant="h1" className="text-ts-teal-20">
              Sorry, we can't find the page you're looking for.
            </Typography>
            <Button color="primary" onClick={handleSubmit} className="w-fit">
              Report Issue
            </Button>
          </Card>
        </Box>
        <TSFooter />
      </Box>
    </ThemeLayout>
  );
};

export default FallbackComponent;
