import React, { FunctionComponent, useContext, useMemo } from "react";

import clsx from "clsx";
import { set } from "lodash";
import { DateTime } from "luxon";

import { CalendarRow, EventDay, StatusEventDayMap } from "./CalendarRow";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { eventIsActive } from "~/lib/employeeEvents";

const EVENT_CUTOFF_HOUR = 20;
const DAYS_IN_WEEK = 7;
const MIN_WEEK_ROWS = 6;

const DayOfWeekHeader: FunctionComponent<{
  compactSpacing?: boolean;
}> = ({ compactSpacing }) => (
  <CalendarRow
    className={compactSpacing ? "mb-1" : "mb-2"}
    compactSpacing
    cellClassName="text-sm text-hs-dark-green-60 uppercase"
    labels={["s", "m", "t", "w", "th", "f", "s"]}
  />
);

const BlankWeekSpacer: FunctionComponent<{ compactSpacing?: boolean }> = ({
  compactSpacing
}) => (
  <div className={clsx("w-12", compactSpacing ? "h-11 mb-1" : "h-12 mb-2")} />
);

interface CalendarDateRowProps {
  labels: string[];
  compactSpacing?: boolean;
  hoverTooltips?: boolean;
  inactiveCells?: boolean[];
  eventCells?: StatusEventDayMap[];
  todayIndex?: number;
  onClickDayLabel?: (label: string) => any;
}

const CalendarDateRow: FunctionComponent<CalendarDateRowProps> = props => (
  <CalendarRow
    dateCells
    className={props.compactSpacing ? "my-1" : "my-2"}
    cellClassName="text-sm"
    inactiveCellClassName="text-hs-near-black opacity-20"
    {...props}
  />
);

interface EventDayCellInfo {
  [year: number]: {
    [month: number]: {
      [day: number]: StatusEventDayMap;
    };
  };
}

type AllActiveLabelDays = { [K in api.AnyLabelStatus]: ActiveLabelDays };

interface ActiveLabelDays {
  [year: number]: {
    [month: number]: {
      [day: number]: boolean;
    };
  };
}

interface CalendarGridProps {
  className?: string;
  monthOfYear: number;
  year: number;
  timezone: string;
  compactSpacing?: boolean;
  hoverTooltips?: boolean;
  events?: api.EmployeeEvent[];
  onClickDay?: (day: DateTime, events?: api.EmployeeEvent[]) => any;
}

const transformEventsToActiveLabelDays = (
  startDate: DateTime,
  endDate: DateTime,
  labelSet: api.LabelSet,
  type: string,
  timezone: string,
  events?: api.EmployeeEvent[]
): ActiveLabelDays => {
  const activeLabelDays: ActiveLabelDays = {};
  for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
    set(
      activeLabelDays,
      `${date.year}.${date.month}.${date.day}`,
      events?.some(e =>
        eventIsActive(
          e,
          labelSet,
          type,
          DateTime.fromObject({
            zone: timezone
          })
            .set({
              year: date.year,
              month: date.month,
              day: date.day,
              hour: EVENT_CUTOFF_HOUR
            })
            .toISO()
        )
      ) ?? false
    );
  }
  return activeLabelDays;
};

// Table to convert prevActive, curActive, nextActive
const eventDayTable = {
  // prevActive
  f: {
    // curActive
    f: {
      // nextActive
      f: EventDay.none,
      t: EventDay.none
    },
    // curActive
    t: {
      // nextActive
      f: EventDay.only,
      t: EventDay.first
    }
  },
  // prevActive
  t: {
    // curActive
    f: {
      // nextActive
      f: EventDay.none,
      t: EventDay.none
    },
    // curActive
    t: {
      // nextActive
      f: EventDay.last,
      t: EventDay.middle
    }
  }
};

const transformActiveLabelDaysToEventDays = (
  activeLabelDays: AllActiveLabelDays,
  startDate: DateTime,
  endDate: DateTime
): EventDayCellInfo => {
  const now = DateTime.local();
  const eventDayCellInfo: EventDayCellInfo = {};

  for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
    (Object.keys(activeLabelDays) as api.AnyLabelStatus[]).forEach(
      labelStatus => {
        const cutoffFutureEvents =
          labelStatus === "sick" || labelStatus === "clear";
        const curActive =
          activeLabelDays[labelStatus][date.year][date.month][date.day];

        const prevDay = date.minus({ days: 1 });
        const prevActive =
          activeLabelDays[labelStatus][prevDay.year][prevDay.month][
            prevDay.day
          ];

        const nextDay = date.plus({ days: 1 });
        const nextActive =
          activeLabelDays[labelStatus][nextDay.year][nextDay.month][
            nextDay.day
          ];

        const eventDay =
          cutoffFutureEvents && date > now
            ? EventDay.none
            : eventDayTable[prevActive ? "t" : "f"][curActive ? "t" : "f"][
                nextActive ? "t" : "f"
              ];
        set(
          eventDayCellInfo,
          `${date.year}.${date.month}.${date.day}.${labelStatus}`,
          eventDay
        );
      }
    );
  }
  return eventDayCellInfo;
};

const generateEventDayCellInfo: (
  props: CalendarGridProps,
  statusKeysWithoutUnknown: string[]
) => EventDayCellInfo = (
  { year, monthOfYear, events, timezone },
  statusKeysWithoutUnknown
) => {
  const currentMonth = DateTime.local(year, monthOfYear);
  const previousMonth = currentMonth.minus({ months: 1 });
  const nextMonth = currentMonth.plus({ months: 1 });
  // Always build one week's data from the previous month,
  // all days from the current month, adn one week's data from the next month:
  const startDatePreviousMonth = previousMonth.daysInMonth + 1 - DAYS_IN_WEEK;
  const startDate = DateTime.local(
    previousMonth.year,
    previousMonth.month,
    startDatePreviousMonth
  );
  const endDate = DateTime.local(nextMonth.year, nextMonth.month, DAYS_IN_WEEK);

  // Filter events that are not in the range we care about for this grid
  const filteredEvents = events?.filter(event => {
    // NOTE: we filter out events that start more than a full day after the
    // endDate or end more than a full day before the startDate to ensure that
    // we've kept all of the relevant events, regardless of the difference in
    // the timezone between the local browser and the employee
    if (DateTime.fromISO(event.started) > endDate.plus({ days: 1 })) {
      return false;
    }
    if (
      event.ended &&
      DateTime.fromISO(event.ended) < startDate.minus({ days: 1 })
    ) {
      return false;
    }
    return true;
  });

  const tuples: [api.AnyLabelStatus, ActiveLabelDays][] =
    statusKeysWithoutUnknown.map(statusKey => [
      statusKey,
      transformEventsToActiveLabelDays(
        startDate,
        endDate,
        statusKey === "sick" || statusKey === "clear"
          ? api.LabelSet.CovidHealthStatus
          : api.LabelSet.AttendanceStatus,
        statusKey,
        timezone,
        filteredEvents
      )
    ]);
  const activeDays = Object.fromEntries(tuples) as AllActiveLabelDays;

  // Now convert activeDays into EventDayCellInfo:
  return transformActiveLabelDaysToEventDays(activeDays, startDate, endDate);
};

export const CalendarGrid: FunctionComponent<CalendarGridProps> = props => {
  const {
    className,
    monthOfYear,
    year,
    compactSpacing,
    hoverTooltips,
    events,
    timezone,
    onClickDay
  } = props;
  const { session } = useContext(MutableSessionContext);
  const eventDayCellInfo = useMemo(() => {
    const { unknown, ...labelsWithoutUnknown } = session.labels;
    return generateEventDayCellInfo(props, Object.keys(labelsWithoutUnknown));
  }, [props, session.labels]);
  const onClickDayLabel = (dayLabel: string): void => {
    // TODO: allow broader matching such that we don't require that the event is active at 8pm local time

    const dateClicked = DateTime.fromObject({ zone: timezone }).set({
      year,
      month: monthOfYear,
      day: parseInt(dayLabel),
      hour: EVENT_CUTOFF_HOUR
    });

    const clickedEvents = (events ?? []).filter(event => {
      const endOfDayToday = DateTime.local().endOf("day");
      if (dateClicked > endOfDayToday) {
        // Ignore sick events when clicking on future dates since we don't
        // display them in the future
        return false;
      }
      return eventIsActive(
        event,
        api.LabelSet.CovidHealthStatus,
        "sick",
        dateClicked.toISO()
      );
    });
    const { unknown, sick, clear, ...allAttendanceLabels } = session.labels;
    Object.keys(allAttendanceLabels).forEach(labelName => {
      const clickedAttendanceEvents = (events ?? []).filter(event =>
        eventIsActive(
          event,
          api.LabelSet.AttendanceStatus,
          labelName,
          dateClicked.toISO()
        )
      );
      clickedEvents.splice(-1, 0, ...clickedAttendanceEvents);
    });
    onClickDay?.(dateClicked.startOf("day"), clickedEvents);
  };
  const currentMonth = DateTime.local(year, monthOfYear);
  const now = DateTime.local();
  const todayInCurrentMonth = year === now.year && monthOfYear === now.month;
  const weeks = [];
  const weekdayToStartMonth = DateTime.local(year, monthOfYear, 1).weekday;
  let currentDate = 1;

  if (weekdayToStartMonth !== 7) {
    const previousMonth = currentMonth.minus({ months: 1 });
    const daysFromCurrentMonth = DAYS_IN_WEEK - weekdayToStartMonth;
    const daysFromPreviousMonth = DAYS_IN_WEEK - daysFromCurrentMonth;
    const dateFromPreviousMonthToStartRow =
      previousMonth.daysInMonth + 1 - daysFromPreviousMonth;
    const firstRowLabels = [];
    const firstRowInactiveCells = [];
    const firstRowEventCells = [];
    let firstRowTodayIndex;
    for (
      let currentPrevMonthDate = dateFromPreviousMonthToStartRow;
      currentPrevMonthDate <= previousMonth.daysInMonth;
      currentPrevMonthDate++
    ) {
      firstRowLabels.push(`${currentPrevMonthDate}`);
      firstRowInactiveCells.push(true);
      firstRowEventCells.push(
        eventDayCellInfo[previousMonth.year][previousMonth.month][
          currentPrevMonthDate
        ]
      );
    }
    for (; currentDate < daysFromCurrentMonth + 1; currentDate++) {
      if (todayInCurrentMonth && currentDate === now.day) {
        firstRowTodayIndex = firstRowLabels.length;
      }
      firstRowLabels.push(`${currentDate}`);
      firstRowInactiveCells.push(false);
      firstRowEventCells.push(eventDayCellInfo[year][monthOfYear][currentDate]);
    }
    weeks.push(
      <CalendarDateRow
        key="firstRow"
        compactSpacing={compactSpacing}
        hoverTooltips={hoverTooltips}
        labels={firstRowLabels}
        inactiveCells={firstRowInactiveCells}
        eventCells={firstRowEventCells}
        todayIndex={firstRowTodayIndex}
        onClickDayLabel={onClickDayLabel}
      />
    );
  }
  while (currentDate <= currentMonth.daysInMonth + 1 - DAYS_IN_WEEK) {
    const rowLabels = [];
    const eventCells = [];
    let todayIndex;
    for (let i = 0; i < DAYS_IN_WEEK; i++) {
      if (todayInCurrentMonth && currentDate + i === now.day) {
        todayIndex = i;
      }
      rowLabels.push(`${currentDate + i}`);
      eventCells.push(eventDayCellInfo[year][monthOfYear][currentDate + i]);
    }
    weeks.push(
      <CalendarDateRow
        key={`middleRow${weeks.length}`}
        compactSpacing={compactSpacing}
        hoverTooltips={hoverTooltips}
        labels={rowLabels}
        eventCells={eventCells}
        todayIndex={todayIndex}
        onClickDayLabel={onClickDayLabel}
      />
    );
    currentDate += DAYS_IN_WEEK;
  }
  if (currentDate <= currentMonth.daysInMonth) {
    const nextMonth = currentMonth.plus({ months: 1 });
    const daysFromCurrentMonth = currentMonth.daysInMonth + 1 - currentDate;
    const daysFromNextMonth = DAYS_IN_WEEK - daysFromCurrentMonth;
    const lastRowLabels = [];
    const lastRowInactiveCells = [];
    const lastRowEventCells = [];
    let lastRowTodayIndex;
    for (; currentDate < currentMonth.daysInMonth + 1; currentDate++) {
      if (todayInCurrentMonth && currentDate === now.day) {
        lastRowTodayIndex = lastRowLabels.length;
      }
      lastRowLabels.push(`${currentDate}`);
      lastRowInactiveCells.push(false);
      lastRowEventCells.push(eventDayCellInfo[year][monthOfYear][currentDate]);
    }
    for (
      let currentNextMonthDate = 1;
      currentNextMonthDate <= daysFromNextMonth + 1;
      currentNextMonthDate++
    ) {
      lastRowLabels.push(`${currentNextMonthDate}`);
      lastRowInactiveCells.push(true);
      lastRowEventCells.push(
        eventDayCellInfo[nextMonth.year][nextMonth.month][currentNextMonthDate]
      );
    }
    weeks.push(
      <CalendarDateRow
        key="lastRow"
        compactSpacing={compactSpacing}
        hoverTooltips={hoverTooltips}
        labels={lastRowLabels}
        inactiveCells={lastRowInactiveCells}
        eventCells={lastRowEventCells}
        todayIndex={lastRowTodayIndex}
        onClickDayLabel={onClickDayLabel}
      />
    );
  }
  while (weeks.length < MIN_WEEK_ROWS) {
    weeks.push(
      <BlankWeekSpacer
        compactSpacing={compactSpacing}
        key={`blankRow${weeks.length}`}
      />
    );
  }

  return (
    <div className={className}>
      <DayOfWeekHeader compactSpacing={compactSpacing} />
      <>{weeks}</>
    </div>
  );
};
