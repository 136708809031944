import React, { FunctionComponent, useContext } from "react";

import QRCode from "qrcode.react";

import BrandLogoGreen from "~/images/brand-green.svg";
import { MutableSessionContext } from "~/lib/context/";

interface InstructionsProps {
  companyName?: string;
  divisionName?: string;
  visitorCode?: string;
}

const Instructions: FunctionComponent<InstructionsProps> = ({
  companyName,
  divisionName,
  visitorCode
}) => {
  const visitorUrl = `${window.location.origin}/go?visitor_code=${visitorCode}`;
  const divisionTitle = divisionName ? ` - ${divisionName}` : "";
  const name = `${companyName}${divisionTitle}`;

  if (!visitorCode) {
    return null;
  }

  return (
    <div className="m-2 border-2 border-gray-700 p-5 text-xl page-break">
      <div className="uppercase text-center font-bold text-4xl">
        Covid-19 Screening Policy
      </div>
      <div className="text-2xl text-center mb-5">{name}</div>
      <div className="mb-4">
        As part of our effort to keep everyone safe during COVID-19, we require
        all individuals on-site to be screened for possible exposure or symptoms
        consistent with COVID-19.
      </div>
      <div className="mb-5">
        Screening is simple and should take less than 1 minute.
      </div>
      <div className="grid grid-cols-2 divide-x divide-gray-700">
        <div className="pt-4 pb-2">
          <div className="uppercase font-bold text-2xl text-center underline mb-3">
            Employees
          </div>
          <p className="mb-4">
            If you've already completed your screening check-in today, you're
            good to go!
          </p>
          <p className="mb-4">
            If you need to send yourself a new screening link:
          </p>
          <p className="mb-4">
            Navigate to{" "}
            <span className="font-bold text-hs-green">go.teamsense.com</span>
          </p>
          <p className="mb-4">
            Tap the button for "Employee check-in" and enter your contact
            information to receive a new screening link.
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <div className="uppercase font-bold text-2xl text-center underline mb-3">
            Visitors
          </div>
          <p className="mb-4">Scan this QR code to get started:</p>
          <div className="w-full text-center">
            <QRCode value={visitorUrl} className="inline" />
          </div>
          <p className="my-2 text-center">-OR-</p>
          <div className="mb-2">
            <p>
              Navigate to{" "}
              <span className="font-bold text-hs-green">go.teamsense.com</span>
            </p>
            <p>
              Tap the button for "Visitor check-in" and enter the following
              code:
            </p>
          </div>
          <div className="text-4xl text-center font-bold mb-1">
            {visitorCode}
          </div>
          <p className="">Follow the prompts to complete your screening.</p>
        </div>
      </div>
      <div className="bottom-0 mt-5 print-bottom">
        <img
          className="m-auto w-auto h-6"
          src={BrandLogoGreen}
          alt="TeamSense Logo"
        />
        <div className="text-xs text-center mt-3">
          Copyright © {new Date().getFullYear()} TeamSense, Inc. All rights
          reserved. Please visit www.TeamSense.com for Terms of Service &
          Privacy Policy.
        </div>
      </div>
    </div>
  );
};

export const VisitorPrintInstructions: FunctionComponent = () => {
  const { session } = useContext(MutableSessionContext);
  setTimeout(() => {
    window.print();
  }, 1000);

  return (
    <>
      <Instructions
        key="company-visitor-instructions"
        companyName={session.company?.name}
        visitorCode={session.company?.code}
      />
      {session.divisions?.map((division, index) => (
        <Instructions
          key={`division-instructions-${index}`}
          companyName={session.company?.name}
          divisionName={division.name}
          visitorCode={division.code}
        />
      ))}
    </>
  );
};
