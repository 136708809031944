import React, { FC, useContext, useEffect, useState } from "react";
import { Collapse, Modal, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { DateTime } from "luxon";

import * as api from "~/api";
import { displayPhoneNumber } from "~/lib/phoneNumber";
import { GenericErrorText, ToastContext, TSButton } from "~common";

import "./CommunicateSentModal.scss";

interface CommunicateSentModalProps {
  message: api.SentMessage;
  handleExit: () => any;
}

const NUM_SKELETON_ROWS = 3;

export const CommunicateSentModal: FC<CommunicateSentModalProps> = ({
  message,
  handleExit
}) => {
  const toast = useContext(ToastContext);

  const [messageDetail, setMessageDetail] = useState<api.SentMessageDetail>();

  useEffect(() => {
    const loadMessage = async (): Promise<void> => {
      if (!message?.sent_id) {
        return;
      }
      let response: api.APIResponse<api.SentMessageDetail> | undefined;

      try {
        response = await api.retrieveSentMessage(message.sent_id);
      } catch {}

      if (response?.ok) {
        setMessageDetail(response.data);
      } else {
        toast.show({
          variant: "danger",
          message: <GenericErrorText />
        });
      }
    };

    loadMessage();
  }, [message.sent_id, toast]);

  let scheduledDate = "";
  try {
    scheduledDate = DateTime.fromISO(message.due).toLocaleString({
      ...DateTime.DATETIME_FULL,
      timeZoneName: "short"
    });
  } catch {
    scheduledDate = DateTime.fromISO(message.due).toLocaleString({
      ...DateTime.DATETIME_FULL,
      locale: "en-US"
    });
  }

  const skeleton =
    !messageDetail &&
    [...Array(NUM_SKELETON_ROWS)].map((_, index) => (
      <tr key={`skeleton-${index}`} className="row-skeleton">
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
      </tr>
    ));

  return (
    <Modal
      show
      container={document.getElementById("ts-modal")}
      centered
      onHide={handleExit}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Sent at {scheduledDate}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>Message Sent</h2>
        <div className="sent-message">
          <div className="subject">
            {message?.context_json.email_subject ?? ""}
          </div>
          <div className="body">{message?.context_json.email_body ?? ""}</div>
        </div>

        <Collapse in={Boolean(messageDetail?.message_logs.length)}>
          <div>
            <Table responsive className="sent-message-log-table align-middle">
              <thead>
                <tr>
                  <th>EMPLOYEE</th>
                  <th>
                    <span className="text-right d-block">SENT TO</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {skeleton}
                {(messageDetail?.message_logs ?? []).map(
                  ({ id, kind, receiver, receiver_employee }) => (
                    <tr key={id}>
                      <td>
                        {receiver_employee && (
                          <>
                            <h3 className="mb-0">{receiver_employee.name}</h3>
                            <h6 className="text-muted">
                              ID: {receiver_employee.external_id}
                            </h6>
                          </>
                        )}
                      </td>
                      <td>
                        <span className="text-right d-block">
                          {kind === "sms" && receiver
                            ? displayPhoneNumber(receiver)
                            : receiver}
                        </span>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        </Collapse>
        {!messageDetail?.message_logs.length && (
          <Table responsive className="sent-message-log-table align-middle">
            <tbody>{skeleton}</tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <TSButton variant="link" onClick={handleExit}>
          Close
        </TSButton>
      </Modal.Footer>
    </Modal>
  );
};
