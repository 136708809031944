import React, { FunctionComponent, useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import clsx from "clsx";

import { AttendanceInsightsNoDivisions } from "./AttendanceNoDivisions";
import { AttendanceInsightsNoResults } from "./AttendanceNoResults";

import * as api from "~/api";
import { roundPercentForInsights } from "~/lib/attendanceInsights";
import { AttendancePopover } from "~/ts-components/attendance/AttendancePopover";
import { AttendanceProgressBar } from "~/ts-components/attendance/AttendanceProgressBar";

import "./EmployeesWithMostAbsences.scss";

export const AbsencesByDivision: FunctionComponent<AbsencesByDivisionProps> = ({
  isLoading,
  data,
  frameLengthText,
  shouldShowCTA
}) => {
  const [hasData, setHasData] = useState(false);
  data?.sort(
    (a, b) =>
      b.totals.summary.absencesPercent - a.totals.summary.absencesPercent
  );
  let max = 0;
  data?.forEach(d => {
    max = Math.max(d.totals.summary.absencesPercent, max);
  });

  const valueBuilder = (percent: number): string => {
    return roundPercentForInsights(percent);
  };

  const percentBuilder = (percent: number): number => {
    return (percent / max) * 100;
  };

  useEffect(() => {
    if (data) {
      setHasData(Boolean(data.find(d => d.totals.summary.absencesCount > 0)));
    }
  }, [data]);

  return (
    <Card
      className={clsx("attendance-insights-card", isLoading && "opacity-25")}
    >
      <div className="p-4">
        {shouldShowCTA ? (
          <AttendanceInsightsNoDivisions title="Absences by Division" />
        ) : (
          <>
            {data?.length && hasData ? (
              <>
                <header>
                  <h3>Absences by Division</h3>
                  <h2>
                    This is how your divisions’ absences compare to one another.
                  </h2>
                </header>
                <div>
                  {data?.map(division => (
                    <AttendanceProgressBar
                      key={division.division.id}
                      barColor="#6DAEA2"
                      barHoverColor="#487F78"
                      value={valueBuilder(
                        division.totals.summary.absencesPercent
                      )}
                      percentCompleted={percentBuilder(
                        division.totals.summary.absencesPercent
                      )}
                      label={<h6>{division.division.name}</h6>}
                      popover={
                        <AttendancePopover
                          title={division.division.name}
                          labels={division.totals.labels}
                          absencesCount={division.totals.summary.absencesCount}
                          durationText={frameLengthText}
                        />
                      }
                    />
                  ))}
                </div>
              </>
            ) : (
              <AttendanceInsightsNoResults title="Absences by Division" />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

interface AbsencesByDivisionProps {
  isLoading: boolean;
  data?: api.DivisionMetric[];
  frameLengthText?: string;
  shouldShowCTA?: boolean;
}
