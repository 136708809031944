import React, { FunctionComponent, useState } from "react";

import { DateTime } from "luxon";

import * as api from "~/api";
import { StatusDropdown } from "~/components/StatusDropdown/StatusDropdown";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";
import { MessageActionButton, Modal, ModalProps } from "~common";

const formattedDate = (iso: api.ISODateTime): string =>
  DateTime.fromISO(iso).toFormat("MMMM d", { locale: "en-US" });

export interface EditStatusModalProps extends ModalProps {
  employee: api.Employee;
  labelKey: api.CovidHealthStatus;
  fullCovidHealthStatuses?: boolean;
  labelInfoMap: api.LabelInfoMap;
  onConfirm?: (
    employee: api.Employee,
    healthStatus: api.CovidHealthStatus,
    note: string
  ) => any;
}

export const EditStatusModal: FunctionComponent<EditStatusModalProps> = ({
  employee,
  labelKey,
  fullCovidHealthStatuses,
  labelInfoMap,
  onConfirm,
  onClose
}) => {
  const language = useLinguiLanguage();
  const [note, setNote] = useState("");
  const [newStatus, setNewStatus] = useState(
    fullCovidHealthStatuses ? labelKey : "unknown"
  );

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setNote(event.target.value);

  const onStatusChange = (changedStatus: api.CovidHealthStatus): void =>
    setNewStatus(changedStatus);

  const onConfirmClicked = (): void => {
    onConfirm?.(employee, newStatus, note);
    onClose();
  };

  const sickEvent = (employee.active_events ?? []).find(
    ({ label }) => label.name === "sick"
  );
  const clearEvent = (employee.active_events ?? []).find(
    ({ label }) => label.name === "clear"
  );
  const alertStatus = getLabelDisplay("sick", labelInfoMap, language);

  let titleText;
  if (sickEvent) {
    titleText = `${
      employee.name
    } entered ${alertStatus} status on ${formattedDate(sickEvent.started)}.`;
  } else if (clearEvent) {
    titleText = `${employee.name} was last cleared on ${formattedDate(
      clearEvent.started
    )}.`;
  } else {
    titleText = `${employee.name} does not have a current status.`;
  }

  return (
    <Modal className="rounded max-w-lg p-16" onClose={onClose}>
      <div className="font-bold text-4xl mb">
        {fullCovidHealthStatuses
          ? "Edit Status"
          : `Remove ${alertStatus} status`}
      </div>
      <div className="text-base font-light py-8">{titleText}</div>
      {fullCovidHealthStatuses && (
        <div className="text-base font-light pb-8">
          <div className="inline-block mr-3">Change status to:</div>
          <StatusDropdown
            containerClassName="rounded border border-hs-dark-gray"
            status={newStatus}
            statusFilter={key => api.isCovidHealthStatus(key)}
            onChange={key => onStatusChange(key as api.CovidHealthStatus)}
          />
        </div>
      )}
      <div className="flex items-center border border-hs-dark-gray h-40 mb-8">
        <textarea
          autoFocus
          maxLength={255}
          className="resize-none text-sm p-5 h-full leading-normal placeholder-gray-400 flex-grow outline-none"
          onChange={onInputChange}
          value={note}
          placeholder="Add notes (optional)"
        />
      </div>
      <div className="flex justify-end pt-2.5">
        <MessageActionButton
          inline
          className="w-32"
          text="Cancel"
          theme="clear-green"
          onClick={onClose}
        />
        <MessageActionButton
          inline
          disabled={!employee || newStatus === labelKey}
          text="Confirm"
          theme="green"
          onClick={onConfirmClicked}
        />
      </div>
    </Modal>
  );
};
