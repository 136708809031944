import React, { FunctionComponent, useState } from "react";

import { t } from "@lingui/macro";
import { I18n, Trans } from "@lingui/react";
import clsx from "clsx";

import { PhoneInput } from "~/components/EmployeeCheckInRequest/PhoneInput";
import { ValidatedPhoneNumber } from "~/components/EmployeeCheckInRequest/types";
import PhoneIcon from "~/images/phone-icon.svg";
import { Loading, MessageActionButton } from "~common";

interface PreviousPhoneNumberProps {
  className?: string;
  requesting: boolean;
  error: boolean;
  onConfirm: (phone: string) => any;
}

export const PreviousPhoneNumber: FunctionComponent<
  PreviousPhoneNumberProps
> = ({ className, requesting, error, onConfirm }) => {
  const [phone, setPhone] = useState<ValidatedPhoneNumber>();

  return (
    <div
      className={clsx(
        "h-full w-full flex-grow flex flex-col justify-between",
        className
      )}
    >
      <div className="p-9 text-2xl font-bold text-hs-dark-green">
        <p>
          <Trans id="identify.previousPhone.title">
            Ok, we'll continue to use your previous number.
          </Trans>
        </p>
        <p>
          <Trans id="identify.previousPhone.summary">
            Please enter that number to access your form or survey.
          </Trans>
        </p>
      </div>
      <div className="bg-hs-light p-9 rounded-t-4xl">
        <div className="flex flex-row items-center mb-2">
          <img className="mr-4" src={PhoneIcon} alt="Phone" />
          <p className="text-2xl font-semibold text-hs-dark-green">
            <Trans id="identify.previousPhone.label">
              Previous mobile number:
            </Trans>
          </p>
        </div>

        <I18n>
          {({ i18n }) => (
            <PhoneInput
              className={clsx(
                "my-3 py-2 px-5 border rounded w-full",
                !error && "border-hs-dark-gray focus:border-hs-green",
                error && "border-hs-error-red"
              )}
              inputStyle={{ fontSize: "18px" }}
              value={phone?.value ?? ""}
              placeholder={i18n._(
                t("identify.previousPhone.placeholder")`Mobile number`
              )}
              onChange={(value, valid, international) => {
                setPhone({ value, valid, international });
              }}
              onEnter={() => phone?.valid && onConfirm(phone.international)}
            />
          )}
        </I18n>

        {error && (
          <p className="my-3 text-xs text-hs-error-red">
            <Trans id="identify.error.alreadySet">
              That number doesn’t match the number we have in your record.
              Please double check and try again.
            </Trans>
          </p>
        )}

        {requesting && <Loading />}

        <div className="pt-5 pb-3">
          <MessageActionButton
            theme="dark-green"
            disabled={!phone?.valid}
            onClick={() => phone?.valid && onConfirm(phone.international)}
            text={
              <Trans id="identify.previousPhone.confirm">Confirm number</Trans>
            }
          />
        </div>
      </div>
    </div>
  );
};
