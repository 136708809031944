import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";

export const GenericErrorText: FunctionComponent<{}> = () => (
  <div dir="auto">
    <Trans id="error.generic">
      Oops. Something went wrong. Please check your network and try again. If
      you continue to see problems, please contact TeamSense customer support.
    </Trans>
  </div>
);
