/**
 * We currently have two copies of `Attendance.tsx` in the repo with a lot of common code.
 * Please be very careful updating this file and work hard to keep both files in sync until
 * we remove the original `ts-pages/_old/Attendance.tsx` file
 **/

import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { DateTime } from "luxon";

import { MutableSessionContext } from "../lib/context/mutableSession";

import * as api from "~/api";
import { EmployeeRole } from "~/api";
import { notTeamLeader } from "~/lib/employeeRoles";
import {
  areAllSelected,
  FilterList,
  persistFilterList,
  SessionKey,
  updateFilterList
} from "~/lib/filterList";
import { useSessionStorageConfig } from "~/lib/hooks";
import { showDivisions } from "~/lib/showDivisions";
import { countsFromEvents } from "~/lib/stats";
import AttendanceLogGrid from "~/ts-components/attendance/AttendanceLogGrid";
import { BundledEventsWithSurveys } from "~/types/attendance";
import {
  AttendanceDetails,
  AttendanceHeader,
  ConfettiAnimation
} from "~attendance";
import {
  GenericErrorText,
  StatsGrid,
  TSButton,
  TSDateSelectorButton,
  TSLoading,
  TSMultiSelectCheckbox
} from "~common";
import { ExportSurveyResultsModal } from "~forms";

import "./Attendance.scss";

const DIVISION_FILTER_INCLUDES_NONE = true;
const INITIAL_DIVISION_FILTER_LIST: FilterList<api.Division> = {
  displayEmpty: DIVISION_FILTER_INCLUDES_NONE,
  items: {}
};

export const Attendance: FC = () => {
  const [config, setConfig] = useSessionStorageConfig();
  const { session } = useContext(MutableSessionContext);
  const history = useHistory();
  const shouldShowDivisions = showDivisions(session);
  const showAttendanceTrendsAndReports = notTeamLeader(session.employee?.role);
  const [selectedDate, setSelectedDate] = useState(
    DateTime.fromISO(config.date) ?? DateTime.local()
  );
  const [events, setEvents] = useState<api.EmployeeEventWithEmployee[]>();
  const [employeesWithEvents, setEmployeesWithEvents] =
    useState<api.SimpleEmployeeWithEvents[]>();
  const [filteredEmployeesWithEvents, setFilteredEmployeesWithEvents] =
    useState<api.SimpleEmployeeWithEvents[]>();
  const [selectedEmployee, setSelectedEmployee] =
    useState<api.SimpleEmployeeWithEvents>();
  const [selectedEventsAndSurveys, setSelectedEventsAndSurveys] =
    useState<BundledEventsWithSurveys[]>();
  const [toggleExportModal, setToggleExportModal] = useState(false);
  const [filteredDivisions, setFilteredDivisions] =
    useState<FilterList<api.Division>>();
  const [exportModalSurveyId, setExportModalSurveyId] = useState<string>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showZeroAbsenceAnimation, setShowZeroAbsenceAnimation] =
    useState(false);
  const [isFiltering, setIsFiltering] = useState<boolean>(true);

  const handleSelectEmployee = (
    employee: api.SimpleEmployeeWithEvents
  ): void => {
    if (!employee) {
      return;
    }

    setSelectedEmployee(employee);
    handleShow();
  };

  const handleClose = (): void => {
    setShowDetailsModal(false);
  };
  const handleShow = (): void => {
    setShowDetailsModal(true);
  };
  const isTablet = useMediaQuery({ maxWidth: 1023 });
  // handlers
  const onClickStatus = (key: string): void => {
    const newEmployeesWithEvents =
      key === ""
        ? employeesWithEvents
        : employeesWithEvents?.filter(employee =>
            employee.events.some(event => event.label.name === key)
          );
    setConfig({ ...config, key });
    setIsFiltering(true);
    setFilteredEmployeesWithEvents(newEmployeesWithEvents);
  };

  const handleSelectedDate = (date: DateTime): void => {
    setSelectedDate(date);
    setConfig({ ...config, date });
    setEvents(undefined);
  };

  const setEmployeesWithEventsData = useCallback(
    (newEmployeesWithEvents: typeof employeesWithEvents) => {
      setEmployeesWithEvents(newEmployeesWithEvents);
      setFilteredEmployeesWithEvents(newEmployeesWithEvents);
    },
    []
  );

  /*
   * Change the sync status locally for a specific event
   * This entails finding that event in both of our state structures,
   * employeesWithEvents and selectedEmployee and swapping that event out
   * with a clone of that event with the sync_status value changed
   */
  const setLocalEventSyncStatus = (
    eventId: string,
    sync_status: api.AlteredEventSyncStatus | undefined,
    data?: api.BaseEmployeeEvent
  ): void => {
    if (!employeesWithEvents) {
      return;
    }
    // Find and update the event in employeesWithEvents:
    const newEmployeesWithEvents = employeesWithEvents.map(employee => {
      const index = employee.events.findIndex(({ id }) => id === eventId);
      if (index === -1) {
        return employee;
      }
      return {
        ...employee,
        events: [
          ...employee.events.slice(0, index),
          {
            ...employee.events[index],
            sync_status: data?.sync_status ?? sync_status,
            sync_timestamp:
              data?.sync_timestamp ?? employee.events[index].sync_timestamp,
            last_sync_by:
              data?.last_sync_by ?? employee.events[index].last_sync_by
          },
          ...employee.events.slice(index + 1)
        ]
      };
    });
    setEmployeesWithEventsData(newEmployeesWithEvents);

    // Find and update the event in selectedEmployee (if necessary):
    if (!selectedEmployee) {
      return;
    }
    const eventIndex = selectedEmployee.events.findIndex(
      ({ id }) => id === eventId
    );
    if (eventIndex === -1) {
      return;
    }
    setSelectedEmployee({
      ...selectedEmployee,
      events: [
        ...selectedEmployee.events.slice(0, eventIndex),
        {
          ...selectedEmployee.events[eventIndex],
          sync_status: data?.sync_status ?? sync_status,
          sync_timestamp:
            data?.sync_timestamp ??
            selectedEmployee.events[eventIndex].sync_timestamp,
          last_sync_by:
            data?.last_sync_by ??
            selectedEmployee.events[eventIndex].last_sync_by
        },
        ...selectedEmployee.events.slice(eventIndex + 1)
      ]
    });
  };

  const onEventStatusSync = async (
    eventId: string,
    status: api.AlteredEventSyncStatus
  ): Promise<void> => {
    if (!session.company || !selectedEmployee) {
      return;
    }
    setLocalEventSyncStatus(eventId, undefined);
    let response: api.APIResponse<api.BaseEmployeeEvent> | undefined;
    try {
      response = await api.updateEventSyncStatus(
        session.company.id,
        selectedEmployee.id,
        eventId,
        status
      );
    } catch {}
    if (response?.ok && response?.data) {
      setLocalEventSyncStatus(eventId, status, response.data);
    } else {
      setLocalEventSyncStatus(eventId, response?.ok ? status : "errored");
    }
  };

  // Effects

  // Determine whether or not to show the ZeroAttendanceModal
  useEffect(() => {
    const lastStoredZeroAbsenceDate = localStorage.getItem("lastZeroAbsence");
    const hasZeroAbsences = events && events.length === 0;
    const currentDate = DateTime.local();

    const isToday =
      currentDate.month === selectedDate.month &&
      currentDate.day === selectedDate.day &&
      currentDate.year === selectedDate.year;

    const displayedToday = (): boolean => {
      // If we don't have the date saved in local storage,
      // that means we haven't shown it before
      if (!lastStoredZeroAbsenceDate) {
        return false;
      } else {
        // Check if we updated local storage today
        // We want to show the modal once every day
        // Ideally, the first time they log in for the day
        const storedDate = DateTime.fromISO(lastStoredZeroAbsenceDate);
        return (
          currentDate.month === storedDate.month &&
          currentDate.day === storedDate.day &&
          currentDate.year === storedDate.year
        );
      }
    };

    // Show the modal if the selected date is today,
    // and if they have zero absences,
    // and we have not showed the modal today
    if (isToday && hasZeroAbsences && !displayedToday()) {
      setShowZeroAbsenceAnimation(true);
      localStorage.setItem("lastZeroAbsence", `${currentDate.toISO()}`);
    }
  }, [selectedDate, events]);

  /*
   * Once, at load time - we load the division filter list from session storage
   * (We could do this to computer the "initial" value in setState() but then we'd load from
   * session storage on every render)
   */
  useEffect(() => {
    setFilteredDivisions(
      updateFilterList(
        INITIAL_DIVISION_FILTER_LIST,
        session.divisions ?? [],
        SessionKey.attendanceDivisionFilter
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * When the selectedDate changes, load the events for that day and construct/set
   * (1) the events needed by the Stats component
   * (2) the employeesWithEvents needed by the AttendanceLogList component
   * (3) the selectedEmployee needed by the AttendanceLogList and AttendanceDetails components
   */
  useEffect(() => {
    const loadEvents = async (): Promise<void> => {
      if (!session.company || !filteredDivisions) {
        return;
      }
      setExportModalSurveyId(session.company.id);
      setSelectedEmployee(undefined);
      setEmployeesWithEventsData(undefined);
      setEvents(undefined);
      let response:
        | api.APIResponse<api.EmployeeEventWithEmployee[]>
        | undefined;
      try {
        const dtEnd = selectedDate.endOf("day");
        const dtStart = selectedDate.startOf("day");
        let filterDivisionIds: string[] | undefined;
        if (!areAllSelected(filteredDivisions, DIVISION_FILTER_INCLUDES_NONE)) {
          filterDivisionIds = (session.divisions ?? [])
            .map(({ id }) => id)
            .filter(id => filteredDivisions.items[id].selected);
          if (DIVISION_FILTER_INCLUDES_NONE && filteredDivisions.displayEmpty) {
            filterDivisionIds.push("None");
          }
        }
        if (filterDivisionIds) {
          persistFilterList(
            filteredDivisions,
            filterDivisionIds,
            DIVISION_FILTER_INCLUDES_NONE,
            SessionKey.attendanceDivisionFilter
          );
        } else {
          sessionStorage.removeItem(SessionKey.attendanceDivisionFilter);
        }
        if (filterDivisionIds?.length === 0) {
          // When we have an empty array of division filters, don't call the API
          // Just use a hardcoded empty response since we have no way to express this
          // in the API and no events can be returned in this case
          response = {
            ok: true,
            status: 200,
            data: []
          };
        } else {
          response = await api.retrieveEmployeeEvents(
            session.company.id,
            dtStart.toISO(),
            dtEnd.toISO(),
            filterDivisionIds,
            {
              exclude: [api.LabelSet.PointsNotification]
            }
          );
        }
      } catch {}

      if (response?.ok) {
        const filteredEvents = response.data.filter(
          ({ started, ended, employee: { timezone }, label: { name } }) => {
            // For the Attendance Pane, we specifically want to exclude C19 Clear:
            if (name === "clear") {
              return false;
            }
            // Exclude any events that start (in the employee's timezone) after
            // the selected day:
            if (
              DateTime.fromISO(started).setZone(timezone).setZone("local", {
                keepLocalTime: true
              }) > selectedDate.endOf("day")
            ) {
              return false;
            }
            // Exclude any events that end (in the employee's timezone) on or
            // before 12:00AM on the selected day:
            if (
              ended &&
              DateTime.fromISO(ended).setZone(timezone).setZone("local", {
                keepLocalTime: true
              }) <= selectedDate.startOf("day")
            ) {
              return false;
            }
            return true;
          }
        );
        setEvents(filteredEvents);
        // Transform api.EmployeeEventWithEmployee[] into api.SimpleEmployeeWithEvents[]
        // while sorting based on earliest event start date per employee:
        const employeeWithEventsMap: {
          [k: string]: api.SimpleEmployeeWithEvents;
        } = {};
        filteredEvents.forEach(event => {
          const existingMapEntry = employeeWithEventsMap[event.employee.id];
          if (existingMapEntry) {
            existingMapEntry.events.push(event);
          } else {
            employeeWithEventsMap[event.employee.id] = {
              ...event.employee,
              events: [event]
            };
          }
        });
        const sortedEmployeesWithEvents = Object.values(
          employeeWithEventsMap
        ).sort((emp1, emp2) => {
          const created1 = DateTime.max(
            ...emp1.events.map(({ created }) => DateTime.fromISO(created))
          );
          const created2 = DateTime.max(
            ...emp2.events.map(({ created }) => DateTime.fromISO(created))
          );
          return created2.toMillis() + created1.toMillis();
        });
        setEmployeesWithEventsData(sortedEmployeesWithEvents);
        setSelectedEmployee(sortedEmployeesWithEvents[0]);
        setIsFiltering(true);
      } else {
        toast.error(<GenericErrorText />);
      }
    };

    loadEvents();
  }, [
    session.company,
    selectedDate,
    session.divisions,
    filteredDivisions,
    setEmployeesWithEventsData
  ]);

  const showAttendanceSurveyResponses = (): boolean => {
    switch (session.employee?.role) {
      case EmployeeRole.employee:
        return false;
      case EmployeeRole.teamLeader:
        return session.features.enabled_flags.includes("tl_survey_responses");
      default:
        return true;
    }
  };

  /*
   * When the selectedEmployee changes, load the surveys as needed and construct/set
   * the selectedEventsAndSurveys needed by the AttendanceDetails component
   */
  useEffect(() => {
    const loadSurveysForEmployee = async (): Promise<void> => {
      if (
        !session.company ||
        !selectedEmployee ||
        !showAttendanceSurveyResponses()
      ) {
        return;
      }

      // If the selected employee id hasn't changed but we are being triggered, it is likely
      // to be related to a local update to an event's sync_status. We optimize this by simply
      // replacing the events in selectedEventsAndSurveys without fetching surveys again
      if (
        selectedEventsAndSurveys &&
        selectedEventsAndSurveys[0]?.events?.[0].employee.id ===
          selectedEmployee.id
      ) {
        const newSelectedEventsWithSurveys = selectedEventsAndSurveys.map(
          eventsWithSurveys => ({
            ...eventsWithSurveys,
            events: eventsWithSurveys.events.map(({ id }) =>
              selectedEmployee.events.find(curEvent => curEvent.id === id)
            )
          })
        );
        // Verify that we matched every event using the find() calls above. If we did not, we will
        // find some events marked as `undefined` in the events list.
        if (
          !newSelectedEventsWithSurveys.some(({ events }) =>
            events.includes(undefined)
          )
        ) {
          // All events matched. Update our state variable and then perform an early exit so that we
          // don't clear all of the data in selectedEventsAndSurveys and make unnecessary refetch calls
          setSelectedEventsAndSurveys(
            newSelectedEventsWithSurveys as unknown as BundledEventsWithSurveys[]
          );
          return;
        }
      }

      // Clear selectedEventsAndSurveys to show loading UX before we make API calls...
      setSelectedEventsAndSurveys(undefined);
      // Fetch the surveys for each of the events:
      const companyId = session.company.id;
      const surveyStates: (api.SurveyState | undefined)[] = await Promise.all(
        selectedEmployee.events.map(async event => {
          let response: api.APIResponse<api.SurveyState> | undefined;
          try {
            response = await api.retrieveEmployeeEventSurvey(
              companyId,
              event.id
            );
          } catch {}
          return response?.ok ? response.data : undefined;
        })
      );

      // Generate bundledEvents in a form that AttendanceDetails can consume:
      const bundledEvents: BundledEventsWithSurveys[] = [];
      selectedEmployee.events.forEach((event, index) => {
        const survey = surveyStates[index];
        const surveyId = survey?.id;
        if (surveyId) {
          const existingEntryWithSameSurvey = bundledEvents.find(
            ({ survey: entrySurvey }) => entrySurvey?.id === surveyId
          );
          if (existingEntryWithSameSurvey) {
            existingEntryWithSameSurvey.events.push(event);
            return;
          }
        }
        bundledEvents.push({
          events: [event],
          survey
        });
      });

      setSelectedEventsAndSurveys(bundledEvents);
    };
    loadSurveysForEmployee();

    // Disabling react-hooks/exhaustive-deps to avoid a circular dependency with selectedEventsAndSurveys
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee, session.company]);

  // For the Attendance Pane, we specifically want to exclude C19 Clear:
  const counts = countsFromEvents(session.labels, events, ["clear"]);
  const currentKey =
    counts && config?.key && counts?.[config.key] > 0 ? config.key : "";

  const handleAttendanceInsightsRoute = (): void => {
    history.push("/dashboard/attendance-insights");
  };

  const attendanceTable = (
    <AttendanceLogGrid
      employees={filteredEmployeesWithEvents}
      selectedEmployeeId={selectedEmployee?.id}
      onSelectEmployee={handleSelectEmployee}
      selectedDate={selectedDate}
      isFiltering={isFiltering}
      setIsFiltering={setIsFiltering}
      allowInteractions={showAttendanceSurveyResponses()}
    />
  );

  // if config.key is set, filter the employeesWithEvents to only include employees
  // with events that match the config.key
  useEffect(() => {
    if (!config.key || !employeesWithEvents || !isFiltering) {
      return;
    }

    if (currentKey) {
      const newEmployeesWithEvents = employeesWithEvents.filter(employee =>
        employee.events.some(event => event.label.name === currentKey)
      );
      setIsFiltering(true);
      setFilteredEmployeesWithEvents(newEmployeesWithEvents);
    }
  }, [config.key, currentKey, employeesWithEvents, isFiltering]);

  return (
    <div className="ts">
      <Container fluid className="standard-page">
        <Row>
          <Col sm="12" md="3">
            <h1>Attendance</h1>
          </Col>
          <Col className="attendance-actions" sm="12" md="9">
            <div>
              <TSDateSelectorButton
                selectedDate={selectedDate}
                onDateSelected={handleSelectedDate}
              />
            </div>
            {shouldShowDivisions && (
              <TSMultiSelectCheckbox
                titlePrefix="Divisions"
                variant="dropdown"
                displayNone={DIVISION_FILTER_INCLUDES_NONE}
                filterList={filteredDivisions ?? INITIAL_DIVISION_FILTER_LIST}
                onChangeFilterList={setFilteredDivisions}
              />
            )}
            {showAttendanceTrendsAndReports && (
              <div>
                <TSButton
                  variant="primary"
                  className="attendance-export-button"
                  onClick={() => setToggleExportModal(true)}
                >
                  Export Attendance Report
                </TSButton>
              </div>
            )}
          </Col>
        </Row>

        <Card className="content-block">
          <div className="attendance-page-header">
            <AttendanceHeader
              numEmployees={employeesWithEvents?.length}
              selectedDate={selectedDate}
            />
            {showAttendanceTrendsAndReports && (
              <div>
                <TSButton
                  endIcon="icon-bulb"
                  variant="link"
                  onClick={handleAttendanceInsightsRoute}
                >
                  View Status Trends
                </TSButton>
              </div>
            )}
          </div>
          {counts ? (
            <StatsGrid counts={counts} statClickHandler={onClickStatus} />
          ) : (
            <TSLoading delaySeconds={1} />
          )}
        </Card>

        <Card className="content-block">
          <div className="attendance-page-header">
            <h1>Attendance Log</h1>
            {showAttendanceTrendsAndReports && (
              <div>
                <TSButton
                  variant="link"
                  endIcon="icon-trendup"
                  onClick={handleAttendanceInsightsRoute}
                >
                  View Absence Trends
                </TSButton>
              </div>
            )}
          </div>
          {showAttendanceSurveyResponses() && (
            <Row>
              <Col md="12" lg={isTablet ? "12" : "7"}>
                {attendanceTable}
              </Col>
              {!isTablet && (
                <Col md="6" lg="5">
                  <div className="sticky top-16">
                    {(employeesWithEvents ?? []).length > 0 && (
                      <AttendanceDetails
                        employee={selectedEmployee}
                        eventsAndSurveys={selectedEventsAndSurveys}
                        onEventStatusSync={onEventStatusSync}
                      />
                    )}
                  </div>
                </Col>
              )}
            </Row>
          )}

          {!showAttendanceSurveyResponses() && <Row> {attendanceTable}</Row>}
          {isTablet && showAttendanceSurveyResponses() && (
            <Modal
              show={showDetailsModal}
              onHide={handleClose}
              container={document.getElementById("ts-modal")}
              centered
            >
              <Modal.Body>
                <AttendanceDetails
                  employee={selectedEmployee}
                  eventsAndSurveys={selectedEventsAndSurveys}
                  onEventStatusSync={onEventStatusSync}
                />
              </Modal.Body>
              <Modal.Footer>
                <TSButton variant="link" onClick={handleClose}>
                  Close
                </TSButton>
              </Modal.Footer>
            </Modal>
          )}
        </Card>

        {toggleExportModal && (
          <ExportSurveyResultsModal
            allowFutureDates
            handleClose={() => setToggleExportModal(false)}
            exportModalSurveyId={exportModalSurveyId}
            exportType="attendance"
          />
        )}
      </Container>
      {showZeroAbsenceAnimation && <ConfettiAnimation />}
    </div>
  );
};
