import React, { FunctionComponent } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import {
  ContentCard,
  InfiniteScrollLoadingSpinner,
  ListGrid,
  ListRow
} from "~common";

const DateField: FunctionComponent<{ date: api.ISODateTime }> = ({ date }) => {
  const dt = DateTime.fromISO(date);
  let dateShortString = "";
  let timeSimpleString = "";
  try {
    dateShortString = dt.toLocaleString(DateTime.DATE_SHORT);
    timeSimpleString = dt.toLocaleString(DateTime.TIME_SIMPLE);
  } catch (e) {
    dateShortString = dt.toLocaleString({
      ...DateTime.DATE_SHORT,
      locale: "en-US"
    });
    timeSimpleString = dt.toLocaleString({
      ...DateTime.TIME_SIMPLE,
      locale: "en-US"
    });
  }
  return (
    <div className="mx-1 flex flex-col space-y-2.5 h-20 justify-center">
      <div className="font-semibold">{dateShortString}</div>
      <div className="text-xs text-hs-medium-dark-gray">{timeSimpleString}</div>
    </div>
  );
};

const TextColumn: FunctionComponent<{
  text: string;
}> = ({ text }) => <div className="mx-1 line-clamp-3">{text}</div>;

const NoteBodyColumn: FunctionComponent<{
  body: string;
}> = ({ body }) => (
  <div className="ml-4 mr-1 whitespace-pre-wrap line-clamp-3 font-semibold">
    {body}
  </div>
);

interface EmployeeNotesListProps {
  notes?: api.Note[];
  onClick: (note: api.Note) => any;
}

const COL_SPANS = [16, 10, 6];
const EMPLOYEE_NOTE_ROW_CLASS_NAME =
  "py-1 bg-white text-base tracking-wide cursor-pointer";

export const EmployeeNotesList: FunctionComponent<EmployeeNotesListProps> = ({
  notes,
  onClick
}) => {
  const headings = [
    <div key="note" className="ml-4 mr-1 uppercase">
      Note
    </div>,
    <div key="updatedBy" className="mx-1 uppercase">
      Updated By
    </div>,
    <div key="updated" className="mx-1 uppercase">
      Updated
    </div>
  ];

  const skeletonRecord = COL_SPANS.map((span, index) => (
    <Skeleton key={`skeleton-${index}`} width={span * 30} />
  ));

  return (
    <ContentCard outerWrapperClassName="p-0 mt-0 mb-8 relative h-full">
      <ListGrid className="sticky top-16">
        <ListRow
          record={headings}
          colSpans={COL_SPANS}
          className="py-3 border-gray-200 bg-gray-50 select-none text-left text-xs leading-4 font-medium tracking-wider"
        />
      </ListGrid>
      <InfiniteScroll
        dataLength={0}
        hasMore={false}
        next={() => 0}
        loader={<InfiniteScrollLoadingSpinner />}
      >
        <ListGrid>
          {!notes &&
            skeletonRecord.map((_, index) => (
              <ListRow
                className={clsx(EMPLOYEE_NOTE_ROW_CLASS_NAME, "h-20")}
                key={`skeleton-${index}`}
                record={skeletonRecord}
                colSpans={COL_SPANS}
              />
            ))}
          {(notes ?? []).map(note => (
            <ListRow
              className={EMPLOYEE_NOTE_ROW_CLASS_NAME}
              key={note.id}
              record={[
                <NoteBodyColumn key="note" body={note.body} />,
                <TextColumn
                  key="updatedBy"
                  text={note.updated_by_name ?? note.created_by_name ?? ""}
                />,
                <DateField key="updated" date={note.updated ?? note.created} />
              ]}
              colSpans={COL_SPANS}
              onClick={() => onClick(note)}
            />
          ))}
          {notes?.length === 0 && (
            <ListRow
              className={clsx(EMPLOYEE_NOTE_ROW_CLASS_NAME, "h-20")}
              key="empty"
              record={[
                <div
                  className="ml-4 mr-1"
                  key="noLinks"
                >{`There are no notes. Click "Add Note" to create one.`}</div>
              ]}
              colSpans={[32]}
            />
          )}
        </ListGrid>
      </InfiniteScroll>
    </ContentCard>
  );
};
