import React, { FunctionComponent } from "react";

import { ValidatedString } from "./types";

import { TextInput } from "~common";

interface VisitorCheckInBodyProps {
  code?: ValidatedString;
  onCodeChange: (code?: ValidatedString) => any;
  onEnter: () => any;
}

// NOTE: there is a displayable string below that includes this
// number as an English string. These values should stay in sync.
const MIN_CODE_LENGTH = 7;
const MAX_CODE_LENGTH = 8;
const codeFormatRegex = RegExp(
  String.raw`^[a-zA-Z\-]{${MIN_CODE_LENGTH},${MAX_CODE_LENGTH}}$`
);

export const VisitorCheckInBody: FunctionComponent<VisitorCheckInBodyProps> = ({
  code,
  onCodeChange,
  onEnter
}) => (
  <>
    <div className="mt-2 pr-2 text-xs font-medium">
      Hello there! Please enter the seven-letter code of the location you are
      visiting.
    </div>
    <TextInput
      className="mt-2 py-2 px-5 wk-appearance-none rounded border border-hs-dark-gray focus-within:border-hs-green text-lg font-semibold"
      validationRegex={codeFormatRegex}
      maxLength={MAX_CODE_LENGTH}
      uppercase
      value={code?.value ?? ""}
      onChange={(value, valid) => {
        onCodeChange({ value, valid });
      }}
      onEnter={onEnter}
    />
  </>
);
