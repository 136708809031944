import React, { FunctionComponent, ReactNode } from "react";

// TODO: Combine classes into a named class and Replace with a standard button.
export const TopButton: FunctionComponent<{
  title: string | ReactNode;
  onClick: () => any;
}> = ({ title, onClick }) => (
  <button
    className="bg-hs-dark-green text-white text-base py-2 px-4 rounded hover:text-white focus:outline-none"
    onClick={onClick}
  >
    {title}
  </button>
);
