import React, { FunctionComponent } from "react";

import { useField } from "formik";

import { Field, ViewProps } from "~common";

/**
 * Wrapper for password field
 */
export const PasswordField: FunctionComponent<ViewProps> = props => {
  const [field] = useField(props.name || "field");
  return (
    <Field {...props}>
      {renderProps => (
        <input
          {...field}
          {...renderProps}
          type={props.type ?? "password"}
          data-testid="input"
        />
      )}
    </Field>
  );
};
