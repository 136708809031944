import React, { FunctionComponent, useContext, useMemo } from "react";

import * as api from "~/api";
import { Dropdown, DropdownProps } from "~/components/Dropdown/Dropdown";
import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";

interface StatusDropdownProps
  extends Omit<DropdownProps, "items" | "currentItem" | "onChangeItem"> {
  statusFilter?: (status: api.AnyStatus) => boolean;
  status?: api.AnyStatus;
  onChange: (status: api.AnyStatus) => any;
}

export const StatusDropdown: FunctionComponent<StatusDropdownProps> = props => {
  const { status, statusFilter, onChange, ...restProps } = props;
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const items = useMemo(() => {
    const labelKeys = Object.keys(session.labels);
    const keys = labelKeys.filter(statusFilter ?? (() => true));
    return keys.map(key => ({
      id: key,
      text: getLabelDisplay(key, session.labels, language)
    }));
  }, [language, session.labels, statusFilter]);

  const currentId = items.find(({ id }) => id === status) ? status : undefined;
  if (!currentId && items?.[0].id) {
    // If we don't have a valid status, immediately send an onChange()
    // to the first one in the list:
    onChange(items[0].id);
  }
  return (
    <Dropdown
      {...restProps}
      currentId={currentId}
      items={items}
      onChangeItem={item => onChange(item.id)}
    />
  );
};
