import React, {
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useCallback
} from "react";
import { Link as RouterLink } from "react-router-dom";

import clsx from "clsx";
import { isString, startsWith } from "lodash";

interface Props {
  button?: boolean;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  external?: boolean;
  rel?: string;
  target?: string;
  to: string;
}

export const Link: FunctionComponent<Props> = props => {
  const { button, children, className, disabled, external, rel, target, to } =
    props;

  const isExternal = external || (isString(to) && startsWith(to, "http"));

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (disabled) {
        event.preventDefault();
      }
    },
    [disabled]
  );

  if (isExternal) {
    return (
      <a
        href={to}
        target={target || "_blank"}
        rel={rel || "noopenner noreferrer"}
        className={clsx(
          button ? "button" : "link",
          {
            [`${button ? "button" : "link"}--disabled`]: disabled
          },
          className
        )}
        onClick={handleClick}
      >
        {children}
      </a>
    );
  } else {
    return (
      <RouterLink
        to={to}
        target={target}
        rel={rel}
        className={clsx(
          button ? "button" : "link",
          {
            [`${button ? "button" : "link"}--disabled`]: disabled
          },
          className
        )}
        onClick={handleClick}
      >
        {children}
      </RouterLink>
    );
  }
};
