import React, { FunctionComponent } from "react";

import { Link } from "../Link/Link";

import { Modal, ModalProps } from "~common";

export const VisitorInstructionsModal: FunctionComponent<ModalProps> = ({
  onClose
}) => {
  return (
    <Modal className="max-w-xl m-3 px-16 py-16 bg-hs-light" onClose={onClose}>
      <div className="text-3xl font-bold pb-8 text-hs-dark-green">
        Check-in Visitors
      </div>
      <div className="mb-5">
        Visitors can check in with TeamSense by navigating to{" "}
        <Link
          external
          to="https://go.teamsense.com"
          className="text-hs-alt-darker-gray underline"
        >
          go.teamsense.com
        </Link>{" "}
        and entering your organization's unique seven-letter code.
      </div>
      <div className="mb-5">
        Visitor records will be captured in the Visitor Log and retained for 90
        days. Details of responses will not be shared with your company - only
        the check-in results (Clear or Alert).
      </div>
      <div>Print your code and instructions for visitors here:</div>
      <div className="flex justify-end mt-10">
        <button
          className="text-base text-hs-green py-2 px-4 rounded focus:outline-none"
          onClick={onClose}
        >
          Close
        </button>
        <Link external to="/visitor-instructions">
          <button className="bg-hs-dark-green text-white text-base py-2 px-4 rounded hover:text-white focus:outline-none">
            Print Instructions
          </button>
        </Link>
      </div>
    </Modal>
  );
};
