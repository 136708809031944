import React from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";

export const Spinners = (): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <h1>Spinners</h1>
        <Row className="mb-3">
          <Col>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <Spinner animation="border" variant="primary" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spinner animation="border" />
            <Spinner animation="border" size="sm" />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
