import { CSSProperties } from "react";
import { Theme } from "react-select";

export const stylesConfig = {
  control: (styles: CSSProperties): CSSProperties => ({
    ...styles,
    padding: "4px 0",
    boxShadow: "1px"
  }),
  menuPortal: (base: CSSProperties): CSSProperties => ({
    ...base,
    zIndex: 9999
  })
};

export const themeConfig: (theme: Theme) => Theme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#F9F9F9",
    primary: "#01c16b"
  }
});

export interface Entity {
  id: string;
  name: string;
}

export interface BaseEntitiesDropdownProps {
  allEntities?: Entity[];
  placeholder?: string;
  isDisabled?: boolean;
}
