import { EmployeeRole } from "~/api";

export const notEmployee = (role: EmployeeRole | undefined): Boolean =>
  role !== EmployeeRole.employee;

export const isAdminOrOwner = (role: EmployeeRole | undefined): Boolean =>
  role === EmployeeRole.divisionAdmin ||
  role === EmployeeRole.companyWideAdmin ||
  role === EmployeeRole.owner;

export const notTeamLeader = (role: EmployeeRole | undefined): Boolean =>
  role !== EmployeeRole.teamLeader;
