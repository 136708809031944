import React, { FunctionComponent } from "react";

import { AlteredEventSyncStatus } from "~/api";
import ChangedStatus from "~/images/changed-status.svg";
import NotSyncedStatus from "~/images/not-synced-status.svg";
import SyncedStatus from "~/images/synced-status.svg";

export const AttendanceSyncStatusIcon: FunctionComponent<
  AttendanceSyncStatusIconProps
> = ({ status, ignoreChanged }) => {
  let src;
  let alt;

  switch (status) {
    case "changed":
      src = ignoreChanged ? NotSyncedStatus : ChangedStatus;
      alt = ignoreChanged ? "Status Not Synced" : "Status Changed";
      break;
    case "not_synced":
    case "errored":
      src = NotSyncedStatus;
      alt = "Status Not Synced";
      break;
    case "synced":
      src = SyncedStatus;
      alt = "Status Synced";
      break;
  }
  if (!status) {
    return <i className="fas fa-circle-notch fa-spin fa-lg" />;
  }
  return <img className="sync-status-icon" {...{ src, alt }} />;
};

interface AttendanceSyncStatusIconProps {
  status?: AlteredEventSyncStatus;
  ignoreChanged?: boolean;
}
