import { ISODateTime, StringTranslations } from "./common";
import { APIResponse, destroy, get, post, put } from "./lib";

export interface PortalLink {
  text: StringTranslations;
  url: string;
  all_company: boolean;
  division_id?: string;
}

export interface AdminPortalLinkWriter extends PortalLink {
  id: string;
}
export interface AdminPortalLink extends Required<AdminPortalLinkWriter> {
  created: ISODateTime;
  updated: ISODateTime;
}

/** Retrieve portal links for a given employee (employee portal) */
export const retrievePortalLinks = (
  company_id: string,
  options?: {
    token?: string;
  }
): Promise<APIResponse<PortalLink[]>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return get(`/companies/${company_id}/employee_portal/`, undefined, headers);
};

/** Retrieve portal links for a company (admin) */
export const retrieveCompanyPortalLinks = async (
  company_id: string,
  filterDivisionIds?: string[]
): Promise<APIResponse<AdminPortalLink[]>> => {
  return await get(`/companies/${company_id}/employee_portal_admin/`, {
    ...(filterDivisionIds
      ? { division_id__in: filterDivisionIds.join(",") }
      : {})
  });
};

/** Create portal link for a company (admin) */
export const createCompanyPortalLink = async (
  company_id: string,
  portalLink: PortalLink
): Promise<APIResponse<{}>> => {
  return await post(
    `/companies/${company_id}/employee_portal_admin/`,
    portalLink
  );
};

/** Update portal link for a company (admin) */
export const updateCompanyPortalLink = async (
  company_id: string,
  portalLink: AdminPortalLinkWriter
): Promise<APIResponse<{}>> => {
  return await put(
    `/companies/${company_id}/employee_portal_admin/${portalLink.id}/`,
    portalLink
  );
};

/** Delete portal link for a company (admin) */
export const deleteCompanyPortalLink = async (
  company_id: string,
  portalLink: AdminPortalLink
): Promise<APIResponse<{}>> => {
  return await destroy(
    `/companies/${company_id}/employee_portal_admin/${portalLink.id}/`
  );
};
