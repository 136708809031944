import React, { FunctionComponent, useEffect, useState } from "react";

import decrease from "../../images/attendance-comparison-decrease.svg";
import increase from "../../images/attendance-comparison-increase.svg";
import invalid from "../../images/attendance-comparison-invalid.svg";

import * as api from "~/api";
import { roundPercentForInsights } from "~/lib/attendanceInsights";
import { AttendanceMiniCard } from "~/ts-components/attendance/AttendanceMiniCard";
import { AttendanceTwoColumn } from "~/ts-components/attendance/AttendanceTwoColumn";

export const AbsencesCompared: FunctionComponent<AbsencesComparedProps> = ({
  isLoading,
  currentRange,
  previousRange,
  frameLength,
  frameLengthText
}) => {
  const [isValidRange, setIsValidRange] = useState(false);
  const [calendarIcon, setCalendarIcon] = useState(decrease);
  const [percentage, setPercentage] = useState<string>("");
  const [range, setRange] = useState("");
  const [verb, setVerb] = useState("");
  const [cardText, setCardText] = useState<JSX.Element>();
  const [currentAverage, setCurrentAverage] = useState<number | undefined>();
  const [previousAverage, setPreviousAverage] = useState<number | undefined>();
  const [absolutePercent, setAbsolutePercent] = useState<number | undefined>();
  const [heading, setHeading] = useState("");

  useEffect(() => {
    const currentAvg = currentRange?.totals?.summary.absencesPercent;
    const previousAvg = previousRange?.totals?.summary.absencesPercent;

    if (!(currentAvg && previousAvg)) {
      setIsValidRange(false);
    } else {
      setIsValidRange(true);
      setCurrentAverage(currentAvg);
      setPreviousAverage(previousAvg);
    }
  }, [currentRange, previousRange]);

  useEffect(() => {
    switch (frameLength) {
      case "day":
        setRange("30 days");
        break;
      case "week":
        setRange("12 weeks");
        break;
      case "month":
        setRange("12 months");
        break;
      default:
        break;
    }
  }, [frameLength]);

  // handle text
  useEffect(() => {
    const calculatePercentDifference = (prev: number, curr: number): number =>
      prev - curr;

    // we can bypass a lot if the rang is invalid
    if (frameLengthText && frameLengthText === "All Time") {
      setCalendarIcon(invalid);
      setHeading("");
      setCardText(<p>All Time filter does not allow for comparison data</p>);
      return;
    }

    if (!isValidRange) {
      setCalendarIcon(invalid);
      setHeading("--%");
      setCardText(<p>Not enough historical data to make a comparison</p>);
      return;
    }

    if (currentAverage && previousAverage) {
      setPercentage(
        roundPercentForInsights(
          calculatePercentDifference(previousAverage, currentAverage)
        )
      );

      if (absolutePercent && absolutePercent < 1) {
        setCalendarIcon(decrease);
        setHeading("On track");
        setCardText(
          <p>
            Your absence rate has{" "}
            <strong>
              remained stable at {roundPercentForInsights(currentAverage)} in
              the last {range} from {roundPercentForInsights(previousAverage)}{" "}
              in the prior {range}
            </strong>
          </p>
        );
        return;
      }

      setCalendarIcon(currentAverage >= previousAverage ? increase : decrease);

      setVerb(currentAverage > previousAverage ? "increased" : "decreased");
      setHeading(currentAverage > previousAverage ? "Uh oh…" : "Hooray!");
      setCardText(
        <p>
          Your absence rate has{" "}
          <strong>
            {verb} to {roundPercentForInsights(currentAverage)} in the last{" "}
            {range} from {roundPercentForInsights(previousAverage)} in the prior{" "}
            {range}
          </strong>
        </p>
      );
    }
  }, [
    verb,
    isValidRange,
    percentage,
    range,
    currentAverage,
    previousAverage,
    absolutePercent,
    frameLengthText
  ]);

  useEffect(() => {
    if (!percentage) {
      return;
    }

    const localPercent = percentage.startsWith("<")
      ? percentage.slice(2)
      : percentage;

    setAbsolutePercent(Math.abs(Number(localPercent.slice(0, -1))));
  }, [percentage]);

  return (
    <AttendanceMiniCard>
      <AttendanceTwoColumn
        isLoading={isLoading}
        heading={heading}
        text={cardText}
        icon={calendarIcon}
      />
    </AttendanceMiniCard>
  );
};

interface AbsencesComparedProps {
  isLoading: boolean;
  currentRange?: api.TotalMetrics;
  previousRange?: api.TotalMetrics;
  frameLength?: string;
  frameLengthText?: string;
}
