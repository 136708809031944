import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import { TermsAndPrivacy } from "./TermsAndPrivacy";

import * as api from "~/api";
import { LanguageDropdown } from "~/components/LanguageDropdown/LanguageDropdown";
import GreenRegisterWelcomeIllustration from "~/images/green-register-welcome-illustration.svg";
import { MessageActionButton } from "~common";

interface WelcomeProps {
  className?: string;
  showLanguages: boolean;
  language: string;
  company: api.Company;
  onChangeLanguage: (lang: string) => any;
  onNext: () => any;
}

export const Welcome: FunctionComponent<WelcomeProps> = ({
  className,
  showLanguages,
  language,
  company,
  onChangeLanguage,
  onNext
}) => {
  const { name: companyName } = company;
  return (
    <div
      className={clsx(
        "h-full w-full flex-grow flex flex-col justify-between",
        className
      )}
    >
      <div>
        <div className="bg-hs-light p-9 rounded-b-4xl">
          <div className="flex flex-row justify-between items-center pb-6">
            <p className="text-2xl font-bold text-hs-dark-green">
              <Trans id="register.welcome.title">Welcome!</Trans>
            </p>
            {showLanguages && (
              <div className="w-34">
                <LanguageDropdown
                  containerClassName="bg-white"
                  company={company}
                  language={language}
                  onChange={onChangeLanguage}
                />
              </div>
            )}
          </div>
          <p className="whitespace-pre-wrap text-base leading-tight text-hs-even-darker-gray">
            <Trans
              id="register.welcome.summary"
              values={{ companyName }}
              components={[<br key="1" />, <br key="2" />]}
            >
              {companyName} is working with TeamSense to help keep you safe and
              connected, starting with regular COVID-19 health surveys before
              work.
              <br />
              <br />
              We need to verify a few things with you to get started.
            </Trans>
          </p>
        </div>

        <img
          className="py-4 ml-auto"
          src={GreenRegisterWelcomeIllustration}
          alt="Welcome Illustration"
        />
      </div>

      <div>
        <div className="flex flex-row justify-center">
          <TermsAndPrivacy />
        </div>
        <div className="mt-3 mb-12 mx-7">
          <MessageActionButton
            theme="dark-green"
            onClick={onNext}
            text={
              <Trans id="register.welcome.getStarted">Let's get started</Trans>
            }
          />
        </div>
      </div>
    </div>
  );
};
