import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo
} from "react";
import { Modal, Row, Col } from "react-bootstrap";

import simplur from "simplur";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import {
  SelectAudienceOptions,
  GenericErrorText,
  SelectAudience,
  ToastContext,
  TSButton,
  Loading
} from "~common";

export const FormsSendModal: FunctionComponent<FormsSendModalInterface> = ({
  show,
  handleExit,
  template
}) => {
  const toast = useContext(ToastContext);
  const { session } = useContext(MutableSessionContext);

  const [recipientsCount, setRecipientsCount] = useState<number>();
  const [isAudienceValid, setIsAudienceValid] = useState(false);
  const [submitDirty, setSubmitDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [groups, setGroups] = useState<api.Group[]>();
  const [audience, setAudience] = useState<SelectAudienceOptions>(() => ({
    allCompany: false,
    divisions: [],
    groups: [],
    employees: [],
    directReports: []
  }));

  const messageRecipients = useMemo(() => {
    const result = [];
    if (audience.allCompany && session.company?.id) {
      result.push({ kind: "com", values: [{ id: session.company.id }] });
    }
    audience?.divisions?.forEach(({ value: id }) =>
      result.push({ kind: "div", values: [{ id }] })
    );
    audience?.groups?.forEach(({ value: id }) =>
      result.push({ kind: "grp", values: [{ id }] })
    );
    audience?.employees?.forEach(({ value: id }) =>
      result.push({ kind: "emp", values: [{ id }] })
    );
    audience?.directReports?.forEach(({ value: id }) =>
      result.push({ kind: "rpt", values: [{ id }] })
    );
    return result as api.MessageRecipient[];
  }, [
    audience?.allCompany,
    audience?.divisions,
    audience?.employees,
    audience?.groups,
    audience?.directReports,
    session.company?.id
  ]);

  // Use Effects
  useEffect(() => {
    const loadGroups = async (): Promise<void> => {
      if (!session.company) {
        return;
      }
      let response: api.APIResponse<api.Group[]> | undefined;
      try {
        response = await api.retrieveGroups(session.company.id);
      } catch {}
      if (response?.ok) {
        setGroups(response.data);
      } else {
        toast.show({ variant: "danger", message: <GenericErrorText /> });
      }
    };

    loadGroups();
  }, [session.company, toast]);

  useEffect(() => {
    const fetchRecipientsCount = async (): Promise<void> => {
      if (!messageRecipients.length) {
        setRecipientsCount(0);
        return;
      }
      let selectedRecipientsCountResponse:
        | api.APIResponse<api.Count>
        | undefined;

      setRecipientsCount(undefined);
      try {
        selectedRecipientsCountResponse = await api.countMessageRecipients(
          messageRecipients
        );
      } catch {}
      if (selectedRecipientsCountResponse?.ok) {
        setRecipientsCount(selectedRecipientsCountResponse.data.count);
      } else {
        toast.show({ variant: "danger", message: <GenericErrorText /> });
      }
    };

    fetchRecipientsCount();
  }, [messageRecipients, toast]);

  const handleCloseModal = useCallback((): void => {
    handleExit();
    setSubmitDirty(false);
    setAudience({});
  }, [handleExit]);

  const handleSend = useCallback(async (): Promise<void> => {
    setSubmitDirty(true);
    if (
      !session?.company?.id ||
      !messageRecipients?.length ||
      !template ||
      !isAudienceValid
    ) {
      return;
    }
    setSaving(true);
    let response: api.APIResponse<{}> | undefined;
    try {
      response = await api.sendSurvey({
        template_id: template.id,
        company_id: session?.company?.id,
        recipients_json: messageRecipients
      });
    } catch {}
    setSaving(false);

    if (response?.ok) {
      handleCloseModal();
      toast.show({ variant: "success", message: "Your form has sent." });
    } else {
      toast.show({ variant: "danger", message: <GenericErrorText /> });
    }
  }, [
    template,
    session.company,
    messageRecipients,
    isAudienceValid,
    handleCloseModal,
    toast
  ]);

  const audienceChangeHandler = (
    audience: SelectAudienceOptions,
    isValid: boolean
  ): void => {
    setAudience(audience);
    setIsAudienceValid(isValid);
  };

  // Button Text
  const confirmPrefix = "Send to";
  const confirmSuffix =
    recipientsCount === undefined
      ? "..."
      : simplur`${recipientsCount} [Person|People]`;
  const confirmButtonTitle = `${confirmPrefix} ${confirmSuffix}`;

  return (
    <>
      <Modal
        show={show}
        container={document.getElementById("ts-modal")}
        centered
        onHide={handleCloseModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Send Form: ${template.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h2 className="h1">Select Recipients</h2>
              <SelectAudience
                audience={audience}
                showErrors={submitDirty && !isAudienceValid}
                audienceChangeHandler={audienceChangeHandler}
                checkboxPrepend="Send to"
                triggerLoad={false}
                fullGroupList={groups}
                requiredMessage="Recipients are required"
                enableAllCompany={session.is_company_admin}
                enableDivisions={Boolean(session.divisions)}
                enableEmployees
                enableGroups
                isAnonymous={template.is_anonymous}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <TSButton onClick={handleSend}>{confirmButtonTitle}</TSButton>
        </Modal.Footer>
        {saving && <Loading />}
      </Modal>
    </>
  );
};

interface FormsSendModalInterface {
  show: boolean;
  template: api.SurveySummary;
  handleExit: () => any;
}
