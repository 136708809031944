import React, { cloneElement, useContext } from "react";

import { PermissionContext } from "~/lib/context/";

type Props = {
  to: string | string[];
  fallback?: React.ReactNode;
  children: React.ReactElement;
  disabled?: boolean;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({
  to,
  fallback,
  children,
  disabled = false
}) => {
  // We "connect" to the provider thanks to the PermissionContext
  const { isAllowedTo } = useContext(PermissionContext);

  // If the user has that permission, render the children
  if (isAllowedTo(to)) {
    return <>{children}</>;
  }

  // If the user does not have the permission, but disabled is True
  // Then we render the children with disabled functionality
  if (disabled && children) {
    return cloneElement(children, { disabled: true });
  }

  // Otherwise, render the fallback or nothing
  return <>{fallback}</>;
};

export default Restricted;
