import React, { FunctionComponent, ReactNode } from "react";

import clsx from "clsx";

export interface ModalProps {
  className?: string;
  onClose: () => void;
  children?: ReactNode;
}

export const Modal: FunctionComponent<ModalProps> = props => {
  const { children, className, onClose } = props;

  return (
    <div
      className={clsx("tailwind-modal-overlay")}
      onMouseDown={onClose}
      data-testid="background"
      dir="auto"
    >
      <div
        className={clsx("tailwind-modal", className)}
        onMouseDown={(event): any => event.stopPropagation()}
        data-testid="modal"
      >
        {children}
      </div>
    </div>
  );
};
