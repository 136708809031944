export const hexToRgb = (hex: string): number[] => {
  const rgb = hex.replace("#", "");
  const hexToInt = (hex: string): number => parseInt(hex, 16);
  const [r, g, b] = [
    hexToInt(rgb.substring(0, 2)),
    hexToInt(rgb.substring(2, 4)),
    hexToInt(rgb.substring(4, 6))
  ];
  return [r, g, b];
};

export const rgbToHex = (r: number, g: number, b: number): string => {
  const intToHex = (c: number): string => c.toString(16).padStart(2, "0");
  return `#${intToHex(r)}${intToHex(g)}${intToHex(b)}`;
};

export const hexToRgba = (hex: string, alpha: number): string => {
  const [r, g, b] = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getColor = (
  paletteColor: string | undefined,
  palette: any,
  defaultColor = "none"
): string => {
  if (!paletteColor) {
    return defaultColor;
  }
  return palette[paletteColor] || defaultColor;
};

export const isStorybook = process.env.STORYBOOK;
