import React, { FunctionComponent, useContext } from "react";

import { DateTime } from "luxon";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { useConversationTranslation } from "~/lib/hooks";

export const PortalLinkRow: FunctionComponent<PortalLinkRowProps> = ({
  portalLink,
  showDivisionColumn,
  onClick
}) => {
  const { session } = useContext(MutableSessionContext);
  const displayText = useConversationTranslation(portalLink.text);

  const divisionName =
    showDivisionColumn &&
    (session.divisions ?? []).find(({ id }) => id === portalLink.division_id)
      ?.name;
  const dt = DateTime.fromISO(portalLink.updated);
  let dateShort = "";
  let timeSimple = "";
  try {
    dateShort = dt.toLocaleString(DateTime.DATE_SHORT);
    timeSimple = dt.toLocaleString(DateTime.TIME_SIMPLE);
  } catch {
    dateShort = dt.toLocaleString({ ...DateTime.DATE_SHORT, locale: "en-US" });
    timeSimple = dt.toLocaleString({
      ...DateTime.TIME_SIMPLE,
      locale: "en-US"
    });
  }

  return (
    <tr
      onClick={() => onClick(portalLink)}
      className="portal-row hover-pointer"
    >
      <td>
        <h3>{displayText}</h3>
      </td>
      <td>
        <h3>{portalLink.url}</h3>
      </td>
      {showDivisionColumn && (
        <td>
          <h3>{portalLink.all_company ? "All Company" : divisionName}</h3>
        </td>
      )}
      <td>
        <h3>{dateShort}</h3>
        <h6 className="text-muted">{timeSimple}</h6>
      </td>
    </tr>
  );
};

interface PortalLinkRowProps {
  portalLink: api.AdminPortalLink;
  showDivisionColumn: boolean;
  onClick: (portalLink: api.AdminPortalLink) => any;
}
