import React, { FunctionComponent } from "react";

import clsx from "clsx";

import InfoIcon from "~/images/info-icon.svg";
import MinusIcon from "~/images/minus-icon.svg";
import PlusIcon from "~/images/plus-icon.svg";

export const TSCollapse: FunctionComponent<CollapseProps> = ({
  color = "#dfe2e1",
  isOpen = false,
  isFirst = false,
  isLast = false,
  onClick,
  surveyMode = false,
  showActions = true,
  children
}) => {
  // Define a gradient string for the border
  let gradient;

  if (isFirst) {
    gradient = "linear-gradient(to bottom, transparent 0%, #dfe2e1 40%)";
  } else if (isLast) {
    gradient = "linear-gradient(to bottom, #dfe2e1 35%, transparent 0%)";
  } else {
    gradient = "#dfe2e1";
  }

  return (
    <div
      className={clsx(
        "relative",
        surveyMode && showActions && !isLast && "cursor-pointer"
      )}
      onClick={() => {
        surveyMode && showActions && onClick?.();
      }}
    >
      <div
        className={clsx(
          "flex flex-start",
          surveyMode && !isLast && "flex-start justify-between text-left",
          surveyMode && showActions && !isLast && "flex-row-reverse"
        )}
      >
        <div
          className={clsx(
            "flex items-center justify-center rounded-lg text-white",
            "cursor-pointer",
            "w-8 h-8 ",
            "lg:mt-4 -ml-4",
            "min-w-[32px]",
            surveyMode && isLast && "self-center ml-0 mt-0 mr-4",
            !showActions && "hidden"
          )}
          onClick={onClick}
          style={{
            backgroundColor: color,
            position: "relative",
            zIndex: 1 // Ensure the pseudo-element is rendered behind the content
          }}
        >
          {isLast ? (
            <img src={InfoIcon} alt="info-icon" />
          ) : (
            <img src={isOpen ? MinusIcon : PlusIcon} alt="collapse" />
          )}
        </div>
        {children}
      </div>
      {!surveyMode && (
        <div
          className="absolute left-0 top-0 bottom-0 w-3"
          style={{
            background: gradient,
            zIndex: 0, // Render behind the main content
            width: "3px"
          }}
        />
      )}
    </div>
  );
};

interface CollapseProps {
  color?: string;
  isOpen?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  children?: React.ReactNode;
  surveyMode?: boolean;
  showActions?: boolean;
  onClick?: () => void;
}
