import React, { FunctionComponent } from "react";
import { Route } from "react-router-dom";

import { RouteProps } from "./routeProps";

import { AccessDenied } from "~/ts-components/common/errors/AccessDenied";

export const PrivateRoute: FunctionComponent<RouteProps> = ({
  component: Component,
  path: Path,
  isAllowed,
  ...config
}) => {
  if (!isAllowed) {
    return (
      <div className="ts">
        <AccessDenied />
      </div>
    );
  }

  return <Route path={Path} component={Component} {...config} />;
};
