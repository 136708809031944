import React, { CSSProperties, FunctionComponent } from "react";
import PhoneInput2 from "react-phone-input-2";

import PhoneNumber from "awesome-phonenumber";
import clsx from "clsx";

import "./PhoneInput.scss";

// This should be exported by react-phone-input-2, but it's not:
interface PICountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

interface PhoneInputProps {
  autoFocus?: boolean;
  className?: string;
  inputClassName?: string;
  inputStyle?: CSSProperties;
  tabIndex?: number;
  id?: string;
  value: string;
  readOnly?: boolean;
  placeholder?: string;
  onChange: (value: string, valid: boolean, international: string) => any;
  onEnter: () => any;
}

export const PhoneInput: FunctionComponent<PhoneInputProps> = ({
  autoFocus,
  className,
  inputClassName,
  inputStyle,
  tabIndex,
  id,
  onChange,
  readOnly,
  onEnter,
  value
}) => {
  const onPhoneInputChange = (
    newValue: string,
    data: PICountryData | {}
  ): void => {
    const pn = new PhoneNumber(
      newValue,
      "countryCode" in data ? data.countryCode : undefined
    );
    onChange(newValue, pn.isValid(), pn.getNumber("e164") ?? newValue);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  // TODO: support placeholder
  // TODO: support localization of country list

  return (
    <div className="phone-input-wrapper">
      <PhoneInput2
        preferredCountries={["us", "ca", "mx"]}
        preserveOrder={["preferredCountries"]}
        countryCodeEditable={false}
        country="us"
        containerClass={clsx(className, "pl-0 bg-white")}
        inputClass={clsx(inputClassName, "font-sans p-0")}
        dropdownClass="font-sans"
        inputStyle={{
          borderColor: "transparent",
          width: "inherit",
          height: "inherit",
          ...inputStyle
        }}
        buttonStyle={{
          borderLeft: "hidden",
          left: "1px",
          borderTop: "hidden",
          top: "1px",
          borderBottom: "hidden",
          bottom: "1px"
        }}
        inputProps={{
          id,
          readOnly,
          autoFocus,
          tabIndex,
          onKeyPress
        }}
        value={value}
        onChange={onPhoneInputChange}
      />
    </div>
  );
};
