import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { Modal, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { DateTime } from "luxon";

import * as api from "~/api";
import {
  AttendanceResponse,
  endDate,
  FrameLength
} from "~/lib/attendanceInsights";
import { MutableSessionContext } from "~/lib/context";
import { useLinguiLocale, useWindowDimensions } from "~/lib/hooks";
import { GenericErrorText, TSButton, TSInput, TSPagination } from "~common";

import "./PerfectAttendanceModal.scss";

export const PerfectAttendanceModal: FunctionComponent<
  PerfectAttendanceModalProps
> = ({
  showModal,
  handleExit,
  timespan,
  startDate,
  frameLength,
  labelList,
  divisionList
}) => {
  const LIMIT = 10;
  const { session } = useContext(MutableSessionContext);
  const skeletonArray = new Array(LIMIT).fill(0);
  const locale = useLinguiLocale();
  const { width } = useWindowDimensions();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState<api.InsightsEmployee[] | undefined>(
    undefined
  );
  const [count, setCount] = useState<number | undefined>();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!showModal) {
      return;
    }

    setIsLoading(true);

    const getPerfectAttendance = async (): Promise<void> => {
      if (!session?.company?.id || !startDate || !frameLength) {
        return;
      }
      let response: AttendanceResponse;
      try {
        response = await api.retrieveAttendanceEmployeeInsights({
          start: startDate,
          end: endDate,
          frameLength,
          metricsLevel: "perfectAttendance",
          labelList,
          divisionList,
          labels: 1,
          limit: LIMIT,
          page,
          searchNameOrId: searchTerm
        });
      } catch {}
      if (response?.ok) {
        const insightsResponse =
          response as api.APISuccessResponse<api.AttendanceInsightsEmployeeResponse>;
        setResults(insightsResponse.data.results);
        setCount(insightsResponse.data.count);
      } else {
        toast.error(<GenericErrorText />);
      }
    };

    getPerfectAttendance().then(() => {
      setIsLoading(false);
    });
  }, [
    showModal,
    startDate,
    frameLength,
    page,
    labelList,
    divisionList,
    session,
    searchTerm
  ]);

  const handlePageChange = (page: number): void => {
    setIsLoading(true);
    setPage(page);
  };

  const handleSearchChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setPage(1);
    setSearchTerm(event.target.value);
  };

  const renderFromDate = (dateSince: string): JSX.Element => {
    let toRenderFromDate = "";
    try {
      toRenderFromDate = dateSince
        ? DateTime.fromISO(dateSince).setLocale(locale).toLocaleString()
        : "No Absences Found";
    } catch (e) {
      toRenderFromDate = dateSince
        ? DateTime.fromISO(dateSince).setLocale("en-US").toLocaleString()
        : "No Absences Found";
    }
    return <div>{toRenderFromDate}</div>;
  };

  const handleClick = (id: string): void => {
    history.push(`/dashboard/new-employees/employee/${id}`);
  };

  return (
    <Modal
      show={showModal}
      container={document.getElementById("ts-modal")}
      centered
      onHide={handleExit}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Perfect Attendance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <header className="mb-4">
          <h2>{timespan}</h2>
          <TSInput
            type="search"
            placeholder="Filter by Employee Name/ID"
            onChange={handleSearchChange}
            defaultValue={searchTerm}
          />
        </header>
        <Table className="perfect-attendance-table" hover>
          <thead>
            <tr>
              <th>EMPLOYEE</th>
              {width >= 768 && (
                <>
                  <th>DIVISION</th>
                  <th>MANAGER</th>
                </>
              )}

              <th className="text-end">PERFECT ATTENDANCE SINCE</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              results &&
              results.map((result, index) => (
                <tr key={index} onClick={() => handleClick(result.id)}>
                  <td className="pa-employee-name">
                    <span>{result.name}</span>
                    <span className="pa-id">ID: {result.externalId}</span>
                    {width < 768 && (
                      <div>
                        <h6>DIVISION</h6>
                        <h5>{result.division?.name}</h5>
                        <h6>MANAGER</h6>
                        <h5>{result.teamLead?.name}</h5>
                      </div>
                    )}
                  </td>

                  {width >= 768 && (
                    <>
                      <td>{result.division?.name}</td>
                      <td>{result.teamLead?.name}</td>
                    </>
                  )}

                  <td className="text-end">
                    {renderFromDate(result.perfectAttendanceSince)}
                  </td>
                </tr>
              ))}
            {!isLoading && results && !results.length && (
              <tr>
                <td colSpan={width < 768 ? 2 : 4} className="pa-no-results">
                  <h1>No results found</h1>
                  <h4>
                    We can&rsquo;t find any employees that meet your search
                    criteria
                  </h4>
                </td>
              </tr>
            )}
            {isLoading &&
              skeletonArray.map((x, i) => (
                <tr key={i}>
                  {new Array(4).fill(0).map((y, j) => (
                    <td key={`${i}${j}`}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
        {count && count > 0 ? (
          <TSPagination
            currentPage={page}
            total={count}
            resultsPerPage={LIMIT}
            onChange={handlePageChange}
          />
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <TSButton variant="link" onClick={handleExit}>
          Close
        </TSButton>
      </Modal.Footer>
    </Modal>
  );
};

interface PerfectAttendanceModalProps {
  showModal: boolean;
  handleExit: () => void;
  timespan?: string;
  startDate?: string;
  frameLength?: FrameLength;
  labelList?: string;
  divisionList?: string;
  // results?: api.InsightsEmployee[];
}
