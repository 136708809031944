import React, { FunctionComponent, MouseEvent, useState } from "react";

import clsx from "clsx";

import { TextMessage } from "../TextMessage/TextMessage";

import * as api from "~/api";
import { useConversationTranslation, useEffectOnce } from "~/lib/hooks";
import { Button } from "~common";

interface SingleChoiceProps extends api.Choice {
  selected: boolean;
  disabled?: boolean;
  onClick: (e: MouseEvent) => void;
}

const SingleChoice: FunctionComponent<SingleChoiceProps> = ({
  display,
  loc_display,
  onClick,
  selected,
  disabled
}) => {
  const displayText = useConversationTranslation(display, loc_display);
  return (
    <Button
      className={clsx(
        "whitespace-normal leading-tight rounded-lg p-3 m-1 border border-solid outline-none focus:outline-none",
        !disabled &&
          !selected &&
          "bg-gray-100 border-gray-300 focus:border-gray-300",
        disabled &&
          !selected &&
          "bg-gray-100 border-gray-100 focus:border-gray-100 text-hs-medium-dark-gray focus:text-hs-medium-dark-gray",
        selected && "bg-hs-green-20 border-hs-green focus:border-hs-green"
      )}
      onClick={onClick}
    >
      {displayText}
    </Button>
  );
};

export interface ChoiceMessageProps extends api.ChoiceSet {
  /** Context for rendering template text. */
  context: api.TemplateStringContext;

  /** Prompt string. */
  templateText: string;

  /** Initial selected choice keys, if any. */
  selected?: string[];

  /** If true, no longer allow interaction. */
  disabled?: boolean;

  /** Change handler that always shows the latest selected set. */
  onChange: (selected: string[], valid: boolean) => void;
}

export const ChoiceMessage: FunctionComponent<ChoiceMessageProps> = props => {
  const {
    choices,
    context,
    single,
    minChoices,
    maxChoices,
    required,
    selected,
    disabled,
    onChange,
    templateText
  } = props;
  const [nowSelected, setNowSelected] = useState<Set<string>>(
    new Set<string>(selected)
  );

  const requiredMin = required ? 1 : 0;
  const enforcedMin = minChoices ?? requiredMin;
  const isValid = (current: Set<string>): boolean =>
    current.size >= enforcedMin;

  const singleMax = single ? 1 : undefined;
  const enforcedMax = maxChoices ?? singleMax;

  const singleChoiceMode = enforcedMin === 1 && enforcedMax === 1;

  const isMultiChoiceMaxSelected =
    !singleChoiceMode &&
    Boolean(nowSelected) &&
    enforcedMax !== undefined &&
    nowSelected.size >= enforcedMax;

  const handleClick = (changedKey: string): void => {
    // Ignore clicks if we're disabled.
    if (disabled) {
      return;
    }

    if (!nowSelected.has(changedKey) && isMultiChoiceMaxSelected) {
      return;
    }

    // Don't mutate our state directly.
    let newSelected = new Set(nowSelected);

    // update our state -- first, flipping the tapped key or making it unique.
    if (singleChoiceMode) {
      newSelected = new Set([changedKey]);
    } else if (newSelected.has(changedKey)) {
      newSelected.delete(changedKey);
    } else {
      newSelected.add(changedKey);
    }

    // update state
    setNowSelected(newSelected);

    // determine if this state is valid

    // alert our victims!
    onChange(Array.from(newSelected), isValid(newSelected));
  };

  // Give calling components an opportunity to get initial state.
  useEffectOnce(() => {
    if (disabled) {
      return;
    }
    onChange(Array.from(nowSelected), isValid(nowSelected));
  });

  return (
    <>
      <div className="pb-3">
        <TextMessage templateText={templateText} context={context} />
      </div>
      <div className="w-full block m-auto">
        <div className="flex justify-left flex-wrap">
          {choices.map(choice => (
            <SingleChoice
              selected={nowSelected.has(choice.key)}
              disabled={isMultiChoiceMaxSelected}
              key={choice.key}
              display={choice.display}
              loc_display={choice.loc_display}
              onClick={e => {
                handleClick(choice.key);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};
