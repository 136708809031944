import React, { FunctionComponent, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import * as api from "~/api";
import { Screener } from "~/components";
import BackArrow from "~/images/back-arrow.svg";
import { MutableSessionContext } from "~/lib/context";
import { Survey } from "~/views/Survey";
import { GenericErrorText, Loading } from "~common";

interface ParamTypes {
  token: string;
}

export const ScreenerView: FunctionComponent<{}> = () => {
  const { session } = useContext(MutableSessionContext);

  const [employee, setEmployee] = useState<api.Employee>();
  const [surveyToken, setSurveyToken] = useState<string>();
  const [gettingToken, setGettingToken] = useState(false);

  const { token } = useParams<ParamTypes>();

  const onEmployeeSelected = async (
    employee?: api.IdentificationEmployee
  ): Promise<void> => {
    if (!session.company || !token || !employee) {
      return;
    }

    let employeeResponse: api.APIResponse<api.Employee> | undefined;
    try {
      employeeResponse = await api.retrieveEmployee(
        session.company.id,
        employee.id,
        { token }
      );
    } catch {}
    if (!employeeResponse?.ok) {
      toast.error(<GenericErrorText />);
      return;
    }

    setEmployee(employeeResponse.data);

    setGettingToken(true);
    let response: api.APIResponse<api.SurveyLink> | undefined;
    try {
      response = await api.getOrCreateScreenerSurvey(
        session.company.id,
        employee.id,
        token
      );
    } catch {}
    setGettingToken(false);

    if (!response?.ok) {
      toast.error(<GenericErrorText />);
      return;
    }

    setSurveyToken(response.data.token);
  };

  const resetToSearch = (): void => {
    setEmployee(undefined);
    setSurveyToken(undefined);
    setGettingToken(false);
  };

  if (surveyToken) {
    return (
      <Survey
        tokenFromScreener={surveyToken}
        employeeFromScreener={employee}
        onStartOverForScreener={resetToSearch}
      />
    );
  }

  return (
    <div className="h-screen w-screen bg-white">
      <Screener
        employee={employee}
        token={token}
        onEmployeeSelected={onEmployeeSelected}
      >
        {gettingToken && <Loading />}
        {!gettingToken && !surveyToken && (
          <div className="relative" onClick={resetToSearch}>
            <img className="h-4 w-10 mt-6" src={BackArrow} alt="Back" />
          </div>
        )}
      </Screener>
    </div>
  );
};
