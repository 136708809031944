import React, { FC, useRef, useState, useContext } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

import { DateTime } from "luxon";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { TSButton } from "~common";

import "./ExportSurveyResultsModal.scss";

export const ExportSurveyResultsModal: FC<ExportSurveyResultsModalNewProps> = ({
  handleClose,
  exportModalSurveyId,
  allowFutureDates,
  exportType,
  titleModal,
  descriptionModal
}) => {
  const { session } = useContext(MutableSessionContext);

  const refStartDate = useRef<DatePicker>(null);
  const refEndDate = useRef<DatePicker>(null);

  const [newStartDate, setNewStartDate] = useState<string>();
  const [newEndDate, setNewEndDate] = useState<string | undefined>(
    allowFutureDates ? undefined : DateTime.local().toISODate()
  );
  const isStartValid = DateTime.fromISO(newStartDate ?? "").isValid;
  const isEndValid = DateTime.fromISO(newEndDate ?? "").isValid;

  const defaultMaxDate = allowFutureDates ? null : new Date();
  const defaultTitle =
    exportType === "attendance"
      ? "Export Attendance Report"
      : "Export Survey Responses";
  const defaultDescription =
    exportType === "attendance"
      ? "Export a .CSV file of attendance statuses for a selected range of dates:"
      : "Export a .CSV file of survey responses for a selected range of dates:";

  // utility methods
  const onRequestExportSurveyResults = (
    startDate: api.ISODateTime,
    endDate: api.ISODateTime
  ): void => {
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
    let downloadUrl;

    if (session?.company?.id && exportType === "attendance") {
      // eslint-disable-next-line max-len
      downloadUrl = `${window.location.origin}/api/companies/${session.company.id}/attendance_report/?window_start=${encodedStartDate}&window_end=${encodedEndDate}`;
    } else if (session?.company?.id && exportType === "survey") {
      // eslint-disable-next-line max-len
      downloadUrl = `${window.location.origin}/api/conversations/company/${session.company.id}/survey_responses/?start_date=${encodedStartDate}&end_date=${encodedEndDate}&template_id=${exportModalSurveyId}`;
    }

    if (downloadUrl) {
      window.location.href = downloadUrl;
      downloadUrl && toast.success("Your report has started downloading.");
    }
  };

  // handlers
  const startChangeHandler = (value: Date | [Date, Date] | null): void => {
    if (!(value instanceof Array)) {
      setNewStartDate(
        value ? DateTime.fromJSDate(value).toISODate() : undefined
      );
    }
    if (!isEndValid) {
      refEndDate.current?.setFocus();
    }
  };

  const endChangeHandler = (value: Date | [Date, Date] | null): void => {
    if (!(value instanceof Array)) {
      setNewEndDate(value ? DateTime.fromJSDate(value).toISODate() : undefined);
    }
  };

  const exportClickHandler = (): void => {
    if (!newStartDate || !newEndDate) {
      return;
    }
    onRequestExportSurveyResults(
      DateTime.fromISO(newStartDate).startOf("day").toISO(),
      DateTime.fromISO(newEndDate).endOf("day").toISO()
    );
    modalCloseHandler();
  };

  const modalCloseHandler = (): void => {
    handleClose();
  };

  return (
    <>
      <Modal
        container={document.getElementById("ts-modal")}
        show
        onHide={modalCloseHandler}
        centered
      >
        <Modal.Body>
          <h1>{titleModal || defaultTitle}</h1>
          <p className="mb-4">{descriptionModal || defaultDescription}</p>
          <Row>
            <Col>
              <Form.Group className="ts-input">
                <Form.Label>Start Date</Form.Label>
                <DatePicker
                  portalId="root"
                  wrapperClassName="d-block"
                  placeholderText="Select date"
                  selected={
                    newStartDate
                      ? DateTime.fromISO(newStartDate).toJSDate()
                      : null
                  }
                  maxDate={
                    newEndDate
                      ? DateTime.fromISO(newEndDate).toJSDate()
                      : defaultMaxDate
                  }
                  className="form-control"
                  ref={refStartDate}
                  onChange={value => startChangeHandler(value)}
                  onFocus={() => refEndDate.current?.setOpen(false)}
                  tabIndex={1}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="ts-input">
                <Form.Label>End Date</Form.Label>
                <DatePicker
                  portalId="root"
                  wrapperClassName="d-block"
                  placeholderText="Select date"
                  selected={
                    newEndDate ? DateTime.fromISO(newEndDate).toJSDate() : null
                  }
                  minDate={
                    newStartDate
                      ? DateTime.fromISO(newStartDate).toJSDate()
                      : null
                  }
                  maxDate={allowFutureDates ? null : new Date()}
                  className="form-control"
                  ref={refEndDate}
                  onChange={value => endChangeHandler(value)}
                  onFocus={() => refStartDate.current?.setOpen(false)}
                  tabIndex={1}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <TSButton variant="link" onClick={modalCloseHandler} size="lg">
            Cancel
          </TSButton>
          <TSButton
            variant="primary"
            disabled={!isStartValid || !isEndValid}
            onClick={exportClickHandler}
            size="lg"
          >
            Export
          </TSButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface ExportSurveyResultsModalNewProps {
  handleClose: Function;
  exportModalSurveyId: string | undefined;
  allowFutureDates: boolean;
  exportType: "attendance" | "survey";
  titleModal?: string;
  descriptionModal?: string;
}
