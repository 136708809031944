/**
 * Return the value of a named cookie, or null if not found.
 */
export const getCookie = (name: string): string | null => {
  const cookieName = `${name}=`;
  const decoded = decodeURIComponent(document.cookie);
  const splits = decoded.split(";");
  for (const split of splits) {
    const trimmed = split.trim();
    if (trimmed.indexOf(cookieName) === 0) {
      return trimmed.substring(cookieName.length, trimmed.length);
    }
  }
  return null;
};

/** Return the current CSRF token, or null if not found. */
export const getCSRFToken = (): string | null =>
  getCookie(process.env.REACT_APP_CSRF_COOKIE_NAME || "csrftoken");
