import React, { FunctionComponent } from "react";

import { LoginPage } from "./LoginPage";

export const ResetSent: FunctionComponent = () => {
  return (
    <LoginPage>
      <h1>Please check your email.</h1>
      <p>
        Please check your email for instructions on how to set your password. We
        will only send an email if we recognize your account and it is active.
      </p>
      <p>
        If you don't receive one in the next few minutes, please reach out to us
        at{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:support@teamsense.com`}
        >
          support@teamsense.com.
        </a>
      </p>
    </LoginPage>
  );
};
