import React, { FunctionComponent } from "react";

import clsx from "clsx";

import { TSButton } from "../common/ts-buttons/TSButton";
import "./AttendanceInsightsFilterPanel.scss";

export const AttendanceInsightsFilterPanel: FunctionComponent<
  AttendanceInsightsFilterPanelProps
> = ({
  children,
  enableApplyFilters,
  handleExit,
  handleApplyFilters,
  handleClearFilters,
  isVisible,
  isLoading
}) => {
  return (
    <div className={clsx("ts-filter-panel", isVisible && "is-visible")}>
      <div
        className={clsx("filter-panel-shim", isVisible && "is-visible")}
      ></div>
      <div className={clsx("filter-panel", isVisible && "is-visible")}>
        <header>
          <h1 className="h2">Filters</h1>
          <button onClick={handleExit}>
            <i className="icon-close" />
          </button>
        </header>
        <div className="filter-panel-content">{children}</div>
        <footer>
          <TSButton
            size="sm"
            onClick={handleApplyFilters}
            disabled={!enableApplyFilters || isLoading}
          >
            Apply Filters
          </TSButton>
          <TSButton size="sm" variant="link" onClick={handleClearFilters}>
            Clear Filters
          </TSButton>
        </footer>
      </div>
    </div>
  );
};

interface AttendanceInsightsFilterPanelProps {
  isVisible?: boolean;
  children?: React.ReactNode;
  handleExit: () => void;
  handleApplyFilters: () => void;
  handleClearFilters: () => void;
  enableApplyFilters: boolean;
  isLoading: boolean;
}
