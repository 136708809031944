import React, { FunctionComponent } from "react";
import DatePicker from "react-mobile-datepicker";

import { Trans } from "@lingui/react";
import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import { useEffectOnce } from "~/lib/hooks";

import "./DatePicker.scss";

const dateConfig = {
  month: {
    format: "M",
    caption: <Trans id="survey.date.caption.month">Month</Trans>,
    step: 1
  },
  date: {
    format: "D",
    caption: <Trans id="survey.date.caption.date">Date</Trans>,
    step: 1
  },
  year: {
    format: "YYYY",
    caption: <Trans id="survey.date.caption.year">Year</Trans>,
    step: 1
  }
};

const getMinDate = (
  range: string,
  maxPastDays?: number,
  otherAnswerValue?: DateTime
): DateTime | undefined => {
  const now = DateTime.local();

  if (range === "future") {
    return now.plus({ days: 1 }).startOf("day");
  }

  if (range === "todayAndFuture") {
    return now.startOf("day");
  }

  if (range === "futureAfterEarlierAnswer" && otherAnswerValue) {
    return otherAnswerValue.plus({ days: 1 }).startOf("day");
  }

  if (range === "futureFromEarlierAnswer" && otherAnswerValue) {
    return otherAnswerValue.startOf("day");
  }

  if ((maxPastDays ?? 0) <= 0) {
    return;
  }

  return now.minus({ days: maxPastDays }).startOf("day");
};

const getMaxDate = (range: string, maxFutureDays?: number): DateTime => {
  const now = DateTime.local();

  if (range === "past") {
    return now.minus({ days: 1 }).endOf("day");
  }

  if (range === "todayAndPast") {
    return now.endOf("day");
  }

  if ((maxFutureDays ?? 0) <= 0) {
    return now.plus({ years: 1 }).endOf("day");
  }

  return now.plus({ days: maxFutureDays }).endOf("day");
};

interface DatePickerWidgetProps {
  className?: string;
  range?: api.DateQuestion["hintRange"];
  maxPastDays?: number;
  maxFutureDays?: number;
  otherAnswerValue?: Date;
  theme?: string;
  onChange: (value: Date) => any;
  value: Date;
}

export const DatePickerWidget: FunctionComponent<DatePickerWidgetProps> = ({
  range = "all",
  maxPastDays,
  maxFutureDays,
  otherAnswerValue,
  theme,
  className,
  onChange,
  value
}) => {
  const dtValue = DateTime.fromJSDate(value);
  const dtOtherAnswerValue = otherAnswerValue
    ? DateTime.fromJSDate(otherAnswerValue)
    : undefined;
  const dtMin = getMinDate(range, maxPastDays, dtOtherAnswerValue);
  const dtMax = getMaxDate(range, maxFutureDays);

  const dtConstrainedMaxValue = DateTime.min(dtValue, dtMax);

  // NOTE: The value we pass to the DatePicker component is adjusted to be
  // near the middle of the day (typically noon, possibly 11AM or 1PM on a DST change day)
  // to avoid problems with DatePicker near the Daylight Savings Time boundary

  // NOTE: we convert the time coming from this component back to 12:00AM when
  // calling onChange() so that code that uses this component won't detect this
  // and will always see 12:00AM times (in the appropriate timezone)
  const dtPickerValue = (
    dtMin ? DateTime.max(dtConstrainedMaxValue, dtMin) : dtConstrainedMaxValue
  )
    .startOf("day")
    .plus({ hours: 12 });

  // Give calling components an opportunity to get initial state.
  useEffectOnce(() => {
    onChange(dtPickerValue.startOf("day").toJSDate());
  });

  return (
    <div className={clsx(className, "w-full")}>
      <DatePicker
        dateConfig={dateConfig}
        theme={theme}
        min={dtMin?.toJSDate()}
        max={dtMax.toJSDate()}
        onChange={(date: Date) =>
          onChange(DateTime.fromJSDate(date).startOf("day").toJSDate())
        }
        showFooter={false}
        showHeader={false}
        isPopup={false}
        showCaption
        value={dtPickerValue.toJSDate()}
      />
    </div>
  );
};
