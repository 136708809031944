import React, { FunctionComponent, ReactNode } from "react";

import { I18n } from "@lingui/core";
import { withI18n, withI18nProps } from "@lingui/react";
import { DateTime } from "luxon";

import { I18nContext, i18nHookContext } from "../context/i18n";

/**
 * This is a hack that exposes LinguiJS's i18n object as a context value so
 * it can be consumed by a hook instead of relying on the withI18n HOC.
 *
 * Make sure this is lower in the tree than your I18nProvider but higher than
 * any component that calls `useI18n`.
 *
 * This component works by intercepting the value provided by withI18n and
 * passing it to its consumers in a unique object every time the component
 * renders. Because we create a unique object every time, the consumers are
 * properly updated when language is changed.
 */
export const I18nHookHackProviderUnwrapped: FunctionComponent<{
  i18n: I18n;
  children?: ReactNode;
}> = ({ i18n, children }) => (
  <i18nHookContext.Provider value={{ i18n }}>
    {children}
  </i18nHookContext.Provider>
);

export const I18nHookHackProvider = withI18n()<withI18nProps>(
  I18nHookHackProviderUnwrapped
);

/**
 * This hook offers a syntax that is similar to what we will get in Lingui v3.
 */
export const useLingui = (): I18nContext => React.useContext(i18nHookContext);

export const useLinguiLanguage = (): string => {
  const locale = useLingui().i18n?.language ?? "en-US";
  const dateNow = DateTime.fromISO("2016-05-25T09:08:34.123");
  try {
    // Validate the locale is supported by Luxon.
    dateNow.setLocale(locale);
    dateNow.toLocaleString(DateTime.DATE_FULL);
    return locale;
  } catch (e) {
    return "en-US";
  }
};

export const useLinguiLocale = (): string => {
  const language = useLinguiLanguage();
  return language === "es" ? "es-MX" : language;
};

type LocalizationTable = { [key: string]: string };

export const processConversationTranslation: (
  language: string,
  // Primary text may itself be localizable
  text?: string | LocalizationTable,
  // If not, we may send in a separate localization table
  // TODO: remove all instances of loc_foo in favor of a unified type, and then
  // remove this optional param from this method.
  locText?: LocalizationTable
) => string = (language, text, locText = {}) =>
  typeof text === "string"
    ? // handle the case where a separate localization table is *potentially* present
      locText[language] ?? locText["en-US"] ?? text
    : // and where it isn't.
      text?.[language] ?? text?.["en-US"] ?? "";

export const useConversationTranslation: (
  text?: string | LocalizationTable,
  locText?: LocalizationTable
) => string = (text, locText) =>
  processConversationTranslation(useLinguiLanguage(), text, locText);
