import { ISODateTime } from "./common";
import { APIResponse, get } from "./lib";

import { Division } from "~/api/companies";
import { InsightsEmployee } from "~/api/employees";

export interface InsightsLabel {
  name: string;
  count: number;
  percent: number;
}

interface Summary {
  employeesCount?: number;
  absencesCount: number;
  absencesPercent: number;
}

export interface Frame {
  name: string;
  summary: Summary;
  labels?: InsightsLabel[];
}

export interface Totals {
  summary: Summary;
  labels: InsightsLabel[];
}

export interface TotalMetrics {
  frameLength: string;
  frames?: Frame[];
  totals?: Totals;
}

export interface DivisionMetric {
  division: Division;
  frameLength: string;
  frames: Frame[];
  totals: Totals;
}

export interface AttendanceInsightsResponse {
  totalMetrics?: TotalMetrics;
  divisionMetrics?: DivisionMetric[];
}

export interface AttendanceInsightsEmployeeResponse {
  next?: string;
  previous?: string;
  employeeCount?: number;
  perfectAttendance?: number;
  absentEmployees?: number;
  results?: InsightsEmployee[];
  count?: number;
}

export interface AttendanceInsightsOptions {
  token?: string;
  start?: ISODateTime;
  end?: ISODateTime;
  frameLength?: "month" | "week" | "day";
  metricsLevel?: "total" | "division" | "perfectAttendance" | "mostAbsences";
  labelList?: string;
  divisionList?: string;
  labels?: number;
  limit?: number;
  page?: number;
  searchNameOrId?: string;
}

export const retrieveAttendanceInsights = async (
  options?: AttendanceInsightsOptions
): Promise<APIResponse<AttendanceInsightsResponse>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  const query = {
    ...(options?.start ? { start: options.start } : {}),
    ...(options?.end ? { end: options.end } : {}),
    ...(options?.frameLength ? { frameLength: options?.frameLength } : {}),
    ...(options?.metricsLevel ? { metricsLevel: options?.metricsLevel } : {}),
    ...(options?.labelList ? { labelList: options.labelList } : {}),
    ...(options?.divisionList ? { divisionList: options.divisionList } : {}),
    ...(options?.labels ? { labels: options.labels.toString() } : {})
  };
  return await get(`/insights/attendance/`, query, headers);
};

export const retrieveAttendanceEmployeeInsights = async (
  options?: AttendanceInsightsOptions
): Promise<APIResponse<AttendanceInsightsEmployeeResponse>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  const query = {
    ...(options?.start ? { start: options.start } : {}),
    ...(options?.end ? { end: options.end } : {}),
    ...(options?.frameLength ? { frameLength: options?.frameLength } : {}),
    ...(options?.metricsLevel ? { metricsLevel: options?.metricsLevel } : {}),
    ...(options?.labelList ? { labelList: options.labelList } : {}),
    ...(options?.divisionList ? { divisionList: options.divisionList } : {}),
    ...(options?.labels ? { labels: options.labels.toString() } : {}),
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
    ...(options?.searchNameOrId
      ? { searchNameOrId: options.searchNameOrId.toString() }
      : {})
  };

  return await get(`/insights/attendance/employees/`, query, headers);
};
