import React, { FunctionComponent, ReactNode } from "react";

import clsx from "clsx";

export const ToggleButton: FunctionComponent<{
  className?: string;
  name: string | ReactNode;
  imageSrc?: string;
  selectedImageSrc?: string;
  selected: boolean;
  onClick?: () => any;
}> = ({ className, name, imageSrc, selectedImageSrc, selected, onClick }) => (
  <div className={clsx("cursor-pointer", className)} onClick={onClick}>
    <div
      className={clsx(
        "flex flex-row text-sm font-semibold items-center justify-center space-x-2",
        selected && "text-hs-dark-green",
        !selected && "text-hs-medium-dark-gray"
      )}
    >
      <img src={selected ? selectedImageSrc : imageSrc} alt="" />
      <div>{name}</div>
    </div>
    <div
      className={clsx(
        "mt-2 -mx-1 h-1 w-full border rounded-full",
        selected && "bg-hs-dark-green border-hs-dark-green",
        !selected && "border-transparent"
      )}
    />
  </div>
);
