import { DateTime } from "luxon";

interface InitialEventDates {
  started: string;
  ended: string | undefined;
}

const isStartOfDay = (dt: DateTime): boolean => dt.equals(dt.startOf("day"));

const getInitalStartDate = ({
  started,
  timezone,
  currentDay
}: {
  started?: string | undefined;
  timezone: string;
  currentDay: DateTime;
}): string =>
  started
    ? DateTime.fromISO(started, { zone: timezone }).toISODate()
    : currentDay.toISODate();

const getInitialEndDate = ({
  ended,
  timezone
}: {
  ended: string | undefined;
  timezone: string;
}): string | undefined => {
  if (!ended) {
    return undefined;
  }

  const endedAsDateTime = DateTime.fromISO(ended, { zone: timezone });

  // Since we show "End Date" and not "Return Date" (which is what our data model stores),
  // we back up the end date by one day when the event ends at 12:00AM in the employee's timezone
  const adjusted = isStartOfDay(endedAsDateTime)
    ? endedAsDateTime.minus({ days: 1 })
    : DateTime.fromISO(ended);

  return adjusted.toISODate();
};

export const getInitialEventDates = ({
  currentDay,
  event,
  timezone
}: {
  currentDay: DateTime;
  event?: {
    started?: string | undefined;
    ended?: string | undefined;
  };
  timezone: string;
}): InitialEventDates => {
  return {
    started: getInitalStartDate({
      started: event?.started,
      timezone,
      currentDay
    }),
    ended: getInitialEndDate({ ended: event?.ended, timezone })
  };
};

interface UpdatedEventDates {
  started: string;
  ended: string;
}

export const getUpdatedEventDates = ({
  startDate,
  endDate,
  timezone
}: {
  startDate: string;
  endDate: string;
  timezone: string;
}): UpdatedEventDates => {
  const zoneAdjustedStartDate = DateTime.fromISO(startDate, {
    zone: timezone
  });

  const endAsDateTime = DateTime.fromISO(endDate, { zone: timezone });

  // Since we show "End Date" and not "Return Date" (which is what our data model stores),
  // we add one day to the end date if it is set to 12:00AM in the employee's timezone

  // TODO: This should always be true now???

  const zoneAdjustedEndDate = isStartOfDay(endAsDateTime)
    ? endAsDateTime.plus({ days: 1 })
    : endAsDateTime;

  return {
    started: zoneAdjustedStartDate.toISO(),
    ended: zoneAdjustedEndDate.toISO()
  };
};
