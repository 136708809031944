import React, { FunctionComponent } from "react";

interface RightExpanderProps {
  className?: string;
}

export const rightExpander: FunctionComponent<RightExpanderProps> = ({
  className
}) => (
  <svg className={className} fill="currentColor" viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);

interface TeamSenseCheckProps {
  fill: string;
}
export const TeamSenseCheck: FunctionComponent<TeamSenseCheckProps> = ({
  fill
}) => (
  <svg
    width={"36"}
    height={"34"}
    viewBox="0 0 36 34"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 0H31.8693L15.8207 27.6424L5.66259 11.9778H10.3313L15.693 20.5924L22.5046 8.82147C20.0882 7.05898 17.1611 6.11478 14.1519 6.12377C6.34648 6.11478 0.0091284 12.3465 9.85617e-06 20.0439C-0.00910869 27.7413 6.31002 33.991 14.1155 34C21.921 34.009 28.2584 27.7773 28.2675 20.0799C28.2675 18.3443 27.9392 16.6178 27.3009 14.9992L36 0Z"
      fill={fill}
    />
  </svg>
);

interface BrandLogoProps {
  fill: string;
}
export const brandLogo: FunctionComponent<BrandLogoProps> = ({ fill }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 562.2 85.7"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill={fill}
        d="M303.7,32h-7c0-12.8-7-18.6-19.3-18.6c-10.6,0-16.7,5.2-16.7,13.7c0,9,6.5,11.7,19.1,14.8
        c14.3,3.5,25.3,7.1,25.3,22.1c0,13.2-10,21.5-25.8,21.5c-17.1,0-27.1-9-27.1-26.5h6.9c0,14.1,8,20.2,20.2,20.2s18.8-5.5,18.8-15.1
        c0-9.3-6.9-11.9-20-15.2c-14.5-3.6-24.4-7.2-24.4-21.6c0-11.8,8.8-20.1,23.7-20.1C294.9,7.2,303.7,15.6,303.7,32z"
      />
      <path
        fill={fill}
        d="M353.7,68.1h6.8c-1.7,9.2-9.2,17.4-22.8,17.4C322.3,85.5,313,74,313,56.2c0-15.9,9-27.4,24.2-27.4
        c12.1,0,20.5,7.3,22.8,19.2c0.6,3.4,0.9,6.9,0.9,10.4h-41.2c0.3,14.3,8,21.4,17.9,21.4C346.5,79.9,352.1,75,353.7,68.1z M319.8,53
        h34.7c-0.7-12-7.6-18.6-17.3-18.6C327.3,34.4,320.5,41.4,319.8,53z"
      />
      <path
        fill={fill}
        d="M414.4,51.3v32.8h-6.6V51.6c0-12.7-5.2-16.9-13.8-16.9c-8.4,0-15.7,6-15.7,17.4v31.9h-6.6V30.2h6.5v7.3h0.2
        c3.3-5.1,9.3-8.5,16.8-8.5C407.6,29,414.4,36.4,414.4,51.3z"
      />
      <path
        fill={fill}
        d="M464.7,47.3h-6.6c0-8.2-5.1-12.7-13.7-12.7c-7.4,0-12.2,3.5-12.2,8.9c0,6.6,4.2,7.4,13.6,9.7
        c10.1,2.3,20.5,4.5,20.5,16.7c0,9.3-7.9,15.7-20.2,15.7c-13.6,0-21.5-7-21.8-19.5h6.7c0,8.8,5.5,13.8,15.1,13.8
        c8.1,0,13.6-3.8,13.6-9.6c0-7.1-4.7-8.4-16-11c-9-2.1-18-3.9-18-15.2c0-9.1,7.8-15.2,18.8-15.2C457.1,28.8,464.6,35.5,464.7,47.3z"
      />
      <path
        fill={fill}
        d="M514.3,68.1h6.8c-1.7,9.2-9.2,17.4-22.8,17.4c-15.4,0-24.6-11.5-24.6-29.3c0-15.9,9-27.4,24.2-27.4
        c12.1,0,20.5,7.3,22.8,19.2c0.6,3.4,0.9,6.9,0.8,10.4h-41.2c0.3,14.3,8,21.4,17.9,21.4C507.1,79.9,512.7,75,514.3,68.1z M480.5,53
        h34.7c-0.7-12-7.6-18.6-17.3-18.6C488,34.4,481.1,41.4,480.5,53z"
      />
      <path
        fill={fill}
        d="M562.2,0h-4.5l-17.6,30.7l-11.1-17.4h5.1l5.9,9.6l7.5-13.1c-2.7-2-5.9-3-9.2-3c-8.6,0-15.5,6.9-15.5,15.5
        c0,8.6,6.9,15.5,15.5,15.5s15.5-6.9,15.5-15.5c0-1.9-0.4-3.9-1.1-5.7L562.2,0z"
      />
      <g>
        <path fill={fill} d="M37.5,19.4v64.7H24.9V19.4H0V8.4h62.4v11.1H37.5z" />
        <path
          fill={fill}
          d="M87.5,67.8h12.4c-2,9.7-10.7,17.9-24.9,17.9c-16.5,0-26.2-11.8-26.2-29.2c0-16,9.6-28,25.9-28
          c12.3,0,21.3,7.1,24.2,18.4c1.1,3.4,1.5,7.4,1.5,12.7H60.9c0.4,12.2,6.6,17.2,14.1,17.2C82,76.8,86.4,73,87.5,67.8z M61.1,51.6
          h27.3c-0.9-9.5-6.4-14.1-13.6-14.1C67.5,37.4,62.2,42.2,61.1,51.6z"
        />
        <path
          fill={fill}
          d="M153.8,49.6v20.2c0,6.4,0.5,10.8,1.4,14.2h-11c-0.5-2.3-0.7-5-0.7-7.5h-0.2c-3.9,5.7-10,8.7-18.2,8.7
          c-11.3,0-18.3-6.8-18.3-16.1c0-8.8,5.2-14.6,20.7-17c4.4-0.7,10.7-1.4,14.6-1.6v-2.1c0-8-4.4-11.2-10.5-11.2
          c-6.7,0-10.3,3.7-10.6,9.5H109c0.5-10.2,8-18.3,22.5-18.3C145.8,28.5,153.8,35.5,153.8,49.6z M142.1,58.5
          c-3.6,0.2-8.8,0.7-12.4,1.4c-7.8,1.3-10.6,3.6-10.6,8.5c0,4.8,3.4,8,9.6,8c3.7,0,7.3-1.2,9.8-3.6c2.9-3,3.7-5.8,3.7-11.6V58.5z"
        />
        <path
          fill={fill}
          d="M242.6,50.8v33.3h-12.1V52c0-9.6-3.7-13.4-9.8-13.4c-7.1,0-11.4,6.1-11.4,16.4v29.1h-12.1V50.7
          c0-7.7-3.4-12.1-9.7-12.1c-7.2,0-11.4,6.2-11.4,16.6v28.9H164v-54h11.6v6.5h0.2c3.8-5.2,8.6-7.8,15-7.8c7.4,0,13.2,3.4,16,9.8
          c3.9-6.2,9.1-9.8,17.3-9.8C235.6,28.8,242.6,36.2,242.6,50.8z"
        />
      </g>
    </g>
  </svg>
);

export const BrandBlack = brandLogo({ fill: "#000000" });
export const BrandGreen = brandLogo({ fill: "#01c16b" });
export const BrandRed = brandLogo({ fill: "#ff3e3e" });
export const BrandWhite = brandLogo({ fill: "#ffffff" });
