import React, { FunctionComponent } from "react";

import { DateTime } from "luxon";
import simplur from "simplur";

interface AttendanceHeaderProps {
  numEmployees?: number;
  selectedDate: DateTime;
}

export const AttendanceHeader: FunctionComponent<AttendanceHeaderProps> = ({
  numEmployees,
  selectedDate
}) => {
  let formattedDate = "";
  try {
    formattedDate = selectedDate.toLocaleString({ ...DateTime.DATE_MED });
  } catch (e) {
    formattedDate = selectedDate.toLocaleString({
      ...DateTime.DATE_MED,
      locale: "en-US"
    });
  }
  const selectedStart = selectedDate.startOf("day");
  const localStart = DateTime.local().startOf("day");
  const zeroAbsenceMessage =
    numEmployees === 0
      ? "Congratulations! You have zero absences today!"
      : null;

  // Set defaults for the header text
  // Using &nbsp; to ensure that a line of text is being rendered
  let prefix: JSX.Element | string = <>&nbsp;</>;
  let tense: JSX.Element | string = <>&nbsp;</>;
  let empCount: JSX.Element | string = <>&nbsp;</>;
  let suffix: JSX.Element | string = <>&nbsp;</>;

  // Override the defaults, if needed, based on what day was selected
  if (selectedStart > localStart) {
    if (numEmployees !== undefined) {
      // Since the dashboard is not localized for now, we use simplur
      // to determine the pluralization of the sentence
      prefix = `On ${formattedDate}, `;
      tense = simplur`${[numEmployees]} there [is|are] `;
      suffix = "scheduled to be out.";
    }
  } else if (selectedStart < localStart) {
    if (numEmployees !== undefined) {
      prefix = `On ${formattedDate}, `;
      tense = simplur`${[numEmployees]} there [was|were] `;
      suffix = "out.";
    }
  } else if (numEmployees !== undefined) {
    prefix = "Today, ";
    tense = simplur`${[numEmployees]} there [is|are] `;
    suffix = "out.";
  }

  if (numEmployees !== undefined) {
    empCount = simplur`${numEmployees} employee[|s] `;
  }

  return zeroAbsenceMessage ? (
    <h1>{zeroAbsenceMessage}</h1>
  ) : (
    <h1>
      <strong>{prefix}</strong>
      {tense}
      <strong>{empCount}</strong>
      {suffix}
    </h1>
  );
};
