import React, { FunctionComponent } from "react";

import clsx from "clsx";

import Restricted from "~/ts-components/permissionProvider/Restricted";

interface TabItem {
  label: string;
  imageSrc: string;
  restrictedTo: string[];
}

interface VerticalTabListProps {
  className?: string;
  items: TabItem[];
  selectedIndex: number;
  onSelectItem: (index: number) => any;
}

export const VerticalTabList: FunctionComponent<VerticalTabListProps> = ({
  className,
  items,
  selectedIndex,
  onSelectItem
}) => {
  return (
    <div className={className}>
      {items.map(({ label, imageSrc, restrictedTo }, index) => (
        <Restricted to={restrictedTo} key={`pane_${index}`}>
          <div
            className={clsx(
              "px-5 py-5-2 flex flex-row text-sm text-hs-dark-green cursor-pointer",
              selectedIndex === index && "bg-hs-selected-gray rounded-r-full"
            )}
            onClick={() => onSelectItem(index)}
          >
            <img className="mr-5" src={imageSrc} alt={label} />
            {label}
          </div>
        </Restricted>
      ))}
    </div>
  );
};
