import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { EmployeeSummary } from "../EmployeeSummary/EmployeeSummary";
import { EmployeeCalendarOverview } from "../Roster/EmployeeCalendarOverview";
import { EmployeeConversationHistory } from "../Roster/EmployeeConversationHistory";
import { EmployeeMessageLog } from "../Roster/EmployeeMessageLog";
import { EmployeeNotes } from "../Roster/EmployeeNotes";
import { EmployeeNotifications } from "../Roster/EmployeeNotifications";

import * as api from "~/api";
import PaneNotificationIcon from "~/images/notifications-icon-dark.svg";
import PaneNoteIcon from "~/images/pane-note-icon.svg";
import PaneScheduledIcon from "~/images/pane-scheduled-icon.svg";
import PaneSentIcon from "~/images/pane-sent-icon.svg";
import PaneSurveyIcon from "~/images/pane-survey-icon.svg";
import PaneUserIcon from "~/images/pane-user-icon.svg";
import { MutableSessionContext } from "~/lib/context/";
import { useEffectOnce } from "~/lib/hooks";
import { EditEmployeeModal } from "~/ts-components/employees";
import Restricted from "~/ts-components/permissionProvider/Restricted";
import { ConfirmationModal, VerticalTabList } from "~common";

import "./EmployeeReportsPane.scss";

const TAB_ITEMS = [
  {
    label: "Calendar",
    imageSrc: PaneScheduledIcon,
    relativeUrl: "/calendar",
    restrictedTo: ["EmployeeAttendanceStatus.READ", "EmployeeHealthStatus.READ"]
  },
  {
    label: "Surveys & Forms",
    imageSrc: PaneSurveyIcon,
    relativeUrl: "/surveys",
    restrictedTo: ["EmployeeSurveysForms.READ"]
  },
  {
    label: "Message Log",
    imageSrc: PaneSentIcon,
    relativeUrl: "/messages",
    restrictedTo: ["EmployeeMessageLog.READ"]
  },
  {
    label: "Notes",
    imageSrc: PaneNoteIcon,
    relativeUrl: "/notes",
    restrictedTo: ["EmployeeNotes.READ"]
  },
  {
    label: "Notifications",
    imageSrc: PaneNotificationIcon,
    relativeUrl: "/notifications",
    restrictedTo: ["EmployeeNotifications.READ"]
  }
];

/** The single-employee reports pane view */
export const EmployeeReportsPane: FunctionComponent = () => {
  interface ParamTypes {
    employeeId: string;
    tab:
      | "attendance"
      | "calendar"
      | "notes"
      | "surveys"
      | "messages"
      | "notifications"
      | undefined;
  }

  interface LocationTypes {
    afterEmployeeCreate?: boolean;
  }

  const location = useLocation<LocationTypes>();
  const locationStateAfterEmployeeCreate = Boolean(
    location.state?.afterEmployeeCreate
  );
  const { employeeId, tab } = useParams<ParamTypes>();
  const [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false);
  const modalCancelClickHandler = (): void => setShowEditEmployeeModal(false);
  const modalSaveClickHandler = (changedEmployee: api.Employee): void => {
    setShowEditEmployeeModal(false);
    setEmployee(changedEmployee);
  };
  const { session } = useContext(MutableSessionContext);

  enum Tab {
    Attendance = 0,
    Calendar = 1,
    Surveys = 2,
    Messages = 3,
    Notes = 4,
    Notifications = 5
  }

  let tabIndex = Tab.Attendance;
  switch (tab) {
    case "calendar":
      tabIndex = Tab.Calendar;
      break;
    case "notes":
      tabIndex = Tab.Notes;
      break;
    case "surveys":
      tabIndex = Tab.Surveys;
      break;
    case "messages":
      tabIndex = Tab.Messages;
      break;
    case "notifications":
      tabIndex = Tab.Notifications;
      break;
  }

  const [employee, setEmployee] = useState<api.Employee>();
  const [notes, setNotes] = useState<api.Note[]>([]);
  const [showEmployeeScheduleModal, setShowEmployeeScheduleModal] =
    useState(false);
  const [showEditRecurringSurvey, setShowEditRecurringSurvey] =
    useState<boolean>();
  const [recurringSurveyTemplateId, setRecurringSurveyTemplateId] =
    useState<string>();
  const [afterEmployeeCreate, setAfterEmployeeCreate] = useState(
    locationStateAfterEmployeeCreate
  );
  const [handledAfterEmployeeCreate, setHandledAfterEmployeeCreate] =
    useState(false);

  const [tabItems, setTabItems] = useState(TAB_ITEMS);
  const history = useHistory();

  useEffectOnce(() => {
    loadEmployeeWithEvents();
    loadNotes();
    loadRecurringSurveySchedule();
  });

  const loadEmployeeWithEvents = async (): Promise<void> => {
    const response = await api.retrieveEmployee(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      session.company!.id,
      employeeId,
      { events: true, healthSurveyActivity: true, permissionSets: true }
    );

    if (response.ok) {
      setEmployee(response.data);
    } else {
      console.debug(
        "loadEmployeeWithEvents: could not retrieve employee: ",
        response.errors
      );
    }
  };

  const loadNotes = async (): Promise<void> => {
    const response = await api.retrieveEmployeeNotes(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      session.company!.id,
      employeeId
    );

    if (response.ok) {
      setNotes(response.data);
    } else {
      console.debug(
        "loadEmployeeWithEvents: could not retrieve notes",
        response.errors
      );
    }
  };

  const loadRecurringSurveySchedule = async (): Promise<void> => {
    let response: api.APIResponse<api.RecurringSurveySchedule> | undefined;
    try {
      response = await api.retrieveEmployeeRecurringSurveySchedule(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        session.company!.id,
        employeeId
      );
    } catch {}
    setShowEditRecurringSurvey(response?.status !== 404);
    if (response?.ok) {
      setRecurringSurveyTemplateId(response.data.survey_template_id);
    }
  };

  const onCreateNote = async (
    employee: api.Employee,
    body: string
  ): Promise<void> => {
    await api.createEmployeeNote(employee.company_id, employee.id, body);
    loadNotes();
  };

  const onUpdateNote = async (
    employee: api.Employee,
    body: string,
    note: api.Note
  ): Promise<boolean> => {
    note.body = body;
    const response = await api.updateNote(
      employee.company_id,
      employee.id,
      note
    );
    if (response.ok) {
      loadNotes();
      loadEmployeeWithEvents();
      toast.success("Note updated.");
    } else {
      toast.error("Error trying to update note.");
    }

    return response.ok;
  };

  const onDeleteNote = async (
    employee: api.Employee,
    note: api.Note
  ): Promise<void> => {
    const response = await api.deleteNote(
      employee.company_id,
      employee.id,
      note
    );
    if (response.ok) {
      loadNotes();
      loadEmployeeWithEvents();
      toast.success("Note deleted.");
    } else {
      toast.error("Failed trying to delete note.");
    }
  };

  useEffect(() => {
    // Remove the signal from the location state that indicates that we just landed
    // here after creating an employee using history.replace()

    // Move this information into state (as afterEmployeeCreate) so we preserve it
    // without causing side effects if/when this page is reloaded or navigated back
    // to in the future.
    if (locationStateAfterEmployeeCreate) {
      setAfterEmployeeCreate(true);
      history.replace(location.pathname);
    }
  }, [history, location.pathname, locationStateAfterEmployeeCreate]);

  useEffect(() => {
    // In the event that we landed here after creating an employee, we should either
    // prompt the admin to set up a survey schedule OR pop up a toast indicating that
    // the employee creation was successful.

    // The handledAfterEmployeeCreate value in state ensures that we only do this ONCE.
    if (
      afterEmployeeCreate &&
      employee &&
      showEditRecurringSurvey !== undefined &&
      !handledAfterEmployeeCreate
    ) {
      setHandledAfterEmployeeCreate(true);
      if (showEditRecurringSurvey) {
        setShowEmployeeScheduleModal(true);
      }
    }
    const newTab = {
      label: "Attendance",
      relativeUrl: "/attendance",
      imageSrc: PaneUserIcon,
      restrictedTo: [
        "EmployeeAttendanceStatus.READ",
        "EmployeeHealthStatus.READ"
      ]
    };
    setTabItems([newTab, ...TAB_ITEMS]);
  }, [
    afterEmployeeCreate,
    employee,
    handledAfterEmployeeCreate,
    showEditRecurringSurvey
  ]);

  const onEmployeeChangedFromCalendar = (): void => {
    loadEmployeeWithEvents();
    // Reload notes since they may have changed
    loadNotes();
  };

  return (
    <div className="w-full h-full flex-1 flex flex-row">
      <div className="flex flex-col pt-8">
        <EmployeeSummary
          employee={employee}
          onEdit={() => setShowEditEmployeeModal(true)}
        />

        <VerticalTabList
          className="mt-5"
          items={tabItems}
          selectedIndex={tabIndex}
          onSelectItem={index =>
            history.push(
              `/dashboard/${
                ["/calendar", "/attendance"].includes(
                  tabItems[index].relativeUrl
                )
                  ? "new-employees"
                  : "employees"
              }/employee/${employeeId}${tabItems[index].relativeUrl}`
            )
          }
        />
      </div>
      <div className="flex flex-col flex-grow employee-content">
        <div className="flex flex-grow">
          <div className="w-full h-full">
            {tabIndex === Tab.Calendar && employee && (
              <EmployeeCalendarOverview
                employee={employee}
                onEmployeeChanged={onEmployeeChangedFromCalendar}
              />
            )}
            {tabIndex === Tab.Notes && employee && (
              <Restricted
                to={[
                  "EmployeeNotes.READ",
                  "EmployeeNotes.UPDATE",
                  "EmployeeNotes.DELETE",
                  "EmployeeNotes.CREATE"
                ]}
              >
                <EmployeeNotes
                  notes={notes}
                  employee={employee}
                  onCreateNote={onCreateNote}
                  onUpdateNote={onUpdateNote}
                  onDeleteNote={onDeleteNote}
                />
              </Restricted>
            )}
            {tabIndex === Tab.Surveys && employee && (
              <EmployeeConversationHistory
                employee={employee}
                showEditRecurringSurvey={showEditRecurringSurvey}
                recurringSurveyTemplateId={recurringSurveyTemplateId}
              />
            )}
            {tabIndex === Tab.Messages && employee && (
              <EmployeeMessageLog employee={employee} />
            )}
            {tabIndex === Tab.Notifications && employee && (
              <EmployeeNotifications
                employee={employee}
                onEdit={() => setShowEditEmployeeModal(true)}
              />
            )}
            {showEmployeeScheduleModal && (
              <ConfirmationModal
                prompt="Success! Would you like to set up a survey schedule too?"
                onClose={() => setShowEmployeeScheduleModal(false)}
                onConfirm={() =>
                  history.push(
                    `/dashboard/employees/employee/${employee?.id}/schedule`
                  )
                }
                confirmText="Survey settings"
                cancelText="I'll do it later"
              >
                <div className="text-hs-dark-green py-3">
                  This employee has been saved. Would you like to set up a
                  survey schedule?
                </div>
              </ConfirmationModal>
            )}
          </div>
        </div>
      </div>

      <EditEmployeeModal
        show={showEditEmployeeModal}
        employee={employee}
        modalSaveClickHandler={modalSaveClickHandler}
        modalCancelClickHandler={modalCancelClickHandler}
      />
    </div>
  );
};
