import colors from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";

const MuiPaperTheme: MuiElementTheme<"MuiPaper"> = {
  defaultProps: {
    elevation: 0
  },
  styleOverrides: {
    root: {
      border: `1px solid ${colors.gray[80]}`,
      borderRadius: "8px"
    }
  }
};

export default MuiPaperTheme;
