import React, { FunctionComponent, ReactNode } from "react";
import { useHistory } from "react-router-dom";

import { Loading } from "../loading/Loading";

import CloseIcon from "~/images/close-icon.svg";

interface FullScreenModalProps {
  title: string;
  loading?: boolean;
  headerActions?: ReactNode;
  children?: ReactNode;
}

export const FullScreenModal: FunctionComponent<FullScreenModalProps> = ({
  title,
  loading,
  headerActions,
  children
}) => {
  const history = useHistory();

  return (
    <div className="flex flex-col bg-white w-full min-h-full flex-1">
      {loading && <Loading />}
      <div className="flex flex-row flex-shrink-0 items-center w-full px-2 py-5 border-b border-hs-alt-gray">
        <img
          onClick={history.goBack}
          className="px-7 py-2 cursor-pointer"
          src={CloseIcon}
          alt="Close"
        />
        <div className="text-2xl font-semibold truncate flex-grow">{title}</div>
        {headerActions}
      </div>
      <div className="relative overflow-y-auto overflow-x-hidden h-full">
        <div className="max-w-2xl ml-8 md:ml-16 lg:ml-64 mr-auto py-2 px-4">
          {children}
        </div>
      </div>
    </div>
  );
};
