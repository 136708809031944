import React, { FunctionComponent } from "react";

import * as api from "~/api";
import {
  Dropdown,
  DropdownItem,
  DropdownProps
} from "~/components/Dropdown/Dropdown";

interface SurveyDropdownProps
  extends Omit<DropdownProps, "items" | "currentItem" | "onChangeItem"> {
  surveyTemplates: api.SurveyTemplate[];
  onChange: (templateId: string) => any;
}

export const SurveyDropdown: FunctionComponent<SurveyDropdownProps> = props => {
  const { surveyTemplates, onChange, ...restProps } = props;

  const items = surveyTemplates.map(({ id, name }) => ({ id, text: name }));

  const onChangeItem = (item: DropdownItem): void => onChange(item.id);

  return <Dropdown {...restProps} items={items} onChangeItem={onChangeItem} />;
};
