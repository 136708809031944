import React, { FunctionComponent, MouseEvent, ReactNode } from "react";

import clsx from "clsx";

import * as api from "~/api";
import { Counts } from "~/lib/stats";
import { getLabelColor, getLabelDisplay } from "~/lib/status";

export interface StatProps {
  className?: string;

  /** Optional click handler for row */
  onClick?: (e: MouseEvent) => void;

  stat: ReactNode;
  title: ReactNode;
}

export const Stat: FunctionComponent<StatProps> = props => {
  const { className, stat, onClick, title } = props;

  return (
    <div
      className={clsx(
        "bg-white rounded-md border border-solid border-gray-200 hover:border-green-500",
        className,
        onClick && "cursor-pointer"
      )}
    >
      <div
        className="flex flex-row text-center py-3 px-2 xxs:px-4"
        onClick={onClick}
      >
        <div className="w-16 xs:w-20 md:w-24 mt-1 text-3xl font-semibold text-gray-900">
          {stat}
        </div>
        <div className="flex-1 my-auto text-sm leading-4 font-medium text-gray-500">
          {title}
        </div>
      </div>
    </div>
  );
};

interface StatsProps {
  className?: string;

  stats: StatProps[];
}

const IDEAL_STATS_PER_ROW = 4;

// TODO: loading, empty, error?
export const Stats: FunctionComponent<StatsProps> = props => {
  const { className, stats } = props;

  const fullGridWidthClassName =
    stats.length % IDEAL_STATS_PER_ROW ? "md:grid-cols-3" : `md:grid-cols-4`;

  return (
    <div
      className={clsx(
        "grid grid-cols-1 gap-5 xs:grid-cols-2",
        fullGridWidthClassName,
        className
      )}
    >
      {stats.map((stat, index) => {
        return <Stat key={`stat-${index}`} {...stat} />;
      })}
    </div>
  );
};

export const countsToStats = (
  counts: Counts,
  labelInfoMap: api.LabelInfoMap,
  language: string,
  onClickStatus: (key: string) => any
): StatProps[] =>
  Object.entries(counts).map(([key, count]) => ({
    onClick: () => onClickStatus(key),
    stat: <span>{count}</span>,
    title: (
      <div>
        <div
          className="rounded-full mx-auto mb-2 w-4 h-4 p-0"
          style={{
            backgroundColor: getLabelColor(key, labelInfoMap)
          }}
        >
          &nbsp;
        </div>
        <p>{getLabelDisplay(key, labelInfoMap, language)}</p>
      </div>
    )
  }));
