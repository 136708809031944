import React, { FunctionComponent, useContext } from "react";

import { MutableSessionContext } from "~/lib/context/";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelColor, getLabelDisplay } from "~/lib/status";

import "./CalendarLegend.scss";

export const CalendarLegend: FunctionComponent = () => {
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const { unknown, ...labelsWithoutUnknown } = session.labels;
  const labelKeysWithoutUnknown = Object.keys(labelsWithoutUnknown);

  return (
    <div className="calendar-legend">
      {labelKeysWithoutUnknown.map(key => (
        <div key={`status-${key}`} className="calendar-legend-label">
          <div
            className="calendar-legend-label-color"
            style={{ backgroundColor: getLabelColor(key, session.labels) }}
          />
          {getLabelDisplay(key, session.labels, language)}
        </div>
      ))}
    </div>
  );
};
