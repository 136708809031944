import React, { FunctionComponent, useContext } from "react";

import clsx from "clsx";
import { TooltipProps } from "recharts";

import { MutableSessionContext } from "~/lib/context";
import { useLinguiLanguage } from "~/lib/hooks";
import { getLabelDisplay } from "~/lib/status";

import "./AbsenceRateByStatusTooltip.scss";

export const AbsenceRateByStatusTooltip: FunctionComponent<
  AbsenceRateByStatusTooltipProps
> = ({ active, payload, label, frameLength }) => {
  const language = useLinguiLanguage();
  const { session } = useContext(MutableSessionContext);

  const fullDetails = payload?.length ? payload?.[0].payload : {};

  const resultsTotal = Object.entries(fullDetails)
    .filter(detail => detail[0].includes("_count"))
    .map(detail => ({ count: Number(detail[1]) }))
    .reduce((total, obj) => {
      return total + obj.count;
    }, 0);

  return (
    <>
      {active && fullDetails && payload && payload.length && (
        <div className="arbs-infocard">
          <h4 className="mb-1">
            {(frameLength === "month" || frameLength === "day") && label}
            {frameLength === "week" && `Week of ${label}`}
          </h4>
          <div className="arbs-status-row mb-2">
            <span># of Responses</span>
            <span className="arbs-status-value">{resultsTotal}</span>
          </div>
          {payload
            .filter(label => label.value !== 0)
            .sort((a, b) => Number(b.value) - Number(a.value))
            .map((label, i) => (
              <div key={i} className="arbs-status-row mb-1">
                <div className="arbs-status">
                  <span
                    style={{ backgroundColor: label.color }}
                    className={clsx("legend-square")}
                  ></span>
                  {label.dataKey &&
                    getLabelDisplay(
                      label.dataKey.toString(),
                      session.labels,
                      language
                    )}
                </div>
                <span className="arbs-status-value">
                  {fullDetails[`${label.name}_count`]}
                </span>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

interface AbsenceRateByStatusTooltipProps extends TooltipProps<number, string> {
  frameLength?: "month" | "week" | "day";
}
