import { createContext } from "react";

import { I18n } from "@lingui/core";

export interface I18nContext {
  i18n?: I18n;
}

/**
 * The context that is used to propagate i18n object and updates.
 */
export const i18nHookContext = createContext<I18nContext>({});
