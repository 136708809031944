import React, { FunctionComponent, useState } from "react";

import icon from "../../../images/attendance-perfect-attendance.svg";
import { AttendanceMiniCard } from "../AttendanceMiniCard";
import { AttendanceTwoColumn } from "../AttendanceTwoColumn";

import { PerfectAttendanceModal } from "./PerfectAttendanceModal";

import * as api from "~/api";
import { FrameLength } from "~/lib/attendanceInsights";

import "./PerfectAttendanceSummary.scss";

export const PerfectAttendanceSummary: FunctionComponent<
  PerfectAttendanceSummaryProps
> = ({ timespan, isLoading, data, startDate, frameLength, hideReport }) => {
  const [showModal, setShowModal] = useState(false);

  const renderText = (): JSX.Element => {
    return (
      <>
        <p>
          Employees Maintaining Perfect Attendance{" "}
          {timespan !== "All Time" ? "during the" : "for"}{" "}
          {timespan?.toLowerCase()}{" "}
          <em>excluding any applied status filters</em>
        </p>
        <PerfectAttendanceModal
          showModal={showModal}
          handleExit={() => setShowModal(false)}
          timespan={timespan}
          startDate={startDate}
          frameLength={frameLength}
        />
      </>
    );
  };

  return (
    <AttendanceMiniCard>
      <>
        <AttendanceTwoColumn
          isLoading={isLoading}
          heading={data?.perfectAttendance?.toString()}
          text={renderText()}
          icon={icon}
          onCTAClick={() => setShowModal(true)}
          hideReport={hideReport}
        />
      </>
    </AttendanceMiniCard>
  );
};

interface PerfectAttendanceSummaryProps {
  timespan?: string;
  isLoading: boolean;
  data?: api.AttendanceInsightsEmployeeResponse;
  startDate?: string;
  frameLength?: FrameLength;
  labelList?: string;
  divisionList?: string;
  hideReport: boolean;
}
