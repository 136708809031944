import React, { FunctionComponent } from "react";

import clsx from "clsx";

import { AttendanceSyncStatusIcon } from "./AttendanceSyncStatusIcon";

import * as api from "~/api";
import "./AttendanceSyncStatusButton.scss";

const SYNCED_TEXT = "Synced to Time & Attendance";
const NOT_SYNCED_TEXT = "Mark synced to Time & Attendance";
const PENDING_SYNC_TEXT = "Sync pending to Time & Attendance";

const getTextFromSyncStatus = (
  status?: api.AlteredEventSyncStatus,
  automaticSync?: boolean
): string | JSX.Element => {
  switch (status) {
    case "changed":
    case "not_synced":
      return automaticSync ? PENDING_SYNC_TEXT : NOT_SYNCED_TEXT;
    case "errored":
      return NOT_SYNCED_TEXT;
    case "synced":
      return SYNCED_TEXT;
    default:
      return <>&nbsp;</>;
  }
};

const getClassNameFromSyncStatus = (
  status?: api.AlteredEventSyncStatus,
  automaticSync?: boolean
): string | JSX.Element => {
  switch (status) {
    case "changed":
      return automaticSync ? "not-synced" : "changed";
    case "synced":
      return "synced";
    default:
      return "not-synced";
  }
};

export const AttendanceSyncStatusButton: FunctionComponent<
  AttendanceSyncStatusButtonProps
> = ({ status, automaticSync, disabled, onEventStatusSync, event }) => {
  const buttonText = getTextFromSyncStatus(status, automaticSync);
  const baseClassName = getClassNameFromSyncStatus(status, automaticSync);

  if (automaticSync) {
    return (
      <div className={clsx(baseClassName, "attendance-sync-status-auto")}>
        <div className="mr-2">{buttonText}</div>
        <AttendanceSyncStatusIcon status={status} ignoreChanged={true} />
      </div>
    );
  }

  // Determine the new sync status once this toggle is clicked
  const newStatus = status === "synced" ? "not_synced" : "synced";

  return (
    <button
      disabled={disabled || !status}
      onClick={() => {
        onEventStatusSync(event.id, newStatus);
      }}
      className={clsx(
        baseClassName,
        "inline-flex",
        "items-center",
        "btn btn-outline-secondary btn-sm",
        "border-solid",
        "text-sm",
        "attendance-sync-status-button"
      )}
    >
      <div className="mr-2">{buttonText}</div>
      <AttendanceSyncStatusIcon status={status} />
    </button>
  );
};

interface AttendanceSyncStatusButtonProps {
  status?: api.AlteredEventSyncStatus;
  disabled?: boolean;
  automaticSync?: boolean;
  event: api.BaseEmployeeEvent;
  onEventStatusSync: (
    event_id: string,
    status: api.AlteredEventSyncStatus
  ) => void;
}
