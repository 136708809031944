import React, { FunctionComponent, ReactNode } from "react";

import clsx from "clsx";

interface ContentCardProps {
  /** The title for the dashboard pane */
  title?: string;
  outerWrapperClassName?: string;
  id?: string;
  children?: ReactNode;
}

/** Generic card for content. */
export const ContentCard: FunctionComponent<ContentCardProps> = ({
  title,
  children,
  outerWrapperClassName,
  id
}) => (
  <div id={id} className={clsx("content-card", outerWrapperClassName)}>
    {title && <h2 className="content-card-heading">{title}</h2>}
    <div>{children}</div>
  </div>
);
