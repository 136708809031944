import { Division, ISODateTime, Group, SurveyState } from "./";

export interface TeamLead {
  id: string;
  company_id: string;
  name: string;
}

export interface ExternalEmployee {
  id: string;
  friendly_name: string;
}

export interface Note {
  id: string;
  employee_id: string;
  body: string;
  created: string;
  created_by_id?: string;
  created_by_name?: string;
  updated: string;
  updated_by_id?: string;
  updated_by_name?: string;
  has_employee_events: boolean;
}

export enum LabelSet {
  CovidHealthStatus = "health_status",
  AttendanceStatus = "attendance_status",
  PointsNotification = "points_notification"
}

type CovidHealthEventStatus = "sick" | "clear";

export type CovidHealthStatus = CovidHealthEventStatus | "unknown";

export interface HealthLabel {
  name: CovidHealthEventStatus;
  label_set: LabelSet.CovidHealthStatus;
}

export const isCovidHealthStatus = (key: string): boolean =>
  ["sick", "clear", "unknown"].includes(key);

export const isAttendanceStatus = (key: string): boolean =>
  !isCovidHealthStatus(key);
export type AttendanceStatus = string;

export type AnyLabelStatus = CovidHealthEventStatus | AttendanceStatus;
export type AnyStatus = CovidHealthStatus | AttendanceStatus;
export type AnyStatusPlusNone = CovidHealthStatus | AttendanceStatus | "None";

export interface AttendanceLabel {
  name: string;
  label_set: LabelSet.AttendanceStatus;
}

export interface StatusCounts {
  id: string;
  datetime: string;
  [LabelSet.CovidHealthStatus]: {
    [key: string]: number;
  };
  [LabelSet.AttendanceStatus]: {
    [key: string]: number;
  };
  unknown?: number;
  total: number;
}

export type EventSyncStatus = "changed" | "synced" | "not_synced";

export type AlteredEventSyncStatus = EventSyncStatus | "errored";

export interface BaseEmployeeEvent {
  id: string;
  label: HealthLabel | AttendanceLabel;
  created: ISODateTime;
  started: ISODateTime;
  note?: Note;
  ended?: ISODateTime;
  is_active: boolean;
  sync_status?: AlteredEventSyncStatus;
  last_sync_by?: string;
  sync_timestamp?: ISODateTime;
}

export interface EmployeeEvent extends BaseEmployeeEvent {
  employee_id: string;
}

export interface EmployeeEventSurvey extends BaseEmployeeEvent {
  employee_id: string;
  survey?: SurveyState;
}

export enum NotificationPreference {
  sms = "SL",
  email = "EL",
  both = "BL",
  none = ""
}

export enum EmployeeRole {
  owner = "owner",
  divisionAdmin = "division_admin",
  companyWideAdmin = "company_wide_admin",
  teamLeader = "team_leader",
  employee = "employee"
}

export interface EmployeeRoleOptions {
  displayText: string;
  role: string;
  description: string;
  disabled: boolean;
}

export interface EmployeeHealthSurveyActivity {
  last_scheduled?: string;
  last_completed?: string;
}

export interface IdentificationEmployee {
  id: string;
  name: string;
  external_id: string;
}

export interface IdentificationEmployeeReference
  extends Partial<Omit<IdentificationEmployee, "id">> {
  id: string;
}

export interface SimpleEmployee extends IdentificationEmployee {
  timezone: string;
  division?: Division;
}

export interface ResultsEmployee extends IdentificationEmployee {
  division?: Division;
  team_lead?: TeamLead;
}

// Used within front-end code (not in current API responses)
export interface SimpleEmployeeWithEvents extends SimpleEmployee {
  events: EmployeeEventWithEmployee[];
}

export interface SimpleEmployeeWithFullNameDetails extends SimpleEmployee {
  given_name: string;
  family_name: string;
}

export interface Employee extends SimpleEmployee {
  user_id: string | null;
  badge_id?: string;
  company_id: string;
  given_name: string;
  family_name: string;
  preferred_name: string;
  friendly_name: string;
  first_name: string; // Historical - remove once no longer referenced in message template strings
  phone_number: string | null;
  notify_email?: string | null;
  notification_preference: NotificationPreference;
  preferred_language: string;
  greeting: string;
  is_admin: boolean;
  is_team_lead?: boolean;
  url: string;
  team_lead?: TeamLead | null;
  groups?: Group[];
  events?: EmployeeEvent[];
  active_events?: EmployeeEvent[];
  health_survey_activity?: EmployeeHealthSurveyActivity;
  role?: EmployeeRole;
  role_label?: string;
  alternate_for?: TeamLead[];
  admin_divisions?: Division[];
  message?: string;
  permission_sets?: string[];
}

export interface InsightsEmployee extends SimpleEmployee {
  absencesCount: number;
  labels: Array<{ name: string; count: number }>;
  division?: Division;
  externalId?: string;
  teamLead?: TeamLead;
  perfectAttendanceSince: string;
}

export type MessageStatus =
  | "delivered"
  | "received"
  | "undelivered"
  | "queued"
  | "sent"
  | "accepted"
  | "no_destination"
  | "opted_out"
  | "error"
  | "rejected"
  | "failed"
  | "logged"
  | "unknown";

/** A description of an attempted or successful employee notification. */
export interface EmployeeNotificationStatus {
  /** The time the notification was sent. */
  sent: ISODateTime;

  /** The notification channel used. */
  channel: "sms" | "email";

  /** the status of the message */
  status: MessageStatus;

  /** The target of the delivery, like a phone number or email address. */
  target?: string;

  /** Optional details about delivery, including error details. */
  details?: string;
}

export interface NewEmployee
  extends Omit<
    Employee,
    | "id"
    | "name"
    | "user_id"
    | "friendly_name"
    | "first_name"
    | "greeting"
    | "is_admin"
    | "is_company_wide_admin"
    | "url"
    | "division"
  > {
  division?: Omit<Division, "name" | "code">;
}

export interface EmployeeEventWithEmployee extends BaseEmployeeEvent {
  employee: SimpleEmployeeWithFullNameDetails;
}
