import { useState, useEffect } from "react";

export const useIsMobile = (): boolean => {
  const mobileMaxWidth = 768;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileMaxWidth);
  const handleWindowSizeChange = (): void =>
    setIsMobile(window.innerWidth <= mobileMaxWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return isMobile;
};
