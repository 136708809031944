import React, { FunctionComponent } from "react";

import clsx from "clsx";
import { DateTime, Interval } from "luxon";

import { LabelColorMapInterface } from "./StatusCalendar";

import * as api from "~/api";
import "./CalendarShape.scss";

export const CalendarShape: FunctionComponent<CalendarShapeInterface> = ({
  singleEvent,
  currentDay,
  colorMap
}) => {
  const start = DateTime.fromISO(singleEvent.started);
  const end = singleEvent.ended
    ? DateTime.fromISO(singleEvent.ended)
    : DateTime.local().endOf("day");
  const dateRange = Interval.fromDateTimes(start, end);

  const ongoing = singleEvent.ended === null;
  // we check event happenings at 20:00
  const currentDayAtEightPM = currentDay.endOf("day").minus({ hours: 4 });
  const singleDayEvent = dateRange.length("day") <= 1;
  const isFirstDay = start.hasSame(currentDayAtEightPM, "day");
  const hoursUntilEnd = end.diff(currentDayAtEightPM, "hours").hours;

  // only 1 color ever associated with an event, so we grab the first array item
  const fillColors = colorMap.filter(c => c.status === singleEvent?.label.name);
  let fillColor = "#ebf0ff";

  if (fillColors) {
    fillColor = fillColors[0]?.lightColor;
  }

  return (
    <div
      style={{ backgroundColor: fillColor, color: fillColor }}
      className={clsx(
        "calendar-shape",
        singleDayEvent && "single-day",
        !singleDayEvent && isFirstDay && "multi-day--start",
        !singleDayEvent && hoursUntilEnd > 24 && "multi-day--span",
        !singleDayEvent && hoursUntilEnd <= 24 && "multi-day--end",
        ongoing && "ongoing"
      )}
    ></div>
  );
};

interface CalendarShapeInterface {
  singleEvent: api.EmployeeEvent;
  currentDay: DateTime;
  colorMap: LabelColorMapInterface[];
}
