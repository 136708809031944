import React, { FunctionComponent, ReactNode } from "react";

import { ToggleButton } from "~/components/ToggleButton/ToggleButton";

interface ToggleControlProps {
  on: boolean;
  onToggle: (on: boolean) => any;
  nameOn: ReactNode;
  imageSrcOn?: string;
  selectedImageSrcOn?: string;
  nameOff: ReactNode;
  imageSrcOff?: string;
  selectedImageSrcOff?: string;
}

export const ToggleControl: FunctionComponent<ToggleControlProps> = ({
  on,
  onToggle,
  nameOn,
  imageSrcOn,
  selectedImageSrcOn,
  nameOff,
  imageSrcOff,
  selectedImageSrcOff
}) => (
  <div className="pt-3 flex flex-row">
    <ToggleButton
      className="flex-1"
      selected={!on}
      name={nameOff}
      onClick={() => onToggle(false)}
      imageSrc={imageSrcOff}
      selectedImageSrc={selectedImageSrcOff}
    />
    <ToggleButton
      className="flex-1"
      selected={on}
      name={nameOn}
      onClick={() => onToggle(true)}
      imageSrc={imageSrcOn}
      selectedImageSrc={selectedImageSrcOn}
    />
  </div>
);
