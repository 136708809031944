import React, {
  FC,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo
} from "react";
import { Modal } from "react-bootstrap";

import { FormScheduleRecurrence } from "./FormScheduleRecurrence";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import {
  FormScheduleRecurrenceDetails,
  SurveyScheduleRecipients
} from "~/types";
import {
  SelectAudience,
  SelectAudienceOptions,
  GenericErrorText,
  ToastContext,
  TSButton,
  TSInput,
  TSInputChangeEvent
} from "~common";

// Bootstrap `sm` screen width
const MOBILE_WIDTH = 576;

export const FormScheduleModal: FC<FormScheduleModalProps> = props => {
  const {
    show,
    handleClose,
    details,
    isEditMode,
    formId,
    loadSchedules,
    isAnonymous,
    isCovid
  } = props;
  const { recipientGroups } = details;

  const { session } = useContext(MutableSessionContext);
  const toast = useRef(useContext(ToastContext));

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [scheduleName, setScheduleName] = useState(details?.name || "");

  // data coming from recurrence and audience and bound for the api
  const [recurrencePayload, setRecurrencePayload] =
    useState<FormScheduleRecurrenceDetails>({});
  const [recipientGroupsPayload, setRecipientGroupsPayload] =
    useState(recipientGroups);

  const [showErrors, setShowErrors] = useState(false);
  const [recurrenceIsValid, setRecurrenceIsValid] = useState(true);
  const [recipientsIsValid, setRecipientsIsValid] = useState(true);

  const [loadRecipients, setLoadRecipients] = useState(false);
  const [recipientCount, setRecipientCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  // Utility methods
  // convert recipientGroups from the API to an object
  // formatted for the SelectAudience component
  const recipientsToOptions = useMemo<SelectAudienceOptions>(() => {
    const employeeOptions = (recipientGroups?.employees ?? []).map(
      (option: { employeeId: string; name: string }) => ({
        value: option.employeeId,
        label: option.name
      })
    );
    const groupOptions = (recipientGroups?.groups ?? []).map(
      (option: { groupId: string; name: string }) => ({
        value: option.groupId,
        label: option.name
      })
    );
    const divisionOptions = (recipientGroups?.divisions ?? []).map(
      (option: { divisionId: string; name: string }) => ({
        value: option.divisionId,
        label: option.name
      })
    );

    return {
      allCompany: recipientGroups?.allCompany,
      employees: employeeOptions,
      groups: groupOptions,
      divisions: divisionOptions
    };
  }, [recipientGroups]);

  // convert SelectAudience component options to an object
  // formatted for the form schedule api
  const optionsToRecipients = (
    audience: SelectAudienceOptions
  ): SurveyScheduleRecipients => {
    const employeeOptions = (audience?.employees ?? []).map(
      (option: { value: string; label: string }) => ({
        employeeId: option.value,
        name: option.label
      })
    );
    const groupOptions = (audience?.groups ?? []).map(
      (option: { value: string; label: string }) => ({
        groupId: option.value,
        name: option.label
      })
    );
    const divisionOptions = (audience?.divisions ?? []).map(
      (option: { value: string; label: string }) => ({
        divisionId: option.value,
        name: option.label
      })
    );

    return {
      allCompany: audience?.allCompany,
      employees: employeeOptions,
      groups: groupOptions,
      divisions: divisionOptions
    };
  };

  const showGenericErrorToast = (): void => {
    toast.current.show({
      variant: "danger",
      message: <GenericErrorText />
    });
  };

  const postAndUpdateSchedule = async (): Promise<void> => {
    if (!session.company?.id) {
      return;
    }

    const { frequency, schedules } = recurrencePayload;
    const payload = {
      name: scheduleName,
      companyId: session.company?.id,
      templateId: formId,
      frequency,
      recipientGroups: recipientGroupsPayload,
      schedules
    } as FormScheduleRecurrenceDetails;

    try {
      let response: api.APIResponse<FormScheduleRecurrenceDetails>;

      if (isEditMode) {
        payload.id = details.id;
        response = await api.updateSurveySchedule(payload);
      } else {
        response = await api.addSurveySchedule(payload);
      }

      if (response.ok) {
        loadSchedules();
        modalCloseHandler();

        toast.current.show({
          variant: "success",
          message: isEditMode
            ? "Your schedule changes have been saved."
            : "A new schedule has been added."
        });
      } else {
        showGenericErrorToast();
      }
    } catch {
      showGenericErrorToast();
    }
  };

  // Event Handlers
  const modalCloseHandler = (): void => {
    setShowErrors(false);
    setScheduleName("");
    handleClose();
  };

  const postAndUpdateScheduleHandler = (): void => {
    setShowErrors(true);
    const formIsValid = scheduleName && recurrenceIsValid && recipientsIsValid;
    formIsValid && postAndUpdateSchedule();
  };

  const scheduleNameHandler = (event: TSInputChangeEvent): void => {
    const target = event.target as HTMLInputElement;
    target && setScheduleName(target.value);
  };

  const scheduleModalExitHandler = (): void => {
    if (showDeleteConfirmation) {
      setShowDeleteConfirmation(false);
    }
  };

  const confirmDeleteClickHandler = async (): Promise<void> => {
    if (details) {
      try {
        const response = await api.deleteSurveySchedule(details);
        if (response.ok) {
          loadSchedules();
          modalCloseHandler();

          toast.current.show({
            variant: "danger",
            message: `${details.name || "Schedule"} has been deleted.`
          });
        } else {
          showGenericErrorToast();
        }
      } catch {
        showGenericErrorToast();
      }
    }
  };

  const recurrenceChangeHandler = (
    details: FormScheduleRecurrenceDetails,
    isValid: boolean
  ): void => {
    setRecurrencePayload(details);
    setRecurrenceIsValid(isValid);
  };

  const audienceChangeHandler = (
    audience: SelectAudienceOptions,
    isValid: boolean
  ): void => {
    const convertedRecipients = optionsToRecipients(audience);
    setRecipientGroupsPayload(convertedRecipients);
    setRecipientsIsValid(isValid);
  };

  const recipientCountChangeHandler = useCallback((data: number) => {
    setRecipientCount(data);
  }, []);

  // Effects
  useLayoutEffect(() => {
    // Determine if we're mobile by checking out width.
    const updateMobileByWidth = (): void => {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };

    // Hookup to resize window event to track when we hit
    // a mobile width and execute at least once on load.
    window.addEventListener("resize", updateMobileByWidth);
    updateMobileByWidth();

    return () => window.removeEventListener("resize", updateMobileByWidth);
  }, []);

  return (
    <>
      <Modal
        container={document.getElementById("ts-modal")}
        show={show}
        onHide={modalCloseHandler}
        onEntered={() => setLoadRecipients(true)}
        size="lg"
        onExited={scheduleModalExitHandler}
        className={showDeleteConfirmation ? "visually-hidden" : ""}
        centered
      >
        <Modal.Header>
          <h1 className="mb-0">
            {isEditMode && `Edit ${details?.name}`}
            {!isEditMode && `Add Survey Schedule`}
          </h1>
        </Modal.Header>
        <Modal.Body>
          <h1>Set Schedule</h1>
          <TSInput
            placeholder="Schedule Name"
            defaultValue={scheduleName}
            required
            label="Schedule Name"
            onChange={event => scheduleNameHandler(event)}
            isInvalid={showErrors && !scheduleName}
            errorText="Please enter a schedule name"
          />
          <FormScheduleRecurrence
            details={details}
            recurrenceChangeHandler={(
              details: FormScheduleRecurrenceDetails,
              isValid: boolean
            ) => recurrenceChangeHandler(details, isValid)}
            showErrors={showErrors}
          />

          <h1 className="mt-4">Select Recipients</h1>
          {/* for covid surveys, ONLY show employees */}
          {/* for anonymous surveys, DO NOT show employees */}
          <SelectAudience
            checkboxPrepend="Set Schedule for"
            triggerLoad={loadRecipients}
            audience={recipientsToOptions}
            audienceCountChangeHandler={recipientCountChangeHandler}
            audienceChangeHandler={(
              audience: SelectAudienceOptions,
              isValid: boolean
            ) => audienceChangeHandler(audience, isValid)}
            showErrors={showErrors}
            requiredMessage="Please make a selection"
            isCovid={isCovid}
            enableAllCompany={!isCovid}
            enableEmployees={!isAnonymous}
            enableGroups={!isCovid}
            enableDivisions={!isCovid}
          />

          {/* Recipient Totals message */}
          <h2 className="pt-3 mt-3">
            {recipientCount || 0} {recipientCount === 1 ? "Person" : "People"}{" "}
            will receive this message
          </h2>
        </Modal.Body>
        <Modal.Footer>
          {isEditMode && (
            <TSButton
              startIcon="icon-trash"
              variant="danger"
              className="me-auto"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              {!isMobile && "Delete"}
            </TSButton>
          )}

          <TSButton variant="text" onClick={modalCloseHandler}>
            Close
          </TSButton>

          <TSButton variant="primary" onClick={postAndUpdateScheduleHandler}>
            Schedule
          </TSButton>
        </Modal.Footer>
      </Modal>

      {/* Delete confirmation modal */}
      <Modal
        className="confirm-delete-modal"
        container={document.getElementById("ts-modal")}
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        centered
      >
        <Modal.Body>
          <h1 className="text-danger">
            Are you sure you want to permanently delete this schedule?
          </h1>
          <p>You can't retrieve deleted schedules.</p>
        </Modal.Body>
        <Modal.Footer>
          <TSButton
            variant="link"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </TSButton>
          <TSButton
            variant="danger"
            endIcon="icon-trash"
            onClick={confirmDeleteClickHandler}
          >
            Delete
          </TSButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface FormScheduleModalProps {
  handleClose: Function;
  show: boolean;
  details: FormScheduleRecurrenceDetails;
  isEditMode: boolean;
  formId: string;
  loadSchedules: Function;
  isAnonymous: boolean;
  isCovid: boolean;
}
