import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";

import { Link } from "~/components/Link/Link";

export const TermsAndPrivacy: FunctionComponent<{}> = () => (
  <div className="text-xs text-hs-even-darker-gray">
    <Trans
      id="register.welcome.terms"
      components={[
        <Link
          key="1"
          external
          to="https://www.teamsense.com/terms-of-service"
          className="text-hs-even-darker-gray underline"
        >
          {""}
        </Link>,
        <Link
          key="2"
          external
          to="https://www.teamsense.com/privacy-policy"
          className="text-hs-even-darker-gray underline"
        >
          {""}
        </Link>
      ]}
    >
      Read our <Link to="">Terms of Service</Link> and{" "}
      <Link to="">Privacy Policy</Link>
    </Trans>
  </div>
);
