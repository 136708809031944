import React, {
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useCallback
} from "react";

import clsx from "clsx";
import { isFunction } from "lodash";

interface Props {
  children: string | ReactNode;
  className?: string;
  disabled?: boolean;
}

interface ClickProps extends Props {
  onClick: (event: MouseEvent) => void;
  submit?: boolean;
}

interface SubmitProps extends Props {
  onClick?: (event: MouseEvent) => void;
  submit: boolean;
}

export const Button: FunctionComponent<ClickProps | SubmitProps> = props => {
  const { children, className, onClick, disabled, submit } = props;

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (!disabled && isFunction(onClick)) {
        onClick(event);
      }
    },
    [disabled, onClick]
  );

  return (
    <button
      className={clsx("button", className, { "button--disabled": disabled })}
      onClick={handleClick}
      type={submit ? "submit" : "button"}
      data-testid="button"
    >
      {children}
    </button>
  );
};
