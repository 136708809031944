import React, { FunctionComponent, useState, ReactNode } from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import { SearchEmployee } from "../Search/SearchEmployee";

import { Message } from "./Message";
import { ScreenerConfirmation } from "./ScreenerConfirmation";

import * as api from "~/api";
import { MessageActionButton } from "~common";

interface ScreenerProps {
  className?: string;
  token: string;
  onEmployeeSelected?: (employee?: api.IdentificationEmployee) => any;
  employee?: api.Employee;
  children?: ReactNode;
}

export const Screener: FunctionComponent<ScreenerProps> = props => {
  const { className, children, token, employee, onEmployeeSelected } = props;
  const [agreed, setAgreed] = useState(false);

  if (agreed && employee) {
    return <>{children}</>;
  }

  return (
    <div
      className={clsx(
        "flex flex-col absolute inset-0 p-4",
        !agreed && "justify-end",
        className
      )}
    >
      {!agreed && (
        <>
          <Message
            className="mb-2.5"
            text={
              <Trans id="screener.message">
                You will be able to enter data for individuals, but will not be
                able to view data once entered.
              </Trans>
            }
          ></Message>
          <ScreenerConfirmation className="mb-5" />
          <MessageActionButton
            text={<Trans id="screener.agree">I agree</Trans>}
            theme="green"
            onClick={() => setAgreed(true)}
          />
        </>
      )}
      {agreed && !employee && (
        <>
          <div className="my-4 text-hs-dark-green" dir="auto">
            <Trans id="screener.search.title">
              Search by employee name or ID:
            </Trans>
          </div>
          <SearchEmployee
            id="searchEmployee"
            className="ts"
            key="search"
            placeholder="Search"
            presentation="mobile"
            apiToken={token}
            onEmployeeSelected={onEmployeeSelected}
          />
        </>
      )}
    </div>
  );
};
