import React, { FunctionComponent } from "react";
import { Redirect } from "react-router-dom";

import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

export const AzureLogin: FunctionComponent = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Redirect to="/" />
    </MsalAuthenticationTemplate>
  );
};
