import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import * as api from "~/api";
import { EditNotifications } from "~/components/EditNotifications/EditNotifications";
import { MutableSessionContext } from "~/lib/context/";
import { saveScheduleChange } from "~/lib/schedule";
import {
  FullScreenModal,
  GenericErrorText,
  MessageActionButton
} from "~common";

interface ParamTypes {
  employeeId?: string;
}

/** The edit employee schedule pane view */
export const EditEmployeeSchedulePane: FunctionComponent = () => {
  const history = useHistory();
  const { session } = useContext(MutableSessionContext);

  const { employeeId } = useParams<ParamTypes>();
  const [triggerNavigateBack, setTriggerNavigateBack] = useState(false);
  const [employee, setEmployee] = useState<api.Employee>();

  // form status
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [scheduleDirty, setScheduleDirty] = useState(false);

  // schedule data
  const [currentWeekdayTimes, setCurrentWeekdayTimes] =
    useState<api.WeeklySchedule>();

  // methods...

  // submit schedule changes
  const onSaveClicked = async (): Promise<void> => {
    if (!employee) {
      return;
    }

    setSubmitting(true);
    const saveScheduleSuccess =
      scheduleDirty && currentWeekdayTimes
        ? await saveScheduleChange({
            employee,
            schedule: {
              schedule: currentWeekdayTimes
            },
            suppressSuccess: true
          })
        : true;

    setSubmitting(false);

    if (saveScheduleSuccess) {
      toast.success(`${employee.name}'s survey settings have been updated.`);
      setTriggerNavigateBack(true);
    }
  };

  // effects...

  // TODO: think it'd be good to centralize the api calls into utilities.
  // this logic is repeated in a lot of places.

  // load employee
  useEffect(() => {
    const loadEmployee = async (): Promise<void> => {
      if (!employeeId || !session?.company?.id) {
        return;
      }
      let response: api.APIResponse<api.Employee> | undefined;

      setEmployeeLoading(true);
      try {
        response = await api.retrieveEmployee(session?.company?.id, employeeId);
      } catch {}
      setEmployeeLoading(false);

      if (response?.ok) {
        setEmployee(response.data);
      } else {
        toast.error(<GenericErrorText />);
      }
    };

    loadEmployee();
  }, [employeeId, session]);

  // handle navigation
  useEffect(() => {
    if (triggerNavigateBack) {
      history.goBack();
      return;
    }
    const unblock = history.block(() => {
      if (scheduleDirty) {
        return "Are you sure you want to leave?\nAny changes you have made will be discarded.";
      }
    });
    return unblock;
  }, [scheduleDirty, history, triggerNavigateBack]);

  return (
    <FullScreenModal
      title={`Edit Survey Schedule for ${employee?.name ?? ""} ${
        employee?.preferred_name ? `(${employee.preferred_name})` : ""
      }`}
      loading={employeeLoading || submitting}
      headerActions={
        <MessageActionButton
          inline
          className="w-32"
          disabled={!scheduleDirty}
          text="Save"
          theme="green"
          onClick={onSaveClicked}
        />
      }
    >
      {employee && (
        <>
          <EditNotifications
            employee={employee}
            desktopTimePicker
            onWeekdayTimesChange={setCurrentWeekdayTimes}
            onDirtyChange={setScheduleDirty}
          />
        </>
      )}
    </FullScreenModal>
  );
};
