import React, { FunctionComponent, useContext } from "react";

import { Trans } from "@lingui/react";

import { EmployeeRole } from "~/api";
import EditIcon from "~/images/edit-icon.svg";
import DeleteIcon from "~/images/trash-icon.svg";
import { MutableSessionContext } from "~/lib/context";
import { TSPill } from "~common";

export const AttendanceEventHeader: FunctionComponent<
  AttendanceEventHeaderProps
> = ({
  pill,
  pill: { text, color, background },
  title,
  created,
  showActions,
  handleEdit,
  handleDelete
}) => {
  const { session } = useContext(MutableSessionContext);
  const showDelete = session?.employee?.role !== EmployeeRole.teamLeader;
  return (
    <div className="lg:flex md:flex-row justify-between">
      <div className="flex-row">
        {pill && <TSPill text={text} background={background} color={color} />}
        {title && (
          <div className="text-lg text-ts-gray-20 font-semibold	pt-2 pb-2">
            {title}
          </div>
        )}
        {created && (
          <div className="text-ts-gray-40 text-sm font-medium">
            <Trans id="attendanceEventHeader.event.created">Created on</Trans>{" "}
            <span className="font-bold">{created}</span>
          </div>
        )}
      </div>
      {showActions && (
        <div className="flex-row">
          <button
            className="inline-flex p-2 text-ts-teal-40 font-bold"
            onClick={handleEdit}
          >
            <img className="mr-2" src={EditIcon} alt="edit" /> Edit
          </button>
          {showDelete && (
            <button
              className="inline-flex p-2 text-ts-red-20 font-bold"
              onClick={handleDelete}
            >
              <img className="mr-2" src={DeleteIcon} alt="delete" />
              Delete
            </button>
          )}
        </div>
      )}
    </div>
  );
};

interface AttendanceEventHeaderProps {
  pill: {
    text: string;
    color: string;
    background: string;
  };
  title?: string;
  created?: string;
  showActions?: boolean;
  handleEdit?: () => void;
  handleDelete?: () => void;
}
