import React, { FunctionComponent, useContext } from "react";

import * as api from "~/api";
import { Link } from "~/components/Link/Link";
import { MutableSessionContext } from "~/lib/context/";
import { displayPhoneNumber } from "~/lib/phoneNumber";
import { showDivisions } from "~/lib/showDivisions";
import { ContentCard } from "~common";

export interface EmployeeSummaryProps {
  employee?: api.Employee;
  onEdit?: () => any;
}

interface SummaryField {
  heading: string;
  value: string | React.ReactNode;
}

const EMPTY_LINE = <>&mdash;</>;
const EMPTY_SPACE_LINE = <>&nbsp;</>;

export const EmployeeSummary: FunctionComponent<EmployeeSummaryProps> = ({
  employee,
  onEdit
}) => {
  const { session } = useContext(MutableSessionContext);

  const emailValue = employee?.notify_email ? (
    <Link
      key="email"
      external
      to={`mailto:${employee?.notify_email}`}
      className="block truncate"
    >
      {employee?.notify_email}
    </Link>
  ) : (
    EMPTY_LINE
  );
  const phoneValue = displayPhoneNumber(employee?.phone_number) ?? EMPTY_LINE;

  const summaryFields: SummaryField[] = [
    { heading: "Phone", value: phoneValue },
    {
      heading: "Email",
      value: emailValue
    },
    { heading: "ID", value: employee?.external_id },
    { heading: "Manager", value: employee?.team_lead?.name },
    ...(showDivisions(session)
      ? [{ heading: "Division", value: employee?.division?.name }]
      : []),
    ...[{ heading: "Role", value: employee?.role_label }]
  ];

  return (
    <ContentCard outerWrapperClassName="w-63 m-0 mb-5 ml-5 p-5">
      <div className="flex flex-row">
        <div className="flex-grow hs-dark-green text-base font-semibold">
          {employee?.name ?? EMPTY_SPACE_LINE}
        </div>
        <div
          className="text-hs-green text-sm text-center my-auto underline hover:text-hs-dark-green-60 cursor-pointer"
          onClick={onEdit}
        >
          Edit
        </div>
      </div>
      <div className="h-px bg-hs-alt-gray my-5-2" />
      <div className="flex flex-col space-y-1.5 overflow-x-hidden">
        {summaryFields.map(({ heading, value }, index) => (
          <div key={`field-${index}`}>
            <div className="text-xxs text-hs-black-40 tracking-widest font-semibold uppercase">
              {heading}:
            </div>
            <div className="text-sm py-1-2 text-hs-dark-green truncate">
              {value}
              {!value && EMPTY_LINE}
            </div>
          </div>
        ))}
      </div>
    </ContentCard>
  );
};
