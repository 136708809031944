import React, { FC, useContext, useEffect, useState } from "react";
import { Collapse, Form } from "react-bootstrap";
import { Token } from "react-bootstrap-typeahead";

import clsx from "clsx";

import { TSTypeahead } from "./ts-inputs/TSTypeahead";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { Method, useApi } from "~/lib/hooks/useApi";
import { employeeSearchHandler } from "~/lib/typeaheadHandler";
import { TSTypeaheadOption } from "~common";

export const SelectAudience: FC<SelectAudienceProps> = props => {
  const {
    triggerLoad,
    className,
    readOnly,
    audience,
    audienceChangeHandler,
    audienceCountChangeHandler,
    label,
    checkboxPrepend,
    showErrors,
    requiredMessage,
    fullGroupList,
    isCovid = false,
    isAnonymous = false,
    enableAllCompany = true,
    enableEmployees = true,
    enableGroups = true,
    enableDivisions = true,
    enableDirectReports = true,
    hideCheckboxes = [],
    disableApi = false
  } = props;

  const { session } = useContext(MutableSessionContext);
  const companyId = session.company?.id ?? "";

  const checkboxPhrase = (option: string): JSX.Element => (
    <span>
      {checkboxPrepend} <strong>{option}</strong>
    </span>
  );

  const checkboxLabels = {
    all: checkboxPhrase(`Entire Company`),
    employees: checkboxPhrase("Selected Individual Employees"),
    groups: checkboxPhrase("Everyone in a Selected Group"),
    divisions: checkboxPhrase("Everyone in a Selected Division"),
    directReports: checkboxPhrase("Someone's Direct Reports")
  };

  // If more than one feature toggle is true then we show checkboxes
  const featureToggles = [
    enableAllCompany,
    enableEmployees,
    enableGroups,
    enableDivisions,
    enableDirectReports
  ];

  const [showCheckboxes] = useState(
    featureToggles.filter(flag => flag).length > 1
  );

  const showSpecificCheckbox = (kind: string): boolean =>
    showCheckboxes && !hideCheckboxes.includes(kind);

  // full lists pulled from apis and formatted to fit the type
  const [allGroups, setAllGroups] = useState<TSTypeaheadOption[]>([]);
  const [allDivisions, setAllDivisions] = useState<TSTypeaheadOption[]>([]);

  // employees, divisions and groups selected in the dropdowns
  const [selectedEmployees, setSelectedEmployees] = useState<
    TSTypeaheadOption[]
  >(audience?.employees || []);
  const [selectedGroups, setSelectedGroups] = useState<TSTypeaheadOption[]>(
    audience?.groups || []
  );
  const [selectedDivisions, setSelectedDivisions] = useState<
    TSTypeaheadOption[]
  >(audience?.divisions || []);

  const [selectedTeamLeaders, setSelectedTeamLeaders] = useState<
    TSTypeaheadOption[]
  >(audience?.directReports || []);

  // which dropdowns to show, none if allCompany is selected
  // If showCheckboxes is false, there is only one dropdown to show.
  // This means we don't need to show its checkbox, but we do need to
  // set it to be true so the dropdown is displayed.
  const [allCompany, setAllCompany] = useState(
    (enableAllCompany && audience?.allCompany) || false
  );
  const [showEmployees, setShowEmployees] = useState(
    Boolean(
      (enableEmployees && selectedEmployees?.length) ||
        (enableEmployees && !showCheckboxes) ||
        false
    )
  );
  const [showGroups, setShowGroups] = useState(
    Boolean(
      (enableGroups && selectedGroups?.length) ||
        (enableGroups && !showCheckboxes) ||
        false
    )
  );
  const [showDivisions, setShowDivisions] = useState(
    Boolean(
      hideCheckboxes.includes("division") ||
        (enableDivisions && selectedDivisions?.length) ||
        (enableDivisions && !showCheckboxes) ||
        false
    )
  );
  const [showTeamLeaders, setShowTeamLeaders] = useState(
    Boolean(
      (enableDirectReports && selectedTeamLeaders?.length) ||
        (enableDirectReports && !showCheckboxes) ||
        false
    )
  );

  // If the dropdown is being edited, this is true
  // The total audience's calculation is called when these are toggled to false
  const [editingEmployees, setEditingEmployees] = useState(false);
  const [editingGroups, setEditingGroups] = useState(false);
  const [editingDivisions, setEditingDivisions] = useState(false);
  const [editingDirectReports, setEditingDirectReports] = useState(false);

  // Have selections been made?
  const [isValid, setIsValid] = useState(false);

  const isSelectionValid =
    allCompany ||
    (showDivisions && Boolean(selectedDivisions.length)) ||
    (showGroups && Boolean(selectedGroups.length)) ||
    (showEmployees && Boolean(selectedEmployees.length)) ||
    (showTeamLeaders && Boolean(selectedTeamLeaders.length));

  // data loading hooks

  // Groups data requires an API call
  // Employees will be called within TSTypeahead in async mode only
  // Divisions is pulled from the session data
  const { data: groupsData, call: getGroupsData } = useApi<api.Group[]>(
    api.groupsRetrieveUrl(companyId)
  );

  const { data: audienceCount, call: getRecipientCountData } =
    useApi<api.Count>(api.recipientCountUrl(), Method.POST);

  // Utility methods
  // hit the countMessageRecipients api when inputs change
  const fetchRecipientsCount = (): void => {
    const mapRecipient = (
      kind: api.MessageRecipientKind,
      data: string[] | undefined
    ): api.MessageRecipientWriter[] => {
      return data && data.length
        ? [
            {
              kind,
              values: data.map(id => ({ id }))
            }
          ]
        : [];
    };
    const allCompanyRecipients = mapRecipient(
      "com",
      allCompany && session.company ? [session.company.id] : []
    );
    const allEmployeeRecipients = mapRecipient(
      "emp",
      showEmployees ? selectedEmployees?.map(i => i.value) : []
    );
    const allGroupRecipients = mapRecipient(
      "grp",
      showGroups ? selectedGroups?.map(i => i.value) : []
    );
    const allDivisionRecipients = mapRecipient(
      "div",
      showDivisions ? selectedDivisions?.map(i => i.value) : []
    );
    const allDirectReportRecipients = mapRecipient(
      "rpt",
      showTeamLeaders ? selectedTeamLeaders?.map(i => i.value) : []
    );

    const request = [
      ...allCompanyRecipients,
      ...allEmployeeRecipients,
      ...allGroupRecipients,
      ...allDivisionRecipients,
      ...allDirectReportRecipients
    ];
    getRecipientCountData({ body: request });
  };

  const convertOptions = (options: any): TSTypeaheadOption[] => {
    return (options ?? []).map((option: { id: string; name: string }) => ({
      value: option.id,
      label: option.name
    }));
  };

  // Effects
  // process groups data when loaded
  useEffect(() => {
    const options = convertOptions(fullGroupList ?? groupsData);
    options && setAllGroups(options);
  }, [fullGroupList, groupsData]);

  // process divisions data from the session data
  useEffect(() => {
    const options = convertOptions(session.divisions);
    options && setAllDivisions(options);
  }, [session.divisions]);

  // trigger load of groups and employees lists
  useEffect(() => {
    if (session?.company?.id && triggerLoad) {
      enableGroups && getGroupsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.company, triggerLoad]);

  // trigger fetch count
  useEffect(() => {
    if ((triggerLoad || fullGroupList) && isSelectionValid) {
      if (!disableApi) {
        fetchRecipientsCount();
      }
    } else {
      audienceCountChangeHandler && audienceCountChangeHandler(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triggerLoad,
    isSelectionValid,
    allCompany,
    showEmployees,
    showDivisions,
    showGroups,
    showTeamLeaders,
    editingEmployees,
    editingGroups,
    editingDivisions,
    editingDirectReports
  ]);

  // pass selected values up to parent when they change
  useEffect(() => {
    // either all company is selected,
    // or you've ticked a checkbox and made a selection in the dropdown
    setIsValid(isSelectionValid);

    audienceChangeHandler &&
      audienceChangeHandler(
        {
          allCompany,
          divisions: !allCompany && showDivisions ? selectedDivisions : [],
          groups: !allCompany && showGroups ? selectedGroups : [],
          employees: !allCompany && showEmployees ? selectedEmployees : [],
          directReports:
            !allCompany && showTeamLeaders ? selectedTeamLeaders : []
        },
        isSelectionValid
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allCompany,
    selectedEmployees,
    selectedGroups,
    selectedDivisions,
    selectedTeamLeaders,
    showEmployees,
    showGroups,
    showDivisions,
    showTeamLeaders
  ]);

  // pass count up to parent when it changes
  useEffect(() => {
    audienceCountChangeHandler &&
      audienceCountChangeHandler(audienceCount?.count || 0);
  }, [audienceCount, audienceCountChangeHandler]);

  // Content Fragments
  const errors = showErrors && !isValid && (
    <div className="d-block invalid-feedback">{requiredMessage}</div>
  );

  const allCompanyInputs = enableAllCompany && showCheckboxes && (
    <Form.Check
      className="check-lg"
      name="allCompany"
      type="checkbox"
      id="allCompany"
      readOnly={readOnly}
      disabled={readOnly}
      label={checkboxLabels.all}
      onChange={() => setAllCompany(!allCompany)}
      checked={allCompany}
    />
  );

  const employeesInputs = enableEmployees && (
    <>
      {showCheckboxes && (
        <Form.Check
          className="check-lg"
          name="showEmployees"
          type="checkbox"
          id="showEmployees"
        >
          <Form.Check.Input
            name="showEmployees"
            type="checkbox"
            disabled={readOnly || allCompany || isAnonymous}
            readOnly={readOnly}
            checked={!allCompany && showEmployees}
            onChange={() => setShowEmployees(!showEmployees)}
          />
          <Form.Check.Label>{checkboxLabels.employees}</Form.Check.Label>
          {isAnonymous && (
            <Form.Text>
              Cannot choose individual employees for anonymous surveys
            </Form.Text>
          )}
        </Form.Check>
      )}
      <Collapse in={!allCompany && showEmployees}>
        <div>
          <TSTypeahead
            className="mb-3"
            id="employeesTypeahead"
            readOnly={readOnly}
            label="Select Employees"
            placeholder="Select Multiple Items"
            labelKey="label"
            onChange={items =>
              setSelectedEmployees(items as TSTypeaheadOption[])
            }
            onFocus={() => setEditingEmployees(true)}
            onBlur={() => setEditingEmployees(false)}
            options={[]}
            asyncSearchHandler={nameOrId =>
              employeeSearchHandler(session, {
                nameOrId
              })
            }
            selected={selectedEmployees}
            multiple
            // Special warning for when survey type is covid
            helpText={
              isCovid
                ? "If a selected employee is part of an existing schedule, they will be removed from that schedule and added to this schedule."
                : undefined
            }
            isInvalid={showErrors && !isValid}
            showId={true}
          />
        </div>
      </Collapse>
    </>
  );

  const groupsInputs = enableGroups && (
    <>
      {showCheckboxes && (
        <Form.Check
          className="check-lg"
          name="showGroups"
          type="checkbox"
          id="showGroups"
          readOnly={readOnly}
          label={checkboxLabels.groups}
          onChange={() => setShowGroups(!showGroups)}
          checked={!allCompany && showGroups}
          disabled={readOnly || allCompany}
        />
      )}
      <Collapse in={!allCompany && showGroups}>
        <div>
          <TSTypeahead
            className="mb-3"
            id="groupsTypeahead"
            readOnly={readOnly}
            label="Select Groups"
            placeholder="Select Multiple Items"
            labelKey="label"
            onChange={items => setSelectedGroups(items as TSTypeaheadOption[])}
            onFocus={() => setEditingGroups(true)}
            onBlur={() => setEditingGroups(false)}
            options={allGroups}
            selected={selectedGroups}
            multiple
            paginate
            isInvalid={showErrors && !isValid}
          />
        </div>
      </Collapse>
    </>
  );

  const divisionsInputs = enableDivisions && (
    <>
      {showSpecificCheckbox("division") && (
        <Form.Check
          className="check-lg"
          name="showDivisions"
          type="checkbox"
          id="showDivisions"
          readOnly={readOnly}
          label={checkboxLabels.divisions}
          onChange={() => setShowDivisions(!showDivisions)}
          checked={!allCompany && showDivisions}
          disabled={readOnly || allCompany}
        />
      )}
      <Collapse in={!allCompany && showDivisions}>
        <div>
          <TSTypeahead
            className={clsx("mb-3", className)}
            id="divisionsTypeahead"
            readOnly={readOnly}
            label="Select Divisions"
            placeholder="Select Multiple Items"
            labelKey="label"
            onChange={items =>
              setSelectedDivisions(items as TSTypeaheadOption[])
            }
            onFocus={() => setEditingDivisions(true)}
            onBlur={() => setEditingDivisions(false)}
            options={allDivisions}
            selected={selectedDivisions}
            multiple
            paginate
            isInvalid={showErrors && !isValid}
            renderToken={(option: any, props, idx) => {
              const disabled =
                readOnly ||
                !session.divisions?.find(x => x.name === option.label);
              return (
                <Token
                  key={idx}
                  disabled={disabled}
                  onRemove={props.onRemove}
                  option={option}
                >
                  {`${option.label}`}
                </Token>
              );
            }}
          />
        </div>
      </Collapse>
    </>
  );

  const teamLeaderInputs = enableDirectReports && (
    <>
      {showCheckboxes && (
        <Form.Check
          className="check-lg"
          name="showTeamLeaders"
          type="checkbox"
          id="showTeamLeaders"
          readOnly={readOnly}
          label={checkboxLabels.directReports}
          onChange={() => setShowTeamLeaders(!showTeamLeaders)}
          checked={!allCompany && showTeamLeaders}
          disabled={readOnly || allCompany}
        />
      )}
      <Collapse in={!allCompany && showTeamLeaders}>
        <div>
          <TSTypeahead
            className="mb-3"
            id="directReportsTypeahead"
            readOnly={readOnly}
            label="Select a Team Leader"
            placeholder="Select Item"
            labelKey="label"
            onChange={items =>
              setSelectedTeamLeaders(items as TSTypeaheadOption[])
            }
            onFocus={() => setEditingDirectReports(true)}
            onBlur={() => setEditingDirectReports(false)}
            options={[]}
            selected={selectedTeamLeaders}
            multiple
            asyncSearchHandler={nameOrId =>
              employeeSearchHandler(
                session,
                {
                  nameOrId
                },
                true
              )
            }
            isInvalid={showErrors && !isValid}
          />
        </div>
      </Collapse>
    </>
  );

  return (
    <>
      {label && (
        <Form.Label>
          <span>＊</span> {label}
        </Form.Label>
      )}
      {errors}

      {allCompanyInputs}
      {employeesInputs}
      {groupsInputs}
      {divisionsInputs}
      {teamLeaderInputs}
    </>
  );
};

export interface SelectAudienceOptions {
  allCompany?: boolean;
  divisions?: TSTypeaheadOption[];
  groups?: TSTypeaheadOption[];
  employees?: TSTypeaheadOption[];
  directReports?: TSTypeaheadOption[];
}

interface SelectAudienceProps {
  triggerLoad?: boolean;
  readOnly?: boolean;
  audience?: SelectAudienceOptions;
  audienceChangeHandler?: Function;
  audienceCountChangeHandler?: Function;
  label?: string;
  checkboxPrepend?: string;
  showErrors?: boolean;
  requiredMessage?: string;
  isCovid?: boolean;
  isAnonymous?: boolean;
  fullGroupList?: api.Group[];
  enableAllCompany?: boolean;
  enableEmployees?: boolean;
  enableGroups?: boolean;
  enableDivisions?: boolean;
  enableDirectReports?: boolean;
  hideCheckboxes?: string[];
  className?: string;
  disableApi?: boolean;
}
