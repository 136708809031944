import React, { FunctionComponent } from "react";

interface SubmitFeedbackProp {
  mailTo: string;
  heading?: string;
  subHeading?: string;
}
export const SubmitFeedback: FunctionComponent<SubmitFeedbackProp> = ({
  mailTo: MailTo,
  heading: Heading,
  subHeading: SubHeading
}) => {
  return (
    <div className="my-auto ml-1 mb-4 text-center">
      <h1>{Heading}</h1>
      <h2>{SubHeading}</h2>
      <a className="btn btn-primary" href={MailTo}>
        Submit an idea
      </a>
    </div>
  );
};
