import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useState
} from "react";

import { Entity } from "../entities-dropdown/entities-dropdown-common";
import { EntitiesDropdown } from "../entities-dropdown/EntitiesDropdown";
import { MessageActionButton } from "../message-action-button/MessageActionButton";
import { Modal, ModalProps } from "../modal/Modal";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import {
  isCompanySelectedInRecipients,
  recipientIdsForKind,
  recipientIdentificationEmployeesFromRecipients
} from "~/lib/recipientsUtils";
import { showDivisionsIfCompanyHasDivisions } from "~/lib/showDivisions";

interface SelectRecipientsModalProps extends ModalProps {
  groups?: api.Group[];
  employees?: api.IdentificationEmployee[];
  recipients?: api.MessageRecipient[];
  onSave?: (updatedRecipients: api.MessageRecipient[]) => any;
  disableEmployeesMessage?: string;
}

const SectionHeader: FunctionComponent<{ children?: ReactNode }> = ({
  children
}) => <div className="pt-3 text-sm font-bold text-hs-black">{children}</div>;

export const SelectRecipientsModal: FunctionComponent<
  SelectRecipientsModalProps
> = ({
  onClose,
  recipients,
  onSave,
  groups,
  employees,
  disableEmployeesMessage
}) => {
  const { session } = useContext(MutableSessionContext);
  const companyVisible = session?.is_company_admin;
  const divisionsVisible = showDivisionsIfCompanyHasDivisions(session);
  const initialCompanySelected =
    companyVisible && isCompanySelectedInRecipients(recipients);
  const [companySelected, setCompanySelected] = useState(
    initialCompanySelected
  );
  const initialDivisionIds = recipientIdsForKind("div", recipients);
  const [selectedDivisionIds, setSelectedDivisionIds] =
    useState<string[]>(initialDivisionIds);
  const initialGroupIds = recipientIdsForKind("grp", recipients);
  const [selectedGroupIds, setSelectedGroupIds] =
    useState<string[]>(initialGroupIds);
  const initialEmployees =
    recipientIdentificationEmployeesFromRecipients(recipients);
  const [selectedEmployees, setSelectedEmployees] =
    useState<api.IdentificationEmployee[]>(initialEmployees);

  const onToggleCompany = (): void => {
    setSelectedDivisionIds([]);
    setSelectedGroupIds([]);
    setSelectedEmployees([]);
    setCompanySelected(!companySelected);
  };

  const onSaveClicked = (): void => {
    if (!session.company) {
      return;
    }
    // TODO: Compare initial state with current state to determine if anything really has changed
    if (companySelected) {
      onSave?.([{ kind: "com", values: [{ id: session.company.id }] }]);
    } else {
      const divisionRecipients = selectedDivisionIds.map(
        (divisionId): api.MessageRecipient => ({
          kind: "div",
          values: [{ id: divisionId }]
        })
      );
      const groupRecipients = selectedGroupIds.map(
        (groupId): api.MessageRecipient => ({
          kind: "grp",
          values: [{ id: groupId }]
        })
      );
      const employeeRecipients = selectedEmployees.map(
        (employee): api.EmployeeMessageRecipient => ({
          kind: "emp",
          values: [employee]
        })
      );
      onSave?.([
        ...divisionRecipients,
        ...groupRecipients,
        ...employeeRecipients
      ]);
    }
    onClose();
  };

  // NOTE: We don't store the external_id in the EntitiesDropdown, but since we don't use it,
  // we can just fake it with the employee ID to make the types work out
  const onChangeEmployeeEntities = (entities: Entity[]): void =>
    setSelectedEmployees(
      entities.map(entitity => ({ ...entitity, external_id: entitity.id }))
    );

  return (
    <Modal
      className="flex flex-col max-h-3/4 min-h-1/2 max-w-xl rounded-none p-0 overflow-y-visible"
      onClose={onClose}
    >
      <div className="px-5 py-4 flex flex-row justify-between items-center border-b border-gray-200">
        <div className="text-xs leading-4 font-semibold uppercase text-gray-500">
          Recipients:
        </div>
        <button
          className="text-base text-hs-black rounded focus:outline-none"
          onClick={onClose}
        >
          ✕
        </button>
      </div>
      <div className="flex-1 px-5 pt-4 pb-6">
        <div className="mt-4 space-y-4">
          {companyVisible && (
            <div className="flex items-start select-none">
              <div className="flex items-center h-5">
                <input
                  id="company"
                  type="checkbox"
                  className="form-check-input"
                  checked={companySelected}
                  onChange={onToggleCompany}
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="company" className="font-medium text-gray-700">
                  {`All ${session.company?.name} Employees`}
                </label>
              </div>
            </div>
          )}
          {divisionsVisible && (
            <>
              <SectionHeader>Divisions</SectionHeader>
              <EntitiesDropdown
                isDisabled={companySelected}
                allEntities={session.divisions}
                entityIds={selectedDivisionIds}
                onChange={setSelectedDivisionIds}
              />
            </>
          )}
          <SectionHeader>Groups</SectionHeader>
          <EntitiesDropdown
            isDisabled={companySelected}
            allEntities={groups ?? []}
            entityIds={selectedGroupIds}
            onChange={setSelectedGroupIds}
          />
          <SectionHeader>Employees</SectionHeader>
          <EntitiesDropdown
            isDisabled={companySelected || Boolean(disableEmployeesMessage)}
            allEntities={employees}
            entityIds={selectedEmployees.map(({ id }) => id)}
            onChangeEntities={onChangeEmployeeEntities}
          />
          {disableEmployeesMessage && (
            <p className="italic">{disableEmployeesMessage}</p>
          )}
        </div>
      </div>
      <div className="px-5 pt-2 pb-5 flex justify-end">
        <MessageActionButton
          inline
          className="w-24 h-10 py-2"
          textSizeClassName="text-base"
          text="Select"
          disabled={
            !companySelected &&
            selectedDivisionIds.length === 0 &&
            selectedGroupIds.length === 0 &&
            selectedEmployees.length === 0
          }
          theme="green"
          onClick={onSaveClicked}
        />
      </div>
    </Modal>
  );
};
