import React, { FunctionComponent, useEffect, useState } from "react";

import clsx from "clsx";

import "./TSLoading.scss";

export const TSCenteredLoadingSpinner: FunctionComponent<TSLoadingProps> = ({
  className,
  delaySeconds = 0
}) => {
  const [visible, setVisible] = useState(delaySeconds === 0);

  useEffect(() => {
    if (delaySeconds === 0) {
      return;
    }
    const handle = setTimeout(() => setVisible(true), delaySeconds * 1000);
    return () => clearTimeout(handle);
  }, [delaySeconds]);

  return (
    <div className={clsx("ts-loading", className)} data-testid="wrapper">
      <i
        className={clsx(
          "fas fa-circle-notch fa-spin fa-3x",
          !visible && "hidden"
        )}
      />
    </div>
  );
};

export const TSLoading: FunctionComponent<TSLoadingProps> = ({
  className,
  ...rest
}) => (
  <TSCenteredLoadingSpinner
    className={clsx(className, "ts-loading-center")}
    {...rest}
  />
);

interface TSLoadingProps {
  className?: string;
  delaySeconds?: number;
}
