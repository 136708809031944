import React, { FunctionComponent, ReactNode } from "react";

import TSLogo from "~/images/brand-dark-green.svg";
import LoginIllustration from "~/images/login-illustration.svg";
import TSMark from "~/images/logo-mark-light-gray.svg";
import { TSFooter } from "~/ts-components/common/ts-footer/TSFooter";
import "./Login.scss";

interface LoginProps {
  children: ReactNode;
}

export const HELP_URL = "https://help.teamsense.com";

export const LoginPage: FunctionComponent<LoginProps> = ({ children }) => {
  return (
    <div className="login-page ts">
      <div className="form-column">
        <main>
          <div className="ts-mark">
            <img alt="TeamSense Mark" src={TSMark} />
          </div>
          <div className="ts-logo">
            <img alt="TeamSense Logo" src={TSLogo} />
          </div>
          {children}
          <a
            className="question-link text-sm"
            href={HELP_URL}
            target="_blank"
            rel="noreferrer"
          >
            Help Center
          </a>
        </main>
        <footer>
          <TSFooter />
        </footer>
      </div>
      <div className="illustration">
        <img alt="TeamSense Login Illustration" src={LoginIllustration} />
      </div>
    </div>
  );
};
