import PhoneNumber from "awesome-phonenumber";

/*
 * Returns a displayable phone number
 * (for now, only show a "friendly" number when it is a US number
 *  since we don't have a great way of deducing which country code is local)
 */

export const displayPhoneNumber = (
  phoneNumber?: string | null
): string | undefined => {
  const pn = new PhoneNumber(phoneNumber ?? "");
  if (pn.isValid()) {
    return pn.getCountryCode() === 1
      ? pn.getNumber("national")
      : pn.getNumber("international");
  }
  return phoneNumber ?? undefined;
};
