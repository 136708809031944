import React, { ElementType, FunctionComponent, ReactNode } from "react";

import clsx from "clsx";
import { useField } from "formik";

/**
 *  Types
 */

export type Transformer<Value extends FieldValue> = (
  value: FieldValue
) => Value;

export interface RenderProps {
  className: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  autoComplete?: string;
}

export type FieldValue = string | number | boolean | undefined;

export type OnChange = (value: FieldValue) => void;

export interface ViewProps {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  fieldClassName?: string;
  label?: string;
  hint?: string;
  name?: string;
  wrapperElement?: ReactNode;
  placeholder?: string;
  autoComplete?: string;
  type?: string;
}

export interface Props extends ViewProps {
  children: (props: RenderProps) => ReactNode;
}

export const Field: FunctionComponent<Props> = props => {
  const {
    autoFocus,
    children,
    className,
    disabled,
    fieldClassName,
    hint,
    label,
    name,
    placeholder,
    autoComplete
  } = props;
  const WrapperElement = (props.wrapperElement || "label") as ElementType;
  const [, meta] = useField(name || "field");

  /**
   * Renderers
   */

  /**
   * Renders a label using the stock label styling
   */
  const renderLabel = (label?: string): ReactNode | null => {
    if (label) {
      return (
        <div className="flex justify-between items-center mb-1">
          <span className="field-label">{label}</span>
          {hint && <span className="field-hint">{hint}</span>}
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   * Renders any errors
   */
  const renderErrors = (): ReactNode =>
    meta.touched && meta.error && <span className="error">{meta.error}</span>;

  /**
   * We use the "function as child component" pattern here to make Field
   * highly composable. Field should probably not be used directly in most cases,
   * but instead implement a new component like EmailField which serves a
   * specific purpose and simplifies the API.
   */
  const renderProps: RenderProps = {
    className: clsx(fieldClassName, "field", {
      "field--invalid": meta.touched && meta.error,
      "field--disabled": disabled
    }),
    disabled,
    placeholder,
    autoFocus,
    autoComplete
  };

  return (
    <WrapperElement className={clsx("field-set", className)}>
      {renderLabel(label)}
      {children(renderProps)}
      {renderErrors()}
    </WrapperElement>
  );
};
