import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { DateTime } from "luxon";

import { ExportSurveyResultsModal } from "../ts-components/forms/ExportSurveyResultsModal";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { FormsSendModal } from "~/ts-components/forms/FormsSendModal";
import { GenericErrorText, TSButton } from "~common";

export const Forms: FunctionComponent = () => {
  const history = useHistory();
  const { session } = useContext(MutableSessionContext);

  const [surveysSummary, setSurveysSummary] = useState<api.SurveySummary[]>([]);
  const [exportModalSurveyId, setExportModalSurveyId] = useState<string>();
  const [showSurveyResultsModal, setShowSurveyResultsModal] =
    useState<boolean>(false);
  const [formToSend, setFormToSend] = useState<api.SurveySummary>();

  const [showFormsSendModal, setShowFormsSendModal] = useState(false);
  // utilities
  const getShortDate = (date?: api.ISODateTime): string => {
    const dt = DateTime.fromISO(date ?? "");
    let shortDate = "";
    try {
      shortDate = date ? dt.toLocaleString(DateTime.DATE_SHORT) : "";
    } catch (e) {
      shortDate = date
        ? dt.toLocaleString({ ...DateTime.DATE_SHORT, locale: "en-US" })
        : "";
    }
    return shortDate;
  };

  // handlers
  const onSendForm = (survey: api.SurveySummary): void => {
    setFormToSend(survey);
    setShowFormsSendModal(true);
  };

  const modalCloseHandler = (): void => {
    setShowSurveyResultsModal(false);
  };

  const addScheduleClickHandler = (id: string): void => {
    setShowSurveyResultsModal(true);
    setExportModalSurveyId(id);
  };

  const rowClickHandler = (formId: string): void => {
    history.push(`/dashboard/forms/form/${formId}`);
  };

  // effects
  useEffect(() => {
    const loadSurveyTemplates = async (): Promise<void> => {
      if (session.company) {
        let response: api.APIResponse<api.SurveysSummaryResponse> | undefined;
        try {
          response = await api.retrieveSurveysSummary(session.company.id);
        } catch {}
        if (response?.ok) {
          setSurveysSummary(response.data.data);
        } else {
          toast.error(<GenericErrorText />);
        }
      }
    };
    loadSurveyTemplates();
  }, [session.company]);

  const tableRows = surveysSummary.map(survey => (
    <tr
      className="hover-pointer"
      key={survey.id}
      onClick={() => rowClickHandler(survey.id)}
    >
      <td>{survey.name}</td>
      <td>{`${survey.today} today`}</td>

      <td>{survey.latest === null ? "None" : getShortDate(survey.latest)}</td>

      <td>{getShortDate(survey.created)}</td>
      <td>{survey.is_anonymous && <i className="icon-check h1" />}</td>
      <td>
        <div className="flex flex-nowrap">
          <TSButton variant="link" onClick={() => onSendForm(survey)}>
            Send Form
          </TSButton>
          <TSButton
            variant="link"
            onClick={() => {
              addScheduleClickHandler(survey.id);
            }}
          >
            Export Responses
          </TSButton>
        </div>
      </td>
    </tr>
  ));

  return (
    <div className="ts">
      <Container fluid className="standard-page">
        <Row>
          <Col>
            <Table className="align-middle" responsive>
              <thead>
                <tr>
                  <th style={{ minWidth: "200px" }}>FORM</th>
                  <th>RESPONSES</th>
                  <th>LAST RESPONSE</th>
                  <th>CREATED</th>
                  <th>ANONYMOUS</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {!surveysSummary && (
                  <tr>
                    <td>Loading...</td>
                  </tr>
                )}

                {tableRows}

                {surveysSummary?.length === 0 && (
                  <tr>
                    <td>There are no forms available.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      {formToSend && (
        <FormsSendModal
          show={showFormsSendModal}
          template={formToSend}
          handleExit={() => setShowFormsSendModal(false)}
        />
      )}
      {showSurveyResultsModal && (
        <ExportSurveyResultsModal
          allowFutureDates={false}
          handleClose={modalCloseHandler}
          exportModalSurveyId={exportModalSurveyId}
          exportType="survey"
        />
      )}
    </div>
  );
};
