import React, { FunctionComponent, useState } from "react";

import { t } from "@lingui/macro";
import { I18n, Trans } from "@lingui/react";
import clsx from "clsx";

import * as api from "~/api";
import { ValidatedString } from "~/components/EmployeeCheckInRequest/types";
import EmailIcon from "~/images/email-icon.svg";
import {
  Loading,
  MessageActionButton,
  TextInput,
  emailFormatRegex
} from "~common";

interface EmailProps {
  className?: string;
  employee: api.ExternalEmployee;
  requesting: boolean;
  error: boolean;
  onConfirm: (phone: string) => any;
  onDontHaveEmail: () => any;
}

export const Email: FunctionComponent<EmailProps> = ({
  className,
  employee,
  requesting,
  error,
  onConfirm,
  onDontHaveEmail
}) => {
  const [email, setEmail] = useState<ValidatedString>();
  const { friendly_name: friendlyName } = employee;

  return (
    <div
      className={clsx(
        "h-full w-full flex-grow flex flex-col justify-between",
        className
      )}
    >
      <div className="p-9 text-2xl font-bold text-hs-dark-green">
        <p>
          <Trans id="register.email.summary">
            Do you have an email you can access before heading to work?
          </Trans>
        </p>
      </div>
      <div className="bg-hs-light p-9 rounded-t-4xl">
        <div className="flex flex-row items-center mb-2">
          <img className="mr-4" src={EmailIcon} alt="Email" />
          <p className="text-2xl font-semibold text-hs-dark-green">
            <Trans id="register.email.label">Email address:</Trans>
          </p>
        </div>

        <I18n>
          {({ i18n }) => (
            <TextInput
              className={clsx(
                "my-3 py-2 px-5 border rounded text-lg w-full",
                !error && "border-hs-dark-gray focus:border-hs-green",
                error && "border-hs-error-red"
              )}
              validationRegex={emailFormatRegex}
              value={email?.value ?? ""}
              placeholder={i18n._(
                t(
                  "register.email.placeholder"
                )`Email the survey to this address`
              )}
              onChange={(value, valid) => {
                setEmail({ value, valid });
              }}
              onEnter={() => email?.valid && onConfirm(email.value)}
            />
          )}
        </I18n>

        {error && (
          <p className="my-3 text-xs text-hs-error-red">
            <Trans id="register.error.alreadySet" values={{ friendlyName }}>
              {friendlyName}, it looks like we already have your info, so you
              are good to go!
            </Trans>
          </p>
        )}

        {requesting && <Loading />}

        <div className="pt-5 pb-3">
          <MessageActionButton
            theme="dark-green"
            disabled={!email?.valid}
            onClick={() => email?.valid && onConfirm(email.value)}
            text={<Trans id="register.email.confirm">Confirm email</Trans>}
          />
          <div
            className="mt-6 text-center text-lg underline cursor-pointer text-hs-dark-green"
            onClick={onDontHaveEmail}
          >
            <Trans id="register.email.dontHaveEmail">
              I don't have a smartphone / email
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
};
