import React, { FunctionComponent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import { DateTime } from "luxon";

import { TSButton } from "../ts-buttons/TSButton";

import CalendarIcon from "~/images/calendar-select-icon.svg";

import "./TSDateRangePicker.scss";

interface RangePickerProp {
  start?: DateTime;
  end?: DateTime;
  onChange: (start?: DateTime, end?: DateTime) => any;
}

export const TSDateRangePicker: FunctionComponent<RangePickerProp> = ({
  start,
  end,
  onChange
}) => {
  const [datePickerStart, setDatePickerStart] = useState<DateTime | undefined>(
    start
  );
  const [datePickerEnd, setDatePickerEnd] = useState<DateTime | undefined>(end);
  useEffect(() => {
    if (datePickerStart && datePickerEnd) {
      onChange(datePickerStart.startOf("day"), datePickerEnd.endOf("day"));
    }
    // Disabling exhaustive-deps because we don't care when onChange changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerEnd, datePickerStart]);
  const getFilterButtonName = (start?: DateTime, end?: DateTime): string => {
    if (!start || !end) {
      return "All Time";
    }
    if (start.startOf("day").equals(end.startOf("day"))) {
      try {
        return start.toLocaleString(DateTime.DATE_MED);
      } catch (e) {
        return start.toLocaleString({ locale: "en-US", ...DateTime.DATE_MED });
      }
    }
    try {
      return `${start.toLocaleString(DateTime.DATE_MED)} - ${end.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } catch (e) {
      return `${start.toLocaleString({
        locale: "en-US",
        ...DateTime.DATE_MED
      })} - ${end.toLocaleString({ locale: "en-US", ...DateTime.DATE_MED })}`;
    }
  };
  const onClear = (): void => {
    setDatePickerStart(undefined);
    setDatePickerEnd(undefined);
  };

  const jsDateToString = (date: Date): string => {
    try {
      return DateTime.fromJSDate(date).toLocaleString({
        ...DateTime.DATE_FULL,
        day: undefined
      });
    } catch (e) {
      return DateTime.fromJSDate(date)
        .setLocale("en-US")
        .toLocaleString({
          ...DateTime.DATE_FULL,
          day: undefined
        });
    }
  };
  return (
    <div className="date-picker-fw">
      <DatePicker
        selectsRange
        selected={datePickerStart?.toJSDate()}
        startDate={datePickerStart?.toJSDate()}
        endDate={datePickerEnd?.toJSDate()}
        shouldCloseOnSelect={Boolean(datePickerStart) && !datePickerEnd}
        className="w-100"
        customInput={
          <button className="rounded-2 btn btn-primary">
            <div className="text-nowrap ellipsis overflow-hidden">
              {getFilterButtonName(start, end)}
            </div>
            <img
              src={CalendarIcon}
              alt="Calendar Icon"
              className="mb-1 ms-2 rounded-0"
            />
          </button>
        }
        onClickOutside={() => {
          if (!datePickerEnd) {
            if (!datePickerStart) {
              onChange(undefined, undefined);
            } else {
              setDatePickerEnd(datePickerStart);
            }
          }
        }}
        onChange={dates => {
          if (dates instanceof Array) {
            const [rangeStart, rangeEnd] = dates;
            setDatePickerStart(
              rangeStart ? DateTime.fromJSDate(rangeStart) : undefined
            );
            setDatePickerEnd(
              rangeEnd ? DateTime.fromJSDate(rangeEnd) : undefined
            );
          }
        }}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <>
            <div className="custom-range-picker-controls">
              <div className="custom-header-top">
                <TSButton
                  variant="link"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  className="decrease-month"
                >
                  {"<"}
                </TSButton>

                <span className="h3 month-and-year">
                  {jsDateToString(date)}
                </span>

                <TSButton
                  variant="link"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  className="increase-month"
                >
                  {">"}
                </TSButton>
              </div>
              <div className="custom-header-bottom">
                <TSButton variant="link" size="sm" onClick={onClear}>
                  Clear
                </TSButton>
              </div>
            </div>
          </>
        )}
        popperPlacement="bottom-end"
      />
    </div>
  );
};
