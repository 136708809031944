import { APIResponse, destroy, get, patch, post } from "./lib";

export interface ExtendedGroupWriter {
  company_id: string;
  name: string;
  employee_ids: string[];
}

export interface ExtendedGroup extends ExtendedGroupWriter {
  id: string;
  division_ids?: string[];
}

export type Group = Pick<ExtendedGroup, "id" | "name">;

export interface GroupWriter extends Pick<ExtendedGroup, "name"> {
  id?: string;
}

/** Query the server to obtain the information for a company's employees */
export const retrieveGroups = async (
  companyId: string,
  options?: {
    token?: string;
  }
): Promise<APIResponse<Group[]>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return await get(
    `/companies/${companyId}/groups/?simple=true`,
    undefined,
    headers
  );
};

export const createGroup = async (
  companyId: string,
  group: ExtendedGroupWriter
): Promise<APIResponse<Group>> => {
  return await post(`/companies/${companyId}/groups/`, group);
};

/** Add Employees to group */
export const addEmployeesToGroup = async (
  companyId: string,
  group: Group,
  employeeIds: string[]
): Promise<APIResponse<object>> => {
  return await patch(
    `/companies/${companyId}/groups/${group.id}/employees/`,
    employeeIds
  );
};

/** Remove Employees from group */
export const removeEmployeesFromGroup = async (
  companyId: string,
  group: Group,
  employeeIds: string[]
): Promise<APIResponse<Record<string, never>>> => {
  return await destroy(
    `/companies/${companyId}/groups/${group.id}/employees/`,
    employeeIds
  );
};
