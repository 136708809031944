import React, { FunctionComponent, useContext } from "react";
import { useLocation } from "react-router-dom";

import { I18nProvider } from "@lingui/react";

import { MutableSessionContext } from "~/lib/context";
import { I18nHookHackProvider } from "~/lib/hooks";
import catalogAk from "~/locales/ak/messages";
import catalogAr from "~/locales/ar/messages";
import catalogBn from "~/locales/bn/messages";
import catalogEn from "~/locales/en/messages";
import catalogEs from "~/locales/es/messages";
import catalogFr from "~/locales/fr/messages";
import catalogHa from "~/locales/ha/messages";
import catalogHi from "~/locales/hi/messages";
import catalogJa from "~/locales/ja/messages";
import catalogKmKH from "~/locales/km-KH/messages";
import catalogLo from "~/locales/lo/messages";
import catalogMl from "~/locales/ml/messages";
import catalogMy from "~/locales/my/messages";
import catalogNe from "~/locales/ne/messages";
import catalogPl from "~/locales/pl/messages";
import catalogRu from "~/locales/ru/messages";
import catalogRw from "~/locales/rw/messages";
import catalogSo from "~/locales/so/messages";
import catalogSw from "~/locales/sw/messages";
import catalogTl from "~/locales/tl/messages";
import catalogUk from "~/locales/uk/messages";
import catalogVi from "~/locales/vi/messages";
import catalogYue from "~/locales/yue/messages";
import catalogZhCN from "~/locales/zh-CN/messages";
import catalogZhTW from "~/locales/zh-TW/messages";

interface InternationalizationProps {
  children: React.ReactNode;
}

export const InternationalizationWrapper: FunctionComponent<
  InternationalizationProps
> = ({ children }) => {
  const query = new URLSearchParams(useLocation().search);

  const { session } = useContext(MutableSessionContext);

  const preferredLanguage =
    query.get("lang") ?? session.employee?.preferred_language ?? "en-US";

  return (
    <I18nProvider
      language={preferredLanguage}
      catalogs={{
        "en-US": catalogEn,
        es: catalogEs,
        "vi-VN": catalogVi,
        "ht-HT": catalogHa,
        ak: catalogAk,
        ar: catalogAr,
        bn: catalogBn,
        fr: catalogFr,
        hi: catalogHi,
        "km-KH": catalogKmKH,
        mh: catalogMl,
        my: catalogMy,
        ne: catalogNe,
        so: catalogSo,
        sw: catalogSw,
        tl: catalogTl,
        yue: catalogYue,
        "zh-TW": catalogZhTW,
        "zh-CN": catalogZhCN,
        ru: catalogRu,
        uk: catalogUk,
        rw: catalogRw,
        pl: catalogPl,
        ja: catalogJa,
        lo: catalogLo
      }}
    >
      <I18nHookHackProvider>{children}</I18nHookHackProvider>
    </I18nProvider>
  );
};
