import React, { FunctionComponent } from "react";

import clsx from "clsx";

import "./StatusLabel.scss";

interface StatusLabelProps {
  color: string;
  className?: string;
  displayText: string;
}

export const StatusLabel: FunctionComponent<StatusLabelProps> = ({
  color: background,
  displayText,
  className
}) => {
  return (
    <div className={clsx(`status-label`, className)}>
      <span className="disc" style={{ background }} /> {displayText}
    </div>
  );
};
