import { formatDuration, formatTemp, shortDate } from "./ranges";

import * as api from "~/api";
import { processConversationTranslation } from "~/lib/hooks";
/** Convert a Choice[] value into text. */

export const convertChoiceAnswerToString = (
  choiceSet: api.ChoiceSet,
  values: string[],
  language: string
): string => {
  const choices = choiceSet.choices.filter(({ key }) => values.includes(key));
  return choices
    .map(choice =>
      processConversationTranslation(
        language,
        choice.display,
        choice.loc_display
      )
    )
    .join("; ");
};

/** Convert an arbitrary answer into text. */
export const convertAnswerToString = (
  step: api.Step,
  answer: api.Answer,
  language: string,
  timeZone?: string
): string | null => {
  switch (answer.kind) {
    case "choice":
      if (step.kind !== "choice") {
        return null;
      }
      return convertChoiceAnswerToString(
        step.choiceSet,
        answer.value,
        language
      );
    case "number":
      if (step.kind !== "number") {
        return null;
      }
      if (step.hint === "duration") {
        return formatDuration(answer.value, step.hintUnit);
      }
      if (step.hint === "temperature") {
        return formatTemp(answer.value, step.hintUnit);
      }
      return `${answer.value}`;
    case "string":
      return answer.value;
    case "date":
      return shortDate(answer.value, timeZone);
    default:
      return null;
  }
};
