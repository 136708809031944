import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from "react";

import clsx from "clsx";

import { EmployeeCheckInBody } from "./EmployeeCheckInBody";
import { ValidatedPhoneNumber, ValidatedString } from "./types";
import { VisitorCheckInBody } from "./VisitorCheckInBody";
import { VisitorCheckInPhoneName } from "./VisitorCheckInPhoneName";

import { useEffectOnce } from "~/lib/hooks";
import { BrandGreen } from "~/svg";
import { Loading, MessageActionButton } from "~common";

interface EmployeeCheckInRequestProps {
  className?: string;
  waiting: boolean;
  visitorCode?: string;
  visitorCompanyName?: string;
  onEmployeeRequest: (phone?: string, email?: string) => any;
  onVisitorCodeSubmit: (code: string) => any;
  onVisitorRequest: (code: string, phone: string, name: string) => any;
}

export const EmployeeCheckInRequest: FunctionComponent<
  EmployeeCheckInRequestProps
> = props => {
  const {
    className,
    waiting,
    visitorCode,
    visitorCompanyName,
    onEmployeeRequest,
    onVisitorCodeSubmit,
    onVisitorRequest
  } = props;
  const [employeeMode, setEmployeeMode] = useState(false);
  const [visitorMode, setVisitorMode] = useState(Boolean(visitorCode));
  const [email, setEmail] = useState<ValidatedString>();
  const [phone, setPhone] = useState<ValidatedPhoneNumber>();
  const [visitorPhone, setVisitorPhone] = useState<ValidatedPhoneNumber>();
  const [visitorName, setVisitorName] = useState<ValidatedString>();
  let initialCurrentVisitorCode: ValidatedString | undefined;
  if (visitorCode) {
    initialCurrentVisitorCode = { value: visitorCode, valid: true };
  }
  const [currentVisitorCode, setCurrentVisitorCode] = useState<
    ValidatedString | undefined
  >(initialCurrentVisitorCode);

  useEffectOnce(() => {
    // Do this on load in case the visitor was supplied as a prop
    onSendVisitorCode();
  });

  const introScreen = !employeeMode && !visitorMode;

  // When an iPhone dismisses the keyboard, the view is scrolled down. Upon transitioning
  // to the next scene, we want to scroll back to the top of the window
  const scrollToTop = useCallback(() => {
    if (visitorCompanyName) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [visitorCompanyName]);

  useEffect(scrollToTop, [scrollToTop]);

  const onSendEmployeeCheckin = (): void => {
    onEmployeeRequest(phone?.international, email?.value);
  };

  const onSendVisitorCode = (): void => {
    if (!currentVisitorCode?.valid) {
      return;
    }
    onVisitorCodeSubmit(currentVisitorCode.value.replace(/-/g, ""));
  };

  const onRequestVisitorConversation = (): void => {
    if (
      !currentVisitorCode?.valid ||
      !visitorPhone?.valid ||
      !visitorName?.valid
    ) {
      return;
    }
    onVisitorRequest(
      currentVisitorCode.value.replace(/-/g, ""),
      visitorPhone.international,
      visitorName.value
    );
  };

  return (
    <div className={clsx("flex flex-col p-8 justify-between", className)}>
      <div className="w-full mt-5 mb-11 flex flex-row justify-center">
        <div style={{ width: "154px" }}>{BrandGreen}</div>
      </div>
      <div className="mt-5 flex flex-col justify-center tracking-tight text-hs-dark-green">
        <div className="text-4xl leading-tight font-medium text-center mb-8">
          {introScreen && "Check in with TeamSense"}
          {!introScreen &&
            (employeeMode || (visitorMode && !visitorCompanyName)) &&
            "Let's get you checked in."}
        </div>

        {employeeMode && (
          <EmployeeCheckInBody
            phone={phone}
            email={email}
            onPhoneChange={setPhone}
            onEmailChange={setEmail}
            onEnter={onSendEmployeeCheckin}
          />
        )}
        {visitorMode && !visitorCompanyName && (
          <VisitorCheckInBody
            code={currentVisitorCode}
            onCodeChange={setCurrentVisitorCode}
            onEnter={onSendVisitorCode}
          />
        )}
        {visitorMode && visitorCompanyName && (
          <VisitorCheckInPhoneName
            visitorCompanyName={visitorCompanyName}
            phone={visitorPhone}
            name={visitorName}
            onPhoneChange={setVisitorPhone}
            onNameChange={setVisitorName}
            onEnter={onRequestVisitorConversation}
          />
        )}
        {waiting && <Loading />}
      </div>
      {employeeMode && (
        <MessageActionButton
          text="Send my check-in"
          className="mt-4 py-4 tracking-wide"
          theme="green"
          disabled={waiting || (!phone?.valid && !email?.valid)}
          onClick={onSendEmployeeCheckin}
        />
      )}
      {visitorMode && !visitorCompanyName && (
        <MessageActionButton
          text="Submit"
          className="mt-4 py-4 tracking-wide"
          theme="green"
          disabled={waiting || !currentVisitorCode?.valid}
          onClick={onSendVisitorCode}
        />
      )}
      {visitorMode && visitorCompanyName && (
        <MessageActionButton
          text="Submit"
          className="mt-4 py-4 tracking-wide"
          theme="green"
          disabled={waiting || !visitorName?.valid || !visitorPhone?.valid}
          onClick={onRequestVisitorConversation}
        />
      )}
      {introScreen && (
        <MessageActionButton
          text="Employee Check-in"
          className="mt-4 py-4 tracking-wide"
          theme="green"
          onClick={() => setEmployeeMode(true)}
        />
      )}
      {introScreen && (
        <MessageActionButton
          text="Visitor Check-in"
          className="mt-4 py-4 tracking-wide"
          theme="white"
          onClick={() => setVisitorMode(true)}
        />
      )}
    </div>
  );
};
