import React, { FunctionComponent, useEffect } from "react";
import { useLocation } from "react-router";

// Sources/Triggers query params enum
// These should be always in sync with the values in the backend
enum Source {
  SMS = "sms",
  EMAIL = "email",
  UNKNOWN = "unk"
}

enum Trigger {
  ADMIN = "admin",
  POINTS = "points",
  PORTAL = "portal",
  UNKNOWN = "unk",
  SURVEY = "survey",
  REMINDER = "reminder",
  NOTIFICATION = "notify"
}

type Src = {
  source: Source | undefined;
  trigger: Trigger | undefined;
};

export const SourceWrapper: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const paramSrcKey = "src";
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query && query.has(paramSrcKey)) {
      const rawSrc = query.get(paramSrcKey);
      if (rawSrc) {
        const [source, trigger] = rawSrc.split("_");
        const src: Src = {
          source: Source.UNKNOWN,
          trigger: Trigger.UNKNOWN
        };
        if (Object.values(Source).includes(source as Source)) {
          src.source = source as Source;
        }
        if (Object.values(Trigger).includes(trigger as Trigger)) {
          src.trigger = trigger as Trigger;
        }
      }
    }
  }, [location]);

  return <>{children}</>;
};
