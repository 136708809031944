import React, { useContext, useEffect, useRef, useState } from "react";

import * as api from "~/api";
import { permissionMappings } from "~/api";
import { PermissionContext } from "~/lib/context";
import { GenericErrorText } from "~/ts-components/_old/common/error/GenericErrorText";
import { ToastContext } from "~/ts-components/common/ts-toast/TSToastProvider";

export enum Method {
  GET,
  PUT,
  POST,
  PATCH,
  DELETE
}

export const useApi = <T extends object>(
  url: string,
  method?: Method
): TApiResponse<T> => {
  // Other refs.
  const toast = useRef(useContext(ToastContext));
  const isMounted = useRef(false);

  // Hooks
  const [status, setStatus] = useState<Number>(0);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<api.APIErrors>();
  const [loading, setLoading] = useState<boolean>(false);

  // We "connect" to the provider thanks to the PermissionContext
  const { isAllowedTo } = useContext(PermissionContext);

  let passedPermissionCheck = false;

  // If we have defined a URL and its matching permissions AND current user has access to those permissions, call API
  // If we haven't defined the URL and its permissions, skip the check for now and call API
  if (
    !(url in permissionMappings) ||
    (url in permissionMappings && isAllowedTo(permissionMappings[url]))
  ) {
    passedPermissionCheck = true;
  }

  // Main function for making the API call.
  async function call(params?: ApiParams): Promise<TCallResponse<T>> {
    // Set loading.
    setLoading(true);

    // Prepare default response.
    let response: api.APIResponse<T> = {} as api.APIResponse<T>;

    if (passedPermissionCheck) {
      try {
        // We allow method overrides in case this is used for more than one request type with minor url differences.
        const reqMethod: Method = params?.method || method || Method.GET;

        // Build out our url.
        const reqUrl = `${url}${params?.path ?? ""}${
          (params && api.buildQueryString(params.params)) ?? ""
        }`.replaceAll("//", "/");

        // Call into our underlying api layer to complete the request.
        if (reqMethod === Method.GET) {
          response = await api.get<T>(reqUrl, undefined, params?.headers);
        } else if (reqMethod === Method.PUT) {
          response = await api.put<T>(reqUrl, params?.body, params?.headers);
        } else if (reqMethod === Method.POST) {
          response = await api.post<T>(reqUrl, params?.body, params?.headers);
        } else if (reqMethod === Method.PATCH) {
          response = await api.patch<T>(reqUrl, params?.body);
        } else if (reqMethod === Method.DELETE) {
          response = await api.destroy<T>(
            reqUrl,
            params?.body,
            params?.headers
          );
        }

        if (response) {
          setStatus(response.status);

          if (response.ok) {
            setData(response.data);
            setError(undefined);
          } else {
            setError(response.errors);
          }
        }
      } catch (error) {
        setError(error as api.APIErrors);
      }
    }

    // Done loading!
    setLoading(false);

    // Return out the current response data
    return {
      data: (response.ok && response.data) || undefined,
      status: response.status,
      ok: response.ok,
      error: (!response.ok && response.errors) || undefined
    };
  }

  useEffect(() => {
    if (isMounted.current && !loading && error?.non_field_errors) {
      toast.current.show({
        variant: "danger",
        message: <GenericErrorText />
      });
    }
  }, [loading, error]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return { call, status, data, error, loading };
};

type TBaseResponse<T> = {
  status?: Number;
  data?: T;
  error?: api.APIErrors;
  call: (opt?: ApiParams) => Promise<TCallResponse<T>>;
  loading: boolean;
  ok?: boolean;
};

export type TCallResponse<T> = Omit<TBaseResponse<T>, "call" | "loading">;
export type TApiResponse<T> = Omit<TBaseResponse<T>, "ok">;

type ApiParams = {
  path?: string;
  params?: Query;
  method?: Method;
  body?: any;
  headers?: api.Headers;
};

type Query = {
  [key: string]: string;
};
