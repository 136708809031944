import React, { FC, useContext, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";

import { AttendanceTab } from "./AttendanceTab";
import { StatusCalendar } from "./status-calendar/";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context";
import { useEffectOnce, useLinguiLocale } from "~/lib/hooks";
import { EditEmployeeModal } from "~/ts-components/employees/EditEmployeeModal";
import { EmployeeSummary } from "~/ts-components/employees/EmployeeSummary";
import { SidebarLayout } from "~common";

// remove when wired up
const PagePlaceholder: FC<{ name: string }> = ({ name }) => {
  return (
    <>
      <div className="main-content-header">{/*<TSButton /> */}</div>
      <h1>{name}</h1>
    </>
  );
};

export const EmployeeReportPane: FC<EmployeeReportPaneProps> = params => {
  const { parentUrl } = params;
  const match = useRouteMatch();
  const locale = useLinguiLocale();

  const { session } = useContext(MutableSessionContext);

  const [pages, setPages] = useState<PageProps[]>([]);
  const [employee, setEmployee] = useState<api.Employee>();

  const [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false);

  const { employeeId } = useParams<ParamTypes>();
  const oldRoute = `/dashboard/employees/employee/${employeeId}`;

  // Page URLs to show in sidebar
  const statusCalendarUrl = `${match.url}/calendar`;
  const surveysFormsUrl = `${oldRoute}/surveys`;
  const messagesUrl = `${oldRoute}/messages`;
  const notesUrl = `${oldRoute}/notes`;
  const notificationsUrl = `${oldRoute}/notifications`;
  const attendanceUrl = `${match.url}/attendance`;

  const modalCancelClickHandler = (): void => setShowEditEmployeeModal(false);
  const modalSaveClickHandler = (changedEmployee: api.Employee): void => {
    setShowEditEmployeeModal(false);
    setEmployee(changedEmployee);
  };

  const loadEmployee = async (): Promise<void> => {
    const response = await api.retrieveEmployee(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      session.company!.id,
      employeeId
    );

    if (response.ok) {
      setEmployee(response.data);
    } else {
      console.debug(
        "loadEmployeeWithEvents: could not retrieve employee: ",
        response.errors
      );
    }
  };

  useEffectOnce(() => {
    loadEmployee();
  });

  useEffect(() => {
    // Check if the optional nav items have been determined before setting the pages array.
    // We only want this to happen once, otherwise we have multiple data fetches on page load.

    const thePages = employee && [
      {
        label: "Calendar",
        href: statusCalendarUrl,
        icon: "icon-calendar",
        restrictedTo: [
          "EmployeeAttendanceStatus.READ",
          "EmployeeHealthStatus.READ"
        ],
        render: () => <StatusCalendar employee={employee} />
      },
      {
        label: "Surveys & Forms",
        href: surveysFormsUrl,
        icon: "icon-message",
        restrictedTo: "EmployeeSurveysForms.READ",
        render: () => <PagePlaceholder name="Surveys & Forms" />
      },
      {
        label: "Message Logs",
        href: messagesUrl,
        icon: "icon-send",
        restrictedTo: "EmployeeMessageLog.READ",
        render: () => <PagePlaceholder name="Message Logs" />
      },
      {
        label: "Notes",
        href: notesUrl,
        icon: "icon-edit",
        restrictedTo: "EmployeeNotes.READ",
        render: () => <PagePlaceholder name="Notes" />
      },
      {
        label: "Notifications",
        href: notificationsUrl,
        icon: "icon-notifications",
        restrictedTo: "EmployeeNotes.READ",
        render: () => <PagePlaceholder name="Notifications" />
      }
    ];

    if (thePages) {
      thePages.splice(0, 0, {
        label: "Attendance",
        href: attendanceUrl,
        icon: "icon-user",
        restrictedTo: [
          "EmployeeAttendanceStatus.READ",
          "EmployeeHealthStatus.READ"
        ],
        render: () => <AttendanceTab employee={employee} />
      });
    }

    // Only set the pages array if it does not exist yet.
    thePages && !pages.length && employee && setPages(thePages);
  }, [
    pages,
    locale,
    messagesUrl,
    notesUrl,
    employee,
    notificationsUrl,
    statusCalendarUrl,
    surveysFormsUrl,
    attendanceUrl
  ]);

  return (
    pages && (
      <>
        <SidebarLayout
          parentUrl={parentUrl}
          defaultUrl={attendanceUrl}
          mixedUsage={true}
          pages={pages}
          cardContent={
            <EmployeeSummary
              employee={employee}
              onEdit={() => setShowEditEmployeeModal(true)}
            />
          }
          detailsLinkText="Employee Details"
          sidebarLinkText="Employees"
        />
        <EditEmployeeModal
          show={showEditEmployeeModal}
          employee={employee}
          modalSaveClickHandler={modalSaveClickHandler}
          modalCancelClickHandler={modalCancelClickHandler}
        />
      </>
    )
  );
};

interface ParamTypes {
  employeeId: string;
  tab: "notes" | "surveys" | "messages" | "notifications" | undefined;
}

interface PageProps {
  label: string;
  href: string;
  icon: string;
  render: () => JSX.Element;
  restrictedTo: string | string[];
}

interface EmployeeReportPaneProps {
  parentUrl: string;
}
