import React, { FunctionComponent } from "react";
import { Col, Modal, Row } from "react-bootstrap";

import iconAttendanceTrends from "../../images/insights-attendance-trends.svg";
import iconCustomFilters from "../../images/insights-custom-filters.svg";
import iconEmpowerHRManagers from "../../images/insights-empower-hr-managers.svg";
import iconInformedData from "../../images/insights-informed-data.svg";
import { TSButton } from "../common/ts-buttons/TSButton";

import "./PromotionalModal.scss";

export const InsightsPromotionalModal: FunctionComponent<
  AttendanceInsightsPromotionalModalProps
> = ({ show, handleExit, mailTo }) => {
  const handleEmailClick = (): void => {
    window.location.href = mailTo;
  };

  interface FeatureProps {
    icon: string;
    title: string;
    description: string;
  }

  const Feature: FunctionComponent<FeatureProps> = ({
    icon,
    title,
    description
  }) => {
    return (
      <div className="feature-insights">
        <div className="icon-insights">
          <img src={icon} alt="Attendance Trends" />
        </div>

        <div className="description-insights">
          <h2 className="h3 m-0">{title}</h2>
          <h5 className="m-0">{description}</h5>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        show={show}
        container={document.getElementById("ts-modal")}
        centered
        onHide={handleExit}
        size="lg"
        className="insights-promotional-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Unlock Attendance Insights</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h2 className="h2">
                Change the Way you Look at your Absences Forever
              </h2>
              <p>
                Absences are more than meets the eye. There’s a ton of valuable
                data behind the scenes just waiting to see the light of day. Get
                all the slick features you need to learn more about your team’s
                absences, spot trends, and bring data to light.
              </p>
            </Col>
          </Row>
          <div className="mt-4">
            <Feature
              icon={iconAttendanceTrends}
              title="Attendance Trends"
              description="Reveal team members with perfect attendance or those absent the most."
            />
            <Feature
              icon={iconCustomFilters}
              title="Custom Filters"
              description="Easily sort absences by date, absence type, manager, or location."
            />
            <Feature
              icon={iconInformedData}
              title="Informed Data"
              description="Uncover detailed attendance data to help make decisions that impact profits and productivity."
            />
            <Feature
              icon={iconEmpowerHRManagers}
              title="Empower HR Managers"
              description="Equip managers with attendance tools and insight to support their teams."
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="display-flex">
          <div>
            <TSButton variant="link" size="lg" onClick={handleExit}>
              No, Thanks.
            </TSButton>
            <TSButton variant="primary" size="lg" onClick={handleEmailClick}>
              Unlock Now
            </TSButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface AttendanceInsightsPromotionalModalProps {
  show: boolean;
  handleExit: () => void;
  mailTo: string;
}
