import React, { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import * as api from "~/api";
import { Link } from "~/components/Link/Link";
import { ScheduleWeek } from "~/components/ScheduleWeek/ScheduleWeek";
import { Loading, GenericErrorText } from "~common";

interface EditNotificationsProps {
  employee: api.Employee;
  token?: string;
  desktopTimePicker?: boolean;
  insetDarkHeader?: boolean;
  onWeekdayTimesChange?: (weekdayTimes?: api.WeeklySchedule) => any;
  onDirtyChange?: (dirty: boolean) => any;
}

export const EditNotifications: FunctionComponent<EditNotificationsProps> = ({
  employee,
  token,
  desktopTimePicker,
  insetDarkHeader,
  onWeekdayTimesChange,
  onDirtyChange
}) => {
  const [loading, setLoading] = useState(false);
  const [invalidScheduleError, setInvalidScheduleError] = useState(false);
  const [weekdayTimes, setWeekdayTimes] = useState<api.WeeklySchedule>();
  const [currentWeekdayTimes, setCurrentWeekdayTimes] =
    useState<api.WeeklySchedule>();

  useEffect(() => {
    const loadSchedule = async (): Promise<void> => {
      let scheduleResponse:
        | api.APIResponse<api.RecurringSurveySchedule>
        | undefined;
      setLoading(true);
      try {
        scheduleResponse = await api.retrieveEmployeeRecurringSurveySchedule(
          employee.company_id,
          employee.id,
          { token }
        );
      } catch {}
      setLoading(false);

      if (!scheduleResponse?.ok) {
        if (scheduleResponse?.status === 409) {
          setInvalidScheduleError(true);
        } else {
          toast.error(<GenericErrorText />);
        }
        return;
      }

      setWeekdayTimes(scheduleResponse.data.schedule);
      setCurrentWeekdayTimes(scheduleResponse.data.schedule);
    };
    loadSchedule();
  }, [employee.company_id, employee.id, token]);

  useEffect(() => {
    onDirtyChange?.(
      JSON.stringify(currentWeekdayTimes) !== JSON.stringify(weekdayTimes)
    );
  }, [currentWeekdayTimes, onDirtyChange, weekdayTimes]);

  useEffect(() => {
    onWeekdayTimesChange?.(currentWeekdayTimes);
  }, [currentWeekdayTimes, onWeekdayTimesChange]);

  return (
    <div className="py-7" dir="auto">
      <label
        className={clsx(
          "flex uppercase mb-2 text-xs font-bold tracking-widest",
          insetDarkHeader ? "text-hs-dark-green px-9" : "text-hs-dark-gray"
        )}
      >
        <Trans id="editSchedule.title">Health Survey Schedule</Trans>
      </label>
      <div className="px-9">
        {loading && <Loading />}
        {currentWeekdayTimes && (
          <ScheduleWeek
            desktopPicker={desktopTimePicker}
            timezone={employee.timezone}
            weekdayTimes={currentWeekdayTimes}
            onChangeWeekdayTimes={setCurrentWeekdayTimes}
          />
        )}
        {invalidScheduleError && (
          <p className="whitespace-pre-wrap p-2">
            <Trans
              id="editSchedule.error.invalid"
              components={[
                <br key="1" />,
                <br key="2" />,
                <br key="3" />,
                <br key="4" />,
                <Link
                  key="5"
                  external
                  to="mailto:support@teamsense.com"
                  className="text-hs-green underline"
                >
                  {""}
                </Link>
              ]}
            >
              This employee has a special notification schedule that can't be
              directly edited.
              <br />
              <br />
              To edit this employee's notification schedule, reach out to
              <br />
              <br />
              <Link to="">support@teamsense.com</Link>
            </Trans>
          </p>
        )}
      </div>
    </div>
  );
};
