import React, { FunctionComponent, useContext, useState } from "react";

import clsx from "clsx";
import { Option } from "react-bootstrap-typeahead/types/types";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/";
import { employeeSearchHandler } from "~/lib/typeaheadHandler";
import { TSTypeahead } from "~common";

interface SearchEmployeeProps {
  className?: string;
  id: string;
  apiToken?: string;
  placeholder?: string;
  autoFocus?: boolean;
  presentation: "mobile" | "admin";
  onEmployeeSelected?: (employee?: api.IdentificationEmployee) => any;
}

export const SearchEmployee: FunctionComponent<SearchEmployeeProps> = props => {
  const {
    className,
    id,
    apiToken,
    placeholder,
    autoFocus,
    presentation,
    onEmployeeSelected
  } = props;

  const { session } = useContext(MutableSessionContext);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  const VAL_MESSAGE = {
    Required: "This field is required"
  };

  const typeaheadChangeHandler = (selected: Option[]): void => {
    selected.length > 0
      ? setFormError(undefined)
      : setFormError(VAL_MESSAGE.Required);
  };

  const employeeTypeaheadChangeHandler = (selected: Option[]): void => {
    if (selected.length > 0) {
      const selectedOption = selected[0] as api.IdentificationEmployee;
      onEmployeeSelected?.(selectedOption);
    } else {
      onEmployeeSelected?.();
    }
    typeaheadChangeHandler(selected);
  };

  return (
    <div
      className={clsx(
        "flex flex-col",
        presentation === "mobile" && "h-full",
        className
      )}
    >
      <TSTypeahead
        id={id}
        onChange={selected => employeeTypeaheadChangeHandler(selected)}
        showId
        options={[]}
        placeholder={placeholder}
        autoFocus={autoFocus}
        errorText={formError}
        isInvalid={formError !== undefined}
        asyncSearchHandler={nameOrId =>
          employeeSearchHandler(session, { nameOrId, token: apiToken })
        }
        required
      />
    </div>
  );
};
