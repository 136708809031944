import React, { FunctionComponent } from "react";

import { Link } from "../Link/Link";

import { PhoneInput } from "./PhoneInput";
import { ValidatedPhoneNumber, ValidatedString } from "./types";

import { TextInput } from "~common";

interface VisitorCheckInPhoneNameProps {
  visitorCompanyName: string;
  phone?: ValidatedPhoneNumber;
  name?: ValidatedString;
  onPhoneChange: (phone?: ValidatedPhoneNumber) => any;
  onNameChange: (email?: ValidatedString) => any;
  onEnter: () => any;
}

export const VisitorCheckInPhoneName: FunctionComponent<
  VisitorCheckInPhoneNameProps
> = ({
  visitorCompanyName,
  phone,
  name,
  onPhoneChange,
  onNameChange,
  onEnter
}) => (
  <>
    <div className="space-y-2 text-xs font-medium">
      <p>
        Thank you for reporting your health status before visiting{" "}
        {` ${visitorCompanyName}`}.
      </p>
      <p>
        We will ask for your contact information and a few questions about your
        health. Depending on your responses, you will be cleared for your visit,
        or will be asked to refrain from visiting at this time.
      </p>
      <p>Let's start with your contact information:</p>
    </div>

    <div className="mt-4 ml-3 text-lg font-medium">Mobile Number</div>
    <PhoneInput
      className="mt-2 py-2 px-5 rounded border border-hs-dark-gray focus-within:border-hs-green"
      inputClassName="font-semibold"
      inputStyle={{ fontSize: "18px" }}
      value={phone?.value ?? ""}
      onChange={(value, valid, international) => {
        onPhoneChange({ value, valid, international });
      }}
      onEnter={onEnter}
    />

    <div className="mt-2 ml-3 text-lg font-medium">First and Last Name</div>
    <TextInput
      className="mt-2 py-2 px-5 wk-appearance-none rounded border border-hs-dark-gray focus-within:border-hs-green text-lg font-semibold"
      value={name?.value ?? ""}
      validationRegex={/^.+$/}
      onChange={(value, valid) => {
        onNameChange({ value, valid });
      }}
      onEnter={onEnter}
    />

    <div className="mt-2 space-y-2 text-xs font-medium text-hs-dark-gray">
      <p className="my-2">
        Note that no one at
        {` ${visitorCompanyName} `}
        will have access to the details of your responses - only the result
        (cleared or not). Your contact information will not be used for anything
        other than following up with you for contact tracing purposes. We clear
        all the data at regular intervals. Please contact your host or company
        contact with any questions.
      </p>
      <Link
        external
        to="https://www.teamsense.com/terms-of-service"
        className="text-hs-dark-gray underline"
      >
        Terms of Service
      </Link>
      {" & "}
      <Link
        external
        to="https://www.teamsense.com/privacy-policy"
        className="text-hs-dark-gray underline"
      >
        Privacy Policy
      </Link>
    </div>
  </>
);
