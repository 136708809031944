import React, { FunctionComponent } from "react";
import { Card } from "react-bootstrap";

import clsx from "clsx";

import {
  DailyHeatMap,
  MonthlyHeatMap,
  WeeklyHeatMap
} from "./absences-heat-map";
import { AttendanceLegendItem } from "./AttendanceLegendItem";
import { AttendanceInsightsNoResults } from "./AttendanceNoResults";

import * as api from "~/api";
import { FrameLength, heatmapColorMap } from "~/lib/attendanceInsights";

import "./AbsencesHeatMap.scss";

export const AbsencesHeatMap: FunctionComponent<AbsencesHeatMapInterface> = ({
  data,
  frameLength,
  isLoading
}) => {
  const hasData = Boolean(data?.find(d => d.summary.absencesCount > 0));
  return (
    <Card
      className={clsx("attendance-insights-card", isLoading && "opacity-25")}
    >
      <div className="p-4">
        {!isLoading && hasData ? (
          <>
            <header>
              <h3>Absences Heat Map</h3>
              <h2>
                These are the dates your employees were most frequently absent.
              </h2>
            </header>
            {frameLength === "week" && <WeeklyHeatMap data={data} />}
            {frameLength === "month" && <MonthlyHeatMap data={data} />}
            {frameLength === "day" && <DailyHeatMap data={data} />}
            <footer className="attendance-heatmap-legend">
              {heatmapColorMap.map(item => (
                <AttendanceLegendItem
                  color={item.color}
                  text={item.value}
                  key={item.color}
                />
              ))}
            </footer>
          </>
        ) : (
          <AttendanceInsightsNoResults title="Absences Heat Map"></AttendanceInsightsNoResults>
        )}
      </div>
    </Card>
  );
};

interface AbsencesHeatMapInterface {
  isLoading: boolean;
  data?: api.Frame[];
  frameLength?: FrameLength;
}
