import React, { FunctionComponent, ReactNode } from "react";

import clsx from "clsx";

export interface FieldLabelProps {
  textClassName?: string;
  required?: boolean;
  htmlFor?: string;
  children?: ReactNode;
}

export const FieldLabel: FunctionComponent<FieldLabelProps> = ({
  textClassName,
  required,
  htmlFor,
  children
}) => (
  <label
    htmlFor={htmlFor}
    className={clsx(
      "flex flex-row uppercase mx-1 mb-2 text-xs font-bold tracking-widest",
      textClassName,
      !textClassName && "text-hs-dark-gray"
    )}
  >
    <>
      {required && <div className="text-hs-green mr-1">＊</div>}
      {children}
    </>
  </label>
);
