import React, { FunctionComponent, useState, useEffect } from "react";

import clsx from "clsx";

import { Modal, ModalProps } from "./Modal";

export interface ConfirmationModalProps extends ModalProps {
  prompt: string;
  onConfirm: () => any;
  confirmText?: string;
  confirmDisabled?: boolean;
  cancelText?: string;
  dangerous?: boolean;
  topRightLinkText?: string;
  onClickTopRightLink?: () => any;
}

export const ConfirmationModal: FunctionComponent<
  ConfirmationModalProps
> = props => {
  const {
    children,
    className,
    prompt,
    onClose,
    onConfirm,
    confirmText,
    confirmDisabled,
    cancelText,
    dangerous,
    topRightLinkText,
    onClickTopRightLink
  } = props;

  const [disable, setDisable] = useState(confirmDisabled);

  useEffect(() => {
    setDisable(confirmDisabled);
  }, [confirmDisabled]);

  const buttonClass = clsx(
    "w-full inline-flex items-center justify-center px-4 py-2 border-none",
    "font-medium rounded-full"
  );
  const cancelButtonClass = "text-hs-green";
  const sendButtonClass = clsx(
    "text-white px-6 focus:outline-none focus:shadow-outline",
    confirmDisabled && "bg-hs-dark-gray",
    !confirmDisabled &&
      (dangerous ? "button--destructive" : "bg-hs-green hover:bg-hs-alt-green")
  );

  return (
    <Modal
      className={clsx(className, "rounded max-w-lg p-6")}
      onClose={onClose}
    >
      <div className="grid grid-cols-1 p-2 sm:p-3 z-50 space-y-2">
        <div className="flex flex-row justify-between">
          <h3 className="text-2xl leading-6 font-medium">{prompt}</h3>
          {topRightLinkText && (
            <div
              className="text-hs-red hover:text-hs-dark-red cursor-pointer"
              onClick={onClickTopRightLink}
            >
              {topRightLinkText}
            </div>
          )}
        </div>
        {children}
        <div className="flex justify-end">
          <div className="mt-3 rounded-md ml-3 w-auto">
            <button
              type="button"
              className={`${buttonClass} ${cancelButtonClass}`}
              onClick={onClose}
            >
              {cancelText ?? "Cancel"}
            </button>
          </div>
          <div className="mt-3 rounded-md ml-3 w-auto">
            <button
              type="submit"
              disabled={disable}
              className={`${buttonClass} ${sendButtonClass}`}
              onClick={() => {
                setDisable(true);
                onConfirm();
              }}
            >
              {confirmText ?? "Confirm"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
