import React, { FunctionComponent } from "react";

import { DateTime } from "luxon";

import "./AttendancePaneEmptyMessage.scss";

export const AttendancePaneEmptyMessage: FunctionComponent<
  AttendancePaneEmptyMessageProps
> = ({ selectedDate }) => {
  const today = DateTime.local();
  let formattedDate = "";
  try {
    formattedDate = selectedDate.toLocaleString(DateTime.DATE_MED);
  } catch (e) {
    formattedDate = selectedDate.toLocaleString({
      locale: "en-US",
      ...DateTime.DATE_MED
    });
  }
  const isPast = selectedDate.startOf("day") < today.startOf("day");

  return (
    <h1 className="title-empty-attendances">
      <span className="icon-hands">🙌</span>
      {formattedDate}
      {isPast
        ? " was a pretty awesome day. No absences!"
        : " currently has no absences scheduled!"}
    </h1>
  );
};

interface AttendancePaneEmptyMessageProps {
  selectedDate: DateTime;
}
