import React, { FunctionComponent, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { Typography } from "@mui/material";
import { DateTime } from "luxon";

import AttendanceHistory from "./attendance/AttendanceHistory";

import * as api from "~/api";
import {
  getEmployeeAttendanceEvents,
  getEmployeeAttendancePoints
} from "~/api";
import AlertIcon from "~/images/alert-icon.svg";
import NewFeature from "~/images/new-feature.svg";
import NotAvailable from "~/images/not-available.svg";
import { MutableSessionContext } from "~/lib/context";
import { DateFormat, getFormattedDate } from "~/lib/dateFormatter";
import { useEffectOnce, useLinguiLanguage } from "~/lib/hooks";
import {
  getLabelColorDarkened,
  getLabelColorLightened,
  getLabelDisplay
} from "~/lib/status";
import AttendancePointsBar from "~/mui-components/AttendancePointsBar/AttendancePointsBar";
import Card from "~/mui-components/Card/Card";

export const AttendanceTab: FunctionComponent<PointsInterface> = ({
  employee
}) => {
  const [attendancePoints, setAttendancePoints] = useState<
    api.AttendancePoints | null | undefined
  >(undefined);
  const [attendancePolicy, setAttendancePolicy] =
    useState<api.AttendancePolicy | null>(null);
  const [eventCounts, setEventCounts] = useState<
    api.AttendanceEvent[] | undefined
  >(undefined);
  const [lastPointsNotificationDate, setLastPointsNotificationDate] =
    useState<string>("");
  const showPageTitle = useMediaQuery({ query: "(min-width: 800px)" });
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const labels = session?.labels;

  const getEmployeeAttendanceEventsAndPoints = (): void => {
    if (employee?.id) {
      getEmployeeAttendancePoints(employee.company_id, employee.id).then(
        response => {
          if (
            response.ok &&
            "data" in response &&
            response.data.attendancePoints
          ) {
            setAttendancePoints(response.data.attendancePoints);
          } else {
            setAttendancePoints(null);
          }
        }
      );

      getEmployeeAttendanceEvents(employee.company_id, employee.id).then(
        response => {
          if (response.ok) {
            setEventCounts(response.data.eventCounts);
          }
        }
      );
    }
  };

  const fetchAttendancePolicy = (): void => {
    if (employee?.company_id && employee?.id) {
      api
        .getAttendancePolicy(employee.company_id, employee.id)
        .then(response => {
          if (response.ok && "data" in response) {
            setAttendancePolicy(response.data);
          }
        });
    }
  };

  const fetchLastPointsNotificationEvent = (): void => {
    if (employee?.company_id && employee?.id) {
      api
        .getLatestPointsNotificationEvent(employee.company_id, employee.id)
        .then(response => {
          if (response.ok && "data" in response) {
            setLastPointsNotificationDate(
              getFormattedDate(
                response.data.started,
                DateFormat.dateTime12HourWithAtAndTimeZone
              )
            );
          }
        });
    }
  };

  useEffectOnce(() => {
    getEmployeeAttendanceEventsAndPoints();
    fetchAttendancePolicy();
    fetchLastPointsNotificationEvent();
  });

  const leftIcon = (
    attendancePoints: api.AttendancePoints | null
  ): JSX.Element => {
    if (attendancePoints === null) {
      return <img src={NewFeature} alt="alert" />;
    } else if (attendancePoints?.count === null) {
      return <img src={NotAvailable} alt="alert" />;
    } else {
      return <img src={AlertIcon} alt="alert" />;
    }
  };

  const pointsContent = (
    attendancePoints: api.AttendancePoints | null
  ): JSX.Element => {
    if (attendancePoints?.count === null) {
      return (
        <div className="flex flex-column justify-center ml-5">
          <div className="card-text text-lg font-semibold">
            Points Balance currently unavailable
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-column justify-between ml-5">
          <div className="card-title text-3xl font-semibold leading-6">
            {attendancePoints?.count}
          </div>
          <p className="card-text text-base">Attendance Points</p>
        </div>
      );
    }
  };

  const PointsContainer = (): JSX.Element => {
    if (!attendancePoints) {
      return <></>;
    }
    return (
      <>
        <div className="sidebar-info-card card flex-row">
          <div className="p-4 flex flex-row">
            <>
              {leftIcon(attendancePoints)}
              {pointsContent(attendancePoints)}
            </>
          </div>
        </div>
        {attendancePoints && attendancePoints.count !== null ? (
          <div className="font-medium mt-2 text-base text-gray-500">
            Balances last updated on{" "}
            {DateTime.fromISO(attendancePoints.updated).toFormat(
              "LLL dd, ttt",
              { locale: "en-US" }
            )}
          </div>
        ) : null}
      </>
    );
  };

  const StatusesContainer = (): JSX.Element | null => {
    const totalEvents = eventCounts?.reduce(
      (total, event) => total + event.count,
      0
    );

    const eventCountsWithText = eventCounts?.map(event => {
      return {
        ...event,
        displayText: getLabelDisplay(event.label, labels, language)
      };
    });
    return totalEvents ? (
      <div className="sidebar-info-card card flex-row mt-4">
        {totalEvents > 0 && (
          <div className="p-4 flex flex-column w-full">
            <div className="card-title text-lg font-normal leading-6 mb-5">
              In the last 12 months, {employee.name} has had {totalEvents}{" "}
              occurrence
              {totalEvents > 1 ? "s" : ""}:
            </div>

            <div className="flex flex-row flex-wrap columns-4 justify-evenly">
              {eventCountsWithText?.map(event => {
                const labelTextColor = getLabelColorDarkened(
                  event.label,
                  session.labels
                );
                const labelBgColor = getLabelColorLightened(
                  event.label,
                  session.labels
                );

                const pillStyle = {
                  color: labelTextColor,
                  backgroundColor: labelBgColor
                };
                return (
                  <div
                    className="flex flex-column mt-2 flex-wrap items-center p-2 mx-4 mb-2 w-40"
                    key={event.label}
                  >
                    <div className="card-title text-3xl font-semibold leading-6">
                      {event.count}
                    </div>
                    <div
                      className="rounded-pill font-bold text-center"
                      style={pillStyle}
                    >
                      {event.displayText}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    ) : null;
  };

  return (
    <>
      <div className="main-content-header justify-start">
        <h2 className="!text-2xl !leading-6">
          {showPageTitle && "Attendance"}
        </h2>
      </div>
      {attendancePolicy ? (
        <Card className="flex flex-row flex-wrap justify-center items-center my-3 gap-x-8 gap-y-4">
          <div className="flex flex-col grow max-w-xl mr-auto gap-y-2 w-full">
            <AttendancePointsBar
              attendancePointsUsed={attendancePoints?.count || 0}
              showAllSegmentNumbers={true}
              thresholds={attendancePolicy.points_thresholds}
              canSeeInfoDetails={true}
              infoDetailsText={attendancePolicy.summary.join("\n")}
              lastUpdatedDate={attendancePoints?.updated}
              unavailable={attendancePoints?.count === null}
              hideDate={true}
            />
            <div className="flex flex-col text-ts-gray-40">
              <Typography variant="caption">
                Points data last updated{" "}
                {getFormattedDate(
                  attendancePoints?.updated || "",
                  DateFormat.dateTime12HourWithAtAndTimeZone
                )}
              </Typography>
              {lastPointsNotificationDate && (
                <Typography variant="caption">
                  Last notification sent {lastPointsNotificationDate}
                </Typography>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <PointsContainer />
      )}
      <StatusesContainer />
      <AttendanceHistory
        employee={employee}
        noTopPaddingHistory={!attendancePoints}
        getEmployeeAttendanceEventsAndPoints={
          getEmployeeAttendanceEventsAndPoints
        }
      />
    </>
  );
};

interface PointsInterface {
  employee: api.Employee;
}
