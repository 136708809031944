import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import * as api from "~/api";
import { useConversationTranslation } from "~/lib/hooks";

interface PortalLinkProps {
  portalLink: api.PortalLink;
  onClick: (template: api.PortalLink) => any;
}

const PortalLink: FunctionComponent<PortalLinkProps> = ({
  portalLink,
  onClick
}) => {
  const displayText =
    useConversationTranslation(portalLink.text) || portalLink.url;
  return (
    <div
      className="w-full p-4 rounded-lg bg-white text-base text-ts-teal-20 cursor-pointer"
      onClick={() => onClick(portalLink)}
    >
      {displayText}
    </div>
  );
};

interface PortalListProps {
  className?: string;
  portalLinks: api.PortalLink[];
  onClick: (template: api.PortalLink) => any;
}

export const PortalList: FunctionComponent<PortalListProps> = ({
  className,
  portalLinks,
  onClick
}) => {
  const linksAvailable = portalLinks.length > 0;
  return (
    <div
      className={clsx(
        "w-full flex flex-grow overflow-y-hidden flex-col",
        className
      )}
      dir="auto"
    >
      {!linksAvailable && (
        <div className="p-9 rounded-t-4xl">
          <p className="text-lg font-semibold text-ts-gray-70">
            <Trans id="portal.list.noLinks">
              Hmm, it looks like your company hasn&#39;t added any portal links
              yet
            </Trans>
          </p>
        </div>
      )}
      {linksAvailable && (
        <>
          <div className="mt-4 px-5 flex flex-grow overflow-y-auto">
            <div className="space-y-4 w-full">
              {portalLinks.map((portalLink, index) => (
                <PortalLink
                  key={`${portalLink.url}-${index}`}
                  portalLink={portalLink}
                  onClick={onClick}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
