import React, { FunctionComponent, useState } from "react";

import * as api from "~/api";
import {
  CreateableEntitiesDropdown,
  ConfirmationModal,
  ModalProps
} from "~common";

interface AddGroupsModalProps extends ModalProps {
  employeeIds: string[];
  groups: api.Group[];
  onConfirm?: (groups: api.GroupWriter[]) => any;
}

export const AddGroupsModal: FunctionComponent<AddGroupsModalProps> = ({
  employeeIds,
  groups,
  onClose,
  onConfirm
}) => {
  const [selectedGroups, setSelectedGroups] = useState<api.GroupWriter[]>([]);

  const prompt =
    employeeIds.length > 1
      ? `Add ${employeeIds.length} employees to groups`
      : `Add employee to groups`;

  return (
    <ConfirmationModal
      className="overflow-visible"
      prompt={prompt}
      onClose={onClose}
      onConfirm={() => onConfirm?.(selectedGroups)}
      confirmDisabled={selectedGroups.length === 0}
    >
      <p className="text-sm pt-1 pb-3">Select groups:</p>
      <CreateableEntitiesDropdown
        allEntities={groups}
        entities={selectedGroups}
        placeholder="Type to find or create a group..."
        onChange={setSelectedGroups}
      />
    </ConfirmationModal>
  );
};
