import React, { FC } from "react";

import NoResponsesLargeIcon from "~/images/no-responses-large-icon.svg";

import "./ResultsGauge.scss";

interface FormResultsNoResponsesProps {
  noSurveysSent: boolean;
  noFiltersApplied: boolean;
}

export const FormResultsNoResponses: FC<FormResultsNoResponsesProps> = ({
  noSurveysSent,
  noFiltersApplied
}) => (
  <div className="form-results-no-responses">
    <img src={NoResponsesLargeIcon} alt="" />
    <h1>
      {noFiltersApplied && "There are no responses for this survey yet."}
      {noFiltersApplied &&
        noSurveysSent &&
        " Send it out to your employees to get results."}
      {!noFiltersApplied && (
        <>
          We couldn't find any responses that met your criteria. Tip: try
          checking your filters.
        </>
      )}
    </h1>
  </div>
);
