import {
  formatISODateToCustomFormat,
  getDateRange,
  getLabelDisplay
} from "./status";

import * as api from "~/api";

export const sortEmployeesWithEvents = (
  data: api.SimpleEmployeeWithEvents[],
  field: string,
  dir: number,
  session: any,
  language: string
): api.SimpleEmployeeWithEvents[] => {
  if (!data || data.length === 0) {
    return data;
  }

  const sortedAsc = [...data];
  const sortedDesc = [...data];

  if (field === "given_name") {
    sortedAsc.sort((a: any, b: any) =>
      a?.given_name?.localeCompare(b?.given_name)
    );
    sortedDesc.sort((a: any, b: any) =>
      b?.given_name?.localeCompare(a?.given_name)
    );
  } else if (field === "family_name") {
    sortedAsc.sort((a: any, b: any) =>
      a?.family_name?.localeCompare(b?.family_name)
    );
    sortedDesc.sort((a: any, b: any) =>
      b?.family_name?.localeCompare(a?.family_name)
    );
  } else if (field === "external_id") {
    sortedAsc.sort((a: any, b: any) =>
      a?.external_id?.localeCompare(b?.external_id)
    );
    sortedDesc.sort((a: any, b: any) =>
      b?.external_id?.localeCompare(a?.external_id)
    );
  } else if (field === "syncStatus") {
    sortedAsc?.sort((a: any, b: any) =>
      a?.events?.[0]?.sync_status?.localeCompare(b?.events?.[0]?.sync_status)
    );
    sortedDesc?.sort((a: any, b: any) =>
      b?.events?.[0]?.sync_status?.localeCompare(a?.events?.[0]?.sync_status)
    );
  } else if (field === "status") {
    sortedAsc.sort((a: any, b: any) =>
      getLabelDisplay(
        a.events[0].label.name,
        session.labels,
        language
      ).localeCompare(
        getLabelDisplay(b.events[0].label.name, session.labels, language)
      )
    );
    sortedDesc.sort((a: any, b: any) =>
      getLabelDisplay(
        b.events[0].label.name,
        session.labels,
        language
      ).localeCompare(
        getLabelDisplay(a.events[0].label.name, session.labels, language)
      )
    );
  } else if (field === "statusDates") {
    sortedAsc.sort((a: any, b: any) =>
      getDateRange(
        a?.timezone,
        a?.events[0].started,
        a?.events[0].ended
      ).localeCompare(
        getDateRange(b.timezone, b.events[0].started, b.events[0].ended)
      )
    );
    sortedDesc.sort((a: any, b: any) =>
      getDateRange(
        b.timezone,
        b.events[0].started,
        b.events[0].ended
      ).localeCompare(
        getDateRange(a.timezone, a.events[0].started, a.events[0].ended)
      )
    );
  } else if (field === "created") {
    sortedAsc.sort((a: any, b: any) =>
      formatISODateToCustomFormat(
        a?.events[0].created,
        a.timezone
      ).localeCompare(
        formatISODateToCustomFormat(b.events[0].created, b.timezone)
      )
    );
    sortedDesc.sort((a: any, b: any) =>
      formatISODateToCustomFormat(
        b.events[0].created,
        b.timezone
      ).localeCompare(
        formatISODateToCustomFormat(a.events[0].created, a.timezone)
      )
    );
  }

  if (dir === 1) {
    return sortedAsc;
  } else if (dir === -1) {
    return sortedDesc;
  }

  return data;
};

export const findEventDifferences = (
  currentEmployee: api.SimpleEmployeeWithEvents,
  updatedEmployee: api.SimpleEmployeeWithEvents
): boolean => {
  // Check if both employees have events arrays
  if (!currentEmployee?.events || !updatedEmployee?.events) {
    return false;
  }

  const currentEvents = currentEmployee.events;
  const updatedEvents = updatedEmployee.events;

  // Find the minimum length of the two events arrays
  const minLength = Math.min(currentEvents.length, updatedEvents.length);

  // Initialize an array to store the differences
  const differences = [];

  for (let i = 0; i < minLength; i++) {
    const currentEvent = currentEvents[i];
    const updatedEvent = updatedEvents[i];

    // Check if both events have a sync_status property
    if (
      currentEvent?.sync_status !== undefined &&
      updatedEvent?.sync_status !== undefined
    ) {
      // Compare sync_status of the current event with the updated event
      if (currentEvent.sync_status !== updatedEvent.sync_status) {
        differences.push({
          index: i,
          currentSyncStatus: currentEvent.sync_status,
          updatedSyncStatus: updatedEvent.sync_status
        });
      }
    }
  }

  return differences.length > 0;
};
