/* The purpose of this route is to clearly indicate which routes are
 * no longer intended to be supported in cra-client. */
import React, { FunctionComponent, ReactElement } from "react";
import { Route, RouteProps } from "react-router-dom";

import { FullRedirect } from "./FullRedirect";

interface TransitionRouteProps extends RouteProps {
  children?: ReactElement;
}

export const TransitionRoute: FunctionComponent<TransitionRouteProps> = ({
  children,
  ...config
}) => {
  const redirectPortedRoutes =
    process.env.REACT_APP_REDIRECT_PORTED_ROUTES === "YES" || false;
  const isLocalhost = window.location.hostname === "localhost";
  // when localhost port is 8080, we are testing docker, and this is ok.
  const redirectOK = isLocalhost && window.location.port !== "8080";

  // If we are not running dev server directly and redirect is set
  // tell the broswer to phone home to server. Else just return the
  // children.
  if (redirectPortedRoutes && redirectOK) {
    return (
      <div className="m-8">
        <p className="p-4">
          Cowardly refusing to redirect ported route recursively when connecting
          directly to dev server on <b>{window.location.href}</b>.
        </p>
        <p className="p-4">
          When <code>REACT_APP_REDIRECT_PORTED_ROUTES=YES</code> you must
          connect via a proxy address (in dev:
          <a className="text-blue-600" href="https://dev.teamsense.local">
            dev.teamsense.local
          </a>
          ) so that the proxy can intercept and route it to vite frontend.
        </p>
        <p className="p-4">
          If you want to get back to the login screen with the dev server you
          will need to unset this env var and restart your dev server.
        </p>
      </div>
    );
  } else if (redirectPortedRoutes) {
    return (
      <Route
        {...config}
        render={(props): ReactElement => {
          return (
            <FullRedirect
              to={{
                pathname: `${props.location.pathname}${props.location.search}`
              }}
            />
          );
        }}
      />
    );
  } else {
    return (
      <Route
        {...config}
        render={routeProps => {
          if (React.isValidElement(children)) {
            return React.cloneElement(children, routeProps);
          } else {
            return children;
          }
        }}
      />
    );
  }
};
