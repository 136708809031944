import {
  AlteredEventSyncStatus,
  AttendanceStatus,
  CovidHealthStatus,
  Employee,
  EmployeeEvent,
  EmployeeEventWithEmployee,
  EmployeeNotificationStatus,
  ExternalEmployee,
  IdentificationEmployee,
  LabelSet,
  NewEmployee,
  Note,
  StatusCounts
} from "./employees";
import {
  APIResponse,
  destroy,
  get,
  Headers,
  PaginatedResponse,
  patch,
  post,
  put
} from "./lib";

import { DeepPartial } from "~/lib/DeepPartial";

export interface Company {
  id: string;
  name: string;
  url: string;
  languages: string[];
  code?: string;
}

export interface Division {
  id: string;
  name: string;
  code?: string;
}

export interface IdentifyEmployeeResponse {
  friendly_name: string;
  company_name: string;
  inbound_sms_phone_number: string;
  token: string;
}

export interface SurveyTokenResponse {
  request_survey_token: string;
}

export interface WeeklySchedule {
  [key: string]: string;
}

export interface RecurringSurveySchedule {
  schedule: WeeklySchedule;
  survey_template_id: string;
}

export interface RecurringSurveyScheduleWriter {
  schedule: WeeklySchedule;
  survey_template_id?: string;
}

export interface ExpandedDivision extends Division {
  company: Company;
}

export const isDivision = (
  companyOrDivision: Company | ExpandedDivision
): companyOrDivision is ExpandedDivision =>
  (companyOrDivision as ExpandedDivision).company !== undefined;

export interface EmployeeTokenInfo {
  company_id: string;
  employee_id: string;
}

/**
  We should consider a data structure like this:
  tzname: { [key: string]: string }
  so that way the front-end can localize the timezone names
  and pass in additional context (e.g short timezones)
 */
export interface Timezones {
  iana: string;
  tzname: string;
}

/** Query the server to obtain the employees for a given team lead */
export const retrieveTeamLeadTeamMembers = async (
  company_id: string,
  employee_id: string,
  options?: {
    token?: string;
    events?: boolean;
    activeEvents?: boolean;
    notifications?: boolean;
  }
): Promise<APIResponse<Employee[]>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return await get(
    `/companies/${company_id}/employees/${employee_id}/team_members/`,
    {
      events: options?.events ? "1" : "0",
      active_events: options?.activeEvents ? "1" : "0",
      notifications: options?.notifications ? "1" : "0"
    },
    headers
  );
};

/** Query the server to obtain the information for an employee */
export const retrieveEmployee = async (
  company_id: string,
  employee_id: string,
  options?: {
    token?: string;
    events?: boolean;
    activeEvents?: boolean;
    healthSurveyActivity?: boolean;
    permissionSets?: boolean;
  }
): Promise<APIResponse<Employee>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  // TODO: move to a new query parameter format to request which data payload we want
  return await get(
    `/companies/${company_id}/employees/${employee_id}/`,
    {
      ...(options?.events ? { events: "1" } : {}),
      ...(options?.activeEvents ? { active_events: "1" } : {}),
      ...(options?.healthSurveyActivity ? { health_survey_activity: "1" } : {}),
      ...(options?.permissionSets ? { permission_sets: "1" } : {})
    },
    headers
  );
};

/** Query the server to obtain the notifications statuses for an employee */
export const retrieveEmployeeNotificationStatuses = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<EmployeeNotificationStatus[]>> => {
  return await get(
    `/companies/${company_id}/employees/${employee_id}/notifications/`
  );
};

/** Deactivate an employee */
export const deactivateEmployee = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<{}>> => {
  return await destroy(`/companies/${company_id}/employees/${employee_id}/`);
};

/** Activate an employee */
export const activateEmployee = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<Employee>> => {
  return await post(
    `/companies/${company_id}/employees/${employee_id}/activate/`,
    {}
  );
};

/** Query the server to obtain the weekly recurring survey schedule for an employee */
export const retrieveEmployeeRecurringSurveySchedule = async (
  company_id: string,
  employee_id: string,
  options?: {
    token?: string;
  }
): Promise<APIResponse<RecurringSurveySchedule>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return await get(
    `/companies/${company_id}/employees/${employee_id}/recurring_survey_schedule/`,
    undefined,
    headers
  );
};

/** Modify a weekly recurring schedule for an employee */
export const setEmployeeRecurringSurveySchedule = async (
  company_id: string,
  employee_id: string,
  schedule: RecurringSurveyScheduleWriter,
  options?: {
    token?: string;
  }
): Promise<APIResponse<{}>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return await put(
    `/companies/${company_id}/employees/${employee_id}/recurring_survey_schedule/`,
    schedule,
    headers
  );
};

/** Query the server to obtain the information for a company */
export const retrieveCompany = async (
  company_id: string
): Promise<APIResponse<Company>> => {
  return await get(`/companies/${company_id}/`);
};

/** Query the server to obtain the information for an employee by external id */
export const retrieveExternalEmployee = async (
  company_id: string,
  external_id: string
): Promise<APIResponse<ExternalEmployee>> => {
  return await get(
    `/companies/${company_id}/external_employees/${encodeURIComponent(
      external_id
    )}/`
  );
};

/** Query the server to obtain the information for an employee by external id
 *  and (optionally) company name */
export const identifyEmployee = async (
  smsToken: string,
  badge_number: string,
  company_name?: string
): Promise<APIResponse<IdentifyEmployeeResponse>> => {
  return await post(
    `/companies/identify_employee/`,
    {
      badge_number,
      ...(company_name ? { company_name } : {})
    },
    {
      "X-SMSTokenSession": smsToken
    }
  );
};

/** Update the phone number for an employee identified using the identifyEmployee() API */
export const updateIdentifiedEmployeePhone = async (
  token: string
): Promise<APIResponse<SurveyTokenResponse>> => {
  return await post(
    `/companies/update_identified_employee_phone/`,
    {},
    {
      "X-EmployeeTokenSession": token
    }
  );
};

/** Confirm the phone number for an employee identified using the identifyEmployee() API */
export const confirmIdentifiedEmployeePhone = async (
  token: string,
  phone_number: string
): Promise<APIResponse<SurveyTokenResponse>> => {
  return await post(
    `/companies/confirm_identified_employee_phone/`,
    { phone_number },
    {
      "X-EmployeeTokenSession": token
    }
  );
};

export type StatusFilterChoices = CovidHealthStatus | AttendanceStatus | "None";

interface EmployeeFilterOptions {
  teamLeadsOnly?: boolean;
  teamLeadIds?: string[];
  groupIds?: string[];
  divisionIds?: string[];
  statuses?: StatusFilterChoices[];
  name?: string;
  nameOrId?: string;
  inactiveOnly?: boolean;
}

const headersFromFilterOptions: (
  filterOptions?: EmployeeFilterOptions
) => Headers = options => ({
  ...(options?.inactiveOnly ? { is_active: "0" } : {}),
  ...(options?.teamLeadsOnly ? { is_team_lead: "1" } : {}),
  ...(options?.teamLeadIds
    ? { team_lead_id__in: options.teamLeadIds.join(",") }
    : {}),
  ...(options?.groupIds ? { group_id__in: options.groupIds.join(",") } : {}),
  ...(options?.divisionIds
    ? { division_id__in: options.divisionIds.join(",") }
    : {}),
  ...(options?.name ? { name: options.name } : {}),
  ...(options?.nameOrId ? { name_or_external_id: options.nameOrId } : {}),
  ...(options?.statuses ? { status__in: options.statuses.join(",") } : {})
});

/** Query the server to obtain a list of public ids for a company's employees */
export const retrieveEmployeePublicIds = async (
  company_id: string,
  options?: EmployeeFilterOptions
): Promise<APIResponse<string[]>> => {
  // TODO: move to a new query parameter format to request which data payload we want
  return await get(
    `/companies/${company_id}/employee_ids/`,
    headersFromFilterOptions(options)
  );
};

export interface RetrieveEmployeeOptions extends EmployeeFilterOptions {
  token?: string;
  limit?: number;
  events?: boolean;
  activeEvents?: boolean;
  notifications?: boolean;
  healthSurveyActivity?: boolean;
  onlyUnderScope?: boolean;
  groups?: boolean;
  onlyUnderEmployeeScope?: string;
}

/** Query the server to obtain a list of a companies employees QUICKLY with no other info */
export const getCompanyEmployees = async (
  company_id: string,
  options?: RetrieveEmployeeOptions
): Promise<APIResponse<PaginatedResponse<IdentificationEmployee>>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return await get(
    `/companies/${company_id}/employees/`,
    {
      ...(options?.limit ? { limit: `${options?.limit}` } : {}),
      ...{ simple: "1" },
      ...headersFromFilterOptions(options)
    },
    headers
  );
};

/** Query the server to obtain the information for a company's employees */
export const retrieveEmployees = async (
  company_id: string,
  options?: RetrieveEmployeeOptions
): Promise<APIResponse<PaginatedResponse<Employee>>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  // TODO: move to a new query parameter format to request which data payload we want
  return await get(
    `/companies/${company_id}/employees/`,
    {
      ...(options?.limit ? { limit: `${options?.limit}` } : {}),
      ...(options?.events ? { events: "1" } : {}),
      ...(options?.activeEvents ? { active_events: "1" } : {}),
      ...(options?.notifications ? { notifications: "1" } : {}),
      ...(options?.healthSurveyActivity ? { health_survey_activity: "1" } : {}),
      ...(options?.onlyUnderScope ? { only_under_scope: "1" } : {}),
      ...(options?.groups ? { groups: "1" } : {}),
      ...(options?.onlyUnderEmployeeScope
        ? { only_under_employee_scope: options.onlyUnderEmployeeScope }
        : {}),
      ...headersFromFilterOptions(options)
    },
    headers
  );
};

/** Query the server to obtain more employees via cursor pagination */
export const retrieveMoreEmployees = async (
  nextUrl: string,
  options?: {
    token?: string;
    groups?: boolean;
  }
): Promise<APIResponse<PaginatedResponse<Employee>>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  const path = nextUrl.substring(nextUrl.indexOf("/companies/"));
  return await get(
    path,
    {
      ...(options?.groups ? { groups: "1" } : {})
    },
    headers
  );
};

/** Query the server to obtain the employees for a given team lead */
export const retrieveEmployeeTokenInfo = async (
  token: string
): Promise<APIResponse<EmployeeTokenInfo>> => {
  return await get(`/companies/employee_tokens/${token}/`);
};

/** Send an SMS link to the screener experience to a specific employee */
export const makeScreener = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<EmployeeTokenInfo>> => {
  return await post(
    `/companies/${company_id}/employees/${employee_id}/make_screener/`,
    {}
  );
};

/** Create an employee event */
export const createEmployeeEvent = async (
  company_id: string,
  employee_id: string,
  event: DeepPartial<EmployeeEvent>,
  options?: {
    token?: string;
  }
): Promise<APIResponse<EmployeeEvent>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return await post(
    `/companies/${company_id}/employees/${employee_id}/events/`,
    event,
    headers
  );
};

/** Update an employee event */
export const updateEmployeeEvent = async (
  company_id: string,
  employee_id: string,
  event: DeepPartial<EmployeeEvent>,
  options?: {
    token?: string;
  }
): Promise<APIResponse<EmployeeEvent>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return await put(
    `/companies/${company_id}/employees/${employee_id}/events/${event.id}/`,
    event,
    headers
  );
};

export const deleteEmployeeEvent = async (
  company_id: string,
  employee_id: string,
  event: EmployeeEvent,
  options?: {
    token?: string;
  }
): Promise<APIResponse<object>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return await destroy(
    `/companies/${company_id}/employees/${employee_id}/events/${event.id}/`,
    undefined,
    headers
  );
};

/** Update event sync status */
export const updateEventSyncStatus = async (
  company_id: string,
  employee_id: string,
  event_id: string,
  sync_status: AlteredEventSyncStatus
): Promise<APIResponse<EmployeeEvent>> => {
  return await patch(
    `/companies/${company_id}/employees/${employee_id}/events/${event_id}/sync_status`,
    { sync_status }
  );
};

/** Retrieve all employee events */
export const retrieveEmployeeEvents = (
  company_id: string,
  start_date: string,
  end_date: string,
  filterDivisionIds?: string[],
  labelSets?: {
    exclude?: LabelSet[];
    only?: LabelSet[];
  }
): Promise<APIResponse<EmployeeEventWithEmployee[]>> => {
  const baseURL = `/companies/${company_id}/events/`;
  const searchParams = new URLSearchParams({
    active_starting: start_date,
    active_ending: end_date,
    ordering: "-created"
  });

  const params: [string, string[] | undefined][] = [
    ["division_id__in", filterDivisionIds],
    ["excluded_label_sets", labelSets?.exclude],
    ["only_included_label_sets", labelSets?.only]
  ];

  params.forEach(([key, value]: [string, string[] | undefined]) => {
    if (value) {
      searchParams.append(key, value.join(","));
    }
  });

  return get(`${baseURL}?${searchParams.toString()}`);
};

/** Set the health status for an employee */
export const setHealthStatus = async (
  company_id: string,
  employee_id: string,
  health_status: CovidHealthStatus,
  note_body?: string
): Promise<APIResponse<EmployeeEvent | {}>> => {
  return await post(
    `/companies/${company_id}/employees/${employee_id}/health_status/${health_status}/`,
    note_body ? { note: { body: note_body } } : {}
  );
};

export const getLatestPointsNotificationEvent = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<EmployeeEvent>> => {
  return await get(
    `/companies/${company_id}/employees/${employee_id}/last_points_notification_event/`
  );
};

/** Verify a company or division code */
export const verifyVisitorCode = async (
  code: string
): Promise<APIResponse<Company | ExpandedDivision>> => {
  return await get(`/companies/code/${code}/`);
};

export const startInfoVerification = async (
  company_id: string,
  employee_id: string,
  preferred_language?: string,
  phone_number?: string,
  notify_email?: string
): Promise<APIResponse<{}>> => {
  if (phone_number && notify_email) {
    throw new Error("phone_number and notify_email cannot both be supplied");
  }
  return await post(
    `/companies/${company_id}/employees/${employee_id}/start_info_verification/`,
    {
      preferred_language,
      ...(phone_number ? { phone_number } : {}),
      ...(notify_email ? { notify_email } : {})
    }
  );
};

export const completeInfoVerification = async (
  company_id: string,
  employee_id: string,
  token: string
): Promise<APIResponse<{}>> => {
  const headers = { "X-EmployeeTokenSession": token };
  return await post(
    `/companies/${company_id}/employees/${employee_id}/complete_info_verification/`,
    {},
    headers
  );
};

/** Retrieve status counts */
export const retrieveStatusCounts = async (
  company_id: string,
  start_date?: string,
  end_date?: string
): Promise<APIResponse<StatusCounts[]>> => {
  return await get(`/companies/${company_id}/status/`, {
    ...(start_date ? { start_date } : {}),
    ...(end_date ? { end_date } : {})
  });
};

/** Retrieve notes */
export const retrieveEmployeeNotes = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<Note[]>> => {
  return await get(`/companies/${company_id}/employees/${employee_id}/notes/`);
};

export const createEmployeeNote = async (
  company_id: string,
  employee_id: string,
  body: string
): Promise<APIResponse<Note>> => {
  return await post(
    `/companies/${company_id}/employees/${employee_id}/notes/`,
    { body }
  );
};

export const updateNote = async (
  company_id: string,
  employee_id: string,
  note: Note
): Promise<APIResponse<Note>> => {
  return await put(
    `/companies/${company_id}/employees/${employee_id}/notes/${note.id}/`,
    note
  );
};

export const deleteNote = async (
  company_id: string,
  employee_id: string,
  note: Note
): Promise<APIResponse<object>> => {
  return await destroy(
    `/companies/${company_id}/employees/${employee_id}/notes/${note.id}/`
  );
};

export const createEmployee = async (
  company_id: string,
  employee: NewEmployee
): Promise<APIResponse<Employee>> => {
  return await post(`/companies/${company_id}/employees/`, employee);
};

export const updateEmployee = async (
  employee: DeepPartial<Employee>
): Promise<APIResponse<Employee>> => {
  return await patch(
    `/companies/${employee.company_id}/employees/${employee.id}/`,
    employee
  );
};

/** Retrieve available timezones */
export const retrieveTimezones = async (
  company_id: string
): Promise<APIResponse<Timezones[]>> => {
  return await get(`/companies/${company_id}/timezones/`);
};

export type StatusNotificationObservingSetting =
  | "direct_reports"
  | "extended_directs"
  | "all_company"
  | "all_division"
  | "none";

export interface EmployeeStatusNotificationConfig {
  observing: StatusNotificationObservingSetting;
  alternate_for_ids: string[];
}

/** Retrieve status notification config for an employee */
export const retrieveEmployeeStatusNotificationConfig = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<EmployeeStatusNotificationConfig>> => {
  return await get(
    `/companies/${company_id}/employees/${employee_id}/status_notification_config`
  );
};

/** Update status notification config for an employee */
export const updateEmployeeStatusNotificationConfig = async (
  company_id: string,
  employee_id: string,
  config: EmployeeStatusNotificationConfig
): Promise<APIResponse<EmployeeStatusNotificationConfig>> => {
  return await put(
    `/companies/${company_id}/employees/${employee_id}/status_notification_config`,
    config
  );
};

export type ThresholdType = "safe" | "light" | "severe" | "termination";

export type Threshold = {
  points: number;
  name: string;
  type: ThresholdType;
  pointsLimit?: number;
  notification_threshold: string;
};

export type AttendancePolicy = {
  name: string;
  summary: string[];
  company: Company;
  points_thresholds: Threshold[];
};

export const getAttendancePolicy = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<AttendancePolicy>> => {
  return await get(
    `/companies/${company_id}/employees/${employee_id}/attendance_policy/`
  );
};

export enum DivisionConfig {
  ATTENDANCE_POINTS_DISPLAY = "attendance_points_display",
  ATTENDANCE_POINTS_POLICY = "attendance_points_policy",
  ATTENDANCE_POINTS_NOTIFY = "attendance_points_notify"
}

export const getDivisionConfig = async (
  company_id: string,
  division_id: string,
  setting_id: string
): Promise<APIResponse<{ data: boolean }>> => {
  const response: APIResponse<any> = await get(
    `/companies/${company_id}/division/${division_id}/config/${setting_id}`
  );
  if (response && "data" in response && "data" in response.data) {
    response.data = response.data.data;
  }
  return response;
};

export const getDivisionConfigs = async (
  company_id: string,
  division_id: string
): Promise<APIResponse<{ [key in DivisionConfig]: boolean }>> => {
  const response: APIResponse<any> = await get(
    `/companies/${company_id}/division/${division_id}/configs/`
  );
  if (response && "data" in response && "data" in response.data) {
    response.data = response.data.data;
  }
  return response;
};
