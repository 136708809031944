import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import { Bullet } from "../Bullet/Bullet";

interface ScreenerConfirmationProps {
  className?: string;
}

const styles: { [name: string]: React.CSSProperties } = {
  container: {
    borderRadius: "8px"
  }
};

export const ScreenerConfirmation: FunctionComponent<
  ScreenerConfirmationProps
> = props => {
  const { className } = props;
  const statements = [
    <Trans id="screener.confirmation.trained" key="trained">
      "I have been trained on our screening process & policy"
    </Trans>,
    <Trans id="screener.confirmation.share" key="share">
      "I will not share any of the data I see today with anyone else"
    </Trans>,
    <Trans id="screener.confirmation.ppe" key="ppe">
      "I have the necessary PPE to safely screen others"
    </Trans>
  ];

  return (
    <div
      className={clsx("py-7 px-6 bg-gray-100 text-hs-dark-green", className)}
      style={styles.container}
    >
      <div className="mb-5">
        <Trans id="screener.confirmation.text">
          Please confirm the following before you begin:
        </Trans>
      </div>

      {statements.map((statement, i) => (
        <div key={`statement_${i}`} className="flex flex-row">
          <Bullet className="mt-2" color="#01C16B" size="large" />
          <div className="pb-5 pl-4 bg-transparent">{statement}</div>
        </div>
      ))}
    </div>
  );
};
