import React, { FunctionComponent } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";
import "@inovua/reactdatagrid-community/index.css";

import TSTable from "../common/ts-table/TSTable";

import { AttendancePaneEmptyMessage } from "./AttendancePaneEmptyMessage";

import * as api from "~/api";
import { useSort } from "~/lib/hooks/useSort";
import "./AttendanceLogGrid.scss";

const AttendanceLogGrid: FunctionComponent<AttendanceLogGridProps> = ({
  className,
  employees,
  selectedEmployeeId,
  onSelectEmployee,
  selectedDate,
  isFiltering,
  setIsFiltering,
  allowInteractions = true
}) => {
  const {
    columns,
    dataSource,
    mobileColumns,
    isMobile,
    config,
    selected,
    onSelectionChange,
    onSortInfoChange
  } = useSort({
    employees,
    selectedEmployeeId,
    onSelectEmployee,
    isFiltering,
    setIsFiltering
  });

  const emptyEmployeesMessage = employees?.length === 0 && (
    <AttendancePaneEmptyMessage
      selectedDate={selectedDate}
      key="empty-message"
    />
  );

  return (
    <div className={clsx("ts attendance-table", className)}>
      <TSTable
        idProperty="id"
        className="table"
        defaultSortInfo={config.sortInfo}
        rowClassName={`global-custom-row ${
          allowInteractions ? "cursor-pointer" : ""
        }`}
        style={{ height: "100%", minHeight: 520 }}
        dataSource={dataSource}
        columns={isMobile ? mobileColumns : columns}
        emptyText={emptyEmployeesMessage}
        selected={selected}
        showColumnMenuTool={false}
        showCellBorders="horizontal"
        showZebraRows={false}
        rowHeight={81}
        headerHeight={50}
        onSelectionChange={onSelectionChange}
        sortInfo={config.sortInfo}
        onSortInfoChange={onSortInfoChange}
        enableColumnAutosize
        enableSelection
      />
    </div>
  );
};

export default AttendanceLogGrid;

interface AttendanceLogGridProps {
  className?: string;
  employees?: api.SimpleEmployeeWithEvents[];
  selectedEmployeeId?: string;
  onSelectEmployee: (employee: api.SimpleEmployeeWithEvents) => any;
  selectedDate: DateTime;
  isFiltering: boolean;
  setIsFiltering: (isFiltering: boolean) => void;
  allowInteractions?: boolean;
}
