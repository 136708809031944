import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";
import { DateTime } from "luxon";

import * as api from "~/api";
import { LanguageDropdown } from "~/components/LanguageDropdown/LanguageDropdown";
import StartOverIcon from "~/images/start-over-icon.svg";
import { useLinguiLanguage, useLinguiLocale } from "~/lib/hooks";

interface DateHeaderProps {
  isoDate?: api.ISODateTime;
}

const DateHeader: FunctionComponent<DateHeaderProps> = ({ isoDate }) => {
  const locale = useLinguiLocale();
  const date = isoDate ? DateTime.fromISO(isoDate) : DateTime.local();
  let dateText = "";
  try {
    dateText = date.setLocale(locale).toLocaleString({
      ...DateTime.DATE_MED_WITH_WEEKDAY,
      month: "long",
      year: undefined
    });
  } catch (e) {
    dateText = date.setLocale("en-US").toLocaleString({
      ...DateTime.DATE_MED_WITH_WEEKDAY,
      month: "long",
      year: undefined
    });
  }

  return (
    <div className="select-none text-hs-darker-gray uppercase text-xxs tracking-widest">
      {dateText}
    </div>
  );
};

interface ConversationHeaderProps {
  darkMode?: boolean;
  isoDate?: api.ISODateTime;
  completed?: boolean;
  hasStarted: boolean;
  subHeader: React.ReactNode;
  showUndo: boolean;
  showLanguages: boolean;
  onChangeLanguage: (lang: string) => any;
  onUndo: () => any;
  onStartOver: () => any;
}

export const ConversationHeader: FunctionComponent<ConversationHeaderProps> = ({
  darkMode,
  isoDate,
  completed,
  hasStarted,
  subHeader,
  showUndo,
  showLanguages,
  onChangeLanguage,
  onStartOver,
  onUndo
}) => {
  const language = useLinguiLanguage();

  return (
    <>
      <div
        className={clsx(
          "flex-none flex flex-row justify-between items-center px-5 h-11 border-b sticky top-0 bg-hs-gray",
          darkMode ? "border-hs-white-10" : "border-bg-hs-gray"
        )}
        dir="auto"
      >
        {showLanguages ? (
          <LanguageDropdown
            containerClassName="text-xs rounded border border-hs-dark-gray bg-white"
            language={language}
            onChange={onChangeLanguage}
          />
        ) : (
          <DateHeader isoDate={isoDate} />
        )}

        {hasStarted && !completed && (
          <div
            className="flex flex-row select-none text-hs-green text-xs tracking-wider cursor-pointer"
            onClick={showUndo ? onUndo : onStartOver}
          >
            {showUndo ? (
              <Trans id="survey.header.undo">Undo</Trans>
            ) : (
              <Trans id="survey.header.startOver">Start Over</Trans>
            )}{" "}
            <img
              className="h-auto w-auto pl-2"
              src={StartOverIcon}
              alt={showUndo ? "Undo" : "Start Over"}
            />
          </div>
        )}
      </div>
      {subHeader && (
        <div
          className={clsx(
            "text-white bg-hs-green text-center py-2 flex-row h-11 border-b"
          )}
          dir="auto"
        >
          {subHeader}
        </div>
      )}
    </>
  );
};
