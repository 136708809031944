import React, { FC } from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import * as api from "~/api";

interface FormResultsTextQuestionProps {
  textQuestion?: api.SurveyTextResults;
  showDivColumn: boolean;
  isAnonymous: boolean;
}

export const FormResultsTextQuestion: FC<FormResultsTextQuestionProps> = ({
  textQuestion,
  showDivColumn,
  isAnonymous
}) => (
  <div className="my-8">
    <h2>{textQuestion ? textQuestion.question : <Skeleton />}</h2>
    <div className="form-results-text-table">
      <Table>
        <thead className="sticky-top">
          <tr>
            <th>{textQuestion ? "RESPONSES" : <Skeleton />}</th>
            {textQuestion && !isAnonymous && <th>EMPLOYEE</th>}
            {showDivColumn && (
              <th>{textQuestion ? "DIVISION" : <Skeleton />}</th>
            )}
            <th>{textQuestion ? "MANAGER" : <Skeleton />}</th>
          </tr>
        </thead>
        <tbody>
          {!textQuestion?.answers && (
            <tr>
              <td
                colSpan={!textQuestion?.isAnonymous ? 4 : 3}
                className="text-center"
              >
                <h6 className="my-0">
                  {textQuestion ? "NO RESPONSES" : <Skeleton />}
                </h6>
              </td>
            </tr>
          )}
          {textQuestion?.answers?.map((answer, i) => (
            <tr key={i}>
              <td>{answer.value}</td>
              {!isAnonymous && <td>{answer.employee?.name}</td>}
              {showDivColumn && <td>{answer.employee?.division?.name}</td>}
              <td>{answer.employee?.team_lead?.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  </div>
);
