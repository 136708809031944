import * as api from "~/api";
import { RecipientListItem } from "~common";

export const parseRecipients = (
  recipients?: api.MessageRecipientWriter[],
  session?: api.Session,
  groups?: api.Group[],
  employees?: api.IdentificationEmployee[]
): RecipientListItem[] => {
  const parsedRecipients: RecipientListItem[] = [];
  recipients?.forEach(recipient => {
    if (recipient.kind === "emp" || recipient.kind === "rpt") {
      recipient.values.forEach(value => {
        parsedRecipients.push({
          kind: recipient.kind,
          id: value.id,
          text: generateEmployeeRecipientDisplayText(
            recipient.kind,
            value.name ??
              displayRecipient(
                session,
                recipient.kind,
                value.id,
                groups,
                employees
              ) ??
              ""
          )
        });
      });
    } else {
      recipient.values.forEach(value => {
        parsedRecipients.push({
          kind: recipient.kind,
          id: value.id,
          text:
            displayRecipient(
              session,
              recipient.kind,
              value.id,
              groups,
              employees
            ) ?? ""
        });
      });
    }
  });
  return parsedRecipients;
};

export const generateEmployeeRecipientDisplayText = (
  recipientKind: string,
  employeeName: string
): string => {
  return recipientKind === "rpt" ? `${employeeName}'s Reports` : employeeName;
};

export const generateRecipientConfirmationText = (
  parsedRecipients?: RecipientListItem[]
): string => {
  if (!parsedRecipients || parsedRecipients.length === 0) {
    return "There are no recipients selected";
  }
  let output = "This message will be sent to";
  if (parsedRecipients?.find(r => r.kind === "com")) {
    return `${output} everyone at the company.`;
  }
  let firstElement = true;
  const confirmationString = (intro: string, kind: string): void => {
    const filtered = parsedRecipients.filter(r => r.kind === kind);
    if (filtered.length > 0) {
      output += firstElement ? intro : ` and ${intro}`;
      output += filtered.map(f => f.text).join(", ");
      firstElement = false;
    }
  };
  confirmationString(" everyone in the following divisions: ", "div");
  confirmationString(" everyone in the following groups: ", "grp");
  confirmationString(" each of these employees: ", "emp");
  return output;
};

export const generateRecipientDisplayText = (
  recipients?: RecipientListItem[]
): string =>
  recipients
    ?.map(recipient => recipient.text)
    .filter(text => text)
    .join(", ") ?? "";
const displayRecipient = (
  session?: api.Session,
  recipientKind?: api.MessageRecipientKind,
  recipientId?: string,
  groups?: api.Group[],
  employees?: api.IdentificationEmployee[]
): string | undefined => {
  if (!session || !groups) {
    return;
  }
  switch (recipientKind) {
    case "com":
      return session.company?.name;
    case "div": {
      const division = (session.divisions ?? []).find(
        ({ id }) => id === recipientId
      );
      return division?.name;
    }
    case "grp": {
      const group = (groups ?? []).find(({ id }) => id === recipientId);
      return group?.name;
    }
    case "emp":
    case "rpt": {
      const employee = (employees ?? []).find(({ id }) => id === recipientId);
      return employee?.name;
    }
  }
};

export const isCompanySelectedInRecipients = (
  recipients?: api.MessageRecipient[]
): boolean => {
  return Boolean((recipients ?? []).find(({ kind }) => kind === "com"));
};

export const recipientIdsForKind = (
  recipientKind: api.MessageRecipientKind,
  recipients?: api.MessageRecipient[]
): string[] => {
  const initialIds: string[] = [];
  recipients?.forEach(({ kind, values }) => {
    if (kind === recipientKind) {
      values.forEach(({ id }) => initialIds.push(id));
    }
  });
  return initialIds;
};

export const recipientIdentificationEmployeesForDirectReports = (
  recipients?: api.MessageRecipient[]
): api.IdentificationEmployee[] => {
  const drEmployees: Set<api.IdentificationEmployee> = new Set();
  recipients?.forEach(recipient => {
    if (recipient.kind === "rpt") {
      recipient.values.forEach(employee => {
        drEmployees.add(employee);
      });
    }
  });

  return Array.from(drEmployees);
};

export const recipientIdentificationEmployeesFromRecipients = (
  recipients?: api.MessageRecipient[]
): api.IdentificationEmployee[] => {
  const initialEmployees: api.IdentificationEmployee[] = [];
  recipients?.forEach(recipient => {
    if (recipient.kind === "emp") {
      recipient.values.forEach(employee => initialEmployees.push(employee));
    }
  });
  return initialEmployees;
};
