import React, { FunctionComponent } from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import "./PortalLinkList.scss";

import { PortalLinkRow } from "./PortalLinkRow";

import * as api from "~/api";
import { FilterList, FilterListItem } from "~/lib/filterList";
import { TSMultiSelectCheckbox } from "~common";

const NUM_SKELETON_ROWS = 3;

export const PortalLinkList: FunctionComponent<PortalLinkListProps> = ({
  portalLinks,
  showDivisionColumn,
  divisionFilterList,
  onChangeDivisionFilterList,
  onClick
}) => {
  const numColumns = showDivisionColumn ? 4 : 3;
  const emptyResultsMessage = `There are no portal links${
    showDivisionColumn ? " that match the current filters" : ""
  }. Click "Add Portal Link" to create one.`;

  // content fragments
  const skeleton =
    !portalLinks &&
    [...Array(NUM_SKELETON_ROWS)].map((_, index) => (
      <tr key={`skeleton-${index}`} className="portal-row-skeleton">
        {[...Array(numColumns)].map((_, index) => (
          <td key={`td-skeleton-${index}`}>
            <Skeleton />
          </td>
        ))}
      </tr>
    ));

  const portalLinkRows = (portalLinks ?? []).map(portalLink => (
    <PortalLinkRow
      showDivisionColumn={showDivisionColumn}
      portalLink={portalLink}
      key={portalLink.id}
      onClick={onClick}
    />
  ));

  const emptyResultsRow = portalLinks?.length === 0 && (
    <tr className="portal-row-empty">
      <td colSpan={numColumns}>
        <h3>{emptyResultsMessage}</h3>
      </td>
    </tr>
  );

  return (
    <Table className="portal-table" responsive>
      <thead>
        <tr>
          <th>DISPLAY NAME</th>
          <th>URL</th>
          {showDivisionColumn && (
            <th>
              <TSMultiSelectCheckbox
                className="relative inline-block text-left mx-1"
                titlePrefix="DIVISONS"
                variant="heading"
                displayNone={false}
                filterList={divisionFilterList}
                onChangeFilterList={onChangeDivisionFilterList}
              />
            </th>
          )}
          <th>LAST MODIFIED</th>
        </tr>
      </thead>
      <tbody>
        {skeleton}
        {portalLinkRows}
        {emptyResultsRow}
      </tbody>
    </Table>
  );
};
interface PortalLinkListProps {
  portalLinks?: api.AdminPortalLink[];
  showDivisionColumn: boolean;
  divisionFilterList: FilterList<FilterListItem>;
  onChangeDivisionFilterList: (updateList: FilterList<FilterListItem>) => any;
  onClick: (portalLink: api.AdminPortalLink) => any;
}
