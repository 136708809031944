import { toast } from "react-toastify";

import Color from "color";
import { DateTime } from "luxon";

import * as api from "~/api";

export interface EmployeeLabels {
  employee: api.Employee;
  labelKeys: api.AnyStatus[];
}

const DEFAULT_LABEL_COLOR = "#C4C4C4";
const DEFAULT_LABEL_LANG = "en-US";

export const getLabelColor = (
  key: string,
  labelInfoMap: api.LabelInfoMap
): string => labelInfoMap[key]?.color ?? DEFAULT_LABEL_COLOR;

export const getLabelColorLightened = (
  key: string,
  labelInfoMap: api.LabelInfoMap
): string => new Color(getLabelColor(key, labelInfoMap)).lighten(0.2).hex();

export const getLabelColorDarkened = (
  key: string,
  labelInfoMap: api.LabelInfoMap
): string => new Color(getLabelColor(key, labelInfoMap)).darken(0.6).hex();

export const getLabelDisplay = (
  key: string,
  labelInfoMap: api.LabelInfoMap,
  language: string
): string => {
  const mainLanguage = language.split("-")[0];
  return (
    labelInfoMap[key]?.display[language] ??
    labelInfoMap[key]?.display[mainLanguage] ??
    labelInfoMap[key]?.display[DEFAULT_LABEL_LANG] ??
    key
  );
};

export const generateFullStatusesWithEmployeeList = (
  fullCovidHealthStatuses: boolean,
  employees?: api.Employee[]
): EmployeeLabels[] | undefined => {
  if (!employees) {
    return;
  }
  return employees.map(employee => {
    const activeEvents = employee.active_events ?? [];
    const activeLabelKeys = activeEvents.map(({ label }) => label.name);
    const labelKeys =
      fullCovidHealthStatuses && activeLabelKeys.length === 0
        ? ["unknown"]
        : activeLabelKeys;
    return {
      employee,
      labelKeys
    };
  });
};

const labelSortValue = (labelKey: string, sortedStatuses: string[]): number => {
  const index = sortedStatuses.findIndex(key => key === labelKey);
  if (index === -1) {
    return 0;
  }
  return 2 ** index;
};

export const statusSortRank = (
  status: EmployeeLabels,
  labelInfoMap: api.LabelInfoMap
): number => {
  const { sick, clear, unknown, ...otherLabels } = labelInfoMap;
  // Always place sick first, clear and unknown last, and any empty statuses last:
  // This shows in the above order but is in "reversed" order
  // as to always place empty statuses at the bottom of the list.
  const sortedStatuses = [
    ...(unknown ? ["unknown"] : []),
    ...(clear ? ["clear"] : []),
    ...Object.keys(otherLabels).sort(),
    ...(sick ? ["sick"] : [])
  ];

  return status.labelKeys.reduce(
    (acc, key) => acc + labelSortValue(key, sortedStatuses),
    0
  );
};

export const saveAttendanceStatusChange = async (
  employee: api.Employee,
  startDate: api.ISODateTime,
  endDate: api.ISODateTime,
  status: api.AttendanceStatus,
  labelInfoMap: api.LabelInfoMap,
  language: string,
  note?: string,
  event?: api.EmployeeEvent,
  options?: { token?: string }
): Promise<boolean> => {
  const apiMethod = event ? api.updateEmployeeEvent : api.createEmployeeEvent;
  const labelDisplay = getLabelDisplay(status, labelInfoMap, language);
  const successMsg = event
    ? `${employee.name}'s status was updated.`
    : `${employee.name}'s ${labelDisplay} was scheduled.`;
  const errorMsg = event
    ? `Sorry, we were unable to update ${employee.name}'s ${labelDisplay} status. Please try again.`
    : `Sorry, we were unable to schedule ${employee.name}'s ${labelDisplay}. Please try again.`;

  const response = await apiMethod(
    employee.company_id,
    employee.id,
    {
      ...event,
      label: {
        label_set: api.LabelSet.AttendanceStatus,
        name: status
      },
      ...(event?.note || note
        ? {
            note: {
              ...event?.note,
              body: note
            }
          }
        : {}),
      started: DateTime.fromISO(startDate, { zone: employee.timezone }).toISO(),
      ended: DateTime.fromISO(endDate, { zone: employee.timezone }).toISO()
    },
    options
  );
  if (response.ok) {
    toast.success(successMsg);
  } else {
    toast.error(errorMsg);
  }
  return response.ok;
};

export const deleteAttendanceStatusEvent = async (
  employee: api.Employee,
  event: api.EmployeeEvent,
  options?: { token?: string }
): Promise<boolean> => {
  const response = await api.deleteEmployeeEvent(
    employee.company_id,
    employee.id,
    event,
    options
  );
  if (response.ok) {
    toast.success(`${employee.name}'s status was deleted.`);
  } else {
    toast.error(
      `Sorry, we were unable to delete ${employee.name}'s status. Please try again.`
    );
  }
  return response.ok;
};

export const changeHealthStatus = async (
  employee: api.Employee,
  healthStatus: api.CovidHealthStatus,
  labelInfoMap: api.LabelInfoMap,
  language: string,
  noteBody: string
): Promise<boolean> => {
  const response = await api.setHealthStatus(
    employee.company_id,
    employee.id,
    healthStatus,
    noteBody
  );
  if (response.ok) {
    toast.success(
      `${employee.name}'s status is now ${getLabelDisplay(
        healthStatus,
        labelInfoMap,
        language
      )}.`
    );
  } else {
    toast.error(`Sorry, we were unable to edit ${employee.name}'s status.`);
  }
  return response.ok;
};

export const generateDateRangeShort = (
  locale: string,
  timezone: string,
  event?: api.EmployeeEvent
): string => {
  if (!event) {
    return "";
  }
  const dtStarted = DateTime.fromISO(event.started)
    .setZone(timezone)
    .setLocale(locale);
  const dtEnded = event.ended
    ? DateTime.fromISO(event.ended).setZone(timezone).setLocale(locale)
    : undefined;
  return constructDateRangeShort(dtStarted, dtEnded);
};

const adjustEndDate = (
  dtEnded: DateTime | undefined,
  dtStarted: DateTime
): DateTime | undefined => {
  if (!dtEnded) {
    return;
  }
  // We show the end date as the last day when they are absent, not the return date,
  // so we subtract one day from the provided ended ISODateTime:
  const dtAdjustedEnded = dtEnded.minus({ days: 1 });
  if (dtAdjustedEnded.startOf("day") <= dtStarted.startOf("day")) {
    // If the event is around 24 hours, the "adjusted" end date is the same as
    // the start date, so we don't need to show a range
    // Alternatively, if the "adjusted" end date is less than the started date,
    // we should just show the start date
    return;
  }
  return dtAdjustedEnded;
};

export const constructDateRangeShortFormatted = (
  dtStarted: DateTime,
  dtEnded?: DateTime,
  options?: { showDashForUnending: true }
): string => {
  let startedDate = "";
  try {
    startedDate = dtStarted.toLocaleString({
      month: "short",
      day: "numeric"
    });
  } catch {
    startedDate = dtStarted.toLocaleString({
      month: "2-digit",
      day: "2-digit",
      locale: "en-US"
    });
  }

  const adjustedEndDate = adjustEndDate(dtEnded, dtStarted);

  if (!dtEnded) {
    return options?.showDashForUnending ? `${startedDate} -` : startedDate;
  } else if (!adjustedEndDate) {
    return startedDate;
  }

  let endedDate = "";
  try {
    endedDate = adjustedEndDate.toLocaleString({
      month: "short",
      day: "numeric"
    });
  } catch {
    endedDate = adjustedEndDate.toLocaleString({
      month: "2-digit",
      day: "2-digit",
      locale: "en-US"
    });
  }

  return `${startedDate} - ${endedDate}`;
};

export const constructDateRangeShort = (
  dtStarted: DateTime,
  dtEnded?: DateTime,
  options?: { showDashForUnending: true }
): string => {
  let startedDate = "";
  try {
    startedDate = dtStarted.toLocaleString({
      ...DateTime.DATE_SHORT,
      year: undefined
    });
  } catch (e) {
    startedDate = dtStarted.toLocaleString({
      ...DateTime.DATE_SHORT,
      year: undefined,
      locale: "en-US"
    });
  }

  const dtAdjustedEnded = adjustEndDate(dtEnded, dtStarted);

  if (!dtEnded) {
    return options?.showDashForUnending ? `${startedDate} -` : startedDate;
  } else if (!dtAdjustedEnded) {
    return startedDate;
  }

  let endedDate = "";
  try {
    endedDate = dtAdjustedEnded.toLocaleString({
      ...DateTime.DATE_SHORT,
      year: undefined
    });
  } catch (e) {
    endedDate = dtAdjustedEnded.toLocaleString({
      ...DateTime.DATE_SHORT,
      year: undefined,
      locale: "en-US"
    });
  }
  // TODO: Consider making this format string localizable?
  return `${startedDate} - ${endedDate}`;
};

export const getDateRange = (
  timezone: string,
  started: string,
  ended: string | undefined,
  format?: boolean
): string => {
  const startDate = DateTime.fromISO(started).setZone(timezone);
  const endDate = ended ? DateTime.fromISO(ended).setZone(timezone) : undefined;

  return format
    ? constructDateRangeShortFormatted(startDate, endDate, {
        showDashForUnending: true
      })
    : constructDateRangeShort(startDate, endDate, {
        showDashForUnending: true
      });
};

export const formatISODateToCustomFormat = (
  isoDateString: string,
  timezone: string
): string => {
  const dateTime = DateTime.fromISO(isoDateString).setZone(timezone);

  // Format the DateTime object into "MM/dd h:mm a" format
  let formattedDate = "";
  try {
    formattedDate = dateTime.toLocaleString({
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true
    });
  } catch {
    formattedDate = dateTime.toLocaleString({
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      locale: "en-US"
    });
  }

  return formattedDate;
};

export const formatISODateToMonthDay = (
  isoDateString: string,
  timezone: string,
  locale: string
): string => {
  const dateTime = DateTime.fromISO(isoDateString)
    .setZone(timezone)
    .setLocale(locale);

  // Format the DateTime object into "MMM dd" format
  let formattedDate = "";
  try {
    formattedDate = dateTime.toLocaleString({
      month: "short",
      day: "numeric"
    });
  } catch {
    formattedDate = dateTime.toLocaleString({
      month: "short",
      day: "numeric",
      locale: "en-US"
    });
  }

  return formattedDate;
};

export const formatISODateToLongFormat = (
  isoDateString: string,
  timezone: string,
  locale: string
): string => {
  const dateTime = DateTime.fromISO(isoDateString)
    .setZone(timezone)
    .setLocale(locale);

  let formattedDate = "";
  try {
    formattedDate = dateTime.toLocaleString({
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short"
    });
  } catch {
    formattedDate = dateTime.toLocaleString({
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
      locale: "en-US"
    });
  }

  return formattedDate;
};

export const getEmployeeSyncStatus = (
  employee: api.SimpleEmployeeWithEvents
): api.AlteredEventSyncStatus | undefined => {
  const uniqueStatuses = new Set();
  for (const event of employee.events) {
    uniqueStatuses.add(event.sync_status);
  }
  // This means we're in the loading state
  if (uniqueStatuses.has(undefined)) {
    return;
  }

  if (uniqueStatuses.size === 1) {
    if (uniqueStatuses.has("synced")) {
      return "synced";
    }
    if (uniqueStatuses.has("not_synced")) {
      return "not_synced";
    }
  }
  // If none of the conditions get met,
  // We are either in the "changed" or "errored" state
  // We should display the symbol for "changed"
  return "changed";
};
