import React, { FC } from "react";
import { ProgressBar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import * as api from "~/api";
import { getRoundedPercentage } from "~/lib/stats";

import "./FormResults.scss";

interface FormResultsChoiceAnswerProps {
  answer?: api.SurveyChoiceAnswers;
  totalCount?: number;
}

export const FormResultsChoiceAnswer: FC<FormResultsChoiceAnswerProps> = ({
  answer,
  totalCount
}) => {
  const percentage =
    answer?.count && totalCount ? answer?.count / totalCount : 0;
  const rounded = getRoundedPercentage(percentage);
  return (
    <div className="form-answer">
      <div className="form-response">
        {answer ? answer.display : <Skeleton />}
      </div>
      <div className="form-progress">
        <ProgressBar now={rounded} />
      </div>
      <div>{answer ? `${rounded}%` : <Skeleton />}</div>
    </div>
  );
};
